const lang = {
    general: {
        wait: '处理中，请稍待',
        choose_folder: '选择档案夹',
    },
    buttons: {
        next: '下一步',
        back: '上一步',
        done: '完成',
        back_to_beam_studio: '返回 Beam Studio',
    },
    topbar: {
        untitled: '无标题',
        titles: {
            settings: '偏好设定',
        },
        export: 'GO',
        preview: '相机预览',
        preview_title: '相机预览',
        curve_engrave: '3D 曲线',
        task_preview: '工作预览',
        frame_task: '外框预览',
        borderless: '(开盖模式)',
        tag_names: {
            rect: '矩形',
            ellipse: '椭圆',
            path: '路径',
            polygon: '多边形',
            image: '影像',
            text: '文本',
            text_path: '路径文本',
            line: '線段',
            g: '群组',
            multi_select: '多个物件',
            use: '汇入物件',
            svg: 'SVG 物件',
            dxf: 'DXF 物件',
            no_selection: '无选择',
        },
        alerts: {
            start_preview_timeout: '#803 启动相机预览时超时，请重新开启您的机器或是 Beam Studio ，如果此错误持续发生，请参考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此则引导</a>。',
            fail_to_start_preview: '#803 启动相机预览失败，请重新开启您的机器或是 Beam Studio，如果此错误持续发生，请参考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此则引导</a>。',
            fail_to_connect_with_camera: '#803 与机器相机建立连线时失败，请重新开启您的机器或是 Beam Studio ，如果此错误持续发生，请参考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此則引導</a>。',
            add_content_first: '请先新增物件',
            headtype_mismatch: '检测到错误的模块，',
            headtype_none: '未检测到模块，',
            headtype_unknown: '检测到未知模块，',
            install_correct_headtype: '请正确安装10W或20W雷射模块以进入低激光模式。',
            door_opened: '请将门盖关闭以进入低激光模式。',
            fail_to_get_door_status: '请确保门盖关闭以进入低激光模式。',
            QcleanScene: '您要清除绘图吗？<br/>这还将擦除您的撤消历史！',
            power_too_high: '功率过高',
            power_too_high_msg: '激光管在高功率（70％ 以上）下耗损较快，使用低功率可以延长雷试管使用寿命。\n输入「知道了」以继续。',
            power_too_high_confirm: '知道了',
        },
        hint: {
            polygon: '按下 + / - 键以增加 / 减少边数。',
        },
        menu: {
            preferences: '偏好设定',
            hide: '隐藏 Beam Studio',
            hideothers: '隐藏其他',
            service: '服务',
            quit: '离开',
            window: '视窗',
            minimize: '最小化',
            close: '关闭视窗',
            file: '档案',
            edit: '编辑',
            help: '说明',
            open: '打开',
            samples: '范例',
            import_hello_beamo: 'beamo 范例',
            import_hello_beambox: 'Beambox 范例',
            import_material_testing_old: '材质雕刻测试 - 经典',
            import_material_testing_simple_cut: '材质切割测试 - 简易',
            import_material_testing_cut: '材质切割测试档',
            import_material_testing_engrave: '材质雕刻测试',
            import_material_testing_line: '材质线段测试',
            import_material_printing_test: '材质打印测试',
            import_ador_laser_example: 'Ador 激光范例',
            import_ador_printing_example_single: 'Ador 打印范例 - 单色',
            import_ador_printing_example_full: 'Ador 打印范例 - 全彩',
            import_acrylic_focus_probe: '压克力对焦尺 - 3mm',
            export_to: '汇出成...',
            export_flux_task: 'FLUX 工作',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: '储存',
            save_as: '另存新档',
            save_to_cloud: '保存到云端',
            about_beam_studio: '关于 Beam Studio',
            undo: '复原',
            redo: '重做',
            cut: '剪下',
            copy: '复制',
            paste: '贴上',
            paste_in_place: '粘贴到原位置',
            group: '群組化',
            ungroup: '解散群組',
            delete: '删除',
            duplicate: '重製',
            offset: '位移複製',
            scale: '縮放',
            rotate: '旋轉',
            reset: '重設',
            align_center: '置中',
            photo_edit: '影像',
            svg_edit: 'SVG',
            path: '路径',
            decompose_path: '解散非连续路径',
            object: '物件',
            layer_setting: '图层',
            layer_color_config: '颜色设定',
            image_sharpen: '鋭化',
            image_crop: '裁剪',
            image_invert: '色彩反转',
            image_stamp: '生成印章斜角',
            image_vectorize: '向量化',
            image_curve: '曲线',
            align_to_edges: '贴齐端点',
            document_setting: '文件设定',
            document_setting_short: '文件设定',
            clear_scene: '新建文件',
            machines: '机器',
            add_new_machine: '新增或设定机器',
            help_center: '说明中心',
            show_gesture_tutorial: '触控手势说明',
            show_start_tutorial: '显示新手教学',
            show_ui_intro: '显示界面介绍',
            questionnaire: '问卷回馈',
            change_logs: '更新日志',
            contact: '联络我们',
            tutorial: 'Delta Family 打印教学',
            design_market: 'Design Market',
            forum: '社群论坛',
            software_update: '软件更新',
            bug_report: '错误回报',
            dashboard: '仪表版',
            machine_info: '机器资讯',
            network_testing: '检测网路设定',
            commands: '指令',
            update_firmware: '韌體更新',
            using_beam_studio_api: '使用 Beam Studio API',
            set_as_default: '设为预设',
            calibrate_beambox_camera: '校正相机',
            calibrate_printer_module: '校准打印模块',
            calibrate_ir_module: '校准红外模块',
            calibrate_beambox_camera_borderless: '校正相机 (开盖模组）',
            calibrate_diode_module: '校正二极管激光模组',
            manage_account: '管理我的帐号',
            sign_in: '登入',
            sign_out: '登出',
            account: '帐号',
            my_account: '我的帐号',
            download_log: '汇出机器日志',
            download_log_canceled: '取消日志下载',
            download_log_error: '不明错误发生，请稍候再试一次',
            keyboard_shortcuts: '快捷键',
            log: {
                network: 'Network',
                hardware: 'Hardware',
                discover: 'Discover',
                usb: 'USB',
                usblist: 'USB List',
                camera: 'Camera',
                cloud: 'Cloud',
                player: 'Player',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: '检视',
            zoom_in: '放大',
            zoom_out: '缩小',
            fit_to_window: '配合视窗尺寸',
            zoom_with_window: '自动配合视窗尺寸',
            borderless_mode: '开盖模式',
            show_grids: '显示格线',
            show_rulers: '显示尺规',
            show_layer_color: '显示图层颜色',
            anti_aliasing: '抗锯齿',
            disassemble_use: '解散图档',
        },
        select_machine: '选择机器',
    },
    support: {
        no_webgl: '您的系统不支持 WebGL，建议您使用其他电脑开启 Mozu Studio',
        no_vcredist: '请安装 Visual C++ Redistributable 2015<br/>可以在flux3dp.com找到',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] 请重启 Beam Studio',
        OPERATION_ERROR: '[OE] 机器发生状态冲突，请再试一次',
        SUBSYSTEM_ERROR: '[SE] 固件执行雕刻程序错误，请尝试重新启动机器',
        UNKNOWN_COMMAND: '[UC] 请更新机器固件',
    },
    device_selection: {
        no_beambox: '#801 我们在网路上找不到您的机器，\n请参考<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/360001683556">此指南</a>排除连线问题！',
        no_device_web: '#801 请检查您的机器状态，或点击下方的‘机器设置’来设置机器。',
    },
    update: {
        update: '更新',
        release_note: '版本消息:',
        firmware: {
            caption: '有新的机器固件更新',
            message_pattern_1: '"%s" 有新的固件更新。',
            message_pattern_2: '%s 固件 v%s 可使用 - 你的版本为 v%s.',
            latest_firmware: {
                caption: '固件更新',
                message: '固件已经是最新版本',
                still_update: '文件更新',
                cant_get_latest: '无法取得最新版本固件资讯。',
            },
            confirm: '上传',
            upload_file: '固件上传',
            update_success: '固件更新上传成功',
            update_fail: '#822 更新失败',
            too_old_for_web: '你韧体的版本为 v3.5.1。\n欲使用线上版的 Beam Studio 请更新至最新韧体。',
            force_update_message: '#814 请将您的机器更新至最新的固件版本。',
            firmware_too_old_update_by_sdcard: '固件版本太旧，请使用SD卡更新固件。',
        },
        software: {
            checking: '检查更新中',
            switch_version: '版本切换',
            check_update: '检查更新',
            caption: 'Beam Studio 有新的软件更新',
            downloading: '正在背景下载中，您可以按确定以继续您的工作。',
            install_or_not: '已准备好更新，是否重新启动以套用更新？',
            switch_or_not: '已准备完成，是否重新启动以切换？',
            available_update: 'Beam Studio v%s 现可提供下载，你的版本为 v%s ，是否要下载更新？',
            available_switch: 'Beam Studio v%s 现可提供切换，你的版本为 v%s ，是否要切换至此此版本？',
            not_found: 'Beam Studio 已是最新版本。',
            no_response: '无法连接到伺服器，请确认您目前的网路状态。',
            switch_version_not_found: '無法找到可切換的版本',
            yes: '是',
            no: '否',
            update_for_ador: '当前的软件版本 %s 较旧，请下载 Beam Studio for Ador 的最新版本。',
        },
        updating: '更新中...',
        skip: '跳过此版本',
        preparing: '准备中...',
        later: '稍候',
        download: '在线更新',
        cannot_reach_internet: '#823 服务器无法连接<br/>请确认网络连接',
        install: '下载',
        upload: '上传',
    },
    topmenu: {
        version: '版本',
        credit: 'Beam Studio 的开发仰赖 <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> 开放原始码计画与其他<a target="_blank" href="https://flux3dp.com/credits/">开放原始码的软体</a>的协助才得以完成。',
        ok: '确定',
        file: {
            label: '文件',
            import: '导入',
            save_fcode: '导出工作',
            save_scene: '导出场景',
            save_svg: '汇出 SVG',
            save_png: '汇出 PNG',
            save_jpg: '汇出 JPG',
            converting: '转换成图片...',
            all_files: '所有文件',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio 场景',
            fcode_files: 'FLUX Code',
            clear_recent: '清除历史纪录',
            path_not_exit: '此路径似乎已不存在于电脑中，请确认是否有更改档案位置。',
        },
        device: {
            download_log_canceled: '取消日志下载',
            download_log_error: '不明错误发生，请稍候再试一次',
            log: {
                usblist: 'USB 清单',
            },
            network_test: '网路检测',
        },
    },
    initialize: {
        // generic strings
        next: '下一步',
        start: '开始设置',
        skip: '跳过',
        cancel: '取消',
        confirm: '确认',
        connect: '连接',
        back: '返回',
        retry: '重试',
        no_machine: '目前没有机器或已设置过连接，跳过此步骤',
        // specific caption/content
        select_language: '请选择你想使用的语言',
        select_machine_type: '请选择您的机种',
        select_connection_type: '请选择您的连接方式',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: '有线网路',
            ether2ether: '网路线直连',
            usb: 'USB 連線',
        },
        connect_wifi: {
            title: '连接 Wi-Fi',
            tutorial1: '1. 到机器控制面板 > 点击 「网路」 > 「设定 Wi-Fi」。',
            tutorial1_ador: '1. 到机器控制面板 > 点击 「机器」> 点击 「网路」 > 「设定 Wi-Fi」。',
            tutorial2: '2. 选择并连接您想使用的 Wi-Fi 。',
            what_if_1: '机器找不到我想使用的 Wi-Fi',
            what_if_1_content: '1. Wi-Fi 加密方式需为 WPA2 或无密码。 \n2. 加密方式可由 Wi-Fi 路由器设定，如果路由器不支援 WPA2，可联系客服购买，如果不确定路由器是否支援，可以将型号传给客服询问。',
            what_if_2: '机器找不到任何 Wi-Fi',
            what_if_2_content: '1. 确认您的 Wi-Fi 接收器是否有着实插上。 \n2. 如果面板上没有出现无线网路硬体位置，请联系客服。 \n3. Wi-Fi 频道为 2.4Ghz (不支援 5Ghz)。',
        },
        connect_wired: {
            title: '连接有线网路',
            tutorial1: '1. 请将您的机器以乙太网路线与路由器连接。',
            tutorial2: '2. 在触控面板点击「网路」以获得有线网路 IP 。',
            tutorial2_ador: '在触控面板点击「机器」>「网路」以获得有线网路 IP 。',
            what_if_1: '机器显示的有线网路 IP 是空的',
            what_if_1_content: '1. 确认您的乙太网路线是否有着实插上。 \n2. 如果面板上没有出现有线网路硬体位置，请联系客服。',
            what_if_2: '机器显示的 IP 开头为 169',
            what_if_2_content: '1. IP 地址为 169.254 开头通常为 DHCP 设定问题，需要联系网路服务提供商或是网路设定者来协助。 \n2. 如果工作环境的网路是由电脑直接 PPPoE 连网，请改由路由器直接 PPPoE 联网，并在路由器中开启DHCP 功能。',
        },
        connect_ethernet: {
            title: '网路线直连',
            tutorial1: '1. 将您的机器与您的电脑以乙太网路线连接。',
            tutorial2_1: '2. 依照',
            tutorial2_a_text: '这篇文章',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/zh-tw/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/zh-tw/articles/360001507715',
            tutorial2_2: '使您的电脑同时扮演路由器的角色。',
            tutorial3: "3. 点击 '下一步'。",
        },
        connect_usb: {
            title: 'USB 连线',
            title_sub: '（仅支援 HEXA & Ador）',
            tutorial1: '将您的机器与您的电脑以 USB 线连接。',
            tutorial2: "点击 '下一步'。",
            turn_off_machine: '关闭机器',
            turn_on_machine: '打开机器',
            wait_for_turning_on: "启动过程完成并进入主屏幕后，点击 '下一步'。",
        },
        connect_machine_ip: {
            check_usb: '确认 USB 连线',
            enter_ip: '请输入您的机器 IP',
            check_ip: '确认 IP',
            invalid_ip: 'IP 错误：',
            invalid_format: '格式不符合',
            starts_with_169254: '由 169.254 开头',
            unreachable: '无法连接至指定 IP',
            check_connection: '确认机器连线',
            check_firmware: '确认韧体版本',
            check_camera: '确认相机',
            retry: '重试',
            succeeded_message: '连线成功 🎉',
            finish_setting: '开始创作',
        },
        connecting: '连接中',
        setting_completed: {
            start: '开始使用',
            great: '欢迎使用 Beam Studio',
            setup_later: '您可以随时从选单 >「机器」>「新增或设定机器」来设定连线。',
            back: '回到 Wi-Fi 设置',
            ok: '开始使用',
        },
    },
    error_pages: {
        screen_size: '请注意，Beam Studio 无法在您的装置上顺利执行。若要获得最佳使用体验，装置萤幕宽度至少应有 1024 像素。',
    },
    menu: {
        mm: '毫米',
        inches: '英吋',
    },
    settings: {
        on: '开',
        off: '关',
        low: '低',
        high: '正常',
        caption: '设置',
        tabs: {
            general: '一般',
            device: '机器',
        },
        ip: '机器 IP 位址',
        guess_poke: '自动搜寻机器 IP',
        auto_connect: '自动选择唯一机器',
        wrong_ip_format: 'IP格式错误',
        reset: '重置所有设置',
        default_machine: '默认机器',
        default_machine_button: '无',
        remove_default_machine_button: '删除',
        confirm_remove_default: '将会删除默认机器',
        reset_now: '重置所有设置',
        confirm_reset: '确认要重置 Beam Studio?',
        language: '语言',
        notifications: '显示桌面通知',
        check_updates: '自动检查',
        autosave_enabled: '自动储存',
        autosave_path: '档案夹',
        autosave_interval: '储存间隔',
        autosave_number: '自动储存数',
        autosave_path_not_correct: '找不到指定的路径',
        preview_movement_speed: '相机预览移动速度',
        preview_movement_speed_hl: '相机预览移动速度（二极管激光启用时）',
        default_units: '预设单位',
        default_font_family: '预设字体',
        default_font_style: '预设字型',
        fast_gradient: '速度优化',
        engraving_direction: '雕刻方向',
        top_down: '由上往下',
        bottom_up: '由下往上',
        vector_speed_constraint: '限制上限速度 (20 mm/s)',
        loop_compensation: '封闭路径补偿',
        blade_radius: '旋转刀半径',
        blade_precut_switch: '旋转刀预切',
        blade_precut_position: '预切位置',
        default_beambox_model: '预设文件设定',
        guides_origin: '参考线座标',
        guides: '参考线',
        image_downsampling: '点阵图预览品质',
        anti_aliasing: '抗锯齿',
        continuous_drawing: '连续绘制',
        trace_output: '向量化及影像描图输出',
        single_object: '单一物件',
        grouped_objects: '多个物件群组',
        simplify_clipper_path: '路径计算优化',
        enable_low_speed: '启用慢速移动',
        enable_custom_backlash: '启用自訂背隙補償',
        custom_preview_height: '自訂预览高度',
        mask: '工作范围剪裁',
        text_path_calc_optimization: '路径计算优化',
        font_substitute: '自动替换字体',
        font_convert: '文本转路径',
        default_borderless_mode: '开盖模式预设',
        default_enable_autofocus_module: '自动对焦预设',
        default_enable_diode_module: '二极管激光预设',
        diode_offset: '二极管激光偏移值',
        autofocus_offset: '自动对焦高度补偿',
        diode_one_way_engraving: '二极管激光单向雕刻',
        diode_two_way_warning: '双向出光，速度较快时可能导致雕刻时位置误差，建议预先使用材料进行测试。',
        share_with_flux: '与 FLUX 分享',
        none: '无',
        close: '关闭',
        enabled: '启用',
        disabled: '不启用',
        cancel: '取消',
        done: '套用',
        module_offset_10w: '10W 二极管激光偏移',
        module_offset_20w: '20W 二极管激光偏移',
        module_offset_printer: '打印偏移',
        module_offset_2w_ir: '2W 红外激光偏移',
        printer_advanced_mode: '打印机高级模式',
        default_laser_module: '默认激光模块',
        low_laser_for_preview: '外框预览低激光',
        groups: {
            general: '一般',
            update: '软体更新',
            connection: '连线',
            autosave: '自动储存',
            camera: '相机',
            editor: '编辑器',
            path: '路径 (线段)',
            engraving: '雕刻 (扫描)',
            mask: '工作范围剪裁',
            text_to_path: '文本',
            modules: '扩充模组',
            ador_modules: 'Ador 模组',
            privacy: '隐私',
        },
        notification_on: '开启',
        notification_off: '关闭',
        update_latest: '稳定版',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: '群组',
            use: '汇入图档',
            image: '图片',
            text: '文字',
        },
        context_menu: {
            cut: '剪切',
            copy: '复制',
            paste: '粘贴',
            paste_in_place: '粘贴到原位置',
            duplicate: '重製',
            delete: '删除',
            group: '组合',
            ungroup: '取消组合元素',
            move_front: '移至顶部',
            move_up: '向上移动',
            move_down: '向下移动',
            move_back: '移至底部',
        },
        popup: {
            select_import_method: '选择分层方式:',
            select_import_module: '选择模块：',
            touchpad: '触摸板',
            mouse: '鼠标',
            layer_by_layer: '依图层分层',
            layer_by_color: '依颜色分层',
            nolayer: '不分层',
            loading_image: '载入图片中，请稍候...',
            no_support_text: 'Beam Studio 目前不支持由外部导入文本标签，请由矢量绘图软件将文本转成路径后再导入。',
            speed_too_high_lower_the_quality: '在此雕刻分辨率使用过高速度，可能导致渐层雕刻較差品质。',
            too_fast_for_path: '含有路径物件的图层速度过快，可能导致切割时位置误差。\n不建议超过在切割路径时超过 20mm/s。',
            too_fast_for_path_and_constrain: '以下图层： %s\n含有向量路径物件且速度超过 20mm/s，为维持雕刻的精度，向量路径速度将被限制在 20mm/s，您可以在偏好设定解除此限制。',
            both_power_and_speed_too_high: '激光管在高功率下耗损较快，使用低功率可以延长雷试管使用寿命。\n同时在此雕刻分辨率使用过高速度，可能导致渐层雕刻較差品质。',
            should_update_firmware_to_continue: '#814 您的固件版本不支持最新的软件改善。为了更良好的使用经验与雕刻品质，请先更新手机切膜机的固件以继续。 (主菜单 > 机器 > [ Your手机切膜机] > 固件更新)',
            recommend_downgrade_software: '检测到较旧的固件版本。我们正在积极解决兼容性问题，但目前建议您回退到 <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>。',
            recommend_upgrade_firmware: '我们检测到较旧的固件版本。我们正在积极解决兼容性问题，但目前建议更新到最新的固件。',
            still_continue: '继续',
            more_than_two_object: '太多物件，只支援两物件操作',
            not_support_object_type: '不支援的物件类型',
            select_first: '请先选取物件以继续',
            select_at_least_two: '请选取两个物件以继续',
            import_file_contain_invalid_path: '#808 汇入的SVG档案中含有不存在的图片路径，请确认档案中所有连结之图片皆存在，或改将图片嵌入档案中。',
            import_file_error_ask_for_upload: '读取 SVG 档时发生错误，是否愿意上传档案回报错误给开发团队？',
            upload_file_too_large: '#819 档案大小过大，请联络客服。',
            successfully_uploaded: '档案已成功上传。',
            upload_failed: '#819 档案上传失败。',
            or_turn_off_borderless_mode: '或是关闭开盖模式',
            svg_1_1_waring: '此档案标示之 SVG 版本为 1.1 版，可能有潜在的不相容风险。',
            svg_image_path_waring: '此档案内含有以路径读取的图片，可能会有读取失败的风险。请在做图汇出 SVG 时，当点阵图相关选项改成嵌入。',
            dxf_version_waring: '此 DXF 档版本非 2013 版，可能有潜在的不相容风险。',
            dont_show_again: '别再显示此提醒',
            convert_to_path_fail: '转换成路径失败。',
            save_unsave_changed: '请问是否要储存未储存的变更，否则变更将会遗失？',
            dxf_bounding_box_size_over: '图像超出工作范围，请在 CAD 软体中将图像放置于原点附近，或确定图档单位是否正确设定。',
            progress: {
                uploading: '上传中',
                calculating: '计算中',
            },
            backend_connect_failed_ask_to_upload: '#802 连接后端程式时持续发生错误，请问您是否要将错误报告上传到云端?',
            backend_error_hint: '后端程式无法连接，部分功能可能无法使用。',
            pdf2svg: {
                error_when_converting_pdf: '#824 将 PDF 转换成 SVG 时发生错误：',
                error_pdf2svg_not_found: '#825 无法找到 pdf2svg 指令，请使用您的套件管理装置安装 pdf2svg（e.g., "yum install pdf2svg" or "apt-get install pdf2svg"）。',
            },
            ungroup_use: '正要解散外部汇入的 DXF 或是 SVG ，在含有物件较多的情况，可能会需要等一阵子，是否确定解散？',
            vectorize_shading_image: '渐层影像在向量化时将花费较多时间，且容易有杂点，请将影像渐层关闭后再执行。',
            change_workarea_before_preview: '%s 的工作范围与目前设定的工作范围不相符，是否要切换目前的工作范围？',
            bug_report: '错误回报',
            sentry: {
                title: '我们一起让 Beam Studio 变得更好',
                message: '请问您是否同意在遇到错误时将相关资讯自动上传给开发团队？',
            },
            questionnaire: {
                caption: '协助我们填写问卷',
                message: '协助我们填写问券，让产品变得更好。',
                unable_to_get_url: '无法透过网路取得目前最新问卷的连结，请确认您的网路连线状况。',
                no_questionnaire_available: '目前没有可供填写的问卷。',
            },
            facebook_group_invitation: {
                title: '加入 FLUX 官方用户社群',
                message: '即刻加入 FLUX 官方用户社群，与广大 FLUX 用户分享使用心得、展示雷雕作品以及获取最新的产品资讯。',
                join_now: '立马加入',
                later: '稍后加入',
                already_joined: '已加入用户社群',
                dont_show_again: '不要再提醒我',
            },
            ai_credit: {
                relogin_to_use: '请重新登录以使用此功能。',
                insufficient_credit: '您的 Credit 已用完',
                insufficient_credit_msg: '您无法使用%s，请前往会员中心充值 AI Credit。',
                buy_link: 'https://member.flux3dp.com/zh-TW/credit',
                go: '前往',
            },
            text_to_path: {
                caption: '文字转路径 2.0',
                message: 'Beam Studio 现在推出全新的文字转路径2.0，为您带来更稳定的使用体验！您是否要立即切换？\n您也可以稍后于偏好设定中的「文字转路径」修改此设定。 ',
            },
        },
        zoom_block: {
            fit_to_window: '配合视窗尺寸',
        },
        time_est_button: {
            calculate: '预估时间',
            estimate_time: '预计所需时间：',
        },
        left_panel: {
            unpreviewable_area: '非相机预览范围',
            diode_blind_area: '非雕刻范围',
            borderless_blind_area: '非雕刻范围',
            borderless_preview: '开盖模式相机预览',
            rectangle: '长方形',
            ellipse: '椭圆形',
            line: '线段',
            image: '图片',
            text: '文本',
            label: {
                cursor: '选取',
                photo: '图片',
                text: '文字',
                line: '线段',
                rect: '方块',
                oval: '椭圆',
                polygon: '多边形',
                pen: '钢笔',
                shapes: '元素',
                array: '阵列',
                preview: '相机预览',
                trace: '影像描图',
                end_preview: '结束预览',
                clear_preview: '清除预览',
                choose_camera: '相机预览',
                live_feed: '实时视频',
                adjust_height: '调整高度',
                qr_code: '二维码',
                boxgen: 'Boxgen',
                my_cloud: '我的云端',
                curve_engraving: {
                    title: '3D 曲线',
                    exit: '退出',
                    select_area: '选择区域',
                    preview_3d_curve: '预览 3D 曲面',
                    clear_area: '清除选定区域',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: '图层',
                objects: '物件',
                path_edit: '路径编辑',
            },
            layer_panel: {
                layer1: '默认图层',
                layer_bitmap: '位图层',
                layer_engraving: '雕刻图层',
                layer_cutting: '切割图层',
                current_layer: '目前图层',
                move_elems_to: '移动到：',
                notification: {
                    dupeLayerName: '图层名称与现有图层重复，请使用别的名称。',
                    newName: '新图层名称',
                    enterUniqueLayerName: '请输入一个唯一的图层名称',
                    enterNewLayerName: '请输入新的图层名称',
                    layerHasThatName: '图层已经采用了该名称',
                    QmoveElemsToLayer: "您确定移动所选元素到图层'%s'吗?",
                    moveElemFromPrintingLayerTitle: '您是否要移动选取物件至 %s 并转换为激光物件？',
                    moveElemFromPrintingLayerMsg: '请注意，如果您继续此操作，选取物件的颜色设置将会被删除，并根据 %s 参数而定。',
                    moveElemToPrintingLayerTitle: '您是否要移动选取物件至 %s 并转换为打印物件？',
                    moveElemToPrintingLayerMsg: '请注意，如果您继续此操作，选取物件的设置将会被删除，并根据 %s 参数而定。',
                    splitColorTitle: '您是否要将所选图层展开为 CMYK 图层？',
                    splitColorMsg: '请注意，如果您完成此操作，展开的图层将无法重新合并。',
                    mergeLaserLayerToPrintingLayerTitle: '您是否要将多个图层合并为一个打印图层？',
                    mergeLaserLayerToPrintingLayerMsg: '请注意，如果您继续此操作，激光图层的设置将会被删除，并根据当前图层参数而定。',
                    mergePrintingLayerToLaserLayerTitle: '您是否要将多个图层合并为一个激光图层？',
                    mergePrintingLayerToLaserLayerMsg: '请注意，如果您继续此操作，打印图层的颜色设置将会被删除，并根据当前图层参数而定。',
                },
                layers: {
                    layer: '图层',
                    layers: '图层',
                    del: '删除图层',
                    move_down: '向下移动图层',
                    new: '新建图层',
                    rename: '重命名图层',
                    move_up: '向上移动图层',
                    dupe: '复制图层',
                    lock: '锁定图层',
                    unlock: '解锁',
                    merge_down: '向下合并',
                    merge_all: '全部合并',
                    merge_selected: '合并选取图层',
                    move_elems_to: '移动元素至:',
                    move_selected: '移动元素至另一个图层',
                    switchToFullColor: '切换到全彩图层',
                    switchToSingleColor: '切换到单色图层',
                    splitFullColor: '展开图层',
                    fullColor: '全彩',
                },
            },
            laser_panel: {
                preset_setting: '参数调整（%s）',
                multi_layer: '多个图层',
                parameters: '選擇參數',
                strength: '功率',
                low_power_warning: '功率较低时（低于 10 %），有可能不出光。',
                speed: '速度',
                speed_contrain_warning: '矢量路径速度将被限制在 20mm/s (0.79in/s)，您可以在偏好设定解除此限制。',
                low_speed_warning: '低速度下可能会导致材料燃烧。',
                repeat: '运行次数',
                add_on: '扩充模组',
                focus_adjustment: '对焦调整',
                height: '物件高度',
                z_step: '每次递降',
                diode: '二极体雷射',
                backlash: '背隙调整',
                ink_saturation: '饱和度',
                print_multipass: '多重打印',
                white_ink: '白墨',
                white_ink_settings: '白墨设置',
                color_adjustment: '色版调整',
                color_adjustment_short: '色版',
                halftone: '半色调',
                halftone_link: 'https://support.flux3dp.com/hc/zh-tw/articles/9402670389647',
                color_strength: '强度',
                times: '次',
                cut: '切割',
                engrave: '雕刻',
                more: '管理',
                delete: '删除',
                reset: '恢复预设',
                sure_to_reset: '这将会重置所有的预设参数，并保留您的自订参数，确定要继续进行吗？',
                apply: '套用',
                cancel: '取消',
                save: '储存参数',
                save_and_exit: '保存并退出',
                name: '名称',
                default: '预设',
                customized: '自订参数清单',
                inuse: '使用中',
                export_config: '汇出参数',
                new_config_name: '新参数名称',
                sure_to_load_config: '将要读取预设参数的排序与使用状况，并覆盖所有同名的自订参数，确定要继续进行吗？',
                custom_preset: '自订',
                various_preset: '多个参数',
                module: '模块',
                slider: {
                    regular: '普通',
                    low: '低',
                    very_low: '最低',
                    high: '高',
                    very_high: '最高',
                    slow: '慢',
                    very_slow: '最慢',
                    fast: '快',
                    very_fast: '最快',
                },
                dropdown: {
                    parameters: '选择参数',
                    save: '新增目前参数',
                    export: '汇出参数',
                    import: '汇入参数',
                    more: '管理',
                    mm: {
                        wood_3mm_cutting: '木板 - 3mm 切割',
                        wood_5mm_cutting: '木板 - 5mm 切割',
                        wood_7mm_cutting: '木板 - 7mm 切割',
                        wood_8mm_cutting: '木板 - 8mm 切割',
                        wood_10mm_cutting: '木板 - 10mm 切割',
                        wood_engraving: '木板 - 刻印',
                        acrylic_3mm_cutting: '压克力 - 3mm 切割',
                        acrylic_5mm_cutting: '压克力 - 5mm 切割',
                        acrylic_8mm_cutting: '压克力 - 8mm 切割',
                        acrylic_10mm_cutting: '压克力 - 10mm 切割',
                        acrylic_engraving: '压克力 - 刻印',
                        mdf_3mm_cutting: '新西兰密迪板 - 3 mm 切割',
                        mdf_5mm_cutting: '新西兰密迪板 - 5 mm 切割',
                        mdf_engraving: '新西兰密迪板 - 刻印',
                        leather_3mm_cutting: '皮革 - 3mm 切割',
                        leather_5mm_cutting: '皮革 - 5mm 切割',
                        leather_engraving: '皮革 - 刻印',
                        denim_1mm_cutting: '牛仔布 - 1mm 切割',
                        fabric_3mm_cutting: '布料 - 3mm 切割',
                        fabric_5mm_cutting: '布料 - 5mm 切割',
                        fabric_engraving: '布料 - 刻印',
                        rubber_bw_engraving: '印章垫 - 刻印',
                        glass_bw_engraving: '玻璃 - 刻印',
                        metal_bw_engraving: '不锈钢 - 刻印',
                        steel_engraving_spray_engraving: '不锈钢喷剂 - 刻印',
                        stainless_steel_bw_engraving_diode: '不锈钢 - 刻印（二极体雷射）',
                        gold_engraving: '金 - 刻印',
                        brass_engraving: '黄铜 - 刻印',
                        ti_engraving: '钛 - 刻印',
                        stainless_steel_engraving: '不锈钢 - 刻印',
                        aluminum_engraving: '铝 - 刻印',
                        black_acrylic_3mm_cutting: '黑色压克力 - 3mm 切割',
                        black_acrylic_5mm_cutting: '黑色压克力 - 5mm 切割',
                        black_acrylic_engraving: '黑色压克力 - 刻印',
                        abs_engraving: 'ABS - 刻印',
                        silver_engraving: '银 - 刻印',
                        iron_engraving: '铁 - 刻印',
                        fabric_printing: '布料 - 打印',
                        canvas_printing: '画布 - 打印',
                        cardstock_printing: '卡纸 - 打印',
                        wood_printing: '木材 - 打印',
                        bamboo_printing: '竹子 - 打印',
                        cork_printing: '软木 - 打印',
                        flat_stone_printing: '石板 - 打印',
                        acrylic_printing: '亚克力 - 打印',
                        pc_printing: 'PC - 打印',
                        stainless_steel_printing: '不锈钢 - 打印',
                        gloss_leather_printing: '亮面皮革 - 打印',
                        glass_printing: '玻璃 - 打印',
                    },
                    inches: {
                        wood_3mm_cutting: "木板 - 0.1'' 切割",
                        wood_5mm_cutting: "木板 - 0.2'' 切割",
                        wood_7mm_cutting: "木板 - 0.3'' 切割",
                        wood_8mm_cutting: "木板 - 0.3'' 切割",
                        wood_10mm_cutting: "木板 - 0.4'' 切割",
                        wood_engraving: '木板 - 刻印',
                        acrylic_3mm_cutting: "压克力 - 0.1'' 切割",
                        acrylic_5mm_cutting: "压克力 - 0.2'' 切割",
                        acrylic_8mm_cutting: "压克力 - 0.3'' 切割",
                        acrylic_10mm_cutting: "压克力 - 0.4'' 切割",
                        acrylic_engraving: '压克力 - 刻印',
                        mdf_3mm_cutting: "新西兰密迪板 - 0.1'' 切割",
                        mdf_5mm_cutting: "新西兰密迪板 - 0.2'' 切割",
                        mdf_engraving: '新西兰密迪板 - 刻印',
                        leather_3mm_cutting: "皮革 - 0.1'' 切割",
                        leather_5mm_cutting: "皮革 - 0.2'' 切割",
                        leather_engraving: '皮革 - 刻印',
                        denim_1mm_cutting: "牛仔布 - 0.4'' 切割",
                        fabric_3mm_cutting: "布料 - 0.1'' 切割",
                        fabric_5mm_cutting: "布料 - 0.2'' 切割",
                        fabric_engraving: '布料 - 刻印',
                        rubber_bw_engraving: '印章垫 - 刻印',
                        glass_bw_engraving: '玻璃 - 刻印',
                        metal_bw_engraving: '不锈钢 - 刻印',
                        steel_engraving_spray_engraving: '不锈钢喷剂 - 刻印',
                        stainless_steel_bw_engraving_diode: '不锈钢 - 刻印（二极体雷射）',
                        gold_engraving: '金 - 刻印',
                        brass_engraving: '黄铜 - 刻印',
                        ti_engraving: '钛 - 刻印',
                        stainless_steel_engraving: '不锈钢 - 刻印',
                        aluminum_engraving: '铝 - 刻印',
                        black_acrylic_3mm_cutting: "黑色压克力 - 0.1'' 切割",
                        black_acrylic_5mm_cutting: "黑色压克力 - 0.2'' 切割",
                        black_acrylic_engraving: '黑色压克力 - 刻印',
                        abs_engraving: 'ABS - 刻印',
                        silver_engraving: '银 - 刻印',
                        iron_engraving: '铁 - 刻印',
                        fabric_printing: '布料 - 打印',
                        canvas_printing: '画布 - 打印',
                        cardstock_printing: '卡纸 - 打印',
                        wood_printing: '木材 - 打印',
                        bamboo_printing: '竹子 - 打印',
                        cork_printing: '软木 - 打印',
                        flat_stone_printing: '石板 - 打印',
                        acrylic_printing: '亚克力 - 打印',
                        pc_printing: 'PC - 打印',
                        stainless_steel_printing: '不锈钢 - 打印',
                        gloss_leather_printing: '亮面皮革 - 打印',
                        glass_printing: '玻璃 - 打印',
                    },
                },
                laser_speed: {
                    text: '速度',
                    unit: 'mm/s',
                    fast: '快',
                    slow: '慢',
                    min: 1,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: '功率',
                    high: '强',
                    low: '弱',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: '墨水类型',
                    normal: '一般墨',
                    UV: 'UV 墨',
                },
                para_in_use: '此参数已在使用中。',
                do_not_adjust_default_para: '无法调整预设参数。',
                existing_name: '已存在此名称的自订参数。',
                presets: '预设',
            },
            object_panel: {
                zoom: '缩放',
                group: '群组',
                ungroup: '解散群组',
                distribute: '均分',
                hdist: '水平均分',
                vdist: '垂直均分',
                align: '对齐',
                left_align: '靠左对齐',
                center_align: '置中对齐',
                right_align: '靠右对齐',
                top_align: '顶端对齐',
                middle_align: '中线对齐',
                bottom_align: '底部对齐',
                boolean: '布林',
                union: '组合形状',
                subtract: '去除前面形状',
                intersect: '形状区域相交',
                difference: '排除重叠形状',
                flip: '翻转',
                hflip: '水平翻转',
                vflip: '垂直翻转',
                lock_aspect: '锁定长宽比',
                unlock_aspect: '解锁长宽比',
                option_panel: {
                    fill: '填充',
                    rounded_corner: '圆角',
                    sides: '边数',
                    font_family: '字体',
                    font_style: '字型',
                    font_size: '字级',
                    letter_spacing: '字距',
                    line_spacing: '行距',
                    vertical_text: '直书',
                    start_offset: '文本偏移',
                    vertical_align: '对齐',
                    text_infill: '文本填充',
                    path_infill: '路径填充',
                    shading: '渐层',
                    threshold: '曝光阈值',
                    threshold_short: '临界值',
                    stroke: '边框',
                    stroke_color: '边框颜色',
                    stroke_width: '边框宽度',
                    color: '颜色',
                },
                actions_panel: {
                    replace_with: '替换影像',
                    replace_with_short: '替换影像',
                    trace: '向量化',
                    grading: '曲线',
                    brightness: '亮度对比',
                    sharpen: '鋭化',
                    crop: '裁剪',
                    bevel: '生成斜角',
                    invert: '色彩反转',
                    weld_text: '合併文字',
                    convert_to_path: '转换为路径',
                    fetching_web_font: '取得线上字体中',
                    uploading_font_to_machine: '上传字体到机器中',
                    wait_for_parsing_font: '解析字体中...',
                    offset: '位移複製',
                    array: '阵列',
                    auto_arrange: '自动排列',
                    decompose_path: '解散非连续路径',
                    disassemble_use: '解散图档',
                    create_textpath: '建立路径文本',
                    create_textpath_short: '建立路径文本',
                    detach_path: '解散路径文本',
                    detach_path_short: '解散路径文本',
                    edit_path: '编辑路径',
                    disassembling: '解散中...',
                    ungrouping: '解散群组中...',
                    simplify: '路径优化',
                    ai_bg_removal: '智能去背',
                    ai_bg_removal_short: 'AI抠图',
                    ai_bg_removal_reminder: '按下按钮将立即使用 0.2 Credit，是否继续？',
                    outline: '勾勒轮廓',
                },
                path_edit_panel: {
                    node_type: '节点类型',
                    sharp: '锐利',
                    round: '平滑',
                    connect: '连接',
                    disconnect: '断开',
                    delete: '删除',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: '部分字体在不同系统间有差异，输出前请将字体转换成路径，以确保文本正确显示。转换文本至路径中...',
            retreive_image_data: '撷取影像资料中...',
            export_file_error_ask_for_upload: '汇出工作时发生错误，是否愿意上传工作场景回报错误给开发团队？',
        },
        image_trace_panel: {
            apply: '套用',
            back: '上一步',
            cancel: '取消',
            next: '下一步',
            brightness: '曝光',
            contrast: '对比',
            threshold: '临界值',
            okay: '完成',
            tuning: '描图参数',
        },
        photo_edit_panel: {
            apply: '套用',
            back: '上一步',
            cancel: '取消',
            next: '下一步',
            sharpen: '锐化',
            sharpness: '鋭化强度',
            radius: '鋭化半径',
            crop: '裁剪',
            aspect_ratio: '长宽比',
            original: '原图',
            free: '任意',
            curve: '曲线',
            start: '开始',
            processing: '处理中',
            invert: '色彩反转',
            okay: '完成',
            compare: '原图比较',
            phote_edit: '影像编辑',
            brightness_and_contrast: '亮度对比',
            brightness: '亮度',
            contrast: '对比',
        },
        document_panel: {
            document_settings: '文件设定',
            engrave_parameters: '雕刻参数',
            workarea: '工作范围',
            rotary_mode: '旋转轴',
            borderless_mode: '开盖模式',
            engrave_dpi: '雕刻分辨率',
            enable_diode: '二极管激光',
            enable_autofocus: '自动对焦',
            extend_workarea: '扩展工作区域',
            add_on: '扩充模组',
            low: '低',
            medium: '中',
            high: '高',
            ultra: '极高',
            enable: '啟用',
            disable: '关闭',
            cancel: '取消',
            save: '储存',
            notification: {
                changeFromPrintingWorkareaTitle: '您要将打印图层转换至雷射图层吗？',
            },
        },
        object_panels: {
            wait_for_parsing_font: '解析字体中...',
            text_to_path: {
                font_substitute_pop: '您的文本包含当前字体不支持的字符。<br/>您想使用『%s』来替换吗?',
                check_thumbnail_warning: '转换文字时出现字体不支援的情形，请在送出工作前，再次检查预览图确认文字是否如预期转换。',
                error_when_parsing_text: '将文本转换为路径时出错',
                use_current_font: '使用当前字体',
                retry: '请稍后重试或选择其他字体',
            },
            lock_desc: '缩放时固定比例 (SHIFT)',
        },
        tool_panels: {
            cancel: '取消',
            confirm: '确认',
            grid_array: '生成阵列',
            array_dimension: '阵列维度',
            rows: '列',
            columns: '行',
            array_interval: '阵列间隔',
            dx: '宽',
            dy: '高',
            offset: '偏移物件',
            nest: '排列最佳化',
            _offset: {
                direction: '偏移物件',
                inward: '向內',
                outward: '向外',
                dist: '偏移距离',
                corner_type: '边角',
                sharp: '尖角',
                round: '圆角',
                fail_message: '生成偏移物件失败',
                not_support_message: '选取物件中含有不支援的类型：\n图片、群组、文字、汇入图档。',
            },
            _nest: {
                start_nest: '开始排列',
                stop_nest: '停止排列',
                end: '结束',
                spacing: '间距',
                rotations: '旋转方向数距',
                no_element: '没有物件可以进行排列。',
            },
        },
        network_testing_panel: {
            network_testing: '网路检测',
            local_ip: '本机 IP 位置：',
            insert_ip: '目标 IP 位置：',
            empty_ip: '#818 请先输入目标 IP 位置',
            start: '检测',
            end: '結束',
            testing: '网路检测中...',
            invalid_ip: '#818 错误的 IP 位置',
            ip_startswith_169: '#843 目标 IP 开头为 169.254',
            connection_quality: '连接质量',
            average_response: '平均回覆时间',
            test_completed: '检测完成',
            test_fail: '检测失敗',
            cannot_connect_1: '#840 无法与目标 IP 建立连线',
            cannot_connect_2: '#840 无法与目标 IP 建立连线，请确认是否与目标 IP 在同一网路',
            network_unhealthy: '#841 连接质量 < 70 或平均回覆时间 > 100ms',
            device_not_on_list: '#842 列表上看不到机器，连接质量 > 70 且平均回覆时间 < 100ms',
            hint_device_often_on_list: '列表上经常找不到机器？',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/zh-tw/articles/360001841636',
            hint_connect_failed_when_sending_job: '送出工作时出现无法连接？',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/zh-tw/articles/360001841656',
            hint_connect_camera_timeout: '启动相机预览时超时？',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/zh-tw/articles/360001791895',
            cannot_get_local: '无法取得本地 IP 位置',
            fail_to_start_network_test: '#817 無法開始网路检测。',
            linux_permission_hint: '此问题通常是因为权限不足而发生，请在终端机以 "sudo beam-studio --no-sandbox" 以获得权​​限进行网路检测。',
        },
        layer_color_config_panel: {
            layer_color_config: '图层颜色参数设定',
            color: '颜色',
            power: '功率',
            speed: '速度',
            repeat: '执行次数',
            add: '新增',
            save: '储存',
            cancel: '取消',
            default: '回复预设',
            add_config: '新增颜色',
            in_use: '此颜色已在使用中。',
            no_input: '请输入颜色色码。',
            sure_to_reset: '您将会失去所有自订颜色参数，确定要回复到预设值？',
            sure_to_delete: '确定要删除这项颜色参数。',
        },
        rating_panel: {
            title: '喜欢 Beam Studio 吗？',
            description: '如果您喜欢 Beam Studio，请给我们评分，我们将不胜感激。',
            dont_show_again: '别再显示此提醒',
            thank_you: '谢谢您',
        },
        svg_editor: {
            unnsupported_file_type: 'Beam Studio 不直接支持此文件格式。请先输出成图片档或 SVG 格式',
            unable_to_fetch_clipboard_img: '无法读取复制连结中的档案',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: '播放',
            pause: '暫停',
            stop: '停止',
            play_speed: '播放速度',
            travel_path: '运行轨迹',
            invert: '色彩反转',
            preview_info: '预览资讯',
            size: '尺寸',
            estimated_time: '预估总时间',
            cut_time: '切割时间',
            rapid_time: '快速移动时间',
            cut_distance: '切割距离',
            rapid_distance: '快速移动距离',
            current_position: '当下位置',
            remark: '*所有资讯皆为预估数值，仅供参考。',
            start_here: '从这里开始',
            end_preview: '结束预览',
        },
        shapes_panel: {
            title: '元素',
            basic: '基本',
            shape: '形状',
            graphics: '图形',
            arrow: '箭头',
            label: '标签',
            decor: '装饰',
            circular: '圆形',
            corner: '角落',
            line: '线条',
            photo: '相框',
            ribbon: '丝带',
            speech: '对话框',
            text: '文本框',
            animals: '动物',
            birds: '鸟类',
            land: '陆地',
            sea: '海洋',
            holidays: '节日',
            celebration: '庆祝',
            CNY: '农历新年',
            easter: '复活节',
            halloween: '万圣节',
            valentines: '情人节',
            Xmas: '圣诞节',
            nature: '自然',
            elements: '元素',
            environment: '环境',
            plants: '植物',
            weather: '天气',
        },
        announcement_panel: {
            title: '公告',
            dont_show_again: '不再显示',
        },
    },
    editor: {
        prespray_area: '预喷区',
        opacity: '相机预览不透明度',
        exposure: '预览预览曝光值',
    },
    flux_id_login: {
        connection_fail: '#847 无法连接至 FLUX 会员中心。',
        login_success: '登入成功',
        login: '登入',
        unlock_shape_library: '登入使用百万图形资源',
        email: '电子信箱',
        password: '密码',
        remember_me: '记住我',
        forget_password: '忘记密码',
        register: '注册 FLUX 帐号',
        offline: '离线使用',
        work_offline: '我要离线使用',
        incorrect: '信箱或密码输入错误',
        not_verified: '信箱尚未认证。',
        new_to_flux: '还不是会员？免费注册新帐号',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: '探索 FLUX+ 计划',
            thank_you: '感谢您成为我们的重要会员!',
            ai_credit_tooltip: '用于 AI 抠图',
            flux_credit_tooltip: '用于 Design Market 和 AI 抠图',
            goto_member_center: '前往会员中心',
            access_plus_feature_1: '您正在访问一个',
            access_plus_feature_2: '功能。',
            access_plus_feature_note: '您必须是 FLUX+ 会员才能访问此功能。',
            access_monotype_feature: '您无法使用 Monotype 字体。',
            access_monotype_feature_note: '您必须是 FLUX+ Pro 会员或购买 Monotype 字体加购才能访问此功能。',
            learn_more: '了解更多',
            get_addon: '购买加购',
            subscribe_now: '立即订阅',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI 抠图',
                my_cloud: '云存储',
                boxgen: '3D 盒子生成器',
                dmkt: '1000+ 设计模板',
                monotype: '250+ 高级字体',
            },
        },
    },
    noun_project_panel: {
        login_first: '请先登入会员，完成后即可启用。',
        enjoy_shape_library: '新增百万图形资源，请尽情使用。',
        shapes: '形状',
        elements: '元素',
        recent: '最近使用',
        search: '搜寻',
        clear: '清除',
        export_svg_title: '无法汇出 SVG',
        export_svg_warning: '专案中含有受知识产权法律的保护的Noun Project 物件，因此汇出时Beam Studio 会自动帮您排除这类型之物件，您仍可以透过储存场景(.beam 档) 的方式保留您的专案，请问是否要继续汇出？',
        learn_more: '更多资讯',
    },
    change_logs: {
        change_log: '更改日志：',
        added: '新增：',
        fixed: '修正：',
        changed: '更改：',
        see_older_version: '查看之前版本',
        help_center_url: 'https://support.flux3dp.com/hc/zh-tw/sections/360000421876',
    },
    select_device: {
        select_device: '选择机器',
        auth_failure: '#811 认证失败',
        unable_to_connect: '#810 无法与机器创建稳定连接',
    },
    device: {
        pause: '暂停',
        paused: '已暂停',
        pausing: '正在暂停',
        select_printer: '选择成型机',
        retry: '重试',
        status: '状态',
        busy: '忙碌中',
        ready: '待命中',
        reset: '重设(kick)',
        abort: '取消工作',
        start: '开始',
        please_wait: '请稍待...',
        quit: '中断链接',
        completing: '完成中',
        aborted: '已终止',
        completed: '已完成',
        aborting: '取消工作中',
        starting: '启动中',
        preparing: '准备中',
        resuming: '恢复中',
        scanning: '扫描',
        occupied: '机器被占用',
        running: '工作中',
        uploading: '上传中',
        processing: '处理中',
        disconnectedError: {
            caption: '机器连接中断',
            message: '请确认 %s 的网络连接是否正常',
        },
        unknown: '未知状态',
        pausedFromError: '发生错误暂停',
        model_name: '型号',
        IP: 'IP',
        serial_number: '序号',
        firmware_version: '固件版本',
        UUID: 'UUID',
        select: '选择',
        deviceList: '机器列表',
        disable: '关闭',
        enable: '开启',
        submodule_type: '模块',
        cartridge_serial_number: '墨盒序号',
        ink_color: '墨水颜色',
        ink_type: '墨水类型',
        ink_level: '剩余墨水量',
        close_door_to_read_cartridge_info: '请关闭机器的门盖以访问墨盒信息。',
        cartridge_info_read_failed: '请确认墨盒完全插入。尝试将墨水取出并重新安装。',
        cartridge_info_verification_failed: '请确认您是否使用 FLUX 原厂墨盒。',
    },
    monitor: {
        monitor: 'MONITOR',
        go: '开始',
        resume: '繼續',
        start: '开始',
        pause: '暂停',
        stop: '停止',
        record: 'RECORD',
        camera: '相机',
        taskTab: '工作',
        connecting: '连接中，请稍候',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 主板没有回应。请联系 FLUX 客服。',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 子系统没有回应。请联系 FLUX 客服。',
        HARDWARE_ERROR_PUMP_ERROR: '#900 水冷未开，请联系客服 (02) 2651-3171',
        HARDWARE_ERROR_DOOR_OPENED: '#901 门盖开启，将门盖关上以继续',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 水温过高，请稍后再继续',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 底盖开启，将底盖关上以继续',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z轴归零失败',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 抽屉开启',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 火焰侦测异常',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 气流侦测异常',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 未侦测到旋转轴',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 归零 PULL-OFF 失败',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 检测到错误的模块。请安装正确的模块以继续。',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 未检测到模块。请确保模块已正确安装以继续。',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 检测到未知模块。请安装正确的模块以继续。',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 打印模块无响应。',
        USER_OPERATION_ROTARY_PAUSE: '请切换旋转轴马达开关',
        USER_OPERATION_REMOVE_CARTRIDGE: '请取出墨盒以继续。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 检测到错误的模块。请安装正确的模块以继续。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 未检测到模块。请确保模块已正确安装以继续。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 检测到未知模块。请安装正确的模块以继续。',
        USER_OPERATION_CHANGE_TOOLHEAD: '请安装正确的模块以继续。',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: '请插入墨盒以继续。',
        USER_OPERATION_CHANGE_CARTRIDGE: '请插入正确的墨盒以继续。',
        USER_OPERATION: '请遵循设备面板上的指示以继续。',
        RESOURCE_BUSY: '机器忙碌中\n如果机器没有在进行动作， 请重新启动机器',
        DEVICE_ERROR: '固件发生错误\n请重新启动机器',
        NO_RESPONSE: '#905 连接主板时发生错误\n请重新启动机器。',
        SUBSYSTEM_ERROR: '#402 子系统没有回应。请联系 FLUX 客服。',
        HARDWARE_FAILURE: '固件发生错误\n请重新启动机器',
        MAINBOARD_OFFLINE: '#905 连接主板时发生错误\n请重新启动机器。',
        bug_report: '下载错误回报档案',
        processing: '处理中',
        savingPreview: '正在产生预览图',
        hour: '小时',
        minute: '分',
        second: '秒',
        left: '完成',
        temperature: '温度',
        forceStop: '是否强制停止现在工作?',
        upload: '上传',
        download: '下载',
        relocate: '重新定位',
        cancel: '取消',
        prepareRelocate: '准备重新定位中',
        extensionNotSupported: '上传文件不支持此文件格式',
        fileExistContinue: '文件已存在，是否要覆盖？',
        confirmFileDelete: '是否确定要删除这个文件？',
        ask_reconnect: '与机器的连线中断，是否尝试重新连接？',
        task: {
            BEAMBOX: '激光雕刻',
            'N/A': '自由模式',
        },
    },
    alert: {
        caption: '错误',
        duplicated_preset_name: '重复的默认名称',
        info: '消息',
        warning: '警告',
        error: '错误',
        instruction: '提示',
        oops: '哎呀',
        retry: '重试',
        abort: '放弃',
        confirm: '确认',
        cancel: '取消',
        close: '关闭',
        ok: '确定',
        ok2: '好',
        yes: ' 是',
        no: '否',
        stop: '停止',
        save: '储存',
        dont_save: '不要储存',
        learn_more: '了解更多',
    },
    caption: {
        connectionTimeout: '连接逾时',
    },
    message: {
        cancelling: '取消中...',
        connecting: '连接中...',
        connectingMachine: '连接 %s 中...',
        tryingToConenctMachine: '连接机器中...',
        connected: '已连接',
        authenticating: '密码验证中...',
        enteringRawMode: '正在进入基本动作模式...',
        endingRawMode: '正在结束基本动作模式...',
        enteringLineCheckMode: '正在进入可靠传输模式...',
        endingLineCheckMode: '正在结束可靠传输模式...',
        exitingRotaryMode: '正在结束旋转轴模式...',
        turningOffFan: '正在关闭抽气风扇...',
        turningOffAirPump: '正在关闭空气帮浦...',
        gettingLaserSpeed: '正在取得雷射头移动速度...',
        settingLaserSpeed: '正在设定雷射头移动速度...',
        retrievingCameraOffset: '正在取得相机参数...',
        connectingCamera: '正在连接相机...',
        homing: '归零中...',
        connectionTimeout: '#805 连接机器逾时，请确认你的网路状态和机器的 Wi-Fi 讯号符号。',
        getProbePosition: '获取探针位置中...',
        device_not_found: {
            caption: '找不到默认机器',
            message: '#812 请确认默认机器的 Wi-Fi 指示灯，或取消设置默认机器',
        },
        device_busy: {
            caption: '机器忙碌中',
            message: '机器正在进行另外一项工作，请稍候再试。如果机器持续没有回应，请将机器重新启动。',
        },
        device_is_used: '机器正被使用中，是否要终止现在任务？',
        monitor_too_old: {
            caption: '固件需要更新',
            content: '#814 请按照<a target="_blank" href="http://helpcenter.flux3dp.com/hc/zh-tw/articles/216251077">此说明</a>安装最新固件版本',
        },
        unknown_error: '#821 无法与机器创建连接，请使用“功能表 > 说明 > 错误回报”',
        unknown_device: '#826 无法与机器创建连接，请确认 USB 有连接于机器',
        unsupport_osx_version: '目前系统版本 MacOS X %s 较旧，部分功能可能无法使用，请更新到 macOS 11+。',
        unsupport_win_version: '目前系统版本 %s 较旧，部分功能可能无法使用，请更新到最新版。',
        need_password: '需要密码与机器创建连接',
        unavailableWorkarea: '#804 目前设定的工作范围超过目标机器的工作范围。请确认选择的机器型号，或从 编辑 > 文件设定 更改工作范围。',
        please_enter_dpi: '请输入该文件的 dpi (in mm)',
        auth_error: '#820 认证失败：请将 Beam Studio 以及机器韧体更新至最新版。',
        usb_unplugged: 'USB 连接逾时，请确认与机器的连接',
        uploading_fcode: '正在上传 fcode',
        cant_connect_to_device: '#827 无法链接机器，请确认机器是否开启，以及与机器的链接方式',
        unable_to_find_machine: '无法连接到机器 ',
        disconnected: '连接不稳，请确认机器连接状况并稍后再试一次',
        unable_to_start: '#830 无法开始工作，如果持续发生，请附上错误回报，与我们联系:\n',
        camera: {
            camera_cable_unstable: '侦测到相机传输照片时不稳定，仍能正常进行相机预览，但可能会有预览速度较慢或超时的问题。<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/360001791895">了解更多</a>',
            fail_to_transmit_image: '#845 相机传输照片异常，请将机器重新开机。如果问题持续发生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/4402756056079">此则引导</a>。',
            ws_closed_unexpectly: '#844 与机器相机的连线无预期的中断。如果问题持续发生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/4402755805071">此则引导</a>。',
            continue_preview: '继续预览',
            abort_preview: '中断预览',
        },
        preview: {
            camera_preview: '相机预览',
            auto_focus: '自动对焦',
            auto_focus_instruction: '请将激光模块头移至物体上方，并按照动画指示按下AF进行对焦。',
            already_performed_auto_focus: '您已经执行了自动对焦，要使用现有数值吗？',
            please_enter_height: '请输入物体的高度，以拍摄精确的相机照片。',
            apply: '套用',
            enter_manually: '手动输入',
            adjust: '调整',
            adjust_height_tooltip: '单击复选框以启用编辑。',
        },
        unsupported_example_file: '所选示例文件不受当前工作区支持。',
        time_remaining: '剩余时间：',
    },
    machine_status: {
        '-17': '墨匣模式',
        '-10': '動作模式',
        '-2': '扫描中',
        '-1': '维护中',
        0: '待命中',
        1: '初始化',
        2: 'ST_TRANSFORM',
        4: '启动中',
        6: '回复中',
        16: '工作中',
        18: '回复中',
        32: '已暂停',
        36: '已暂停',
        38: '暂停中',
        48: '已暂停',
        50: '暂停中',
        64: '已完成',
        66: '完成中',
        68: '准备中',
        128: '已中断',
        256: 'Alarm',
        512: 'Fatal',
        UNKNOWN: '-',
    },
    calibration: {
        update_firmware_msg1: '您的固件版本不支援此功能。请先更新 Beambox 的固件至 v',
        update_firmware_msg2: '以上以继续。 (主选单 > 机器 > [ Your Beambox ] > 固件更新',
        camera_calibration: '相机校正',
        diode_calibration: '二极管激光模组校正',
        module_calibration_printer: '打印机模块校准',
        module_calibration_2w_ir: '红外模块校准',
        back: '上一步',
        next: '下一步',
        skip: '跳過',
        cancel: '取消',
        finish: '完成',
        do_engraving: '执行切割',
        start_engrave: '开始绘制校正图片',
        start_printing: '开始打印校正图片',
        ask_for_readjust: '是否需要跳过切割步骤，进行拍照及校正？',
        please_goto_beambox_first: '请先选择 Beambox 功能，再进行校正',
        please_place_paper: '请将干净 A4 白纸放在工作区域的左上角',
        please_place_paper_center: '请将干净 A4 白纸放在工作区的中央。',
        please_place_dark_colored_paper: '请将 A4 深色纸放置在工作区的中央。',
        please_refocus: {
            beambox: '请旋转升降平台旋钮，直到轻触焦距螺丝或焦距尺，完成对焦后，转回对焦尺。',
            beamo: '请转开焦距固定环，调整雷射头至平台轻触焦距尺，完成对焦后，旋紧固定环、转回对焦尺。',
            beamo_af: '请双击自动对焦套件侧边按钮，使探针轻触雕刻材料。',
            hexa: '请连续按压升降平台左侧按钮 2 次，使平台上移、自动对焦探针轻触雕刻材料。',
        },
        without_af: '无自动对焦',
        with_af: '有自动对焦',
        dx: '水平位移',
        dy: '垂直位移',
        rotation_angle: '旋转角度',
        x_ratio: '水平比例',
        y_ratio: '垂直比例',
        show_last_config: '显示前次校正结果',
        use_last_config: '汇入前次校正数值',
        taking_picture: '截取图片中...',
        analyze_result_fail: '校正失败<br/>请确认:<br/>1. 校正图片完整画在 A4 纸上<br/>2. 已旋转升降平台旋钮，直到轻触焦距螺丝，完成对焦',
        drawing_calibration_image: '绘制校正图片中...',
        calibrate_done: '校正相机完成！使用时请正确对焦以取得良好的预览效果。',
        calibrate_done_diode: '校正完成！二极管激光模组偏移值已自动储存。',
        hint_red_square: '请将红框对齐切割出来的方块',
        hint_adjust_parameters: '由这些参数来调整红框的位置，旋转与大小',
        zendesk_link: 'https://support.flux3dp.com/hc/zh-tw/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: '校正二极管激光需要使用相机校正参数，请确认您的机器已进行过相机校正。并请旋转升降平台旋钮，直到轻触焦距螺丝或焦距尺，完成对焦',
            beamo: '校正二极管激光需要使用相机校正参数，请确认您的机器已进行过相机校正。并请转开焦距固定环，调整雷射头至平台轻触焦距尺，完成对焦',
        },
        downloading_pictures: '正在下载图片...',
        failed_to_download_pictures: '#848 图片下载失败，请联系FLUX支持。',
        uploading_images: '正在上传图片...',
        calculating_camera_matrix: '正在计算相机矩阵...',
        calculating_regression_parameters: '正在计算回归参数...',
        failed_to_calibrate_camera: '#848 相机校准失败，请联系FLUX支持。',
        failed_to_save_calibration_results: '#849 保存校准结果失败，请重试。如果问题持续发生，请联系FLUX支持。',
        ador_autofocus_material: '在机器的主页面上长按“AF”图标3秒，并让探针轻轻触碰材料。',
        ador_autofocus_focusing_block: '在机器主页上按“AF”图标3秒钟，让探头触碰对焦块。',
        align_red_cross_cut: '请将红色十字的中心与切割十字对齐。',
        align_red_cross_print: '请将红色十字的中心与印刷十字对齐。',
        retake: '重拍照片',
        calibrate_camera_before_calibrate_modules: '请在校准模块之前进行相机校准。',
        check_checkpoint_data: '检查点数据',
        checking_checkpoint: '正在检查检查点数据...',
        found_checkpoint: '在您的设备上找到了检查点数据。您想从检查点恢复吗？',
        use_old_camera_parameter: '您要使用当前的相机镜头参数吗？',
        downloading_checkpoint: '下载检查点数据...',
        failed_to_parse_checkpoint: '无法解析检查点数据。',
        check_device_pictures: '检查设备图片',
        checking_pictures: '正在检查设备图片...',
        no_picutre_found: '#846 您的设备没有可用于校准的原始照片。请联系FLUX支持。',
        calibrating_with_device_pictures: '使用设备图片进行校准...',
        failed_to_calibrate_with_pictures: '#847 使用设备图片进行校准失败。',
        getting_plane_height: '获取平面高度...',
        preparing_to_take_picture: '准备拍照...',
        put_paper: '放置纸张',
        put_paper_step1: '请将A4或信纸大小的白纸放置在工作区的中心。',
        put_paper_step2: '固定纸的四个角以确保它平放。',
        put_paper_step3: '点击“开始绘制校正图片”。',
        solve_pnp_title: '对齐标记点',
        solve_pnp_step1: '请根据每个红色标记点的编号和预期位置对齐雕刻点。',
        solve_pnp_step2: '您可以按“重拍照片”重新对齐或手动调整标记位置。',
        align_olt: '位置：对齐左上角外侧的雕刻点。',
        align_ort: '位置：对齐右上角外侧的雕刻点。',
        align_olb: '位置：对齐左下角外侧的雕刻点。',
        align_orb: '位置：对齐右下角外侧的雕刻点。',
        align_ilt: '位置：对齐左上角内侧的雕刻点。',
        align_irt: '位置：对齐右上角内侧的雕刻点。',
        align_ilb: '位置：对齐左下角内侧的雕刻点。',
        align_irb: '位置：对齐右下角内侧的雕刻点。',
        elevate_and_cut: '抬升和裁剪',
        elevate_and_cut_step_1: '将一块A4大小的浅色木材放置在工作区中心，并将其抬高至20mm。',
        elevate_and_cut_step_1_prism_lift: '使用Ador Prism Lift，最大长度为14mm，搭配至少6mm厚度的木材。',
        with_prism_lift: '使用 Ador Prism Lift',
        without_prism_lift: '不使用 Ador Prism Lift',
        camera_parameter_saved_successfully: '相机参数保存成功。',
        failed_to_save_camera_parameter: '保存相机参数失败。',
        failed_to_solve_pnp: '无法解出相机位置。',
    },
    input_machine_password: {
        require_password: '"%s" 需要密码',
        connect: '连接',
        password: '密码',
    },
    tutorial: {
        skip: '跳过教学',
        welcome: '欢迎使用',
        suggest_calibrate_camera_first: '我们建议使用者在第一次使用机器时校正相机，并在每次使用时将平台对焦，以取得最好的效果。<br/>请问您要现在进行相机校正吗？<br/>（您也可以先跳过，并在之后从上方选单「机器」>「您的机器名称」>「校正相机」进行校正。）',
        camera_calibration_failed: '相机校正失败',
        ask_retry_calibration: '请问是否重新执行相机校正？',
        needNewUserTutorial: '请问您是否需要 Beam Studio 的教学？<br/>（您也可以先跳过，并在之后从「说明」>「显示新手教学」进行教学。）',
        needNewInterfaceTutorial: '请问是否需要为您介绍 Beam Studio 的新介面？ <br/>（您也可以先跳过，并在之后从「说明」>「显示介面介绍」进行介绍。）',
        next: '下一步',
        look_for_machine: '寻找机器中',
        unable_to_find_machine: '无法找到可用于新手教学的机器，是否进行要进行连线设定、重试或是跳过教学？',
        skip_tutorial: '已跳过新手教学，您可以在「说明」>「显示新手教学」再次启动新手教学。',
        set_connection: '连线设定',
        retry: '重试',
        newUser: {
            draw_a_circle: '画一个圆',
            drag_to_draw: '拖曳以生成图形',
            infill: '设定填充',
            switch_to_object_panel: '切换到物件面板',
            switch_to_layer_panel: '切换到图层面板',
            set_preset_wood_engraving: '設定參數：木板 - 刻印',
            set_preset_wood_cut: '設定參數：木板 - 切割',
            add_new_layer: '新增图层',
            draw_a_rect: '画一个方形',
            switch_to_preview_mode: '切换到相机预览模式',
            preview_the_platform: '预览工作平台',
            end_preview_mode: '结束相机预览模式',
            put_wood: '1. 放进木板',
            adjust_focus: '2. 调整焦距',
            close_cover: '3. 将门盖关上',
            send_the_file: '送出工作',
            end_alert: '请问您是否确定要结束教学？',
            please_select_wood_engraving: '请选择「木板 - 刻印」参数。',
            please_select_wood_cutting: '请选择「木板 - 切割」参数。',
        },
        newInterface: {
            camera_preview: '相机预览',
            select_image_text: '选取、图片、文字工具',
            basic_shapes: '基本几何形状',
            pen_tool: '钢笔工具',
            add_new_layer: '新增图层',
            rename_by_double_click: '双击以重新命名',
            drag_to_sort: '拖曳以排序',
            layer_controls: '右键点击以呼叫图层控制功能：\n复制、合并、锁定、删除图层',
            switch_between_layer_panel_and_object_panel: '在图层面板与物件面板间切换',
            align_controls: '排列控制项',
            group_controls: '群组、解散群组',
            shape_operation: '几何图形操作',
            flip: '翻转',
            object_actions: '物件操作',
            end_alert: '请问您是否确定要结束新介面介绍？',
            select_machine: '选择机器',
            start_work: '开始工作',
        },
        gesture: {
            pan: '双指平移让画布随着您的手指方向移动。',
            zoom: '双指靠拢以缩小，或双指分开来放大画布。',
            click: '点按以选择物件。',
            drag: '拖曳以多选物件。',
            hold: '按住一段时间打开选单。',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: '介绍完毕，开始创作吧！',
    },
    layer_module: {
        none: '无',
        general_laser: '激光',
        laser_10w_diode: '10W 二极管激光器',
        laser_20w_diode: '20W 二极管激光器',
        laser_2w_infrared: '2W 红外激光器',
        printing: '打印',
        unknown: '未知模块',
        notification: {
            convertFromPrintingModuleTitle: '您是否要将打印图层转换为激光图层？',
            convertFromPrintingModuleMsg: '请注意，如果您继续此操作，打印图层的颜色设置将会被删除，并根据当前图层参数而定。',
            convertFromLaserModuleTitle: '您是否要将激光图层转换为打印图层？',
            convertFromLaserModuleMsg: '请注意，如果您继续此操作，激光图层的设置将会被删除，并根据当前图层参数而定。',
            importedDocumentContainsPrinting: '文档包含打印图层，您想将工作区更改为Ador吗？',
            printingLayersCoverted: '打印图层已转换为激光图层。',
            performPrintingCaliTitle: '执行打印模块校准',
            performPrintingCaliMsg: '点击「确认」执行校准，或通过顶部菜单中的「机器」 > 「您的机器名称」 > 「校准打印模块」进行校准。',
            performIRCaliTitle: '执行红外模块校准',
            performIRCaliMsg: '点击「确认」执行校准，或通过顶部菜单中的「机器」 > 「您的机器名称」 > 「校准红外模块」进行校准。',
        },
        non_working_area: '非工作区域',
    },
    qr_code_generator: {
        title: '二维码生成器',
        placeholder: '输入链接或文本',
        preview: '预览',
        error_tolerance: '容错级别',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: '反转背景色',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: '基本箱体',
        coming_soon: '即将推出',
        workarea: '工作区',
        max_dimension_tooltip: '最大宽度/高度/深度设置为 %s。',
        volume: '体积',
        outer: '外部',
        inner: '内部',
        width: '宽度',
        height: '高度',
        depth: '深度',
        cover: '盖板',
        thickness: '厚度',
        add_option: '添加选项',
        joints: '拼接',
        finger: '指接榫',
        finger_warning: '盒子内侧长度需要至少6mm(0.24inch)，才能使用指接榫拼接。',
        tSlot: 'T 型槽',
        tSlot_warning: '盒子侧长需要至少30mm(1.18inch)，才能使用 T 型槽拼接。',
        edge: '边缘',
        tCount: 'T 数量',
        tCount_tooltip: 'T型槽数量适用于短边;长边的数量根据其长度计算。',
        tDiameter: 'T 直径',
        tLength: 'T 长度',
        continue_import: '继续导入',
        customize: '自定义',
        merge: '合并',
        text_label: '文本标签',
        beam_radius: '切口补偿',
        beam_radius_warning: '建议在盒子或榫接边较短时取消补偿，以确保盒子的接合。',
        import: '导入',
        cancel: '取消',
        reset: '重置',
        zoom: '缩放',
        control_tooltip: '左键旋转\n滚轮缩放\n右键平移',
        control_tooltip_touch: '拖动旋转\n捏合缩放\n双指平移',
    },
    my_cloud: {
        title: '我的云端',
        loading_file: '正在加载...',
        no_file_title: '保存文件到我的云端开始使用',
        no_file_subtitle: '转到菜单 > "档案" > "保存到云端"',
        file_limit: '免费文件',
        upgrade: '升级',
        sort: {
            most_recent: '最近',
            oldest: '最旧',
            a_to_z: '名称: A - Z',
            z_to_a: '名称: Z - A',
        },
        action: {
            open: '打开',
            rename: '重命名',
            duplicate: '复制',
            download: '下载',
            delete: '删除',
            confirmFileDelete: '确定要删除此文件吗?此操作无法撤消。',
        },
        save_file: {
            choose_action: '保存档案：',
            save: '覆盖旧档案',
            save_new: '另存新档',
            input_file_name: '另存新档为：',
            invalid_char: '无效字符:',
            storage_limit_exceeded: '您的云端储存空间已满。在储存新档案前，请先删除您不需要的档案。',
        },
    },
    camera_data_backup: {
        title: '相机数据备份',
        no_picture_found: '机器中未找到任何图片。',
        folder_not_exists: '所选文件夹不存在。',
        incorrect_folder: '相机数据上传失败。请检查您选择的文件夹是否正确。',
        downloading_data: '正在下载数据...',
        estimated_time_left: '预计剩余时间：',
        uploading_data: '正在上传数据...',
        download_success: '相机数据下载成功。',
        upload_success: '相机数据上传成功。',
    },
    insecure_websocket: {
        extension_detected: '检测到 Beam Studio Connect 扩展',
        extension_detected_description: '我们检测到您已安装了 Beam Studio Connect 扩展。请点击“确认”以重定向到 HTTPS，或点击“取消”以继续使用 HTTP。',
        extension_not_deteced: '无法检测到 Beam Studio Connect 扩展',
        extension_not_deteced_description: '要使用 HTTPS，请点击“确认”以安装 Beam Studio Connect 扩展。安装扩展后，请刷新页面以激活它。<br/>否则，请点击下面的链接查看如何在 Chrome 中使用 HTTP。',
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">链接</a>',
    },
    curve_engraving: {
        measure_audofocus_area: '测量自动对焦区域',
        amount: '数量',
        gap: '间距',
        rows: '行',
        coloumns: '列',
        row_gap: '行间距',
        column_gap: '列间距',
        set_object_height: '设置物件高度',
        set_object_height_desc: '测量物件的最大厚度。',
        measure_area_help: '如何选择3D曲线的自动对焦区域？',
        reselect_area: '重新选择区域',
        start_autofocus: '开始自动对焦',
        starting_measurement: '开始测量...',
        preview_3d_curve: '预览 3D 曲线',
        apply_arkwork: '将艺术作品应用于 3D 曲线',
        apply_camera: '将相机图像应用于 3D 曲线',
        click_to_select_point: '点击选择或取消选择要重新测量的点。',
        remeasure: '重新测量',
        remeasuring_points: '正在重新测量...',
    },
    pass_through: {
        title: '直通模式',
    },
};
export default lang;
