// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-myCloud-Head-module__head--AS1nt{display:flex;position:absolute;top:20px;right:40px}.src-web-app-components-dialogs-myCloud-Head-module__head--AS1nt .src-web-app-components-dialogs-myCloud-Head-module__select--Uz-lG .ant-select-selection-item{color:#1890ff}.src-web-app-components-dialogs-myCloud-Head-module__select-dropdown--Ked62 .ant-select-item-option-selected>div{color:#1890ff}.src-web-app-components-dialogs-myCloud-Head-module__tabs--zmwWa>div{border-bottom:0}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/myCloud/Head.module.scss","webpack://./src/web/styles/_variables.scss"],"names":[],"mappings":"AAEA,iEACE,YAAA,CACA,iBAAA,CACA,QAAA,CACA,UAAA,CAEE,+JACE,aCES,CDGf,iHACE,aCJa,CDOf,qEACE,eAAA","sourcesContent":["@use 'styles/variables' as variables;\n\n.head {\n  display: flex;\n  position: absolute;\n  top: 20px;\n  right: 40px;\n  .select {\n    :global(.ant-select-selection-item) {\n      color: variables.$primary-blue;\n    }\n  }\n}\n\n.select-dropdown :global(.ant-select-item-option-selected) > div {\n  color: variables.$primary-blue;\n}\n\n.tabs > div {\n  border-bottom: 0;\n}\n","$print-default-font-color: #4A4A4A;\n$default-button-border-radius: 4px;\n$size: 200px;\n$big-z-index: 9999;\n$primaryColor: #888;\n$backgroundColor: #F0F0F0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #E0E0E0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$secondary-gray: #7c7c7c;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"head": "src-web-app-components-dialogs-myCloud-Head-module__head--AS1nt",
	"select": "src-web-app-components-dialogs-myCloud-Head-module__select--Uz-lG",
	"select-dropdown": "src-web-app-components-dialogs-myCloud-Head-module__select-dropdown--Ked62",
	"tabs": "src-web-app-components-dialogs-myCloud-Head-module__tabs--zmwWa"
};
export default ___CSS_LOADER_EXPORT___;
