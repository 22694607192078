import React, { memo, useCallback, useContext } from 'react';
import { Button, Col, Space } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import Context from './Context';
import styles from './ArrowButtons.module.scss';
const ArrowButtons = () => {
    const { dispatch } = useContext(Context);
    const addPresetToUse = useCallback(() => dispatch({ type: 'add-preset' }), [dispatch]);
    const removePresetFromUse = useCallback(() => dispatch({ type: 'remove-preset' }), [dispatch]);
    return (React.createElement(Col, { span: 2 },
        React.createElement(Space, { align: "center", className: styles.space },
            React.createElement("div", null,
                React.createElement(Button, { className: styles.btn, size: "small", onClick: addPresetToUse },
                    React.createElement(RightOutlined, null)),
                React.createElement(Button, { className: styles.btn, size: "small", onClick: removePresetFromUse },
                    React.createElement(LeftOutlined, null))))));
};
export default memo(ArrowButtons);
