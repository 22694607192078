import * as React from 'react';
import Controls from 'app/components/settings/Control';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
import UnitInput from 'app/widgets/Unit-Input-v2';
import { getWorkarea } from 'app/constants/workarea-constants';
const Path = ({ selectedModel, defaultUnit, vectorSpeedConstraintOptions, precutSwitchOptions, loopCompensation, bladeRadius, precutX, precutY, updateBeamboxPreferenceChange, updateConfigChange, }) => {
    var _a;
    const { lang } = i18n;
    const workarea = getWorkarea(selectedModel);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.path),
        React.createElement(SelectControl, { id: "set-vector-speed-contraint", label: lang.settings.vector_speed_constraint, url: lang.settings.help_center_urls.vector_speed_constraint, options: vectorSpeedConstraintOptions, onChange: (e) => updateBeamboxPreferenceChange('vector_speed_contraint', e.target.value) }),
        React.createElement(Controls, { id: "set-loop-compensation", label: lang.settings.loop_compensation, url: lang.settings.help_center_urls.loop_compensation },
            React.createElement(UnitInput, { id: "loop-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: 20, defaultValue: Number(loopCompensation || '0') / 10, getValue: (val) => updateConfigChange('loop_compensation', Number(val) * 10), forceUsePropsUnit: true, className: { half: true } })),
        i18n.getActiveLang() === 'zh-cn' ? (React.createElement("div", null,
            React.createElement(Controls, { label: lang.settings.blade_radius },
                React.createElement(UnitInput, { id: "radius-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: 30, step: 0.01, defaultValue: bladeRadius || 0, getValue: (val) => updateBeamboxPreferenceChange('blade_radius', val), forceUsePropsUnit: true, className: { half: true } })),
            React.createElement(SelectControl, { id: "set-blade-precut", label: lang.settings.blade_precut_switch, options: precutSwitchOptions, onChange: (e) => updateBeamboxPreferenceChange('blade_precut', e.target.value) }),
            React.createElement(Controls, { label: lang.settings.blade_precut_position },
                React.createElement("span", { className: "font2", style: { marginRight: '10px' } }, "X"),
                React.createElement(UnitInput, { id: "precut-x-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: workarea.width, defaultValue: precutX || 0, getValue: (val) => updateBeamboxPreferenceChange('precut_x', val), forceUsePropsUnit: true, className: { half: true } }),
                React.createElement("span", { className: "font2", style: { marginRight: '10px' } }, "Y"),
                React.createElement(UnitInput, { id: "precut-y-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: (_a = workarea.displayHeight) !== null && _a !== void 0 ? _a : workarea.height, defaultValue: precutY || 0, getValue: (val) => updateBeamboxPreferenceChange('precut_y', val), forceUsePropsUnit: true, className: { half: true } })))) : null));
};
export default Path;
