var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, Spin } from 'antd';
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import ConnectionTypeIcons from 'app/icons/connection-type/ConnectionTypeIcons';
import deviceConstants from 'app/constants/device-constants';
import discover from 'helpers/api/discover';
import fileExportHelper from 'helpers/file-export-helper';
import i18n from 'helpers/i18n';
import TopBarController from 'app/views/beambox/TopBar/contexts/TopBarController';
import styles from './DeviceSelector.module.scss';
const DeviceSelector = ({ onSelect, onClose }) => {
    const [deviceList, setDeviceList] = useState([]);
    const selectedDevice = TopBarController.getSelectedDevice();
    const selectedKey = selectedDevice === null || selectedDevice === void 0 ? void 0 : selectedDevice.serial;
    const discoverer = useMemo(() => discover('device-selector', (discoverdDevices) => {
        const filteredDevices = discoverdDevices.filter((device) => device.serial !== 'XXXXXXXXXX');
        filteredDevices.sort((deviceA, deviceB) => {
            if (deviceA.serial === selectedKey && deviceB.serial !== selectedKey)
                return -1;
            if (deviceA.serial !== selectedKey && deviceB.serial === selectedKey)
                return 1;
            return deviceA.name.localeCompare(deviceB.name);
        });
        setDeviceList(filteredDevices);
    }), [selectedKey]);
    useEffect(() => () => {
        discoverer.removeListener('device-selector');
    }, [discoverer]);
    const status = i18n.lang.machine_status;
    const timeout = useRef(null);
    useEffect(() => {
        if (deviceList.length === 0) {
            timeout.current = setTimeout(() => {
                Alert.popUp({
                    caption: i18n.lang.alert.oops,
                    message: i18n.lang.device_selection.no_beambox,
                    buttonType: AlertConstants.CUSTOM_CANCEL,
                    buttonLabels: [i18n.lang.topbar.menu.add_new_machine],
                    onCancel: () => {
                        onSelect(null);
                        onClose();
                    },
                    callbacks: () => __awaiter(void 0, void 0, void 0, function* () {
                        onSelect(null);
                        onClose();
                        const res = yield fileExportHelper.toggleUnsavedChangedDialog();
                        if (res)
                            window.location.hash = '#initialize/connect/select-machine-model';
                    }),
                });
            }, 5000);
        }
        else {
            clearTimeout(timeout.current);
        }
        return () => clearTimeout(timeout.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceList.length]);
    const list = deviceList.length > 0 ? (deviceList.map((device) => {
        const statusText = status[device.st_id] || status.UNKNOWN;
        const statusColor = deviceConstants.statusColor[device.st_id] || 'grey';
        const connectionType = ['10.55.0.17', '10.55.0.1'].includes(device.ipaddr) ? 'USB' : 'Wifi';
        const Icon = ConnectionTypeIcons[connectionType];
        let progress = '';
        if (device.st_id === 16 && typeof device.st_prog === 'number') {
            progress = `${(device.st_prog * 100).toFixed(1)}%`;
        }
        return (React.createElement("li", { className: classNames({ [styles.selected]: selectedKey === device.serial }), key: device.uuid, onClick: () => {
                onSelect(device);
                onClose();
            }, "data-testid": device.serial },
            React.createElement("label", { className: styles.name }, device.name),
            React.createElement("label", { className: classNames(styles.status, styles[statusColor]) },
                statusText,
                " ",
                progress),
            React.createElement("label", { className: styles['connection-type'] },
                React.createElement(Icon, null))));
    })) : (React.createElement(Spin, { className: styles.spinner, indicator: React.createElement(LoadingOutlined, { className: styles.icon, spin: true }) }));
    return (React.createElement(Modal, { open: true, closable: false, centered: true, onCancel: () => {
            onSelect(deviceList.some((device) => device.serial === selectedKey) ? selectedDevice : null);
            onClose();
        }, width: 415, footer: null, title: i18n.lang.topbar.select_machine },
        React.createElement("div", { className: styles['device-list'] },
            React.createElement("ul", null, list))));
};
export default DeviceSelector;
