import classNames from 'classnames';
import React, { createContext, useMemo } from 'react';
import constant from 'app/actions/beambox/constant';
import FloatingPanel from 'app/widgets/FloatingPanel';
import isWeb from 'helpers/is-web';
import { useIsMobile } from 'helpers/system-helper';
import styles from './FullWindowPanel.module.scss';
export const FullWindowPanelContext = createContext({
    isMobile: false,
    isWindows: false,
    isDesktop: true,
});
const FullWindowPanel = ({ onClose, mobileTitle, renderMobileFixedContent, renderMobileContents, renderContents, }) => {
    const isMobile = useIsMobile();
    const isWindows = useMemo(() => window.os === 'Windows', []);
    const web = useMemo(() => isWeb(), []);
    if (isMobile)
        return (React.createElement(FullWindowPanelContext.Provider, { value: { isMobile, isWindows, isDesktop: !web } },
            React.createElement(FloatingPanel, { className: classNames(styles.container, {
                    [styles.windows]: isWindows,
                    [styles.desktop]: !web,
                }), anchors: [0, window.innerHeight - constant.titlebarHeight], title: mobileTitle, fixedContent: renderMobileFixedContent === null || renderMobileFixedContent === void 0 ? void 0 : renderMobileFixedContent(), onClose: onClose }, renderMobileContents === null || renderMobileContents === void 0 ? void 0 : renderMobileContents())));
    return (React.createElement(FullWindowPanelContext.Provider, { value: { isMobile, isWindows, isDesktop: !web } },
        React.createElement("div", { className: classNames(styles.container, {
                [styles.windows]: isWindows,
                [styles.desktop]: !web,
            }) }, renderContents === null || renderContents === void 0 ? void 0 : renderContents())));
};
export default FullWindowPanel;
