const lang = {
    general: {
        wait: 'Behandler, vent venligst',
        choose_folder: 'Vælg mappe',
    },
    buttons: {
        next: 'NÆSTE',
        back: 'TILBAGE',
        done: 'FÆRDIG',
        back_to_beam_studio: 'Tilbage til Beam Studio',
    },
    topbar: {
        untitled: 'Untitled',
        titles: {
            settings: 'Indstillinger',
        },
        export: 'EKSPORTER',
        preview: 'FORHÅNDSVISNING',
        preview_title: 'Forhåndsvisning',
        curve_engrave: '3D Kurve',
        task_preview: 'Opgaverammeforhåndsvisning',
        frame_task: 'Kørende ramme',
        borderless: '(ÅBEN NEDERST)',
        tag_names: {
            rect: 'Rektangel',
            ellipse: 'Oval',
            path: 'Sti',
            polygon: 'Polygon',
            image: 'Billede',
            text: 'Tekst',
            text_path: 'Tekst på sti',
            line: 'Linje',
            g: 'Gruppe',
            multi_select: 'Flere objekter',
            use: 'Importeret objekt',
            svg: 'SVG-objekt',
            dxf: 'DXF-objekt',
            no_selection: 'Ingen valg',
        },
        alerts: {
            start_preview_timeout: '#803 Timeout opstod ved start af forhåndsvisning. Genstart maskinen eller Beam Studio. Hvis fejlen fortsætter, se <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denne vejledning</a>.',
            fail_to_start_preview: '#803 Kunne ikke starte forhåndsvisning. Genstart maskinen eller Beam Studio. Hvis fejlen fortsætter, se <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denne vejledning</a>.',
            fail_to_connect_with_camera: '#803 Kunne ikke oprette forbindelse til maskinkamera. Genstart venligst din maskine eller Beam Studio. Hvis denne fejl fortsætter, skal du følge <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">denne vejledning</a>.',
            add_content_first: 'Tilføj venligst objekter først',
            headtype_mismatch: 'Forkert modul registreret. ',
            headtype_none: 'Modul ikke registreret. ',
            headtype_unknown: 'Ukendt modul registreret. ',
            install_correct_headtype: 'Installer venligst 10W/20W diode laser moduler korrekt for at aktivere lav laser til kørsel af ramme.',
            door_opened: 'Luk venligst døren for at aktivere lav laser til kørsel af ramme.',
            fail_to_get_door_status: 'Sørg venligst for at døren er lukket for at aktivere lav laser til kørsel af ramme.',
            QcleanScene: 'Vil du rydde tegningen?<br/>Dette vil også slette din angre-historik!',
            power_too_high: 'FOR HØJ EFFEKT',
            power_too_high_msg: 'Brug af lavere laserstyrke (under 70%) forlænger laserens levetid.\nIndtast "NOTERET" for at fortsætte.',
            power_too_high_confirm: 'NOTERET',
        },
        hint: {
            polygon: 'Tryk på + / - tasten for at øge / mindske sider.',
        },
        menu: {
            preferences: 'Indstillinger',
            hide: 'Skjul Beam Studio',
            hideothers: 'Skjul andre',
            service: 'Tjenester',
            quit: 'Afslut',
            window: 'Vindue',
            minimize: 'Minimer',
            close: 'Luk vindue',
            file: 'Fil',
            edit: 'Rediger',
            help: 'Hjælp',
            open: 'Åbn',
            samples: 'Eksempler',
            import_hello_beamo: 'Eksempel på beamo',
            import_hello_beambox: 'Eksempel på beambox',
            import_material_testing_old: 'Materiale graveringstest - Klassisk',
            import_material_testing_simple_cut: 'Materiale skæretest - Enkel',
            import_material_testing_cut: 'Materiale skæretest',
            import_material_testing_engrave: 'Materiale graveringstest',
            import_material_testing_line: 'Materiale linjetest',
            import_material_printing_test: 'Materiale print test',
            import_ador_laser_example: 'Eksempel på Ador Laser',
            import_ador_printing_example_single: 'Eksempel på Ador Print - Enkelt farve',
            import_ador_printing_example_full: 'Eksempel på Ador Print - Fuld farve',
            import_acrylic_focus_probe: 'Akryl fokusprobe - 3mm',
            export_to: 'Eksportér til...',
            export_flux_task: 'FLUX opgave',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Gem',
            save_as: 'Gem som...',
            save_to_cloud: 'Gem i skyen',
            about_beam_studio: 'Om Beam Studio',
            undo: 'Fortryd',
            redo: 'Gendan',
            cut: 'Klip',
            copy: 'Kopiér',
            paste: 'Indsæt',
            paste_in_place: 'Indsæt på plads',
            group: 'Gruppér',
            ungroup: 'Opdel gruppe',
            delete: 'Slet',
            duplicate: 'Duplikér',
            offset: 'Forskyd',
            scale: 'Skalér',
            rotate: 'Rotér',
            reset: 'Nulstil',
            align_center: 'Centrér',
            photo_edit: 'Billede',
            svg_edit: 'SVG',
            path: 'Sti',
            decompose_path: 'Dekomponer',
            object: 'Objekt',
            layer_setting: 'Lag',
            layer_color_config: 'Farveindstillinger',
            image_sharpen: 'Skarp',
            image_crop: 'Beskær',
            image_invert: 'Inverter',
            image_stamp: 'Stempel',
            image_vectorize: 'Spor',
            image_curve: 'Kurve',
            align_to_edges: 'Fastgør til hjørner',
            document_setting: 'Dokumentindstillinger',
            document_setting_short: 'Dokument',
            clear_scene: 'Nye filer',
            machines: 'Maskiner',
            add_new_machine: 'Maskinopsætning',
            help_center: 'Hjælpecenter',
            show_gesture_tutorial: 'Introduktion til håndbevægelser',
            show_start_tutorial: 'Vis startvejledning',
            show_ui_intro: 'Vis grænsefladeintroduktion',
            questionnaire: 'Feedbackskema',
            change_logs: 'Ændringslog',
            contact: 'Kontakt os',
            tutorial: 'Start vejledning til Delta Family Printing',
            design_market: 'Designmarked',
            forum: 'Community-forum',
            software_update: 'Softwareopdatering',
            bug_report: 'Fejlrapport',
            dashboard: 'Instrumentbræt',
            machine_info: 'Maskininfo',
            network_testing: 'Test netværksindstillinger',
            commands: 'Kommandoer',
            update_firmware: 'Opdater firmware',
            using_beam_studio_api: 'Brug Beam Studio API',
            set_as_default: 'Sæt som standard',
            calibrate_beambox_camera: 'Kalibrer kamera',
            calibrate_printer_module: 'Kalibrer printmodul',
            calibrate_ir_module: 'Kalibrer infrarød modul',
            calibrate_beambox_camera_borderless: 'Kalibrer kamera (åben bund)',
            calibrate_diode_module: 'Kalibrer diode lasermodul',
            manage_account: 'Administrer min konto',
            sign_in: 'Log ind',
            sign_out: 'Log ud',
            account: 'Konto',
            my_account: 'Min konto',
            download_log: 'Download logs',
            download_log_canceled: 'Logdownload annulleret',
            download_log_error: 'Der opstod en ukendt fejl, prøv igen senere',
            keyboard_shortcuts: 'Tastaturgenveje',
            log: {
                network: 'Netværk',
                hardware: 'Hardware',
                discover: 'Opdag',
                usb: 'USB',
                usblist: 'USB-liste',
                camera: 'Kamera',
                cloud: 'Sky',
                player: 'Afspiller',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Vis',
            zoom_in: 'Zoom ind',
            zoom_out: 'Zoom ud',
            fit_to_window: 'Tilpas til vinduestørrelse',
            zoom_with_window: 'Auto tilpas til vinduestørrelse',
            borderless_mode: 'Rammeløs tilstand',
            show_grids: 'Vis gitter',
            show_rulers: 'Vis linealer',
            show_layer_color: 'Brug lagfarve',
            anti_aliasing: 'Anti-aliasing',
            disassemble_use: 'Afmonter',
        },
        select_machine: 'Vælg en maskine',
    },
    support: {
        no_webgl: 'WebGL understøttes ikke. Brug venligst andre enheder.',
        no_vcredist: 'Installer venligst Visual C++ Redistributable 2015<br/>Det kan downloades på flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Der opstod en ukendt fejl. Genstart venligst Beam Studio og maskinen.',
        OPERATION_ERROR: '[OE] Der opstod en statuskonflikt, prøv handlingen igen.',
        SUBSYSTEM_ERROR: '[SE] Fejl, da maskinfirmwaren udførte opgaven. Genstart maskinen.',
        UNKNOWN_COMMAND: '[UC] Opdater venligst enhedsfirmwaren',
    },
    device_selection: {
        no_beambox: '#801 Vi kan ikke finde din maskine på netværket.\nFølg venligst <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guiden</a> for at finde fejlen i forbindelsen.',
        no_device_web: "#801 Tjek venligst status for din maskine, eller klik på 'Maskinopsætning' nedenfor for at opsætte maskinen.",
    },
    update: {
        update: 'Opdater',
        release_note: 'Frigivelsesnote:',
        firmware: {
            caption: 'En firmwareopdatering til maskinen er tilgængelig',
            message_pattern_1: "'%s' er nu klar til firmwareopdatering.",
            message_pattern_2: '%s Firmware v%s er nu tilgængelig - Du har v%s.',
            latest_firmware: {
                caption: 'Maskin firmwareopdatering',
                message: 'Du har den nyeste maskin firmware',
                still_update: 'OPDATER',
                cant_get_latest: 'Kunne ikke hente information om den nyeste firmware.',
            },
            confirm: 'UPLOAD',
            upload_file: 'Firmware upload (*.bin / *.fxfw)',
            update_success: 'Firmwareopdateringen er uploadet',
            update_fail: '#822 Opdatering mislykkedes',
            too_old_for_web: 'Din nuværende maskin firmwareversion er v%s.\nHvis du vil bruge den online version af Beam Studio skal du opdatere maskin firmwaren til den nyeste version.',
            force_update_message: '#814 Opdater venligst din maskine til den nyeste firmwareversion.',
            firmware_too_old_update_by_sdcard: 'Firmwareversionen er for gammel. Opdater venligst firmwaren ved hjælp af et SD-kort.',
        },
        software: {
            checking: 'Søger efter opdateringer',
            switch_version: 'Skift version',
            check_update: 'Søg efter opdateringer',
            caption: 'En softwareopdatering til Beam Studio er tilgængelig',
            downloading: 'Downloader opdateringer i baggrunden, du kan klikke "OK" for at fortsætte dit arbejde.',
            install_or_not: 'er klar til opdatering. Vil du genstarte og opdatere nu?',
            switch_or_not: 'er klar til skift. Vil du genstarte og skifte nu?',
            available_update: 'Beam Studio v%s er tilgængelig nu. Du har v%s. Vil du downloade opdateringen?',
            available_switch: 'Beam Studio v%s er tilgængelig nu. Du har v%s. Vil du skifte til denne version?',
            not_found: 'Du bruger den nyeste version af Beam Studio.',
            no_response: 'Kunne ikke oprette forbindelse til serveren, tjek venligst dine netværksindstillinger.',
            switch_version_not_found: 'Skiftbar version ikke fundet.',
            yes: 'Ja',
            no: 'Nej',
            update_for_ador: 'Den nuværende softwareversion %s er ikke kompatibel, download venligst den nyeste version af Beam Studio til Ador.',
        },
        updating: 'Opdaterer...',
        skip: 'Spring denne version over',
        preparing: 'Forbereder...',
        later: 'SENERE',
        download: 'ONLINE OPDATERING',
        cannot_reach_internet: '#823 Server er utilgængelig<br/>Tjek venligst internetforbindelsen',
        install: 'INSTALLER',
        upload: 'UPLOAD',
    },
    topmenu: {
        version: 'Version',
        credit: 'Beam Studio er muliggjort af <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> open source projektet og anden <a target="_blank" href="https://flux3dp.com/credits/">open source software</a>.',
        ok: 'OK',
        file: {
            label: 'Fil',
            import: 'Importer',
            save_fcode: 'Eksporter FLUX opgave',
            save_scene: 'Gem scene',
            save_svg: 'Eksporter SVG',
            save_png: 'Eksporter PNG',
            save_jpg: 'Eksporter JPG',
            converting: 'Konverterer til billede...',
            all_files: 'Alle filer',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio Scene',
            fcode_files: 'FLUX Code',
            clear_recent: 'Ryd Senest Åbnet',
            path_not_exit: 'Denne sti findes ikke længere på disken.',
        },
        device: {
            download_log_canceled: 'Log download annulleret',
            download_log_error: 'Der opstod en ukendt fejl, prøv igen senere',
            log: {
                usblist: 'USB Liste',
            },
            network_test: 'Test Netværk',
        },
    },
    initialize: {
        next: 'Næste',
        start: 'Start',
        skip: 'Spring over',
        cancel: 'Annuller',
        confirm: 'Bekræft',
        connect: 'Forbind',
        back: 'Tilbage',
        retry: 'Prøv igen',
        no_machine: 'Jeg har ikke en maskine lige nu.',
        select_language: 'Vælg sprog',
        select_machine_type: 'Vælg din model',
        select_connection_type: 'Hvordan vil du forbinde?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Ledningsnetværk',
            ether2ether: 'Direkte forbindelse',
            usb: 'USB-forbindelse',
        },
        connect_wifi: {
            title: 'Forbinder til Wi-Fi',
            tutorial1: '1. Gå til berøringsskærmen > Klik på "Netværk" > "Opret forbindelse til WiFi".',
            tutorial1_ador: '1. Gå til berøringspanelet > Klik på "MASKINE" > Klik på "Netværk" > "Opret forbindelse til WiFi".',
            tutorial2: '2. Vælg og opret forbindelse til dit foretrukne Wi-Fi.',
            what_if_1: 'Hvad nu hvis jeg ikke kan se mit Wi-Fi?',
            what_if_1_content: '1. Wi-Fi krypteringsmetoden bør være WPA2 eller ingen adgangskode.\n2. Krypteringsmetoden kan indstilles i Wi-Fi routeradministrationsgrænsefladen. Hvis routeren ikke understøtter WPA2, og du har brug for hjælp til at vælge den rigtige router, kontakt venligst FLUX Support.',
            what_if_2: 'Hvad nu, hvis jeg ikke ser noget Wi-Fi?',
            what_if_2_content: '1. Sørg for, at Wi-Fi-donglen er helt isat.\n2. Hvis der ikke er nogen MAC-adresse på det trådløse netværk på touchskærmen, kontakt venligst FLUX Support.\n3. Wi-Fi-kanalen bør være 2.4Ghz (5Ghz understøttes ikke).',
        },
        connect_wired: {
            title: 'Forbindelse til kablet netværk',
            tutorial1: '1. Forbind maskinen med din router.',
            tutorial2: '2. Tryk på "Netværk" for at få den kablede netværks-IP.',
            tutorial2_ador: '2. Tryk på "MASKINE" > "Netværk" for at få den kablede netværks-IP.',
            what_if_1: "Hvad nu hvis IP'en er tom?",
            what_if_1_content: '1. Sørg for, at Ethernet-kablet er helt isat.\n2. Hvis der ikke er nogen MAC-adresse på det kablede netværk på touchskærmen, kontakt venligst FLUX Support.',
            what_if_2: "Hvad nu hvis IP'en starter med 169?",
            what_if_2_content: '1. Hvis IP-adressen starter med 169.254, bør det være et DHCP-indstillingsproblem, kontakt venligst din ISP for yderligere hjælp.\n2. Hvis din computer opretter forbindelse til internettet direkte ved hjælp af PPPoE, skal du ændre til at bruge routeren til at oprette forbindelse ved hjælp af PPPoE og aktivere DHCP-funktionen i routeren.',
        },
        connect_ethernet: {
            title: 'Direkte forbindelse',
            tutorial1: '1. Forbind maskinen med din computer med et ethernetkabel.',
            tutorial2_1: '2. Følg ',
            tutorial2_a_text: 'denne vejledning',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' for at gøre din computer til en router.',
            tutorial3: '3. Klik på Næste.',
        },
        connect_usb: {
            title: 'USB-forbindelse',
            title_sub: ' (HEXA Only)',
            tutorial1: '1. Forbind maskinen med din computer med et USB-kabel.',
            tutorial2: '2. Klik på Næste.',
            turn_off_machine: 'Sluk maskinen.',
            turn_on_machine: 'Tænd maskinen.',
            wait_for_turning_on: 'Klik på "Næste", når opstartsprocessen er færdig, og du er kommet ind på hovedskærmen.',
        },
        connect_machine_ip: {
            check_usb: 'Kontroller USB-forbindelse',
            enter_ip: 'Indtast din maskines IP',
            check_ip: 'Kontrollerer IP-tilgængelighed',
            invalid_ip: 'Ugyldig IP: ',
            invalid_format: 'Ugyldigt format',
            starts_with_169254: 'Starter med 169.254',
            unreachable: 'IP utilgængelig',
            check_connection: 'Kontrollerer maskinforbindelse',
            check_firmware: 'Kontrollerer firmware version',
            check_camera: 'Kontrollerer kamera tilgængelighed',
            retry: 'Prøv igen',
            succeeded_message: 'Forbindelse oprettet 🎉',
            finish_setting: 'Start med at oprette!',
        },
        connecting: 'Forbinder...',
        setting_completed: {
            start: 'Start',
            great: 'Velkommen til Beam Studio',
            setup_later: 'Du kan altid opsætte din maskine fra titellinjen > "Maskiner" > "Maskinopsætning"',
            back: 'Tilbage',
            ok: 'START MED AT OPRETTE',
        },
    },
    error_pages: {
        screen_size: 'Bemærk venligst at Beam Studio muligvis ikke fungerer optimalt på din enhed. For en optimal oplevelse bør det være en enhed med en skærm bredde på mindst 1024 pixels.',
    },
    menu: {
        mm: 'mm',
        inches: 'Tommer',
    },
    settings: {
        on: 'Til',
        off: 'Fra',
        low: 'Lav',
        high: 'Normal',
        caption: 'Indstillinger',
        tabs: {
            general: 'Generelt',
            device: 'Maskine',
        },
        ip: 'Maskinens IP-adresse',
        guess_poke: 'Søg efter maskinens IP-adresse',
        auto_connect: 'Vælg automatisk den eneste maskine',
        wrong_ip_format: 'Forkert IP-format',
        default_machine: 'Standardmaskine',
        default_machine_button: 'Tom',
        remove_default_machine_button: 'Fjern',
        confirm_remove_default: 'Standardmaskinen vil blive fjernet.',
        reset: 'Nulstil Beam Studio',
        reset_now: 'Nulstil Beam Studio',
        confirm_reset: 'Bekræft nulstilling af Beam Studio?',
        language: 'Sprog',
        notifications: 'Skrivebordspåmindelser',
        check_updates: 'Automatisk kontrol',
        autosave_enabled: 'Auto-gem',
        autosave_path: 'Auto-gem placering',
        autosave_interval: 'Gem hver',
        autosave_number: 'Antal auto-gem',
        autosave_path_not_correct: 'Angivet sti ikke fundet.',
        preview_movement_speed: 'Eksempel på bevægelses hastighed',
        preview_movement_speed_hl: 'Eksempel på bevægelses hastighed (diode laser aktiveret)',
        default_units: 'Standardenheder',
        default_font_family: 'Standard skrifttype',
        default_font_style: 'Standard skrifttypestil',
        fast_gradient: 'Hastighedsoptimering',
        engraving_direction: 'Retning',
        top_down: 'Top-ned',
        bottom_up: 'Bund-op',
        vector_speed_constraint: 'Hastighedsgrænse (20mm/s)',
        loop_compensation: 'Sløjfekompensation',
        blade_radius: 'Bladradius',
        blade_precut_switch: 'Blad forskæring',
        blade_precut_position: 'Forskæringsposition',
        default_beambox_model: 'Standard dokumentindstilling',
        guides_origin: 'Retningslinjeoprindelse',
        guides: 'Retningslinjer',
        image_downsampling: 'Bitmap eksempelkvalitet',
        anti_aliasing: 'Kantudjævning',
        continuous_drawing: 'Kontinuerlig tegning',
        trace_output: 'Billede Spor Output',
        single_object: 'Enkelt Objekt',
        grouped_objects: 'Grupperede Objekter',
        simplify_clipper_path: 'Optimer den Beregnede Sti',
        enable_low_speed: 'Aktivér langsom hastighed bevægelse',
        enable_custom_backlash: 'Aktivér Brugerdefineret Backlash Kompensation',
        custom_preview_height: 'Brugerdefineret Forhåndsvisningshøjde',
        mask: 'Arbejdsområde Beskæring',
        text_path_calc_optimization: 'Stiberegning Optimering',
        font_substitute: 'Erstat Ikke-understøttede Tegn',
        font_convert: 'Tekst til sti konverter',
        default_borderless_mode: 'Åbn Bund Standard',
        default_enable_autofocus_module: 'Autofokus Standard',
        default_enable_diode_module: 'Diode Laser Standard',
        diode_offset: 'Diode Laser Forskydning',
        autofocus_offset: 'Autofokus Forskydning',
        diode_one_way_engraving: 'Diode Laser Envejs Gravering',
        diode_two_way_warning: 'Tovejs lysudsendelse er hurtigere og genererer sandsynligvis nogen unøjagtighed på lasergraveringsposition. Anbefaler at teste først.',
        share_with_flux: 'Del Beam Studio Analytics',
        none: 'Ingen',
        close: 'Luk',
        enabled: 'Aktiveret',
        disabled: 'Deaktiveret',
        cancel: 'Annuller',
        done: 'Anvend',
        module_offset_10w: '10W diodelaser forskydning',
        module_offset_20w: '20W diodelaser forskydning',
        module_offset_printer: 'Printer forskydning',
        module_offset_2w_ir: '2W infrarød laserforskydning',
        printer_advanced_mode: 'Avanceret printer tilstand',
        default_laser_module: 'Standard lasermodul',
        low_laser_for_preview: 'Laser til Kørende Ramme',
        groups: {
            general: 'Generelt',
            update: 'Softwareopdateringer',
            connection: 'Forbindelse',
            autosave: 'Autosave',
            camera: 'Kamera',
            editor: 'Editor',
            engraving: 'Rastering (Scanning)',
            path: 'Vector (Outlines)',
            mask: 'Workarea Clipping',
            text_to_path: 'Text',
            modules: 'Add-on',
            ador_modules: 'Ador moduler',
            privacy: 'Privacy',
        },
        notification_on: 'On',
        notification_off: 'Off',
        update_latest: 'Latest',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-US/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/en-us/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Gruppe',
            use: 'Importer SVG',
            image: 'Billede',
            text: 'Tekst',
        },
        context_menu: {
            cut: 'Klip',
            copy: 'Kopier',
            paste: 'Indsæt',
            paste_in_place: 'Indsæt på plads',
            duplicate: 'Dupliker',
            delete: 'Slet',
            group: 'Grupper',
            ungroup: 'Del gruppe',
            move_front: 'Bring til forgrund',
            move_up: 'Bring frem',
            move_down: 'Send bagud',
            move_back: 'Send til bagende',
        },
        popup: {
            select_import_method: 'Vælg lagdelingsstil:',
            select_import_module: 'Vælg modul:',
            touchpad: 'Touchpad',
            mouse: 'Mus',
            layer_by_layer: 'Lag',
            layer_by_color: 'Farve',
            nolayer: 'Enkelt lag',
            loading_image: 'Indlæser billede, vent venligst...',
            no_support_text: 'Beam Studio understøtter ikke tekst-tag i øjeblikket. Konvertér tekst til sti før import.',
            speed_too_high_lower_the_quality: 'At bruge så høj hastighed ved denne opløsning kan resultere i ringere kvalitet af skyggegravering.',
            both_power_and_speed_too_high: 'At bruge lavere laserstyrke forlænger laserens levetid. Desuden kan for høj hastighed ved denne opløsning resultere i ringere skyggegraveringskvalitet.',
            too_fast_for_path: 'At bruge for høj hastighed i lag, der indeholder stivektor-objekter, kan resultere i ringere præcision ved skæring. Vi anbefaler ikke at bruge hastigheder over 20 mm/sek. ved skæring.',
            too_fast_for_path_and_constrain: 'Følgende lag: %s indeholder stivektor-stibaner og har hastigheder over 20 mm/sek. Skærehastigheden for stivektor-stibaner vil blive begrænset til 20 mm/sek. Du kan fjerne denne begrænsning under Indstillinger.',
            should_update_firmware_to_continue: '#814 Din firmware understøtter ikke denne version af Beam Studio. Opdater venligst firmwaren for at fortsætte. (Menu > Maskine > [Din maskine] > Opdater firmware)',
            recommend_downgrade_software: 'Vi registrerede en ældre firmwareversion. Vi arbejder aktivt på kompatibilitetsproblemer, men foreløbig anbefaler vi at vende tilbage til Beam Studio 1.9.5.',
            recommend_upgrade_firmware: 'Vi registrerede en ældre firmwareversion. Vi arbejder aktivt på kompatibilitetsproblemer, men foreløbig anbefaler vi at opdatere til den nyeste firmware.',
            still_continue: 'Fortsæt',
            more_than_two_object: 'For mange objekter. Understøtter kun 2 objekter',
            not_support_object_type: 'Understøtter ikke objekttypen',
            select_first: 'Vælg et objekt først.',
            select_at_least_two: 'Vælg to objekter for at fortsætte',
            import_file_contain_invalid_path: '#808 Importeret SVG-fil indeholder ugyldig billedsti. Sørg for, at alle billedfiler findes, eller indsæt billede i filen',
            import_file_error_ask_for_upload: 'Kunne ikke importere SVG-fil. Er du villig til at give filen til udviklingsholdet til fejlrapport?',
            upload_file_too_large: '#819 Filen er for stor til upload.',
            successfully_uploaded: 'Upload af fil lykkedes.',
            upload_failed: '#819 Upload af fil mislykkedes.',
            or_turn_off_borderless_mode: ' Eller slå bundløs tilstand fra.',
            svg_1_1_waring: 'Versionen af denne SVG-fil er v 1.1, der kan være potentielle kompatibilitetsproblemer.',
            svg_image_path_waring: 'Denne SVG-fil indeholder <image>, der indlæser fra filstien. Dette kan medføre fejl ved indlæsning. \nFor at undgå denne risiko skal du venligst bruge integreret billede ved eksport af SVG.',
            dxf_version_waring: 'Versionen af denne DXF-fil er ikke 2013, der kan være potentielle kompatibilitetsproblemer.',
            dont_show_again: 'Vis ikke dette næste gang.',
            convert_to_path_fail: 'Kunne ikke konvertere til sti.',
            save_unsave_changed: 'Vil du gemme ikke-gemte ændringer?',
            dxf_bounding_box_size_over: 'Tegningsstørrelsen er uden for arbejdsområdet. Flyt venligst din tegning tættere på oprindelsen i dit CAD-software, eller sørg for, at enheden er indstillet korrekt.',
            progress: {
                uploading: 'Uploader',
                calculating: 'Beregner',
            },
            backend_connect_failed_ask_to_upload: '#802 Fejl opstår hele tiden, når der forsøges forbindelse til backend. Vil du uploade din fejlrapportlog?',
            backend_error_hint: 'Funktioner fungerer muligvis ikke korrekt på grund af backend-fejl.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Fejl ved konvertering af PDF til SVG:',
                error_pdf2svg_not_found: '#825 Fejl: Kommandoen pdf2svg blev ikke fundet. Installer venligst pdf2svg med din pakkehåndtering (f.eks. "yum install pdf2svg" eller "apt-get install pdf2svg").',
            },
            ungroup_use: 'Dette vil opdele importerede DXF eller SVG filer. Fordi filen kan indeholde et stort antal elementer, kan det tage tid at opdele. Er du sikker på at fortsætte?',
            vectorize_shading_image: 'Gradientbilleder tager længere tid at spore og er tilbøjelige til støj. Slå venligst billedgradienten fra, før du fortsætter.',
            change_workarea_before_preview: 'Arbejdsområdet for %s matcher ikke det aktuelt indstillede arbejdsområde. Vil du skifte det aktuelle arbejdsområde?',
            bug_report: 'Fejlrapport',
            sentry: {
                title: 'Lad os gøre Beam Studio bedre sammen',
                message: 'Accepterer du automatisk at uploade relevante oplysninger til udviklingsholdet, når der opstår fejl?',
            },
            questionnaire: {
                caption: 'Hjælp os med at udfylde spørgeskemaet',
                message: 'Hjælp os med at udfylde spørgeskemaet for at gøre Beam Studio bedre.',
                unable_to_get_url: 'Kunne ikke hente linket til spørgeskemaet via internettet. Bekræft venligst din internetforbindelse.',
                no_questionnaire_available: 'Der er ikke noget spørgeskema tilgængeligt lige nu.',
            },
            facebook_group_invitation: {
                title: 'Tilmeld FLUX officiel brugergruppe',
                message: 'Tilmeld vores officielle Facebook-gruppe for at oprette forbindelse med andre FLUX-laserbrugere, diskutere FLUX-lasere, dele laserkunst og holde dig opdateret med de seneste opdateringer på vores produkter. Vi glæder os til at se dig der!',
                join_now: 'Tæl mig med',
                later: 'Måske senere',
                already_joined: 'Allerede tilmeldt',
                dont_show_again: 'Vis ikke dette igen',
            },
            ai_credit: {
                relogin_to_use: 'Log venligst ind igen for at bruge denne funktion',
                insufficient_credit: 'Du er løbet tør for kredit',
                insufficient_credit_msg: 'Du kan ikke bruge %s. Gå til medlemscenteret og køb AI-kredit',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Gå til',
            },
            text_to_path: {
                caption: 'Tekst til sti konverter 2.0',
                message: "Beam Studio introducerer nu en ny tekst til sti konverter (Konverter 2.0), som producerer mere pålidelige resultater! Vil du skifte til den nu? \nDu kan også justere denne indstilling senere i 'Tekst til sti konverter' afsnittet i indstillingerne.",
            },
        },
        zoom_block: {
            fit_to_window: 'Tilpas til vindue',
        },
        time_est_button: {
            calculate: 'Estimer tid',
            estimate_time: 'Estimeret tid:',
        },
        left_panel: {
            unpreviewable_area: 'Blindt område',
            diode_blind_area: 'Hybrid laser blindt område',
            borderless_blind_area: 'Ikke-graveringsområde',
            borderless_preview: 'Åbn bundkameraforhåndsvisning i bundløs tilstand',
            rectangle: 'Rektangel',
            ellipse: 'Ellipse',
            line: 'Linje',
            image: 'Billede',
            text: 'Tekst',
            label: {
                cursor: 'Vælg',
                photo: 'Billede',
                text: 'Tekst',
                line: 'Linje',
                rect: 'Rektangel',
                oval: 'Oval',
                polygon: 'Polygon',
                pen: 'Pen',
                shapes: 'Elementer',
                array: 'Array',
                preview: 'Kamera forhåndsvisning',
                trace: 'Spor billede',
                end_preview: 'Afslut forhåndsvisning',
                clear_preview: 'Ryd forhåndsvisning',
                choose_camera: 'Kamera',
                live_feed: 'Live-feed',
                adjust_height: 'Juster højde',
                qr_code: 'QR-kode',
                boxgen: 'Boxgen',
                my_cloud: 'Min sky',
                curve_engraving: {
                    title: '3D Kurve',
                    exit: 'Afslut',
                    select_area: 'Vælg område',
                    preview_3d_curve: 'Forhåndsvis 3D-kurve',
                    clear_area: 'Ryd valgt område',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Lag',
                objects: 'Objekter',
                path_edit: 'Stiredigering',
            },
            layer_panel: {
                layer1: 'Lag 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Gravering',
                layer_cutting: 'Skæring',
                current_layer: 'Aktuelt lag',
                move_elems_to: 'Flyt elementer til:',
                notification: {
                    dupeLayerName: 'Der er allerede et lag med det navn!',
                    newName: 'NYT NAVN',
                    enterUniqueLayerName: 'Indtast venligst et unikt lagnavn',
                    enterNewLayerName: 'Indtast venligst det nye lagnavn',
                    layerHasThatName: 'Laget har allerede det navn',
                    QmoveElemsToLayer: "Flyt valgte elementer til lag '%s'?",
                    moveElemFromPrintingLayerTitle: 'Flyt det valgte element til %s og konverter det til et laserelement?',
                    moveElemFromPrintingLayerMsg: 'Bemærk, at hvis du gennemfører denne handling, vil farveindstillingerne for det valgte element blive fjernet og indstillet til %s.',
                    moveElemToPrintingLayerTitle: 'Flyt det valgte element til %s og konverter det til et udskriftselement?',
                    moveElemToPrintingLayerMsg: 'Bemærk, at hvis du gennemfører denne handling, vil indstillingerne for det valgte element blive fjernet og indstillet til %s.',
                    splitColorTitle: 'Vil du udvide det valgte lag til CMYK-lag?',
                    splitColorMsg: 'Bemærk, at hvis du fortsætter med denne handling, vil du ikke kunne vende tilbage til de oprindelige farvelag.',
                    mergeLaserLayerToPrintingLayerTitle: 'Vil du sammenlægge disse lag til ét udskriftslag?',
                    mergeLaserLayerToPrintingLayerMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil indstillingerne for laserlaget blive fjernet og indstillet til det aktuelle lag.',
                    mergePrintingLayerToLaserLayerTitle: 'Vil du sammenlægge disse lag til ét laserlag?',
                    mergePrintingLayerToLaserLayerMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil farveindstillingerne for udskriftslaget blive fjernet og indstillet til det aktuelle lag.',
                },
                layers: {
                    layer: 'Lag',
                    layers: 'Lag',
                    del: 'Slet lag',
                    move_down: 'Flyt lag ned',
                    new: 'Nyt lag',
                    rename: 'Omdøb lag',
                    move_up: 'Flyt lag op',
                    dupe: 'Dupliker lag',
                    lock: 'Lås lag',
                    unlock: 'Lås op',
                    merge_down: 'Sammenlæg nedad',
                    merge_all: 'Sammenlæg alle',
                    merge_selected: 'Sammenlæg valgte lag',
                    move_elems_to: 'Flyt elementer til:',
                    move_selected: 'Flyt valgte elementer til et andet lag',
                    switchToFullColor: 'Skift til fuldfarvelag',
                    switchToSingleColor: 'Skift til enkelt farvelag',
                    splitFullColor: 'Udvid lag',
                    fullColor: 'Fuld farve',
                },
            },
            laser_panel: {
                preset_setting: 'Indstillinger (%s)',
                multi_layer: 'Flere lag',
                parameters: 'Parametre',
                strength: 'Effekt',
                low_power_warning: 'Lavere laserstyrke (under 10%) kan muligvis ikke udsende laserlys.',
                speed: 'Hastighed',
                speed_contrain_warning: 'Skærehastigheden for vektorstibaner vil være begrænset til 20 mm/s. Du kan fjerne denne begrænsning under Indstillinger.',
                low_speed_warning: 'Lav hastighed kan forårsage brænding af materialet.',
                repeat: 'Udfør',
                add_on: 'Tilføjelse',
                focus_adjustment: 'Fokusjustering',
                height: 'Objekthøjde',
                z_step: 'Z-trin',
                diode: 'Diodelaser',
                backlash: 'Tilbageløb',
                ink_saturation: 'Mætning',
                print_multipass: 'Multi-pass',
                white_ink: 'Hvid blæk',
                white_ink_settings: 'Hvid blæk indstillinger',
                color_adjustment: 'Kanaljustering',
                color_adjustment_short: 'Kanal',
                halftone: 'Halvtoner',
                halftone_link: 'https://support.flux3dp.com/hc/zh-tw/articles/9402670389647',
                color_strength: 'Styrke',
                times: 'gange',
                cut: 'Skær',
                engrave: 'Graver',
                more: 'Administrer',
                delete: 'Slet',
                reset: 'Nulstil',
                sure_to_reset: 'Dette vil nulstille alle forudindstillinger og beholde dine tilpassede parametre, er du sikker på at fortsætte?',
                apply: 'Anvend',
                cancel: 'Annullér',
                save: 'Gem',
                save_and_exit: 'Gem og luk',
                name: 'Navn',
                default: 'Standard',
                customized: 'Brugerdefineret liste',
                inuse: 'I brug',
                export_config: 'Eksportér forudindstillinger',
                new_config_name: 'Nyt forudindstillet navn',
                sure_to_load_config: 'Dette vil indlæse forudindstillinger og erstatte brugerdefinerede parametre angivet i filen. Er du sikker på, at du vil fortsætte?',
                custom_preset: 'Brugerdefineret',
                various_preset: 'Forskellige forudindstillinger',
                module: 'Modul',
                slider: {
                    regular: 'Almindelig',
                    low: 'Lav',
                    very_low: 'Min',
                    high: 'Høj',
                    very_high: 'Maks',
                    slow: 'Langsom',
                    very_slow: 'Langsomst',
                    fast: 'Hurtig',
                    very_fast: 'Hurtigst',
                },
                dropdown: {
                    parameters: 'Forudindstillinger',
                    save: 'Tilføj aktuelle parametre',
                    export: 'Eksportér',
                    import: 'Importér',
                    more: 'Administrér',
                    mm: {
                        wood_3mm_cutting: 'Træ - 3 mm skæring',
                        wood_5mm_cutting: 'Træ - 5 mm skæring',
                        wood_7mm_cutting: 'Træ - 7mm skæring',
                        wood_8mm_cutting: 'Træ - 8 mm skæring',
                        wood_10mm_cutting: 'Træ - 10 mm skæring',
                        wood_engraving: 'Træ - gravering',
                        acrylic_3mm_cutting: 'Akryl - 3 mm skæring',
                        acrylic_5mm_cutting: 'Akryl - 5 mm skæring',
                        acrylic_8mm_cutting: 'Akryl - 8 mm skæring',
                        acrylic_10mm_cutting: 'Akryl - 10 mm skæring',
                        acrylic_engraving: 'Akryl - gravering',
                        mdf_3mm_cutting: 'New Zealand MDF - 3mm skæring',
                        mdf_5mm_cutting: 'New Zealand MDF - 5mm skæring',
                        mdf_engraving: 'New Zealand MDF - gravering',
                        leather_3mm_cutting: 'Læder - 3 mm skæring',
                        leather_5mm_cutting: 'Læder - 5 mm skæring',
                        leather_engraving: 'Læder - gravering',
                        denim_1mm_cutting: 'Denim - 1mm skæring',
                        fabric_3mm_cutting: 'Stof - 3 mm skæring',
                        fabric_5mm_cutting: 'Stof - 5 mm skæring',
                        fabric_engraving: 'Stof - gravering',
                        rubber_bw_engraving: 'Gummi - gravering',
                        glass_bw_engraving: 'Glas - gravering',
                        metal_bw_engraving: 'Metal - gravering',
                        steel_engraving_spray_engraving: 'Metal - gravering',
                        stainless_steel_bw_engraving_diode: 'Metal - gravering (diode laser)',
                        gold_engraving: 'Guld - gravering',
                        brass_engraving: 'Messing - gravering',
                        ti_engraving: 'Titan - gravering',
                        stainless_steel_engraving: 'Rustfrit stål - gravering',
                        aluminum_engraving: 'Aluminium - gravering',
                        black_acrylic_3mm_cutting: 'Sort akryl - 3mm skæring',
                        black_acrylic_5mm_cutting: 'Sort akryl - 5mm skæring',
                        black_acrylic_engraving: 'Sort akryl - gravering',
                        abs_engraving: 'ABS - gravering',
                        silver_engraving: 'Sølv - gravering',
                        iron_engraving: 'Jern - gravering',
                        fabric_printing: 'Stof - print',
                        canvas_printing: 'Lærred - print',
                        cardstock_printing: 'Karton - print',
                        wood_printing: 'Træ - print',
                        bamboo_printing: 'Bambus - print',
                        cork_printing: 'Kork - Udskrivning',
                        flat_stone_printing: 'Flad sten - Udskrivning',
                        acrylic_printing: 'Akryl - Udskrivning',
                        pc_printing: 'PC - Udskrivning',
                        stainless_steel_printing: 'Rustfrit stål - Udskrivning',
                        gloss_leather_printing: 'Blankt læder - Udskrivning',
                        glass_printing: 'Glas - Udskrivning',
                    },
                    inches: {
                        wood_3mm_cutting: 'Træ - 0,1" skæring',
                        wood_5mm_cutting: 'Træ - 0,2" skæring',
                        wood_7mm_cutting: 'Træ - 0,3" Skæring',
                        wood_8mm_cutting: 'Træ - 0,3" skæring',
                        wood_10mm_cutting: 'Træ - 0,4" skæring',
                        wood_engraving: 'Træ - gravering',
                        acrylic_3mm_cutting: 'Akryl - 0,1" skæring',
                        acrylic_5mm_cutting: 'Akryl - 0,2" skæring',
                        acrylic_8mm_cutting: 'Akryl - 0,3" skæring',
                        acrylic_10mm_cutting: 'Akryl - 0,4" skæring',
                        acrylic_engraving: 'Akryl - gravering',
                        mdf_3mm_cutting: 'MDF - 0,1" Skæring',
                        mdf_5mm_cutting: 'MDF - 0,2" Skæring',
                        mdf_engraving: 'MDF - Gravering',
                        leather_3mm_cutting: 'Læder - 0,1" skæring',
                        leather_5mm_cutting: 'Læder - 0,2" skæring',
                        leather_engraving: 'Læder - gravering',
                        denim_1mm_cutting: 'Denim - 0,04" Skæring',
                        fabric_3mm_cutting: 'Stof - 0,1" skæring',
                        fabric_5mm_cutting: 'Stof - 0,2" skæring',
                        fabric_engraving: 'Stof - gravering',
                        rubber_bw_engraving: 'Gummi - gravering',
                        glass_bw_engraving: 'Glas - gravering',
                        metal_bw_engraving: 'Metal - gravering',
                        steel_engraving_spray_engraving: 'Metal - gravering',
                        stainless_steel_bw_engraving_diode: 'Metal - gravering (diodelaser)',
                        gold_engraving: 'Guld - Gravering',
                        brass_engraving: 'Messing - Gravering',
                        ti_engraving: 'Titanium - Gravering',
                        stainless_steel_engraving: 'Rustfrit stål - Gravering',
                        aluminum_engraving: 'Aluminium - Gravering',
                        black_acrylic_3mm_cutting: 'Sort Akryl - 0,1" Skæring',
                        black_acrylic_5mm_cutting: 'Sort Akryl - 0,2" Skæring',
                        black_acrylic_engraving: 'Sort Akryl - Gravering',
                        abs_engraving: 'ABS - Gravering',
                        silver_engraving: 'Sølv - Gravering',
                        iron_engraving: 'Jern - Gravering',
                        fabric_printing: 'Stof - Udskrivning',
                        canvas_printing: 'Lærred - Udskrivning',
                        cardstock_printing: 'Karton - Udskrivning',
                        wood_printing: 'Træ - Udskrivning',
                        bamboo_printing: 'Bambus - Udskrivning',
                        cork_printing: 'Kork - Udskrivning',
                        flat_stone_printing: 'Flad sten - Udskrivning',
                        acrylic_printing: 'Akryl - Udskrivning',
                        pc_printing: 'PC - Udskrivning',
                        stainless_steel_printing: 'Rustfrit stål - Udskrivning',
                        gloss_leather_printing: 'Blankt læder - Udskrivning',
                        glass_printing: 'Glas - Udskrivning',
                    },
                },
                laser_speed: {
                    text: 'Hastighed',
                    unit: 'mm/s',
                    fast: 'Hurtig',
                    slow: 'Langsom',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Effekt',
                    high: 'Høj',
                    low: 'Lav',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Blæktype',
                    normal: 'Blæk',
                    UV: 'UV-blæk',
                },
                para_in_use: 'Denne parameter anvendes.',
                do_not_adjust_default_para: 'Standardindstillinger kan ikke justeres.',
                existing_name: 'Dette navn bruges allerede.',
                presets: 'Forudindstilling',
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Gruppér',
                ungroup: 'Opdel gruppe',
                distribute: 'Fordel',
                hdist: 'Fordel vandret',
                vdist: 'Fordel lodret',
                align: 'Juster',
                left_align: 'Venstrejuster',
                center_align: 'Centrer',
                right_align: 'Højrejuster',
                top_align: 'Topjuster',
                middle_align: 'Midtjuster',
                bottom_align: 'Bundjuster',
                boolean: 'Boolesk',
                union: 'Forening',
                subtract: 'Fratræk',
                intersect: 'Gennemskær',
                difference: 'Forskel',
                flip: 'Vend',
                hflip: 'Vandret vend',
                vflip: 'Lodret vend',
                lock_aspect: 'Lås størrelsesforhold',
                unlock_aspect: 'Lås størrelsesforhold op',
                option_panel: {
                    fill: 'Udfyldning',
                    rounded_corner: 'Afrundede hjørner',
                    sides: 'Sider',
                    font_family: 'Skrifttype',
                    font_style: 'Stil',
                    font_size: 'Størrelse',
                    letter_spacing: 'Bogstavafstand',
                    line_spacing: 'Linjeafstand',
                    vertical_text: 'Lodret tekst',
                    start_offset: 'Tekstforskydning',
                    vertical_align: 'Justering',
                    text_infill: 'Tekstudfyldning',
                    path_infill: 'Stiforfyldning',
                    shading: 'Gradient',
                    threshold: 'Tærskel lysstyrke',
                    threshold_short: 'Tærskel',
                    stroke: 'Streg',
                    stroke_color: 'Stregfarve',
                    stroke_width: 'Stregbredde',
                    color: 'Farve',
                },
                actions_panel: {
                    replace_with: 'Erstat med...',
                    replace_with_short: 'Erstat',
                    trace: 'Spor',
                    grading: 'Gradering',
                    brightness: 'Lysstyrke',
                    sharpen: 'Skarphed',
                    crop: 'Beskær',
                    bevel: 'Fas',
                    invert: 'Inverter',
                    weld_text: 'Svejs tekst',
                    convert_to_path: 'Konverter til sti',
                    fetching_web_font: 'Henter online skrift...',
                    uploading_font_to_machine: 'Uploader skrift til maskinen...',
                    wait_for_parsing_font: 'Fortolker tekst... Vent venligst',
                    offset: 'Forskyd',
                    array: 'Array',
                    auto_arrange: 'Autoarrangér',
                    decompose_path: 'Dekomponer',
                    disassemble_use: 'Skil ad',
                    create_textpath: 'Opret tekst på sti',
                    create_textpath_short: 'Stitekst',
                    detach_path: 'Skil tekst på sti ad',
                    detach_path_short: 'Skil ad',
                    edit_path: 'Rediger sti',
                    disassembling: 'Skiller ad...',
                    ungrouping: 'Skiller gruppe ad...',
                    simplify: 'Forenklet',
                    ai_bg_removal: 'Baggrundsfjernelse',
                    ai_bg_removal_short: 'BG fjernelse',
                    ai_bg_removal_reminder: 'Tryk på knappen vil straks bruge 0,2 kredit. Vil du fortsætte?',
                    outline: 'Omrids',
                },
                path_edit_panel: {
                    node_type: 'NODETYPE',
                    sharp: 'Skarp',
                    round: 'Rund',
                    connect: 'Forbind',
                    disconnect: 'Afbryd',
                    delete: 'Slet',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Konverter tekst til sti...',
            retreive_image_data: 'Henter billeddata...',
            export_file_error_ask_for_upload: 'Kunne ikke eksportere opgave. Er du villig til at dele arbejdsscenen med udviklingsholdet til fejlrapportering?',
        },
        image_trace_panel: {
            apply: 'Anvend',
            back: 'Tilbage',
            cancel: 'Annullér',
            next: 'Næste',
            brightness: 'Lysstyrke',
            contrast: 'Kontrast',
            threshold: 'Tærskel',
            okay: 'Ok',
            tuning: 'Parametre',
        },
        photo_edit_panel: {
            apply: 'Anvend',
            back: 'Tilbage',
            cancel: 'Annullér',
            next: 'Næste',
            sharpen: 'Skarphed',
            sharpness: 'Skarphed',
            radius: 'Radius',
            crop: 'Beskær',
            aspect_ratio: 'Billedformat',
            original: 'Original',
            free: 'Fri',
            curve: 'Kurve',
            start: 'Start',
            processing: 'Behandler',
            invert: 'Inverter farve',
            okay: 'Ok',
            compare: 'Sammenlign',
            phote_edit: 'Fotoredigering',
            brightness_and_contrast: 'Lysstyrke / Kontrast',
            brightness: 'Lysstyrke',
            contrast: 'Kontrast',
        },
        document_panel: {
            document_settings: 'Dokumentindstillinger',
            engrave_parameters: 'Graveringsparametre',
            workarea: 'Arbejdsområde',
            rotary_mode: 'Roterende',
            borderless_mode: 'Åben bund',
            engrave_dpi: 'Opløsning',
            enable_diode: 'Diodelaser',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Udvid arbejdsområdet',
            add_on: 'Tilføjelser',
            low: 'Lav',
            medium: 'Medium',
            high: 'Høj',
            ultra: 'Ultra høj',
            enable: 'Aktivér',
            disable: 'Deaktivér',
            cancel: 'Annuller',
            save: 'Gem',
            notification: {
                changeFromPrintingWorkareaTitle: 'Vil du konvertere Udskriftslag til Laserskærelag?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Parser skrifttype ... Vent et sekund',
            text_to_path: {
                font_substitute_pop: 'Din tekst indeholder tegn, der ikke understøttes af den aktuelle skrifttype. <br/>Vil du bruge <strong>%s</strong> som erstatning?',
                check_thumbnail_warning: 'Nogle tekster blev ændret til andre skrifttyper ved parsing af tekster til stier, og nogle tegn konverteres muligvis ikke korrekt. <br/>Kontrollér venligst billedet igen, før du sender opgaven.',
                error_when_parsing_text: 'Fejl ved konvertering af tekst til sti',
                retry: 'Prøv igen senere eller vælg en anden skrifttype',
                use_current_font: 'Brug nuværende skrifttype',
            },
            lock_desc: 'Bevar forholdet mellem bredde og højde (SHIFT)',
        },
        tool_panels: {
            cancel: 'Annuller',
            confirm: 'Bekræft',
            grid_array: 'Opret gitterarray',
            array_dimension: 'Arraydimension',
            rows: 'Rækker',
            columns: 'Kolonner',
            array_interval: 'Arrayinterval',
            dx: 'X',
            dy: 'Y',
            offset: 'Forskydning',
            nest: 'Placering Optimering',
            _offset: {
                direction: 'Forskydningsretning',
                inward: 'Indad',
                outward: 'Udad',
                dist: 'Forskydningsafstand',
                corner_type: 'Hjørne',
                sharp: 'Skarp',
                round: 'Rund',
                fail_message: 'Kunne ikke forskyde objekter',
                not_support_message: 'Valgte elementer indeholder ikke-understøttede SVG-tags:\nBillede, Gruppe, Tekst og Importeret Objekt.',
            },
            _nest: {
                start_nest: 'Placer',
                stop_nest: 'Stop',
                end: 'Luk',
                spacing: 'Afstand',
                rotations: 'Mulige rotationer',
                no_element: 'Der er ingen elementer at placere.',
            },
        },
        network_testing_panel: {
            network_testing: 'Netværkstest',
            local_ip: 'Lokal IP-adresse:',
            insert_ip: 'Destinations-IP-adresse:',
            empty_ip: '#818 Angiv venligst destinations-IP først.',
            start: 'Start',
            end: 'Stop',
            testing: 'Tester netværk...',
            invalid_ip: '#818 Ugyldig IP-adresse',
            ip_startswith_169: '#843 Maskine IP-adresse starter med 169.254',
            connection_quality: 'Forbindelseskvalitet',
            average_response: 'Gennemsnitlig svartid',
            test_completed: 'Test afsluttet',
            test_fail: 'Test mislykkedes',
            cannot_connect_1: '#840 Kan ikke oprette forbindelse til mål-IP.',
            cannot_connect_2: '#840 Kan ikke oprette forbindelse til mål-IP. Kontroller venligst, at målet er på samme netværk.',
            network_unhealthy: '#841 Forbindelseskvalitet <70 eller gennemsnitlig reaktionstid >100 ms',
            device_not_on_list: '#842 Maskinen er ikke på listen, men forbindelseskvaliteten er >70 og den gennemsnitlige reaktionstid er <100 ms',
            hint_device_often_on_list: 'Maskinen findes ofte ikke på listen?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Mislykkedes med at oprette forbindelse ved afsendelse af et job?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Timeout opstår, når kameravisningen startes?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Adgang til lokal IP-adresse mislykkedes.',
            fail_to_start_network_test: '#817 Kan ikke starte netværkstest.',
            linux_permission_hint: 'Denne fejl opstår normalt på grund af utilstrækkelige tilladelser.\nKør venligst "sudo beam-studio --no-sandbox" i terminalen for at få tilladelser og udføre netværkstest.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Lagfarvekonfigurationer',
            color: 'Farve',
            power: 'Effekt',
            speed: 'Hastighed',
            repeat: 'Udfør',
            add: 'Tilføj',
            save: 'Gem',
            cancel: 'Annuller',
            default: 'Nulstil til standard',
            add_config: 'Tilføj farve',
            in_use: 'Denne farve er i brug.',
            no_input: 'Angiv venligst gyldig hex-farvekode.',
            sure_to_reset: 'Du vil miste alle tilpassede parametre, er du sikker på, at du vil nulstille til standard?',
            sure_to_delete: 'Er du sikker på, at du vil slette denne farveindstilling?',
        },
        rating_panel: {
            title: 'Synes du godt om Beam Studio?',
            description: 'Hvis du kan lide Beam Studio, vil vi sætte stor pris på, hvis du kunne bedømme os.',
            dont_show_again: 'Vis ikke dette næste gang.',
            thank_you: 'Tak!',
        },
        svg_editor: {
            unnsupported_file_type: 'Filtypen understøttes ikke direkte. Konvertér filen til SVG eller bitmap',
            unable_to_fetch_clipboard_img: 'Kunne ikke hente billede fra dit udklipsholder',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Afspil',
            pause: 'Pause',
            stop: 'Stop',
            play_speed: 'Afspilningshastighed',
            travel_path: 'Rejsesti',
            invert: 'Inverter',
            preview_info: 'Forhåndsvisningsoplysninger',
            size: 'Størrelse',
            estimated_time: 'Samlet estimeret tid',
            cut_time: 'Skæretid',
            rapid_time: 'Rejsetid',
            cut_distance: 'Skæreafstand',
            rapid_distance: 'Rejseafstand',
            current_position: 'Nuværende position',
            remark: '* Alle oplysninger er et estimeret referenceværdi.',
            start_here: 'Start her',
            end_preview: 'Slut forhåndsvisning',
        },
        shapes_panel: {
            title: 'Elementer',
            basic: 'Grundlæggende',
            shape: 'Form',
            graphics: 'Grafik',
            arrow: 'Pil',
            label: 'Etiket',
            decor: 'Dekoration',
            circular: 'Cirkulær',
            corner: 'Hjørne',
            line: 'Linje',
            photo: 'Foto',
            ribbon: 'Bånd',
            speech: 'Tale',
            text: 'Tekstboks',
            animals: 'Dyr',
            birds: 'Fugle',
            land: 'Land',
            sea: 'Hav',
            holidays: 'Helligdage',
            celebration: 'Fest',
            CNY: 'Kinesisk nytår',
            easter: 'Påske',
            halloween: 'Allehelgensaften',
            valentines: 'Valentinsdag',
            Xmas: 'Jul',
            nature: 'Natur',
            elements: 'Elementer',
            environment: 'Miljø',
            plants: 'Planter',
            weather: 'Vejr',
        },
        announcement_panel: {
            title: 'Meddelelse',
            dont_show_again: 'Vis ikke igen',
        },
    },
    editor: {
        prespray_area: 'Primeområde',
        opacity: 'Forhåndsvisning gennemsigtighed',
        exposure: 'Forhåndsvisningseksponering',
    },
    flux_id_login: {
        connection_fail: '#847 Kunne ikke oprette forbindelse til FLUX medlemsservice.',
        login_success: 'Logget ind med succes.',
        login: 'Log ind',
        unlock_shape_library: 'Log ind for at låse formdatabasen op.',
        email: 'E-mail',
        password: 'Adgangskode',
        remember_me: 'Husk mig',
        forget_password: 'Glemt adgangskode?',
        register: 'Opret din FLUX-konto',
        offline: 'Arbejd offline',
        work_offline: 'Arbejd offline',
        incorrect: 'E-mailadresse eller adgangskode er ikke korrekt.',
        not_verified: 'E-mailadressen er endnu ikke bekræftet.',
        new_to_flux: 'Ny til FLUX? Opret en konto.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Udforsk FLUX+ planer',
            thank_you: 'Tak fordi du er et værdsat medlem!',
            ai_credit_tooltip: 'Til AI baggrundsfjernelse',
            flux_credit_tooltip: 'Til Design Market filer og AI baggrundsfjernelse',
            goto_member_center: 'Gå til Medlemscenter',
            access_plus_feature_1: 'Du får adgang til en',
            access_plus_feature_2: 'funktion.',
            access_plus_feature_note: 'Du skal have FLUX+ medlemskab for at få adgang til denne funktion.',
            access_monotype_feature: 'Du har ikke Monotype-skrifttyper Tilføjelse.',
            access_monotype_feature_note: 'Du skal have FLUX+ Pro-medlemskab eller Monotype-skrifttyper Tilføjelse for at få adgang til denne funktion.',
            learn_more: 'Lær mere',
            get_addon: 'Få tilføjelse',
            subscribe_now: 'Tilmeld dig nu',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI baggrundsfjernelse',
                my_cloud: 'Ubegrænset sky-lager',
                boxgen: '3D kassegenerator',
                dmkt: '1000+ designfiler',
                monotype: '250+ premium skrifttyper',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Log ind for at låse formdatabasen op.',
        enjoy_shape_library: 'Nyd formdatabasen.',
        shapes: 'Former',
        elements: 'Elementer',
        recent: 'Seneste',
        search: 'Søg',
        clear: 'Ryd',
        export_svg_title: 'Kan ikke eksportere SVG',
        export_svg_warning: 'Dette projekt indeholder objekter, der er beskyttet af ophavsret. Derfor vil Beam Studio automatisk udelade disse objekter under eksporten. Du kan stadig gemme dit projekt i Beam Studio Scene (.beam-format) for at beholde alle dine data. Vil du stadig eksportere?',
        learn_more: 'Lær mere',
    },
    change_logs: {
        change_log: 'Ændringslog',
        added: 'Tilføjet',
        fixed: 'Rettet',
        changed: 'Ændret',
        see_older_version: 'Se ældre versioner',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Vælg enhed',
        auth_failure: '#811 Godkendelsesfejl',
        unable_to_connect: '#810 Kan ikke oprette en stabil forbindelse til maskinen',
    },
    device: {
        pause: 'Pause',
        paused: 'Pauset',
        pausing: 'Pauser',
        select_printer: 'Vælg printer',
        retry: 'Prøv igen',
        status: 'Status',
        busy: 'Optaget',
        ready: 'Klar',
        reset: 'Nulstil',
        abort: 'Afbryd',
        start: 'Start',
        please_wait: 'Vent venligst...',
        quit: 'Afslut',
        completing: 'Færdiggør',
        aborted: 'Afbrudt',
        completed: 'Færdig',
        aborting: 'Afbryder',
        starting: 'Starter',
        preparing: 'Forbereder',
        resuming: 'Genoptager',
        scanning: 'Scanner',
        occupied: 'Optaget',
        running: 'Kører',
        uploading: 'Uploader',
        processing: 'Behandler',
        disconnectedError: {
            caption: 'Maskine afbrudt',
            message: 'Bekræft venligst om netværksadgang for %s er tilgængelig',
        },
        unknown: 'Ukendt',
        pausedFromError: 'Pauset pga. fejl',
        model_name: 'Modelnavn',
        IP: 'IP',
        serial_number: 'Serienummer',
        firmware_version: 'Firmwareversion',
        UUID: 'UUID',
        select: 'Vælg',
        deviceList: 'Maskinliste',
        disable: 'Deaktiver',
        enable: 'Aktiver',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    },
    monitor: {
        monitor: 'OVERVÅG',
        go: 'Start',
        resume: 'Genoptag',
        start: 'Start',
        pause: 'Pause',
        stop: 'Stop',
        record: 'Optag',
        camera: 'Kamera',
        taskTab: 'Opgave',
        connecting: 'Forbinder, vent venligst...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritisk fejl: Hovedkort offline. Kontakt venligst FLUX-support.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritisk fejl: Intet svar fra subsystem. Kontakt venligst FLUX-support.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Kontroller venligst din vandtank.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Luk døren for at fortsætte.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Overophedet. Vent venligst et par minutter.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bund åbnet. Luk bunden for at fortsætte.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-akse nulstilling mislykkedes',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Skuffen åbnet',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Flammesensor unormal',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftstrømsensor unormal',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Ingen roterende modul registreret',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Hjemkørsel trækfejl',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Forkert modul registreret. Installer det korrekte modul for at fortsætte.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul ikke registreret. Sørg for, at modulet er installeret korrekt for at fortsætte.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Ukendt modul registreret. Installer det korrekte modul for at fortsætte.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Ingen reaktion fra printermodulet.',
        USER_OPERATION_ROTARY_PAUSE: 'Skift venligst til roterende motor',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Fjern venligst patronen for at fortsætte.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Forkert modul registreret. Installer det korrekte modul for at fortsætte.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul ikke registreret. Sørg for, at modulet er installeret korrekt for at fortsætte.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Ukendt modul registreret. Installer det korrekte modul for at fortsætte.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Installer det korrekte modul for at fortsætte.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Indsæt venligst patronen for at fortsætte.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Indsæt venligst den korrekte patron for at fortsætte.',
        USER_OPERATION: 'Følg venligst instruktionerne på enhedspanelet for at fortsætte.',
        RESOURCE_BUSY: 'Maskinen er optaget. \nGenstart den venligst hvis den ikke kører',
        DEVICE_ERROR: 'Der opstod en fejl. \nGenstart venligst maskinen',
        NO_RESPONSE: '#905 Fejl ved forbindelse til hovedprintkort. \nGenstart venligst maskinen',
        SUBSYSTEM_ERROR: '#402 Kritisk fejl: Intet svar fra delsystem. Kontakt venligst FLUX-support',
        HARDWARE_FAILURE: 'Der opstod en fejl. \nGenstart venligst maskinen',
        MAINBOARD_OFFLINE: '#905 Fejl ved forbindelse til hovedprintkort. \nGenstart venligst maskinen',
        bug_report: 'Fejlrapport',
        processing: 'Behandler',
        savingPreview: 'Genererer miniaturebilleder',
        hour: 't',
        minute: 'm',
        second: 's',
        left: 'tilbage',
        temperature: 'Temperatur',
        forceStop: 'Ønsker du at afbryde den igangværende opgave?',
        upload: 'Upload',
        download: 'Download',
        relocate: 'Flyt',
        cancel: 'Annuller',
        prepareRelocate: 'Forbereder flytning',
        extensionNotSupported: 'Dette filformat understøttes ikke',
        fileExistContinue: 'Filen findes allerede. Vil du erstatte den?',
        confirmFileDelete: 'Er du sikker på, at du vil slette denne fil?',
        ask_reconnect: 'Forbindelsen til maskinen er afbrudt. Vil du genoprette forbindelsen?',
        task: {
            BEAMBOX: 'Lasergravering',
            'N/A': 'Fri tilstand',
        },
    },
    alert: {
        caption: 'Fejl',
        duplicated_preset_name: 'Duplikeret forudindstillet navn',
        info: 'INFO',
        warning: 'ADVARSEL',
        error: 'UH-OH',
        instruction: 'Instruktion',
        oops: 'Ups...',
        retry: 'Prøv igen',
        abort: 'Afbryd',
        confirm: 'Bekræft',
        cancel: 'Annuller',
        close: 'Luk',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ja',
        no: 'Nej',
        stop: 'Stop',
        save: 'Gem',
        dont_save: 'Gem ikke',
        learn_more: 'Lær mere',
    },
    caption: {
        connectionTimeout: 'Forbindelsestimeout',
    },
    message: {
        cancelling: 'Annullerer...',
        connecting: 'Forbinder...',
        connectingMachine: 'Forbinder %s...',
        tryingToConenctMachine: 'Forsøger at oprette forbindelse til maskine...',
        connected: 'Forbundet',
        authenticating: 'Godkender...',
        enteringRawMode: 'Indtaster rå tilstand...',
        endingRawMode: 'Forlader rå tilstand...',
        enteringLineCheckMode: 'Indtaster linjetjektilstand...',
        endingLineCheckMode: 'Forlader linje kontroltilstand...',
        exitingRotaryMode: 'Forlader drejetilstand...',
        turningOffFan: 'Slukker blæser...',
        turningOffAirPump: 'Slukker luftpumpe...',
        gettingLaserSpeed: 'Læser lasers hovedhastighed...',
        settingLaserSpeed: 'Indstiller lasers hovedhastighed...',
        retrievingCameraOffset: 'Læser kamera forskydning...',
        connectingCamera: 'Forbinder kamera...',
        homing: 'Hjemkører...',
        connectionTimeout: '#805 Enhedsforbindelse timeout. Kontroller venligst dit netværks tilstand og din maskines Wi-Fi-indikator.',
        getProbePosition: 'Henter probe position...',
        device_not_found: {
            caption: 'Standardmaskine ikke fundet',
            message: '#812 Kontroller venligst din maskines Wi-Fi-indikator',
        },
        device_busy: {
            caption: 'Maskinen er optaget',
            message: 'Maskinen udfører en anden opgave, prøv igen senere. Hvis den holder op med at fungere, genstart da maskinen.',
        },
        device_is_used: 'Maskinen er i brug, vil du afbryde den nuværende opgave?',
        monitor_too_old: {
            caption: 'Forældet firmware',
            content: '#814 Installer venligst den nyeste firmware med <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">denne vejledning</a>.',
        },
        unknown_error: '#821 Programmet stødte på en ukendt fejl, brug Hjælp > Menu > Fejlrapportering.',
        unknown_device: '#826 Kan ikke oprette forbindelse til maskinen, sørg for at USB er tilsluttet maskinen',
        unsupport_osx_version: 'Den nuværende macOS-version %s understøtter muligvis ikke alle funktioner. Opdater venligst til macOS 11+.',
        unsupport_win_version: 'Den nuværende OS-version %s understøtter muligvis ikke alle funktioner. Opdater venligst til den nyeste version.',
        need_password: 'Adgangskode kræves for at oprette forbindelse til maskinen',
        unavailableWorkarea: '#804 Det nuværende arbejdsområde overstiger maskinens arbejdsområde. Kontroller det valgte maskines arbejdsområde eller indstil arbejdsområde fra Rediger > Dokumentindstillinger.',
        please_enter_dpi: 'Angiv venligst enheden for din fil (i mm)',
        auth_error: '#820 Godkendelsesfejl: Opdater Beam Studio og maskinfirmware til den nyeste version.',
        usb_unplugged: 'USB-forbindelsen er tabt. Kontroller venligst din USB-forbindelse',
        uploading_fcode: 'Uploader FCode',
        cant_connect_to_device: '#827 Kan ikke oprette forbindelse til maskinen, kontroller venligst din forbindelse',
        unable_to_find_machine: 'Kan ikke finde maskine ',
        disconnected: 'Ustabil forbindelse, kontroller enhedsforbindelse og prøv igen senere',
        unable_to_start: '#830 Kan ikke starte opgaven. Prøv igen. Hvis dette sker igen, bedes du kontakte os med fejlrapport:',
        camera: {
            camera_cable_unstable: 'Det er registreret, at kameraet overfører billeder ustabilt. Forhåndsvisning kan stadig udføres normalt, men der kan være problemer med langsom forhåndsvisning eller timeout.',
            fail_to_transmit_image: '#845 Noget gik galt med billedtransmissionen. Genstart venligst din maskine eller Beam Studio. Hvis denne fejl fortsætter, skal du følge <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">denne vejledning</a>.',
            ws_closed_unexpectly: '#844 Forbindelsen til maskinkameraet er blevet lukket uventet. Hvis denne fejl fortsætter, skal du følge <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">denne vejledning</a>.',
            continue_preview: 'Fortsæt',
            abort_preview: 'Afbryd',
        },
        preview: {
            camera_preview: 'Kamera forhåndsvisning',
            auto_focus: 'Auto fokus',
            auto_focus_instruction: 'Flyt venligst lasermodulet over objektet og følg anvisningerne for at trykke på AF for at fokusere.',
            already_performed_auto_focus: 'Du har allerede udført autofokus, vil du bruge eksisterende værdier?',
            please_enter_height: 'Angiv venligst objektets højde. For at optage et præcist kamerabillede.',
            apply: 'Anvend',
            enter_manually: 'Indtast manuelt',
            adjust: 'Juster',
            adjust_height_tooltip: 'Aktiver redigering ved at afkrydse.',
        },
        unsupported_example_file: 'Det valgte eksempelfil understøttes ikke af det aktuelle arbejdsområde.',
        time_remaining: 'Tid tilbage:',
    },
    machine_status: {
        '0': 'Ledig',
        '1': 'Initierer',
        '2': 'ST_TRANSFORM',
        '4': 'Starter',
        '6': 'Genoptager',
        '16': 'Arbejder',
        '18': 'Genoptager',
        '32': 'På pause',
        '36': 'På pause',
        '38': 'Sætter på pause',
        '48': 'På pause',
        '50': 'Sætter på pause',
        '64': 'Færdig',
        '66': 'Færdiggør',
        '68': 'Forbereder',
        '128': 'Afbrudt',
        '256': 'Alarm',
        '512': 'Fatal',
        '-17': 'Patron IO-tilstand',
        '-10': 'Vedligeholdelsestilstand',
        '-2': 'Scanner',
        '-1': 'Vedligeholder',
        UNKNOWN: 'Ukendt',
    },
    calibration: {
        update_firmware_msg1: 'Din firmware understøtter ikke denne funktion. Opdater venligst firmware til v',
        update_firmware_msg2: 'eller nyere for at fortsætte。 (Menu > Maskine > [Din maskine] > Opdater firmware)',
        camera_calibration: 'Kamera kalibrering',
        diode_calibration: 'Diode laser modul kalibrering',
        module_calibration_printer: 'Printer modul kalibrering',
        module_calibration_2w_ir: 'Infrarødt modul kalibrering',
        back: 'TILBAGE',
        next: 'NÆSTE',
        skip: 'SPRING OVER',
        cancel: 'ANNULLER',
        finish: 'FÆRDIG',
        do_engraving: 'UDFØR GRAVERING',
        start_engrave: 'START GRAVERING',
        start_printing: 'START UDSKRIVNING',
        ask_for_readjust: 'Vil du springe graveringstrinnet over og derefter tage et billede og kalibrere direkte?',
        please_goto_beambox_first: 'Skift venligst til graveringstilstand (Beambox) for at bruge denne funktion.',
        please_place_paper: 'Anbring venligst et A4- eller brevstørrelse hvidt papir i venstre øverste hjørne af arbejdsområdet.',
        please_place_paper_center: 'Anbring venligst et A4- eller brevstørrelse hvidt papir i midten af arbejdsområdet.',
        please_place_dark_colored_paper: 'Anbring venligst et A4- eller brevstørrelse mørkt farvet papir i midten af arbejdsområdet.',
        please_refocus: {
            beambox: 'Juster venligst platformen til fokuspunktet (højden af den vendte acryl).',
            beamo: 'Juster venligst laserhovedet til at fokusere på graveringsobjektet (højden af den vendte acryl).',
            beamo_af: 'Dobbelttryk venligst på sideknappen på autofokus-tilbehøret og lad proben røre materialet blidt.',
            hexa: 'Dobbeltklik på højdejusteringsknappen for at løfte bordet op og få proben til at røre graveringsmaterialet.',
        },
        without_af: 'Uden autofokus',
        with_af: 'Med autofokus',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotation',
        x_ratio: 'X-forhold',
        y_ratio: 'Y-forhold',
        show_last_config: 'Vis sidste resultat',
        use_last_config: 'Brug sidste kalibreringsværdi',
        taking_picture: 'Tager billede...',
        analyze_result_fail: 'Kunne ikke analysere det optagede billede. <br/> Kontroller venligst: <br/> 1. Det optagede billede dækker helt med hvidt papir. <br/> 2. Platformen er korrekt fokuseret.',
        drawing_calibration_image: 'Tegner kalibreringsbillede...',
        calibrate_done: 'Kalibrering udført. Bedre kameranøjagtighed opnås ved præcis fokusering.',
        calibrate_done_diode: 'Kalibrering udført. Forskydning af diode-lasermodul er gemt.',
        hint_red_square: 'Juster venligst den røde firkants yderside med den skårne firkant.',
        hint_adjust_parameters: 'Brug disse parametre til at justere det røde kryds',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Når du kalibrerer laserdiodemodulet, er kameraet nødvendigt.\nSørg venligst for, at kameraet på denne maskine er kalibreret.\nJuster venligst platformen til fokuspunktet (højden af det nedadgående akryl)',
            beamo: 'Når du kalibrerer laserdiodemodulet, er kameraet nødvendigt.\nSørg venligst for, at kameraet på denne maskine er kalibreret.\nJuster venligst laserhovedet til at fokusere på graveringsobjektet (højden af det nedadgående akryl)',
        },
        downloading_pictures: 'Downloader billeder...',
        failed_to_download_pictures: '#848 Kunne ikke downloade billeder, kontakt venligst FLUX-support.',
        uploading_images: 'Uploader billeder...',
        calculating_camera_matrix: 'Beregner kameramatriks...',
        calculating_regression_parameters: 'Beregner regressionsparametre...',
        failed_to_calibrate_camera: '#848 Kunne ikke kalibrere kamera, kontakt venligst FLUX-support.',
        failed_to_save_calibration_results: '#849 Kunne ikke gemme kalibreringsresultater, prøv igen. Hvis dette bliver ved med at ske, skal du kontakte FLUX-support.',
        ador_autofocus_material: 'Tryk på "AF"-ikonet på maskinens hovedside i 3 sekunder, og lad sonden røre materialet blidt.',
        ador_autofocus_focusing_block: 'Tryk på "AF"-ikonet på maskinens hovedside i 3 sekunder, og lad sonden røre fokuseringsblokken.',
        align_red_cross_cut: 'Juster venligst midten af det røde kryds med skærekorset.',
        align_red_cross_print: 'Juster venligst midten af det røde kryds med det printede kryds.',
        retake: 'Tag billede igen',
        calibrate_camera_before_calibrate_modules: 'Udfør venligst kamera kalibrering før kalibrering af moduler.',
        check_checkpoint_data: 'Kontrolpunktdata',
        checking_checkpoint: 'Kontrollerer kontrolpunktdata...',
        found_checkpoint: 'Kontrolpunktdata fundet på din enhed. Ønsker du at gendanne fra kontrolpunkt?',
        use_old_camera_parameter: 'Ønsker du at bruge de aktuelle kamerelinseparametre?',
        downloading_checkpoint: 'Downloader kontrolpunktdata...',
        failed_to_parse_checkpoint: 'Kunne ikke analysere kontrolpunktdata.',
        check_device_pictures: 'Kontroller enhedens billeder',
        checking_pictures: 'Kontrollerer enhedens billeder...',
        no_picutre_found: '#846 Din enhed har ikke nogen tilgængelige rå fotos til kalibrering. Kontakt venligst FLUX support.',
        calibrating_with_device_pictures: 'Kalibrerer med enhedens billeder...',
        failed_to_calibrate_with_pictures: '#847 Kunne ikke kalibrere med enhedens billeder.',
        getting_plane_height: 'Får Højde på Plan...',
        preparing_to_take_picture: 'Forbereder til at tage billede...',
        put_paper: 'Læg papir',
        put_paper_step1: 'Venligst placer et A4- eller brevstørrelse hvidt papir i midten af arbejdsområdet.',
        put_paper_step2: 'Sørg for at sikre de fire hjørner af papiret for at sikre, at det ligger fladt.',
        put_paper_step3: 'Klik på "START GRAVERING".',
        solve_pnp_title: 'Juster markørpunkterne',
        solve_pnp_step1: 'Justér venligst graveringpunkterne i henhold til nummeret og den forventede position for hvert rødt markørpunkt.',
        solve_pnp_step2: 'Du kan trykke på "Tag billede igen" for at justere igen eller manuelt justere markørpositionerne.',
        align_olt: 'Position: Justér med graveringpunktet på den ydre øverste venstre.',
        align_ort: 'Position: Justér med graveringspunktet på den ydre øverste højre side.',
        align_olb: 'Position: Justér med graveringspunktet på den ydre nederste venstre side.',
        align_orb: 'Position: Justér med graveringspunktet på den ydre nederste højre side.',
        align_ilt: 'Position: Justér med graveringpunktet på den indre øverste venstre.',
        align_irt: 'Position: Justér med graveringspunktet på den indre øverste højre side.',
        align_ilb: 'Position: Justér med graveringspunktet på den indre nederste venstre side.',
        align_irb: 'Position: Justér med graveringspunktet på den indre nederste højre side.',
        elevate_and_cut: 'Hæv og Skær',
        elevate_and_cut_step_1: 'Placer et A4-størrelse lyst træ midt i arbejdsområdet, hævet til 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Brug Ador Prism Lift med en maksimal længde på 14mm sammen med et træ på mindst 6mm tykkelse.',
        with_prism_lift: 'Med Ador Prism Lift',
        without_prism_lift: 'Uden Ador Prism Lift',
        camera_parameter_saved_successfully: 'Kameraparameter gemt succesfuldt.',
        failed_to_save_camera_parameter: 'Kunne ikke gemme kameraparameter.',
        failed_to_solve_pnp: 'Kunne ikke løse kamerapositionen.',
    },
    input_machine_password: {
        require_password: '"%s" kræver en adgangskode',
        connect: 'FORBIND',
        password: 'Adgangskode',
    },
    tutorial: {
        skip: 'Spring over',
        welcome: 'VELKOMMEN',
        suggest_calibrate_camera_first: 'Vi anbefaler brugere at kalibrere kameraet først og fokusere igen før hver forhåndsvisning for optimale resultater.<br/>Vil du bekræfte at udføre kalibreringen nu?<br/>(Du kan springe det over nu og gøre det senere ved at klikke på "Menu" > "Maskine" > [Din Maskine] > "Kalibrer Kamera".)',
        camera_calibration_failed: 'Kamera Kalibrering Mislykkedes',
        ask_retry_calibration: 'Vil du kalibrere kameraet igen?',
        needNewUserTutorial: 'Vil du starte en tutorial?<br/>(Du kan springe det over nu og starte senere ved at klikke på "Hjælp" > "Vis Start Tutorial".)',
        needNewInterfaceTutorial: 'Vil du starte en tutorial for Beam Studios nye interface?<br/>(Du kan springe det over nu og starte senere ved at klikke på "Hjælp" > "Vis Interface Introduktion".)',
        next: 'NÆSTE',
        look_for_machine: 'Søger maskine til vejledning...',
        unable_to_find_machine: 'Kunne ikke finde maskine til vejledning. Vil du gå til forbindelsesindstillings siden, prøve igen eller springe vejledningen over?',
        skip_tutorial: 'Du har sprunget vejledningen over. Du kan altid starte vejledningen ved at klikke på "Hjælp" > "Vis start vejledning"',
        set_connection: 'Sæt forbindelse',
        retry: 'Prøv igen',
        newUser: {
            draw_a_circle: 'Tegn en cirkel',
            drag_to_draw: 'Træk for at tegne',
            infill: 'Aktiver udfyldning',
            switch_to_object_panel: 'Skift til objektpanel',
            switch_to_layer_panel: 'Skift til lag panel',
            set_preset_wood_engraving: 'Sæt forudindstilling: Træ - gravering',
            set_preset_wood_cut: 'Sæt forudindstilling: Træ - skæring',
            add_new_layer: 'Tilføj et nyt lag',
            draw_a_rect: 'Tegn et rektangel',
            switch_to_preview_mode: 'Skift til forhåndsvisningstilstand',
            preview_the_platform: 'Forhåndsvis platformen',
            end_preview_mode: 'Afslut forhåndsvisningstilstand',
            put_wood: '1. Anbring prøvetræet',
            adjust_focus: '2. Juster fokus',
            close_cover: '3. Luk låget',
            send_the_file: 'Send filen',
            end_alert: 'Er du sikker på, du vil afslutte vejledningen?',
            please_select_wood_engraving: 'Vælg venligst træsnit - gravering som forudindstilling.',
            please_select_wood_cutting: 'Vælg venligst træsnit - skæring som forudindstilling.',
        },
        newInterface: {
            camera_preview: 'Kameraforhåndsvisning',
            select_image_text: 'Vælg billede/tekst',
            basic_shapes: 'Grundlæggende former',
            pen_tool: 'Penneværktøj',
            add_new_layer: 'Tilføj nyt lag',
            rename_by_double_click: 'Omdøb ved dobbeltklik',
            drag_to_sort: 'Træk for at sortere',
            layer_controls: 'Højreklik for at vælge lagkontrol: Dupliker/Flet/Lås/Slet lag',
            switch_between_layer_panel_and_object_panel: 'Skift mellem lagpanel og objektpanel',
            align_controls: 'Justering af kontrol',
            group_controls: 'Gruppekontrol',
            shape_operation: 'Formhåndtering',
            flip: 'Vend',
            object_actions: 'Objekthandlinger',
            end_alert: 'Er du sikker på, du vil afslutte introduktionen til det nye brugerinterface?',
            select_machine: 'Vælg en maskine',
            start_work: 'Start arbejde',
        },
        gesture: {
            pan: 'Rul lærredet med to fingre.',
            zoom: 'Knib med to fingre for at zoome ind/ud på lærredet.',
            click: 'Tryk for at vælge objektet.',
            drag: 'Træk for at vælge flere objekter.',
            hold: 'Tryk og hold for at åbne genvejsmenuen.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Det var alt for selvstudiet. Nu er det tid til at gå i gang!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: '10W Diode laser',
        laser_20w_diode: '20W Diode laser',
        laser_2w_infrared: '2W Infrarød laser',
        printing: 'Printning',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Vil du konvertere Printmodulet til Lasermodulet?',
            convertFromPrintingModuleMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil farveindstillingerne i printlaget blive fjernet og indstillet i henhold til det aktuelle lag.',
            convertFromLaserModuleTitle: 'Vil du konvertere Lasermodulet til Printmodulet?',
            convertFromLaserModuleMsg: 'Vær opmærksom på, at hvis du gennemfører denne handling, vil indstillingerne for laserlaget blive fjernet og indstillet i henhold til det aktuelle lag.',
            importedDocumentContainsPrinting: 'Dokumentet indeholder printlag, vil du ændre arbejdsområde til Ador?',
            printingLayersCoverted: 'Printlagene er blevet konverteret til laserskærelag.',
            performPrintingCaliTitle: 'Udfør kalibrering af printmodulet',
            performPrintingCaliMsg: 'Klik på "Bekræft" for at udføre kalibreringen, eller få adgang til kalibrering via topmenuen.<br />(Maskine > [Dit maskinnavn] > Kalibrer printmodul)',
            performIRCaliTitle: 'Udfør kalibrering af infrarød modul',
            performIRCaliMsg: 'Klik på "Bekræft" for at udføre kalibreringen, eller få adgang til kalibrering via topmenuen.<br />(Maskine > [Dit maskinnavn] > Kalibrer infrarød modul)',
        },
        non_working_area: 'Ikke-arbejdsområde',
    },
    qr_code_generator: {
        title: 'QR-kodegenerator',
        placeholder: 'Indtast et link eller tekst',
        preview: 'Forhåndsvisning',
        error_tolerance: 'Fejltolerance',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Inverter baggrundsfarve',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Basic Box',
        coming_soon: 'Kommer snart',
        workarea: 'Arbejdsområde',
        max_dimension_tooltip: 'Maks. bredde/højde/dybde indstilling er %s.',
        volume: 'Volumen',
        outer: 'Ydre',
        inner: 'Indre',
        width: 'Bredde',
        height: 'Højde',
        depth: 'Dybde',
        cover: 'Dæksel',
        thickness: 'Tykkelse',
        add_option: 'Tilføj mulighed',
        joints: 'Samling',
        finger: 'Finger',
        finger_warning: 'Den indvendige sidelængde af boksen skal være mindst 6 mm (0,24 tommer) for at være kompatibel med fingersammenføjning.',
        tSlot: 'T-spor',
        tSlot_warning: 'Boksside længden skal være mindst 30 mm (1,18 tommer) for at være kompatibel med T-spor sammenføjning.',
        edge: 'Kant',
        tCount: 'T Tæl',
        tCount_tooltip: 'T-spor antallet gælder for den korte side; mængden på den lange side beregnes ud fra dens længde.',
        tDiameter: 'T Diameter',
        tLength: 'T Længde',
        continue_import: 'Fortsæt med at importere',
        customize: 'Tilpas',
        merge: 'Sammenføj',
        text_label: 'Etiket',
        beam_radius: 'Kerfkompensation',
        beam_radius_warning: 'Fjern kerfkompensation, når kantene eller samlingerne på kassen er korte for at sikre kassemontering',
        import: 'Importer',
        cancel: 'Annullér',
        reset: 'Nulstil',
        zoom: 'Zoom',
        control_tooltip: 'Venstre museknap for at rotere\nRul for at zoome\nHøjre museknap for at panorere',
        control_tooltip_touch: 'Træk for at rotere\nKnib sammen for at zoome\nTo fingre for at panorere',
    },
    my_cloud: {
        title: 'Min sky',
        loading_file: 'Indlæser...',
        no_file_title: 'Gem filer til Min sky for at komme i gang.',
        no_file_subtitle: 'Gå til Menu > "Fil" > "Gem i skyen"',
        file_limit: 'Gratis fil',
        upgrade: 'Opgrader',
        sort: {
            most_recent: 'Nyeste',
            oldest: 'Ældste',
            a_to_z: 'Navn: A - Å',
            z_to_a: 'Navn: Å - A',
        },
        action: {
            open: 'Åbn',
            rename: 'Omdøb',
            duplicate: 'Dupliker',
            download: 'Hent',
            delete: 'Slet',
            confirmFileDelete: 'Er du sikker på, at du vil slette denne fil? Denne handling kan ikke fortrydes.',
        },
        save_file: {
            choose_action: 'Gem fil:',
            save: 'Gem',
            save_new: 'Gem som ny fil',
            input_file_name: 'Indtast filnavn:',
            invalid_char: 'Ugyldige tegn:',
            storage_limit_exceeded: 'Din cloud-lager har nået øvre grænse. Slet venligst eventuelle unødvendige filer, før du gemmer nye.',
        },
    },
    camera_data_backup: {
        title: 'Kameradata Backup',
        no_picture_found: 'Ingen billeder fundet i maskinen.',
        folder_not_exists: 'Valgte mappe findes ikke.',
        incorrect_folder: 'Fejl ved upload af kalibreringsdata. Kontroller, om den valgte mappe er korrekt.',
        downloading_data: 'Downloader data...',
        estimated_time_left: 'Estimeret resterende tid:',
        uploading_data: 'Uploader data...',
        download_success: 'Kameradata er downloadet succesfuldt.',
        upload_success: 'Kameradata er uploadet succesfuldt.',
    },
    insecure_websocket: {
        extension_detected: 'Beam Studio Connect-udvidelse fundet',
        extension_detected_description: "Vi har registreret, at du har installeret Beam Studio Connect-udvidelsen. Klik venligst på 'Bekræft' for at omdirigere til HTTPS, eller klik på 'Annuller' for at fortsætte med at bruge HTTP.",
        extension_not_deteced: 'Kan ikke registrere Beam Studio Connect-udvidelse',
        extension_not_deteced_description: "For at bruge HTTPS skal du klikke på 'Bekræft' for at installere Beam Studio Connect-udvidelsen. Efter installation af udvidelsen skal du opdatere siden for at aktivere den.<br/>Ellers kan du klikke på linket nedenfor for at se, hvordan du bruger HTTP i Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Mål Autofokusområde',
        amount: 'Mængde',
        gap: 'Mellemrum',
        rows: 'Rækker',
        coloumns: 'Kolonner',
        row_gap: 'Rækker Mellemrum',
        column_gap: 'Kolonner Mellemrum',
        set_object_height: 'Indstil objektets højde',
        set_object_height_desc: 'Mål objektets maksimale tykkelse.',
        measure_area_help: 'Hvordan vælger man autofokusområdet til 3D-kurve?',
        reselect_area: 'Vælg område igen',
        start_autofocus: 'Start Autofokus',
        starting_measurement: 'Starter måling...',
        preview_3d_curve: 'Forhåndsvis 3D-kurve',
        apply_arkwork: 'Anvend kunstværk på 3D-kurve',
        apply_camera: 'Anvend kamerabillede på 3D-kurve',
        click_to_select_point: 'Klik for at vælge eller fravælge punkter til genmåling.',
        remeasure: 'Genmål',
        remeasuring_points: 'Genmåler punkter...',
    },
    pass_through: {
        title: 'Gennemgangstilstand',
    },
};
export default lang;
