// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-SvgNestButtons-module__container--QhIwj{display:flex;gap:12px}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/SvgNestButtons.module.scss"],"names":[],"mappings":"AAAA,mEACE,YAAA,CACA,QAAA","sourcesContent":[".container {\n  display: flex;\n  gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-views-beambox-SvgNestButtons-module__container--QhIwj"
};
export default ___CSS_LOADER_EXPORT___;
