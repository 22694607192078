// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__btn--uh2Fr{cursor:pointer;width:24px;height:24px;opacity:.5}.src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__btn--uh2Fr:hover:not(.src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__disabled--MXcOG){opacity:1}.src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__btn--uh2Fr.src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__disabled--MXcOG{opacity:.3;cursor:default}.src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__btn--uh2Fr img{filter:brightness(0)}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/Right-Panels/ConfigPanel/SaveConfigButton.module.scss"],"names":[],"mappings":"AAAA,wFACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CAEA,gMACE,SAAA,CAGF,oLACE,UAAA,CACA,cAAA,CAGF,4FACE,oBAAA","sourcesContent":[".btn {\n  cursor: pointer;\n  width: 24px;\n  height: 24px;\n  opacity: 0.5;\n\n  &:hover:not(.disabled) {\n    opacity: 1;\n  }\n\n  &.disabled {\n    opacity: 0.3;\n    cursor: default;\n  }\n\n  img {\n    filter: brightness(0);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__btn--uh2Fr",
	"disabled": "src-web-app-views-beambox-Right-Panels-ConfigPanel-SaveConfigButton-module__disabled--MXcOG"
};
export default ___CSS_LOADER_EXPORT___;
