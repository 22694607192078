var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { ConfigProvider, InputNumber, Slider, Switch } from 'antd';
import { Popover } from 'antd-mobile';
import history from 'app/svgedit/history/history';
import ImageData from 'helpers/image-data';
import i18n from 'helpers/i18n';
import isDev from 'helpers/is-dev';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import OptionPanelIcons from 'app/icons/option-panel/OptionPanelIcons';
import UnitInput from 'app/widgets/Unit-Input-v2';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { isMobile } from 'helpers/system-helper';
import { sliderTheme } from 'app/constants/antd-config';
import styles from './Image-Options.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const LANG = i18n.lang.beambox.right_panel.object_panel.option_panel;
class ImageOptions extends React.Component {
    constructor() {
        super(...arguments);
        this.thresholdCache = new Array(256).fill(null);
        this.currentCallId = 0;
        this.nextCallId = 1;
        this.changeAttribute = (changes) => {
            const { elem } = this.props;
            const batchCommand = new history.BatchCommand('Image Option Panel');
            const setAttribute = (key, value) => {
                svgCanvas.undoMgr.beginUndoableChange(key, [elem]);
                elem.setAttribute(key, value);
                const cmd = svgCanvas.undoMgr.finishUndoableChange();
                if (!cmd.isEmpty()) {
                    batchCommand.addSubCommand(cmd);
                }
            };
            const keys = Object.keys(changes);
            for (let i = 0; i < keys.length; i += 1) {
                const key = keys[i];
                setAttribute(key, changes[key]);
            }
            if (!batchCommand.isEmpty()) {
                svgCanvas.undoMgr.addCommandToHistory(batchCommand);
            }
        };
        this.generateImageData = (isShading, threshold) => {
            const { elem } = this.props;
            return new Promise((resolve) => {
                ImageData(elem.getAttribute('origImage'), {
                    width: parseFloat(elem.getAttribute('width')),
                    height: parseFloat(elem.getAttribute('height')),
                    grayscale: {
                        is_rgba: true,
                        is_shading: isShading,
                        threshold,
                        is_svg: false,
                    },
                    onComplete: (result) => {
                        resolve(result);
                    },
                });
            });
        };
        this.handleGradientClick = () => __awaiter(this, void 0, void 0, function* () {
            const { elem, updateObjectPanel } = this.props;
            let isShading = elem.getAttribute('data-shading') === 'true';
            isShading = !isShading;
            const threshold = isShading ? 254 : 128;
            const imageData = yield this.generateImageData(isShading, threshold);
            const { pngBase64 } = imageData;
            this.changeAttribute({
                'data-shading': isShading,
                'data-threshold': isShading ? 254 : 128,
                'xlink:href': pngBase64,
            });
            this.forceUpdate();
            updateObjectPanel();
        });
        this.handlePwmClick = () => __awaiter(this, void 0, void 0, function* () {
            const { elem } = this.props;
            const cur = elem.getAttribute('data-pwm') === '1';
            this.changeAttribute({
                'data-pwm': cur ? '0' : '1',
            });
            this.forceUpdate();
        });
        this.handleThresholdChange = (val) => __awaiter(this, void 0, void 0, function* () {
            const callId = this.nextCallId;
            this.nextCallId += 1;
            let result = this.thresholdCache[val];
            if (!result) {
                const { elem } = this.props;
                const isShading = elem.getAttribute('data-shading') === 'true';
                const imageData = yield this.generateImageData(isShading, val);
                result = imageData.pngBase64;
                this.thresholdCache[val] = result;
            }
            if (callId >= this.currentCallId) {
                this.currentCallId = callId;
                this.changeAttribute({
                    'data-threshold': val,
                    'xlink:href': result,
                });
                this.forceUpdate();
            }
        });
    }
    renderGradientBlock() {
        const { elem } = this.props;
        const isGradient = elem.getAttribute('data-shading') === 'true';
        const isPwm = elem.getAttribute('data-pwm') === '1';
        return isMobile() ? (React.createElement(React.Fragment, null,
            React.createElement(ObjectPanelItem.Item, { id: "gradient", content: React.createElement(Switch, { checked: isGradient }), label: LANG.shading, onClick: this.handleGradientClick }),
            isGradient && isDev() && (React.createElement(ObjectPanelItem.Item, { id: "pwm", content: React.createElement(Switch, { checked: isPwm }), label: "tPWM", onClick: this.handlePwmClick })))) : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles['option-block'], key: "gradient" },
                React.createElement("div", { className: styles.label }, LANG.shading),
                React.createElement(Switch, { size: "small", checked: isGradient, onChange: this.handleGradientClick })),
            isGradient && isDev() && (React.createElement("div", { className: styles['option-block'], key: "pwm" },
                React.createElement("div", { className: styles.label }, "tPWM"),
                React.createElement(Switch, { size: "small", checked: isPwm, onChange: this.handlePwmClick })))));
    }
    renderThresholdBlock() {
        const { elem } = this.props;
        const isGradient = elem.getAttribute('data-shading') === 'true';
        const activeKey = ObjectPanelController.getActiveKey();
        const visible = activeKey === 'threshold';
        if (isGradient) {
            return null;
        }
        const threshold = parseInt(elem.getAttribute('data-threshold'), 10) || 128;
        return isMobile() ? (React.createElement(Popover, { visible: visible, content: React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.threshold_short),
                React.createElement(ConfigProvider, { theme: { token: { borderRadius: 100 } } },
                    React.createElement(InputNumber, { className: styles.input, type: "number", min: 1, max: 255, value: threshold, precision: 0, onChange: this.handleThresholdChange, controls: false })),
                React.createElement(Slider, { className: styles.slider, min: 1, max: 255, step: 1, marks: { 128: '128' }, value: threshold, onChange: this.handleThresholdChange })) },
            React.createElement(ObjectPanelItem.Item, { id: "threshold", content: React.createElement(OptionPanelIcons.Threshold, null), label: LANG.threshold_short, autoClose: false }))) : (React.createElement(Fragment, { key: "threshold" },
            React.createElement("div", { className: classNames(styles['option-block'], styles['with-slider']) },
                React.createElement("div", { className: styles.label }, LANG.threshold),
                React.createElement(UnitInput, { min: 1, max: 255, decimal: 0, unit: "", className: { [styles['option-input']]: true }, defaultValue: threshold, getValue: this.handleThresholdChange })),
            React.createElement(ConfigProvider, { theme: sliderTheme },
                React.createElement(Slider, { min: 1, max: 255, step: 1, value: threshold, onChange: this.handleThresholdChange }))));
    }
    render() {
        return isMobile() ? (React.createElement(React.Fragment, null,
            this.renderGradientBlock(),
            this.renderThresholdBlock())) : (React.createElement("div", { className: styles.options },
            this.renderGradientBlock(),
            this.renderThresholdBlock()));
    }
}
export default ImageOptions;
