import * as React from 'react';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
function Mask({ maskOptions, updateBeamboxPreferenceChange }) {
    const lang = i18n.lang;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.mask),
        React.createElement(SelectControl, { label: lang.settings.mask, url: lang.settings.help_center_urls.mask, id: "set-mask", options: maskOptions, onChange: (e) => updateBeamboxPreferenceChange('enable_mask', e.target.value) })));
}
export default Mask;
