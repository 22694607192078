import React from 'react';
import { Col, Form, Input, Row, Slider } from 'antd';
import constant from 'app/actions/beambox/constant';
import i18n from 'helpers/i18n';
const LANG = i18n.lang.beambox.document_panel;
function EngraveDpiSlider({ value, onChange }) {
    const dpiMap = ['low', 'medium', 'high', 'ultra'];
    const onSliderValueChange = (num) => {
        onChange(dpiMap[num]);
    };
    return (React.createElement(Form.Item, { label: LANG.engrave_dpi },
        React.createElement(Row, { gutter: [8, 0] },
            React.createElement(Col, { span: 12 },
                React.createElement(Slider, { min: 0, max: 3, value: dpiMap.indexOf(value), onChange: onSliderValueChange, tooltip: { formatter: (num) => constant.dpiValueMap[dpiMap[num]] } })),
            React.createElement(Col, { span: 12 },
                React.createElement(Input, { value: `${LANG[value]} (${constant.dpiValueMap[value]} DPI)`, disabled: true })))));
}
export default EngraveDpiSlider;
