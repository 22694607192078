/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import Controls from 'app/components/settings/Control';
import FontFuncs from 'app/actions/beambox/font-funcs';
import i18n from 'helpers/i18n';
import isDev from 'helpers/is-dev';
import SelectControl from 'app/components/settings/SelectControl';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/Unit-Input-v2';
import { getWorkarea } from 'app/constants/workarea-constants';
const fontFamilies = FontFuncs.requestAvailableFontFamilies(true);
function Editor({ defaultUnit, x0, y0, selectedModel, guideSelectionOptions, imageDownsamplingOptions, antiAliasingOptions, continuousDrawingOptions, simplifyClipperPath, enableLowSpeedOptions, enableCustomBacklashOptions, updateConfigChange, updateBeamboxPreferenceChange, updateModel, }) {
    var _a;
    const { lang } = i18n;
    const [defaultFont, updateDefaultFont] = React.useState(storage.get('default-font') || {
        family: 'Arial',
        style: 'Regular',
    });
    const fontOptions = fontFamilies.map((family) => {
        const fontName = FontFuncs.fontNameMap.get(family);
        const label = typeof fontName === 'string' ? fontName : family;
        return {
            value: family,
            label,
            selected: family === defaultFont.family,
        };
    });
    const fontStyleOptions = FontFuncs.requestFontsOfTheFontFamily(defaultFont.family).map((font) => ({
        value: font.postscriptName,
        label: font.style,
        selected: font.style === defaultFont.style,
    }));
    const onSelectFont = (family) => {
        const fonts = FontFuncs.requestFontsOfTheFontFamily(family);
        const newDefaultFont = fonts.filter((font) => font.style === 'Regular')[0] || fonts[0];
        storage.set('default-font', {
            family: newDefaultFont.family,
            postscriptName: newDefaultFont.postscriptName,
            style: newDefaultFont.style,
        });
        updateDefaultFont({
            family: newDefaultFont.family,
            postscriptName: newDefaultFont.postscriptName,
            style: newDefaultFont.style,
        });
    };
    const onSelectFontStyle = (postscriptName) => {
        const newDefaultFont = FontFuncs.getFontOfPostscriptName(postscriptName);
        storage.set('default-font', {
            family: newDefaultFont.family,
            postscriptName: newDefaultFont.postscriptName,
            style: newDefaultFont.style,
        });
        updateDefaultFont({
            family: newDefaultFont.family,
            postscriptName: newDefaultFont.postscriptName,
            style: newDefaultFont.style,
        });
    };
    const modelOptions = [
        {
            value: 'fbm1',
            label: 'beamo',
            selected: selectedModel === 'fbm1',
        },
        {
            value: 'fbb1b',
            label: 'Beambox',
            selected: selectedModel === 'fbb1b',
        },
        {
            value: 'fbb1p',
            label: 'Beambox Pro',
            selected: selectedModel === 'fbb1p',
        },
        {
            value: 'fhexa1',
            label: 'HEXA',
            selected: selectedModel === 'fhexa1',
        },
        {
            value: 'ado1',
            label: 'Ador',
            selected: selectedModel === 'ado1',
        },
    ];
    const workarea = getWorkarea(selectedModel);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.editor),
        React.createElement(SelectControl, { id: "set-default-units", label: lang.settings.default_units, options: [
                {
                    value: 'mm',
                    label: lang.menu.mm,
                    selected: defaultUnit === 'mm',
                },
                {
                    value: 'inches',
                    label: lang.menu.inches,
                    selected: defaultUnit === 'inches',
                },
            ], onChange: (e) => updateConfigChange('default-units', e.target.value) }),
        React.createElement(SelectControl, { id: "set-default-font-family", label: lang.settings.default_font_family, options: fontOptions, onChange: (e) => onSelectFont(e.target.value) }),
        React.createElement(SelectControl, { id: "set-default-font-style", label: lang.settings.default_font_style, options: fontStyleOptions, onChange: (e) => onSelectFontStyle(e.target.value) }),
        React.createElement(SelectControl, { id: "set-default-model", label: lang.settings.default_beambox_model, options: modelOptions, onChange: (e) => {
                updateBeamboxPreferenceChange('model', e.target.value);
                updateModel(e.target.value);
            } }),
        React.createElement(SelectControl, { label: lang.settings.guides, id: "set-guide", options: guideSelectionOptions, onChange: (e) => updateBeamboxPreferenceChange('show_guides', e.target.value) }),
        React.createElement(Controls, { label: lang.settings.guides_origin },
            React.createElement("span", { className: "font2", style: { marginRight: '10px', lineHeight: '32px' } }, "X"),
            React.createElement(UnitInput, { id: "guide-x-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: workarea.width, defaultValue: x0, getValue: (val) => updateBeamboxPreferenceChange('guide_x0', val), forceUsePropsUnit: true, className: { half: true } }),
            React.createElement("span", { className: "font2", style: { marginRight: '10px', lineHeight: '32px' } }, "Y"),
            React.createElement(UnitInput, { id: "guide-y-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: (_a = workarea.displayHeight) !== null && _a !== void 0 ? _a : workarea.height, defaultValue: y0, getValue: (val) => updateBeamboxPreferenceChange('guide_y0', val), forceUsePropsUnit: true, className: { half: true } })),
        React.createElement(SelectControl, { id: "set-bitmap-quality", label: lang.settings.image_downsampling, url: lang.settings.help_center_urls.image_downsampling, options: imageDownsamplingOptions, onChange: (e) => updateBeamboxPreferenceChange('image_downsampling', e.target.value) }),
        React.createElement(SelectControl, { id: "set-anti-aliasing", label: lang.settings.anti_aliasing, url: lang.settings.help_center_urls.anti_aliasing, options: antiAliasingOptions, onChange: (e) => updateBeamboxPreferenceChange('anti-aliasing', e.target.value) }),
        React.createElement(SelectControl, { id: "set-continuous-drawingg", label: lang.settings.continuous_drawing, url: lang.settings.help_center_urls.continuous_drawing, options: continuousDrawingOptions, onChange: (e) => updateBeamboxPreferenceChange('continuous_drawing', e.target.value) }),
        React.createElement(SelectControl, { id: "set-simplify-clipper-path", label: lang.settings.simplify_clipper_path, url: lang.settings.help_center_urls.simplify_clipper_path, options: simplifyClipperPath, onChange: (e) => updateBeamboxPreferenceChange('simplify_clipper_path', e.target.value) }),
        React.createElement(SelectControl, { id: "set-enable-low-speed", label: lang.settings.enable_low_speed, options: enableLowSpeedOptions, onChange: (e) => updateBeamboxPreferenceChange('enable-low-speed', e.target.value) }),
        isDev() && (React.createElement(SelectControl, { id: "set-enable-custom-backlash", label: lang.settings.enable_custom_backlash, options: enableCustomBacklashOptions, onChange: (e) => updateBeamboxPreferenceChange('enable-custom-backlash', e.target.value) }))));
}
export default Editor;
