import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import constant from 'app/actions/beambox/constant';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import LayerModule, { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import i18n from 'helpers/i18n';
import moduleBoundary from 'app/constants/layer-module/module-boundary';
import moduleOffsets from 'app/constants/layer-module/module-offsets';
import workareaManager from 'app/svgedit/workarea';
const { svgedit } = window;
const canvasEventEmitter = eventEmitterFactory.createEventEmitter('canvas');
let boundarySvg;
let boundaryPath;
let boundaryDescText;
const createBoundary = () => {
    var _a;
    boundarySvg = document.createElementNS(svgedit.NS.SVG, 'svg');
    boundaryPath = document.createElementNS(svgedit.NS.SVG, 'path');
    boundaryDescText = document.createElementNS(svgedit.NS.SVG, 'text');
    (_a = document.getElementById('canvasBackground')) === null || _a === void 0 ? void 0 : _a.appendChild(boundarySvg);
    const { width, height } = workareaManager;
    boundarySvg.appendChild(boundaryPath);
    boundarySvg.appendChild(boundaryDescText);
    boundarySvg.setAttribute('id', 'module-boundary');
    boundarySvg.setAttribute('x', '0');
    boundarySvg.setAttribute('y', '0');
    boundarySvg.setAttribute('width', '100%');
    boundarySvg.setAttribute('height', '100%');
    boundarySvg.setAttribute('viewBox', `0 0 ${width} ${height}`);
    boundarySvg.setAttribute('style', 'pointer-events:none');
    boundaryPath.setAttribute('fill', '#CCC');
    boundaryPath.setAttribute('fill-opacity', '0.4');
    boundaryPath.setAttribute('fill-rule', 'evenodd');
    boundaryPath.setAttribute('stroke', 'none');
    boundaryPath.setAttribute('style', 'pointer-events:none');
    boundaryDescText.setAttribute('font-size', '80');
    boundaryDescText.setAttribute('font-weight', 'bold');
    boundaryDescText.setAttribute('fill', '#999');
    boundaryDescText.setAttribute('stroke', 'none');
    boundaryDescText.setAttribute('paint-order', 'stroke');
    boundaryDescText.setAttribute('style', 'pointer-events:none');
    const textNode = document.createTextNode(i18n.lang.layer_module.non_working_area);
    boundaryDescText.appendChild(textNode);
};
const updateCanvasSize = () => {
    const { width, height } = workareaManager;
    const viewBox = `0 0 ${width} ${height}`;
    boundarySvg === null || boundarySvg === void 0 ? void 0 : boundarySvg.setAttribute('viewBox', viewBox);
};
canvasEventEmitter.on('canvas-change', updateCanvasSize);
const update = (module) => {
    const workarea = BeamboxPreference.read('workarea');
    if (!modelsWithModules.has(workarea)) {
        boundaryPath === null || boundaryPath === void 0 ? void 0 : boundaryPath.setAttribute('d', '');
        boundaryDescText === null || boundaryDescText === void 0 ? void 0 : boundaryDescText.setAttribute('display', 'none');
        return;
    }
    if (!boundaryPath)
        createBoundary();
    const { width: w, height: h } = workareaManager;
    const viewBox = `0 0 ${w} ${h}`;
    boundarySvg === null || boundarySvg === void 0 ? void 0 : boundarySvg.setAttribute('viewBox', viewBox);
    const d1 = `M0,0H${w}V${h}H0V0`;
    const { dpmm } = constant;
    let { top, left, bottom, right } = moduleBoundary[module];
    const offsets = Object.assign(Object.assign({}, moduleOffsets), BeamboxPreference.read('module-offsets'));
    const [offsetX, offsetY] = offsets[module];
    if (module === LayerModule.PRINTER && offsetY < 0)
        top = Math.max(top + offsetY, 0);
    if (offsetX >= 0)
        left = Math.max(left, offsetX);
    else
        right = Math.max(right, -offsetX);
    const rotaryMode = BeamboxPreference.read('rotary_mode');
    if (rotaryMode) {
        top = 0;
        bottom = 0;
    }
    else if (offsetY >= 0) {
        top = Math.max(top, offsetY);
        bottom = Math.max(bottom - offsetY, 0);
    }
    else
        bottom = Math.max(bottom, -offsetY);
    if (!top && !bottom && !left && !right) {
        boundaryPath === null || boundaryPath === void 0 ? void 0 : boundaryPath.setAttribute('d', '');
        boundaryDescText === null || boundaryDescText === void 0 ? void 0 : boundaryDescText.setAttribute('display', 'none');
        return;
    }
    top *= dpmm;
    left *= dpmm;
    bottom *= dpmm;
    right *= dpmm;
    const d2 = `M${left},${top}H${w - right}V${h - bottom}H${left}V${top}`;
    boundaryPath === null || boundaryPath === void 0 ? void 0 : boundaryPath.setAttribute('d', `${d1} ${d2}`);
    boundaryDescText === null || boundaryDescText === void 0 ? void 0 : boundaryDescText.removeAttribute('display');
    if (top >= bottom) {
        boundaryDescText === null || boundaryDescText === void 0 ? void 0 : boundaryDescText.setAttribute('x', `${top / 2 - 40}`);
        boundaryDescText === null || boundaryDescText === void 0 ? void 0 : boundaryDescText.setAttribute('y', `${top / 2 + 40} `);
    }
    else {
        boundaryDescText === null || boundaryDescText === void 0 ? void 0 : boundaryDescText.setAttribute('x', `${bottom / 2 - 40}`);
        boundaryDescText === null || boundaryDescText === void 0 ? void 0 : boundaryDescText.setAttribute('y', `${h - bottom / 2 + 40}`);
    }
};
export default {
    update,
};
