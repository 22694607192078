var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Checkbox, Col, ConfigProvider, Form, Modal, Row, Switch } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import changeWorkarea from 'app/svgedit/operations/changeWorkarea';
import constant from 'app/actions/beambox/constant';
import diodeBoundaryDrawer from 'app/actions/canvas/diode-boundary-drawer';
import EngraveDpiSlider from 'app/widgets/EngraveDpiSlider';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import LayerModule, { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import OpenBottomBoundaryDrawer from 'app/actions/beambox/open-bottom-boundary-drawer';
import presprayArea from 'app/actions/canvas/prespray-area';
import rotaryAxis from 'app/actions/canvas/rotary-axis';
import Select from 'app/widgets/AntdSelect';
import useI18n from 'helpers/useI18n';
import { getWorkarea } from 'app/constants/workarea-constants';
import styles from './DocumentSettings.module.scss';
const eventEmitter = eventEmitterFactory.createEventEmitter('dpi-info');
const workareaOptions = [
    { label: 'beamo', value: 'fbm1' },
    { label: 'Beambox', value: 'fbb1b' },
    { label: 'Beambox Pro', value: 'fbb1p' },
    { label: 'HEXA', value: 'fhexa1' },
    { label: 'Ador', value: 'ado1' },
];
const DocumentSettings = ({ unmount }) => {
    const lang = useI18n();
    const langDocumentSettings = lang.beambox.document_panel;
    const [form] = Form.useForm();
    const [engraveDpi, setEngraveDpi] = useState(BeamboxPreference.read('engrave_dpi'));
    // state for engrave dpi v2
    // const [engraveDpiValue, setEngraveDpiValue] = useState(
    //   BeamboxPreference.read('engrave-dpi-value') || dpiMap[engraveDpi] || 250
    // );
    const origWorkarea = useMemo(() => BeamboxPreference.read('workarea'), []);
    const [workarea, setWorkarea] = useState(origWorkarea || 'fbb1b');
    const [rotaryMode, setRotaryMode] = useState(BeamboxPreference.read('rotary_mode'));
    const [extendRotaryWorkarea, setExtendRotaryWorkarea] = useState(!!BeamboxPreference.read('extend-rotary-workarea'));
    const [borderlessMode, setBorderlessMode] = useState(BeamboxPreference.read('borderless') === true);
    const [enableDiode, setEnableDiode] = useState(BeamboxPreference.read('enable-diode') === true);
    const [enableAutofocus, setEnableAutofocus] = useState(BeamboxPreference.read('enable-autofocus') === true);
    const handleEngraveDpiChange = (value) => setEngraveDpi(value);
    const handleWorkareaChange = (value) => setWorkarea(value);
    const handleRotaryModeChange = (on) => setRotaryMode(on ? 1 : 0);
    const handleBorderlessModeChange = (value) => setBorderlessMode(value);
    const handleDiodeModuleChange = (value) => setEnableDiode(value);
    const handleAutofocusModuleChange = (value) => setEnableAutofocus(value);
    const rotaryModels = useMemo(() => getWorkarea(workarea).rotary, [workarea]);
    useEffect(() => {
        if (!rotaryModels.includes(rotaryMode)) {
            form.setFieldValue('rotary_mode', 0);
            handleRotaryModeChange(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rotaryModels]);
    const handleSave = () => {
        BeamboxPreference.write('engrave_dpi', engraveDpi);
        eventEmitter.emit('UPDATE_DPI', engraveDpi);
        // state for engrave dpi v2
        // BeamboxPreference.write('engrave-dpi-value', engraveDpiValue);
        BeamboxPreference.write('borderless', borderlessMode);
        BeamboxPreference.write('enable-diode', enableDiode);
        BeamboxPreference.write('enable-autofocus', enableAutofocus);
        const workareaChanged = workarea !== BeamboxPreference.read('workarea');
        const rotaryChanged = rotaryMode !== BeamboxPreference.read('rotary_mode') ||
            extendRotaryWorkarea !== (!!BeamboxPreference.read('extend-rotary-workarea'));
        BeamboxPreference.write('rotary_mode', rotaryMode);
        BeamboxPreference.write('extend-rotary-workarea', extendRotaryWorkarea);
        if (workareaChanged || rotaryChanged) {
            changeWorkarea(workarea, { toggleModule: workareaChanged });
            rotaryAxis.toggleDisplay();
            presprayArea.togglePresprayArea();
        }
        else {
            // this is called in changeWorkarea
            OpenBottomBoundaryDrawer.update();
            if (enableDiode)
                diodeBoundaryDrawer.show();
            else
                diodeBoundaryDrawer.hide();
        }
    };
    const doesSupportOpenBottom = constant.addonsSupportList.openBottom.includes(workarea);
    const doesSupportHybrid = constant.addonsSupportList.hybridLaser.includes(workarea);
    const doesSupportAutofocus = constant.addonsSupportList.autoFocus.includes(workarea);
    return (React.createElement(Modal, { open: true, centered: true, title: langDocumentSettings.document_settings, onCancel: unmount, onOk: () => __awaiter(void 0, void 0, void 0, function* () {
            if (origWorkarea !== workarea &&
                modelsWithModules.has(origWorkarea) &&
                !modelsWithModules.has(workarea) &&
                document.querySelectorAll(`g.layer[data-module="${LayerModule.PRINTER}"]`).length) {
                const res = yield new Promise((resolve) => {
                    alertCaller.popUp({
                        id: 'save-document-settings',
                        message: langDocumentSettings.notification.changeFromPrintingWorkareaTitle,
                        messageIcon: 'notice',
                        buttonType: alertConstants.CONFIRM_CANCEL,
                        onConfirm: () => resolve(true),
                        onCancel: () => resolve(false),
                    });
                });
                if (!res)
                    return;
            }
            handleSave();
            unmount();
        }), cancelText: langDocumentSettings.cancel, okText: langDocumentSettings.save },
        React.createElement(Form, { form: form, labelCol: { span: 6 }, wrapperCol: { span: 18 } },
            React.createElement(EngraveDpiSlider, { value: engraveDpi, onChange: handleEngraveDpiChange }),
            React.createElement(Form.Item, { name: "workarea", initialValue: workarea, label: langDocumentSettings.workarea },
                React.createElement(Select, { bordered: true, onChange: handleWorkareaChange }, workareaOptions.map((option) => (React.createElement(Select.Option, { key: option.value, value: option.value }, option.label))))),
            React.createElement("strong", null, langDocumentSettings.add_on),
            React.createElement(ConfigProvider, { theme: {
                    components: {
                        Form: {
                            itemMarginBottom: 0,
                        },
                    },
                } },
                React.createElement(Row, null,
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { name: "rotary_mode", className: classNames({ [styles.disabled]: rotaryModels.length === 1 }), label: langDocumentSettings.rotary_mode, labelCol: { span: 12, offset: 0 } },
                            React.createElement(Switch, { checked: rotaryMode !== 0, disabled: rotaryModels.length === 1, onChange: handleRotaryModeChange })),
                        React.createElement("div", { className: styles.subCheckbox }, workarea === 'ado1' && rotaryMode !== 0 && (React.createElement(Checkbox, { checked: extendRotaryWorkarea, onChange: (e) => setExtendRotaryWorkarea(e.target.checked) }, langDocumentSettings.extend_workarea)))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { name: "borderless_mode", className: classNames({ [styles.disabled]: !doesSupportOpenBottom }), label: langDocumentSettings.borderless_mode, labelCol: { span: 12, offset: 0 } },
                            React.createElement(Switch, { checked: doesSupportOpenBottom && borderlessMode, disabled: !doesSupportOpenBottom, onChange: handleBorderlessModeChange })))),
                React.createElement(Row, null,
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { name: "autofocus-module", className: classNames({ [styles.disabled]: !doesSupportAutofocus }), label: langDocumentSettings.enable_autofocus, labelCol: { span: 12, offset: 0 } },
                            React.createElement(Switch, { checked: doesSupportAutofocus && enableAutofocus, disabled: !doesSupportAutofocus, onChange: handleAutofocusModuleChange }))),
                    React.createElement(Col, { span: 12 },
                        React.createElement(Form.Item, { name: "diode_module", className: classNames({ [styles.disabled]: !doesSupportHybrid }), label: langDocumentSettings.enable_diode, labelCol: { span: 12, offset: 0 } },
                            React.createElement(Switch, { checked: doesSupportHybrid && enableDiode, disabled: !doesSupportHybrid, onChange: handleDiodeModuleChange }))))))));
};
export default DocumentSettings;
