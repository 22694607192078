import classNames from 'classnames';
import React, { memo, useContext, useMemo } from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import history from 'app/svgedit/history/history';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import UnitInput from 'app/widgets/Unit-Input-v2';
import useI18n from 'helpers/useI18n';
import { CUSTOM_PRESET_CONSTANT, DataType, writeData } from 'helpers/layer/layer-config-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import ConfigPanelContext from './ConfigPanelContext';
import styles from './Block.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const RepeatBlock = ({ type = 'default', }) => {
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel;
    const { selectedLayers, state, dispatch, initState } = useContext(ConfigPanelContext);
    const { repeat } = state;
    const timeEstimationButtonEventEmitter = useMemo(() => eventEmitterFactory.createEventEmitter('time-estimation-button'), []);
    const handleChange = (value) => {
        dispatch({
            type: 'change',
            payload: { repeat: value, configName: CUSTOM_PRESET_CONSTANT },
        });
        timeEstimationButtonEventEmitter.emit('SET_ESTIMATED_TIME', null);
        if (type !== 'modal') {
            const batchCmd = new history.BatchCommand('Change repeat');
            selectedLayers.forEach((layerName) => {
                writeData(layerName, DataType.repeat, value, { batchCmd });
                writeData(layerName, DataType.configName, CUSTOM_PRESET_CONSTANT, { batchCmd });
            });
            batchCmd.onAfter = initState;
            svgCanvas.addCommandToHistory(batchCmd);
        }
    };
    return type === 'panel-item' ? (React.createElement(ObjectPanelItem.Number, { id: "repeat", label: t.repeat, value: repeat.value, min: 1, max: 100, updateValue: handleChange, unit: t.times, decimal: 0 })) : (React.createElement("div", { className: classNames(styles.panel, styles['without-drag']) },
        React.createElement("span", { className: styles.title }, t.repeat),
        React.createElement(UnitInput, { id: "repeat", className: { [styles.input]: true }, min: 1, max: 100, unit: t.times, defaultValue: repeat.value, getValue: handleChange, decimal: 0, displayMultiValue: repeat.hasMultiValue })));
};
export default memo(RepeatBlock);
