var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * API image tracer
 * Ref: none
 */
import importSvg from 'app/svgedit/operations/import/importSvg';
import Websocket from 'helpers/websocket';
import { DataType, writeData } from 'helpers/layer/layer-config-helper';
const init = () => {
    const events = {
        onMessage: (data) => __awaiter(void 0, void 0, void 0, function* () {
            if (data.svg) {
                yield importSvg(new Blob([data.svg], { type: 'text/plain' }), { isFromAI: true });
                if (data.layerData) {
                    const layerDataJSON = JSON.parse(data.layerData);
                    const layerNames = Object.keys(layerDataJSON);
                    for (let i = 0; i < layerNames.length; i += 1) {
                        const layerName = layerNames[i];
                        const { name, speed, power } = layerDataJSON[layerName];
                        writeData(name, DataType.speed, parseInt(speed, 10));
                        writeData(name, DataType.strength, parseInt(power, 10));
                    }
                }
            }
        }),
        onError: (response) => {
            console.log('AI extension error: ', response);
        },
        onFatal: (response) => {
            console.log('AI extension fatal error: ', response);
        },
        onOpen: () => {
            console.log('AI extension connected');
        },
    };
    const ws = Websocket({
        method: 'push-studio',
        onMessage: (data) => {
            events.onMessage(data);
        },
        onError: (response) => {
            events.onError(response);
        },
        onFatal: (response) => {
            events.onFatal(response);
        },
    });
    return {
        connection: ws,
    };
};
export default {
    init,
};
