import { getSVGAsync } from 'helpers/svg-editor-helper';
import history from './history';
let svgCanvas = null;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
export class UndoManager {
    constructor(historyEventHandler) {
        this.handler = historyEventHandler || null;
        this.undoStackPointer = 0;
        this.undoStack = [];
        // this is the stack that stores the original values, the elements and
        // the attribute name for begin/finish
        this.undoChangeStackPointer = -1;
        this.undoableChangeStack = [];
    }
    setHandler(historyEventHandler) {
        this.handler = historyEventHandler;
    }
    ;
    resetUndoStack() {
        this.undoStack = [];
        this.undoStackPointer = 0;
    }
    getUndoStackSize() {
        return this.undoStackPointer;
    }
    getRedoStackSize() {
        return this.undoStack.length - this.undoStackPointer;
    }
    getNextUndoCommandText() {
        return this.undoStackPointer > 0 ? this.undoStack[this.undoStackPointer - 1].getText() : '';
    }
    getNextRedoCommandText() {
        return this.undoStackPointer < this.undoStack.length
            ? this.undoStack[this.undoStackPointer].getText()
            : '';
    }
    undo() {
        if (this.undoStackPointer > 0) {
            svgCanvas.setHasUnsavedChange(true);
            this.undoStackPointer -= 1;
            const cmd = this.undoStack[this.undoStackPointer];
            cmd.unapply(this.handler);
        }
    }
    redo() {
        if (this.undoStackPointer < this.undoStack.length && this.undoStack.length > 0) {
            svgCanvas.setHasUnsavedChange(true);
            const cmd = this.undoStack[this.undoStackPointer];
            this.undoStackPointer += 1;
            cmd.apply(this.handler);
        }
    }
    addCommandToHistory(cmd) {
        // FIXME: we MUST compress consecutive text changes to the same element
        // (right now each keystroke is saved as a separate command that includes the
        // entire text contents of the text element)
        // TODO: consider limiting the history that we store here (need to do some slicing)
        // if our stack pointer is not at the end, then we have to remove
        // all commands after the pointer and insert the new command
        if (this.undoStackPointer < this.undoStack.length && this.undoStack.length > 0) {
            this.undoStack = this.undoStack.splice(0, this.undoStackPointer);
        }
        this.undoStack.push(cmd);
        this.undoStackPointer = this.undoStack.length;
        const isInitCommand = this.undoStack.length === 1 && cmd.getText() === 'Create Layer';
        if (svgCanvas && !isInitCommand) {
            svgCanvas.setHasUnsavedChange(true);
        }
        // console.log(this.undoStack);
    }
    beginUndoableChange(attrName, elems) {
        this.undoChangeStackPointer += 1;
        const p = this.undoChangeStackPointer;
        const elements = elems.filter((elem) => !!elem);
        const oldValues = elements.map((elem) => elem.getAttribute(attrName));
        this.undoableChangeStack[p] = { attrName, oldValues, elements };
    }
    finishUndoableChange() {
        const p = this.undoChangeStackPointer;
        this.undoChangeStackPointer -= 1;
        const changeset = this.undoableChangeStack[p];
        const { attrName, elements, oldValues } = changeset;
        const batchCmd = new history.BatchCommand(`Change ${attrName}`);
        for (let i = elements.length - 1; i >= 0; i -= 1) {
            const elem = elements[i];
            if (elem == null) {
                // eslint-disable-next-line no-continue
                continue;
            }
            const changes = {};
            changes[attrName] = oldValues[i];
            if (changes[attrName] !== elem.getAttribute(attrName)) {
                batchCmd.addSubCommand(new history.ChangeElementCommand(elem, changes, attrName));
            }
        }
        this.undoableChangeStack[p] = null;
        return batchCmd;
    }
}
// singleton
const undoManager = new UndoManager(null);
export default undoManager;
