var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import i18n from 'helpers/i18n';
import Progress from '../progress-caller';
import Alert from '../alert-caller';
import TutorialConstants from '../../constants/tutorial-constants';
import Dialog from '../dialog-caller';
import Discover from '../../../helpers/api/discover';
const LANG = i18n.lang.tutorial;
const getMachineForTutorial = () => __awaiter(void 0, void 0, void 0, function* () {
    return new Promise((resolve) => {
        let discover = Discover('tutorial', (machines) => {
            if (machines.length > 0) {
                resolve(true);
                discover.removeListener('tutorial');
                discover = null;
            }
        });
        setTimeout(() => {
            if (discover) {
                resolve(false);
                discover.removeListener('tutorial');
                discover = null;
            }
        }, 3000);
    });
});
const startNewUserTutorial = (callback) => __awaiter(void 0, void 0, void 0, function* () {
    Progress.openNonstopProgress({
        id: 'tutorial-find-machine',
        message: LANG.look_for_machine,
    });
    const isAnyMachineAvailable = yield getMachineForTutorial();
    Progress.popById('tutorial-find-machine');
    if (isAnyMachineAvailable) {
        Dialog.showTutorial(TutorialConstants.NEW_USER_TUTORIAL, callback);
    }
    else {
        const buttons = [
            {
                label: LANG.set_connection,
                className: 'btn-default primary',
                onClick: () => { window.location.hash = '#initialize/connect/select-machine-model'; },
            },
            {
                label: LANG.retry,
                className: 'btn-default primary',
                onClick: () => startNewUserTutorial(callback),
            },
            {
                label: LANG.skip,
                className: 'btn-default',
                onClick: () => {
                    Alert.popUp({
                        message: LANG.skip_tutorial,
                    });
                }
            },
        ];
        Alert.popUp({
            message: LANG.unable_to_find_machine,
            buttons
        });
    }
});
const startInterfaceTutorial = (callback) => {
    Dialog.showTutorial(TutorialConstants.INTERFACE_TUTORIAL, callback);
};
export default {
    startNewUserTutorial,
    startInterfaceTutorial
};
