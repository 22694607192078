// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__container--Yk6mV{display:flex;justify-content:space-between;height:25px;margin:0 15px 5px}.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__container--Yk6mV>div{display:flex;align-items:center}.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__container--Yk6mV .src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__button--0BVJp{width:24px;height:24px;opacity:.5;cursor:pointer}.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__container--Yk6mV .src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__button--0BVJp:not(:first-child){margin-left:10px}.src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__container--Yk6mV .src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__button--0BVJp>img{filter:brightness(0)}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/Right-Panels/ConfigPanel/ConfigOperations.module.scss"],"names":[],"mappings":"AAAA,8FACE,YAAA,CACA,6BAAA,CACA,WAAA,CACA,iBAAA,CAEA,kGACE,YAAA,CACA,kBAAA,CAGF,yLACE,UAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CAEA,2MACE,gBAAA,CAEF,6LACE,oBAAA","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-between;\n  height: 25px;\n  margin: 0 15px 5px;\n\n  > div {\n    display: flex;\n    align-items: center;\n  }\n\n  .button {\n    width: 24px;\n    height: 24px;\n    opacity: 0.5;\n    cursor: pointer;\n\n    &:not(:first-child) {\n      margin-left: 10px;\n    }\n    > img {\n      filter: brightness(0);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__container--Yk6mV",
	"button": "src-web-app-views-beambox-Right-Panels-ConfigPanel-ConfigOperations-module__button--0BVJp"
};
export default ___CSS_LOADER_EXPORT___;
