var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable react/sort-comp */
import * as React from 'react';
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import Constant from 'app/actions/beambox/constant';
import dialog from 'implementations/dialog';
import DeviceConstants from 'app/constants/device-constants';
import DeviceErrorHandler from 'helpers/device-error-handler';
import DeviceMaster from 'helpers/device-master';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import i18n from 'helpers/i18n';
import MonitorStatus from 'helpers/monitor-status';
import OutputError from 'helpers/output-error';
import Progress from 'app/actions/progress-caller';
import VersionChecker from 'helpers/version-checker';
import { Mode } from 'app/constants/monitor-constants';
const eventEmitter = eventEmitterFactory.createEventEmitter('monitor');
let LANG = i18n.lang;
const updateLang = () => {
    LANG = i18n.lang;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getFirstBlobInArray = (array) => {
    const id = array.findIndex((elem) => elem instanceof Blob);
    if (id >= 0) {
        return array[id];
    }
    return null;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findKeyInObjectArray = (array, key) => {
    const res = array.filter((o) => Object.keys(o).some((name) => name === key));
    if (res.length > 0) {
        return res[0][key];
    }
    return null;
};
const { IDLE, PAUSED, PAUSED_FROM_STARTING, PAUSING_FROM_STARTING, PAUSED_FROM_RUNNING, PAUSING_FROM_RUNNING, ABORTED, ALARM, FATAL, COMPLETED, TOOLHEAD_CHANGE, } = DeviceConstants.status;
const reportStates = new Set([
    PAUSED_FROM_STARTING,
    PAUSED_FROM_RUNNING,
    ABORTED,
    PAUSING_FROM_RUNNING,
    PAUSING_FROM_STARTING,
    ALARM,
    FATAL,
    TOOLHEAD_CHANGE,
    COMPLETED,
]);
export const MonitorContext = React.createContext(null);
export class MonitorContextProvider extends React.Component {
    constructor(props) {
        super(props);
        this.clearErrorPopup = () => {
            if (this.lastErrorId) {
                Alert.popById(this.lastErrorId);
                this.lastErrorId = null;
            }
        };
        this.enterWorkingMode = (task) => __awaiter(this, void 0, void 0, function* () {
            if (!task) {
                const taskInfo = yield this.getWorkingTaskInfo();
                const { imageBlob, taskTime } = this.getTaskInfo(taskInfo);
                let taskImageURL = null;
                if (imageBlob) {
                    taskImageURL = URL.createObjectURL(imageBlob);
                }
                this.setState({
                    mode: Mode.WORKING,
                    taskImageURL,
                    taskTime,
                });
            }
            else {
                this.setState({
                    mode: Mode.WORKING,
                    taskImageURL: task.taskImageURL,
                    taskTime: task.taskTime,
                });
            }
        });
        this.exitWorkingMode = () => {
            const { mode, fileInfo, previewTask } = this.state;
            console.warn(fileInfo);
            if (previewTask) {
                this.setState({
                    mode: mode === Mode.CAMERA ? Mode.CAMERA : Mode.PREVIEW,
                    taskImageURL: previewTask.taskImageURL,
                    taskTime: previewTask.taskTime,
                });
                this.modeBeforeCamera = Mode.PREVIEW;
            }
            else if (fileInfo) {
                const { imageBlob, taskTime } = this.getTaskInfo(fileInfo);
                const taskImageURL = URL.createObjectURL(imageBlob);
                this.setState({
                    mode: mode === Mode.CAMERA ? Mode.CAMERA : Mode.FILE_PREVIEW,
                    taskImageURL,
                    taskTime,
                });
                this.modeBeforeCamera = Mode.FILE_PREVIEW;
            }
            else {
                this.setState({
                    mode: mode === Mode.CAMERA ? Mode.CAMERA : Mode.FILE,
                });
                this.modeBeforeCamera = Mode.FILE;
            }
        };
        this.startRelocate = () => __awaiter(this, void 0, void 0, function* () {
            const { mode } = this.state;
            if (mode === Mode.CAMERA_RELOCATE)
                return;
            this.modeBeforeRelocate = mode;
            const getCameraOffset = () => __awaiter(this, void 0, void 0, function* () {
                const isBorderless = BeamboxPreference.read('borderless') || false;
                const configName = isBorderless ? 'camera_offset_borderless' : 'camera_offset';
                const resp = yield DeviceMaster.getDeviceSetting(configName);
                console.log(`Reading ${configName}\nResp = ${resp.value}`);
                resp.value = ` ${resp.value}`;
                let cameraOffset = {
                    x: Number(/ X:\s?(-?\d+\.?\d+)/.exec(resp.value)[1]),
                    y: Number(/ Y:\s?(-?\d+\.?\d+)/.exec(resp.value)[1]),
                    angle: Number(/R:\s?(-?\d+\.?\d+)/.exec(resp.value)[1]),
                    scaleRatioX: Number((/SX:\s?(-?\d+\.?\d+)/.exec(resp.value) || /S:\s?(-?\d+\.?\d+)/.exec(resp.value))[1]),
                    scaleRatioY: Number((/SY:\s?(-?\d+\.?\d+)/.exec(resp.value) || /S:\s?(-?\d+\.?\d+)/.exec(resp.value))[1]),
                };
                console.log(`Got ${configName}`, cameraOffset);
                if (cameraOffset.x === 0 && cameraOffset.y === 0) {
                    cameraOffset = {
                        x: Constant.camera.offsetX_ideal,
                        y: Constant.camera.offsetY_ideal,
                        angle: 0,
                        scaleRatioX: Constant.camera.scaleRatio_ideal,
                        scaleRatioY: Constant.camera.scaleRatio_ideal,
                    };
                }
                return cameraOffset;
            });
            Progress.popById('prepare-relocate');
            Progress.openNonstopProgress({
                id: 'prepare-relocate',
                message: LANG.monitor.prepareRelocate,
            });
            this.stopReport();
            try {
                const cameraOffset = yield getCameraOffset();
                yield DeviceMaster.enterRawMode();
                yield DeviceMaster.rawSetRotary(false);
                yield DeviceMaster.rawHome();
                this.setState({
                    mode: Mode.CAMERA_RELOCATE,
                    cameraOffset,
                    currentPosition: { x: 0, y: 0 },
                });
            }
            catch (error) {
                console.error('Error when entering relocate mode', error);
                this.startReport();
            }
            Progress.popById('prepare-relocate');
        });
        this.endRelocate = () => {
            this.setState({ mode: this.modeBeforeRelocate }, () => {
                if (!this.reporter) {
                    this.startReport();
                }
            });
        };
        this.onHighlightItem = (item) => {
            const { highlightedItem } = this.state;
            if (!highlightedItem ||
                highlightedItem.name !== item.name ||
                highlightedItem.type !== item.type) {
                this.setState({ highlightedItem: item });
            }
            else {
                this.setState({ highlightedItem: {} });
            }
        };
        this.setMonitorMode = (value) => {
            this.setState({ mode: value });
        };
        this.onSelectFolder = (folderName, absolute = false) => {
            let { currentPath } = this.state;
            if (!absolute) {
                currentPath.push(folderName);
            }
            else if (folderName === '') {
                currentPath = [];
            }
            else {
                currentPath = folderName.split('/');
            }
            this.setState({
                currentPath,
                highlightedItem: {},
            });
            console.info('Current Path', currentPath);
        };
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        this.onSelectFile = (fileName, fileInfo) => __awaiter(this, void 0, void 0, function* () {
            const { currentPath } = this.state;
            const path = currentPath.join('/');
            let info = fileInfo;
            if (!info) {
                info = yield DeviceMaster.fileInfo(path, fileName);
            }
            console.info(info);
            const { imageBlob, taskTime } = this.getTaskInfo(info);
            let taskImageURL = null;
            if (imageBlob) {
                taskImageURL = URL.createObjectURL(imageBlob);
            }
            console.log(info);
            this.setState({
                mode: Mode.FILE_PREVIEW,
                fileInfo: info,
                taskImageURL,
                taskTime,
            });
        });
        this.setShouldUpdateFileList = (val) => {
            this.setState({ shouldUpdateFileList: val });
        };
        this.doesFileExistInDirectory = (path, fileName) => __awaiter(this, void 0, void 0, function* () {
            const name = fileName.replace('.gcode', '.fc');
            try {
                const res = yield DeviceMaster.fileInfo(path, name);
                if (!res.error || res.error.length === 0) {
                    console.log(res.error, res.error.length === 0);
                    return true;
                }
                return false;
            }
            catch (error) {
                return false;
            }
        });
        this.uploadFile = (file) => __awaiter(this, void 0, void 0, function* () {
            const { currentPath } = this.state;
            const path = currentPath.join('/');
            if (!path)
                return;
            const name = file.name.split(/[\\/]/).at(-1).replace(/ /g, '_');
            const fileExist = yield this.doesFileExistInDirectory(path, name);
            if (fileExist) {
                const res = yield new Promise((resolve) => {
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_INFO,
                        message: LANG.monitor.fileExistContinue,
                        buttonType: AlertConstants.YES_NO,
                        onYes: () => resolve(true),
                        onNo: () => resolve(false),
                    });
                });
                if (!res)
                    return;
            }
            this.setState({ uploadProgress: 0 });
            const reader = new FileReader();
            reader.onload = () => __awaiter(this, void 0, void 0, function* () {
                const fileInfo = file.name.split('.');
                const ext = fileInfo[fileInfo.length - 1];
                let type;
                let isValid = false;
                if (ext === 'fc') {
                    type = { type: 'application/fcode' };
                    isValid = true;
                }
                else if (ext === 'gcode') {
                    type = { type: 'text/gcode' };
                    isValid = true;
                }
                if (isValid) {
                    const blob = new Blob([reader.result], type);
                    yield DeviceMaster.uploadToDirectory(blob, path, name, (progress) => {
                        const p = Math.floor((progress.step / progress.total) * 100);
                        this.setState({ uploadProgress: p });
                    });
                    this.setState({ uploadProgress: null });
                    this.setShouldUpdateFileList(true);
                }
                else {
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_INFO,
                        message: LANG.monitor.extensionNotSupported,
                    });
                }
            });
            reader.readAsArrayBuffer(file);
        });
        this.showUploadDialog = () => __awaiter(this, void 0, void 0, function* () {
            const file = (yield dialog.getFileFromDialog({
                filters: [{ name: 'task', extensions: ['fc'] }],
            }));
            if (!file)
                return;
            this.uploadFile(file);
        });
        this.onDownload = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const { currentPath, highlightedItem } = this.state;
                const { name } = highlightedItem;
                const path = currentPath.join('/');
                const file = yield DeviceMaster.downloadFile(path, name, (p) => {
                    this.setState({ downloadProgress: p });
                });
                this.setState({ downloadProgress: null });
                const getContent = () => __awaiter(this, void 0, void 0, function* () { return file[1]; });
                yield dialog.writeFileDialog(getContent, name, name, [
                    {
                        name: i18n.lang.topmenu.file.all_files,
                        extensions: ['*'],
                    },
                ]);
            }
            catch (e) {
                console.error('Error when downloading file', e);
            }
        });
        this.onDeleteFile = () => {
            const { currentPath, highlightedItem } = this.state;
            const path = currentPath.join('/');
            Alert.popUp({
                type: AlertConstants.SHOW_POPUP_INFO,
                message: LANG.monitor.confirmFileDelete,
                buttonType: AlertConstants.YES_NO,
                onYes: () => __awaiter(this, void 0, void 0, function* () {
                    yield DeviceMaster.deleteFile(path, highlightedItem.name);
                    this.setShouldUpdateFileList(true);
                }),
            });
        };
        this.onPlay = () => __awaiter(this, void 0, void 0, function* () {
            var _a;
            const { device } = this.props;
            const { mode, report, currentPath, fileInfo, relocateOrigin } = this.state;
            this.clearErrorPopup();
            if (report.st_id === IDLE) {
                const vc = VersionChecker(device.version);
                console.log(device.version);
                if (vc.meetRequirement('RELOCATE_ORIGIN')) {
                    console.log(relocateOrigin);
                    yield DeviceMaster.setOriginX(relocateOrigin.x);
                    yield DeviceMaster.setOriginY(relocateOrigin.y);
                }
                if (mode === Mode.PREVIEW) {
                    const { previewTask } = this.state;
                    const fCode = previewTask.fcodeBlob;
                    try {
                        yield DeviceMaster.go(fCode, (progress) => {
                            const p = Math.floor((progress.step / progress.total) * 100);
                            this.setState({ uploadProgress: p });
                        });
                        this.setState({ uploadProgress: null });
                    }
                    catch (error) {
                        this.setState({ uploadProgress: null });
                        Alert.popUp({
                            type: AlertConstants.SHOW_POPUP_ERROR,
                            message: LANG.message.unable_to_start + ((_a = error.error) === null || _a === void 0 ? void 0 : _a.join('_')),
                        });
                    }
                }
                else if (mode === Mode.FILE_PREVIEW) {
                    yield DeviceMaster.goFromFile(currentPath.join('/'), fileInfo[0]);
                }
            }
            else if (MonitorStatus.isAbortedOrCompleted(report)) {
                DeviceMaster.restart();
            }
            else {
                // PAUSED
                DeviceMaster.resume();
            }
            eventEmitter.emit('PLAY');
        });
        this.onPause = () => {
            DeviceMaster.pause();
        };
        this.onStop = () => {
            DeviceMaster.stop();
        };
        this.onMaintainMoveStart = () => {
            this.setState({ isMaintainMoving: true });
        };
        this.onMaintainMoveEnd = (x, y) => {
            this.setState({
                isMaintainMoving: false,
                currentPosition: { x, y },
            });
        };
        this.onRelocate = () => {
            const { currentPosition } = this.state;
            const { x, y } = currentPosition;
            this.setState({
                relocateOrigin: { x, y },
                mode: this.modeBeforeRelocate,
            });
        };
        const { mode, previewTask } = props;
        updateLang();
        this.isGettingReport = false;
        this.lastErrorId = null;
        this.modeBeforeCamera = mode;
        this.modeBeforeRelocate = mode;
        this.state = {
            mode,
            currentPath: [],
            highlightedItem: {},
            fileInfo: null,
            previewTask,
            workingTask: null,
            taskImageURL: mode === Mode.PREVIEW ? previewTask.taskImageURL : null,
            taskTime: mode === Mode.PREVIEW ? previewTask.taskTime : null,
            report: {},
            uploadProgress: null,
            downloadProgress: null,
            shouldUpdateFileList: false,
            currentPosition: { x: 0, y: 0 },
            relocateOrigin: { x: 0, y: 0 },
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.fetchInitialInfo();
            this.startReport();
            const { mode } = this.state;
            if (mode === Mode.WORKING) {
                const taskInfo = yield this.getWorkingTaskInfo();
                const { imageBlob, taskTime } = this.getTaskInfo(taskInfo);
                let taskImageURL = null;
                if (imageBlob) {
                    taskImageURL = URL.createObjectURL(imageBlob);
                }
                this.setState({
                    taskImageURL,
                    taskTime,
                    workingTask: taskInfo,
                });
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        const { taskImageURL, previewTask } = this.state;
        if (prevState.taskImageURL && prevState.taskImageURL !== taskImageURL) {
            if (previewTask && prevState.taskImageURL !== previewTask.taskImageURL) {
                URL.revokeObjectURL(prevState.taskImageURL);
            }
        }
    }
    componentWillUnmount() {
        this.stopReport();
        const { taskImageURL } = this.state;
        URL.revokeObjectURL(taskImageURL);
    }
    startReport() {
        if (this.reporter)
            clearInterval(this.reporter);
        this.reporter = setInterval(() => __awaiter(this, void 0, void 0, function* () {
            try {
                if (this.isGettingReport)
                    return;
                this.isGettingReport = true;
                const report = yield DeviceMaster.getReport();
                this.processReport(report);
            }
            catch (error) {
                if (error && error.status === 'raw') {
                    return;
                }
                console.error('Monitor report error:', error);
                this.stopReport();
                const res = yield DeviceMaster.reconnect();
                console.log(res);
                if (res.success) {
                    this.startReport();
                }
                else {
                    const { onClose } = this.props;
                    const askRetryReconnect = () => new Promise((resolve) => {
                        Alert.popUp({
                            id: 'monitor-reconnect',
                            type: AlertConstants.SHOW_POPUP_ERROR,
                            buttonType: AlertConstants.RETRY_CANCEL,
                            message: LANG.monitor.ask_reconnect,
                            onRetry: () => __awaiter(this, void 0, void 0, function* () {
                                const res2 = yield DeviceMaster.reconnect();
                                if (res2.success) {
                                    Alert.popById('connection-error');
                                    resolve(true);
                                }
                                else {
                                    const doRetry = yield askRetryReconnect();
                                    resolve(doRetry);
                                }
                            }),
                            onCancel: () => resolve(false),
                        });
                    });
                    if (!Alert.checkIdExist('monitor-reconnect')) {
                        const doRetry = yield askRetryReconnect();
                        if (doRetry) {
                            this.startReport();
                        }
                        else {
                            onClose();
                        }
                    }
                }
            }
            finally {
                this.isGettingReport = false;
            }
        }), 1500);
    }
    stopReport() {
        clearInterval(this.reporter);
        this.reporter = null;
    }
    fetchInitialInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const report = yield DeviceMaster.getReport();
                this.processReport(report);
            }
            catch (error) {
                console.log('monitor fetch initial info error:\n', error);
                if (error.status === 'fatal') {
                    yield DeviceMaster.reconnect();
                }
            }
        });
    }
    processReport(report) {
        return __awaiter(this, void 0, void 0, function* () {
            const { report: currentReport, mode } = this.state;
            const keys = Object.keys(report);
            for (let i = 0; i < keys.length; i += 1) {
                const key = keys[i];
                if (currentReport[key] === undefined ||
                    JSON.stringify(currentReport[key]) !== JSON.stringify(report[key])) {
                    // console.log(key, 'changed');
                    if (report.st_id > 0 && (mode !== Mode.WORKING || key === 'session')) {
                        const keepsCameraMode = mode === Mode.CAMERA && MonitorStatus.allowedCameraStatus.includes(report.st_id);
                        const keepsFileMode = mode === Mode.FILE_PREVIEW || mode === Mode.FILE;
                        if (!keepsCameraMode && !keepsFileMode) {
                            console.log('to work mode');
                            this.enterWorkingMode();
                        }
                    }
                    else if (report.st_id === IDLE) {
                        if (mode === Mode.WORKING ||
                            (mode === Mode.CAMERA && this.modeBeforeCamera === Mode.WORKING)) {
                            this.exitWorkingMode();
                        }
                    }
                    this.setState({ report });
                    break;
                }
            }
            if (!report.error || report.error.length === 0) {
                this.clearErrorPopup();
                return;
            }
            let { error } = report;
            error = error instanceof Array ? error : [error];
            console.error(error);
            if (error[0] === 'TIMEOUT') {
                try {
                    yield DeviceMaster.reconnect();
                }
                catch (e) {
                    console.error('Error when reconnect in monitor', e);
                    Alert.popUp({
                        id: 'monitor-error',
                        type: AlertConstants.SHOW_POPUP_ERROR,
                        message: LANG.message.connectionTimeout,
                    });
                    const { onClose } = this.props;
                    onClose();
                }
                return;
            }
            const errorId = error.join('_');
            if (this.lastErrorId && this.lastErrorId !== errorId) {
                this.clearErrorPopup();
            }
            if (reportStates.has(report.st_id)) {
                const handleRetry = () => __awaiter(this, void 0, void 0, function* () {
                    this.clearErrorPopup();
                    const pauseStates = [
                        PAUSED,
                        PAUSED_FROM_STARTING,
                        PAUSED_FROM_RUNNING,
                        PAUSING_FROM_STARTING,
                        PAUSING_FROM_RUNNING,
                    ];
                    if (report.st_id === ABORTED) {
                        yield DeviceMaster.quit();
                        this.onPlay();
                    }
                    else if (pauseStates.includes(report.st_id)) {
                        DeviceMaster.resume();
                    }
                });
                const handleReport = () => __awaiter(this, void 0, void 0, function* () {
                    const getContent = () => __awaiter(this, void 0, void 0, function* () {
                        const contents = [];
                        const bxLogs = OutputError.getOutput();
                        contents.push(...bxLogs);
                        this.stopReport();
                        const { device } = this.props;
                        const vc = VersionChecker(device.version);
                        const playerLogName = vc.meetRequirement('NEW_PLAYER')
                            ? 'playerd.log'
                            : 'fluxplayerd.log';
                        Progress.openSteppingProgress({ id: 'get_log', message: 'downloading' });
                        const logFiles = yield DeviceMaster.getLogsTexts([playerLogName, 'fluxrobotd.log'], (progress) => Progress.update('get_log', {
                            message: 'downloading',
                            percentage: (progress.completed / progress.size) * 100,
                        }));
                        Progress.popById('get_log');
                        this.startReport();
                        const logKeys = Object.keys(logFiles);
                        for (let i = 0; i < logKeys.length; i += 1) {
                            const key = logKeys[i];
                            const blob = getFirstBlobInArray(logFiles[key]);
                            if (blob) {
                                contents.push(`\n===\n${key}\n===\n`);
                                contents.push(blob);
                            }
                        }
                        return new Blob(contents);
                    });
                    yield dialog.writeFileDialog(getContent, LANG.beambox.popup.bug_report, 'devicelogs.txt', [
                        {
                            name: window.os === 'MacOS' ? 'txt (*.txt)' : 'txt',
                            extensions: ['txt'],
                        },
                    ]);
                });
                const errorMessage = DeviceErrorHandler.translate(error);
                if (!Alert.checkIdExist(errorId) && !this.lastErrorId) {
                    if ([ALARM, FATAL].includes(report.st_id)) {
                        Alert.popUp({
                            id: errorId,
                            type: AlertConstants.SHOW_POPUP_ERROR,
                            message: errorMessage,
                            primaryButtonIndex: 0,
                            buttonLabels: [LANG.alert.abort],
                            callbacks: [() => DeviceMaster.stop()],
                        });
                    }
                    else if (error[0] === 'HARDWARE_ERROR' || error[0] === 'USER_OPERATION') {
                        if (error[1] !== 'REMOVE_CARTRIDGE') {
                            Alert.popUp({
                                id: errorId,
                                type: error[0] === 'USER_OPERATION'
                                    ? AlertConstants.SHOW_POPUP_INSTRUCTION
                                    : AlertConstants.SHOW_POPUP_ERROR,
                                message: errorMessage,
                                buttonType: AlertConstants.RETRY_CANCEL,
                                onRetry: handleRetry,
                            });
                        }
                        else {
                            Alert.popUp({
                                id: errorId,
                                type: error[0] === 'USER_OPERATION'
                                    ? AlertConstants.SHOW_POPUP_INSTRUCTION
                                    : AlertConstants.SHOW_POPUP_ERROR,
                                message: errorMessage,
                            });
                        }
                    }
                    else {
                        Alert.popUp({
                            id: errorId,
                            type: AlertConstants.SHOW_POPUP_ERROR,
                            message: errorMessage,
                            primaryButtonIndex: 0,
                            buttonLabels: [LANG.alert.retry, LANG.monitor.bug_report, LANG.alert.cancel],
                            callbacks: [handleRetry, handleReport, () => { }],
                        });
                    }
                    this.lastErrorId = errorId;
                }
            }
        });
    }
    // eslint-disable-next-line class-methods-use-this
    getWorkingTaskInfo() {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield DeviceMaster.getPreviewInfo();
            return res;
        });
    }
    // eslint-disable-next-line class-methods-use-this
    getTaskInfo(info) {
        const imageBlob = getFirstBlobInArray(info);
        const taskTime = findKeyInObjectArray(info, 'TIME_COST') || findKeyInObjectArray(info, 'time_cost');
        return { imageBlob, taskTime };
    }
    render() {
        const { onClose, children } = this.props;
        const { onHighlightItem, onSelectFolder, onSelectFile, setShouldUpdateFileList, uploadFile, onDeleteFile, onPlay, onPause, onStop, onMaintainMoveStart, onMaintainMoveEnd, onDownload, showUploadDialog, setMonitorMode, } = this;
        return (React.createElement(MonitorContext.Provider, { value: Object.assign(Object.assign({ onClose }, this.state), { onHighlightItem,
                onSelectFolder,
                onSelectFile,
                setShouldUpdateFileList,
                uploadFile,
                onDeleteFile,
                onPlay,
                onPause,
                onStop,
                onMaintainMoveStart,
                onMaintainMoveEnd,
                setMonitorMode,
                onDownload,
                showUploadDialog }) }, children));
    }
}
