var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Websocket from '../websocket';
class CameraCalibrationApi {
    constructor() {
        this.solvePnPFindCorners = (img, dh) => new Promise((resolve, reject) => {
            let data = {};
            this.events.onMessage = (response) => {
                if (response instanceof Blob) {
                    resolve({ success: true, blob: response, data });
                }
                else if (response.status === 'continue') {
                    this.ws.send(img);
                }
                else if (response.status === 'fail') {
                    console.log('fail', response);
                    resolve({ success: false, blob: null, data: response });
                }
                else if (response.status === 'ok') {
                    const { status } = response, rest = __rest(response, ["status"]);
                    data = rest;
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            const size = img instanceof Blob ? img.size : img.byteLength;
            // solve_pnp_find_corners [calibration_version] [elevated_dh] [file_length]
            this.ws.send(`solve_pnp_find_corners 2 ${dh.toFixed(3)} ${size}`);
        });
        this.solvePnPCalculate = (dh, points) => new Promise((resolve, reject) => {
            let success = true;
            let data = {};
            this.events.onMessage = (response) => {
                if (response.status === 'fail') {
                    success = false;
                    console.log('fail', response);
                }
                else if (response.status === 'ok') {
                    const { status } = response, rest = __rest(response, ["status"]);
                    data = rest;
                    resolve({ success, data });
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            // solve_pnp_calculate [calibration_version] [elevated_dh] [points]
            this.ws.send(`solve_pnp_calculate 2 ${dh.toFixed(3)} ${JSON.stringify(points)}`);
        });
        this.updateData = (data) => new Promise((resolve, reject) => {
            this.events.onMessage = (response) => {
                if (response.status === 'ok') {
                    resolve(true);
                }
                else {
                    reject(response);
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            // update_data [data]
            this.ws.send(`update_data ${JSON.stringify(data)}`);
        });
        this.extrinsicRegression = (rvecs, tvecs, heights) => new Promise((resolve, reject) => {
            let success = true;
            let data = {};
            this.events.onMessage = (response) => {
                if (response.status === 'fail') {
                    success = false;
                    console.log('fail', response);
                }
                else if (response.status === 'ok') {
                    const { status } = response, rest = __rest(response, ["status"]);
                    data = rest;
                    resolve({ success, data });
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            // extrinsic_regression [rvecs] [tvecs] [heights]
            const rvecsStr = JSON.stringify(rvecs);
            const tvecsStr = JSON.stringify(tvecs);
            const heightsStr = JSON.stringify(heights);
            this.ws.send(`extrinsic_regression ${rvecsStr} ${tvecsStr} ${heightsStr}`);
        });
        this.events = {
            onMessage: () => { },
            onError: () => { },
            onFatal: () => { },
        };
        this.ws = Websocket({
            method: 'camera-calibration',
            onMessage: (data) => {
                this.events.onMessage(data);
            },
            onError: (response) => {
                this.events.onError(response);
            },
            onFatal: (response) => {
                this.events.onFatal(response);
            },
        });
    }
    upload(data) {
        return new Promise((resolve, reject) => {
            this.events.onMessage = (response) => {
                switch (response.status) {
                    case 'ok':
                        resolve(response);
                        break;
                    case 'fail':
                        resolve(response);
                        break;
                    case 'none':
                        resolve(response);
                        break;
                    case 'continue':
                        this.ws.send(data);
                        break;
                    default:
                        console.log('strange message', response);
                        break;
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            const size = data instanceof Blob ? data.size : data.byteLength;
            this.ws.send(`upload ${size}`);
        });
    }
    startFisheyeCalibrate() {
        return new Promise((resolve, reject) => {
            this.events.onMessage = (response) => {
                switch (response.status) {
                    case 'ok':
                        resolve(true);
                        break;
                    default:
                        console.log('strange message', response);
                        break;
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            this.ws.send('start_fisheye_calibration');
        });
    }
    addFisheyeCalibrateImg(height, img) {
        return new Promise((resolve, reject) => {
            this.events.onMessage = (response) => {
                switch (response.status) {
                    case 'ok':
                        resolve(true);
                        break;
                    case 'continue':
                        this.ws.send(img);
                        break;
                    default:
                        console.log('strange message', response);
                        break;
                }
            };
            this.events.onError = (response) => {
                resolve(false);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                resolve(false);
                console.log('on fatal', response);
            };
            const size = img instanceof Blob ? img.size : img.byteLength;
            this.ws.send(`add_fisheye_calibration_image ${size} ${height}`);
        });
    }
    doFisheyeCalibration(onProgress) {
        return new Promise((resolve, reject) => {
            this.events.onMessage = (response) => {
                switch (response.status) {
                    case 'ok':
                        resolve(response);
                        break;
                    case 'progress':
                        if (onProgress)
                            onProgress(response.progress);
                        break;
                    case 'fail':
                        reject(response.reason);
                        break;
                    default:
                        console.log('strange message', response);
                        break;
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            this.ws.send('do_fisheye_calibration');
        });
    }
    findPerspectivePoints(onProgress) {
        return new Promise((resolve, reject) => {
            this.events.onMessage = (response) => {
                switch (response.status) {
                    case 'ok':
                        resolve(response);
                        break;
                    case 'progress':
                        if (onProgress)
                            onProgress(response.progress);
                        break;
                    case 'fail':
                        reject(response.reason);
                        break;
                    default:
                        console.log('strange message', response);
                        break;
                }
            };
            this.ws.send('find_perspective_points');
        });
    }
    calculateRegressionParam(onProgress) {
        return new Promise((resolve, reject) => {
            this.events.onMessage = (response) => {
                switch (response.status) {
                    case 'ok':
                        resolve(response);
                        break;
                    case 'progress':
                        if (onProgress)
                            onProgress(response.progress);
                        break;
                    case 'fail':
                        reject(response.reason);
                        break;
                    default:
                        console.log('strange message', response);
                        break;
                }
            };
            this.ws.send('cal_regression_param');
        });
    }
    calibrateChessboard(img, height, chessboard = [48, 36]) {
        return new Promise((resolve, reject) => {
            let blob;
            this.events.onMessage = (response) => {
                var _a, _b;
                if (response instanceof Blob) {
                    blob = response;
                }
                else if (response.status === 'continue') {
                    this.ws.send(img);
                }
                else if (response.status === 'fail') {
                    resolve({ success: false, data: { reason: response.reason } });
                }
                else if (((_b = (_a = response.status).toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a)) === 'error') {
                    console.log('error', response);
                    resolve({ success: false, data: { reason: response.message } });
                }
                else if (response.status === 'ok') {
                    const { status } = response, rest = __rest(response, ["status"]);
                    resolve({ success: true, blob, data: rest });
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            const size = img instanceof Blob ? img.size : img.byteLength;
            // calibrate_chessboard [file_length] [height] [chessboard_w] [chessboard_h]
            this.ws.send(`calibrate_chessboard ${size} ${height.toFixed(2)} ${chessboard.join(' ')}`);
        });
    }
    findCorners(img, withPitch = false) {
        return new Promise((resolve, reject) => {
            let success = true;
            let data = {};
            this.events.onMessage = (response) => {
                if (response instanceof Blob) {
                    resolve({ success, blob: response, data });
                }
                else if (response.status === 'continue') {
                    this.ws.send(img);
                }
                else if (response.status === 'fail') {
                    success = false;
                    console.log('fail', response);
                }
                else if (response.status === 'ok') {
                    const { status } = response, rest = __rest(response, ["status"]);
                    data = rest;
                }
            };
            this.events.onError = (response) => {
                reject(response);
                console.log('on error', response);
            };
            this.events.onFatal = (response) => {
                reject(response);
                console.log('on fatal', response);
            };
            const size = img instanceof Blob ? img.size : img.byteLength;
            // corner_detection [camera_pitch] [file_length] [calibration_version]
            this.ws.send(`corner_detection ${withPitch ? 20 : 0} ${size} 2`);
        });
    }
}
export default CameraCalibrationApi;
