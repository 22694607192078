var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { memo } from 'react';
import dialog from 'implementations/dialog';
import i18n from 'helpers/i18n';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import styles from './ConfigOperations.module.scss';
let svgEditor;
getSVGAsync((globalSVG) => { svgEditor = globalSVG.Editor; });
const importLaserConfig = () => __awaiter(void 0, void 0, void 0, function* () {
    const dialogOptions = {
        filters: [{ name: 'JSON', extensions: ['json', 'JSON'] }],
    };
    const fileBlob = yield dialog.getFileFromDialog(dialogOptions);
    if (fileBlob)
        svgEditor.importLaserConfig(fileBlob);
});
const exportLaserConfigs = () => __awaiter(void 0, void 0, void 0, function* () {
    const isLinux = window.os === 'Linux';
    const getContent = () => {
        const laserConfig = {};
        laserConfig.customizedLaserConfigs = storage.get('customizedLaserConfigs');
        laserConfig.defaultLaserConfigsInUse = storage.get('defaultLaserConfigsInUse');
        return JSON.stringify(laserConfig);
    };
    yield dialog.writeFileDialog(getContent, i18n.lang.beambox.right_panel.laser_panel.export_config, isLinux ? '.json' : '', [{
            name: window.os === 'MacOS' ? 'JSON (*.json)' : 'JSON',
            extensions: ['json'],
        }, {
            name: i18n.lang.topmenu.file.all_files,
            extensions: ['*'],
        }]);
});
const ConfigOperations = ({ onMoreClick }) => {
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel.dropdown;
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", null,
            React.createElement("div", { className: styles.button, title: t.import, onClick: importLaserConfig },
                React.createElement("img", { src: "img/right-panel/icon-import.svg" })),
            React.createElement("div", { className: styles.button, title: t.export, onClick: exportLaserConfigs },
                React.createElement("img", { src: "img/right-panel/icon-export.svg" }))),
        React.createElement("div", null,
            React.createElement("div", { className: styles.button, title: t.more, onClick: onMoreClick },
                React.createElement("img", { src: "img/right-panel/icon-setting.svg" })))));
};
export default memo(ConfigOperations);
