const lang = {
    general: {
        wait: 'Verwerken, even geduld',
        choose_folder: 'Map kiezen',
    },
    buttons: {
        next: 'Volgende',
        back: 'Terug',
        done: 'Klaar',
        back_to_beam_studio: 'Terug naar Beam Studio',
    },
    topbar: {
        untitled: 'Naamloos',
        titles: {
            settings: 'Voorkeuren',
        },
        export: 'GA',
        preview: 'Voorbeeld',
        preview_title: 'Voorbeeld',
        curve_engrave: '3D Curve',
        task_preview: 'Padvoorbeeld',
        frame_task: 'Frame uitvoeren',
        borderless: '(OPEN ONDERKANT)',
        tag_names: {
            rect: 'Rechthoek',
            ellipse: 'Ovaal',
            path: 'Pad',
            polygon: 'Veelhoek',
            image: 'Afbeelding',
            text: 'Tekst',
            text_path: 'Tekst op pad',
            line: 'Lijn',
            g: 'Groep',
            multi_select: 'Meerdere objecten',
            use: 'Geïmporteerd object',
            svg: 'SVG-object',
            dxf: 'DXF-object',
            no_selection: 'Geen selectie',
        },
        alerts: {
            start_preview_timeout: '#803 Time-out opgetreden bij het starten van de voorbeeldmodus. Start uw machine of Beam Studio opnieuw op. Als deze fout zich blijft voordoen, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">deze handleiding</a>.',
            fail_to_start_preview: '#803 Kan voorbeeldmodus niet starten. Start uw machine of Beam Studio opnieuw op. Als deze fout zich blijft voordoen, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">deze handleiding</a>.',
            fail_to_connect_with_camera: '#803 Kan geen verbinding maken met machinecamera. Start uw machine of Beam Studio opnieuw op. Als deze fout zich blijft voordoen, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">deze handleiding</a>.',
            add_content_first: 'Voeg eerst objecten toe',
            headtype_mismatch: 'Onjuiste module gedetecteerd. ',
            headtype_none: 'Module niet gedetecteerd. ',
            headtype_unknown: 'Onbekende module gedetecteerd. ',
            install_correct_headtype: 'Installeer de 10W/20W diode lasermodules correct om laag laservermogen voor het uitvoeren van frames in te schakelen.',
            door_opened: 'Sluit het deksel om laag laservermogen voor het uitvoeren van frames in te schakelen.',
            fail_to_get_door_status: 'Zorg ervoor dat het deksel gesloten is om laag laservermogen voor het uitvoeren van frames in te schakelen.',
            QcleanScene: 'Wil je de tekening wissen?<br/>Dit zal ook je undo-geschiedenis wissen!',
            power_too_high: 'TE HOGE SPANNING',
            power_too_high_msg: 'Gebruik een lager laservermogen (onder 70%) om de levensduur van de lasertube te verlengen.\nVoer "BEGREPEN" in om door te gaan.',
            power_too_high_confirm: 'BEGREPEN',
        },
        hint: {
            polygon: 'Druk op + / - om het aantal zijden te verhogen / verlagen.',
        },
        menu: {
            preferences: 'Voorkeuren',
            hide: 'Verberg Beam Studio',
            hideothers: 'Anderen verbergen',
            service: 'Diensten',
            quit: 'Afsluiten',
            window: 'Venster',
            minimize: 'Minimaliseren',
            close: 'Venster sluiten',
            file: 'Bestand',
            edit: 'Bewerken',
            help: 'Help',
            open: 'Openen',
            samples: 'Voorbeelden',
            import_hello_beamo: 'beamo voorbeeld',
            import_hello_beambox: 'beambox voorbeeld',
            import_material_testing_old: 'Materiaal graveertest - Klassiek',
            import_material_testing_simple_cut: 'Materiaal snijtest - Eenvoudig',
            import_material_testing_cut: 'Materiaal snijtest',
            import_material_testing_engrave: 'Materiaal graveertest',
            import_material_testing_line: 'Materiaal testlijn',
            import_material_printing_test: 'Materiaal printtest',
            import_ador_laser_example: 'Voorbeeld van Ador Laser',
            import_ador_printing_example_single: 'Voorbeeld van Ador Printing - Enkele kleur',
            import_ador_printing_example_full: 'Voorbeeld van Ador Printing - Volledige kleur',
            import_acrylic_focus_probe: 'Acryl focus probe - 3mm',
            export_to: 'Exporteren naar...',
            export_flux_task: 'FLUX taak',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Opslaan',
            save_as: 'Opslaan als...',
            save_to_cloud: 'Opslaan in cloud',
            about_beam_studio: 'Over Beam Studio',
            undo: 'Ongedaan maken',
            redo: 'Opnieuw',
            cut: 'Knippen',
            copy: 'Kopiëren',
            paste: 'Plakken',
            paste_in_place: 'Plakken op plaats',
            group: 'Groeperen',
            ungroup: 'Degroeperen',
            delete: 'Verwijderen',
            duplicate: 'Dupliceren',
            offset: 'Verschuiven',
            scale: 'Schalen',
            rotate: 'Draaien',
            reset: 'Resetten',
            align_center: 'Centreren',
            photo_edit: 'Afbeelding',
            svg_edit: 'SVG',
            path: 'Pad',
            decompose_path: 'Ontleden',
            object: 'Object',
            layer_setting: 'Laag',
            layer_color_config: 'Kleurinstellingen',
            image_sharpen: 'Scherpstellen',
            image_crop: 'Bijsnijden',
            image_invert: 'Inverteren',
            image_stamp: 'Facet',
            image_vectorize: 'Vectoriseren',
            image_curve: 'Kromme',
            align_to_edges: 'Uitlijnen op hoekpunten',
            document_setting: 'Documentinstellingen',
            document_setting_short: 'Document',
            clear_scene: 'Nieuwe bestanden',
            machines: 'Machines',
            add_new_machine: 'Machine-instellingen',
            help_center: 'Hulppagina',
            show_gesture_tutorial: 'Introductie handgebaren',
            show_start_tutorial: 'Introductietutorial tonen',
            show_ui_intro: 'Interface-introductie tonen',
            questionnaire: 'Vragenlijst feedback',
            change_logs: 'Wijzigingslogboeken',
            contact: 'Contact',
            tutorial: 'Delta-familie printtutorial starten',
            design_market: 'Ontwerpmarkt',
            forum: 'Communityforum',
            software_update: 'Software-update',
            bug_report: 'Fout melden',
            dashboard: 'Dashboard',
            machine_info: 'Machinegegevens',
            network_testing: 'Netwerkinstellingen testen',
            commands: "Commando's",
            update_firmware: 'Firmware bijwerken',
            using_beam_studio_api: 'Beam Studio API gebruiken',
            set_as_default: 'Instellen als standaard',
            calibrate_beambox_camera: 'Camera kalibreren',
            calibrate_printer_module: 'Printmodule kalibreren',
            calibrate_ir_module: 'Infraroodmodule kalibreren',
            calibrate_beambox_camera_borderless: 'Camera kalibreren (open onderkant)',
            calibrate_diode_module: 'Diodelasermodule kalibreren',
            manage_account: 'Mijn account beheren',
            sign_in: 'Inloggen',
            sign_out: 'Uitloggen',
            account: 'Account',
            my_account: 'Mijn account',
            download_log: 'Logs downloaden',
            download_log_canceled: 'Log download geannuleerd',
            download_log_error: 'Onbekende fout opgetreden, later opnieuw proberen',
            keyboard_shortcuts: 'Toetsencombinaties',
            log: {
                network: 'Netwerk',
                hardware: 'Hardware',
                discover: 'Ontdekken',
                usb: 'USB',
                usblist: 'USB-lijst',
                camera: 'Camera',
                cloud: 'Cloud',
                player: 'Speler',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Weergave',
            zoom_in: 'Inzoomen',
            zoom_out: 'Uitzoomen',
            fit_to_window: 'Aanpassen aan venster',
            zoom_with_window: 'Automatisch aanpassen aan venster',
            borderless_mode: 'Randloze modus',
            show_grids: 'Rasters weergeven',
            show_rulers: 'Linialen tonen',
            show_layer_color: 'Laagkleur gebruiken',
            anti_aliasing: 'Antialiasing',
            disassemble_use: 'Demonteren',
        },
        select_machine: 'Selecteer een machine',
    },
    support: {
        no_webgl: 'WebGL wordt niet ondersteund. Gebruik een ander apparaat.',
        no_vcredist: 'Installeer Visual C++ Redistributable 2015<br/>Downloadbaar op flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Onbekende fout opgetreden. Herstart Beam Studio en de machine.',
        OPERATION_ERROR: '[OE] Er is een statusconflict opgetreden, probeer de actie opnieuw.',
        SUBSYSTEM_ERROR: '[SE] Fout bij het uitvoeren van de taak door de firmware van de machine. Herstart de machine.',
        UNKNOWN_COMMAND: '[UC] Werk de firmware van het apparaat bij',
    },
    device_selection: {
        no_beambox: '#801 We kunnen uw machine niet vinden in het netwerk.\nVolg de <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">handleiding</a> om verbindingsproblemen op te lossen.',
        no_device_web: "#801 Controleer de status van uw machine, of klik op 'Machine-instellingen' hieronder om de machine in te stellen.",
    },
    update: {
        update: 'Bijwerken',
        release_note: 'Release opmerkingen:',
        firmware: {
            caption: 'Er is een firmware-update voor de machine beschikbaar',
            message_pattern_1: '"%s" is nu klaar voor een firmware-update.',
            message_pattern_2: '%s firmware v%s is nu beschikbaar - U heeft v%s.',
            latest_firmware: {
                caption: 'Werk machinefirmware bij',
                message: 'U heeft de nieuwste machinefirmware',
                still_update: 'BIJWERKEN',
                cant_get_latest: 'Kan geen informatie krijgen over de nieuwste firmware.',
            },
            confirm: 'UPLOADEN',
            upload_file: 'Firmware uploaden (*.bin / *.fxfw)',
            update_success: 'Firmware-update succesvol geüpload',
            update_fail: '#822 Update mislukt',
            too_old_for_web: 'Uw huidige machinefirmwareversie is v%s.\nAls u de online versie van Beam Studio wilt gebruiken, werk dan de machinefirmware bij naar de nieuwste versie.',
            force_update_message: '#814 Werk uw machine bij naar de nieuwste firmwareversie.',
            firmware_too_old_update_by_sdcard: 'De firmwareversie is te oud. Werk de firmware bij met behulp van een SD-kaart.',
        },
        software: {
            checking: 'Controleren op updates',
            switch_version: 'Versie wisselen',
            check_update: 'Controleren op updates',
            caption: 'Er is een software-update voor Beam Studio beschikbaar',
            downloading: 'Updates worden op de achtergrond gedownload, u kunt op "OK" klikken om door te gaan met uw werk.',
            install_or_not: 'is klaar voor update. Wilt u nu opnieuw opstarten en updaten?',
            switch_or_not: 'is klaar om te wisselen. Wilt u nu opnieuw opstarten en wisselen?',
            available_update: 'Beam Studio v%s is nu beschikbaar. U heeft versie %s. Wilt u de update downloaden?',
            available_switch: 'Beam Studio v%s is nu beschikbaar. U heeft versie %s. Wilt u upgraden naar deze versie?',
            not_found: 'U gebruikt de nieuwste versie van Beam Studio.',
            no_response: 'Kan geen verbinding maken met server, controleer uw netwerkinstellingen.',
            switch_version_not_found: 'Te upgraden versie niet gevonden.',
            yes: 'Ja',
            no: 'Nee',
            update_for_ador: 'De huidige softwareversie %s is niet compatibel, download de nieuwste versie van Beam Studio voor Ador.',
        },
        updating: 'Bezig met updaten...',
        skip: 'Deze versie overslaan',
        preparing: 'Voorbereiden...',
        later: 'LATER',
        download: 'ONLINE UPDATE',
        cannot_reach_internet: '#823 Server is niet bereikbaar<br/>Controleer de internetverbinding',
        install: 'INSTALLEREN',
        upload: 'UPLOADEN',
    },
    topmenu: {
        version: 'Versie',
        credit: 'Beam Studio is mogelijk gemaakt door het <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> open source project en andere <a target="_blank" href="https://flux3dp.com/credits/">open source software</a>.',
        ok: 'OK',
        file: {
            label: 'Bestand',
            import: 'Importeren',
            save_fcode: 'Exporteer FLUX-taak',
            save_scene: 'Scene opslaan',
            save_svg: 'Exporteer SVG',
            save_png: 'Exporteer PNG',
            save_jpg: 'Exporteer JPG',
            converting: 'Converteren naar afbeelding...',
            all_files: 'Alle bestanden',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio-scène',
            fcode_files: 'FLUX-code',
            clear_recent: 'Recent geopende wissen',
            path_not_exit: 'Dit pad lijkt niet meer te bestaan op de schijf.',
        },
        device: {
            download_log_canceled: 'Logboekdownload geannuleerd',
            download_log_error: 'Onbekende fout opgetreden, probeer het later opnieuw',
            log: {
                usblist: 'USB-lijst',
            },
            network_test: 'Netwerk testen',
        },
    },
    initialize: {
        next: 'Volgende',
        start: 'Starten',
        skip: 'Overslaan',
        cancel: 'Annuleren',
        confirm: 'Bevestigen',
        connect: 'Verbinden',
        back: 'Terug',
        retry: 'Opnieuw',
        no_machine: 'Ik heb nu geen machine.',
        select_language: 'Selecteer Taal',
        select_machine_type: 'Selecteer Uw Machinetype',
        select_connection_type: 'Hoe wilt u verbinden?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Bekabeld netwerk',
            ether2ether: 'Directe verbinding',
            usb: 'USB-verbinding',
        },
        connect_wifi: {
            title: 'Verbinden met Wi-Fi',
            tutorial1: '1. Ga naar het aanraakscherm > Tik op "Netwerk" > "Verbind met WiFi".',
            tutorial1_ador: '1. Ga naar het aanraakscherm > Tik op "MACHINE" > Tik op "Netwerk" > "Verbind met WiFi".',
            tutorial2: '2. Selecteer en verbind met uw voorkeurs Wi-Fi.',
            what_if_1: 'Wat als ik mijn Wi-Fi niet zie?',
            what_if_1_content: '1. De Wi-Fi-encryptiemodus moet WPA2 zijn of geen wachtwoord hebben.\n2. De encryptiemodus kan worden ingesteld in de Wi-Fi-routerbeheerinterface. Als de router WPA2 niet ondersteunt en u hulp nodig heeft bij het uitkiezen van de juiste router, neem dan contact op met FLUX-ondersteuning.',
            what_if_2: 'Wat als ik helemaal geen Wi-Fi zie?',
            what_if_2_content: '1. Zorg ervoor dat de Wi-Fi-dongle volledig is aangesloten.\n2. Als er geen MAC-adres van het draadloze netwerk op het aanraakscherm staat, neem dan contact op met FLUX-ondersteuning.\n3. Het Wi-Fi-kanaal moet 2.4Ghz zijn (5Ghz wordt niet ondersteund).',
        },
        connect_wired: {
            title: 'Verbinden met bekabeld netwerk',
            tutorial1: '1. Verbind de machine met uw router.',
            tutorial2: '2. Druk op "Netwerk" om het IP-adres van het bekabelde netwerk te krijgen.',
            tutorial2_ador: '2. Druk op "MACHINE" > "Netwerk" om het IP-adres van het bekabelde netwerk te krijgen.',
            what_if_1: 'Wat als het IP-adres leeg is?',
            what_if_1_content: '1. Zorg ervoor dat de ethernetkabel volledig is aangesloten.\n2. Als er geen MAC-adres van het bekabelde netwerk op het aanraakscherm staat, neem dan contact op met FLUX-ondersteuning.',
            what_if_2: 'Wat als het IP begint met 169?',
            what_if_2_content: '1. Als het IP-adres begint met 169.254, zou het een DHCP-instellingsprobleem moeten zijn, neem voor verdere hulp contact op met uw ISP (internet service provider).\n2. Als uw computer direct verbinding maakt met internet via PPPoE, verander dit dan naar verbinding maken via de router met PPPoE en schakel de DHCP-functie op de router in.',
        },
        connect_ethernet: {
            title: 'Directe verbinding',
            tutorial1: '1. Verbind de machine met uw computer met een ethernetkabel.',
            tutorial2_1: '2. Volg',
            tutorial2_a_text: 'deze handleiding',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: 'om uw computer als router in te stellen.',
            tutorial3: '3. Klik op Volgende.',
        },
        connect_usb: {
            title: 'USB-verbinding',
            title_sub: '(alleen HEXA & Ador)',
            tutorial1: 'Sluit de machine aan op uw computer met een USB-kabel.',
            tutorial2: "Klik op 'Volgende'.",
            turn_off_machine: 'Zet de machine uit.',
            turn_on_machine: 'Zet de machine aan.',
            wait_for_turning_on: "Klik op 'Volgende' nadat het opstartproces is voltooid en u het hoofdscherm hebt bereikt.",
        },
        connect_machine_ip: {
            check_usb: 'Controleer USB-verbinding',
            enter_ip: 'Voer uw machine-IP in',
            check_ip: 'IP-beschikbaarheid controleren',
            invalid_ip: 'Ongeldig IP: ',
            invalid_format: 'Ongeldig formaat',
            starts_with_169254: 'Begint met 169.254',
            unreachable: 'IP onbereikbaar',
            check_connection: 'Machineverbinding controleren',
            check_firmware: 'Firmwareversie controleren',
            check_camera: 'Camera beschikbaarheid controleren',
            retry: 'Opnieuw proberen',
            succeeded_message: 'Verbinding gelukt 🎉',
            finish_setting: 'Begin met ontwerpen!',
        },
        connecting: 'Verbinden...',
        setting_completed: {
            start: 'Start',
            great: 'Welkom bij Beam Studio',
            setup_later: 'U kunt uw machine altijd instellen via de titelbalk > "Machines" > "Machine instellen"',
            back: 'Terug',
            ok: 'BEGIN MET ONTWERPEN',
        },
    },
    error_pages: {
        screen_size: 'Houd er rekening mee dat Beam Studio mogelijk niet optimaal werkt op uw apparaat. Voor een optimale ervaring moet het een apparaat zijn met een schermbreedte van minimaal 1024 pixels.',
    },
    menu: {
        mm: 'mm',
        inches: 'Duimen',
    },
    settings: {
        on: 'Aan',
        off: 'Uit',
        low: 'Laag',
        high: 'Normaal',
        caption: 'Instellingen',
        tabs: {
            general: 'Algemeen',
            device: 'Machine',
        },
        ip: 'Machine IP-adres',
        guess_poke: 'Zoek naar machine IP-adres',
        auto_connect: 'Selecteer automatisch de enige machine',
        wrong_ip_format: 'Ongeldig IP-formaat',
        default_machine: 'Standaardmachine',
        default_machine_button: 'Leeg',
        remove_default_machine_button: 'Verwijderen',
        confirm_remove_default: 'De standaardmachine wordt verwijderd.',
        reset: 'Beam Studio resetten',
        reset_now: 'Beam Studio resetten',
        confirm_reset: 'Beam Studio resetten bevestigen?',
        language: 'Taal',
        notifications: 'Bureaubladmeldingen',
        check_updates: 'Automatisch controleren',
        autosave_enabled: 'Automatisch opslaan',
        autosave_path: 'Locatie voor automatisch opslaan',
        autosave_interval: 'Elke',
        autosave_number: 'Aantal automatisch opslaan',
        autosave_path_not_correct: 'Opgegeven pad niet gevonden.',
        preview_movement_speed: 'Voorbeeld bewegingssnelheid',
        preview_movement_speed_hl: 'Voorbeeld bewegingssnelheid (laser ingeschakeld)',
        default_units: 'Standaard eenheden',
        default_font_family: 'Standaard lettertype',
        default_font_style: 'Standaard lettertypestijl',
        fast_gradient: 'Snelheidsoptimalisatie',
        engraving_direction: 'Richting',
        top_down: 'Van boven naar beneden',
        bottom_up: 'Van onder naar boven',
        vector_speed_constraint: 'Snelheidslimiet (20mm/s)',
        loop_compensation: 'Luscompensatie',
        blade_radius: 'Messtraal',
        blade_precut_switch: 'Mes voorinsnijding',
        blade_precut_position: 'Voorinsnijpositie',
        default_beambox_model: 'Standaard documentinstellingen',
        guides_origin: 'Hulplijnen oorsprong',
        guides: 'Hulplijnen',
        image_downsampling: 'Bitmap voorbeeldkwaliteit',
        anti_aliasing: 'Antialiasing',
        continuous_drawing: 'Doorlopende tekening',
        trace_output: 'Afbeelding vectorisatie uitvoer',
        single_object: 'Enkel object',
        grouped_objects: 'Gegroepeerde objecten',
        simplify_clipper_path: 'Optimaliseer het berekende pad',
        enable_low_speed: 'Schakel lage snelheidsbeweging in',
        enable_custom_backlash: 'Schakel aangepaste backlash compensatie in',
        custom_preview_height: 'Aangepaste voorbeeldhoogte',
        mask: 'Werkgebied bijsnijden',
        text_path_calc_optimization: 'Padberekeningsoptimalisatie',
        font_substitute: 'Vervang onondersteunde tekens',
        font_convert: 'Tekst naar pad Converter',
        default_borderless_mode: 'Open onderkant standaard',
        default_enable_autofocus_module: 'Autofocus standaard',
        default_enable_diode_module: 'Diode laser standaard',
        diode_offset: 'Diode laser offset',
        autofocus_offset: 'Autofocus offset',
        diode_one_way_engraving: 'Diode laser eenrichtingsgravering',
        diode_two_way_warning: 'Bidirectioneel licht uitstralen is sneller en genereert waarschijnlijk enige onnauwkeurigheid in de lasergraveerpositie. Aanbevolen om eerst te testen.',
        share_with_flux: 'Beam Studio-analyses delen',
        none: 'Geen',
        close: 'Sluiten',
        enabled: 'Ingeschakeld',
        disabled: 'Uitgeschakeld',
        cancel: 'Annuleren',
        done: 'Toepassen',
        module_offset_10w: '10W diode laser offset',
        module_offset_20w: '20W diode laser offset',
        module_offset_printer: 'Printer offset',
        module_offset_2w_ir: '2W infrarood laser offset',
        printer_advanced_mode: 'Printer geavanceerde modus',
        default_laser_module: 'Standaard lasermodule',
        low_laser_for_preview: 'Laser voor Frame uitvoeren',
        groups: {
            general: 'Algemeen',
            update: 'Software-updates',
            connection: 'Verbinding',
            autosave: 'Automatisch opslaan',
            camera: 'Camera',
            editor: 'Editor',
            engraving: 'Graveren (scannen)',
            path: 'Vector (omtrekken)',
            mask: 'Werkgebied bijsnijden',
            text_to_path: 'Tekst',
            modules: 'Plug-ins',
            ador_modules: 'Ador-plug-ins',
            privacy: 'Privacy',
        },
        notification_on: 'Aan',
        notification_off: 'Uit',
        update_latest: 'Laatste',
        update_beta: 'Bèta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Groep',
            use: 'Importeer SVG',
            image: 'Afbeelding',
            text: 'Tekst',
        },
        context_menu: {
            cut: 'Knippen',
            copy: 'Kopiëren',
            paste: 'Plakken',
            paste_in_place: 'Plakken op plaats',
            duplicate: 'Dupliceren',
            delete: 'Verwijderen',
            group: 'Groeperen',
            ungroup: 'Degroeperen',
            move_front: 'Naar voorgrond verplaatsen',
            move_up: 'Naar voren verplaatsen',
            move_down: 'Naar achteren verplaatsen',
            move_back: 'Naar achtergrond verplaatsen',
        },
        popup: {
            select_import_method: 'Selecteer de laagstijl:',
            select_import_module: 'Selecteer module:',
            touchpad: 'Touchpad',
            mouse: 'Muis',
            layer_by_layer: 'Laag',
            layer_by_color: 'Kleur',
            nolayer: 'Enkele laag',
            loading_image: 'Afbeelding laden, even geduld...',
            no_support_text: 'Beam Studio ondersteunt momenteel geen teksttag. Zet tekst om naar pad voordat u importeert.',
            speed_too_high_lower_the_quality: 'Te hoge snelheid bij deze resolutie kan resulteren in lagere kwaliteit van arcering.',
            both_power_and_speed_too_high: 'Gebruik van lagere lasersterkte verlengt de levensduur van de laserbuis.\nTe hoge snelheid bij deze resolutie kan resulteren in lagere kwaliteit van arcering.',
            too_fast_for_path: 'Te hoge snelheid in lagen met padobjecten kan resulteren in lagere precisie bij snijden.\nWe raden geen snelheid hoger dan 20 mm/s aan bij snijden.',
            too_fast_for_path_and_constrain: 'De volgende lagen: %s\nbevatten vector padobjecten, en hebben een snelheid hoger dan 20 mm/s.\nDe snijsnelheid van vector padobjecten wordt beperkt tot 20 mm/s.\nU kunt deze beperking opheffen bij de voorkeuren.',
            should_update_firmware_to_continue: '#814 Uw firmware ondersteunt deze versie van Beam Studio niet. Werk de firmware bij om door te gaan. (Menu > Machine > [Uw machine] > Firmware bijwerken)',
            recommend_downgrade_software: 'We hebben een oudere firmwareversie gedetecteerd. We werken actief aan compatibiliteitsproblemen, maar voor nu raden we aan terug te keren naar <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'We hebben een oudere firmwareversie gedetecteerd. We werken actief aan compatibiliteitsproblemen, maar voor nu raden we aan de firmware bij te werken naar de laatste versie.',
            still_continue: 'Doorgaan',
            more_than_two_object: 'Te veel objecten. Ondersteuning voor slechts 2 objecten',
            not_support_object_type: 'Objecttype wordt niet ondersteund',
            select_first: 'Selecteer eerst een object.',
            select_at_least_two: 'Selecteer twee objecten om door te gaan',
            import_file_contain_invalid_path: '#808 Geïmporteerd SVG-bestand bevat ongeldig pad naar afbeelding. Zorg ervoor dat alle afbeeldingen bestaan of inbed de afbeelding in het bestand',
            import_file_error_ask_for_upload: 'Importeren van SVG-bestand mislukt. Wilt u het bestand beschikbaar stellen aan het ontwikkelteam voor bugrapportage?',
            upload_file_too_large: '#819 Bestand is te groot om te uploaden.',
            successfully_uploaded: 'Uploaden van bestand gelukt.',
            upload_failed: '#819 Uploaden van bestand mislukt.',
            or_turn_off_borderless_mode: 'Of schakel de modus zonder onderrand uit.',
            svg_1_1_waring: 'Deze SVG-versie is 1.1, er kunnen compatibiliteitsproblemen zijn.',
            svg_image_path_waring: 'Dit SVG-bestand bevat <image> paden. Dit kan mislukken bij laden.\nGebruik ingesloten afbeeldingen bij exporteren van SVG om dit te voorkomen.',
            dxf_version_waring: 'De DXF-versie is niet 2013, er kunnen compatibiliteitsproblemen zijn.',
            dont_show_again: 'Niet meer tonen.',
            convert_to_path_fail: 'Converteren naar pad is mislukt.',
            save_unsave_changed: 'Wilt u de niet-opgeslagen wijzigingen opslaan?',
            dxf_bounding_box_size_over: 'De tekeninggrootte is buiten het werkgebied. Verplaats uw tekening dichter bij de oorsprong in uw CAD-software, of zorg dat de eenheid correct is.',
            progress: {
                uploading: 'Uploaden',
                calculating: 'Berekenen',
            },
            backend_connect_failed_ask_to_upload: '#802 Fouten blijven optreden bij verbinden met de backend. Wilt u uw bugrapport uploaden?',
            backend_error_hint: 'Functies werken mogelijk niet goed vanwege een backendfout.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Fout bij converteren van PDF naar SVG:',
                error_pdf2svg_not_found: '#825 Fout: opdracht pdf2svg niet gevonden. Installeer pdf2svg met uw pakketbeheerder.',
            },
            ungroup_use: 'Dit zal geïmporteerde DXF/SVG ontgroeperen. Omdat er veel elementen kunnen zijn, kan het even duren. Weet u zeker dat u wilt doorgaan?',
            vectorize_shading_image: 'Gradientafbeeldingen vergen meer verwerkingstijd voor vectorisatie en zijn gevoelig voor ruis. Schakel de afbeeldingsgradiënt uit voordat u verdergaat.',
            change_workarea_before_preview: 'Het werkgebied van %s komt niet overeen met het huidige werkgebied. Wilt u overschakelen naar het huidige werkgebied?',
            bug_report: 'Bugrapportage',
            sentry: {
                title: 'Laten we Beam Studio samen beter maken',
                message: 'Gaat u ermee akkoord om relevante informatie automatisch te uploaden naar het ontwikkelingsteam wanneer er fouten optreden?',
            },
            questionnaire: {
                caption: 'Help ons met het invullen van de vragenlijst',
                message: 'Help ons met het invullen van de vragenlijst om Beam Studio beter te maken.',
                unable_to_get_url: 'Het ophalen van de link naar de vragenlijst via internet is mislukt. Controleer uw internetverbinding.',
                no_questionnaire_available: 'Er is momenteel geen vragenlijst beschikbaar.',
            },
            facebook_group_invitation: {
                title: 'Word lid van de officiële FLUX gebruikersgroep',
                message: 'Word lid van onze officiële Facebook-groep om in contact te komen met andere FLUX-lasergebruikers, FLUX-lasers te bespreken, laserkunstwerken te delen en op de hoogte te blijven van de nieuwste updates over onze producten. We kijken ernaar uit u daar te zien!',
                join_now: 'Ik doe mee',
                later: 'Misschien later',
                already_joined: 'Al lid',
                dont_show_again: 'Niet meer weergeven',
            },
            ai_credit: {
                relogin_to_use: 'Log opnieuw in om deze functie te gebruiken.',
                insufficient_credit: 'Je hebt geen tegoed meer',
                insufficient_credit_msg: 'Je kunt %s niet gebruiken. Ga naar het tegoedcentrum en koop AI-tegoed.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Ga',
            },
            text_to_path: {
                caption: 'Tekst naar pad Converter 2.0',
                message: "Beam Studio introduceert nu een nieuwe tekst naar pad converter (Converter 2.0), die betrouwbaardere resultaten oplevert! Wilt u nu overschakelen? \nU kunt deze instelling later ook aanpassen in het gedeelte 'Tekst naar pad Converter' binnen de voorkeuren.",
            },
        },
        zoom_block: {
            fit_to_window: 'Passend maken aan venster',
        },
        time_est_button: {
            calculate: 'Schat tijd in',
            estimate_time: 'Geschatte tijd:',
        },
        left_panel: {
            unpreviewable_area: 'Blind gebied',
            diode_blind_area: 'Blind gebied hybride laseradd-on',
            borderless_blind_area: 'Niet-graverend gebied',
            borderless_preview: 'Open onderkant modus camerabeeld',
            rectangle: 'Rechthoek',
            ellipse: 'Ellips',
            line: 'Lijn',
            image: 'Afbeelding',
            text: 'Tekst',
            label: {
                cursor: 'Selecteer',
                photo: 'Afbeelding',
                text: 'Tekst',
                line: 'Lijn',
                rect: 'Rechthoek',
                oval: 'Ovaal',
                polygon: 'Veelhoek',
                pen: 'Pen',
                shapes: 'Vormen',
                array: 'Array',
                preview: 'Liveweergave',
                trace: 'Afbeelding traceren',
                end_preview: 'Liveweergave stoppen',
                clear_preview: 'Voorbeeld wissen',
                choose_camera: 'Camera',
                live_feed: 'Live beeld',
                adjust_height: 'Hoogte aanpassen',
                qr_code: 'QR-code',
                boxgen: 'Boxgen',
                my_cloud: 'Mijn cloud',
                curve_engraving: {
                    title: '3D Curve',
                    exit: 'Afsluiten',
                    select_area: 'Gebied selecteren',
                    preview_3d_curve: 'Voorvertoning 3D-curve',
                    clear_area: 'Geselecteerd gebied wissen',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Lagen',
                objects: 'Objecten',
                path_edit: 'Pad bewerken',
            },
            layer_panel: {
                layer1: 'Laag 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Graveren',
                layer_cutting: 'Snijden',
                current_layer: 'Huidige laag',
                move_elems_to: 'Verplaats elementen naar:',
                notification: {
                    dupeLayerName: 'Er is al een laag met die naam!',
                    newName: 'NIEUWE NAAM',
                    enterUniqueLayerName: 'Voer een unieke laagnaam in',
                    enterNewLayerName: 'Voer de nieuwe laagnaam in',
                    layerHasThatName: 'Laag heeft al die naam',
                    QmoveElemsToLayer: "Geselecteerde elementen verplaatsen naar laag '%s'?",
                    moveElemFromPrintingLayerTitle: 'Wilt u het geselecteerde element verplaatsen naar %s en converteren naar een laser element?',
                    moveElemFromPrintingLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de kleurinstellingen van het geselecteerde element worden verwijderd en ingesteld volgens %s.',
                    moveElemToPrintingLayerTitle: 'Wilt u het geselecteerde element verplaatsen naar %s en converteren naar een print element?',
                    moveElemToPrintingLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de instellingen van het geselecteerde element worden verwijderd en ingesteld volgens %s.',
                    splitColorTitle: 'Wilt u de geselecteerde laag uitbreiden naar CMYK-lagen?',
                    splitColorMsg: 'Houd er rekening mee dat u, als u doorgaat met deze bewerking, niet meer kunt terugkeren naar de oorspronkelijke kleurlagen.',
                    mergeLaserLayerToPrintingLayerTitle: 'Wilt u deze lagen samenvoegen tot één printlaag?',
                    mergeLaserLayerToPrintingLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de instellingen van de laserlaag worden verwijderd en ingesteld volgens de huidige laag.',
                    mergePrintingLayerToLaserLayerTitle: 'Wilt u deze lagen samenvoegen tot één laserlaag?',
                    mergePrintingLayerToLaserLayerMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de kleurinstellingen van de printlaag worden verwijderd en ingesteld volgens de huidige laag.',
                },
                layers: {
                    layer: 'Laag',
                    layers: 'Lagen',
                    del: 'Laag verwijderen',
                    move_down: 'Laag omlaag verplaatsen',
                    new: 'Nieuwe laag',
                    rename: 'Laag hernoemen',
                    move_up: 'Laag omhoog verplaatsen',
                    dupe: 'Laag dupliceren',
                    lock: 'Laag vergrendelen',
                    unlock: 'Ontgrendelen',
                    merge_down: 'Samenvoegen omlaag',
                    merge_all: 'Alles samenvoegen',
                    merge_selected: 'Geselecteerde lagen samenvoegen',
                    move_elems_to: 'Elementen verplaatsen naar:',
                    move_selected: 'Geselecteerde elementen verplaatsen naar een andere laag',
                    switchToFullColor: 'Overschakelen naar volledige kleurenlaag',
                    switchToSingleColor: 'Overschakelen naar enkele kleurenlaag',
                    splitFullColor: 'Laag splitsen',
                    fullColor: 'Volledige kleur',
                },
            },
            laser_panel: {
                preset_setting: 'Parameterinstellingen (%s)',
                multi_layer: 'Meerdere lagen',
                parameters: 'Parameters',
                strength: 'Vermogen',
                low_power_warning: 'Lager laservermogen (onder 10%) zou geen laserlicht kunnen uitstralen.',
                speed: 'Snelheid',
                speed_contrain_warning: 'De snijsnelheid van vectorpadobjecten wordt beperkt tot 20 mm/s (0,79 inch/s). U kunt deze beperking opheffen bij de voorkeursinstellingen.',
                low_speed_warning: 'Lage snelheid kan brandplekken op het materiaal veroorzaken.',
                repeat: 'Uitvoeren',
                add_on: 'Add-on',
                focus_adjustment: 'Focusaanpassing',
                height: 'Objecthoogte',
                z_step: 'Z-stap',
                diode: 'Diode laser',
                backlash: 'Speling',
                ink_saturation: 'Verzadiging',
                print_multipass: 'Meervoudig',
                white_ink: 'Witte inkt',
                white_ink_settings: 'Witte inkt instellingen',
                color_adjustment: 'Kanaal aanpassing',
                color_adjustment_short: 'Kanaal',
                halftone: 'Halftoon',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Kracht',
                times: 'keer',
                cut: 'Snijden',
                engrave: 'Graveren',
                more: 'Beheren',
                delete: 'Verwijderen',
                reset: 'Resetten',
                sure_to_reset: 'Dit zal alle standaardinstellingen resetten en uw aangepaste parameters behouden, weet u zeker dat u wilt doorgaan?',
                apply: 'Toepassen',
                cancel: 'Annuleren',
                save: 'Opslaan',
                save_and_exit: 'Opslaan en afsluiten',
                name: 'Naam',
                default: 'Standaard',
                customized: 'Aangepaste lijst',
                inuse: 'Gebruiken',
                export_config: 'Voorinstellingen exporteren',
                new_config_name: 'Nieuwe voorinstelling naam',
                sure_to_load_config: 'Dit zal de voorinstellingen laden en uw aangepaste parameters vervangen. Weet u zeker dat u wilt doorgaan?',
                custom_preset: 'Aangepast',
                various_preset: 'Diverse voorinstellingen',
                module: 'Module',
                slider: {
                    regular: 'Normaal',
                    low: 'Laag',
                    very_low: 'Minimaal',
                    high: 'Hoog',
                    very_high: 'Maximaal',
                    slow: 'Zeer langzaam',
                    very_slow: 'Langzaamst',
                    fast: 'Snel',
                    very_fast: 'Zeer snel',
                },
                dropdown: {
                    parameters: 'Voorinstellingen',
                    save: 'Voeg huidige parameters toe',
                    export: 'Exporteren',
                    import: 'Importeren',
                    more: 'Beheren',
                    mm: {
                        wood_3mm_cutting: 'Hout - 3mm Snijden',
                        wood_5mm_cutting: 'Hout - 5mm Snijden',
                        wood_7mm_cutting: 'Hout - 7mm Snijden',
                        wood_8mm_cutting: 'Hout - 8mm Snijden',
                        wood_10mm_cutting: 'Hout - 10mm Snijden',
                        wood_engraving: 'Hout - Graveren',
                        acrylic_3mm_cutting: 'Acryl - 3mm Snijden',
                        acrylic_5mm_cutting: 'Acryl - 5mm Snijden',
                        acrylic_8mm_cutting: 'Acryl - 8mm Snijden',
                        acrylic_10mm_cutting: 'Acryl - 10mm Snijden',
                        acrylic_engraving: 'Acryl - Graveren',
                        mdf_3mm_cutting: 'MDF - 3mm Snijden',
                        mdf_5mm_cutting: 'MDF - 5mm Snijden',
                        mdf_engraving: 'MDF - Graveren',
                        leather_3mm_cutting: 'Leer - 3mm Snijden',
                        leather_5mm_cutting: 'Leer - 5mm Snijden',
                        leather_engraving: 'Leer - Graveren',
                        denim_1mm_cutting: 'Denim - 1mm Snijden',
                        fabric_3mm_cutting: 'Stof - 3mm Snijden',
                        fabric_5mm_cutting: 'Stof - 5mm Snijden',
                        fabric_engraving: 'Stof - Graveren',
                        rubber_bw_engraving: 'Rubber - Graveren',
                        glass_bw_engraving: 'Glas - Graveren',
                        metal_bw_engraving: 'Metaal - Graveren',
                        steel_engraving_spray_engraving: 'Metaal - Graveren',
                        stainless_steel_bw_engraving_diode: 'Metaal - Graveren (Diode Laser)',
                        gold_engraving: 'Goud - Graveren',
                        brass_engraving: 'Messing - Graveren',
                        ti_engraving: 'Titaan - Graveren',
                        stainless_steel_engraving: 'Roestvrij Staal - Graveren',
                        aluminum_engraving: 'Aluminium - Graveren',
                        black_acrylic_3mm_cutting: 'Zwart Acryl - 3mm Snijden',
                        black_acrylic_5mm_cutting: 'Zwart Acryl - 5mm Snijden',
                        black_acrylic_engraving: 'Zwart Acryl - Graveren',
                        abs_engraving: 'ABS - Graveren',
                        silver_engraving: 'Zilver - Graveren',
                        iron_engraving: 'IJzer - Graveren',
                        fabric_printing: 'Stof - Printen',
                        canvas_printing: 'Canvas - Printen',
                        cardstock_printing: 'Karton - Printen',
                        wood_printing: 'Hout - Printen',
                        bamboo_printing: 'Bamboe - Printen',
                        cork_printing: 'Kurk - Printen',
                        flat_stone_printing: 'Platte steen - Printen',
                        acrylic_printing: 'Acryl - Printen',
                        pc_printing: 'PC - Printen',
                        stainless_steel_printing: 'Roestvrij staal - Printen',
                        gloss_leather_printing: 'Glansleer - Printen',
                        glass_printing: 'Glas - Printen',
                    },
                    inches: {
                        wood_3mm_cutting: 'Hout - 0,1" Snijden',
                        wood_5mm_cutting: 'Hout - 0,2" Snijden',
                        wood_7mm_cutting: 'Hout - 0,3" Snijden',
                        wood_8mm_cutting: 'Hout - 0,3" Snijden',
                        wood_10mm_cutting: 'Hout - 0,4" Snijden',
                        wood_engraving: 'Hout - Graveren',
                        acrylic_3mm_cutting: 'Acryl - 0,1" Snijden',
                        acrylic_5mm_cutting: 'Acryl - 0,2" Snijden',
                        acrylic_8mm_cutting: 'Acryl - 0,3" Snijden',
                        acrylic_10mm_cutting: 'Acryl - 0,4" Snijden',
                        acrylic_engraving: 'Acryl - Graveren',
                        mdf_3mm_cutting: 'MDF - 0,1" Snijden',
                        mdf_5mm_cutting: 'MDF - 0,2" Snijden',
                        mdf_engraving: 'MDF - Graveren',
                        leather_3mm_cutting: 'Leer - 0,1" Snijden',
                        leather_5mm_cutting: 'Leer - 0,2" Snijden',
                        leather_engraving: 'Leer - Graveren',
                        denim_1mm_cutting: 'Denim - 1 mm snijden',
                        fabric_3mm_cutting: 'Textiel - 3 mm snijden',
                        fabric_5mm_cutting: 'Textiel - 5 mm snijden',
                        fabric_engraving: 'Textiel - graveren',
                        rubber_bw_engraving: 'Rubber - graveren',
                        glass_bw_engraving: 'Glas - graveren',
                        metal_bw_engraving: 'Metaal - graveren',
                        steel_engraving_spray_engraving: 'Metaal - Graveren',
                        stainless_steel_bw_engraving_diode: 'RVS - graveren (diodelaser)',
                        gold_engraving: 'Goud - graveren',
                        brass_engraving: 'Messing - graveren',
                        ti_engraving: 'Titanium - graveren',
                        stainless_steel_engraving: 'RVS - graveren',
                        aluminum_engraving: 'Aluminium - graveren',
                        black_acrylic_3mm_cutting: 'Zwart acryl - 3 mm snijden',
                        black_acrylic_5mm_cutting: 'Zwart acryl - 5 mm snijden',
                        black_acrylic_engraving: 'Zwart acryl - Graveren',
                        abs_engraving: 'ABS - Graveren',
                        silver_engraving: 'Zilver - Graveren',
                        iron_engraving: 'IJzer - Graveren',
                        fabric_printing: 'Textiel - Printen',
                        canvas_printing: 'Canvas - Printen',
                        cardstock_printing: 'Karton - Printen',
                        wood_printing: 'Hout - Printen',
                        bamboo_printing: 'Bamboe - Printen',
                        cork_printing: 'Kurk - Printen',
                        flat_stone_printing: 'Platte steen - Printen',
                        acrylic_printing: 'Acryl - Printen',
                        pc_printing: 'PC - Printen',
                        stainless_steel_printing: 'RVS - Printen',
                        gloss_leather_printing: 'Glans leer - Printen',
                        glass_printing: 'Glas - Printen',
                    },
                },
                laser_speed: {
                    text: 'Snelheid',
                    unit: 'mm/s',
                    fast: 'Snel',
                    slow: 'Langzaam',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Vermogen',
                    high: 'Hoog',
                    low: 'Laag',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Inkttype',
                    normal: 'Inkt',
                    UV: 'UV-inkt',
                },
                para_in_use: 'Deze parameter wordt gebruikt.',
                do_not_adjust_default_para: 'Standaardinstellingen kunnen niet worden aangepast.',
                existing_name: 'Deze naam is al in gebruik.',
                presets: 'Voorinstelling',
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Groep',
                ungroup: 'Degroepeer',
                distribute: 'Verdeel',
                hdist: 'Horizontaal verdelen',
                vdist: 'Verticaal verdelen',
                align: 'Uitlijnen',
                left_align: 'Links uitlijnen',
                center_align: 'Centreren',
                right_align: 'Rechts uitlijnen',
                top_align: 'Boven uitlijnen',
                middle_align: 'Midden uitlijnen',
                bottom_align: 'Onder uitlijnen',
                boolean: 'Booleaans',
                union: 'Verenigen',
                subtract: 'Aftrekken',
                intersect: 'Snijden',
                difference: 'Verschil',
                flip: 'Spiegelen',
                hflip: 'Horizontaal spiegelen',
                vflip: 'Verticaal omdraaien',
                lock_aspect: 'Aspect vergrendelen',
                unlock_aspect: 'Aspect ontgrendelen',
                option_panel: {
                    fill: 'Vulling',
                    rounded_corner: 'Afgeronde hoek',
                    sides: 'Zijden',
                    font_family: 'Lettertype',
                    font_style: 'Stijl',
                    font_size: 'Grootte',
                    letter_spacing: 'Letterspatiëring',
                    line_spacing: 'Regelafstand',
                    vertical_text: 'Verticaal tekst',
                    start_offset: 'Tekstverschuiving',
                    vertical_align: 'Uitlijning',
                    text_infill: 'Tekstvulling',
                    path_infill: 'Padvulling',
                    shading: 'Verloop',
                    threshold: 'Drempelhelderheid',
                    threshold_short: 'Drempel',
                    stroke: 'Lijn',
                    stroke_color: 'Lijnkleur',
                    stroke_width: 'Lijndikte',
                    color: 'Kleur',
                },
                actions_panel: {
                    replace_with: 'Vervangen door...',
                    replace_with_short: 'Vervangen',
                    trace: 'Natrekken',
                    grading: 'Graderen',
                    brightness: 'Helderheid',
                    sharpen: 'Scherper maken',
                    crop: 'Bijsnijden',
                    bevel: 'Afronden',
                    invert: 'Inverteren',
                    weld_text: 'Tekst lassen',
                    convert_to_path: 'Converteren naar pad',
                    fetching_web_font: 'Online lettertype ophalen...',
                    uploading_font_to_machine: 'Lettertype uploaden naar machine...',
                    wait_for_parsing_font: 'Lettertype aan het parseren... Een moment geduld',
                    offset: 'Verschuiven',
                    array: 'Array',
                    auto_arrange: 'Automatisch Rangschikken',
                    decompose_path: 'Ontleden',
                    disassemble_use: 'Demonteren',
                    create_textpath: 'Tekst op pad maken',
                    create_textpath_short: 'Padtekst',
                    detach_path: 'Tekst van pad scheiden',
                    detach_path_short: 'Scheiden',
                    edit_path: 'Pad bewerken',
                    disassembling: 'Aan het demonteren...',
                    ungrouping: 'Groepering opheffen...',
                    simplify: 'Vereenvoudigen',
                    ai_bg_removal: 'Achtergrond verwijderen',
                    ai_bg_removal_short: 'Achtergrondverw.',
                    ai_bg_removal_reminder: 'Druk op de knop gebruikt direct 0,2 tegoed, wilt u doorgaan?',
                    outline: 'Omtrek',
                },
                path_edit_panel: {
                    node_type: 'Knooppunttype',
                    sharp: 'Scherp',
                    round: 'Rond',
                    connect: 'Verbinden',
                    disconnect: 'Ontkoppelen',
                    delete: 'Verwijderen',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Tekst naar pad omzetten...',
            retreive_image_data: 'Gegevens ophalen...',
            export_file_error_ask_for_upload: 'Export mislukt. Wilt u de werkende scène naar het ontwikkelteam sturen voor een bugrapport?',
        },
        image_trace_panel: {
            apply: 'Toepassen',
            back: 'Terug',
            cancel: 'Annuleren',
            next: 'Volgende',
            brightness: 'Helderheid',
            contrast: 'Contrast',
            threshold: 'Drempel',
            okay: 'OK',
            tuning: 'Parameters',
        },
        photo_edit_panel: {
            apply: 'Toepassen',
            back: 'Terug',
            cancel: 'Annuleren',
            next: 'Volgende',
            sharpen: 'Scherpte',
            sharpness: 'Scherpte',
            radius: 'Straal',
            crop: 'Bijsnijden',
            aspect_ratio: 'Beeldverhouding',
            original: 'Origineel',
            free: 'Vrij',
            curve: 'Kromming',
            start: 'Starten',
            processing: 'Verwerken',
            invert: 'Kleuren omkeren',
            okay: 'OK',
            compare: 'Vergelijken',
            phote_edit: 'Foto bewerken',
            brightness_and_contrast: 'Helderheid / Contrast',
            brightness: 'Helderheid',
            contrast: 'Contrast',
        },
        document_panel: {
            document_settings: 'Documentinstellingen',
            engrave_parameters: 'Graveerparameters',
            workarea: 'Werkgebied',
            rotary_mode: 'Roterend',
            borderless_mode: 'Open onderzijde',
            engrave_dpi: 'Resolutie',
            enable_diode: 'Diode laser',
            enable_autofocus: 'Autofocus',
            extend_workarea: 'Werkgebied uitbreiden',
            add_on: 'Add-ons',
            low: 'Laag',
            medium: 'Gemiddeld',
            high: 'Hoog',
            ultra: 'Ultra hoog',
            enable: 'Inschakelen',
            disable: 'Uitschakelen',
            cancel: 'Annuleren',
            save: 'Opslaan',
            notification: {
                changeFromPrintingWorkareaTitle: 'Wilt u de printlagen converteren naar laslagen?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Lettertypen aan het verwerken... Een moment geduld',
            text_to_path: {
                font_substitute_pop: 'Uw tekst bevat tekens die niet worden ondersteund door het huidige lettertype. <br/>Wilt u <strong>%s</strong> gebruiken als vervanging?',
                check_thumbnail_warning: 'Sommige teksten zijn veranderd in andere lettertypen bij het converteren van tekst naar paden en sommige tekens zijn mogelijk niet correct geconverteerd.\nControleer de voorbeeldafbeelding opnieuw voordat u de taak verstuurt.',
                error_when_parsing_text: 'Fout bij het converteren van tekst naar pad',
                use_current_font: 'Huidig lettertype gebruiken',
                retry: 'Probeer het later opnieuw of kies een ander lettertype',
            },
            lock_desc: 'Breedte-hoogteverhouding behouden (SHIFT)',
        },
        tool_panels: {
            cancel: 'Annuleren',
            confirm: 'Bevestigen',
            grid_array: 'Rasterreeks aanmaken',
            array_dimension: 'Rasterafmetingen',
            rows: 'Rijen',
            columns: 'Kolommen',
            array_interval: 'Arrayinterval',
            dx: 'X',
            dy: 'Y',
            offset: 'Verschuiving',
            nest: 'Nestoptimalisatie',
            _offset: {
                direction: 'Verschuivingsrichting',
                inward: 'Naar binnen',
                outward: 'Naar buiten',
                dist: 'Verschuivingsafstand',
                corner_type: 'Hoek',
                sharp: 'Scherp',
                round: 'Rond',
                fail_message: 'Kan objecten niet verplaatsen.',
                not_support_message: 'Geselecteerde elementen bevatten niet-ondersteunde SVG-tag:\nAfbeelding, Groep, Tekst en Geïmporteerd Object.',
            },
            _nest: {
                start_nest: 'Inpakken',
                stop_nest: 'Stoppen',
                end: 'Sluiten',
                spacing: 'Tussenruimte',
                rotations: 'Mogelijke rotaties',
                no_element: 'Er zijn geen elementen om in te pakken.',
            },
        },
        network_testing_panel: {
            network_testing: 'Netwerktest',
            local_ip: 'Lokaal IP-adres:',
            insert_ip: 'Doelapparaat IP-adres:',
            empty_ip: '#818 Voer eerst het doelapparaat IP-adres in.',
            start: 'Start',
            end: 'Einde',
            testing: 'Netwerk testen...',
            invalid_ip: '#818 Ongeldig IP-adres',
            ip_startswith_169: '#843 Machine IP-adres begint met 169.254',
            connection_quality: 'Verbindingskwaliteit',
            average_response: 'Gemiddelde reactietijd',
            test_completed: 'Test voltooid',
            test_fail: 'Test mislukt',
            cannot_connect_1: '#840 Kan geen verbinding maken met doel-IP.',
            cannot_connect_2: '#840 Kan geen verbinding maken met doel-IP. Zorg ervoor dat het doel zich in hetzelfde netwerk bevindt.',
            network_unhealthy: '#841 Verbinding onder 70% of reactietijd boven 100ms',
            device_not_on_list: '#842 Machine staat niet in lijst, maar verbinding boven 70% en reactietijd onder 100ms',
            hint_device_often_on_list: 'Staat de machine vaak niet in de lijst?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Mislukt om verbinding te maken bij versturen van taak?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Time-out bij starten camera voorbeeld?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Ophalen lokaal IP-adres mislukt',
            fail_to_start_network_test: '#817 Kan netwerktest niet starten',
            linux_permission_hint: 'Deze fout komt meestal door onvoldoende rechten.\nVoer alsjeblieft "sudo beam-studio --no-sandbox" uit in terminal om rechten te krijgen en de netwerktest uit te voeren.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Laagkleurconfiguraties',
            color: 'Kleur',
            power: 'Vermogen',
            speed: 'Snelheid',
            repeat: 'Uitvoeren',
            add: 'Toevoegen',
            save: 'Opslaan',
            cancel: 'Annuleren',
            default: 'Terugzetten naar standaard',
            add_config: 'Kleur toevoegen',
            in_use: 'Deze kleur wordt gebruikt.',
            no_input: 'Voer een geldige hex-kleurcode in.',
            sure_to_reset: 'U verliest alle aangepaste parameters, weet u zeker dat u terug wilt naar standaard?',
            sure_to_delete: 'Weet u zeker dat u deze kleurinstelling wilt verwijderen?',
        },
        rating_panel: {
            title: 'Geniet u van Beam Studio?',
            description: 'Als u van Beam Studio houdt, zouden we het zeer op prijs stellen als u ons kunt beoordelen.',
            dont_show_again: 'Niet meer weergeven de volgende keer.',
            thank_you: 'Bedankt!',
        },
        svg_editor: {
            unnsupported_file_type: 'Het bestandstype wordt niet direct ondersteund. Converteer het bestand naar SVG of bitmap',
            unable_to_fetch_clipboard_img: 'Het ophalen van de afbeelding uit het klembord is mislukt',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Afspelen',
            pause: 'Pauzeren',
            stop: 'Stoppen',
            play_speed: 'Afspeelsnelheid',
            travel_path: 'Reispad',
            invert: 'Omkeren',
            preview_info: 'Voorbeeldinformatie',
            size: 'Grootte',
            estimated_time: 'Geschatte totale tijd',
            cut_time: 'Snijtijd',
            rapid_time: 'Reistijd',
            cut_distance: 'Snijafstand',
            rapid_distance: 'Reisafstand',
            current_position: 'Huidige positie',
            remark: '* Alle informatie is een geschatte waarde als referentie.',
            start_here: 'Start hier',
            end_preview: 'Voorbeeld beëindigen',
        },
        shapes_panel: {
            title: 'Elementen',
            basic: 'Basis',
            shape: 'Vorm',
            graphics: 'Grafisch',
            arrow: 'Pijl',
            label: 'Label',
            decor: 'Decoratie',
            circular: 'Cirkelvormig',
            corner: 'Hoek',
            line: 'Lijn',
            photo: 'Foto',
            ribbon: 'Lint',
            speech: 'Spraak',
            text: 'Tekstframe',
            animals: 'Dier',
            birds: 'Vogel',
            land: 'Land',
            sea: 'Zee',
            holidays: 'Feestdag',
            celebration: 'Viering',
            CNY: 'Chinees nieuwjaar',
            easter: 'Pasen',
            halloween: 'Halloween',
            valentines: 'Valentijnsdag',
            Xmas: 'Kerstmis',
            nature: 'Natuur',
            elements: 'Elementen',
            environment: 'Omgeving',
            plants: 'Planten',
            weather: 'Weer',
        },
        announcement_panel: {
            title: 'Aankondiging',
            dont_show_again: 'Niet meer weergeven',
        },
    },
    editor: {
        prespray_area: 'Priming gebied',
        opacity: 'Voorbeeld dekking',
        exposure: 'Voorbeeld belichting',
    },
    flux_id_login: {
        connection_fail: '#847 Verbinding met FLUX-lidservice mislukt.',
        login_success: 'Succesvol ingelogd.',
        login: 'Inloggen',
        unlock_shape_library: 'Log in om de vormendatabase te ontgrendelen.',
        email: 'E-mail',
        password: 'Wachtwoord',
        remember_me: 'Onthouden',
        forget_password: 'Wachtwoord vergeten?',
        register: 'Maak uw FLUX-account',
        offline: 'Offline werken',
        work_offline: 'Offline werken',
        incorrect: 'E-mailadres of wachtwoord is onjuist.',
        not_verified: 'Het e-mailadres is nog niet geverifieerd.',
        new_to_flux: 'Nieuw bij FLUX? Maak een account.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Verken FLUX+ abonnementen',
            thank_you: 'Bedankt voor uw lidmaatschap!',
            ai_credit_tooltip: 'Voor AI-achtergrondverwijdering',
            flux_credit_tooltip: 'Voor ontwerpmarktbestanden en AI-achtergrondverwijdering',
            goto_member_center: 'Ga naar het ledengedeelte',
            access_plus_feature_1: 'U heeft toegang tot een',
            access_plus_feature_2: 'functie.',
            access_plus_feature_note: 'U moet FLUX+ lid zijn om toegang te krijgen tot deze functie.',
            access_monotype_feature: 'U heeft geen Monotype-lettertypen-invoegtoepassing.',
            access_monotype_feature_note: 'U moet FLUX+ Pro-lid zijn of de Monotype-lettertypen-invoegtoepassing hebben om toegang te krijgen tot deze functie.',
            learn_more: 'Meer informatie',
            get_addon: 'Krijg invoegtoepassing',
            subscribe_now: 'Abonneer nu',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI-achtergrondverwijdering',
                my_cloud: 'Onbeperkte cloudopslag',
                boxgen: '3D-doosgenerator',
                dmkt: '1000+ ontwerpbestanden',
                monotype: '250+ premium lettertypen',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Log in om de vormendatabase te ontgrendelen.',
        enjoy_shape_library: 'Geniet van de vormenbibliotheek.',
        shapes: 'Vormen',
        elements: 'Elementen',
        recent: 'Recent',
        search: 'Zoeken',
        clear: 'Wissen',
        export_svg_title: 'Kan geen SVG exporteren',
        export_svg_warning: 'Dit project bevat objecten die beschermd zijn door de intellectuele eigendomswet. Beam Studio zal deze objecten daarom automatisch uitsluiten tijdens de export. U kunt uw project nog steeds opslaan in het Beam Studio Scene (.beam)-formaat om al uw gegevens te behouden. Wilt u nog steeds exporteren?',
        learn_more: 'Meer informatie',
    },
    change_logs: {
        change_log: 'Wijzigingslogboeken:',
        added: 'Toegevoegd:',
        fixed: 'Opgelost:',
        changed: 'Gewijzigd:',
        see_older_version: 'Bekijk oudere versies',
        help_center_url: 'https://support.flux3dp.com/hc/en-US/sections/360000421876',
    },
    select_device: {
        select_device: 'Selecteer apparaat',
        auth_failure: '#811 Verificatie mislukt',
        unable_to_connect: '#810 Kan geen stabiele verbinding maken met machine',
    },
    device: {
        pause: 'Pauzeer',
        paused: 'Gepauzeerd',
        pausing: 'Wordt gepauzeerd',
        select_printer: 'Selecteer printer',
        retry: 'Opnieuw proberen',
        status: 'Status',
        busy: 'Bezig',
        ready: 'Klaar',
        reset: 'Resetten (uitzetten)',
        abort: 'Afbreken',
        start: 'Start',
        please_wait: 'Een ogenblik geduld...',
        quit: 'Afsluiten',
        completing: 'Voltooien',
        aborted: 'Afgebroken',
        completed: 'Voltooid',
        aborting: 'Wordt afgebroken',
        starting: 'Opstarten',
        preparing: 'Voorbereiden',
        resuming: 'Hervatten',
        scanning: 'Scannen',
        occupied: 'Bezet',
        running: 'Werken',
        uploading: 'Uploaden',
        processing: 'Verwerken',
        disconnectedError: {
            caption: 'Machine losgekoppeld',
            message: 'Controleer of het netwerk van %s beschikbaar is',
        },
        unknown: 'Onbekend',
        pausedFromError: 'Gepauzeerd vanwege fout',
        model_name: 'Modelnaam',
        IP: 'IP',
        serial_number: 'Serienummer',
        firmware_version: 'Firmwareversie',
        UUID: 'UUID',
        select: 'Selecteren',
        deviceList: 'Apparaatlijst',
        disable: 'Uitschakelen',
        enable: 'Inschakelen',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Start',
        resume: 'Hervatten',
        start: 'Start',
        pause: 'Pauzeren',
        stop: 'Stoppen',
        record: 'Opnemen',
        camera: 'Camera',
        taskTab: 'Taak',
        connecting: 'Verbinden...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritieke fout: Hoofdprintplaat offline. Neem contact op met FLUX-ondersteuning.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritieke fout: Geen reactie van subsysteem. Neem contact op met FLUX-ondersteuning.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Controleer uw watertank.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Sluit de deur om door te gaan.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Oververhit. Wacht een paar minuten.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Onderkant geopend. Sluit de onderkant om door te gaan.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-as reset mislukt',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Lade geopend',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Vlamsensor afwijkend',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luchtstroomsensor afwijkend',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Geen draaitafelmodule gedetecteerd',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing pull-off fout',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Onjuiste module gedetecteerd. Installeer de juiste module om door te gaan.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Module niet gedetecteerd. Zorg dat de module juist is geïnstalleerd om door te gaan.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Onbekende module gedetecteerd. Installeer de juiste module om door te gaan.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: 'Geen reactie van printermodule.',
        USER_OPERATION_ROTARY_PAUSE: 'Schakel over naar de draaimotor.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Verwijder de cartridge om door te gaan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Onjuiste module gedetecteerd. Installeer de juiste module om door te gaan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Module niet gedetecteerd. Zorg dat de module juist is geïnstalleerd om door te gaan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Onbekende module gedetecteerd. Installeer de juiste module om door te gaan.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Installeer de juiste module om door te gaan.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Plaats de cartridge om door te gaan.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Plaats de juiste cartridge om door te gaan.',
        USER_OPERATION: 'Volg de instructies op het apparaatpaneel om door te gaan.',
        RESOURCE_BUSY: 'De machine is bezet. Herstart de machine als deze niet draait.',
        DEVICE_ERROR: 'Er ging iets mis. Start de machine opnieuw op.',
        NO_RESPONSE: '#905 Fout bij verbinden met moederbord. Start de machine opnieuw op.',
        SUBSYSTEM_ERROR: '#402 Kritieke fout: subsysteem reageert niet. Neem contact op met FLUX-ondersteuning.',
        HARDWARE_FAILURE: 'Er ging iets mis. Start de machine opnieuw op.',
        MAINBOARD_OFFLINE: '#905 Fout bij verbinden met moederbord. Start de machine opnieuw op.',
        bug_report: 'Foutrapport',
        processing: 'Verwerken',
        savingPreview: 'Miniatuurweergaven genereren',
        hour: 'u',
        minute: 'm',
        second: 's',
        left: 'links',
        temperature: 'Temperatuur',
        forceStop: 'Wilt u de huidige taak afbreken?',
        upload: 'Uploaden',
        download: 'Downloaden',
        relocate: 'Verplaatsen',
        cancel: 'Annuleren',
        prepareRelocate: 'Voorbereiden op verplaatsen',
        extensionNotSupported: 'Dit bestandsformaat wordt niet ondersteund',
        fileExistContinue: 'Het bestand bestaat al, wilt u het overschrijven?',
        confirmFileDelete: 'Weet u zeker dat u dit bestand wilt verwijderen?',
        ask_reconnect: 'De verbinding met de machine is verbroken. Wilt u opnieuw verbinden?',
        task: {
            BEAMBOX: 'Laser graveren',
            'N/A': 'Vrije modus',
        },
    },
    alert: {
        caption: 'Fout',
        duplicated_preset_name: 'Dubbele voorinstellingsnaam',
        info: 'INFO',
        warning: 'WAARSCHUWING',
        error: 'FOUT',
        instruction: 'Instructie',
        oops: 'Oeps...',
        retry: 'Opnieuw proberen',
        abort: 'Annuleren',
        confirm: 'Bevestigen',
        cancel: 'Annuleren',
        close: 'Sluiten',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ja',
        no: 'Nee',
        stop: 'Stoppen',
        save: 'Opslaan',
        dont_save: 'Niet opslaan',
        learn_more: 'Meer informatie',
    },
    caption: {
        connectionTimeout: 'Verbinding time-out',
    },
    message: {
        cancelling: 'Annuleren...',
        connecting: 'Verbinden...',
        connectingMachine: 'Verbinden met %s...',
        tryingToConenctMachine: 'Proberen te verbinden met machine...',
        connected: 'Verbonden',
        authenticating: 'Authenticeren...',
        enteringRawMode: 'Raw-modus openen...',
        endingRawMode: 'Raw-modus sluiten...',
        enteringLineCheckMode: 'Lijncontrolemodus openen...',
        endingLineCheckMode: 'Lijncontrolemodus sluiten...',
        exitingRotaryMode: 'Rotatiemodus sluiten...',
        turningOffFan: 'Ventilator uitschakelen...',
        turningOffAirPump: 'Luchtpomp uitschakelen...',
        gettingLaserSpeed: 'Lasersnelheid lezen...',
        settingLaserSpeed: 'Lasersnelheid instellen...',
        retrievingCameraOffset: 'Camera-offset lezen...',
        connectingCamera: 'Camera verbinden...',
        homing: 'Refereren...',
        connectionTimeout: '#805 Apparaat verbinding time-out. Controleer uw netwerkstatus en de Wi-Fi-indicator van uw machine.',
        getProbePosition: 'Probe-positie ophalen...',
        device_not_found: {
            caption: 'Standaardmachine niet gevonden',
            message: '#812 Controleer de Wi-Fi-indicator van uw apparaat',
        },
        device_busy: {
            caption: 'Apparaat bezet',
            message: 'Het apparaat voert een andere taak uit, probeer het later opnieuw. Als het apparaat niet meer werkt, start het dan opnieuw op.',
        },
        device_is_used: 'Het apparaat wordt gebruikt, wilt u de huidige taak annuleren?',
        monitor_too_old: {
            caption: 'Verouderde firmware',
            content: '#814 Installeer de nieuwste firmware met <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">deze handleiding</a>.',
        },
        unknown_error: '#821 De applicatie heeft een onbekende fout gevonden, gebruik Help > Menu > Fout rapporteren.',
        unknown_device: '#826 Kan geen verbinding maken met het apparaat, zorg ervoor dat de USB is aangesloten op het apparaat',
        unsupport_osx_version: 'De huidige macOS-versie %s ondersteunt mogelijk niet alle functies. Werk alsjeblieft bij naar macOS 11+.',
        unsupport_win_version: 'De huidige besturingssysteemversie %s ondersteunt mogelijk niet alle functies. Werk alsjeblieft bij naar de nieuwste versie.',
        need_password: 'Wachtwoord vereist om verbinding te maken met het apparaat',
        unavailableWorkarea: '#804 Huidige werkgebied overschrijdt het werkgebied van dit apparaat. Controleer het werkgebied van het geselecteerde apparaat of stel het werkgebied in via Bewerken > Documentinstellingen.',
        please_enter_dpi: 'Voer de eenheid van uw bestand in (in mm)',
        auth_error: '#820 Auth-fout: Werk Beam Studio en apparaatfirmware bij naar de nieuwste versie.',
        usb_unplugged: 'USB-verbinding verbroken. Controleer uw USB-verbinding',
        uploading_fcode: 'FCode uploaden',
        cant_connect_to_device: '#827 Kan geen verbinding maken met de machine, controleer uw verbinding',
        unable_to_find_machine: 'Kan machine niet vinden',
        disconnected: 'Verbinding instabiel, controleer apparaatverbinding en probeer het later opnieuw',
        unable_to_start: '#830 Kan de taak niet starten. Probeer het opnieuw. Als dit opnieuw gebeurt, neem dan contact met ons op met een bugrapportage:',
        camera: {
            camera_cable_unstable: "Er is gedetecteerd dat de camera foto's instabiel overdraagt. Voorbeeldweergave kan nog steeds normaal worden uitgevoerd, maar er kan sprake zijn van trage voorbeeldweergave of time-out.",
            fail_to_transmit_image: '#845 Er ging iets mis met beeldoverdracht. Start uw machine of Beam Studio opnieuw op. Als deze fout aanhoudt, volg dan <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">deze handleiding</a>.',
            ws_closed_unexpectly: '#844 Verbinding met machinecamera onverwacht verbroken. Als deze fout aanhoudt, volg dan <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">deze handleiding</a>.',
            continue_preview: 'Doorgaan',
            abort_preview: 'Afbreken',
        },
        preview: {
            camera_preview: 'Camera voorbeeldweergave',
            auto_focus: 'Auto scherpstellen',
            auto_focus_instruction: 'Verplaats de lasermodulekop boven het object en volg de animatie-instructies om op AF te drukken om scherp te stellen.',
            already_performed_auto_focus: 'U heeft al automatisch scherpgesteld, bestaande waarden gebruiken?',
            please_enter_height: 'Voer de hoogte van het object in om een nauwkeurige camerafoto te maken.',
            apply: 'Toepassen',
            enter_manually: 'Handmatig invoeren',
            adjust: 'Aanpassen',
            adjust_height_tooltip: 'Schakel het selectievakje in om bewerken mogelijk te maken.',
        },
        unsupported_example_file: 'Het geselecteerde voorbeeldbestand wordt niet ondersteund door het huidige werkgebied.',
        time_remaining: 'Resterende tijd:',
    },
    machine_status: {
        '0': 'Inactief',
        '1': 'Initialiseren',
        '2': 'ST_TRANSFORM',
        '4': 'Opstarten',
        '6': 'Hervatten',
        '16': 'Werkend',
        '18': 'Hervatten',
        '32': 'Gepauzeerd',
        '36': 'Gepauzeerd',
        '38': 'Pauzerend',
        '48': 'Gepauzeerd',
        '50': 'Pauzerend',
        '64': 'Voltooid',
        '66': 'Voltooiend',
        '68': 'Voorbereidend',
        '128': 'Afgebroken',
        '256': 'Alarm',
        '512': 'Fataal',
        '-17': 'Cartridge IO-modus',
        '-10': 'Onderhoudsmodus',
        '-2': 'Scannend',
        '-1': 'Onderhouden',
        UNKNOWN: 'Onbekend',
    },
    calibration: {
        update_firmware_msg1: 'Uw firmware ondersteunt deze functie niet. Werk de firmware bij naar v',
        update_firmware_msg2: 'of hoger om door te gaan。 (Menu > Machine > [Uw machine] > Firmware bijwerken)',
        camera_calibration: 'Camera-calibratie',
        diode_calibration: 'Diode lasermodule-calibratie',
        module_calibration_printer: 'Printermodule-calibratie',
        module_calibration_2w_ir: 'Infraroodmodule-calibratie',
        back: 'TERUG',
        next: 'VOLGENDE',
        skip: 'OVERSLAAN',
        cancel: 'ANNULEREN',
        finish: 'KLAAR',
        do_engraving: 'GRAVEREN UITVOEREN',
        start_engrave: 'GRAVEREN STARTEN',
        start_printing: 'AFDRUKKEN STARTEN',
        ask_for_readjust: 'Wilt u de graveerstap overslaan en dan een foto nemen en rechtstreeks kalibreren?',
        please_goto_beambox_first: 'Schakel over naar de graveermodus (Beambox) om deze functie te gebruiken.',
        please_place_paper: 'Plaats een A4- of briefpapierformaat wit papier linksboven in het werkgebied.',
        please_place_paper_center: 'Plaats een A4- of briefpapierformaat wit papier in het midden van het werkgebied.',
        please_place_dark_colored_paper: 'Plaats een A4- of briefpapierformaat donkergekleurd papier in het midden van het werkgebied.',
        please_refocus: {
            beambox: 'Stel het platform af op de brandpuntsafstand (de hoogte van de omlaag gedraaide acryl).',
            beamo: 'Stel de laserkop af om scherp te stellen op het graveerobject (de hoogte van de omlaag gedraaide acryl).',
            beamo_af: 'Dubbeltik de zijknop van de autofocus-accessoire en laat de sonde het materiaal zachtjes aanraken.',
            hexa: 'Dubbelklik op de hoogteverstelknop om de honingraattabel omhoog te brengen en de sonde het graveermateriaal te laten aanraken.',
        },
        without_af: 'Zonder autofocus',
        with_af: 'Met autofocus',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotatie',
        x_ratio: 'X-verhouding',
        y_ratio: 'Y-verhouding',
        show_last_config: 'Laatste resultaat weergeven',
        use_last_config: 'Laatste kalibratiewaarden gebruiken',
        taking_picture: 'Foto maken...',
        analyze_result_fail: 'Kan vastgelegde afbeelding niet analyseren.<br/>Controleer het volgende:<br/>1. Gefotografeerd papier volledig bedekt met wit papier.<br/>2. Het platform is goed scherpgesteld.',
        drawing_calibration_image: 'Kalibratieafbeelding tekenen...',
        calibrate_done: 'Kalibratie voltooid. Betere cameranauwkeurigheid bij precieze scherpstelling.',
        calibrate_done_diode: 'Kalibratie voltooid. Offset van diode lasermodule is opgeslagen.',
        hint_red_square: 'Lijn de buitenkant van het rode vierkant uit met het gesneden vierkant.',
        hint_adjust_parameters: 'Gebruik deze parameters om het rode vierkant aan te passen',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Wanneer u de diode lasermodule kalibreert, is een camera nodig.\nZorg ervoor dat de camera van deze machine is gekalibreerd.\nEn pas het platform vriendelijk aan op het brandpunt (de hoogte van het omgekeerde acryl)',
            beamo: 'Wanneer u de diode lasermodule kalibreert, is een camera nodig.\nZorg ervoor dat de camera van deze machine is gekalibreerd.\nEn pas de laserkop vriendelijk aan om scherp te stellen op het graveerobject (de hoogte van het omgekeerde acryl)',
        },
        downloading_pictures: "Foto's downloaden...",
        failed_to_download_pictures: "#848 Fout bij downloaden foto's, neem contact op met FLUX Support.",
        uploading_images: 'Afbeeldingen uploaden...',
        calculating_camera_matrix: 'Camera matrix berekenen...',
        calculating_regression_parameters: 'Regressieparameters berekenen...',
        failed_to_calibrate_camera: '#848 Fout bij camera kalibratie, neem contact op met FLUX Support.',
        failed_to_save_calibration_results: '#849 Fout bij opslaan kalibratieresultaten, probeer opnieuw. Neem contact op met FLUX Support als dit blijft gebeuren.',
        ador_autofocus_material: 'Druk 3 seconden op het "AF"-pictogram en laat de sonde het materiaal zachtjes aanraken.',
        ador_autofocus_focusing_block: 'Druk 3 seconden op het "AF"-pictogram en laat de sonde het scherpstelblok aanraken.',
        align_red_cross_cut: 'Lijn het midden van het rode kruis uit met het snijdende kruis.',
        align_red_cross_print: 'Lijn het midden van het rode kruis uit met het geprinte kruis.',
        retake: 'Foto opnieuw nemen',
        calibrate_camera_before_calibrate_modules: 'Voer eerst een camerakalibratie uit voordat u modules kalibreert.',
        check_checkpoint_data: 'Controlepuntgegevens',
        checking_checkpoint: 'Controleer controlepuntgegevens...',
        found_checkpoint: 'Controlepuntgegevens gevonden op uw apparaat. Wilt u herstellen vanuit het controlepunt?',
        use_old_camera_parameter: 'Wilt u de huidige camerobjectiefparameters gebruiken?',
        downloading_checkpoint: 'Downloaden van controlepuntgegevens...',
        failed_to_parse_checkpoint: 'Kan de controlepuntgegevens niet analyseren.',
        check_device_pictures: "Apparaatfoto's controleren",
        checking_pictures: "Apparaatfoto's controleren...",
        no_picutre_found: "#846 Uw apparaat heeft geen beschikbare ruwe foto's voor kalibratie. Neem contact op met FLUX-ondersteuning.",
        calibrating_with_device_pictures: "Kalibreren met apparaatfoto's...",
        failed_to_calibrate_with_pictures: "Kalibratie met apparaatfoto's mislukt.",
        getting_plane_height: 'Hoogte van het vlak ophalen...',
        preparing_to_take_picture: 'Voorbereiden om foto te nemen...',
        put_paper: 'Plaats papier',
        put_paper_step1: 'Plaats alstublieft een A4- of Letter-formaat wit papier in het midden van het werkgebied.',
        put_paper_step2: 'Bevestig de vier hoeken van het papier om ervoor te zorgen dat het plat ligt.',
        put_paper_step3: 'Klik op "GRAVEREN STARTEN".',
        solve_pnp_title: 'Markeringspunten uitlijnen',
        solve_pnp_step1: 'Lijn de graveerpunten uit volgens het nummer en de verwachte positie van elk rood markeerpunt.',
        solve_pnp_step2: 'U kunt op "Foto opnieuw nemen" drukken om opnieuw uit te lijnen of de posities van de markeringen handmatig aan te passen.',
        align_olt: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant linksboven.',
        align_ort: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant rechtsboven.',
        align_olb: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant linksonder.',
        align_orb: 'Positie: Lijn uit met het gegraveerde punt aan de buitenkant rechtsonder.',
        align_ilt: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant linksboven.',
        align_irt: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant rechtsboven.',
        align_ilb: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant linksonder.',
        align_irb: 'Positie: Lijn uit met het gegraveerde punt aan de binnenkant rechtsonder.',
        elevate_and_cut: 'Optillen en Snijden',
        elevate_and_cut_step_1: 'Plaats een A4-formaat lichtgekleurd hout in het midden van de werkruimte en verhoog het tot 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Gebruik Ador Prism Lift met een maximale lengte van 14mm samen met een houten plank van minstens 6mm dikte om het hout tot een hoogte van 20mm te verhogen.',
        with_prism_lift: 'Met Ador Prism Lift',
        without_prism_lift: 'Zonder Ador Prism Lift',
        camera_parameter_saved_successfully: 'Camera parameters succesvol opgeslagen.',
        failed_to_save_camera_parameter: 'Opslaan van camera parameters mislukt.',
        failed_to_solve_pnp: 'Het lukte niet om de camerapositie op te lossen.',
    },
    input_machine_password: {
        require_password: '"%s" vereist een wachtwoord',
        connect: 'VERBINDEN',
        password: 'Wachtwoord',
    },
    tutorial: {
        skip: 'Overslaan',
        welcome: 'WELKOM',
        suggest_calibrate_camera_first: 'We adviseren gebruikers om de camera aanvankelijk te kalibreren en opnieuw scherp te stellen voor elke voorbeeldweergave voor optimale resultaten.<br/>Wilt u bevestigen om nu de kalibratie uit te voeren?<br/>(U kunt het nu overslaan en later uitvoeren door te klikken op "Menu" > "Machine" > [Uw Machine] > "Camera Kalibreren".)',
        camera_calibration_failed: 'Camerakalibratie mislukt',
        ask_retry_calibration: 'Wilt u de camera opnieuw kalibreren?',
        needNewUserTutorial: 'Wilt u een tutorial starten?<br/>(U kunt het nu overslaan en later starten door te klikken op "Help" > "Toon Starttutorial".)',
        needNewInterfaceTutorial: 'Wilt u een tutorial starten voor de nieuwe interface van Beam Studio?<br/>(U kunt het nu overslaan en later starten door te klikken op "Help" > "Toon Interface Introductie".)',
        next: 'VOLGENDE',
        look_for_machine: 'Machine zoeken voor tutorial...',
        unable_to_find_machine: 'Kan geen machine vinden voor tutorial. Wilt u naar de verbindingsinstellingenpagina gaan, de tutorial opnieuw proberen of overslaan?',
        skip_tutorial: 'U heeft de tutorial overgeslagen. U kunt altijd de tutorial starten door te klikken op "Help" > "Tutorial tonen"',
        set_connection: 'Verbinding instellen',
        retry: 'Opnieuw proberen',
        newUser: {
            draw_a_circle: 'Teken een cirkel',
            drag_to_draw: 'Sleep om te tekenen',
            infill: 'Infill inschakelen',
            switch_to_object_panel: 'Schakel over naar Objectenpaneel',
            switch_to_layer_panel: 'Schakel over naar laagvenster',
            set_preset_wood_engraving: 'Stel voorinstelling in: Hout - Graveren',
            set_preset_wood_cut: 'Stel voorinstelling in: Hout - Snijden',
            add_new_layer: 'Voeg een nieuwe laag toe',
            draw_a_rect: 'Teken een rechthoek',
            switch_to_preview_mode: 'Schakel over naar voorbeeldmodus',
            preview_the_platform: 'Bekijk het platform',
            end_preview_mode: 'Voorbeeldmodus beëindigen',
            put_wood: '1. Plaats het houtmonster',
            adjust_focus: '2. Stel de focus in',
            close_cover: '3. Sluit het deksel',
            send_the_file: 'Verstuur het bestand',
            end_alert: 'Weet u zeker dat u de zelfstudie wilt afsluiten?',
            please_select_wood_engraving: 'Selecteer a.u.b. de voorinstelling "Hout - Graveren".',
            please_select_wood_cutting: 'Selecteer a.u.b. de voorinstelling "Hout - Snijden".',
        },
        newInterface: {
            camera_preview: 'Camera voorbeeld',
            select_image_text: 'Selecteer afbeelding / tekst',
            basic_shapes: 'Basisvormen',
            pen_tool: 'Pen tool',
            add_new_layer: 'Voeg nieuwe laag toe',
            rename_by_double_click: 'Hernoem door dubbel te klikken',
            drag_to_sort: 'Sleep om te sorteren',
            layer_controls: 'Rechtsklik voor laagopties:\nDupliceren / Samenvoegen / Vergrendelen / Lagen verwijderen',
            switch_between_layer_panel_and_object_panel: 'Schakel tussen laagpaneel en objectpaneel',
            align_controls: 'Lijn uitlijnen',
            group_controls: 'Groepeer',
            shape_operation: 'Vormbewerking',
            flip: 'Spiegelen',
            object_actions: 'Objectacties',
            end_alert: 'Weet u zeker dat u de nieuwe UI-introductie wilt afsluiten?',
            select_machine: 'Selecteer een machine',
            start_work: 'Begin met werken',
        },
        gesture: {
            pan: 'Scroll het canvas met twee vingers.',
            zoom: 'Knijp met twee vingers in/uit om in/uit te zoomen op het canvas.',
            click: 'Tik om het object te selecteren.',
            drag: 'Sleep om meerdere objecten te selecteren.',
            hold: 'Houd ingedrukt om het contextmenu te openen.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Dat is alles voor de zelfstudie. Nu is het tijd om te creëren!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: '10W Diode Laser',
        laser_20w_diode: '20W Diode Laser',
        laser_2w_infrared: '2W Infraroodlaser',
        printing: 'Printen',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Wilt u de Printmodule converteren naar de Lasermodule?',
            convertFromPrintingModuleMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de kleurinstellingen van de printlaag worden verwijderd en ingesteld volgens de huidige laag.',
            convertFromLaserModuleTitle: 'Wilt u de lasermodule converteren naar de printmodule?',
            convertFromLaserModuleMsg: 'Houd er rekening mee dat als u deze bewerking voltooit, de instellingen van de laserlaag worden verwijderd en ingesteld volgens de huidige laag.',
            importedDocumentContainsPrinting: 'Het document bevat een printlaag, wilt u het werkgebied wijzigen naar printen?',
            printingLayersCoverted: 'De printlagen zijn geconverteerd naar laserlagen.',
            performPrintingCaliTitle: 'Voer de kalibratie van de printmodule uit',
            performPrintingCaliMsg: 'Klik op "Bevestigen" om de kalibratie uit te voeren, of open de kalibratie via het bovenste menu. <br /> (Machine> [Uw machinenaam]> Kalibreer printmodule)',
            performIRCaliTitle: 'Voer de kalibratie van de lasermodule uit',
            performIRCaliMsg: 'Klik op "Bevestigen" om de kalibratie uit te voeren, of open de kalibratie via het bovenste menu. <br /> (Machine> [Uw machinenaam]> Kalibreer lasermodule)',
        },
        non_working_area: 'Niet-Werkgebied',
    },
    qr_code_generator: {
        title: 'QR-codegenerator',
        placeholder: 'Voer een link of tekst in',
        preview: 'Voorbeeld',
        error_tolerance: 'Foutcorrectie',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Achtergrondkleur omkeren',
    },
    boxgen: {
        title: 'DOOSGEN',
        basic_box: 'Basisdoos',
        coming_soon: 'Binnenkort beschikbaar',
        workarea: 'Werkgebied',
        max_dimension_tooltip: 'Max. breedte/hoogte/diepte instelling is %s.',
        volume: 'Volume',
        outer: 'Buiten',
        inner: 'Binnen',
        width: 'Breedte',
        height: 'Hoogte',
        depth: 'Diepte',
        cover: 'Deksel',
        thickness: 'Dikte',
        add_option: 'Optie toevoegen',
        joints: 'Verbinding',
        finger: 'Vinger',
        finger_warning: 'De minimale binnenmaat van de doos moet 6 mm (0,24 inch) zijn om compatibel te zijn met vingerverbindingen.',
        tSlot: 'T-sleuf',
        tSlot_warning: 'De minimale binnenmaat van de doos moet 30 mm (1,18 inch) zijn om compatibel te zijn met T-sleuf verbindingen.',
        edge: 'Rand',
        tCount: 'T Aantal',
        tCount_tooltip: 'Het aantal T-sleuven heeft betrekking op de korte zijde; het aantal aan de lange zijde wordt berekend op basis van de lengte.',
        tDiameter: 'T Diameter',
        tLength: 'T Lengte',
        continue_import: 'Doorgaan met importeren',
        customize: 'Aanpassen',
        merge: 'Samenvoegen',
        text_label: 'Label',
        beam_radius: 'Straalcompensatie',
        beam_radius_warning: 'Verwijder de zaagcompensatie wanneer de randen of verbindingen van de doos kort zijn om de doosassemblage te garanderen',
        import: 'Importeren',
        cancel: 'Annuleren',
        reset: 'Resetten',
        zoom: 'Zoomen',
        control_tooltip: 'Linkermuisknop om te draaien\nScrollen om in te zoomen\nRechtermuisknop om te pannen',
        control_tooltip_touch: 'Slepen om te draaien\nKnijpen om in te zoomen\nTwee vingers om te pannen',
    },
    my_cloud: {
        title: 'Mijn cloud',
        loading_file: 'Bezig met laden...',
        no_file_title: 'Sla bestanden op in Mijn cloud om te beginnen.',
        no_file_subtitle: 'Ga naar Menu > "Bestand" > "Opslaan in cloud"',
        file_limit: 'Gratis bestand',
        upgrade: 'Upgrade',
        sort: {
            most_recent: 'Meest recent',
            oldest: 'Oudste',
            a_to_z: 'Naam: A - Z',
            z_to_a: 'Naam: Z - A',
        },
        action: {
            open: 'Openen',
            rename: 'Hernoemen',
            duplicate: 'Dupliceren',
            download: 'Downloaden',
            delete: 'Verwijderen',
            confirmFileDelete: 'Weet u zeker dat u dit bestand wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.',
        },
        save_file: {
            choose_action: 'Bestand opslaan:',
            save: 'Opslaan',
            save_new: 'Opslaan als nieuw bestand',
            input_file_name: 'Voer bestandsnaam in:',
            invalid_char: 'Ongeldige tekens:',
            storage_limit_exceeded: 'Uw cloudopslag heeft de bovengrens bereikt. Verwijder onnodige bestanden voordat u nieuwe opslaat.',
        },
    },
    camera_data_backup: {
        title: 'Camera gegevens back-up',
        no_picture_found: 'Geen afbeelding gevonden in de machine.',
        folder_not_exists: 'Geselecteerde map bestaat niet.',
        incorrect_folder: 'Mislukt om kalibratiegegevens te uploaden. Controleer of de geselecteerde map correct is.',
        downloading_data: 'Gegevens downloaden...',
        estimated_time_left: 'Geschatte resterende tijd:',
        uploading_data: 'Gegevens uploaden...',
        download_success: 'Camera-gegevens succesvol gedownload.',
        upload_success: 'Camera-gegevens succesvol geüpload.',
    },
    insecure_websocket: {
        extension_detected: 'Beam Studio Connect-extensie gedetecteerd',
        extension_detected_description: "We hebben gedetecteerd dat u de Beam Studio Connect-extensie heeft geïnstalleerd. Klik op 'Bevestigen' om door te verwijzen naar HTTPS, of klik op 'Annuleren' om HTTP te blijven gebruiken.",
        extension_not_deteced: 'Kan Beam Studio Connect-extensie niet detecteren',
        extension_not_deteced_description: "Om HTTPS te gebruiken, klik op 'Bevestigen' om de Beam Studio Connect-extensie te installeren. Nadat u de extensie hebt geïnstalleerd, ververs de pagina om deze te activeren.<br/>Anders klikt u op de onderstaande link om te zien hoe u HTTP op Chrome kunt gebruiken.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Meet autofocusgebied',
        amount: 'Hoeveelheid',
        gap: 'Opening',
        rows: 'Rijen',
        coloumns: 'Kolommen',
        row_gap: 'Rijafstand',
        column_gap: 'Kolomafstand',
        set_object_height: 'Stel objecthoogte in',
        set_object_height_desc: 'Meet de maximale dikte van het object.',
        measure_area_help: 'Hoe selecteer je het autofocusgebied voor 3D-curve?',
        reselect_area: 'Selecteer gebied opnieuw',
        start_autofocus: 'Start autofocus',
        starting_measurement: 'Meten starten...',
        preview_3d_curve: 'Voorbeeld van 3D-curve',
        apply_arkwork: 'Kunstwerk op 3D-curve toepassen',
        apply_camera: 'Camerabeeld op 3D-curve toepassen',
        click_to_select_point: 'Klik om punten te selecteren of te deselecteren voor opnieuw meten.',
        remeasure: 'Opnieuw meten',
        remeasuring_points: 'Punten opnieuw meten...',
    },
    pass_through: {
        title: 'Doorgangsmodus',
    },
};
export default lang;
