import classNames from 'classnames';
import React, { memo, useContext } from 'react';
import { Button, Popover } from 'antd-mobile';
import history from 'app/svgedit/history/history';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import objectPanelItemStyles from 'app/views/beambox/Right-Panels/ObjectPanelItem.module.scss';
import useI18n from 'helpers/useI18n';
import { CUSTOM_PRESET_CONSTANT, DataType, writeData } from 'helpers/layer/layer-config-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { ObjectPanelContext } from 'app/views/beambox/Right-Panels/contexts/ObjectPanelContext';
import ConfigPanelContext from './ConfigPanelContext';
import ConfigSlider from './ConfigSlider';
import ConfigValueDisplay from './ConfigValueDisplay';
import styles from './Block.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const MAX_VALUE = 100;
const MIN_VALUE = 1;
function PowerBlock({ type = 'default', }) {
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel;
    const { selectedLayers, state, dispatch, initState } = useContext(ConfigPanelContext);
    const { activeKey } = useContext(ObjectPanelContext);
    const visible = activeKey === 'power';
    const { power } = state;
    const handleChange = (value) => {
        dispatch({
            type: 'change',
            payload: { power: value, configName: CUSTOM_PRESET_CONSTANT },
        });
        if (type !== 'modal') {
            const batchCmd = new history.BatchCommand('Change power');
            selectedLayers.forEach((layerName) => {
                writeData(layerName, DataType.strength, value, { batchCmd });
                writeData(layerName, DataType.configName, CUSTOM_PRESET_CONSTANT, { batchCmd });
            });
            batchCmd.onAfter = initState;
            svgCanvas.addCommandToHistory(batchCmd);
        }
    };
    const content = (React.createElement("div", { className: classNames(styles.panel, styles[type]) },
        React.createElement("span", { className: styles.title }, t.strength),
        React.createElement(ConfigValueDisplay, { inputId: "power-input", type: type, max: MAX_VALUE, min: MIN_VALUE, value: power.value, unit: "%", hasMultiValue: power.hasMultiValue, decimal: 1, onChange: handleChange }),
        React.createElement(ConfigSlider, { id: "power_value", value: power.value, onChange: handleChange, min: MIN_VALUE, max: MAX_VALUE, step: 1 }),
        power.value < 10 && (React.createElement("div", { className: styles.warning },
            React.createElement("div", { className: styles['warning-icon'] }, "!"),
            React.createElement("div", { className: styles['warning-text'] }, t.low_power_warning)))));
    return type === 'panel-item' ? (React.createElement(Popover, { visible: visible, content: content },
        React.createElement(ObjectPanelItem.Item, { id: "power", content: React.createElement(Button, { className: objectPanelItemStyles['number-item'], shape: "rounded", size: "mini", fill: "outline" }, power.value), label: t.strength, autoClose: false }))) : (content);
}
export default memo(PowerBlock);
