// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-ImageEditPanel-CropPanel-module__top-buttons--SGggv{display:flex;justify-content:space-between;margin-bottom:8px}.src-web-app-views-beambox-ImageEditPanel-CropPanel-module__field--hKTWi{display:flex;justify-content:space-between;align-items:center;column-gap:12px;margin-top:8px}.src-web-app-views-beambox-ImageEditPanel-CropPanel-module__field--hKTWi .src-web-app-views-beambox-ImageEditPanel-CropPanel-module__label--rxk5H{white-space:nowrap;font-weight:bold}.src-web-app-views-beambox-ImageEditPanel-CropPanel-module__field--hKTWi .src-web-app-views-beambox-ImageEditPanel-CropPanel-module__select--Nf\\+Ce{width:100%;border-radius:50px}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/ImageEditPanel/CropPanel.module.scss"],"names":[],"mappings":"AAAA,+EACE,YAAA,CACA,6BAAA,CACA,iBAAA,CAEF,yEACE,YAAA,CACA,6BAAA,CACA,kBAAA,CACA,eAAA,CACA,cAAA,CACA,kJACE,kBAAA,CACA,gBAAA,CAEF,oJACE,UAAA,CACA,kBAAA","sourcesContent":[".top-buttons {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 8px;\n}\n.field {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 12px;\n  margin-top: 8px;\n  .label {\n    white-space: nowrap;\n    font-weight: bold;\n  }\n  .select {\n    width: 100%;\n    border-radius: 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-buttons": "src-web-app-views-beambox-ImageEditPanel-CropPanel-module__top-buttons--SGggv",
	"field": "src-web-app-views-beambox-ImageEditPanel-CropPanel-module__field--hKTWi",
	"label": "src-web-app-views-beambox-ImageEditPanel-CropPanel-module__label--rxk5H",
	"select": "src-web-app-views-beambox-ImageEditPanel-CropPanel-module__select--Nf+Ce"
};
export default ___CSS_LOADER_EXPORT___;
