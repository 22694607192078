import React, { useContext, useMemo } from 'react';
import { Modal, Tabs } from 'antd';
import { CameraOutlined, FolderOutlined, PictureOutlined } from '@ant-design/icons';
import deviceConstants from 'app/constants/device-constants';
import MonitorStatus from 'helpers/monitor-status';
import useI18n from 'helpers/useI18n';
import { Mode } from 'app/constants/monitor-constants';
import { MonitorContext } from 'app/contexts/MonitorContext';
import MonitorCamera from './MonitorCamera';
import MonitorFilelist from './MonitorFilelist';
// import MonitorRelocate from './MonitorRelocate';
import MonitorTabExtraContent from './MonitorTabExtraContent';
import MonitorTask from './MonitorTask';
const Monitor = (props) => {
    const { device } = props;
    const { currentPath, mode, onClose, report, setMonitorMode, taskImageURL, uploadProgress } = useContext(MonitorContext);
    const LANG = useI18n();
    const taskMode = report.st_id === deviceConstants.status.IDLE ? Mode.PREVIEW : Mode.WORKING;
    const monitorMode = [Mode.PREVIEW, Mode.FILE_PREVIEW, Mode.WORKING].includes(mode)
        ? taskMode
        : mode;
    // const renderRelocate = (): JSX.Element => {
    //   return (
    //     <MonitorRelocate
    //       device={device}
    //     />
    //   );
    // };
    const tabItems = [
        {
            label: (React.createElement("div", null,
                React.createElement(FolderOutlined, null),
                LANG.topmenu.file.label)),
            key: Mode.FILE,
            children: React.createElement(MonitorFilelist, { path: currentPath.join('/') }),
        },
        {
            label: (React.createElement("div", null,
                React.createElement(CameraOutlined, null),
                LANG.monitor.camera)),
            key: Mode.CAMERA,
            children: React.createElement(MonitorCamera, { device: device }),
        },
    ];
    if (taskImageURL) {
        tabItems.unshift({
            label: (React.createElement("div", null,
                React.createElement(PictureOutlined, null),
                LANG.monitor.taskTab)),
            key: taskMode,
            children: (React.createElement("div", null,
                React.createElement(MonitorTask, null))),
        });
    }
    const statusText = useMemo(() => {
        if (uploadProgress)
            return LANG.beambox.popup.progress.uploading;
        if (report)
            return MonitorStatus.getDisplayStatus(report.st_label);
        return LANG.monitor.connecting;
    }, [LANG, report, uploadProgress]);
    return (React.createElement(Modal, { open: true, centered: true, onCancel: onClose, title: `${device.name} - ${statusText}`, footer: null },
        React.createElement(Tabs, { activeKey: monitorMode, items: tabItems, onChange: (key) => setMonitorMode(key), tabBarExtraContent: React.createElement(MonitorTabExtraContent, null) })));
};
export default Monitor;
