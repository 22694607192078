var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef, useCallback, useRef, useImperativeHandle } from 'react';
import { ConfigProvider, InputNumber } from 'antd';
import styles from './UnitInput.module.scss';
// TODO: add test
/**
 * Unit Input by Antd InputNumber
 * using formatter and parser to display unit
 * if isInch is true, the unit will be inch but the value will still be mm,
 * the transfer will be handled by formatter and parser
 */
const UnitInput = forwardRef((_a, outerRef) => {
    var { unit, isInch, onBlur, onChange, theme, precision = 4 } = _a, props = __rest(_a, ["unit", "isInch", "onBlur", "onChange", "theme", "precision"]);
    const inputRef = useRef(null);
    useImperativeHandle(outerRef, () => inputRef.current, []);
    const formatter = useCallback((value) => {
        let newVal = typeof value === 'string' ? parseFloat(value) : value;
        if (isInch)
            newVal /= 25.4;
        const res = String(Math.floor(newVal * Math.pow(10, precision)) / Math.pow(10, precision));
        return res;
    }, [isInch, precision]);
    const parser = useCallback((value) => {
        const newVal = value.trim();
        if (isInch)
            return parseFloat(newVal) * 25.4;
        return parseFloat(newVal);
    }, [isInch]);
    const handlePressEnter = useCallback(() => {
        var _a;
        const value = parser((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value);
        if (!Number.isNaN(value))
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
    }, [parser, onChange]);
    const handleBlur = useCallback((e) => {
        var _a;
        const value = parser((_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.value);
        if (!Number.isNaN(value))
            onChange === null || onChange === void 0 ? void 0 : onChange(value);
        onBlur === null || onBlur === void 0 ? void 0 : onBlur(e);
    }, [parser, onBlur, onChange]);
    return (React.createElement("div", { className: styles.input },
        React.createElement(ConfigProvider, { theme: theme },
            React.createElement(InputNumber, Object.assign({ ref: inputRef, onPressEnter: handlePressEnter }, props, { onBlur: handleBlur, onChange: onChange, formatter: formatter, parser: parser })),
            React.createElement("span", { className: styles.unit }, unit))));
});
export default UnitInput;
