import React, { useContext, useMemo } from 'react';
import { Button, Modal, Space } from 'antd';
import browser from 'implementations/browser';
import constant from 'app/actions/beambox/constant';
import FloatingPanel from 'app/widgets/FloatingPanel';
import FluxIcons from 'app/icons/flux/FluxIcons';
import isFluxPlusActive from 'helpers/is-flux-plus-active';
import useI18n from 'helpers/useI18n';
import { getCurrentUser } from 'helpers/api/flux-id';
import { MyCloudContext, MyCloudProvider } from 'app/contexts/MyCloudContext';
import { useIsMobile } from 'helpers/system-helper';
import GridFile from './GridFile';
import Head from './Head';
import styles from './MyCloud.module.scss';
const MyCloudModal = () => {
    var _a, _b, _c, _d;
    const user = getCurrentUser();
    const LANG = useI18n();
    const lang = LANG.my_cloud;
    const isMobile = useIsMobile();
    const { onClose, files, setSelectedId } = useContext(MyCloudContext);
    const anchors = [0, window.innerHeight - constant.menuberHeight];
    const content = useMemo(() => {
        if (files === undefined)
            return React.createElement("div", { className: styles.placeholder }, lang.loading_file);
        if (files.length === 0)
            return (React.createElement("div", { className: styles.placeholder },
                React.createElement("div", null, lang.no_file_title),
                React.createElement("div", null, lang.no_file_subtitle)));
        return (React.createElement("div", { className: styles.grids, onClick: () => setSelectedId(null) }, files.map((file) => (React.createElement(GridFile, { key: file.uuid, file: file })))));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);
    const title = (React.createElement(Space, { className: styles['title-container'], size: "middle", align: "center", direction: isMobile ? 'vertical' : 'horizontal' },
        React.createElement("div", { className: styles.title },
            ((_b = (_a = user.info) === null || _a === void 0 ? void 0 : _a.subscription) === null || _b === void 0 ? void 0 : _b.is_valid) && React.createElement(FluxIcons.FluxPlus, null),
            lang.title),
        !((_d = (_c = user.info) === null || _c === void 0 ? void 0 : _c.subscription) === null || _d === void 0 ? void 0 : _d.is_valid) && (React.createElement("div", { className: styles.limit },
            React.createElement("div", { className: styles.tag },
                lang.file_limit,
                " ",
                files ? files.length : '?',
                "/5"),
            isFluxPlusActive && (React.createElement(Button, { type: "link", size: "small", onClick: () => browser.open(LANG.flux_id_login.flux_plus.website_url) }, lang.upgrade))))));
    return isMobile ? (React.createElement(FloatingPanel, { className: styles.panel, anchors: anchors, title: title, fixedContent: React.createElement(Head, null), onClose: onClose }, content)) : (React.createElement(Modal, { title: title, footer: null, width: 720, onCancel: onClose, centered: true, open: true },
        React.createElement(Head, null),
        content));
};
const MyCloud = ({ onClose }) => (React.createElement(MyCloudProvider, { onClose: onClose },
    React.createElement(MyCloudModal, null)));
export default MyCloud;
