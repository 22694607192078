import * as React from 'react';
import classNames from 'classnames';
import Modal from './Modal';
class ModalWithHole extends React.PureComponent {
    componentDidMount() {
        window.addEventListener('resize', () => this.handleResizeWindow());
    }
    componentWillUnmount() {
        window.removeEventListener('resize', () => this.handleResizeWindow());
    }
    handleResizeWindow() {
        this.setState({});
    }
    render() {
        const { className, holePosition, holeSize, children, } = this.props;
        if (!holePosition) {
            return (React.createElement(Modal, { className: { 'with-hole': true } }, children));
        }
        let { width, height } = holeSize;
        if (!height) {
            height = window.innerHeight - (holePosition.top || 0 + holePosition.bottom || 0);
        }
        if (!width) {
            width = window.innerWidth - (holePosition.left || 0 + holePosition.right || 0);
        }
        const bottom = (holePosition.top !== undefined) ? `calc(100% - ${holePosition.top}px)` : (holePosition.bottom + height);
        const top = (holePosition.bottom !== undefined) ? `calc(100% - ${holePosition.bottom}px)` : (holePosition.top + height);
        const right = (holePosition.left !== undefined) ? `calc(100% - ${holePosition.left}px)` : (holePosition.right + width);
        const left = (holePosition.right !== undefined) ? `calc(100% - ${holePosition.right}px)` : (holePosition.left + width);
        return (React.createElement("div", { className: classNames('modal-window', 'with-hole', className) },
            React.createElement("div", { className: "modal-background", style: { top } }),
            React.createElement("div", { className: "modal-background", style: { bottom } }),
            React.createElement("div", { className: "modal-background", style: { left } }),
            React.createElement("div", { className: "modal-background", style: { right } }),
            React.createElement("div", { className: "modal-body" }, children)));
    }
}
export default ModalWithHole;
