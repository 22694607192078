import React from 'react';
import { ContextMenu, ContextMenuTrigger, MenuItem, SubMenu } from 'helpers/react-contextmenu';
import clipboard from 'app/svgedit/operations/clipboard';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import i18n from 'helpers/i18n';
import svgEditor from 'app/actions/beambox/svg-editor';
import { getObjectLayer, moveToOtherLayer } from 'helpers/layer/layer-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { LayerPanelContext } from 'app/views/beambox/Right-Panels/contexts/LayerPanelContext';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const eventEmitter = eventEmitterFactory.createEventEmitter('workarea');
const getCurrentLayer = (selectedElement) => {
    if (!selectedElement)
        return null;
    if (selectedElement.getAttribute('data-tempgroup') === 'true') {
        const originalLayers = new Set([...selectedElement.childNodes]
            .filter((elem) => (elem === null || elem === void 0 ? void 0 : elem.getAttribute('data-imageborder')) !== 'true')
            .map((elem) => elem.getAttribute('data-original-layer')));
        if (originalLayers.size === 1) {
            const [firstValue] = originalLayers;
            return firstValue;
        }
    }
    else {
        const currentLayer = getObjectLayer(selectedElement);
        return currentLayer === null || currentLayer === void 0 ? void 0 : currentLayer.title;
    }
    return null;
};
export default class Workarea extends React.PureComponent {
    constructor(props) {
        super(props);
        this.updateContextMenu = (newValues) => {
            this.setState((prev) => (Object.assign(Object.assign({}, prev), newValues)));
        };
        this.renderLayerSubMenu = () => {
            const { select } = this.state;
            const drawing = svgCanvas === null || svgCanvas === void 0 ? void 0 : svgCanvas.getCurrentDrawing();
            const layerNames = (drawing === null || drawing === void 0 ? void 0 : drawing.all_layers.map(
            // eslint-disable-next-line no-underscore-dangle
            (layer) => layer.name_)) || [];
            const selectedElems = svgCanvas === null || svgCanvas === void 0 ? void 0 : svgCanvas.getSelectedElems();
            const currentLayer = getCurrentLayer(selectedElems === null || selectedElems === void 0 ? void 0 : selectedElems[0]);
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { className: "seperator" }),
                React.createElement(SubMenu, { disabled: !select, title: i18n.lang.beambox.right_panel.layer_panel.move_elems_to }, layerNames.map((layerName) => (React.createElement(MenuItem, { key: layerName, disabled: layerName === currentLayer, onClick: () => moveToOtherLayer(layerName, () => { }, false) }, layerName))))));
        };
        this.state = {
            menuDisabled: false,
            select: false,
            paste: false,
            group: false,
            ungroup: false,
        };
    }
    componentDidMount() {
        eventEmitter.on('update-context-menu', this.updateContextMenu);
    }
    componentWillUnmount() {
        eventEmitter.removeListener('update-context-menu', this.updateContextMenu);
    }
    render() {
        const LANG = i18n.lang.beambox.context_menu;
        const { className } = this.props;
        const { menuDisabled, select, paste, group, ungroup } = this.state;
        const isTouchable = navigator.maxTouchPoints >= 1;
        return (React.createElement(React.Fragment, null,
            React.createElement(ContextMenuTrigger, { id: "canvas-contextmenu", holdToDisplay: isTouchable ? 1000 : -1, disable: menuDisabled },
                React.createElement("div", { id: "workarea", className: className },
                    React.createElement("div", { id: "svgcanvas", style: {
                            position: 'relative',
                        } }))),
            React.createElement(ContextMenu, { id: "canvas-contextmenu" },
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgEditor.cutSelected() }, LANG.cut),
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgEditor.copySelected() }, LANG.copy),
                React.createElement(MenuItem, { disabled: !paste, onClick: () => clipboard.pasteElements('mouse') }, LANG.paste),
                React.createElement(MenuItem, { disabled: !paste, onClick: () => clipboard.pasteElements('in_place') }, LANG.paste_in_place),
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgCanvas.cloneSelectedElements(20, 20) }, LANG.duplicate),
                React.createElement("div", { className: "seperator" }),
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgEditor.deleteSelected() }, LANG.delete),
                React.createElement("div", { className: "seperator" }),
                React.createElement(MenuItem, { disabled: !select || !group, onClick: () => svgCanvas.groupSelectedElements() }, LANG.group),
                React.createElement(MenuItem, { disabled: !select || !ungroup, onClick: () => svgCanvas.ungroupSelectedElement() }, LANG.ungroup),
                React.createElement("div", { className: "seperator" }),
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgCanvas.moveTopBottomSelected('top') }, LANG.move_front),
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgCanvas.moveUpSelectedElement() }, LANG.move_up),
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgCanvas.moveDownSelectedElement() }, LANG.move_down),
                React.createElement(MenuItem, { disabled: !select, onClick: () => svgCanvas.moveTopBottomSelected('bottom') }, LANG.move_back),
                this.renderLayerSubMenu())));
    }
}
// Note: Keep context to update current layer(trigger rerender) when moving a single element
Workarea.contextType = LayerPanelContext;
