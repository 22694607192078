var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React from 'react';
import { Button, ConfigProvider } from 'antd';
import ActionPanelIcons from 'app/icons/action-panel/ActionPanelIcons';
import Dialog from 'app/actions/dialog-caller';
import dialog from 'implementations/dialog';
import FontFuncs from 'app/actions/beambox/font-funcs';
import i18n from 'helpers/i18n';
import imageEdit from 'helpers/image-edit';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import textActions from 'app/svgedit/text/textactions';
import textEdit from 'app/svgedit/text/textedit';
import textPathEdit from 'app/actions/beambox/textPathEdit';
import updateElementColor from 'helpers/color/updateElementColor';
import webNeedConnectionWrapper from 'helpers/web-need-connection-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { isMobile } from 'helpers/system-helper';
import { textButtonTheme } from 'app/constants/antd-config';
import styles from './ActionsPanel.module.scss';
let svgCanvas;
let svgEditor;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
    svgEditor = globalSVG.Editor;
});
const LANG = i18n.lang.beambox.right_panel.object_panel.actions_panel;
class ActionsPanel extends React.Component {
    constructor() {
        super(...arguments);
        this.replaceImage = () => __awaiter(this, void 0, void 0, function* () {
            setTimeout(() => ObjectPanelController.updateActiveKey(null), 300);
            const { elem } = this.props;
            const option = {
                filters: [
                    {
                        name: 'Images',
                        extensions: [
                            'png',
                            'jpg',
                            'jpeg',
                            'jpe',
                            'jif',
                            'jfif',
                            'jfi',
                            'bmp',
                            'jp2',
                            'j2k',
                            'jpf',
                            'jpx',
                            'jpm',
                        ],
                    },
                ],
            };
            const fileBlob = yield dialog.getFileFromDialog(option);
            if (fileBlob) {
                svgEditor.replaceBitmap(fileBlob, elem);
            }
        });
        this.convertTextToPath = () => __awaiter(this, void 0, void 0, function* () {
            const { elem } = this.props;
            const isTextPath = elem.getAttribute('data-textpath-g');
            const textElem = isTextPath ? elem.querySelector('text') : elem;
            if (textActions.isEditing) {
                textActions.toSelectMode();
            }
            svgCanvas.clearSelection();
            yield FontFuncs.convertTextToPath(textElem);
        });
        this.weldText = () => __awaiter(this, void 0, void 0, function* () {
            const { elem } = this.props;
            const isTextPath = elem.getAttribute('data-textpath-g');
            const textElem = isTextPath ? elem.querySelector('text') : elem;
            if (textActions.isEditing) {
                textActions.toSelectMode();
            }
            svgCanvas.clearSelection();
            yield FontFuncs.convertTextToPath(textElem, { weldingTexts: true });
        });
        this.renderButtons = (id, label, onClick, icon, mobileIcon, opts = {}) => {
            const { isFullLine, isDisabled, autoClose, mobileLabel } = opts;
            return isMobile() ? (React.createElement(ObjectPanelItem.Item, { key: mobileLabel || label, id: id, content: mobileIcon, label: mobileLabel || label, onClick: onClick, disabled: isDisabled, autoClose: autoClose })) : (React.createElement("div", { className: classNames(styles['btn-container'], { [styles.half]: !isFullLine }), key: label },
                React.createElement(Button, { className: styles.btn, id: id, icon: icon, onClick: onClick, disabled: isDisabled, block: true },
                    React.createElement("span", { className: styles.label }, label))));
        };
        this.renderArrayButton = (opts = {}) => this.renderButtons('array', LANG.array, () => svgEditor.triggerGridTool(), React.createElement(ActionPanelIcons.Array, null), React.createElement(ActionPanelIcons.ArrayMobile, null), Object.assign({ autoClose: false }, opts));
        this.renderOffsetButton = (opts = {}) => this.renderButtons('offset', LANG.offset, () => svgEditor.triggerOffsetTool(), React.createElement(ActionPanelIcons.Offset, null), React.createElement(ActionPanelIcons.Offset, null), Object.assign({ autoClose: false }, opts));
        this.renderAutoArrangeButton = (opts = {}) => this.renderButtons('auto_arrange', LANG.auto_arrange, () => Dialog.showSvgNestButtons(), React.createElement(ActionPanelIcons.AutoArrange, null), React.createElement(ActionPanelIcons.AutoArrange, null), Object.assign({ isFullLine: true }, opts));
        this.renderImageActions = () => {
            const { elem } = this.props;
            const isShading = elem.getAttribute('data-shading') === 'true';
            const content = {
                autoArrange: this.renderAutoArrangeButton(),
                replace_with: this.renderButtons('replace_with', LANG.replace_with, this.replaceImage, React.createElement(ActionPanelIcons.Replace, null), React.createElement(ActionPanelIcons.ReplaceMobile, null), { isFullLine: true, autoClose: false, mobileLabel: LANG.replace_with_short }),
                'bg-removal': this.renderButtons('bg-removal', LANG.ai_bg_removal, () => imageEdit.removeBackground(elem), React.createElement(ActionPanelIcons.BackgroungRemoval, null), React.createElement(ActionPanelIcons.BackgroungRemovalMobile, null), { isFullLine: true, mobileLabel: LANG.ai_bg_removal_short }),
                trace: this.renderButtons('trace', LANG.trace, () => imageEdit.traceImage(elem), React.createElement(ActionPanelIcons.Trace, null), React.createElement(ActionPanelIcons.Trace, null), { isDisabled: isShading }),
                grading: this.renderButtons('grading', LANG.grading, () => Dialog.showPhotoEditPanel('curve'), React.createElement(ActionPanelIcons.Grading, null), React.createElement(ActionPanelIcons.Brightness, null), { autoClose: false, mobileLabel: LANG.brightness }),
                sharpen: this.renderButtons('sharpen', LANG.sharpen, () => {
                    webNeedConnectionWrapper(() => Dialog.showPhotoEditPanel('sharpen'));
                }, React.createElement(ActionPanelIcons.Sharpen, null), React.createElement(ActionPanelIcons.SharpenMobile, null), { autoClose: false }),
                crop: this.renderButtons('crop', LANG.crop, () => Dialog.showCropPanel(), React.createElement(ActionPanelIcons.Crop, null), React.createElement(ActionPanelIcons.Crop, null), { autoClose: false }),
                bevel: this.renderButtons('bevel', LANG.bevel, () => imageEdit.generateStampBevel(elem), React.createElement(ActionPanelIcons.Bevel, null), React.createElement(ActionPanelIcons.BevelMobile, null)),
                invert: this.renderButtons('invert', LANG.invert, () => imageEdit.colorInvert(elem), React.createElement(ActionPanelIcons.Invert, null), React.createElement(ActionPanelIcons.Invert, null)),
                array: this.renderArrayButton(),
                potrace: this.renderButtons('potrace', LANG.outline, () => imageEdit.potrace(elem), React.createElement(ActionPanelIcons.Outline, null), React.createElement(ActionPanelIcons.Outline, null)),
            };
            const contentOrder = isMobile()
                ? [
                    'replace_with',
                    'potrace',
                    'grading',
                    'sharpen',
                    'crop',
                    'bevel',
                    'invert',
                    'array',
                    'trace',
                    'bg-removal',
                    'autoArrange',
                ]
                : [
                    'replace_with',
                    'bg-removal',
                    'autoArrange',
                    'trace',
                    'grading',
                    'sharpen',
                    'crop',
                    'bevel',
                    'invert',
                    'array',
                    'potrace',
                ];
            const contentInOrder = contentOrder.map((key) => content[key]);
            return contentInOrder;
        };
        this.renderTextActions = () => {
            const content = [
                this.renderButtons('convert_to_path', LANG.convert_to_path, this.convertTextToPath, React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), {
                    isFullLine: true,
                    mobileLabel: LANG.outline,
                }),
                this.renderButtons('weld', LANG.weld_text, this.weldText, React.createElement(ActionPanelIcons.WeldText, null), React.createElement(ActionPanelIcons.WeldText, null), {
                    isFullLine: true,
                }),
                this.renderAutoArrangeButton(),
                this.renderArrayButton({ isFullLine: true }),
            ];
            return content;
        };
        this.renderTextPathActions = () => {
            const { elem } = this.props;
            const content = [
                this.renderButtons('edit_path', LANG.edit_path, () => textPathEdit.editPath(elem), React.createElement(ActionPanelIcons.EditPath, null), React.createElement(ActionPanelIcons.EditPathMobile, null), { isFullLine: true }),
                this.renderButtons('detach_path', LANG.detach_path, () => {
                    const { text, path } = textPathEdit.detachText(elem);
                    textEdit.renderText(text);
                    svgCanvas.multiSelect([text, path], true);
                }, React.createElement(ActionPanelIcons.DecomposeTextpath, null), React.createElement(ActionPanelIcons.DecomposeTextpath, null), { isFullLine: true, mobileLabel: LANG.detach_path_short }),
                this.renderButtons('convert_to_path', LANG.convert_to_path, this.convertTextToPath, React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: LANG.outline }),
                this.renderAutoArrangeButton(),
                this.renderArrayButton({ isFullLine: true }),
            ];
            return content;
        };
        this.renderPathActions = () => {
            const { elem } = this.props;
            const content = [
                this.renderButtons('edit_path', LANG.edit_path, () => svgCanvas.pathActions.toEditMode(elem), React.createElement(ActionPanelIcons.EditPath, null), React.createElement(ActionPanelIcons.EditPathMobile, null), { isFullLine: true }),
                this.renderButtons('decompose_path', LANG.decompose_path, () => svgCanvas.decomposePath(), React.createElement(ActionPanelIcons.Decompose, null), React.createElement(ActionPanelIcons.DecomposeMobile, null), { isFullLine: true }),
                this.renderAutoArrangeButton(),
                this.renderOffsetButton(),
                this.renderArrayButton(),
                this.renderButtons('simplify', LANG.simplify, () => svgCanvas.simplifyPath(), React.createElement(ActionPanelIcons.Simplify, null), React.createElement(ActionPanelIcons.SimplifyMobile, null), { isFullLine: true }),
            ];
            return content;
        };
        this.renderRectActions = () => {
            const { elem } = this.props;
            const content = [
                this.renderButtons('convert_to_path', LANG.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: LANG.outline }),
                this.renderAutoArrangeButton(),
                this.renderOffsetButton(),
                this.renderArrayButton(),
            ];
            return content;
        };
        this.renderEllipseActions = () => {
            const { elem } = this.props;
            const content = [
                this.renderButtons('convert_to_path', LANG.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: LANG.outline }),
                this.renderAutoArrangeButton(),
                this.renderOffsetButton(),
                this.renderArrayButton(),
            ];
            return content;
        };
        this.renderPolygonActions = () => {
            const { elem } = this.props;
            const content = [
                this.renderButtons('convert_to_path', LANG.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: LANG.outline }),
                this.renderAutoArrangeButton(),
                this.renderOffsetButton(),
                this.renderArrayButton(),
            ];
            return content;
        };
        this.renderLineActions = () => {
            const { elem } = this.props;
            const content = [
                this.renderButtons('convert_to_path', LANG.convert_to_path, () => svgCanvas.convertToPath(elem), React.createElement(ActionPanelIcons.ConvertToPath, null), React.createElement(ActionPanelIcons.ConvertToPathMobile, null), { isFullLine: true, mobileLabel: LANG.outline }),
                this.renderAutoArrangeButton(),
                this.renderOffsetButton(),
                this.renderArrayButton(),
            ];
            return content;
        };
        this.renderUseActions = () => {
            const content = [
                this.renderButtons('disassemble_use', LANG.disassemble_use, () => svgCanvas.disassembleUse2Group(), React.createElement(ActionPanelIcons.Disassemble, null), React.createElement(ActionPanelIcons.DisassembleMobile, null), { isFullLine: true }),
                this.renderAutoArrangeButton(),
                this.renderArrayButton({ isFullLine: true }),
            ];
            return content;
        };
        this.renderGroupActions = () => {
            const content = [
                this.renderAutoArrangeButton(),
                this.renderArrayButton({ isFullLine: true }),
            ];
            return content;
        };
        this.renderMultiSelectActions = () => {
            const { elem } = this.props;
            const children = Array.from(elem.childNodes);
            const supportOffset = children.every((child) => !['g', 'text', 'image', 'use'].includes(child.nodeName));
            const appendOptionalButtons = (buttons) => {
                const text = children.find((child) => child.nodeName === 'text');
                const pathLike = children.find((child) => ['path', 'ellipse', 'line', 'polygon', 'rect'].includes(child.nodeName));
                if (children.length === 2 && text && pathLike) {
                    buttons.push(this.renderButtons('create_textpath', LANG.create_textpath, () => {
                        svgCanvas.ungroupTempGroup();
                        let path = pathLike;
                        if (pathLike.nodeName !== 'path') {
                            path = svgCanvas.convertToPath(path);
                        }
                        textPathEdit.attachTextToPath(text, path);
                        updateElementColor(text);
                    }, React.createElement(ActionPanelIcons.CreateTextpath, null), React.createElement(ActionPanelIcons.CreateTextpath, null), { isFullLine: true, mobileLabel: LANG.create_textpath_short }));
                }
            };
            let content = [];
            appendOptionalButtons(content);
            content = [
                ...content,
                this.renderAutoArrangeButton(),
                this.renderOffsetButton({ isDisabled: !supportOffset }),
                this.renderArrayButton(),
            ];
            return content;
        };
    }
    render() {
        const { elem } = this.props;
        let content = null;
        if (elem) {
            const tagName = elem.tagName.toLowerCase();
            if (tagName === 'image' || tagName === 'img') {
                content = this.renderImageActions();
            }
            else if (tagName === 'text') {
                content = this.renderTextActions();
            }
            else if (tagName === 'path') {
                content = this.renderPathActions();
            }
            else if (tagName === 'rect') {
                content = this.renderRectActions();
            }
            else if (tagName === 'ellipse') {
                content = this.renderEllipseActions();
            }
            else if (tagName === 'polygon') {
                content = this.renderPolygonActions();
            }
            else if (tagName === 'line') {
                content = this.renderLineActions();
            }
            else if (tagName === 'use') {
                content = this.renderUseActions();
            }
            else if (tagName === 'g') {
                if (elem.getAttribute('data-tempgroup') === 'true') {
                    content = this.renderMultiSelectActions();
                }
                else if (elem.getAttribute('data-textpath-g')) {
                    content = this.renderTextPathActions();
                }
                else {
                    content = this.renderGroupActions();
                }
            }
        }
        return isMobile() ? (React.createElement("div", { className: styles.container },
            React.createElement(ObjectPanelItem.Divider, null),
            content)) : (React.createElement("div", { className: styles.panel },
            React.createElement("div", { className: styles.title }, "ACTIONS"),
            React.createElement("div", { className: styles['btns-container'] },
                React.createElement(ConfigProvider, { theme: textButtonTheme }, content))));
    }
}
export default ActionsPanel;
