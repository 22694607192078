var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import AboutBeamStudio from 'app/components/dialogs/AboutBeamStudio';
import AnnouncementPanel from 'app/components/dialogs/AnnouncementPanel';
import Boxgen from 'app/components/boxgen/Boxgen';
import CartridgeSettingPanel from 'app/components/dialogs/CartridgeSettingPanel';
import ChangeLog from 'app/components/dialogs/ChangeLog';
import CropPanel from 'app/views/beambox/ImageEditPanel/CropPanel';
import DeviceSelector from 'app/views/dialogs/DeviceSelector';
import DialogBox from 'app/widgets/Dialog-Box';
import DocumentSettings from 'app/components/dialogs/DocumentSettings';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FirmwareUpdate from 'app/components/dialogs/FirmwareUpdate';
import FluxCredit from 'app/components/dialogs/FluxCredit';
import FluxIdLogin from 'app/components/dialogs/FluxIdLogin';
import FluxPlusWarning from 'app/components/dialogs/FluxPlusWarning';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import InputLightBox from 'app/widgets/InputLightbox';
import LayerColorConfigPanel from 'app/views/beambox/Layer-Color-Config';
import MediaTutorial from 'app/components/dialogs/MediaTutorial';
import MyCloud from 'app/components/dialogs/myCloud/MyCloud';
import NetworkTestingPanel from 'app/views/beambox/NetworkTestingPanel';
import NounProjectPanel from 'app/views/beambox/Noun-Project-Panel';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import PhotoEditPanel from 'app/views/beambox/Photo-Edit-Panel';
import PreviewHeight from 'app/components/dialogs/PreviewHeight';
import Prompt from 'app/views/dialogs/Prompt';
import QRCodeGenerator from 'app/components/dialogs/QRCodeGenerator';
import RadioSelectDialog from 'app/components/dialogs/RadioSelectDialog';
import RatingPanel from 'app/components/dialogs/RatingPanel';
import RotationParameters3DPanel from 'app/components/dialogs/camera/RotationParameters3DPanel';
import SaveFileModal from 'app/components/dialogs/myCloud/SaveFileModal';
import ShapePanel from 'app/views/beambox/ShapePanel/ShapePanel';
import shortcuts from 'helpers/shortcuts';
import SvgNestButtons from 'app/views/beambox/SvgNestButtons';
import Tutorial from 'app/views/tutorials/Tutorial';
import webNeedConnectionWrapper from 'helpers/web-need-connection-helper';
import { eventEmitter } from 'app/contexts/DialogContext';
import { getCurrentUser, getInfo } from 'helpers/api/flux-id';
import { getSVGAsync } from 'helpers/svg-editor-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const layerPanelEventEmitter = eventEmitterFactory.createEventEmitter('layer-panel');
const addDialogComponent = (id, component) => {
    eventEmitter.emit('ADD_DIALOG_COMPONENT', id, component);
};
const clearAllDialogComponents = () => {
    eventEmitter.emit('CLEAR_ALL_DIALOG_COMPONENTS');
};
const isIdExist = (id) => {
    const response = {
        isIdExist: false,
    };
    eventEmitter.emit('CHECK_ID_EXIST', id, response);
    return response.isIdExist;
};
const popDialogById = (id) => {
    eventEmitter.emit('POP_DIALOG_BY_ID', id);
};
let promptIndex = 0;
const getPromptId = () => {
    const id = `prompt-${promptIndex}`;
    promptIndex = (promptIndex + 1) % 10000;
    return id;
};
const showLoginDialog = (callback, silent = false) => {
    if (isIdExist('flux-id-login'))
        return;
    if (isWeb() && callback) {
        window.addEventListener('DISMISS_FLUX_LOGIN', callback);
    }
    addDialogComponent('flux-id-login', React.createElement(FluxIdLogin, { silent: silent, onClose: () => {
            window.removeEventListener('DISMISS_FLUX_LOGIN', callback);
            popDialogById('flux-id-login');
            if (callback)
                callback();
        } }));
};
const forceLoginWrapper = (callback, silent) => {
    let user = getCurrentUser();
    if (!user) {
        showLoginDialog(() => {
            user = getCurrentUser();
            if (user)
                callback();
        }, silent);
    }
    else {
        callback();
    }
};
// TODO: decouple dialog caller and dialog modals
const showFluxPlusWarning = (monotype) => {
    if (isIdExist('flux-plus-warning'))
        return;
    addDialogComponent('flux-plus-warning', React.createElement(FluxPlusWarning, { onClose: () => popDialogById('flux-plus-warning'), monotype: monotype }));
};
eventEmitter.on('SHOW_FLUX_PLUS_WARNING', showFluxPlusWarning);
const showDeviceSelector = (onSelect) => {
    addDialogComponent('device-selector', React.createElement(DeviceSelector, { onSelect: onSelect, onClose: () => popDialogById('device-selector') }));
};
const promptDialog = (args) => {
    const id = getPromptId();
    promptIndex = (promptIndex + 1) % 10000;
    addDialogComponent(id, React.createElement(Prompt, { caption: args.caption, message: args.message, defaultValue: args.defaultValue, onYes: args.onYes, onCancel: args.onCancel, onClose: () => popDialogById(id) }));
};
export default {
    addDialogComponent,
    clearAllDialogComponents,
    isIdExist,
    popDialogById,
    selectDevice: () => __awaiter(void 0, void 0, void 0, function* () {
        const device = yield webNeedConnectionWrapper(() => new Promise((resolve) => showDeviceSelector(resolve)));
        return device;
    }),
    showAboutBeamStudio: () => {
        if (isIdExist('about-bs'))
            return;
        addDialogComponent('about-bs', React.createElement(AboutBeamStudio, { onClose: () => popDialogById('about-bs') }));
    },
    showDocumentSettings: () => {
        if (isIdExist('docu-setting'))
            return;
        const unmount = () => popDialogById('docu-setting');
        addDialogComponent('docu-setting', React.createElement(DocumentSettings, { unmount: unmount }));
    },
    showDxfDpiSelector: (defaultValue) => new Promise((resolve) => {
        addDialogComponent('dxf-dpi-select', React.createElement(Prompt, { caption: i18n.lang.message.please_enter_dpi, message: "1, 2.54, 25.4, 72, 96, ...etc.", defaultValue: defaultValue.toString(), onYes: (val) => {
                popDialogById('dxf-dpi-select');
                resolve(Number(val));
            }, onCancel: () => {
                popDialogById('dxf-dpi-select');
                resolve(null);
            }, onClose: () => popDialogById('dxf-dpi-select') }));
    }),
    showNetworkTestingPanel: (ip) => {
        if (isIdExist('network-test'))
            return;
        addDialogComponent('network-test', React.createElement(NetworkTestingPanel, { ip: ip, onClose: () => popDialogById('network-test') }));
    },
    showNounProjectPanel: () => {
        if (isIdExist('noun-project'))
            return;
        addDialogComponent('noun-project', React.createElement(NounProjectPanel, { onClose: () => popDialogById('noun-project') }));
    },
    showCropPanel: () => {
        if (isIdExist('image-crop'))
            return;
        const selectedElements = svgCanvas.getSelectedElems();
        if (selectedElements.length !== 1)
            return;
        const element = selectedElements[0];
        const src = element.getAttribute('origImage') || element.getAttribute('xlink:href');
        addDialogComponent('image-crop', React.createElement(CropPanel, { src: src, image: element, onClose: () => {
                popDialogById('image-crop');
                ObjectPanelController.updateActiveKey(null);
            } }));
    },
    showPhotoEditPanel: (mode) => {
        if (isIdExist('photo-edit'))
            return;
        const selectedElements = svgCanvas.getSelectedElems();
        if (selectedElements.length !== 1)
            return;
        const element = selectedElements[0];
        const src = element.getAttribute('origImage') || element.getAttribute('xlink:href');
        addDialogComponent('photo-edit', React.createElement(PhotoEditPanel, { mode: mode, element: element, src: src, unmount: () => popDialogById('photo-edit') }));
    },
    showLayerColorConfig: () => {
        if (isIdExist('layer-color-config'))
            return;
        addDialogComponent('layer-color-config', React.createElement(LayerColorConfigPanel, { onClose: () => popDialogById('layer-color-config') }));
    },
    showRatingDialog: (onSubmit) => {
        if (isIdExist('rating-dialog'))
            return;
        addDialogComponent('rating-dialog', React.createElement(RatingPanel, { onSubmit: onSubmit, onClose: () => popDialogById('rating-dialog') }));
    },
    showAnnouncementDialog: (announcement) => {
        const id = `announcement-${announcement.id}`;
        if (isIdExist(id))
            return;
        addDialogComponent(id, React.createElement(AnnouncementPanel, { announcement: announcement, onClose: () => popDialogById(id) }));
    },
    showSvgNestButtons: () => {
        if (isIdExist('svg-nest'))
            return;
        addDialogComponent('svg-nest', React.createElement(SvgNestButtons, { onClose: () => popDialogById('svg-nest') }));
    },
    showTutorial: (tutorial, callback) => {
        const { id } = tutorial;
        if (isIdExist(id))
            return;
        svgCanvas.clearSelection();
        layerPanelEventEmitter.emit('startTutorial');
        addDialogComponent(id, React.createElement(Tutorial, { hasNextButton: tutorial.hasNextButton, end_alert: tutorial.end_alert, dialogStylesAndContents: tutorial.dialogStylesAndContents, onClose: () => {
                popDialogById(id);
                layerPanelEventEmitter.emit('endTutorial');
                callback();
            } }));
    },
    promptDialog,
    getPreviewHeight: (args) => new Promise((resolve) => {
        const id = 'get-preview-height';
        if (isIdExist(id))
            popDialogById(id);
        addDialogComponent(id, React.createElement(PreviewHeight, { initValue: args.initValue, onOk: (val) => resolve(val), onClose: () => popDialogById(id) }));
    }),
    getPromptValue: (args) => new Promise((resolve) => {
        const onYes = (val) => resolve(val);
        const onCancel = () => resolve(null);
        promptDialog(Object.assign(Object.assign({}, args), { onYes, onCancel }));
    }),
    showConfirmPromptDialog: (args) => new Promise((resolve) => {
        const id = getPromptId();
        addDialogComponent(id, React.createElement(Prompt, { caption: args.caption, message: args.message, placeholder: args.confirmValue, confirmValue: args.confirmValue, onYes: (value) => {
                var _a;
                if ((value === null || value === void 0 ? void 0 : value.toLowerCase()) === ((_a = args.confirmValue) === null || _a === void 0 ? void 0 : _a.toLowerCase()))
                    resolve(true);
            }, alertConfigKey: args.alertConfigKey, onCancel: () => resolve(false), onClose: () => popDialogById(id) }));
    }),
    showChangLog: (args = {}) => {
        if (isIdExist('change-log'))
            return;
        const { callback } = args;
        addDialogComponent('change-log', React.createElement(ChangeLog, { onClose: () => {
                popDialogById('change-log');
                if (callback)
                    callback();
            } }));
    },
    showInputLightbox: (id, args) => {
        addDialogComponent(id, React.createElement(InputLightBox, { caption: args.caption, type: args.type || 'TEXT_INPUT', inputHeader: args.inputHeader, defaultValue: args.defaultValue, confirmText: args.confirmText, maxLength: args.maxLength, onSubmit: (value) => {
                args.onSubmit(value);
            }, onClose: (from) => {
                popDialogById(id);
                if (from !== 'submit')
                    args.onCancel();
            } }));
    },
    showLoginDialog,
    forceLoginWrapper,
    showDialogBox: (id, style, content) => {
        if (isIdExist(id))
            return;
        console.log(style);
        addDialogComponent(id, React.createElement(DialogBox, { position: style.position, arrowDirection: style.arrowDirection, arrowWidth: style.arrowWidth, arrowHeight: style.arrowHeight, arrowPadding: style.arrowPadding, arrowColor: style.arrowColor, content: content, onClose: () => popDialogById(id) }));
    },
    showFirmwareUpdateDialog: (device, updateInfo, onDownload, onInstall) => {
        if (isIdExist('update-dialog'))
            return;
        const { name, model, version } = device;
        const releaseNode = i18n.getActiveLang() === 'zh-tw' ? updateInfo.changelog_zh : updateInfo.changelog_en;
        addDialogComponent('update-dialog', React.createElement(FirmwareUpdate, { deviceName: name, deviceModel: model, currentVersion: version, latestVersion: updateInfo.latestVersion, releaseNote: releaseNode, onDownload: onDownload, onInstall: onInstall, onClose: () => popDialogById('update-dialog') }));
    },
    showMediaTutorial: (data) => new Promise((resolve) => {
        addDialogComponent('media-tutorial', React.createElement(MediaTutorial, { data: data, onClose: () => {
                popDialogById('media-tutorial');
                resolve();
            } }));
    }),
    showLoadingWindow: () => {
        const id = 'loading-window';
        if (isIdExist(id))
            return;
        addDialogComponent(id, React.createElement("div", { className: "loading-background" },
            React.createElement("div", { className: "spinner-roller absolute-center" })));
    },
    showShapePanel: (onClose) => {
        if (isIdExist('shape-panel'))
            return;
        addDialogComponent('shape-panel', React.createElement(ShapePanel, { onClose: () => {
                onClose();
                popDialogById('shape-panel');
            } }));
    },
    showCatridgeSettingPanel: (initData, inkLevel) => {
        if (isIdExist('catridge-setting'))
            return;
        addDialogComponent('catridge-setting', React.createElement(CartridgeSettingPanel, { inkLevel: inkLevel, initData: initData, onClose: () => popDialogById('catridge-setting') }));
    },
    showRadioSelectDialog: (args) => new Promise((resolve) => {
        const dialogId = args.id || 'radio-select';
        if (isIdExist(dialogId))
            return;
        const { title, options } = args;
        addDialogComponent(dialogId, React.createElement(RadioSelectDialog, { title: title, options: options, defaultValue: options[0].value, onOk: (val) => {
                popDialogById(dialogId);
                resolve(val);
            }, onCancel: () => {
                popDialogById(dialogId);
                resolve(null);
            } }));
    }),
    showRotationParameters3DPanel: ({ initParams, onApply, onSave, }) => {
        if (isIdExist('rotation-parameters-3d'))
            return;
        addDialogComponent('rotation-parameters-3d', React.createElement(RotationParameters3DPanel, { initialParams: initParams, onApply: onApply, onSave: onSave, onClose: () => popDialogById('rotation-parameters-3d') }));
    },
    showQRCodeGenerator: (onClose) => {
        if (isIdExist('qr-code-generator'))
            return;
        addDialogComponent('qr-code-generator', React.createElement(QRCodeGenerator, { onClose: () => {
                popDialogById('qr-code-generator');
                onClose();
            } }));
    },
    showFluxCreditDialog: () => {
        if (isIdExist('flux-id-credit'))
            return;
        forceLoginWrapper(() => __awaiter(void 0, void 0, void 0, function* () {
            yield getInfo(true);
            if (isIdExist('flux-id-credit'))
                return;
            addDialogComponent('flux-id-credit', React.createElement(FluxCredit, { onClose: () => popDialogById('flux-id-credit') }));
        }), true);
    },
    showBoxGen: (onClose) => {
        if (isIdExist('box-gen'))
            return;
        shortcuts.pauseAll();
        addDialogComponent('box-gen', React.createElement(Boxgen, { onClose: () => {
                shortcuts.initialize();
                onClose();
                popDialogById('box-gen');
            } }));
    },
    showMyCloud: (onClose) => {
        if (isIdExist('my-cloud'))
            return;
        forceLoginWrapper(() => {
            if (isIdExist('my-cloud'))
                return;
            addDialogComponent('my-cloud', React.createElement(MyCloud, { onClose: () => {
                    onClose();
                    popDialogById('my-cloud');
                } }));
        }, true);
    },
    saveToCloud: (uuid) => new Promise((resolve) => {
        addDialogComponent('save-to-cloud', React.createElement(SaveFileModal, { onClose: (fileName, isCancelled) => {
                popDialogById('save-to-cloud');
                resolve({ fileName, isCancelled });
            }, uuid: uuid }));
    }),
};
