// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-dialogs-CurveEngraving-CurveEngraving-module__container--sD1qJ{position:relative;width:100%;height:220px;margin-bottom:8px}.src-web-app-components-dialogs-CurveEngraving-CurveEngraving-module__container--sD1qJ canvas{width:100% !important;height:100% !important;background-color:#f7f7f7;cursor:grab}.src-web-app-components-dialogs-CurveEngraving-CurveEngraving-module__buttons--dDpAs{display:flex;gap:8px;justify-content:center;margin-top:8px}.src-web-app-components-dialogs-CurveEngraving-CurveEngraving-module__hint--vSuc-{margin:20px 0;font-size:14px;color:#7c7c7c}", "",{"version":3,"sources":["webpack://./src/web/app/components/dialogs/CurveEngraving/CurveEngraving.module.scss","webpack://./src/web/styles/_variables.scss"],"names":[],"mappings":"AAGA,uFACE,iBAAA,CACA,UAAA,CACA,YAAA,CACA,iBAAA,CAEA,8FACE,qBAAA,CACA,sBAAA,CACA,wBAAA,CACA,WAAA,CAIJ,qFACE,YAAA,CACA,OAAA,CACA,sBAAA,CACA,cAAA,CAGF,kFACE,aAAA,CACA,cAAA,CACA,aCfe","sourcesContent":["@use \"styles/variables\" as variables;\n\n\n.container {\n  position: relative;\n  width: 100%;\n  height: 220px;\n  margin-bottom: 8px;\n\n  canvas {\n    width: 100% !important;\n    height: 100% !important;\n    background-color: #f7f7f7;\n    cursor: grab;\n  }\n}\n\n.buttons {\n  display: flex;\n  gap: 8px;\n  justify-content: center;\n  margin-top: 8px;\n}\n\n.hint {\n  margin: 20px 0;\n  font-size: 14px;\n  color: variables.$secondary-gray;\n}\n","$print-default-font-color: #4A4A4A;\n$default-button-border-radius: 4px;\n$size: 200px;\n$big-z-index: 9999;\n$primaryColor: #888;\n$backgroundColor: #F0F0F0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #E0E0E0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$secondary-gray: #7c7c7c;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "src-web-app-components-dialogs-CurveEngraving-CurveEngraving-module__container--sD1qJ",
	"buttons": "src-web-app-components-dialogs-CurveEngraving-CurveEngraving-module__buttons--dDpAs",
	"hint": "src-web-app-components-dialogs-CurveEngraving-CurveEngraving-module__hint--vSuc-"
};
export default ___CSS_LOADER_EXPORT___;
