import React, { useCallback, useReducer } from 'react';
import { Modal, Row } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import { updateDefaultPresetData } from 'helpers/presets/preset-helper';
import ArrowButtons from './ArrowButtons';
import Context, { getInitState, reducer } from './Context';
import CustomConfigs from './CustomConfigs';
import Footer from './Footer';
import Inputs from './Inputs';
import PresetsList from './PresetsList';
const LaserManageModal = ({ selectedItem, onClose, onSave }) => {
    const [state, dispatch] = useReducer(reducer, null, () => getInitState(selectedItem));
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel;
    const handleDelete = useCallback(() => dispatch({ type: 'delete' }), [dispatch]);
    const handleReset = () => {
        alertCaller.popUp({
            buttonType: alertConstants.YES_NO,
            message: t.sure_to_reset,
            onYes: () => {
                storage.removeAt('defaultLaserConfigsInUse');
                updateDefaultPresetData();
                dispatch({ type: 'reset' });
            },
        });
    };
    const handleSave = () => {
        const { configs, dataChanges, presetsInUse } = state;
        for (let i = 0; i < configs.length; i += 1) {
            if (dataChanges[configs[i].name])
                configs[i] = Object.assign(Object.assign({}, configs[i]), dataChanges[configs[i].name]);
        }
        storage.set('customizedLaserConfigs', configs);
        storage.set('defaultLaserConfigsInUse', presetsInUse);
        onSave();
        onClose();
    };
    const footer = React.createElement(Footer, { onClose: onClose, onDelete: handleDelete, onReset: handleReset, onSave: handleSave });
    return (React.createElement(Modal, { open: true, centered: true, footer: footer, title: t.more, onCancel: onClose },
        React.createElement(Context.Provider, { value: { state, dispatch } },
            React.createElement("div", { className: "more-config-panel" },
                React.createElement(Row, null,
                    React.createElement(PresetsList, null),
                    React.createElement(ArrowButtons, null),
                    React.createElement(CustomConfigs, null)),
                React.createElement(Inputs, null)))));
};
export default LaserManageModal;
