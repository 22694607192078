// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-Right-Panels-ConfigPanel-AddOnBlock-module__label--Jcb-8{height:40px;line-height:20px;padding:10px 15px;background:#f2f2f2;border:1px #e0e0e0 solid;border-width:1px 0;text-align:left;color:#333;font-weight:bold}.src-web-app-views-beambox-Right-Panels-ConfigPanel-AddOnBlock-module__settings--8fN7U{padding:10px 3px}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/Right-Panels/ConfigPanel/AddOnBlock.module.scss"],"names":[],"mappings":"AAAA,oFACE,WAAA,CACA,gBAAA,CACA,iBAAA,CACA,kBAAA,CACA,wBAAA,CACA,kBAAA,CACA,eAAA,CACA,UAAA,CACA,gBAAA,CAGF,uFACE,gBAAA","sourcesContent":[".label {\n  height: 40px;\n  line-height: 20px;\n  padding: 10px 15px;\n  background: #f2f2f2;\n  border: 1px #e0e0e0 solid;\n  border-width: 1px 0;\n  text-align: left;\n  color: #333;\n  font-weight: bold;\n}\n\n.settings {\n  padding: 10px 3px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": "src-web-app-views-beambox-Right-Panels-ConfigPanel-AddOnBlock-module__label--Jcb-8",
	"settings": "src-web-app-views-beambox-Right-Panels-ConfigPanel-AddOnBlock-module__settings--8fN7U"
};
export default ___CSS_LOADER_EXPORT___;
