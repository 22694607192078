import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/UnitInput';
import { objectPanelInputTheme } from 'app/constants/antd-config';
import { useIsMobile } from 'helpers/system-helper';
import styles from './DimensionPanel.module.scss';
import { getValue } from './utils';
const PositionInput = ({ type, value, onChange }) => {
    const inputRef = useRef(null);
    const objectPanelEventEmitter = useMemo(() => eventEmitterFactory.createEventEmitter('object-panel'), []);
    const isMobile = useIsMobile();
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const unit = useMemo(() => (isInch ? 'in' : 'mm'), [isInch]);
    const precision = useMemo(() => (isInch ? 4 : 2), [isInch]);
    useEffect(() => {
        const handler = (newValues) => {
            if (inputRef.current) {
                const newVal = getValue(newValues, type, { unit, allowUndefined: true });
                if (newVal === undefined)
                    return;
                inputRef.current.value = newVal.toFixed(precision);
            }
        };
        objectPanelEventEmitter.on('UPDATE_DIMENSION_VALUES', handler);
        return () => {
            objectPanelEventEmitter.removeListener('UPDATE_DIMENSION_VALUES', handler);
        };
    }, [type, unit, precision, objectPanelEventEmitter]);
    const label = useMemo(() => {
        if (type === 'x')
            return 'X';
        if (type === 'y')
            return 'Y';
        if (type === 'x1')
            return (React.createElement(React.Fragment, null,
                "X",
                React.createElement("sub", null, "1")));
        if (type === 'y1')
            return (React.createElement(React.Fragment, null,
                "Y",
                React.createElement("sub", null, "1")));
        if (type === 'x2')
            return (React.createElement(React.Fragment, null,
                "X",
                React.createElement("sub", null, "2")));
        if (type === 'y2')
            return (React.createElement(React.Fragment, null,
                "Y",
                React.createElement("sub", null, "2")));
        if (type === 'cx')
            return (React.createElement(React.Fragment, null,
                "X",
                React.createElement("sub", null, "C")));
        if (type === 'cy')
            return (React.createElement(React.Fragment, null,
                "Y",
                React.createElement("sub", null, "C")));
        return null;
    }, [type]);
    const inputId = useMemo(() => `${type}_position`, [type]);
    const handleChange = useCallback((val) => onChange(type, val), [type, onChange]);
    if (isMobile)
        return (React.createElement(ObjectPanelItem.Number, { id: inputId, value: value, updateValue: handleChange, label: label }));
    return (React.createElement("div", { className: styles.dimension },
        React.createElement("div", { className: styles.label }, label),
        React.createElement(UnitInput, { ref: inputRef, id: inputId, className: styles.input, theme: objectPanelInputTheme, unit: unit, isInch: isInch, precision: isInch ? 4 : 2, step: isInch ? 2.54 : 1, value: value, controls: false, onChange: handleChange })));
};
export default memo(PositionInput);
