import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { ConfigProvider, InputNumber, Slider } from 'antd';
import useI18n from 'helpers/useI18n';
import styles from './ColorRatioModal.module.scss';
const ColorRatioBlock = ({ ratio, setRatio, color }) => {
    const lang = useI18n().beambox.right_panel.laser_panel;
    const [displayRatio, setDisplayRatio] = useState(ratio);
    useEffect(() => setDisplayRatio(ratio), [ratio]);
    const { title } = useMemo(() => {
        switch (color) {
            case 'c':
                return { title: 'Cyan' };
            case 'm':
                return { title: 'Magenta' };
            case 'y':
                return { title: 'Yellow' };
            case 'k':
                return { title: 'Black' };
            default:
                return { title: '' };
        }
    }, [color]);
    return (React.createElement("div", { className: classNames(styles.block, styles[color]) },
        React.createElement("div", null, title),
        React.createElement("div", { className: styles.header },
            React.createElement("span", { className: styles.title }, lang.color_strength),
            React.createElement("span", { className: styles.input },
                React.createElement(ConfigProvider, { theme: {
                        token: {
                            lineWidth: 0,
                        },
                    } },
                    React.createElement(InputNumber, { size: "small", value: ratio, controls: false, min: 0, max: 200, onChange: setRatio })),
                React.createElement("span", { className: styles.unit }, "%"))),
        React.createElement(Slider, { min: 0, max: 200, step: 1, value: displayRatio, onAfterChange: setRatio, onChange: (v) => setDisplayRatio(v), tooltip: {
                formatter: (v) => `${v}%`,
            } })));
};
export default ColorRatioBlock;
