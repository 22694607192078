import React, { memo } from 'react';
import { Button, Col, Row, Space } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import useI18n from 'helpers/useI18n';
const Footer = ({ onClose, onDelete, onReset, onSave }) => {
    const lang = useI18n();
    const t = lang.beambox.right_panel.laser_panel;
    return (React.createElement(Row, { justify: "space-between" },
        React.createElement(Col, { span: 12 },
            React.createElement(Space, { align: "start", style: { width: '100%' } },
                React.createElement(Button, { key: "laser_delete", type: "primary", danger: true, onClick: onDelete },
                    React.createElement(DeleteFilled, null),
                    t.delete),
                React.createElement(Button, { key: "laser_reset", type: "dashed", danger: true, onClick: onReset }, t.reset))),
        React.createElement(Col, { span: 12 },
            React.createElement(Space, null,
                React.createElement(Button, { key: "laser_save_and_exit", type: "primary", onClick: onSave }, t.save_and_exit),
                React.createElement(Button, { key: "laser_cancel", onClick: onClose }, t.cancel)))));
};
export default memo(Footer);
