import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import ObjectPanelItem from 'app/views/beambox/Right-Panels/ObjectPanelItem';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/UnitInput';
import { objectPanelInputTheme } from 'app/constants/antd-config';
import { useIsMobile } from 'helpers/system-helper';
import styles from './DimensionPanel.module.scss';
import { getValue } from './utils';
const typeKeyMap = {
    w: 'width',
    h: 'height',
    rx: 'rx',
    ry: 'ry',
};
const SizeInput = ({ type, value, onChange, onBlur }) => {
    const inputRef = useRef(null);
    const objectPanelEventEmitter = useMemo(() => eventEmitterFactory.createEventEmitter('object-panel'), []);
    const isMobile = useIsMobile();
    const isInch = useMemo(() => storage.get('default-units') === 'inches', []);
    const unit = useMemo(() => (isInch ? 'in' : 'mm'), [isInch]);
    const precision = useMemo(() => (isInch ? 4 : 2), [isInch]);
    useEffect(() => {
        const handler = (newValues) => {
            if (inputRef.current) {
                const newVal = getValue(newValues, type, { unit, allowUndefined: true });
                if (newVal === undefined)
                    return;
                inputRef.current.value = newVal.toFixed(precision);
            }
        };
        objectPanelEventEmitter.on('UPDATE_DIMENSION_VALUES', handler);
        return () => {
            objectPanelEventEmitter.removeListener('UPDATE_DIMENSION_VALUES', handler);
        };
    }, [type, unit, precision, objectPanelEventEmitter]);
    const label = useMemo(() => {
        if (type === 'w')
            return 'W';
        if (type === 'h')
            return 'H';
        if (type === 'rx')
            return 'W';
        if (type === 'ry')
            return 'H';
        return null;
    }, [type]);
    const handleChange = useCallback((val) => {
        const changeKey = typeKeyMap[type];
        const newVal = type === 'rx' || type === 'ry' ? val / 2 : val;
        onChange(changeKey, newVal);
    }, [onChange, type]);
    if (isMobile) {
        return (React.createElement(ObjectPanelItem.Number, { id: `${type}_size`, value: value, updateValue: handleChange, label: label }));
    }
    return (React.createElement("div", { className: styles.dimension },
        React.createElement("div", { className: styles.label }, label),
        React.createElement(UnitInput, { ref: inputRef, id: `${type}_size`, className: styles.input, theme: objectPanelInputTheme, unit: unit, isInch: isInch, precision: precision, step: isInch ? 2.54 : 1, value: value, onBlur: onBlur, controls: false, onChange: handleChange, min: 0 })));
};
export default memo(SizeInput);
