var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useRef, useEffect, useContext } from 'react';
import { Modal, Segmented } from 'antd';
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import Browser from 'implementations/browser';
import CheckDeviceStatus from 'helpers/check-device-status';
import Constant from 'app/actions/beambox/constant';
import DeviceErrorHandler from 'helpers/device-error-handler';
import DeviceMaster from 'helpers/device-master';
import PreviewModeController from 'app/actions/beambox/preview-mode-controller';
import Progress from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import VersionChecker from 'helpers/version-checker';
import { CalibrationContext } from 'app/contexts/CalibrationContext';
import { STEP_BEFORE_ANALYZE_PICTURE } from 'app/constants/camera-calibration-constants';
import { doGetOffsetFromPicture } from 'helpers/camera-calibration-helper';
const StepRefocus = () => {
    const lang = useI18n();
    const langAlert = lang.alert;
    const langCalibration = lang.calibration;
    const context = useContext(CalibrationContext);
    const { calibratedMachines, device, gotoNextStep, onClose, setCalibratedMachines, setCameraPosition, setCurrentOffset, setImgBlobUrl, setLastConfig, setOriginFanSpeed, } = context;
    const doCuttingTask = () => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield DeviceMaster.select(device);
        if (!res.success) {
            throw new Error('Fail to select device');
        }
        const laserPower = Number((yield DeviceMaster.getLaserPower()).value);
        const fanSpeed = Number((yield DeviceMaster.getFan()).value);
        setOriginFanSpeed(fanSpeed);
        const tempCmdAvailable = VersionChecker(device.version).meetRequirement('TEMP_I2C_CMD');
        if (tempCmdAvailable)
            yield DeviceMaster.setFanTemp(100);
        else if (fanSpeed > 100)
            yield DeviceMaster.setFan(100);
        if (laserPower !== 1)
            yield DeviceMaster.setLaserPower(1);
        yield DeviceMaster.runBeamboxCameraTest();
        if (laserPower !== 1)
            yield DeviceMaster.setLaserPower(Number(laserPower));
        if (!tempCmdAvailable)
            yield DeviceMaster.setFan(fanSpeed);
    });
    const doCaptureTask = () => __awaiter(void 0, void 0, void 0, function* () {
        let blobUrl;
        try {
            yield PreviewModeController.start(device, () => console.log('camera fail. stop preview mode'));
            setLastConfig(PreviewModeController.getCameraOffsetStandard());
            Progress.openNonstopProgress({
                id: 'taking-picture',
                message: langCalibration.taking_picture,
                timeout: 30000,
            });
            const movementX = Constant.camera.calibrationPicture.centerX - Constant.camera.offsetX_ideal;
            const movementY = Constant.camera.calibrationPicture.centerY - Constant.camera.offsetY_ideal;
            blobUrl = yield PreviewModeController.takePictureAfterMoveTo(movementX, movementY);
            setCameraPosition({ x: movementX, y: movementY });
        }
        finally {
            Progress.popById('taking-picture');
        }
        return blobUrl;
    });
    const cutThenCapture = () => __awaiter(void 0, void 0, void 0, function* () {
        yield doCuttingTask();
        const blobUrl = yield doCaptureTask();
        yield doGetOffsetFromPicture(blobUrl, (offset) => {
            setCurrentOffset(offset);
        });
        setImgBlobUrl(blobUrl);
    });
    const [isAutoFocus, setIsAutoFocus] = useState(false);
    const [isCutButtonDisabled, setIsCutButtonDisabled] = useState(false);
    const videoElem = useRef(null);
    useEffect(() => {
        if (videoElem.current)
            videoElem.current.load();
    }, [isAutoFocus]);
    let child = null;
    let message;
    if (device.model === 'fbm1') {
        child = (React.createElement("div", { className: "video-container" },
            React.createElement("div", { className: "tab-container" },
                React.createElement(Segmented, { block: true, options: [langCalibration.without_af, langCalibration.with_af], onChange: (v) => setIsAutoFocus(v === langCalibration.with_af) })),
            React.createElement("video", { className: "video", ref: videoElem, autoPlay: true, loop: true, muted: true },
                React.createElement("source", { src: isAutoFocus ? 'video/autofocus.webm' : 'video/bm_focus.webm', type: "video/webm" }),
                React.createElement("source", { src: isAutoFocus ? 'video/autofocus.mp4' : 'video/bm_focus.mp4', type: "video/mp4" }))));
        message = isAutoFocus ? langCalibration.please_refocus.beamo_af : langCalibration.please_refocus.beamo;
    }
    else if (device.model === 'fhexa1') {
        message = langCalibration.please_refocus.hexa;
        child = (React.createElement("video", { className: "video", ref: videoElem, autoPlay: true, loop: true },
            React.createElement("source", { src: "video/bb2_focus.webm", type: "video/webm" }),
            React.createElement("source", { src: "video/bb2_focus.mp4", type: "video/mp4" })));
    }
    else {
        message = langCalibration.please_refocus.beambox;
        child = (React.createElement("video", { className: "video", ref: videoElem, autoPlay: true, loop: true, muted: true },
            React.createElement("source", { src: "video/bb_focus.webm", type: "video/webm" }),
            React.createElement("source", { src: "video/bb_focus.mp4", type: "video/mp4" })));
    }
    const onEngrave = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isCutButtonDisabled) {
            return;
        }
        try {
            setIsCutButtonDisabled(true);
            yield cutThenCapture();
            if (!calibratedMachines.includes(device.uuid)) {
                setCalibratedMachines([...calibratedMachines, device.uuid]);
            }
            gotoNextStep(STEP_BEFORE_ANALYZE_PICTURE);
        }
        catch (error) {
            setIsCutButtonDisabled(false);
            console.log(error);
            const errorMessage = error instanceof Error
                ? error.message : DeviceErrorHandler.translate(error);
            Alert.popUp({
                id: 'camera-cali-err',
                type: AlertConstants.SHOW_POPUP_ERROR,
                message: `#815 ${errorMessage || 'Fail to cut and capture'}`,
                buttonLabels: [langAlert.ok, langAlert.learn_more],
                callbacks: [
                    () => __awaiter(void 0, void 0, void 0, function* () {
                        const report = yield DeviceMaster.getReport();
                        device.st_id = report.st_id;
                        yield CheckDeviceStatus(device, false, true);
                    }),
                    () => Browser.open(langCalibration.zendesk_link),
                ],
                primaryButtonIndex: 0,
            });
        }
    });
    return (React.createElement(Modal, { width: 400, open: true, centered: true, className: "modal-camera-calibration", title: langCalibration.camera_calibration, onCancel: () => onClose(false), cancelText: langCalibration.cancel, okText: langCalibration.start_engrave, onOk: onEngrave, okButtonProps: { disabled: isCutButtonDisabled } },
        message,
        React.createElement("br", null),
        child));
};
export default StepRefocus;
