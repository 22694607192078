var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-console */
import { sprintf } from 'sprintf-js';
import Alert from 'app/actions/alert-caller';
import AlertConfig from 'helpers/api/alert-config';
import AlertConstants from 'app/constants/alert-constants';
import BeamboxPreference from 'app/actions/beambox/beambox-preference';
import checkDeviceStatus from 'helpers/check-device-status';
import checkOldFirmware from 'helpers/device/checkOldFirmware';
import Constant from 'app/actions/beambox/constant';
import dialogCaller from 'app/actions/dialog-caller';
import deviceMaster from 'helpers/device-master';
import ErrorConstants from 'app/constants/error-constants';
import eventEmitterFactory from 'helpers/eventEmitterFactory';
import FisheyePreviewManagerV1 from 'app/actions/beambox/fisheye-preview-helpers/FisheyePreviewManagerV1';
import FisheyePreviewManagerV2 from 'app/actions/beambox/fisheye-preview-helpers/FisheyePreviewManagerV2';
import i18n from 'helpers/i18n';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import PreviewModeBackgroundDrawer from 'app/actions/beambox/preview-mode-background-drawer';
import Progress from 'app/actions/progress-caller';
import VersionChecker from 'helpers/version-checker';
import { getWorkarea } from 'app/constants/workarea-constants';
const LANG = i18n.lang;
const canvasEventEmitter = eventEmitterFactory.createEventEmitter('canvas');
class PreviewModeController {
    constructor() {
        this.reloadHeightOffset = () => __awaiter(this, void 0, void 0, function* () {
            this.fisheyePreviewManager.reloadLevelingOffset();
        });
        this.editCamera3dRotation = () => __awaiter(this, void 0, void 0, function* () {
            if (!this.fisheyePreviewManager.support3dRotation)
                return;
            const handleApply = (newParams) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (this.fisheyePreviewManager.support3dRotation) {
                    yield ((_a = this.fisheyePreviewManager) === null || _a === void 0 ? void 0 : _a.update3DRotation(newParams));
                    yield this.previewFullWorkarea();
                }
            });
            const handleSave = (newParams) => __awaiter(this, void 0, void 0, function* () {
                yield handleApply(newParams);
                Progress.openNonstopProgress({
                    id: 'saving-fisheye-3d',
                    message: 'Saving fisheye 3d rotation',
                });
                try {
                    yield deviceMaster.updateFisheye3DRotation(newParams);
                    if (newParams.tx !== 0 || newParams.ty !== 0) {
                        const fisheyeParams = this.fisheyePreviewManager.params;
                        const oldCenter = fisheyeParams.center || [0, 0];
                        const newCenter = [oldCenter[0] + newParams.tx, oldCenter[1] + newParams.ty];
                        const strData = JSON.stringify(Object.assign(Object.assign({}, fisheyeParams), { center: newCenter }), (key, val) => {
                            if (typeof val === 'number') {
                                return Math.round(val * 1e6) / 1e6;
                            }
                            return val;
                        });
                        yield new Promise((resolve) => setTimeout(() => resolve(), 3000));
                        yield deviceMaster.uploadFisheyeParams(strData, () => { });
                    }
                    Alert.popUp({ message: 'Saved Successfully!' });
                }
                catch (e) {
                    console.error('Fail to save fisheye 3d rotation', e);
                    Alert.popUpError({ message: 'Unable to save fisheye 3d rotation' });
                }
                finally {
                    Progress.popById('saving-fisheye-3d');
                }
            });
            dialogCaller.showRotationParameters3DPanel({
                initParams: this.fisheyePreviewManager.rotationData,
                onApply: handleApply,
                onSave: handleSave,
            });
        });
        this.resetFishEyeObjectHeight = () => __awaiter(this, void 0, void 0, function* () {
            const res = yield this.fisheyePreviewManager.resetObjectHeight();
            if (res && !PreviewModeBackgroundDrawer.isClean())
                yield this.previewFullWorkarea();
        });
        this.setUpFishEyePreviewMode = () => __awaiter(this, void 0, void 0, function* () {
            try {
                let params;
                try {
                    params = yield deviceMaster.fetchFisheyeParams();
                }
                catch (err) {
                    console.log('Fail to fetchFisheyeParams', err === null || err === void 0 ? void 0 : err.message);
                    throw new Error('Unable to get fisheye parameters, please make sure you have calibrated the camera');
                }
                const device = this.currentDevice;
                if (!('v' in params)) {
                    this.fisheyePreviewManager = new FisheyePreviewManagerV1(device, params);
                }
                else if (params.v === 2) {
                    this.fisheyePreviewManager = new FisheyePreviewManagerV2(device, params);
                }
                const res = yield this.fisheyePreviewManager.setupFisheyePreview({ progressId: 'preview-mode-controller' });
                return res;
            }
            finally {
                if (deviceMaster.currentControlMode === 'raw') {
                    yield deviceMaster.rawLooseMotor();
                    Progress.update('preview-mode-controller', { message: LANG.message.endingRawMode });
                    yield deviceMaster.endRawMode();
                }
                Progress.popById('preview-mode-controller');
            }
        });
        this.isLiveModeOn = () => !!(this.isPreviewModeOn && this.liveModeTimeOut);
        this.isDrawing = false;
        this.originalSpeed = 1;
        this.currentDevice = null;
        this.isPreviewModeOn = false;
        this.isPreviewBlocked = false;
        this.isLineCheckEnabled = true;
        this.cameraOffset = null;
        this.lastPosition = [0, 0]; // in mm
        this.errorCallback = () => { };
    }
    setupBeamSeriesPreviewMode() {
        return __awaiter(this, void 0, void 0, function* () {
            const device = this.currentDevice;
            yield this.retrieveCameraOffset();
            Progress.update('preview-mode-controller', { message: LANG.message.gettingLaserSpeed });
            const laserSpeed = yield deviceMaster.getLaserSpeed();
            if (Number(laserSpeed.value) !== 1) {
                this.originalSpeed = Number(laserSpeed.value);
                Progress.update('preview-mode-controller', { message: LANG.message.settingLaserSpeed });
                yield deviceMaster.setLaserSpeed(1);
            }
            Progress.update('preview-mode-controller', { message: LANG.message.enteringRawMode });
            yield deviceMaster.enterRawMode();
            Progress.update('preview-mode-controller', { message: LANG.message.exitingRotaryMode });
            yield deviceMaster.rawSetRotary(false);
            Progress.update('preview-mode-controller', { message: LANG.message.homing });
            yield deviceMaster.rawHome();
            const vc = VersionChecker(device.version);
            if (vc.meetRequirement('MAINTAIN_WITH_LINECHECK')) {
                yield deviceMaster.rawStartLineCheckMode();
                this.isLineCheckEnabled = true;
            }
            else {
                this.isLineCheckEnabled = false;
            }
            Progress.update('preview-mode-controller', { message: LANG.message.turningOffFan });
            yield deviceMaster.rawSetFan(false);
            Progress.update('preview-mode-controller', { message: LANG.message.turningOffAirPump });
            yield deviceMaster.rawSetAirPump(false);
            yield deviceMaster.rawSetWaterPump(false);
        });
    }
    endBeamSeriesPreviewMode() {
        return __awaiter(this, void 0, void 0, function* () {
            if (deviceMaster.currentControlMode !== 'raw')
                yield deviceMaster.enterRawMode();
            if (this.isLineCheckEnabled)
                yield deviceMaster.rawEndLineCheckMode();
            yield deviceMaster.rawLooseMotor();
            yield deviceMaster.endRawMode();
            if (this.originalSpeed !== 1) {
                yield deviceMaster.setLaserSpeed(this.originalSpeed);
                this.originalSpeed = 1;
            }
        });
    }
    checkDevice(device) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isStarting)
                return false;
            if (this.currentDevice && this.currentDevice.serial !== (device === null || device === void 0 ? void 0 : device.serial)) {
                yield this.end();
                PreviewModeBackgroundDrawer.clear();
            }
            if (!device)
                return false;
            const deviceStatus = yield checkDeviceStatus(device);
            if (!deviceStatus)
                return false;
            const vc = VersionChecker(device.version);
            if (!vc.meetRequirement('USABLE_VERSION')) {
                Alert.popUp({
                    type: AlertConstants.SHOW_POPUP_ERROR,
                    message: LANG.beambox.popup.should_update_firmware_to_continue,
                });
                Progress.popById('start-preview-controller');
                return false;
            }
            if (BeamboxPreference.read('borderless') && !vc.meetRequirement('BORDERLESS_MODE')) {
                // eslint-disable-next-line max-len
                const message = `#814 ${LANG.calibration.update_firmware_msg1} 2.5.1 ${LANG.calibration.update_firmware_msg2} ${LANG.beambox.popup.or_turn_off_borderless_mode}`;
                const caption = LANG.beambox.left_panel.borderless_preview;
                Alert.popUp({
                    type: AlertConstants.SHOW_POPUP_ERROR,
                    message,
                    caption,
                });
                Progress.popById('start-preview-controller');
                return false;
            }
            const res = yield checkOldFirmware(device.version);
            if (!res)
                return false;
            return true;
        });
    }
    start(device, errCallback) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.reset();
            this.isStarting = true;
            const res = yield deviceMaster.select(device);
            if (!res.success) {
                this.isStarting = false;
                return;
            }
            try {
                Progress.openNonstopProgress({
                    id: 'preview-mode-controller',
                    message: sprintf(LANG.message.connectingMachine, device.name),
                });
                this.currentDevice = device;
                if (!Constant.adorModels.includes(device.model))
                    yield this.setupBeamSeriesPreviewMode();
                Progress.update('preview-mode-controller', { message: LANG.message.connectingCamera });
                yield deviceMaster.connectCamera();
                if (Constant.adorModels.includes(device.model)) {
                    const setUpRes = yield this.setUpFishEyePreviewMode();
                    if (!setUpRes) {
                        this.isStarting = false;
                        return;
                    }
                }
                PreviewModeBackgroundDrawer.start(this.cameraOffset);
                PreviewModeBackgroundDrawer.drawBoundary();
                deviceMaster.setDeviceControlReconnectOnClose(device);
                this.errorCallback = errCallback;
                this.isPreviewModeOn = true;
                canvasEventEmitter.emit('UPDATE_CONTEXT');
            }
            catch (error) {
                console.error(error);
                this.reset();
                if (!Constant.adorModels.includes(device.model))
                    yield this.endBeamSeriesPreviewMode();
                deviceMaster.kick();
                throw error;
            }
            finally {
                Progress.popById('preview-mode-controller');
                this.isStarting = false;
            }
        });
    }
    end() {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('end of pmc');
            this.isPreviewModeOn = false;
            if (this.liveModeTimeOut)
                clearTimeout(this.liveModeTimeOut);
            this.liveModeTimeOut = null;
            PreviewModeBackgroundDrawer.clearBoundary();
            PreviewModeBackgroundDrawer.end();
            const { currentDevice } = this;
            yield this.reset();
            if (currentDevice) {
                deviceMaster.setDeviceControlDefaultCloseListener(currentDevice);
                const res = yield deviceMaster.select(currentDevice);
                if (res.success) {
                    if (!Constant.adorModels.includes(currentDevice.model))
                        yield this.endBeamSeriesPreviewMode();
                    deviceMaster.kick();
                }
            }
        });
    }
    toggleFullWorkareaLiveMode() {
        if (this.liveModeTimeOut)
            this.stopFullWorkareaLiveMode();
        else
            this.startFullWorkareaLiveMode();
    }
    startFullWorkareaLiveMode() {
        if (!this.isPreviewModeOn)
            return;
        const setNextTimeout = () => {
            this.liveModeTimeOut = setTimeout(() => {
                this.fullWorkareaLiveUpdate(() => {
                    if (this.liveModeTimeOut)
                        setNextTimeout();
                });
            }, 1000);
        };
        setNextTimeout();
    }
    stopFullWorkareaLiveMode() {
        if (this.liveModeTimeOut)
            clearTimeout(this.liveModeTimeOut);
        this.liveModeTimeOut = null;
    }
    fullWorkareaLiveUpdate(callback = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.reloadHeightOffset();
            yield this.previewFullWorkarea(callback);
        });
    }
    previewFullWorkarea(callback = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { isPreviewBlocked } = this;
            if (isPreviewBlocked)
                return false;
            this.isDrawing = true;
            this.isPreviewBlocked = true;
            try {
                MessageCaller.openMessage({
                    key: 'full-area-preview',
                    content: i18n.lang.topbar.preview,
                    level: MessageLevel.LOADING,
                    duration: 20,
                });
                const imgUrl = yield this.getPhotoFromMachine();
                PreviewModeBackgroundDrawer.drawFullWorkarea(imgUrl, callback);
                this.isPreviewBlocked = false;
                this.isDrawing = false;
                MessageCaller.openMessage({
                    key: 'full-area-preview',
                    level: MessageLevel.SUCCESS,
                    content: 'Successfully previewed',
                    duration: 3,
                });
                return true;
            }
            catch (error) {
                if (this.isPreviewModeOn) {
                    console.log(error);
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_ERROR,
                        message: error.message || error.text,
                    });
                }
                if (!PreviewModeBackgroundDrawer.isClean())
                    this.isDrawing = false;
                this.end();
                callback();
                return false;
            }
        });
    }
    preview(x, y, last = false, callback = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            const { isPreviewBlocked, isPreviewModeOn, currentDevice } = this;
            if (isPreviewBlocked || !isPreviewModeOn)
                return false;
            if (Constant.adorModels.includes(currentDevice === null || currentDevice === void 0 ? void 0 : currentDevice.model)) {
                const res = yield this.previewFullWorkarea(callback);
                return res;
            }
            this.isDrawing = true;
            this.isPreviewBlocked = true;
            const workarea = document.querySelector('#workarea');
            workarea.style.cursor = 'wait';
            try {
                const constrainedXY = this.constrainPreviewXY(x, y);
                const { x: newX, y: newY } = constrainedXY;
                const imgUrl = yield this.getPhotoAfterMove(newX, newY);
                PreviewModeBackgroundDrawer.draw(imgUrl, newX, newY, last, callback);
                workarea.style.cursor = 'url(img/camera-cursor.svg), cell';
                this.isPreviewBlocked = false;
                if (last)
                    this.isDrawing = false;
                return true;
            }
            catch (error) {
                if (this.isPreviewModeOn) {
                    console.log(error);
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_ERROR,
                        message: error.message || error.text,
                    });
                }
                workarea.style.cursor = 'auto';
                if (!PreviewModeBackgroundDrawer.isClean()) {
                    this.isDrawing = false;
                }
                this.end();
                callback();
                return false;
            }
        });
    }
    previewRegion(x1, y1, x2, y2, callback = () => { }) {
        return __awaiter(this, void 0, void 0, function* () {
            const points = (() => {
                const size = (() => {
                    const h = Constant.camera.imgHeight;
                    const a = this.getCameraOffset().angle;
                    const s = this.getCameraOffset().scaleRatioY;
                    const c = h / (Math.cos(a) + Math.sin(a));
                    // overlap a little bit to fix empty area between pictures
                    // (some machine will have it, maybe due to cameraOffset.angle).
                    // it seems like something wrong handling image rotation.
                    return c * s;
                })();
                const { left, right, top, bottom } = (() => {
                    const l = Math.min(x1, x2) + size / 2;
                    const r = Math.max(x1, x2) - size / 2;
                    const t = Math.min(y1, y2) + size / 2;
                    const b = Math.max(y1, y2) - size / 2;
                    return {
                        left: this.constrainPreviewXY(l, 0).x,
                        right: this.constrainPreviewXY(r, 0).x,
                        top: this.constrainPreviewXY(0, t).y,
                        bottom: this.constrainPreviewXY(0, b).y,
                    };
                })();
                let pointsArray = [];
                let shouldRowReverse = false; // let camera 走Ｓ字型
                const step = 0.95 * size;
                for (let curY = top; curY < bottom + size; curY += step) {
                    const row = [];
                    for (let curX = left; curX < right + size; curX += step) {
                        row.push([curX, curY]);
                    }
                    if (shouldRowReverse) {
                        row.reverse();
                    }
                    pointsArray = pointsArray.concat(row);
                    shouldRowReverse = !shouldRowReverse;
                }
                return pointsArray;
            })();
            for (let i = 0; i < points.length; i += 1) {
                MessageCaller.openMessage({
                    key: 'camera-preview',
                    content: `${i18n.lang.topbar.preview} ${i}/${points.length}`,
                    level: MessageLevel.LOADING,
                    duration: 20,
                });
                // eslint-disable-next-line no-await-in-loop
                const result = yield this.preview(points[i][0], points[i][1], i === points.length - 1);
                if (!result) {
                    this.isDrawing = false;
                    return;
                }
            }
            MessageCaller.openMessage({
                key: 'camera-preview',
                level: MessageLevel.SUCCESS,
                content: i18n.lang.device.completed,
                duration: 3,
            });
            callback();
        });
    }
    // x, y in mm
    takePictureAfterMoveTo(movementX, movementY) {
        return this.getPhotoAfterMoveTo(movementX, movementY);
    }
    isPreviewMode() {
        return this.isPreviewModeOn;
    }
    getCameraOffset() {
        return this.cameraOffset;
    }
    getCameraOffsetStandard() {
        return {
            X: this.cameraOffset.x,
            Y: this.cameraOffset.y,
            R: this.cameraOffset.angle,
            SX: this.cameraOffset.scaleRatioX,
            SY: this.cameraOffset.scaleRatioY,
        };
    }
    // helper functions
    retrieveCameraOffset() {
        return __awaiter(this, void 0, void 0, function* () {
            // End linecheck mode if needed
            try {
                if (this.isLineCheckEnabled) {
                    Progress.update('preview-mode-controller', { message: LANG.message.endingLineCheckMode });
                    yield deviceMaster.rawEndLineCheckMode();
                }
            }
            catch (error) {
                if (error.message === ErrorConstants.CONTROL_SOCKET_MODE_ERROR) {
                    // Device control is not in raw mode
                }
                else if (error.status === 'error' &&
                    error.error &&
                    error.error[0] === 'L_UNKNOWN_COMMAND') {
                    // Ghost control socket is not in raw mode, unknown command M172
                }
                else {
                    console.log('Unable to end line check mode', error);
                }
            }
            // cannot getDeviceSetting during RawMode. So we force to end it.
            try {
                Progress.update('preview-mode-controller', { message: LANG.message.endingRawMode });
                yield deviceMaster.endRawMode();
            }
            catch (error) {
                if (error.status === 'error' && error.error && error.error[0] === 'OPERATION_ERROR') {
                    // do nothing.
                    console.log('Not in raw mode right now');
                }
                else if (error.status === 'error' && error.error === 'TIMEOUT') {
                    console.log('Timeout has occur when end raw mode, reconnecting');
                    yield deviceMaster.reconnect();
                }
                else {
                    console.log(error);
                }
            }
            const borderless = BeamboxPreference.read('borderless') || false;
            const supportOpenBottom = Constant.addonsSupportList.openBottom.includes(BeamboxPreference.read('workarea'));
            const configName = supportOpenBottom && borderless ? 'camera_offset_borderless' : 'camera_offset';
            Progress.update('preview-mode-controller', { message: LANG.message.retrievingCameraOffset });
            const resp = yield deviceMaster.getDeviceSetting(configName);
            console.log(`Reading ${configName}\nResp = ${resp.value}`);
            resp.value = ` ${resp.value}`;
            this.cameraOffset = {
                x: Number(/ X:\s?(-?\d+\.?\d+)/.exec(resp.value)[1]),
                y: Number(/ Y:\s?(-?\d+\.?\d+)/.exec(resp.value)[1]),
                angle: Number(/R:\s?(-?\d+\.?\d+)/.exec(resp.value)[1]),
                scaleRatioX: Number((/SX:\s?(-?\d+\.?\d+)/.exec(resp.value) || /S:\s?(-?\d+\.?\d+)/.exec(resp.value))[1]),
                scaleRatioY: Number((/SY:\s?(-?\d+\.?\d+)/.exec(resp.value) || /S:\s?(-?\d+\.?\d+)/.exec(resp.value))[1]),
            };
            if (this.cameraOffset.x === 0 && this.cameraOffset.y === 0) {
                this.cameraOffset = {
                    x: Constant.camera.offsetX_ideal,
                    y: Constant.camera.offsetY_ideal,
                    angle: 0,
                    scaleRatioX: Constant.camera.scaleRatio_ideal,
                    scaleRatioY: Constant.camera.scaleRatio_ideal,
                };
            }
            console.log(`Got ${configName}`, this.cameraOffset);
        });
    }
    reset() {
        return __awaiter(this, void 0, void 0, function* () {
            this.currentDevice = null;
            this.isPreviewModeOn = false;
            this.isPreviewBlocked = false;
            this.cameraOffset = null;
            this.lastPosition = [0, 0];
            deviceMaster.disconnectCamera();
        });
    }
    constrainPreviewXY(x, y) {
        const workarea = BeamboxPreference.read('workarea');
        const { pxWidth: width, pxHeight, pxDisplayHeight } = getWorkarea(workarea);
        const height = pxDisplayHeight !== null && pxDisplayHeight !== void 0 ? pxDisplayHeight : pxHeight;
        const isDiodeEnabled = BeamboxPreference.read('enable-diode') &&
            Constant.addonsSupportList.hybridLaser.includes(workarea);
        const isBorderlessEnabled = BeamboxPreference.read('borderless');
        let maxWidth = width;
        let maxHeight = height;
        if (isDiodeEnabled) {
            maxWidth -= Constant.diode.safeDistance.X * Constant.dpmm;
            maxHeight -= Constant.diode.safeDistance.Y * Constant.dpmm;
        }
        else if (isBorderlessEnabled) {
            maxWidth -= Constant.borderless.safeDistance.X * Constant.dpmm;
        }
        const newX = Math.min(Math.max(x, this.getCameraOffset().x * Constant.dpmm), maxWidth);
        const newY = Math.min(Math.max(y, this.getCameraOffset().y * Constant.dpmm), maxHeight);
        return {
            x: newX,
            y: newY,
        };
    }
    // x, y in pixel
    getPhotoAfterMove(x, y) {
        const movementX = x / Constant.dpmm - this.getCameraOffset().x;
        const movementY = y / Constant.dpmm - this.getCameraOffset().y;
        return this.getPhotoAfterMoveTo(movementX, movementY);
    }
    // movementX, movementY in mm
    getPhotoAfterMoveTo(movementX, movementY) {
        return __awaiter(this, void 0, void 0, function* () {
            let feedrate = Math.min(Constant.camera.movementSpeed.x, Constant.camera.movementSpeed.y);
            const movement = {
                f: feedrate,
                x: movementX,
                y: movementY, // mm
            };
            if (BeamboxPreference.read('enable-diode') &&
                Constant.addonsSupportList.hybridLaser.includes(BeamboxPreference.read('workarea'))) {
                if (BeamboxPreference.read('preview_movement_speed_hl')) {
                    feedrate = BeamboxPreference.read('preview_movement_speed_hl');
                }
                else {
                    feedrate *= 0.6;
                }
            }
            else if (BeamboxPreference.read('preview_movement_speed')) {
                feedrate = BeamboxPreference.read('preview_movement_speed');
            }
            movement.f = feedrate; // firmware will used limited x, y speed still
            const selectRes = yield deviceMaster.select(this.currentDevice);
            if (!selectRes.success) {
                return null;
            }
            const control = yield deviceMaster.getControl();
            if (control.getMode() !== 'raw')
                yield deviceMaster.enterRawMode();
            yield deviceMaster.rawMove(movement);
            yield this.waitUntilEstimatedMovementTime(movementX, movementY);
            const imgUrl = yield this.getPhotoFromMachine();
            return imgUrl;
        });
    }
    // movementX, movementY in mm
    waitUntilEstimatedMovementTime(movementX, movementY) {
        return __awaiter(this, void 0, void 0, function* () {
            let feedrate = Math.min(Constant.camera.movementSpeed.x, Constant.camera.movementSpeed.y);
            if (BeamboxPreference.read('enable-diode') &&
                Constant.addonsSupportList.hybridLaser.includes(BeamboxPreference.read('workarea'))) {
                if (BeamboxPreference.read('preview_movement_speed_hl')) {
                    feedrate = BeamboxPreference.read('preview_movement_speed_hl');
                }
                else {
                    feedrate *= 0.6;
                }
            }
            else if (BeamboxPreference.read('preview_movement_speed')) {
                feedrate = BeamboxPreference.read('preview_movement_speed');
            }
            const moveDist = Math.hypot(this.lastPosition[0] - movementX, this.lastPosition[1] - movementY);
            let timeToWait = moveDist / feedrate;
            timeToWait *= 60000; // min => ms
            // wait for moving camera to take a stable picture, this value need to be optimized
            timeToWait *= 1.2;
            timeToWait += 100;
            this.lastPosition = [movementX, movementY];
            yield new Promise((resolve) => setTimeout(() => resolve(null), timeToWait));
        });
    }
    // just for getPhotoAfterMoveTo()
    getPhotoFromMachine() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const { imgBlob, needCameraCableAlert } = (_a = (yield deviceMaster.takeOnePicture())) !== null && _a !== void 0 ? _a : {};
            if (!imgBlob) {
                throw new Error(LANG.message.camera.ws_closed_unexpectly);
            }
            else if (needCameraCableAlert && !AlertConfig.read('skip_camera_cable_alert')) {
                const shouldContinue = yield new Promise((resolve) => {
                    Alert.popUp({
                        id: 'camera_cable_alert',
                        message: LANG.message.camera.camera_cable_unstable,
                        type: AlertConstants.SHOW_POPUP_WARNING,
                        checkbox: {
                            text: LANG.beambox.popup.dont_show_again,
                            callbacks: () => AlertConfig.write('skip_camera_cable_alert', true),
                        },
                        buttonLabels: [LANG.message.camera.abort_preview, LANG.message.camera.continue_preview],
                        callbacks: [() => resolve(false), () => resolve(true)],
                        primaryButtonIndex: 1,
                    });
                });
                if (!shouldContinue) {
                    this.end();
                    return null;
                }
            }
            const imgUrl = URL.createObjectURL(imgBlob);
            return imgUrl;
        });
    }
}
const instance = new PreviewModeController();
export default instance;
