import * as React from 'react';
import Controls from 'app/components/settings/Control';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
import UnitInput from 'app/widgets/Unit-Input-v2';
function Camera({ speed: { unit, decimal, defaultValue, getValue, }, speedHL: { unit: unitHL, decimal: decimalHL, defaultValue: defaultValueHL, getValue: getValueHL, }, enableCustomPreviewHeightOptions, updateBeamboxPreferenceChange, }) {
    const { lang } = i18n;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.camera),
        React.createElement(Controls, { label: lang.settings.preview_movement_speed },
            React.createElement(UnitInput, { id: "preview-input", unit: unit, min: 3, max: 300, decimal: decimal, defaultValue: defaultValue, getValue: getValue, className: { half: true } })),
        React.createElement(Controls, { label: lang.settings.preview_movement_speed_hl },
            React.createElement(UnitInput, { id: "diode-preview-input", unit: unitHL, min: 3, max: 300, decimal: decimalHL, defaultValue: defaultValueHL, getValue: getValueHL, className: { half: true } })),
        React.createElement(SelectControl, { id: "set-enable-custom-preview-height", label: lang.settings.custom_preview_height, options: enableCustomPreviewHeightOptions, onChange: (e) => updateBeamboxPreferenceChange('enable-custom-preview-height', e.target.value) })));
}
export default Camera;
