var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import changeWorkarea from 'app/svgedit/operations/changeWorkarea';
import constant from 'app/actions/beambox/constant';
import history from 'app/svgedit/history/history';
import i18n from 'helpers/i18n';
import LayerModule, { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import LayerPanelController from 'app/views/beambox/Right-Panels/contexts/LayerPanelController';
import presprayArea from 'app/actions/canvas/prespray-area';
import rotaryAxis from 'app/actions/canvas/rotary-axis';
import symbolMaker from 'helpers/symbol-maker';
import workareaManager from 'app/svgedit/workarea';
import { changeBeamboxPreferenceValue } from 'app/svgedit/history/beamboxPreferenceCommand';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { toggleFullColorAfterWorkareaChange } from 'helpers/layer/layer-config-helper';
import setSvgContent from './setSvgContent';
let svgCanvas;
let svgedit;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
    svgedit = globalSVG.Edit;
});
export const importBvgString = (str) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e;
    const batchCmd = new history.BatchCommand('Import Bvg');
    svgCanvas.clearSelection();
    const setContentCmd = setSvgContent(str.replace(/STYLE>/g, 'style>').replace(/<STYLE/g, '<style'));
    if (!setContentCmd) {
        alertCaller.popUp({
            id: 'load SVG fail',
            type: alertConstants.SHOW_POPUP_WARNING,
            message: 'Error: Unable to load SVG data',
        });
        return;
    }
    if (!setContentCmd.isEmpty())
        batchCmd.addSubCommand(setContentCmd);
    const currentWorkarea = beamboxPreference.read('workarea');
    // loadFromString will lose data-xform and data-wireframe of `use` so set it back here
    if (typeof str === 'string') {
        const workarea = document.getElementById('workarea');
        const tmp = str.substr(str.indexOf('<use')).split('<use');
        for (let i = 1; i < tmp.length; i += 1) {
            tmp[i] = tmp[i].substring(0, tmp[i].indexOf('/>'));
            const id = (_a = tmp[i].match(/id="(svg_\d+)"/)) === null || _a === void 0 ? void 0 : _a[1];
            const elem = document.getElementById(id);
            if (elem) {
                const xform = (_b = tmp[i].match(/data-xform="([^"]*)"/)) === null || _b === void 0 ? void 0 : _b[1];
                if (xform)
                    elem.setAttribute('data-xform', xform);
                const wireframe = (_c = tmp[i].match(/data-wireframe="([a-z]*)"/)) === null || _c === void 0 ? void 0 : _c[1];
                if (wireframe)
                    elem === null || elem === void 0 ? void 0 : elem.setAttribute('data-wireframe', String(wireframe === 'true'));
            }
        }
        let match = str.match(/data-rotary_mode="([^"]*)"/);
        if (match) {
            let rotaryMode = match[1];
            if (rotaryMode === 'true')
                rotaryMode = '1';
            let cmd;
            if (constant.addonsSupportList.rotary.includes(currentWorkarea)) {
                cmd = changeBeamboxPreferenceValue('rotary_mode', parseInt(rotaryMode, 10), {
                    parentCmd: batchCmd,
                });
            }
            else {
                cmd = changeBeamboxPreferenceValue('rotary_mode', 0, { parentCmd: batchCmd });
                beamboxPreference.write('rotary_mode', 0);
            }
            cmd.onAfter = () => {
                rotaryAxis.toggleDisplay();
                workareaManager.setWorkarea(beamboxPreference.read('workarea'));
            };
            rotaryAxis.toggleDisplay();
        }
        const engraveDpi = (_d = str.match(/data-engrave_dpi="([a-zA-Z]+)"/)) === null || _d === void 0 ? void 0 : _d[1];
        if (engraveDpi) {
            changeBeamboxPreferenceValue('engrave_dpi', engraveDpi, { parentCmd: batchCmd });
        }
        else {
            changeBeamboxPreferenceValue('engrave_dpi', 'medium', { parentCmd: batchCmd });
        }
        if (constant.addonsSupportList.hybridLaser.includes(currentWorkarea)) {
            match = str.match(/data-en_diode="([a-zA-Z]+)"/);
            if (match && match[1]) {
                if (match[1] === 'true') {
                    changeBeamboxPreferenceValue('enable-diode', true, { parentCmd: batchCmd });
                }
                else {
                    changeBeamboxPreferenceValue('enable-diode', false, { parentCmd: batchCmd });
                }
            }
        }
        if (constant.addonsSupportList.autoFocus.includes(currentWorkarea)) {
            match = str.match(/data-en_af="([a-zA-Z]+)"/);
            if (match && match[1]) {
                if (match[1] === 'true') {
                    changeBeamboxPreferenceValue('enable-autofocus', true, { parentCmd: batchCmd });
                }
                else {
                    changeBeamboxPreferenceValue('enable-autofocus', false, { parentCmd: batchCmd });
                }
            }
        }
        LayerPanelController.updateLayerPanel();
        match = str.match(/data-zoom="[0-9.]+"/);
        if (match) {
            const zoom = parseFloat(match[0].substring(11, match[0].length - 1));
            workareaManager.zoom(zoom);
        }
        match = str.match(/data-left="[-0-9]+"/);
        const { width, height, zoomRatio } = workareaManager;
        if (match) {
            let left = parseInt(match[0].substring(11, match[0].length - 1), 10);
            left = Math.round((left + width) * zoomRatio);
            workarea.scrollLeft = left;
        }
        match = str.match(/data-top="[-0-9]+"/);
        if (match) {
            let top = parseInt(match[0].substring(10, match[0].length - 1), 10);
            top = Math.round((top + height) * zoomRatio);
            workarea.scrollTop = top;
        }
    }
    const { lang } = i18n;
    let newWorkarea = currentWorkarea;
    if (!modelsWithModules.has(currentWorkarea)) {
        const hasPrintingLayer = ((_e = document
            .getElementById('svgcontent')) === null || _e === void 0 ? void 0 : _e.querySelectorAll(`g.layer[data-module="${LayerModule.PRINTER}"]`).length) > 0;
        if (hasPrintingLayer) {
            const res = yield new Promise((resolve) => {
                alertCaller.popUp({
                    id: 'ask-change-workarea',
                    message: lang.layer_module.notification.importedDocumentContainsPrinting,
                    buttonType: alertConstants.YES_NO,
                    onYes: () => resolve(true),
                    onNo: () => resolve(false),
                });
            });
            if (res) {
                newWorkarea = 'ado1';
            }
            else {
                alertCaller.popUp({
                    type: alertConstants.SHOW_POPUP_INFO,
                    message: lang.layer_module.notification.printingLayersCoverted,
                });
            }
        }
    }
    console.log('Change workarea to', newWorkarea);
    const changeWorkareaCmd = changeWorkarea(newWorkarea, { toggleModule: false });
    batchCmd.addSubCommand(changeWorkareaCmd);
    const defs = svgedit.utilities.findDefs();
    const { parentNode, nextSibling } = defs;
    defs.remove();
    batchCmd.addSubCommand(new history.RemoveElementCommand(defs, nextSibling, parentNode));
    svgedit.utilities.moveDefsOutfromSvgContent();
    const newDefs = svgedit.utilities.findDefs();
    batchCmd.addSubCommand(new history.InsertElementCommand(newDefs));
    const postImportBvgString = () => __awaiter(void 0, void 0, void 0, function* () {
        const workarea = beamboxPreference.read('workarea');
        if (!modelsWithModules.has(workarea)) {
            toggleFullColorAfterWorkareaChange();
        }
        yield symbolMaker.reRenderAllImageSymbol();
        presprayArea.togglePresprayArea();
        LayerPanelController.setSelectedLayers([]);
    });
    yield postImportBvgString();
    batchCmd.onAfter = postImportBvgString;
    svgCanvas.addCommandToHistory(batchCmd);
});
const importBvg = (file) => __awaiter(void 0, void 0, void 0, function* () {
    yield new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = (evt) => __awaiter(void 0, void 0, void 0, function* () {
            const str = evt.target.result;
            yield importBvgString(str);
            resolve();
        });
        reader.readAsText(file);
    });
    svgCanvas.setHasUnsavedChange(false);
});
export default importBvg;
