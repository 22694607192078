var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-await-in-loop */
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputNumber, Modal, Row } from 'antd';
import alertCaller from 'app/actions/alert-caller';
import alertConstants from 'app/constants/alert-constants';
import deviceMaster from 'helpers/device-master';
import isDev from 'helpers/is-dev';
import progressCaller from 'app/actions/progress-caller';
import useI18n from 'helpers/useI18n';
import { setEditingInput, setStopEditingInput } from 'app/widgets/InputKeyWrapper';
import styles from './Calibrate.module.scss';
export var Mode;
(function (Mode) {
    Mode[Mode["UNKNOWN"] = 0] = "UNKNOWN";
    Mode[Mode["MANUAL"] = 1] = "MANUAL";
    Mode[Mode["FETCH"] = 2] = "FETCH";
})(Mode || (Mode = {}));
const PROGRESS_ID = 'fish-eye-calibration';
const Calibrate = ({ mode: initMode = Mode.UNKNOWN, onClose, onNext }) => {
    const lang = useI18n();
    const isDevMode = isDev();
    const [mode, setMode] = useState(isDevMode ? initMode : Mode.FETCH);
    const [img, setImg] = useState(null);
    const [imgs, setImgs] = useState([]);
    const imgsRef = useRef(imgs);
    const [form] = Form.useForm();
    const combineImgs = (topImgUrl, bottomImgUrl) => __awaiter(void 0, void 0, void 0, function* () {
        const topImg = new Image();
        const bottomImg = new Image();
        const loadTopImg = new Promise((resolve) => {
            topImg.onload = () => resolve();
            topImg.src = topImgUrl;
        });
        const loadBotImg = new Promise((resolve) => {
            bottomImg.onload = () => resolve();
            bottomImg.src = bottomImgUrl;
        });
        yield Promise.all([loadTopImg, loadBotImg]);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = topImg.width;
        canvas.height = topImg.height;
        const topHeight = Math.floor(topImg.height / 2);
        const bottomHeight = topImg.height - topHeight;
        ctx.drawImage(topImg, 0, 0, topImg.width, topHeight, 0, 0, topImg.width, topHeight);
        ctx.drawImage(bottomImg, 0, topHeight, topImg.width, bottomHeight, 0, topHeight, topImg.width, bottomHeight);
        return new Promise((resolve) => canvas.toBlob(resolve));
    });
    const takeHalfPicture = (isTopHalf = false) => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: 'tTaking Picture' });
        try {
            const res = yield deviceMaster.takeOnePicture();
            if (!res)
                alertCaller.popUpError({ message: 'tUnable to get image' });
            const newImgUrl = URL.createObjectURL(res.imgBlob);
            const newBlob = yield (isTopHalf
                ? combineImgs(newImgUrl, img.url)
                : combineImgs(img.url, newImgUrl));
            setImg({ blob: newBlob, url: URL.createObjectURL(newBlob) });
            URL.revokeObjectURL(newImgUrl);
            URL.revokeObjectURL(img.url);
        }
        finally {
            progressCaller.popById(PROGRESS_ID);
        }
    });
    const handleTakePicture = () => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({ id: PROGRESS_ID, message: 'tTaking Picture' });
        try {
            const res = yield deviceMaster.takeOnePicture();
            if (!res)
                alertCaller.popUpError({ message: 'tUnable to get image' });
            else
                setImg({ blob: res.imgBlob, url: URL.createObjectURL(res.imgBlob) });
        }
        finally {
            progressCaller.popById(PROGRESS_ID);
        }
    });
    const handleAddImage = () => __awaiter(void 0, void 0, void 0, function* () {
        const height = form.getFieldValue('height');
        setImgs([...imgs, { height, blob: img.blob, url: img.url }]);
        handleTakePicture();
        form.setFieldValue('height', height + 1);
    });
    const fetchCalibImage = () => __awaiter(void 0, void 0, void 0, function* () {
        const newImages = [];
        const startHeight = -19;
        const endHeight = 32;
        const total = endHeight - startHeight + 1;
        let step = parseInt(window === null || window === void 0 ? void 0 : window.localStorage.getItem('fisheye-cali-step'), 10);
        step = Number.isNaN(step) ? 4 : step;
        progressCaller.openSteppingProgress({
            id: PROGRESS_ID,
            message: lang.calibration.downloading_pictures,
            percentage: 0,
        });
        try {
            for (let height = startHeight; height <= endHeight; height += 1) {
                // eslint-disable-next-line no-continue
                if (height !== startHeight && (endHeight - height) % step !== 0)
                    continue;
                const heightStr = height.toFixed(1);
                progressCaller.update(PROGRESS_ID, {
                    message: lang.calibration.downloading_pictures,
                    percentage: Math.round(100 * ((height - startHeight + 1) / total)),
                });
                const bottomImg = (yield deviceMaster.fetchCameraCalibImage(`pic_${heightStr}_top_left.jpg`));
                const bottomImgUrl = URL.createObjectURL(bottomImg);
                if (height < 20)
                    newImages.push({ height, url: URL.createObjectURL(bottomImg), blob: bottomImg });
                else {
                    const topImg = (yield deviceMaster.fetchCameraCalibImage(`pic_${heightStr}_bottom_right.jpg`));
                    const topImgUrl = URL.createObjectURL(topImg);
                    const combined = yield combineImgs(topImgUrl, bottomImgUrl);
                    newImages.push({ height, url: URL.createObjectURL(combined), blob: combined });
                    URL.revokeObjectURL(topImgUrl);
                }
                URL.revokeObjectURL(bottomImgUrl);
            }
        }
        catch (err) {
            let errMsg = err.message || err.error;
            if (!errMsg) {
                try {
                    errMsg = JSON.stringify(err);
                }
                catch (_a) {
                    errMsg = 'Unknown Error';
                }
            }
            console.error('Failed to download pictures', err);
            alertCaller.popUpError({ message: `${lang.calibration.failed_to_download_pictures}: ${errMsg}` });
            onClose(false);
        }
        finally {
            progressCaller.popById(PROGRESS_ID);
        }
        setImgs(newImages);
        if (!isDevMode)
            onNext(newImages);
    });
    useEffect(() => {
        deviceMaster.connectCamera();
        return () => deviceMaster.disconnectCamera();
    }, []);
    useEffect(() => {
        if (mode === Mode.MANUAL)
            handleTakePicture();
        else if (mode === Mode.FETCH)
            fetchCalibImage();
        else {
            alertCaller.popUp({
                message: '請問是否要使用機器中預拍的照片',
                buttonType: alertConstants.YES_NO,
                onYes: () => setMode(Mode.FETCH),
                onNo: () => setMode(Mode.MANUAL),
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);
    useEffect(() => {
        imgsRef.current = imgs;
    }, [imgs]);
    useEffect(() => () => {
        if (imgsRef.current)
            imgsRef.current.forEach(({ url }) => URL.revokeObjectURL(url));
    });
    const removeImg = (i) => {
        setImgs(imgs.filter((_, index) => index !== i));
    };
    const btns = mode === Mode.MANUAL
        ? [
            React.createElement(Button, { onClick: () => takeHalfPicture(true), key: "take-upper-picture" }, "tTake Upper Picture"),
            React.createElement(Button, { onClick: () => takeHalfPicture(false), key: "take-lower-picture" }, "tTake Lower Picture"),
            React.createElement(Button, { onClick: handleTakePicture, key: "take-picture" }, "tTake Picture"),
            React.createElement(Button, { onClick: handleAddImage, key: "add-image" }, "tAdd Image"),
            React.createElement(Button, { type: "primary", disabled: imgs.length < 1, onClick: () => onNext(imgs), key: "next" }, lang.buttons.next),
        ]
        : [
            React.createElement(Button, { type: "primary", disabled: imgs.length < 1, onClick: () => onNext(imgs), key: "next" }, lang.buttons.next),
        ];
    return (React.createElement(Modal, { open: true, centered: true, onCancel: () => onClose(false), title: "tCalibrate Rotate and Scale", footer: btns, maskClosable: false },
        React.createElement("div", null,
            "Please put the chessboard at left top corner",
            mode === Mode.MANUAL && (React.createElement(Row, null,
                React.createElement(Col, { span: 12 },
                    React.createElement("div", { className: styles['img-container'] },
                        React.createElement("img", { src: img === null || img === void 0 ? void 0 : img.url }))),
                React.createElement(Col, { span: 12 },
                    React.createElement(Form, { size: "small", className: "controls", form: form },
                        React.createElement(Form.Item, { name: "height", label: "tHeight", initialValue: 3 },
                            React.createElement(InputNumber, { type: "number", addonAfter: "mm", step: 1, max: 32, min: -19, onFocus: setEditingInput, onBlur: setStopEditingInput, onKeyUp: (e) => e.stopPropagation(), onKeyDown: (e) => e.stopPropagation() })))))),
            React.createElement(Row, null,
                "Current Images:",
                React.createElement("div", { className: classNames(styles.imgs, { [styles.full]: mode === Mode.FETCH }) }, imgs.map(({ url, height }, i) => (React.createElement("div", { className: styles.container, key: url },
                    React.createElement("img", { src: url }),
                    React.createElement("div", null,
                        height,
                        "mm"),
                    React.createElement("button", { type: "button", onClick: () => removeImg(i) }, "remove")))))))));
};
export default Calibrate;
