import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, InputNumber, Modal, Row } from 'antd';
const RotationParameters3DPanel = ({ initialParams, onApply, onClose, onSave, }) => {
    const onChangeApplyTimeout = useRef(null);
    const [params, setParams] = useState(initialParams || {
        rx: 0,
        ry: 0,
        rz: 0,
        sh: 6,
        ch: 182,
        dh: 0,
        tx: 0,
        ty: 0,
    });
    useEffect(() => {
        if (onChangeApplyTimeout.current) {
            clearTimeout(onChangeApplyTimeout.current);
        }
        onChangeApplyTimeout.current = setTimeout(() => {
            onApply(params);
        }, 1000);
    }, [onApply, params]);
    const handleValueChange = (key, value) => {
        setParams(Object.assign(Object.assign({}, params), { [key]: value }));
    };
    const handleApply = useCallback(() => {
        onApply(params);
    }, [params, onApply]);
    const handleSave = useCallback(() => {
        onClose();
        onSave(params);
    }, [params, onSave, onClose]);
    return (React.createElement(Modal, { mask: false, width: 242, open: true, style: { right: 0, position: 'absolute' }, maskClosable: false, onCancel: onClose, title: "3D Rotation Adjustment", footer: [
            React.createElement(Button, { key: "apply", onClick: handleApply }, "Apply"),
            React.createElement(Button, { key: "download", type: "primary", onClick: handleSave }, "Save"),
        ] },
        React.createElement(Row, { gutter: [8, 8] },
            React.createElement(Col, { span: 8 }, "rx"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { step: 0.1, precision: 2, value: params.rx, onChange: (val) => handleValueChange('rx', val) })),
            React.createElement(Col, { span: 8 }, "ry"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { step: 0.1, precision: 2, value: params.ry, onChange: (val) => handleValueChange('ry', val) })),
            React.createElement(Col, { span: 8 }, "rz"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { step: 0.1, precision: 2, value: params.rz, onChange: (val) => handleValueChange('rz', val) })),
            React.createElement(Col, { span: 8 }, "tx"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { step: 1, precision: 0, value: params.tx, onChange: (val) => handleValueChange('tx', val) })),
            React.createElement(Col, { span: 8 }, "ty"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { step: 1, precision: 0, value: params.ty, onChange: (val) => handleValueChange('ty', val) })),
            React.createElement(Col, { span: 8 }, "dh"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { step: 0.1, precision: 1, value: params.dh, onChange: (val) => handleValueChange('dh', val) })),
            React.createElement(Col, { span: 8 }, "sh"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { step: 0.1, value: params.sh, onChange: (val) => handleValueChange('sh', val) })),
            React.createElement(Col, { span: 8 }, "ch"),
            React.createElement(Col, { span: 16 },
                React.createElement(InputNumber, { value: params.ch, onChange: (val) => handleValueChange('ch', val) })))));
};
export default RotationParameters3DPanel;
