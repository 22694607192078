const lang = {
    general: {
        wait: 'Memproses, harap tunggu',
        choose_folder: 'Pilih Folder',
    },
    buttons: {
        next: 'Berikutnya',
        back: 'Kembali',
        done: 'Selesai',
        back_to_beam_studio: 'Kembali ke Beam Studio',
    },
    topbar: {
        untitled: 'Tanpa Judul',
        titles: {
            settings: 'Pengaturan',
        },
        export: 'Ekspor',
        preview: 'PRATINJAU',
        preview_title: 'Pratinjau',
        curve_engrave: '3D Kurva',
        task_preview: 'Pratinjau Jalur',
        frame_task: 'Jalankan Bingkai',
        borderless: '(BUKA BAWAH)',
        tag_names: {
            rect: 'Persegi Panjang',
            ellipse: 'Oval',
            path: 'Jalur',
            polygon: 'Poligon',
            image: 'Gambar',
            text: 'Teks',
            text_path: 'Teks pada Jalur',
            line: 'Garis',
            g: 'Grup',
            multi_select: 'Beberapa Objek',
            use: 'Objek Impor',
            svg: 'Objek SVG',
            dxf: 'Objek DXF',
            no_selection: 'Tidak Ada Pilihan',
        },
        alerts: {
            start_preview_timeout: '#803 Timeout telah terjadi saat memulai mode pratinjau. Harap mulai ulang mesin Anda atau Beam Studio. Jika kesalahan ini berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
            fail_to_start_preview: '#803 Gagal memulai mode pratinjau. Harap mulai ulang mesin Anda atau Beam Studio. Jika kesalahan ini berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
            fail_to_connect_with_camera: '#803 Gagal membuat koneksi dengan kamera mesin. Harap mulai ulang mesin Anda atau Beam Studio. Jika kesalahan ini berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">panduan ini</a>.',
            add_content_first: 'Silakan tambahkan objek terlebih dahulu',
            headtype_mismatch: 'Modul yang tidak cocok terdeteksi. ',
            headtype_none: 'Modul tidak terdeteksi. ',
            headtype_unknown: 'Modul tidak dikenali terdeteksi. ',
            install_correct_headtype: 'Harap pasang modul laser diode 10W/20W dengan benar untuk mengaktifkan laser rendah agar bingkai dapat berjalan.',
            door_opened: 'Harap tutup pintu penutup untuk mengaktifkan laser rendah agar bingkai dapat berjalan.',
            fail_to_get_door_status: 'Harap pastikan pintu penutup tertutup untuk mengaktifkan laser rendah agar bingkai dapat berjalan.',
            QcleanScene: 'Apakah Anda ingin menghapus gambar?<br/>Ini juga akan menghapus riwayat undo Anda!',
            power_too_high: 'DAYA TERLALU TINGGI',
            power_too_high_msg: 'Menggunakan daya laser yang lebih rendah (di bawah 70%) akan memperpanjang masa pakai tabung laser.\nMasukkan "SAYA MENGERTI" untuk melanjutkan.',
            power_too_high_confirm: 'SAYA MENGERTI',
        },
        hint: {
            polygon: 'Tekan tombol + / - untuk menambah / mengurangi sisi.',
        },
        menu: {
            preferences: 'Preferensi',
            hide: 'Sembunyikan Beam Studio',
            hideothers: 'Sembunyikan Lainnya',
            service: 'Layanan',
            quit: 'Keluar',
            window: 'Jendela',
            minimize: 'Minimalkan',
            close: 'Tutup Jendela',
            file: 'Berkas',
            edit: 'Sunting',
            help: 'Bantuan',
            open: 'Buka',
            samples: 'Contoh',
            import_hello_beamo: 'Contoh beamo',
            import_hello_beambox: 'Contoh beambox',
            import_material_testing_old: 'Uji Pahatan Bahan - Klasik',
            import_material_testing_simple_cut: 'Uji Pemotongan Bahan - Sederhana',
            import_material_testing_cut: 'Uji Pemotongan Bahan',
            import_material_testing_engrave: 'Uji Ukiran Bahan',
            import_material_testing_line: 'Uji Garis Bahan',
            import_material_printing_test: 'Uji Cetak Bahan',
            import_ador_laser_example: 'Contoh Laser Ador',
            import_ador_printing_example_single: 'Contoh Cetak Ador - Satu Warna',
            import_ador_printing_example_full: 'Contoh Cetak Ador - Warna Penuh',
            import_acrylic_focus_probe: 'Probe Fokus Akrilik - 3mm',
            export_to: 'Ekspor Ke...',
            export_flux_task: 'Tugas FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Simpan',
            save_as: 'Simpan Sebagai...',
            save_to_cloud: 'Simpan ke Awan',
            about_beam_studio: 'Tentang Beam Studio',
            undo: 'Batalkan',
            redo: 'Ulangi',
            cut: 'Potong',
            copy: 'Salin',
            paste: 'Tempel',
            paste_in_place: 'Tempel di Tempat',
            group: 'Kelompokkan',
            ungroup: 'Lepas Kelompok',
            delete: 'Hapus',
            duplicate: 'Duplikat',
            offset: 'Ofset',
            scale: 'Skala',
            rotate: 'Putar',
            reset: 'Atur Ulang',
            align_center: 'Rata Tengah',
            photo_edit: 'Gambar',
            svg_edit: 'SVG',
            path: 'Jalur',
            decompose_path: 'Dekomposisi',
            object: 'Objek',
            layer_setting: 'Lapisan',
            layer_color_config: 'Pengaturan Warna',
            image_sharpen: 'Tajamkan',
            image_crop: 'Potong',
            image_invert: 'Balik',
            image_stamp: 'Cap',
            image_vectorize: 'Vektorisasi',
            image_curve: 'Kurva',
            align_to_edges: 'Rapat ke Simpul',
            document_setting: 'Pengaturan Dokumen',
            document_setting_short: 'Dokumen',
            clear_scene: 'File baru',
            machines: 'Mesin',
            add_new_machine: 'Setup Mesin',
            help_center: 'Pusat Bantuan',
            show_gesture_tutorial: 'Pengenalan Gerakan Tangan',
            show_start_tutorial: 'Tampilkan Tutorial Mulai',
            show_ui_intro: 'Tampilkan Pengenalan Antarmuka',
            questionnaire: 'Kuesioner Umpan Balik',
            change_logs: 'Log Perubahan',
            contact: 'Hubungi Kami',
            tutorial: 'Mulai Tutorial Pencetakan Delta Family',
            design_market: 'Pasar Desain',
            forum: 'Forum Komunitas',
            software_update: 'Pembaruan Perangkat Lunak',
            bug_report: 'Laporan Bug',
            dashboard: 'Dasbor',
            machine_info: 'Info Mesin',
            network_testing: 'Tes Pengaturan Jaringan',
            commands: 'Perintah',
            update_firmware: 'Perbarui Firmware',
            using_beam_studio_api: 'Menggunakan Beam Studio API',
            set_as_default: 'Atur sebagai Default',
            calibrate_beambox_camera: 'Kalibrasi Kamera',
            calibrate_printer_module: 'Kalibrasi Modul Pencetakan',
            calibrate_ir_module: 'Kalibrasi Modul Inframerah',
            calibrate_beambox_camera_borderless: 'Kalibrasi Kamera (Tanpa Bingkai Bawah)',
            calibrate_diode_module: 'Kalibrasi Modul Laser Diode',
            manage_account: 'Kelola Akun Saya',
            sign_in: 'Masuk',
            sign_out: 'Keluar',
            account: 'Akun',
            my_account: 'Akun Saya',
            download_log: 'Unduh Log',
            download_log_canceled: 'Unduhan log dibatalkan',
            download_log_error: 'Terjadi kesalahan yang tidak diketahui, silakan coba lagi nanti',
            keyboard_shortcuts: 'Pintasan keyboard',
            log: {
                network: 'Jaringan',
                hardware: 'Perangkat Keras',
                discover: 'Temukan',
                usb: 'USB',
                usblist: 'Daftar USB',
                camera: 'Kamera',
                cloud: 'Cloud',
                player: 'Pemutar',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Tampilan',
            zoom_in: 'Perbesar',
            zoom_out: 'Perkecil',
            fit_to_window: 'Sesuaikan Ukuran Jendela',
            zoom_with_window: 'Otomatis Sesuaikan Ukuran Jendela',
            borderless_mode: 'Mode Tanpa Batas',
            show_grids: 'Tampilkan Grid',
            show_rulers: 'Tampilkan Penggaris',
            show_layer_color: 'Gunakan Warna Lapisan',
            anti_aliasing: 'Anti-Aliasing',
            disassemble_use: 'Pisahkan',
        },
        select_machine: 'Pilih mesin',
    },
    support: {
        no_webgl: 'WebGL tidak didukung. Harap gunakan perangkat lain.',
        no_vcredist: 'Mohon instal Visual C++ Redistributable 2015<br/>Dapat diunduh di flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Terjadi kesalahan yang tidak diketahui. Harap mulai ulang Beam Studio dan mesin.',
        OPERATION_ERROR: '[OE] Terjadi konflik status, harap coba ulangi tindakan.',
        SUBSYSTEM_ERROR: '[SE] Terjadi kesalahan saat firmware mesin mengeksekusi tugas. Harap mulai ulang mesin.',
        UNKNOWN_COMMAND: '[UC] Harap perbarui Firmware Perangkat',
    },
    device_selection: {
        no_beambox: '#801 Kami tidak dapat menemukan mesin Anda di jaringan.\nHarap ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">panduan</a> untuk mengatasi masalah koneksi.',
        no_device_web: "#801 Periksa status mesin Anda, atau klik 'Setup Mesin' di bawah untuk mengatur mesin.",
    },
    update: {
        update: 'Perbarui',
        release_note: 'Catatan Rilis:',
        firmware: {
            caption: 'Pembaruan Firmware untuk mesin tersedia',
            message_pattern_1: '"%s" siap untuk pembaruan firmware.',
            message_pattern_2: '%s Firmware v%s sekarang tersedia - Anda memiliki v%s.',
            latest_firmware: {
                caption: 'Pembaruan Firmware Mesin',
                message: 'Anda memiliki Firmware Mesin terbaru',
                still_update: 'PERBARUI',
                cant_get_latest: 'Tidak dapat mendapatkan informasi firmware terbaru.',
            },
            confirm: 'UNGGAH',
            upload_file: 'Firmware upload (*.bin / *.fxfw)',
            update_success: 'Pembaruan firmware berhasil diunggah',
            update_fail: '#822 Pembaruan Gagal',
            too_old_for_web: 'Versi firmware mesin Anda saat ini adalah v%s.\nJika Anda ingin menggunakan versi online dari Beam Studio, harap perbarui firmware mesin ke versi terbaru.',
            force_update_message: '#814 Harap perbarui mesin Anda ke versi firmware terbaru.',
            firmware_too_old_update_by_sdcard: 'Versi firmware terlalu lama. Harap perbarui firmware menggunakan kartu SD.',
        },
        software: {
            checking: 'Memeriksa Pembaruan',
            switch_version: 'Ganti Versi',
            check_update: 'Periksa Pembaruan',
            caption: 'Pembaruan Perangkat Lunak Beam Studio tersedia',
            downloading: 'Mengunduh pembaruan di latar belakang, Anda dapat mengeklik "OK" untuk melanjutkan pekerjaan Anda.',
            install_or_not: '%s siap untuk diperbarui. Apakah Anda ingin memulai ulang dan memperbarui sekarang?',
            switch_or_not: '%s siap untuk beralih. Apakah Anda ingin memulai ulang dan beralih sekarang?',
            available_update: 'Beam Studio v%s tersedia sekarang. Anda memiliki v%s. Apakah Anda ingin mengunduh pembaruan?',
            available_switch: 'Beam Studio v%s tersedia sekarang. Anda memiliki v%s. Apakah Anda ingin beralih ke versi ini?',
            not_found: 'Anda menggunakan versi Beam Studio terbaru.',
            no_response: 'Gagal terhubung ke server, harap periksa pengaturan jaringan Anda.',
            switch_version_not_found: 'Versi yang dapat diubah tidak ditemukan.',
            yes: 'Ya',
            no: 'Tidak',
            update_for_ador: 'Versi perangkat lunak saat ini %s tidak kompatibel, harap unduh versi terbaru dari Beam Studio untuk Ador.',
        },
        updating: 'Memperbarui...',
        skip: 'Lewati Versi Ini',
        preparing: 'Mempersiapkan...',
        later: 'NANTI',
        download: 'PEMBARUAN ONLINE',
        cannot_reach_internet: '#823 Server tidak dapat dijangkau<br/>Harap periksa koneksi internet',
        install: 'PASANG',
        upload: 'UNGGAH',
    },
    topmenu: {
        version: 'Versi',
        credit: 'Beam Studio dimungkinkan oleh proyek sumber terbuka <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> dan perangkat lunak sumber terbuka lainnya <a target="_blank" href="https://flux3dp.com/credits/">sumber terbuka</a>.',
        ok: 'OK',
        file: {
            label: 'File',
            import: 'Impor',
            save_fcode: 'Ekspor Tugas FLUX',
            save_scene: 'Simpan Adegan',
            save_svg: 'Ekspor SVG',
            save_png: 'Ekspor PNG',
            save_jpg: 'Ekspor JPG',
            converting: 'Mengkonversi ke Gambar...',
            all_files: 'Semua File',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Adegan Beam Studio',
            fcode_files: 'Kode FLUX',
            clear_recent: 'Hapus Baru Dibuka',
            path_not_exit: 'Jalur ini sepertinya tidak ada lagi di disk.',
        },
        device: {
            download_log_canceled: 'Log unduhan dibatalkan',
            download_log_error: 'Terjadi kesalahan yang tidak diketahui, silakan coba lagi nanti',
            log: {
                usblist: 'Daftar USB',
            },
            network_test: 'Tes Jaringan',
        },
    },
    initialize: {
        next: 'Berikutnya',
        start: 'Mulai',
        skip: 'Lewati',
        cancel: 'Batal',
        confirm: 'Konfirmasi',
        connect: 'Sambungkan',
        back: 'Kembali',
        retry: 'Coba lagi',
        no_machine: 'Saya tidak memiliki mesin sekarang.',
        select_language: 'Pilih Bahasa',
        select_machine_type: 'Pilih Jenis Mesin Anda',
        select_connection_type: 'Bagaimana Anda ingin menyambung?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Jaringan Kabel',
            ether2ether: 'Sambungan Langsung',
            usb: 'Sambungan USB',
        },
        connect_wifi: {
            title: 'Menyambung ke Wi-Fi',
            tutorial1: '1. Pergi ke Panel Sentuh > Klik "MESIN" > Klik "Jaringan" > "Sambung ke WiFi".',
            tutorial1_ador: '1. Pergi ke Panel Sentuh > Klik "MESIN" > Klik "Jaringan" > "Sambung ke WiFi".',
            tutorial2: '2. Pilih dan sambung Wi-Fi pilihan Anda.',
            what_if_1: 'Bagaimana jika saya tidak melihat Wi-Fi saya?',
            what_if_1_content: '1. Mode enkripsi Wi-Fi harus WPA2 atau tanpa kata sandi.\n2. Mode enkripsi dapat diatur di antarmuka administrasi router Wi-Fi. Jika router tidak mendukung WPA2 dan Anda membutuhkan bantuan memilih router yang tepat, silakan hubungi Dukungan FLUX.',
            what_if_2: 'Bagaimana jika saya tidak melihat Wi-Fi sama sekali?',
            what_if_2_content: '1. Pastikan stik Wi-Fi terpasang dengan benar.\n2. Jika tidak ada Alamat MAC jaringan nirkabel di layar sentuh, silakan hubungi Dukungan FLUX.\n3. Saluran Wi-Fi harus 2,4Ghz (5Ghz tidak didukung).',
        },
        connect_wired: {
            title: 'Menyambung ke Jaringan Kabel',
            tutorial1: '1. Sambungkan mesin dengan router Anda.',
            tutorial2: '2. Tekan "MESIN" > "Jaringan" untuk mendapatkan IP jaringan kabel.',
            tutorial2_ador: '2. Tekan "MESIN" > "Jaringan" untuk mendapatkan IP jaringan kabel.',
            what_if_1: 'Bagaimana jika IP kosong?',
            what_if_1_content: '1. Pastikan kabel Ethernet terpasang dengan benar.\n2. Jika tidak ada Alamat MAC jaringan kabel di layar sentuh, silakan hubungi Dukungan FLUX.',
            what_if_2: 'Bagaimana jika IP dimulai dengan 169?',
            what_if_2_content: '1. Jika alamat IP dimulai dengan 169.254, itu harus masalah pengaturan DHCP, silakan hubungi ISP (penyedia layanan internet) Anda untuk bantuan lebih lanjut.\n2. Jika komputer Anda terhubung ke internet langsung menggunakan PPPoE, silakan ganti menggunakan router untuk menyambung menggunakan PPPoE, dan aktifkan fitur DHCP di router.',
        },
        connect_ethernet: {
            title: 'Sambungan Langsung',
            tutorial1: '1. Sambungkan mesin dengan komputer Anda dengan kabel ethernet.',
            tutorial2_1: '2. Ikuti',
            tutorial2_a_text: 'panduan ini',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: '2. untuk menjadikan komputer Anda sebagai router.',
            tutorial3: '3. Klik Lanjutkan.',
        },
        connect_usb: {
            title: 'Koneksi USB',
            title_sub: '(HEXA & Ador Saja)',
            tutorial1: 'Sambungkan mesin dengan komputer Anda dengan kabel USB.',
            tutorial2: "Klik 'Lanjutkan'.",
            turn_off_machine: 'Matikan mesin.',
            turn_on_machine: 'Nyalakan mesin.',
            wait_for_turning_on: 'Klik "Lanjutkan" setelah proses startup selesai dan Anda telah masuk ke layar utama.',
        },
        connect_machine_ip: {
            check_usb: 'Periksa Koneksi USB',
            enter_ip: 'Masukkan IP Mesin Anda',
            check_ip: 'Memeriksa ketersediaan IP',
            invalid_ip: 'IP Tidak Valid: ',
            invalid_format: 'Format tidak valid',
            starts_with_169254: 'Dimulai dengan 169.254',
            unreachable: 'IP tidak dapat dijangkau',
            check_connection: 'Memeriksa Koneksi Mesin',
            check_firmware: 'Memeriksa versi firmware',
            check_camera: 'Memeriksa ketersediaan kamera',
            retry: 'Coba lagi',
            succeeded_message: 'Berhasil terhubung 🎉',
            finish_setting: 'Mulai Membuat!',
        },
        connecting: 'Menghubungkan...',
        setting_completed: {
            start: 'Mulai',
            great: 'Selamat datang di Beam Studio',
            setup_later: 'Anda selalu dapat mengatur mesin Anda dari Bilah Judul > "Mesin" > "Pengaturan Mesin"',
            back: 'Kembali',
            ok: 'MULAI MEMBUAT',
        },
    },
    error_pages: {
        screen_size: 'Harap dicatat bahwa Beam Studio mungkin tidak berfungsi secara optimal pada perangkat Anda. Untuk pengalaman terbaik, disarankan perangkat dengan lebar layar minimal 1024 piksel.',
    },
    menu: {
        mm: 'mm',
        inches: 'Inci',
    },
    settings: {
        on: 'Hidup',
        off: 'Mati',
        low: 'Rendah',
        high: 'Tinggi',
        caption: 'Pengaturan',
        tabs: {
            general: 'Umum',
            device: 'Mesin',
        },
        ip: 'Alamat IP Mesin',
        guess_poke: 'Cari alamat IP mesin',
        auto_connect: 'Pilih otomatis satu-satunya mesin',
        wrong_ip_format: 'Format IP salah',
        default_machine: 'Mesin Standar',
        default_machine_button: 'Kosong',
        remove_default_machine_button: 'Hapus',
        confirm_remove_default: 'Mesin standar akan dihapus.',
        reset: 'Atur ulang Beam Studio',
        reset_now: 'Atur ulang Beam Studio',
        confirm_reset: 'Konfirmasi atur ulang Beam Studio?',
        language: 'Bahasa',
        notifications: 'Notifikasi Desktop',
        check_updates: 'Periksa Pembaruan Otomatis',
        autosave_enabled: 'Simpan Otomatis',
        autosave_path: 'Lokasi Simpan Otomatis',
        autosave_interval: 'Simpan Setiap',
        autosave_number: 'Jumlah Simpan Otomatis',
        autosave_path_not_correct: 'Lokasi yang ditentukan tidak ditemukan.',
        preview_movement_speed: 'Kecepatan Pratinjau Gerakan',
        preview_movement_speed_hl: 'Kecepatan Pratinjau Gerakan (Laser Diode Aktif)',
        default_units: 'Satuan Standar',
        default_font_family: 'Font Default',
        default_font_style: 'Gaya Font Default',
        fast_gradient: 'Optimasi Kecepatan',
        engraving_direction: 'Arah',
        top_down: 'Atas-bawah',
        bottom_up: 'Bawah-atas',
        vector_speed_constraint: 'Batas Kecepatan (20mm/s)',
        loop_compensation: 'Kompensasi Loop',
        blade_radius: 'Jari-jari Bilah',
        blade_precut_switch: 'Pra-pemotongan Bilah',
        blade_precut_position: 'Posisi Pra-pemotongan',
        default_beambox_model: 'Pengaturan Dokumen Default',
        guides_origin: 'Asal Panduan',
        guides: 'Panduan',
        image_downsampling: 'Kualitas Pratinjau Bitmap',
        anti_aliasing: 'Anti-Aliasing',
        continuous_drawing: 'Gambar Kontinu',
        trace_output: 'Keluaran Lacak Gambar',
        single_object: 'Objek Tunggal',
        grouped_objects: 'Objek Terkelompok',
        simplify_clipper_path: 'Optimalkan Jalur yang Dihitung',
        enable_low_speed: 'Aktifkan gerakan kecepatan lambat',
        enable_custom_backlash: 'Aktifkan Kompensasi Backlash Kustom',
        custom_preview_height: 'Tinggi Pratinjau Kustom',
        mask: 'Pemotongan Area Kerja',
        text_path_calc_optimization: 'Optimasi Perhitungan Jalur',
        font_substitute: 'Ganti Karakter yang Tidak Didukung',
        font_convert: 'Konverter Teks ke Jalur',
        default_borderless_mode: 'Buka Bawah Default',
        default_enable_autofocus_module: 'Autofokus Default',
        default_enable_diode_module: 'Laser Diode Default',
        diode_offset: 'Offset Laser Diode',
        autofocus_offset: 'Offset Fokus Otomatis',
        diode_one_way_engraving: 'Grafir Laser Diode Satu Arah',
        diode_two_way_warning: 'Pancaran cahaya dua arah lebih cepat dan mungkin menghasilkan ketidaktepatan posisi ukiran laser. Disarankan untuk menguji terlebih dahulu.',
        share_with_flux: 'Bagikan Analitik Beam Studio',
        none: 'Tidak Ada',
        close: 'Tutup',
        enabled: 'Diaktifkan',
        disabled: 'Nonaktif',
        cancel: 'Batal',
        done: 'Terapkan',
        module_offset_10w: 'Offset Laser Diode 10W',
        module_offset_20w: 'Offset Laser Diode 20W',
        module_offset_printer: 'Offset Printer',
        module_offset_2w_ir: 'Offset Laser Inframerah 2W',
        printer_advanced_mode: 'Mode Printer Lanjutan',
        default_laser_module: 'Modul Laser Default',
        low_laser_for_preview: 'Laser untuk Jalankan Bingkai',
        groups: {
            general: 'Umum',
            update: 'Pembaruan Perangkat Lunak',
            connection: 'Koneksi',
            autosave: 'Simpan Otomatis',
            camera: 'Kamera',
            editor: 'Editor',
            engraving: 'Raster (Pemindaian)',
            path: 'Vektor (Garis Luar)',
            mask: 'Pemotongan Area Kerja',
            text_to_path: 'Teks',
            modules: 'Add-on',
            ador_modules: 'Modul Ador',
            privacy: 'Privasi',
        },
        notification_on: 'Menyala',
        notification_off: 'Mati',
        update_latest: 'Terbaru',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Grup',
            use: 'Impor SVG',
            image: 'Gambar',
            text: 'Teks',
        },
        context_menu: {
            cut: 'Potong',
            copy: 'Salin',
            paste: 'Tempel',
            paste_in_place: 'Tempel di Tempat',
            duplicate: 'Duplikat',
            delete: 'Hapus',
            group: 'Grup',
            ungroup: 'Batalkan Grup',
            move_front: 'Bawa ke Depan',
            move_up: 'Bawa ke Atas',
            move_down: 'Kirim ke Bawah',
            move_back: 'Kirim ke Belakang',
        },
        popup: {
            select_import_method: 'Pilih gaya pelapisan:',
            select_import_module: 'Pilih Modul:',
            touchpad: 'TouchPad',
            mouse: 'Mouse',
            layer_by_layer: 'Lapisan',
            layer_by_color: 'Warna',
            nolayer: 'Lapisan Tunggal',
            loading_image: 'Memuat gambar, harap tunggu...',
            no_support_text: 'Beam Studio saat ini tidak mendukung teks. Harap ubah teks ke path sebelum mengimpor.',
            speed_too_high_lower_the_quality: 'Menggunakan kecepatan terlalu tinggi pada resolusi ini dapat mengurangi kualitas ukiran bayangan.',
            both_power_and_speed_too_high: 'Menggunakan daya laser lebih rendah akan memperpanjang umur tabung laser. Juga, kecepatan terlalu tinggi pada resolusi ini dapat mengurangi kualitas ukiran bayangan.',
            too_fast_for_path: 'Menggunakan kecepatan terlalu tinggi pada lapisan yang berisi objek path dapat mengurangi presisi saat pemotongan. Kami tidak menyarankan menggunakan kecepatan lebih dari 20 mm/detik saat pemotongan.',
            too_fast_for_path_and_constrain: 'Lapisan berikut: %s berisi objek path vektor, dan memiliki kecepatan melebihi 20 mm/detik. Kecepatan pemotongan objek path vektor akan dibatasi hingga 20 mm/detik. Anda dapat menghapus batasan ini di Pengaturan Preferensi.',
            should_update_firmware_to_continue: '#814 Firmware Anda tidak mendukung versi Beam Studio ini. Harap perbarui firmware untuk melanjutkan. (Menu > Mesin > [Mesin Anda] > Perbarui Firmware)',
            recommend_downgrade_software: 'Kami mendeteksi versi firmware yang lebih lama. Kami sedang menangani masalah kompatibilitas, tetapi untuk saat ini, kami menyarankan kembali ke <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Kami mendeteksi versi firmware yang lebih lama. Kami sedang menangani masalah kompatibilitas, tetapi untuk saat ini, kami menyarankan memperbarui ke firmware terbaru.',
            still_continue: 'Lanjutkan',
            more_than_two_object: 'Terlalu banyak objek. Hanya mendukung 2 objek',
            not_support_object_type: 'Tidak mendukung jenis objek',
            select_first: 'Pilih objek terlebih dahulu.',
            select_at_least_two: 'Pilih dua objek untuk melanjutkan',
            import_file_contain_invalid_path: '#808 File SVG yang diimpor berisi path gambar yang tidak valid. Pastikan semua file gambar ada atau sisipkan gambar dalam file',
            import_file_error_ask_for_upload: 'Gagal mengimpor file SVG. Apakah Anda bersedia memberikan file ke tim pengembang untuk laporan bug ?',
            upload_file_too_large: '#819 File terlalu besar untuk diunggah.',
            successfully_uploaded: 'Unggah file berhasil.',
            upload_failed: '#819 Unggah file gagal.',
            or_turn_off_borderless_mode: 'Atau matikan mode Open Bottom.',
            svg_1_1_waring: 'Versi file SVG ini adalah v 1.1, mungkin ada masalah kompatibilitas potensial.',
            svg_image_path_waring: 'File SVG ini berisi <image> memuat dari jalur file. Ini mungkin menyebabkan kegagalan saat memuat.\nUntuk menghindari risiko ini, mohon gunakan gambar yang disematkan saat mengekspor SVG.',
            dxf_version_waring: 'Versi file DXF ini bukan 2013, mungkin ada masalah kompatibilitas potensial.',
            dont_show_again: 'Jangan tampilkan ini lagi nanti.',
            convert_to_path_fail: 'Gagal mengubah ke jalur.',
            save_unsave_changed: 'Apakah Anda ingin menyimpan perubahan yang belum disimpan?',
            dxf_bounding_box_size_over: 'Ukuran gambar melebihi area kerja. Harap pindahkan gambar Anda lebih dekat ke asal dalam perangkat lunak CAD Anda, atau pastikan bahwa unit diatur dengan benar.',
            progress: {
                uploading: 'Mengunggah',
                calculating: 'Menghitung',
            },
            backend_connect_failed_ask_to_upload: '#802 Kesalahan terus terjadi saat mencoba terhubung ke backend. Apakah Anda ingin mengunggah log laporan bug Anda?',
            backend_error_hint: 'Fitur mungkin tidak berfungsi dengan benar karena kesalahan backend.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Kesalahan saat mengonversi PDF ke SVG:',
                error_pdf2svg_not_found: '#825 Kesalahan: Perintah pdf2svg tidak ditemukan. Harap pasang pdf2svg dengan manajer paket Anda (mis. "yum install pdf2svg" atau "apt-get install pdf2svg").',
            },
            ungroup_use: 'Ini akan membatalkan pengelompokan DXF atau SVG yang diimpor. Karena file mungkin berisi sejumlah besar elemen, mungkin memerlukan waktu untuk membatalkan pengelompokan. Apakah Anda yakin untuk melanjutkan?',
            vectorize_shading_image: 'Gambar gradien memerlukan waktu lebih lama untuk melacak dan rentan terhadap noise. Harap matikan gradien gambar sebelum mengeksekusi.',
            change_workarea_before_preview: 'Area kerja %s tidak cocok dengan area kerja yang saat ini diatur. Apakah Anda ingin beralih area kerja saat ini?',
            bug_report: 'Laporan Bug',
            sentry: {
                title: 'Mari kita membuat Beam Studio lebih baik bersama',
                message: 'Apakah Anda setuju untuk secara otomatis mengunggah informasi yang relevan ke tim pengembangan ketika terjadi kesalahan?',
            },
            questionnaire: {
                caption: 'Bantu Kami Mengisi Kuesioner',
                message: 'Bantu kami mengisi kuesioner untuk membuat Beam Studio lebih baik.',
                unable_to_get_url: 'Gagal mendapatkan tautan ke kuesioner melalui internet. Harap konfirmasi koneksi internet Anda.',
                no_questionnaire_available: 'Saat ini tidak ada kuesioner yang tersedia.',
            },
            facebook_group_invitation: {
                title: 'Bergabung dengan Grup Pengguna Resmi FLUX',
                message: 'Bergabunglah dengan grup Facebook resmi kami untuk terhubung dengan pengguna laser FLUX lainnya, membahas laser FLUX, berbagi karya seni laser, dan tetap update dengan pembaruan terbaru pada produk kami. Kami tidak sabar melihat Anda di sana!',
                join_now: 'Gabung Sekarang',
                later: 'Mungkin Nanti',
                already_joined: 'Sudah Bergabung',
                dont_show_again: 'Jangan tampilkan lagi',
            },
            ai_credit: {
                relogin_to_use: 'Silakan login ulang untuk menggunakan fitur ini.',
                insufficient_credit: 'Anda kehabisan Kredit',
                insufficient_credit_msg: 'Anda tidak dapat menggunakan %s. Pergi ke pusat anggota dan beli Kredit AI.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Pergi',
            },
            text_to_path: {
                caption: 'Konverter Teks ke Jalur 2.0',
                message: "Beam Studio sekarang memperkenalkan konverter teks ke jalur yang baru (Konverter 2.0), yang menghasilkan hasil yang lebih andal! Apakah Anda ingin beralih ke sana sekarang? \nAnda juga dapat menyesuaikan pengaturan ini nanti di bagian 'Konverter Teks ke Jalur' dalam preferensi.",
            },
        },
        zoom_block: {
            fit_to_window: 'Sesuaikan dengan Jendela',
        },
        time_est_button: {
            calculate: 'Perkirakan waktu',
            estimate_time: 'Perkiraan Waktu:',
        },
        left_panel: {
            unpreviewable_area: 'Area Buta',
            diode_blind_area: 'Area Buta Tambahan Laser Hibrid',
            borderless_blind_area: 'Area Non-ukir',
            borderless_preview: 'Pratinjau Kamera Mode Bawah Terbuka',
            rectangle: 'Persegi Panjang',
            ellipse: 'Elips',
            line: 'Garis',
            image: 'Gambar',
            text: 'Teks',
            label: {
                cursor: 'Pilih',
                photo: 'Gambar',
                text: 'Teks',
                line: 'Garis',
                rect: 'Persegi Panjang',
                oval: 'Lingkaran',
                polygon: 'Poligon',
                pen: 'Pen',
                shapes: 'Bentuk',
                array: 'Larik',
                preview: 'Pratinjau Kamera',
                trace: 'Jejak Gambar',
                end_preview: 'Akhiri Pratinjau',
                clear_preview: 'Hapus Pratinjau',
                choose_camera: 'Kamera',
                live_feed: 'Siaran Langsung',
                adjust_height: 'Sesuaikan Tinggi',
                qr_code: 'Kode QR',
                boxgen: 'Boxgen',
                my_cloud: 'Awan Saya',
                curve_engraving: {
                    title: '3D Kurva',
                    exit: 'Keluar',
                    select_area: 'Pilih area',
                    preview_3d_curve: 'Pratinjau Kurva 3D',
                    clear_area: 'Hapus Area yang Dipilih',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Lapisan',
                objects: 'Objek',
                path_edit: 'Edit Jalur',
            },
            layer_panel: {
                layer1: 'Lapisan 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Pengukiran',
                layer_cutting: 'Pemotongan',
                current_layer: 'Lapisan Saat Ini',
                move_elems_to: 'Pindahkan elemen ke:',
                notification: {
                    dupeLayerName: 'Sudah ada lapisan dengan nama itu!',
                    newName: 'NAMA BARU',
                    enterUniqueLayerName: 'Silakan masukkan nama lapisan yang unik',
                    enterNewLayerName: 'Silakan masukkan nama lapisan baru',
                    layerHasThatName: 'Lapisan sudah memiliki nama itu',
                    QmoveElemsToLayer: "Pindahkan elemen terpilih ke lapisan '%s'?",
                    moveElemFromPrintingLayerTitle: 'Pindahkan elemen terpilih ke %s dan ubah menjadi elemen laser?',
                    moveElemFromPrintingLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan warna dari elemen terpilih akan dihapus dan diatur sesuai dengan %s.',
                    moveElemToPrintingLayerTitle: 'Pindahkan elemen terpilih ke %s dan ubah menjadi elemen pencetakan?',
                    moveElemToPrintingLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan dari elemen terpilih akan dihapus dan diatur sesuai dengan %s.',
                    splitColorTitle: 'Apakah Anda ingin memperluas lapisan terpilih menjadi lapisan CMYK?',
                    splitColorMsg: 'Harap perhatikan bahwa jika Anda melanjutkan operasi ini, Anda tidak akan dapat kembali ke lapisan warna asli.',
                    mergeLaserLayerToPrintingLayerTitle: 'Apakah Anda ingin menggabungkan lapisan ini menjadi satu lapisan Pencetakan?',
                    mergeLaserLayerToPrintingLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan lapisan laser akan dihapus dan diatur sesuai dengan lapisan saat ini.',
                    mergePrintingLayerToLaserLayerTitle: 'Apakah Anda ingin menggabungkan lapisan ini menjadi satu lapisan Laser?',
                    mergePrintingLayerToLaserLayerMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan warna lapisan pencetakan akan dihapus dan diatur sesuai dengan lapisan saat ini.',
                },
                layers: {
                    layer: 'Lapisan',
                    layers: 'Lapisan',
                    del: 'Hapus Lapisan',
                    move_down: 'Pindahkan Lapisan Ke Bawah',
                    new: 'Lapisan Baru',
                    rename: 'Ubah Nama Lapisan',
                    move_up: 'Pindahkan Lapisan Ke Atas',
                    dupe: 'Duplikat Lapisan',
                    lock: 'Kunci Lapisan',
                    unlock: 'Buka Kunci',
                    merge_down: 'Gabung Ke Bawah',
                    merge_all: 'Gabung Semua',
                    merge_selected: 'Gabung Lapisan Terpilih',
                    move_elems_to: 'Pindahkan elemen ke:',
                    move_selected: 'Pindahkan elemen terpilih ke lapisan yang berbeda',
                    switchToFullColor: 'Alihkan ke lapisan warna penuh',
                    switchToSingleColor: 'Alihkan ke lapisan warna tunggal',
                    splitFullColor: 'Perluas Lapisan',
                    fullColor: 'Warna penuh',
                },
            },
            laser_panel: {
                preset_setting: 'Pengaturan Parameter (%s)',
                multi_layer: 'Beberapa Lapisan',
                parameters: 'Parameter',
                strength: 'Daya',
                low_power_warning: 'Daya laser yang lebih rendah (di bawah 10%) mungkin tidak memancarkan cahaya laser.',
                speed: 'Kecepatan',
                speed_contrain_warning: 'Kecepatan pemotongan path vector akan dibatasi hingga 20 mm/detik (0,79 in/detik). Anda dapat menghapus batasan ini di Pengaturan Preferensi.',
                low_speed_warning: 'Kecepatan rendah dapat menyebabkan pembakaran bahan.',
                repeat: 'Jalankan',
                add_on: 'Add-on',
                focus_adjustment: 'Penyesuaian Fokus',
                height: 'Tinggi Objek',
                z_step: 'Langkah Z',
                diode: 'Laser Diode',
                backlash: 'Backlash',
                ink_saturation: 'Saturasi',
                print_multipass: 'Multi-pass',
                white_ink: 'Tinta Putih',
                white_ink_settings: 'Pengaturan Tinta Putih',
                color_adjustment: 'Penyesuaian Saluran',
                color_adjustment_short: 'Saluran',
                halftone: 'Setengah tone',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Kekuatan',
                times: 'kali',
                cut: 'Potong',
                engrave: 'Ukir',
                more: 'Kelola',
                delete: 'Hapus',
                reset: 'Atur Ulang',
                sure_to_reset: 'Ini akan mengatur ulang semua preset dan menghapus parameter yang disesuaikan Anda, apakah Anda yakin untuk melanjutkan?',
                apply: 'Terapkan',
                cancel: 'Batal',
                save: 'Simpan',
                save_and_exit: 'Simpan dan Keluar',
                name: 'Nama',
                default: 'Standar',
                customized: 'Daftar Disesuaikan',
                inuse: 'Digunakan',
                export_config: 'Ekspor Preset',
                new_config_name: 'Nama Preset Baru',
                sure_to_load_config: 'Ini akan memuat preset dan mengganti parameter disesuaikan yang diatur dalam file, apakah Anda yakin untuk melanjutkan?',
                custom_preset: 'Kustom',
                various_preset: 'Berbagai Preset',
                module: 'Modul',
                slider: {
                    regular: 'Reguler',
                    low: 'Rendah',
                    very_low: 'Minimal',
                    high: 'Tinggi',
                    very_high: 'Maksimal',
                    slow: 'Lambat',
                    very_slow: 'Paling Lambat',
                    fast: 'Cepat',
                    very_fast: 'Paling Cepat',
                },
                dropdown: {
                    parameters: 'Preset',
                    save: 'Tambahkan parameter saat ini',
                    export: 'Ekspor',
                    import: 'Impor',
                    more: 'Kelola',
                    mm: {
                        wood_3mm_cutting: 'Kayu - Pemotongan 3mm',
                        wood_5mm_cutting: 'Kayu - Pemotongan 5mm',
                        wood_7mm_cutting: 'Kayu - Pemotongan 7mm',
                        wood_8mm_cutting: 'Kayu - Pemotongan 8mm',
                        wood_10mm_cutting: 'Kayu - Pemotongan 10mm',
                        wood_engraving: 'Kayu - Pengukiran',
                        acrylic_3mm_cutting: 'Akrilik - Pemotongan 3mm',
                        acrylic_5mm_cutting: 'Akrilik - Pemotongan 5mm',
                        acrylic_8mm_cutting: 'Akrilik - Pemotongan 8mm',
                        acrylic_10mm_cutting: 'Akrilik - Pemotongan 10mm',
                        acrylic_engraving: 'Akrilik - Pengukiran',
                        mdf_3mm_cutting: 'MDF - Pemotongan 3mm',
                        mdf_5mm_cutting: 'MDF - Pemotongan 5mm',
                        mdf_engraving: 'MDF Selandia Baru - Ukiran',
                        leather_3mm_cutting: 'Kulit - Pemotongan 3mm',
                        leather_5mm_cutting: 'Kulit - Pemotongan 5mm',
                        leather_engraving: 'Kulit - Ukiran',
                        denim_1mm_cutting: 'Denim - Pemotongan 1mm',
                        fabric_3mm_cutting: 'Kain - Pemotongan 3mm',
                        fabric_5mm_cutting: 'Kain - Pemotongan 5mm',
                        fabric_engraving: 'Kain - Ukiran',
                        rubber_bw_engraving: 'Karet - Ukiran',
                        glass_bw_engraving: 'Kaca - Ukiran',
                        metal_bw_engraving: 'Logam - Ukiran',
                        steel_engraving_spray_engraving: 'Logam - Ukiran',
                        stainless_steel_bw_engraving_diode: 'Baja Tahan Karat - Ukiran (Laser Diode)',
                        gold_engraving: 'Emas - Ukiran',
                        brass_engraving: 'Perunggu - Ukiran',
                        ti_engraving: 'Titanium - Ukiran',
                        stainless_steel_engraving: 'Baja Tahan Karat - Ukiran',
                        aluminum_engraving: 'Aluminium - Ukiran',
                        black_acrylic_3mm_cutting: 'Akrilik Hitam - Pemotongan 3mm',
                        black_acrylic_5mm_cutting: 'Akrilik Hitam - Pemotongan 5mm',
                        black_acrylic_engraving: 'Akrilik Hitam - Ukiran',
                        abs_engraving: 'ABS - Ukiran',
                        silver_engraving: 'Perak - Ukiran',
                        iron_engraving: 'Besi - Ukiran',
                        fabric_printing: 'Kain - Pencetakan',
                        canvas_printing: 'Kanvas - Pencetakan',
                        cardstock_printing: 'Karton Tebal - Pencetakan',
                        wood_printing: 'Kayu - Pencetakan',
                        bamboo_printing: 'Bambu - Pencetakan',
                        cork_printing: 'Gabus - Pencetakan',
                        flat_stone_printing: 'Batu Datar - Pencetakan',
                        acrylic_printing: 'Akrilik - Pencetakan',
                        pc_printing: 'PC - Pencetakan',
                        stainless_steel_printing: 'Baja Tahan Karat - Pencetakan',
                        gloss_leather_printing: 'Kulit Glossy - Pencetakan',
                        glass_printing: 'Kaca - Pencetakan',
                    },
                    inches: {
                        wood_3mm_cutting: 'Kayu - 0,1 inci Pemotongan',
                        wood_5mm_cutting: 'Kayu - 0,2 inci Pemotongan',
                        wood_7mm_cutting: 'Kayu - 0,3 inci Pemotongan',
                        wood_8mm_cutting: 'Kayu - 0,3 inci Pemotongan',
                        wood_10mm_cutting: 'Kayu - 0,4 inci Pemotongan',
                        wood_engraving: 'Kayu - Pengukiran',
                        acrylic_3mm_cutting: 'Akrilik - 0,1 inci Pemotongan',
                        acrylic_5mm_cutting: 'Akrilik - 0,2 inci Pemotongan',
                        acrylic_8mm_cutting: 'Akrilik - 0,3 inci Pemotongan',
                        acrylic_10mm_cutting: 'Akrilik - 0,4 inci Pemotongan',
                        acrylic_engraving: 'Akrilik - Ukiran',
                        mdf_3mm_cutting: 'MDF - Pemotongan 0,1 inci',
                        mdf_5mm_cutting: 'MDF - Pemotongan 0,2 inci',
                        mdf_engraving: 'MDF - Ukiran',
                        leather_3mm_cutting: 'Kulit - Pemotongan 0,1 inci',
                        leather_5mm_cutting: 'Kulit - Pemotongan 0,2 inci',
                        leather_engraving: 'Kulit - Ukiran',
                        denim_1mm_cutting: 'Denim - Pemotongan 0,04 inci',
                        fabric_3mm_cutting: 'Kain - Pemotongan 0,1 inci',
                        fabric_5mm_cutting: 'Kain - Pemotongan 0,2 inci',
                        fabric_engraving: 'Kain - Ukiran',
                        rubber_bw_engraving: 'Karet - Ukiran',
                        glass_bw_engraving: 'Kaca - Ukiran',
                        metal_bw_engraving: 'Logam - Ukiran',
                        steel_engraving_spray_engraving: 'Logam - Ukiran',
                        stainless_steel_bw_engraving_diode: 'Logam - Ukiran (Laser Diode)',
                        gold_engraving: 'Emas - Ukiran',
                        brass_engraving: 'Kuningan - Ukiran',
                        ti_engraving: 'Titanium - Ukiran',
                        stainless_steel_engraving: 'Baja Tahan Karat - Ukiran',
                        aluminum_engraving: 'Aluminium - Ukiran',
                        black_acrylic_3mm_cutting: 'Akrilik Hitam - 0,1" Pemotongan',
                        black_acrylic_5mm_cutting: 'Akrilik Hitam - 0,2" Pemotongan',
                        black_acrylic_engraving: 'Akrilik Hitam - Ukiran',
                        abs_engraving: 'ABS - Ukiran',
                        silver_engraving: 'Perak - Ukiran',
                        iron_engraving: 'Besi - Ukiran',
                        fabric_printing: 'Kain - Pencetakan',
                        canvas_printing: 'Kanvas - Pencetakan',
                        cardstock_printing: 'Karton Tebal - Pencetakan',
                        wood_printing: 'Kayu - Pencetakan',
                        bamboo_printing: 'Bambu - Cetak',
                        cork_printing: 'Gabus - Cetak',
                        flat_stone_printing: 'Batu Datar - Cetak',
                        acrylic_printing: 'Akrilik - Cetak',
                        pc_printing: 'PC - Cetak',
                        stainless_steel_printing: 'Baja Tahan Karat - Cetak',
                        gloss_leather_printing: 'Kulit Glossy - Cetak',
                        glass_printing: 'Kaca - Cetak',
                    },
                },
                laser_speed: {
                    text: 'Kecepatan',
                    unit: 'mm/s',
                    fast: 'Cepat',
                    slow: 'Lambat',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Daya',
                    high: 'Tinggi',
                    low: 'Rendah',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Jenis Tinta',
                    normal: 'Tinta',
                    UV: 'Tinta UV',
                },
                para_in_use: 'Parameter ini sedang digunakan.',
                do_not_adjust_default_para: 'Preset tidak dapat disesuaikan.',
                existing_name: 'Nama ini sudah digunakan.',
                presets: 'Preset',
            },
            object_panel: {
                zoom: 'Perbesar',
                group: 'Kelompok',
                ungroup: 'Pisah Kelompok',
                distribute: 'Sebar',
                hdist: 'Sebar Horizontal',
                vdist: 'Sebar Vertikal',
                align: 'Rata',
                left_align: 'Rata Kiri',
                center_align: 'Rata Tengah',
                right_align: 'Rata Kanan',
                top_align: 'Rata Atas',
                middle_align: 'Rata Tengah',
                bottom_align: 'Rata Bawah',
                boolean: 'Boolean',
                union: 'Gabung',
                subtract: 'Kurang',
                intersect: 'Iris',
                difference: 'Selisih',
                flip: 'Balik',
                hflip: 'Balik Horizontal',
                vflip: 'Balik Vertikal',
                lock_aspect: 'Kunci Aspek',
                unlock_aspect: 'Buka Kunci Aspek',
                option_panel: {
                    fill: 'Isi',
                    rounded_corner: 'Sudut Bulat',
                    sides: 'Sisi',
                    font_family: 'Font',
                    font_style: 'Gaya',
                    font_size: 'Ukuran',
                    letter_spacing: 'Spasi Huruf',
                    line_spacing: 'Spasi Baris',
                    vertical_text: 'Teks Vertikal',
                    start_offset: 'Offset Teks',
                    vertical_align: 'Perataan',
                    text_infill: 'Isi Teks',
                    path_infill: 'Isi Lintasan',
                    shading: 'Gradasi',
                    threshold: 'Ambang Kecerahan',
                    threshold_short: 'Ambang',
                    stroke: 'Garis',
                    stroke_color: 'Warna Garis',
                    stroke_width: 'Lebar Garis',
                    color: 'Warna',
                },
                actions_panel: {
                    replace_with: 'Ganti Dengan...',
                    replace_with_short: 'Ganti',
                    trace: 'Telusuri',
                    grading: 'Pemeringkatan',
                    brightness: 'Kecerahan',
                    sharpen: 'Tajamkan',
                    crop: 'Potong',
                    bevel: 'Bevel',
                    invert: 'Invert',
                    weld_text: 'Weld Text',
                    convert_to_path: 'Convert to Path',
                    fetching_web_font: 'Mendapatkan font online...',
                    uploading_font_to_machine: 'Mengunggah font ke mesin...',
                    wait_for_parsing_font: 'Mengurai font... Mohon tunggu sebentar',
                    offset: 'Offset',
                    array: 'Array',
                    auto_arrange: 'Penataan Otomatis',
                    decompose_path: 'Dekomposisi',
                    disassemble_use: 'Lepaskan',
                    create_textpath: 'Buat Teks pada Jalur',
                    create_textpath_short: 'Teks Jalur',
                    detach_path: 'Lepaskan Teks pada Jalur',
                    detach_path_short: 'Lepaskan',
                    edit_path: 'Edit Lintasan',
                    disassembling: 'Membongkar...',
                    ungrouping: 'Batalkan pengelompokan...',
                    simplify: 'Peredaan',
                    ai_bg_removal: 'Hapus Latar Belakang',
                    ai_bg_removal_short: 'Hps Latar Blkg',
                    ai_bg_removal_reminder: 'Menekan tombol akan segera menggunakan 0,2 Kredit, apakah Anda ingin melanjutkan?',
                    outline: 'Garis Besar',
                },
                path_edit_panel: {
                    node_type: 'JENIS NODE',
                    sharp: 'Tajam',
                    round: 'Bulat',
                    connect: 'Sambungkan',
                    disconnect: 'Putuskan',
                    delete: 'Hapus',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Ubah Teks Menjadi Lintasan...',
            retreive_image_data: 'Ambil Data Gambar...',
            export_file_error_ask_for_upload: 'Gagal mengekspor pekerjaan. Apakah Anda bersedia memberikan adegan kerja kepada tim pengembang untuk laporan bug?',
        },
        image_trace_panel: {
            apply: 'Terapkan',
            back: 'Kembali',
            cancel: 'Batal',
            next: 'Berikutnya',
            brightness: 'Kecerahan',
            contrast: 'Kontras',
            threshold: 'Ambang',
            okay: 'Oke',
            tuning: 'Parameter',
        },
        photo_edit_panel: {
            apply: 'Terapkan',
            back: 'Kembali',
            cancel: 'Batal',
            next: 'Berikutnya',
            sharpen: 'Tajamkan',
            sharpness: 'Ketajaman',
            radius: 'Jari-jari',
            crop: 'Potong',
            aspect_ratio: 'Rasio Aspek',
            original: 'Asli',
            free: 'Bebas',
            curve: 'Lengkungan',
            start: 'Mulai',
            processing: 'Memproses',
            invert: 'Balik Warna',
            okay: 'Oke',
            compare: 'Bandingkan',
            phote_edit: 'Edit Foto',
            brightness_and_contrast: 'Kecerahan / Kontras',
            brightness: 'Kecerahan',
            contrast: 'Kontras',
        },
        document_panel: {
            document_settings: 'Pengaturan Dokumen',
            engrave_parameters: 'Parameter Ukiran',
            workarea: 'Area Kerja',
            rotary_mode: 'Putar',
            borderless_mode: 'Buka Bawah',
            engrave_dpi: 'Resolusi',
            enable_diode: 'Laser Diode',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Perluas area kerja',
            add_on: 'Add-on',
            low: 'Rendah',
            medium: 'Sedang',
            high: 'Tinggi',
            ultra: 'Sangat Tinggi',
            enable: 'Aktifkan',
            disable: 'Nonaktifkan',
            cancel: 'Batal',
            save: 'Simpan',
            notification: {
                changeFromPrintingWorkareaTitle: 'Apakah Anda ingin mengubah Lapisan Cetak menjadi Lapisan Laser?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Mengurai font... Mohon tunggu sebentar',
            text_to_path: {
                font_substitute_pop: 'Teks Anda berisi karakter yang tidak didukung oleh font saat ini. <br/>Apakah Anda ingin menggunakan <strong>%s</strong> sebagai pengganti?',
                check_thumbnail_warning: 'Beberapa teks diubah ke font lain saat mengubah teks ke path dan beberapa karakter mungkin tidak dikonversi dengan normal.\nPeriksa gambar pratinjau lagi sebelum mengirim tugas.',
                error_when_parsing_text: 'Kesalahan saat mengubah teks ke path',
                use_current_font: 'Gunakan Font Saat Ini',
                retry: 'Coba lagi nanti atau pilih font lain',
            },
            lock_desc: 'Jaga rasio lebar dan tinggi (SHIFT)',
        },
        tool_panels: {
            cancel: 'Batal',
            confirm: 'Konfirmasi',
            grid_array: 'Buat Pola Grid',
            array_dimension: 'Dimensi Pola',
            rows: 'Baris',
            columns: 'Kolom',
            array_interval: 'Interval Array',
            dx: 'X',
            dy: 'Y',
            offset: 'Offset',
            nest: 'Pengaturan Susunan',
            _offset: {
                direction: 'Arah Offset',
                inward: 'Ke Dalam',
                outward: 'Ke Luar',
                dist: 'Jarak Offset',
                corner_type: 'Sudut',
                sharp: 'Tajam',
                round: 'Bulat',
                fail_message: 'Gagal Meng-offset Objek',
                not_support_message: 'Elemen terpilih mengandung tag SVG yang tidak didukung:\nGambar, Grup, Teks dan Objek Terimpor.',
            },
            _nest: {
                start_nest: 'Atur',
                stop_nest: 'Berhenti',
                end: 'Tutup',
                spacing: 'Spasi',
                rotations: 'Kemungkinan Rotasi',
                no_element: 'Tidak ada elemen untuk diatur.',
            },
        },
        network_testing_panel: {
            network_testing: 'Pengujian Jaringan',
            local_ip: 'Alamat IP lokal:',
            insert_ip: 'Alamat IP perangkat target:',
            empty_ip: '#818 Mohon masukkan alamat IP perangkat target terlebih dahulu.',
            start: 'Mulai',
            end: 'Selesai',
            testing: 'Menguji Jaringan...',
            invalid_ip: '#818 Alamat IP tidak valid',
            ip_startswith_169: '#843 Alamat IP mesin dimulai dengan 169.254',
            connection_quality: 'Kualitas Koneksi',
            average_response: 'Rata-rata Waktu Respon',
            test_completed: 'Pengujian Selesai',
            test_fail: 'Pengujian Gagal',
            cannot_connect_1: '#840 Gagal terhubung ke IP target.',
            cannot_connect_2: '#840 Gagal terhubung ke IP target. Pastikan target berada di jaringan yang sama.',
            network_unhealthy: '#841 Kualitas koneksi <70 atau rata-rata waktu respon >100ms',
            device_not_on_list: '#842 Mesin tidak ada di daftar, tetapi Kualitas koneksi >70 dan rata-rata waktu respon <100ms',
            hint_device_often_on_list: 'Mesin sering tidak ditemukan di daftar?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Gagal terhubung saat mengirim pekerjaan?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Timeout terjadi saat memulai pratinjau kamera?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Akses ke alamat IP lokal gagal.',
            fail_to_start_network_test: '#817 Gagal memulai pengujian jaringan.',
            linux_permission_hint: 'Kesalahan ini biasanya terjadi karena izin yang tidak memadai.\nHarap jalankan "sudo beam-studio --no-sandbox" di terminal untuk mendapatkan izin dan melakukan pengujian jaringan.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Konfigurasi Warna Lapisan',
            color: 'Warna',
            power: 'Daya',
            speed: 'Kecepatan',
            repeat: 'Eksekusi',
            add: 'Tambah',
            save: 'Simpan',
            cancel: 'Batal',
            default: 'Setel Ulang Ke Default',
            add_config: 'Tambah Warna',
            in_use: 'Warna ini sedang digunakan.',
            no_input: 'Harap masukkan kode warna heksadesimal yang valid.',
            sure_to_reset: 'Anda akan kehilangan semua parameter yang disesuaikan, apakah Anda yakin untuk menyetel ulang ke default?',
            sure_to_delete: 'Apakah Anda yakin untuk menghapus pengaturan warna ini?',
        },
        rating_panel: {
            title: 'Menikmati Beam Studio?',
            description: 'Jika Anda menyukai Beam Studio, kami akan sangat menghargai jika Anda dapat memberi penilaian.',
            dont_show_again: 'Jangan Tampilkan ini lagi.',
            thank_you: 'Terima kasih!',
        },
        svg_editor: {
            unnsupported_file_type: 'Jenis file tidak didukung secara langsung. Silakan konversi file menjadi SVG atau bitmap',
            unable_to_fetch_clipboard_img: 'Gagal mengambil gambar dari clipboard Anda',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Mainkan',
            pause: 'Jeda',
            stop: 'Berhenti',
            play_speed: 'Kecepatan Pemutaran',
            travel_path: 'Lintasan Perjalanan',
            invert: 'Balik',
            preview_info: 'Pratinjau Informasi',
            size: 'Ukuran',
            estimated_time: 'Total Waktu Perkiraan',
            cut_time: 'Waktu Pemotongan',
            rapid_time: 'Waktu Perjalanan',
            cut_distance: 'Jarak Pemotongan',
            rapid_distance: 'Jarak Perjalanan',
            current_position: 'Posisi Saat Ini',
            remark: '* Semua informasi adalah nilai perkiraan untuk referensi.',
            start_here: 'Mulai Di Sini',
            end_preview: 'Selesai Pratinjau',
        },
        shapes_panel: {
            title: 'Elemen',
            basic: 'Dasar',
            shape: 'Bentuk',
            graphics: 'Grafis',
            arrow: 'Panah',
            label: 'Label',
            decor: 'Dekorasi',
            circular: 'Lingkaran',
            corner: 'Sudut',
            line: 'Garis',
            photo: 'Foto',
            ribbon: 'Pita',
            speech: 'Pidato',
            text: 'Teks',
            animals: 'Hewan',
            birds: 'Burung',
            land: 'Daratan',
            sea: 'Laut',
            holidays: 'Liburan',
            celebration: 'Perayaan',
            CNY: 'Tahun Baru Imlek',
            easter: 'Paskah',
            halloween: 'Halloween',
            valentines: 'Valentine',
            Xmas: 'Natal',
            nature: 'Alam',
            elements: 'Elemen',
            environment: 'Lingkungan',
            plants: 'Tanaman',
            weather: 'Cuaca',
        },
        announcement_panel: {
            title: 'Pengumuman',
            dont_show_again: 'Jangan tampilkan lagi',
        },
    },
    editor: {
        prespray_area: 'Area Priming',
        opacity: 'Opasitas Pratinjau',
        exposure: 'Pemaparan Pratinjau',
    },
    flux_id_login: {
        connection_fail: '#847 Gagal terhubung ke layanan keanggotaan FLUX.',
        login_success: 'Berhasil login.',
        login: 'Masuk',
        unlock_shape_library: 'Masuk untuk membuka kunci database bentuk.',
        email: 'Email',
        password: 'Kata Sandi',
        remember_me: 'Ingat saya',
        forget_password: 'Lupa Kata Sandi?',
        register: 'Buat Akun FLUX Anda',
        offline: 'Bekerja Offline',
        work_offline: 'Bekerja Offline',
        incorrect: 'Alamat email atau kata sandi salah.',
        not_verified: 'Alamat email belum diverifikasi.',
        new_to_flux: 'Baru di FLUX? Buat akun.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Jelajahi Rencana FLUX+',
            thank_you: 'Terima kasih telah menjadi anggota berharga!',
            ai_credit_tooltip: 'Untuk penghapusan latar belakang AI',
            flux_credit_tooltip: 'Untuk file Pasar Desain dan penghapusan latar belakang AI',
            goto_member_center: 'Pergi ke Pusat Anggota',
            access_plus_feature_1: 'Anda mengakses fitur',
            access_plus_feature_2: 'fitur.',
            access_plus_feature_note: 'Anda perlu berlangganan FLUX+ untuk mengakses fitur ini.',
            access_monotype_feature: 'Anda tidak berlangganan Add-on Font Monotype.',
            access_monotype_feature_note: 'Anda perlu berlangganan FLUX+ Pro atau Add-on Font Monotype untuk mengakses fitur ini.',
            learn_more: 'Pelajari Lebih Lanjut',
            get_addon: 'Dapatkan Add-on',
            subscribe_now: 'Berlangganan sekarang',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Pembuangan Latar Belakang AI',
                my_cloud: 'Penyimpanan Cloud Tak Terbatas',
                boxgen: 'Generator Kotak 3D',
                dmkt: '1000+ File Desain',
                monotype: '250+ Font Premium',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Masuk untuk membuka basis data bentuk.',
        enjoy_shape_library: 'Nikmati basis data bentuk.',
        shapes: 'Bentuk',
        elements: 'Elemen',
        recent: 'Terbaru',
        search: 'Cari',
        clear: 'Hapus',
        export_svg_title: 'Tidak dapat Mengekspor SVG',
        export_svg_warning: 'Proyek ini berisi objek yang dilindungi oleh hukum kekayaan intelektual. Oleh karena itu Beam Studio akan secara otomatis mengecualikan objek-objek ini selama ekspor. Anda masih dapat menyimpan proyek Anda dalam format Beam Studio Scene (.beam) untuk menyimpan seluruh data Anda. Apakah Anda masih ingin mengekspor?',
        learn_more: 'Pelajari Lebih Lanjut',
    },
    change_logs: {
        change_log: 'Log Perubahan:',
        added: 'Ditambahkan:',
        fixed: 'Diperbaiki:',
        changed: 'Diubah:',
        see_older_version: 'Lihat Versi Lama',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Pilih Perangkat',
        auth_failure: '#811 Autentikasi gagal',
        unable_to_connect: '#810 Tidak dapat membangun koneksi stabil dengan mesin',
    },
    device: {
        pause: 'Jeda',
        paused: 'Dijeda',
        pausing: 'Menjeda',
        select_printer: 'Pilih Printer',
        retry: 'Coba lagi',
        status: 'Status',
        busy: 'Sibuk',
        ready: 'Siap',
        reset: 'Reset',
        abort: 'Batalkan',
        start: 'Mulai',
        please_wait: 'Mohon Tunggu...',
        quit: 'Keluar',
        completing: 'Menyelesaikan',
        aborted: 'Dibatalkan',
        completed: 'Selesai',
        aborting: 'Membatalkan',
        starting: 'Memulai',
        preparing: 'Mempersiapkan',
        resuming: 'Melanjutkan',
        scanning: 'Memindai',
        occupied: 'Menduduki',
        running: 'Bekerja',
        uploading: 'Mengunggah',
        processing: 'Memproses',
        disconnectedError: {
            caption: 'Mesin terputus',
            message: 'Harap konfirmasi apakah akses jaringan %s tersedia',
        },
        unknown: 'Tidak diketahui',
        pausedFromError: 'Dihentikan karena kesalahan',
        model_name: 'Nama Model',
        IP: 'IP',
        serial_number: 'Nomor Seri',
        firmware_version: 'Versi Firmware',
        UUID: 'UUID',
        select: 'Pilih',
        deviceList: 'Daftar Mesin',
        disable: 'Nonaktifkan',
        enable: 'Aktifkan',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Mulai',
        resume: 'Lanjutkan',
        start: 'Mulai',
        pause: 'Jeda',
        stop: 'Berhenti',
        record: 'Rekam',
        camera: 'Kamera',
        taskTab: 'Tugas',
        connecting: 'Menghubungkan, mohon tunggu...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kesalahan Kritis: Papan utama offline. Silakan hubungi Dukungan FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kesalahan Kritis: Subsistem tidak merespons. Silakan hubungi Dukungan FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Silakan periksa dengan tangki air Anda.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Tutup pintu untuk melanjutkan.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Terlalu panas. Mohon tunggu beberapa menit.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bawah Terbuka. Tutup bagian bawah untuk melanjutkan.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Reset sumbu Z gagal',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Laci terbuka',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor api anomali',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor aliran udara abnormal',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Tidak terdeteksi modul putar',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Kesalahan Tarik Lepas Penghomatan',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Terdeteksi modul yang salah. Mohon pasang modul yang benar untuk melanjutkan.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Tidak terdeteksi modul. Pastikan modul terpasang dengan benar untuk melanjutkan.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Terdeteksi modul yang tidak diketahui. Pasang modul yang benar untuk melanjutkan.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Tidak ada respon dari modul printer.',
        USER_OPERATION_ROTARY_PAUSE: 'Harap beralih ke motor putar.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Harap lepas kartrid untuk melanjutkan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Terdeteksi modul yang salah. Mohon pasang modul yang benar untuk melanjutkan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Tidak terdeteksi modul. Pastikan modul terpasang dengan benar untuk melanjutkan.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Terdeteksi modul yang tidak diketahui. Pasang modul yang benar untuk melanjutkan.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Pasang modul yang benar untuk melanjutkan.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Silakan masukkan kartrid untuk melanjutkan.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Silakan masukkan kartrid yang benar untuk melanjutkan.',
        USER_OPERATION: 'Silakan ikuti petunjuk di panel perangkat untuk melanjutkan.',
        RESOURCE_BUSY: 'Mesin sibuk\nJika tidak berjalan, harap mulai ulang mesin',
        DEVICE_ERROR: 'Ada kesalahan\nHarap mulai ulang mesin',
        NO_RESPONSE: '#905 Kesalahan saat menyambung ke papan utama.\nHarap mulai ulang mesin.',
        SUBSYSTEM_ERROR: '#402 Kesalahan Kritis: Subsistem tidak merespons. Harap hubungi Dukungan FLUX.',
        HARDWARE_FAILURE: 'Ada kesalahan\nHarap mulai ulang mesin',
        MAINBOARD_OFFLINE: '#905 Kesalahan saat menyambung ke papan utama.\nHarap mulai ulang mesin.',
        bug_report: 'Laporan Bug',
        processing: 'Memproses',
        savingPreview: 'Membuat gambar mini',
        hour: 'j',
        minute: 'm',
        second: 'd',
        left: 'kiri',
        temperature: 'Suhu',
        forceStop: 'Apakah Anda ingin membatalkan tugas saat ini?',
        upload: 'Unggah',
        download: 'Unduh',
        relocate: 'Pindahkan',
        cancel: 'Batalkan',
        prepareRelocate: 'Persiapan untuk Pindah',
        extensionNotSupported: 'Format file ini tidak didukung',
        fileExistContinue: 'File sudah ada, apakah Anda ingin menggantinya?',
        confirmFileDelete: 'Apakah Anda yakin ingin menghapus file ini?',
        ask_reconnect: 'Koneksi dengan mesin telah terputus. Apakah Anda ingin menyambungkan kembali?',
        task: {
            BEAMBOX: 'Pemotongan Laser',
            'N/A': 'Mode Bebas',
        },
    },
    alert: {
        caption: 'Kesalahan',
        duplicated_preset_name: 'Nama preset duplikat',
        info: 'INFO',
        warning: 'PERINGATAN',
        error: 'KESALAHAN',
        instruction: 'Petunjuk',
        oops: 'Ups...',
        retry: 'Coba lagi',
        abort: 'Batalkan',
        confirm: 'Konfirmasi',
        cancel: 'Batal',
        close: 'Tutup',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ya',
        no: 'Tidak',
        stop: 'Berhenti',
        save: 'Simpan',
        dont_save: 'Jangan Simpan',
        learn_more: 'Pelajari Lebih Lanjut',
    },
    caption: {
        connectionTimeout: 'Koneksi timeout',
    },
    message: {
        cancelling: 'Membatalkan...',
        connecting: 'Menghubungkan...',
        connectingMachine: 'Menghubungkan %s...',
        tryingToConenctMachine: 'Mencoba menyambungkan ke mesin...',
        connected: 'Terhubung',
        authenticating: 'Mengotentikasi...',
        enteringRawMode: 'Memasuki mode mentah...',
        endingRawMode: 'Keluar dari mode mentah...',
        enteringLineCheckMode: 'Memasuki mode pemeriksaan garis...',
        endingLineCheckMode: 'Keluar dari mode pemeriksaan garis...',
        exitingRotaryMode: 'Keluar dari mode putar...',
        turningOffFan: 'Mematikan kipas...',
        turningOffAirPump: 'Mematikan pompa udara...',
        gettingLaserSpeed: 'Membaca kecepatan kepala laser...',
        settingLaserSpeed: 'Mengatur kecepatan kepala laser...',
        retrievingCameraOffset: 'Membaca offset kamera...',
        connectingCamera: 'Menghubungkan kamera...',
        homing: 'Melakukan pengaturan awal...',
        connectionTimeout: '#805 Waktu Koneksi Perangkat Habis. Silakan periksa koneksi jaringan Anda dan indikator Wi-Fi mesin.',
        getProbePosition: 'Mendapatkan Posisi Probe...',
        device_not_found: {
            caption: 'Mesin Default tidak ditemukan',
            message: '#812 Silakan periksa indikator Wi-Fi mesin',
        },
        device_busy: {
            caption: 'Mesin Sibuk',
            message: 'Mesin sedang mengerjakan tugas lain, coba lagi nanti. Jika berhenti bekerja, harap mulai ulang mesin.',
        },
        device_is_used: 'Mesin sedang digunakan, apakah Anda ingin membatalkan tugas saat ini?',
        monitor_too_old: {
            caption: 'Firmware Usang',
            content: '#814 Mohon pasang firmware terbaru dengan <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">panduan ini</a>.',
        },
        unknown_error: '#821 Aplikasi mengalami kesalahan yang tidak diketahui, harap gunakan Bantuan > Menu > Laporan Bug.',
        unknown_device: '#826 Tidak dapat terhubung ke mesin, pastikan USB terpasang ke mesin',
        unsupport_osx_version: 'Versi macOS %s saat ini mungkin tidak mendukung beberapa fungsi. Harap perbarui ke macOS 11+.',
        unsupport_win_version: 'Versi OS %s saat ini mungkin tidak mendukung beberapa fungsi. Harap perbarui ke versi terbaru.',
        need_password: 'Memerlukan Kata Sandi untuk Menghubungkan ke Mesin',
        unavailableWorkarea: '#804 Area kerja saat ini melebihi area kerja mesin ini. Harap periksa area kerja mesin yang dipilih atau atur area kerja dari Edit > Pengaturan Dokumen.',
        please_enter_dpi: 'Harap masukkan Satuan file Anda (dalam mm)',
        auth_error: '#820 Kesalahan Otentikasi: Harap perbarui Beam Studio dan firmware mesin ke versi terbaru.',
        usb_unplugged: 'Koneksi USB terputus. Harap periksa koneksi USB Anda',
        uploading_fcode: 'Mengunggah FCode',
        cant_connect_to_device: '#827 Tidak dapat terhubung ke mesin, harap periksa koneksi Anda',
        unable_to_find_machine: 'Tidak dapat menemukan mesin',
        disconnected: 'Koneksi tidak stabil, harap periksa koneksi perangkat dan coba lagi nanti',
        unable_to_start: '#830 Tidak dapat memulai tugas. Harap coba lagi. Jika ini terjadi lagi, hubungi kami dengan laporan bug:',
        camera: {
            camera_cable_unstable: 'Terdeteksi kamera mentransfer foto secara tidak stabil. Pratinjau masih dapat dilakukan, tetapi mungkin ada masalah lambat atau waktu habis.',
            fail_to_transmit_image: '#845 Terjadi kesalahan transmisi gambar. Harap mulai ulang mesin atau Beam Studio. Jika error berlanjut, ikuti <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">panduan ini</a>.',
            ws_closed_unexpectly: '#844 Koneksi ke kamera mesin ditutup secara tidak terduga. Jika error berlanjut, ikuti <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">panduan ini</a>.',
            continue_preview: 'Lanjutkan',
            abort_preview: 'Batalkan',
        },
        preview: {
            camera_preview: 'Pratinjau Kamera',
            auto_focus: 'Fokus Otomatis',
            auto_focus_instruction: 'Harap pindahkan kepala modul laser di atas objek, dan ikuti petunjuk animasi untuk menekan AF untuk memfokuskan.',
            already_performed_auto_focus: 'Anda sudah melakukan fokus otomatis, gunakan nilai yang ada?',
            please_enter_height: 'Silakan masukkan tinggi objek agar dapat mengambil gambar kamera yang akurat.',
            apply: 'Terapkan',
            enter_manually: 'Masukkan Secara Manual',
            adjust: 'Sesuaikan',
            adjust_height_tooltip: 'Centang kotak untuk mengaktifkan penyuntingan.',
        },
        unsupported_example_file: 'File contoh yang dipilih tidak didukung oleh area kerja saat ini.',
        time_remaining: 'Waktu tersisa:',
    },
    machine_status: {
        '0': 'Menganggur',
        '1': 'Memulai',
        '2': 'ST_TRANSFORM',
        '4': 'Mulai',
        '6': 'Melanjutkan',
        '16': 'Bekerja',
        '18': 'Melanjutkan',
        '32': 'Dijeda',
        '36': 'Dijeda',
        '38': 'Penjedaan',
        '48': 'Dijeda',
        '50': 'Penjedaan',
        '64': 'Selesai',
        '66': 'Menyelesaikan',
        '68': 'Mempersiapkan',
        '128': 'Dibatalkan',
        '256': 'Alarm',
        '512': 'Fatal',
        '-17': 'Mode Kartrid IO',
        '-10': 'Mode perawatan',
        '-2': 'Pemindaian',
        '-1': 'Pemeliharaan',
        UNKNOWN: 'Tidak diketahui',
    },
    calibration: {
        update_firmware_msg1: 'Firmware Anda tidak mendukung fungsi ini. Harap perbarui firmware ke v',
        update_firmware_msg2: 'atau di atas untuk melanjutkan。 (Menu > Mesin > [Mesin Anda] > Perbarui Firmware)',
        camera_calibration: 'Kalibrasi Kamera',
        diode_calibration: 'Kalibrasi Modul Laser Diode',
        module_calibration_printer: 'Kalibrasi Modul Printer',
        module_calibration_2w_ir: 'Kalibrasi Modul Inframerah',
        back: 'KEMBALI',
        next: 'BERIKUTNYA',
        skip: 'LEWATI',
        cancel: 'BATALKAN',
        finish: 'SELESAI',
        do_engraving: 'LAKUKAN PENGUKIRAN',
        start_engrave: 'MULAI UKIR',
        start_printing: 'MULAI CETAK',
        ask_for_readjust: 'Apakah Anda ingin melewati langkah pengukiran dan kemudian mengambil foto dan mengkalibrasi secara langsung?',
        please_goto_beambox_first: 'Silakan beralih ke Mode Pengukiran ( Beambox ) untuk menggunakan fitur ini.',
        please_place_paper: 'Silakan letakkan kertas putih ukuran A4 atau Letter di pojok kiri atas area kerja.',
        please_place_paper_center: 'Silakan letakkan kertas putih ukuran A4 atau Letter di tengah area kerja.',
        please_place_dark_colored_paper: 'Silakan letakkan kertas berwarna gelap ukuran A4 atau Letter di tengah area kerja.',
        please_refocus: {
            beambox: 'Silakan sesuaikan platform ke titik fokus (tinggi akrilik yang dibalik)',
            beamo: 'Silakan sesuaikan kepala laser untuk memfokuskan pada benda ukiran (tinggi akrilik yang dibalik)',
            beamo_af: 'Silakan ketuk dua kali tombol di samping penambahan fokus otomatis dan biarkan probe menyentuh material dengan lembut.',
            hexa: 'Klik dua kali tombol penyesuaian tinggi untuk menaikkan meja sarang lebah dan membuat probe menyentuh bahan benda ukiran.',
        },
        without_af: 'Tanpa Fokus Otomatis',
        with_af: 'Dengan Fokus Otomatis',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotasi',
        x_ratio: 'Rasio X',
        y_ratio: 'Rasio Y',
        show_last_config: 'Tampilkan Hasil Terakhir',
        use_last_config: 'Gunakan Nilai Kalibrasi Terakhir',
        taking_picture: 'Mengambil Gambar...',
        analyze_result_fail: 'Gagal menganalisis gambar yang ditangkap.<br/>Pastikan:<br/>1. Gambar yang ditangkap sepenuhnya tertutup kertas putih.<br/>2. Platform difokuskan dengan benar.',
        drawing_calibration_image: 'Menggambar gambar kalibrasi...',
        calibrate_done: 'Kalibrasi selesai. Akurasi kamera yang lebih baik diberikan saat fokus tepat.',
        calibrate_done_diode: 'Kalibrasi selesai. Offset modul laser diode telah disimpan.',
        hint_red_square: 'Harap sejajarkan bagian luar kotak merah dengan kotak pemotongan.',
        hint_adjust_parameters: 'Gunakan parameter ini untuk menyesuaikan kotak merah',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Saat mengkalibrasi modul laser diode, kamera diperlukan.\nPastikan kamera mesin ini telah dikalibrasi.\nDan sesuaikan platform ke titik fokus (tinggi akrilik yang dibalik)',
            beamo: 'Saat mengkalibrasi modul laser diode, kamera diperlukan.\nPastikan kamera mesin ini telah dikalibrasi.\nDan sesuaikan kepala laser untuk fokus pada objek ukiran (tinggi akrilik yang dibalik)',
        },
        downloading_pictures: 'Mengunduh Gambar...',
        failed_to_download_pictures: '#848 Gagal mengunduh gambar, silakan hubungi Dukungan FLUX.',
        uploading_images: 'Mengunggah Gambar...',
        calculating_camera_matrix: 'Menghitung Matriks Kamera...',
        calculating_regression_parameters: 'Menghitung Parameter Regresi...',
        failed_to_calibrate_camera: '#848 Gagal mengkalibrasi kamera, silakan hubungi Layanan FLUX.',
        failed_to_save_calibration_results: '#849 Gagal menyimpan hasil kalibrasi, silakan coba lagi. Jika ini terus terjadi, silakan hubungi Layanan FLUX.',
        ador_autofocus_material: 'Tekan ikon “AF” di halaman utama mesin selama 3 detik dan biarkan probe menyentuh bahan dengan lembut.',
        ador_autofocus_focusing_block: 'Tekan ikon "AF" di halaman utama mesin selama 3 detik dan biarkan probe menyentuh Blok Fokus.',
        align_red_cross_cut: 'Harap sejajarkan tengah salib merah dengan salib pemotongan.',
        align_red_cross_print: 'Harap sejajarkan tengah salib merah dengan salib cetak.',
        retake: 'Ambil Ulang Gambar',
        calibrate_camera_before_calibrate_modules: 'Harap lakukan kalibrasi kamera sebelum mengkalibrasi modul.',
        check_checkpoint_data: 'Data titik kontrol',
        checking_checkpoint: 'Memeriksa data titik kontrol...',
        found_checkpoint: 'Data titik kontrol ditemukan di perangkat Anda. Apakah Anda ingin memulihkannya dari titik kontrol?',
        use_old_camera_parameter: 'Apakah Anda ingin menggunakan parameter lensa kamera saat ini?',
        downloading_checkpoint: 'Mengunduh data titik kontrol...',
        failed_to_parse_checkpoint: 'Gagal mengurai data titik kontrol.',
        check_device_pictures: 'Periksa Gambar Perangkat',
        checking_pictures: 'Memeriksa gambar perangkat...',
        no_picutre_found: '#846 Perangkat Anda tidak memiliki foto mentah yang tersedia untuk kalibrasi. Silakan hubungi dukungan FLUX.',
        calibrating_with_device_pictures: 'Melakukan kalibrasi dengan gambar perangkat...',
        failed_to_calibrate_with_pictures: '#847 Gagal melakukan kalibrasi dengan gambar perangkat.',
        getting_plane_height: 'Mendapatkan Tinggi Bidang...',
        preparing_to_take_picture: 'Persiapan untuk mengambil foto...',
        put_paper: 'Letakkan kertas',
        put_paper_step1: 'Harap letakkan selembar kertas putih berukuran A4 atau Surat di tengah area kerja.',
        put_paper_step2: 'Pastikan empat sudut kertas terpasang dengan baik untuk memastikan kertas tersebut datar.',
        put_paper_step3: 'Klik "MULAI UKIR".',
        solve_pnp_title: 'Luruskan Titik Penanda',
        solve_pnp_step1: 'Silakan sejajarkan titik ukiran sesuai dengan nomor dan posisi yang diharapkan dari setiap titik penanda merah.',
        solve_pnp_step2: 'Anda dapat menekan "Ambil Ulang Gambar" untuk meluruskan kembali atau menyesuaikan posisi penanda secara manual.',
        align_olt: 'Posisi: Sejajarkan dengan titik terukir di kiri atas luar.',
        align_ort: 'Posisi: Sejajarkan dengan titik terukir di kanan atas luar.',
        align_olb: 'Posisi: Sejajarkan dengan titik terukir di kiri bawah luar.',
        align_orb: 'Posisi: Sejajarkan dengan titik terukir di kanan bawah luar.',
        align_ilt: 'Posisi: Sejajarkan dengan titik terukir di kiri atas dalam.',
        align_irt: 'Posisi: Sejajarkan dengan titik terukir di kanan atas dalam.',
        align_ilb: 'Posisi: Sejajarkan dengan titik terukir di kiri bawah dalam.',
        align_irb: 'Posisi: Sejajarkan dengan titik terukir di kanan bawah dalam.',
        elevate_and_cut: 'Menaikkan dan Memotong',
        elevate_and_cut_step_1: 'Letakkan sepotong kayu berukuran A4 berwarna terang di tengah area kerja, diangkat ke 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Gunakan Ador Prism Lift dengan panjang maksimum 14 mm bersama dengan kayu setidaknya 6 mm tebal.',
        with_prism_lift: 'Dengan Ador Prism Lift',
        without_prism_lift: 'Tanpa Ador Prism Lift',
        camera_parameter_saved_successfully: 'Parameter kamera berhasil disimpan.',
        failed_to_save_camera_parameter: 'Gagal menyimpan parameter kamera.',
        failed_to_solve_pnp: 'Gagal memecahkan posisi kamera.',
    },
    input_machine_password: {
        require_password: '"%s" memerlukan kata sandi',
        connect: 'HUBUNGKAN',
        password: 'Kata Sandi',
    },
    tutorial: {
        skip: 'Lewati',
        welcome: 'SELAMAT DATANG',
        suggest_calibrate_camera_first: 'Kami menyarankan pengguna untuk mengkalibrasi kamera pada awalnya dan mengatur ulang fokus sebelum setiap pratinjau untuk hasil yang optimal.<br/>Apakah Anda ingin mengonfirmasi untuk melakukan kalibrasi sekarang?<br/>(Anda dapat melewatkan ini sekarang dan melakukannya nanti dengan mengklik "Menu" > "Mesin" > [Mesin Anda] > "Kalibrasi Kamera".)',
        camera_calibration_failed: 'Kalibrasi Kamera Gagal',
        ask_retry_calibration: 'Apakah Anda ingin mengkalibrasi kamera lagi?',
        needNewUserTutorial: 'Apakah Anda ingin memulai tutorial?<br/>(Anda dapat melewatkan ini sekarang dan memulainya nanti dengan mengklik "Bantuan" > "Tampilkan Tutorial Awal".)',
        needNewInterfaceTutorial: 'Apakah Anda ingin memulai tutorial untuk antarmuka baru Beam Studio?<br/>(Anda dapat melewatkan ini sekarang dan memulainya nanti dengan mengklik "Bantuan" > "Tampilkan Pengantar Antarmuka".)',
        next: 'BERIKUTNYA',
        look_for_machine: 'Mencari mesin untuk tutorial...',
        unable_to_find_machine: 'Tidak dapat menemukan mesin untuk Tutorial. Apakah Anda ingin pergi ke halaman pengaturan koneksi, mencoba lagi atau melewati tutorial?',
        skip_tutorial: 'Anda telah melewati tutorial. Anda selalu dapat memulai tutorial dengan mengklik "Bantuan" > "Tampilkan Tutorial Awal"',
        set_connection: 'Atur Koneksi',
        retry: 'Coba lagi',
        newUser: {
            draw_a_circle: 'Gambar Lingkaran',
            drag_to_draw: 'Seret untuk Menggambar',
            infill: 'Nyalakan Isi',
            switch_to_object_panel: 'Beralih ke Panel Objek',
            switch_to_layer_panel: 'Beralih ke Panel Lapisan',
            set_preset_wood_engraving: 'Atur Preset: Kayu - Ukiran',
            set_preset_wood_cut: 'Atur Preset: Kayu - Pemotongan',
            add_new_layer: 'Tambahkan Lapisan Baru',
            draw_a_rect: 'Gambar Persegi Panjang',
            switch_to_preview_mode: 'Beralih ke Mode Pratinjau',
            preview_the_platform: 'Pratinjau Platform',
            end_preview_mode: 'Akhiri Mode Pratinjau',
            put_wood: '1. Letakkan contoh kayu',
            adjust_focus: '2. Sesuaikan fokus',
            close_cover: '3. Tutup penutup',
            send_the_file: 'Kirim Berkas',
            end_alert: 'Apakah Anda yakin untuk mengakhiri tutorial?',
            please_select_wood_engraving: 'Mohon pilih Preset "Kayu - Ukiran".',
            please_select_wood_cutting: 'Silakan pilih preset "Kayu - Pemotongan"',
        },
        newInterface: {
            camera_preview: 'Pratinjau Kamera',
            select_image_text: 'Pilih / Gambar / Teks',
            basic_shapes: 'Bentuk Dasar',
            pen_tool: 'Alat Pena',
            add_new_layer: 'Tambahkan Lapisan Baru',
            rename_by_double_click: 'Ganti nama dengan klik dua kali',
            drag_to_sort: 'Seret untuk mengurutkan',
            layer_controls: 'Klik kanan untuk memilih Kontrol Lapisan:\nDuplikat / Gabung / Kunci / Hapus Lapisan',
            switch_between_layer_panel_and_object_panel: 'Beralih antara Panel Lapisan dan Panel Objek',
            align_controls: 'Kontrol Perataan',
            group_controls: 'Kontrol Pengelompokan',
            shape_operation: 'Operasi Bentuk',
            flip: 'Balik',
            object_actions: 'Tindakan Objek',
            end_alert: 'Apakah Anda yakin untuk mengakhiri pengenalan UI baru?',
            select_machine: 'Pilih mesin',
            start_work: 'Mulai Bekerja',
        },
        gesture: {
            pan: 'Geser kanvas dengan dua jari.',
            zoom: 'Cubit dengan dua jari untuk memperbesar/memperkecil kanvas.',
            click: 'Ketuk untuk memilih objek.',
            drag: 'Seret untuk memilih beberapa objek.',
            hold: 'Tekan dan tahan untuk membuka menu konteks.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Itu semua untuk tutorialnya. Sekarang saatnya untuk membuat!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: 'Laser Diode 10W',
        laser_20w_diode: 'Laser Diode 20W',
        laser_2w_infrared: 'Laser Inframerah 2W',
        printing: 'Pencetakan',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Apakah Anda ingin mengubah modul Pencetakan menjadi modul Laser?',
            convertFromPrintingModuleMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan warna lapisan cetak akan dihapus dan disesuaikan dengan lapisan saat ini.',
            convertFromLaserModuleTitle: 'Apakah Anda ingin mengubah modul Laser menjadi modul Cetak?',
            convertFromLaserModuleMsg: 'Harap perhatikan bahwa jika Anda menyelesaikan operasi ini, pengaturan lapisan laser akan dihapus dan disesuaikan dengan lapisan saat ini.',
            importedDocumentContainsPrinting: 'Dokumen berisi lapisan cetak, apakah Anda ingin mengubah area kerja ke Ador?',
            printingLayersCoverted: 'Lapisan cetak telah dikonversi menjadi lapisan laser.',
            performPrintingCaliTitle: 'Lakukan kalibrasi modul cetak',
            performPrintingCaliMsg: 'Klik "Konfirmasi" untuk mengeksekusi kalibrasi, atau akses kalibrasi melalui menu atas. <br /> (Mesin> [Nama Mesin Anda]> Kalibrasi Modul Cetak)',
            performIRCaliTitle: 'Lakukan kalibrasi modul Inframerah',
            performIRCaliMsg: 'Klik "Konfirmasi" untuk mengeksekusi kalibrasi, atau akses kalibrasi melalui menu atas. <br /> (Mesin> [Nama Mesin Anda]> Kalibrasi Modul Inframerah)',
        },
        non_working_area: 'Area Non-kerja',
    },
    qr_code_generator: {
        title: 'Pembuat Kode QR',
        placeholder: 'Masukkan tautan atau teks',
        preview: 'Pratinjau',
        error_tolerance: 'Toleransi Kesalahan',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Balik warna latar belakang',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Kotak Dasar',
        coming_soon: 'Segera Hadir',
        workarea: 'Area Kerja',
        max_dimension_tooltip: 'Maks lebar/tinggi/kedalaman pengaturan adalah %s.',
        volume: 'Volume',
        outer: 'Luar',
        inner: 'Dalam',
        width: 'Lebar',
        height: 'Tinggi',
        depth: 'Kedalaman',
        cover: 'Tutup',
        thickness: 'Ketebalan',
        add_option: 'Tambah Opsi',
        joints: 'Sambungan',
        finger: 'Jari',
        finger_warning: 'Panjang sisi dalam kotak minimal 6mm (0,24 inci) agar kompatibel dengan sambungan jari.',
        tSlot: 'Slot-T',
        tSlot_warning: 'Panjang sisi kotak minimal 30mm (1,18 inci) agar kompatibel dengan sambungan T-slot.',
        edge: 'Tepi',
        tCount: 'Hitungan T',
        tCount_tooltip: 'Jumlah slot T berlaku untuk sisi pendek; jumlah pada sisi panjang dihitung berdasarkan panjangnya.',
        tDiameter: 'Diameter T',
        tLength: 'Panjang T',
        continue_import: 'Lanjutkan untuk Impor',
        customize: 'Sesuaikan',
        merge: 'Gabung',
        text_label: 'Label',
        beam_radius: 'Kompensasi kerf',
        beam_radius_warning: 'Hapus kompensasi kerf saat tepi atau sambungan kotak pendek untuk memastikan perakitan kotak yang tepat',
        import: 'Impor',
        cancel: 'Batal',
        reset: 'Setel Ulang',
        zoom: 'Perbesar',
        control_tooltip: 'Klik kiri untuk memutar\nGulir untuk memperbesar\nKlik kanan untuk menggeser',
        control_tooltip_touch: 'Seret untuk memutar\nCubit untuk memperbesar\nDua jari untuk menggeser',
    },
    my_cloud: {
        title: 'Awan Saya',
        loading_file: 'Memuat...',
        no_file_title: 'Simpan berkas ke Awan Saya untuk memulai.',
        no_file_subtitle: 'Buka Menu > "Berkas" > "Simpan ke Awan"',
        file_limit: 'File gratis',
        upgrade: 'Tingkatkan',
        sort: {
            most_recent: 'Terbaru',
            oldest: 'Terlama',
            a_to_z: 'Nama: A - Z',
            z_to_a: 'Nama: Z - A',
        },
        action: {
            open: 'Buka',
            rename: 'Ubah Nama',
            duplicate: 'Duplikat',
            download: 'Unduh',
            delete: 'Hapus',
            confirmFileDelete: 'Apakah Anda yakin ingin menghapus berkas ini? Tindakan ini tidak dapat dibatalkan.',
        },
        save_file: {
            choose_action: 'Simpan Berkas:',
            save: 'Simpan',
            save_new: 'Simpan sebagai berkas baru',
            input_file_name: 'Masukkan nama berkas:',
            invalid_char: 'Karakter tidak valid:',
            storage_limit_exceeded: 'Penyimpanan cloud Anda telah mencapai batas atas. Harap hapus file yang tidak diperlukan sebelum menyimpan file baru.',
        },
    },
    camera_data_backup: {
        title: 'Cadangkan Data Kamera',
        no_picture_found: 'Tidak ada gambar ditemukan di mesin.',
        folder_not_exists: 'Folder yang dipilih tidak ada.',
        incorrect_folder: 'Gagal mengunggah data kalibrasi. Harap periksa apakah folder yang Anda pilih sudah benar.',
        downloading_data: 'Mengunduh data...',
        estimated_time_left: 'Estimasi waktu tersisa:',
        uploading_data: 'Mengunggah data...',
        download_success: 'Data kamera berhasil diunduh.',
        upload_success: 'Data kamera berhasil diunggah.',
    },
    insecure_websocket: {
        extension_detected: 'Perpanjangan Beam Studio Connect Terdeteksi',
        extension_detected_description: "Kami telah mendeteksi bahwa Anda telah menginstal perpanjangan Beam Studio Connect. Klik 'Konfirmasi' untuk mengalihkan ke HTTPS, atau klik 'Batal' untuk melanjutkan menggunakan HTTP.",
        extension_not_deteced: 'Tidak dapat Mendeteksi Perpanjangan Beam Studio Connect',
        extension_not_deteced_description: "Untuk menggunakan HTTPS, klik 'Konfirmasi' untuk menginstal ekstensi Beam Studio Connect. Setelah menginstal ekstensi, silakan segarkan halaman untuk mengaktifkannya.<br/>Jika tidak, klik tautan di bawah ini untuk melihat cara menggunakan HTTP di Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Tautan</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Ukur Area Autofokus',
        amount: 'Jumlah',
        gap: 'Celah',
        rows: 'Baris',
        coloumns: 'Kolom',
        row_gap: 'Celah Baris',
        column_gap: 'Celah Kolom',
        set_object_height: 'Atur Tinggi Objek',
        set_object_height_desc: 'Ukur ketebalan maksimum objek.',
        measure_area_help: 'Bagaimana memilih area fokus otomatis untuk Kurva 3D?',
        reselect_area: 'Pilih Area Kembali',
        start_autofocus: 'Mulai Autofokus',
        starting_measurement: 'Memulai pengukuran...',
        preview_3d_curve: 'Pratinjau Kurva 3D',
        apply_arkwork: 'Terapkan Karya Seni pada Kurva 3D',
        apply_camera: 'Terapkan Gambar Kamera pada Kurva 3D',
        click_to_select_point: 'Klik untuk memilih atau membatalkan pilihan titik untuk mengukur ulang.',
        remeasure: 'Ukur ulang',
        remeasuring_points: 'Mengukur ulang titik...',
    },
    pass_through: {
        title: 'Mode Lewat',
    },
};
export default lang;
