var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { sprintf } from 'sprintf-js';
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import checkDeviceStatus from 'helpers/check-device-status';
import constant from 'app/actions/beambox/constant';
import deviceMaster from 'helpers/device-master';
import getDevice from 'helpers/device/get-device';
import LayerModule from 'app/constants/layer-module/layer-modules';
import MessageCaller, { MessageLevel } from 'app/actions/message-caller';
import progressCaller from 'app/actions/progress-caller';
import TopBarIcons from 'app/icons/top-bar/TopBarIcons';
import useI18n from 'helpers/useI18n';
import versionChecker from 'helpers/version-checker';
import workareaManager from 'app/svgedit/workarea';
import { CanvasContext, CanvasMode } from 'app/contexts/CanvasContext';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import styles from './FrameButton.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const PROGRESS_ID = 'frame-task';
const FrameButton = () => {
    const lang = useI18n();
    const tAlerts = lang.topbar.alerts;
    const { mode } = useContext(CanvasContext);
    const getCoords = useCallback(() => {
        const allBBox = svgCanvas.getVisibleElementsAndBBoxes();
        const coords = {
            minX: undefined,
            minY: undefined,
            maxX: undefined,
            maxY: undefined,
        };
        const { width: workareaWidth, height: fullHeight, rotaryExpansion } = workareaManager;
        const workareaHeight = fullHeight - rotaryExpansion[0] - rotaryExpansion[1];
        // TODO: consider module offset
        allBBox.forEach(({ bbox }) => {
            const { x, y, width, height } = bbox;
            const right = x + width;
            const bottom = y + height;
            if (right < 0 || bottom < 0 || x > workareaWidth || y > workareaHeight)
                return;
            if (coords.minX === undefined || bbox.x < coords.minX)
                coords.minX = bbox.x;
            if (coords.minY === undefined || bbox.y < coords.minY)
                coords.minY = bbox.y;
            if (coords.maxX === undefined || right > coords.maxX)
                coords.maxX = right;
            if (coords.maxY === undefined || bottom > coords.maxY)
                coords.maxY = bottom;
        });
        if (coords.minX !== undefined) {
            coords.minX = Math.max(coords.minX, 0);
            coords.minY = Math.max(coords.minY, 0);
            coords.maxX = Math.min(coords.maxX, workareaWidth);
            coords.maxY = Math.min(coords.maxY, workareaHeight);
        }
        return coords;
    }, []);
    const handleClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const coords = getCoords();
        // Only check minX because it's enough to know if there is any element
        if (coords.minX === undefined) {
            MessageCaller.openMessage({
                key: 'no-element-to-frame',
                level: MessageLevel.INFO,
                content: tAlerts.add_content_first,
                duration: 3,
            });
            return;
        }
        const { device } = yield getDevice();
        if (!device)
            return;
        const deviceStatus = yield checkDeviceStatus(device);
        if (!deviceStatus)
            return;
        progressCaller.openNonstopProgress({
            id: PROGRESS_ID,
            message: sprintf(lang.message.connectingMachine, device.name),
            timeout: 30000,
        });
        let isLineCheckEnabled = false;
        let lowLaserPower = 0;
        const isAdor = constant.adorModels.includes(device.model);
        if (isAdor) {
            let warningMessage = '';
            const deviceDetailInfo = yield deviceMaster.getDeviceDetailInfo();
            const headType = parseInt(deviceDetailInfo.head_type, 10);
            if ([LayerModule.LASER_10W_DIODE, LayerModule.LASER_20W_DIODE].includes(headType)) {
                lowLaserPower = beamboxPreference.read('low_power') * 10;
            }
            else if (headType === 0) {
                warningMessage = tAlerts.headtype_none + tAlerts.install_correct_headtype;
            }
            else if ([LayerModule.LASER_1064, LayerModule.PRINTER].includes(headType)) {
                warningMessage = tAlerts.headtype_mismatch + tAlerts.install_correct_headtype;
            }
            else {
                warningMessage = tAlerts.headtype_unknown + tAlerts.install_correct_headtype;
            }
            if (lowLaserPower) {
                try {
                    const res = yield deviceMaster.getDoorOpen();
                    const isDoorOpened = res.value === '1';
                    if (isDoorOpened) {
                        warningMessage = tAlerts.door_opened;
                    }
                }
                catch (error) {
                    console.error(error);
                    warningMessage = tAlerts.fail_to_get_door_status;
                }
            }
            if (warningMessage) {
                MessageCaller.openMessage({
                    key: 'low-laser-warning',
                    level: MessageLevel.INFO,
                    content: warningMessage,
                });
            }
        }
        try {
            progressCaller.update(PROGRESS_ID, { message: lang.message.enteringRawMode });
            yield deviceMaster.enterRawMode();
            progressCaller.update(PROGRESS_ID, { message: lang.message.exitingRotaryMode });
            yield deviceMaster.rawSetRotary(false);
            progressCaller.update(PROGRESS_ID, { message: lang.message.homing });
            yield deviceMaster.rawHome();
            const vc = versionChecker(device.version);
            if ((!isAdor && vc.meetRequirement('MAINTAIN_WITH_LINECHECK')) ||
                (isAdor && vc.meetRequirement('ADOR_RELEASE'))) {
                yield deviceMaster.rawStartLineCheckMode();
                isLineCheckEnabled = true;
            }
            progressCaller.update(PROGRESS_ID, { message: lang.message.turningOffFan });
            yield deviceMaster.rawSetFan(false);
            progressCaller.update(PROGRESS_ID, { message: lang.message.turningOffAirPump });
            yield deviceMaster.rawSetAirPump(false);
            if (!isAdor)
                yield deviceMaster.rawSetWaterPump(false);
            // TODO: add progress update with time
            const movementFeedrate = 6000; // mm/min
            // TODO: check if we need to wait between each move
            progressCaller.update(PROGRESS_ID, { message: lang.device.processing });
            const { dpmm } = constant;
            coords.minX /= dpmm;
            coords.minY /= dpmm;
            coords.maxX /= dpmm;
            coords.maxY /= dpmm;
            yield deviceMaster.rawMove({ x: coords.minX, y: coords.minY, f: movementFeedrate });
            if (lowLaserPower > 0) {
                yield deviceMaster.rawSetLaser({ on: true, s: lowLaserPower });
                yield deviceMaster.rawSet24V(true);
            }
            yield deviceMaster.rawMove({ x: coords.maxX, y: coords.minY, f: movementFeedrate });
            yield deviceMaster.rawMove({ x: coords.maxX, y: coords.maxY, f: movementFeedrate });
            yield deviceMaster.rawMove({ x: coords.minX, y: coords.maxY, f: movementFeedrate });
            yield deviceMaster.rawMove({ x: coords.minX, y: coords.minY, f: movementFeedrate });
        }
        catch (error) {
            console.log('frame error:\n', error);
        }
        finally {
            if (deviceMaster.currentControlMode === 'raw') {
                if (isLineCheckEnabled)
                    yield deviceMaster.rawEndLineCheckMode();
                yield deviceMaster.rawSetLaser({ on: false, s: 0 });
                yield deviceMaster.rawSet24V(false);
                yield deviceMaster.rawLooseMotor();
                yield deviceMaster.endRawMode();
            }
            progressCaller.popById(PROGRESS_ID);
        }
        deviceMaster.kick();
    });
    return (React.createElement("div", { className: classNames(styles.button, { [styles.disabled]: mode !== CanvasMode.Draw }), onClick: handleClick, title: lang.topbar.frame_task },
        React.createElement(TopBarIcons.Frame, null)));
};
export default FrameButton;
