var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Cropper from 'cropperjs';
import { Button, ConfigProvider, Modal } from 'antd';
import calculateBase64 from 'helpers/image-edit-panel/calculate-base64';
import handleFinish from 'helpers/image-edit-panel/handle-finish';
import jimpHelper from 'helpers/jimp-helper';
import progressCaller from 'app/actions/progress-caller';
import Select from 'app/widgets/AntdSelect';
import useI18n from 'helpers/useI18n';
import { cropPreprocess } from 'helpers/image-edit-panel/preprocess';
import { useIsMobile } from 'helpers/system-helper';
import styles from './CropPanel.module.scss';
const MODAL_PADDING_X = 80;
const MODAL_PADDING_Y = 170;
const CropPanel = ({ src, image, onClose }) => {
    const lang = useI18n();
    const t = lang.beambox.photo_edit_panel;
    const cropperRef = useRef(null);
    const previewImageRef = useRef(null);
    const originalSizeRef = useRef({ width: 0, height: 0 });
    const historyRef = useRef([]);
    const { isShading, threshold, isFullColor } = useMemo(() => ({
        isShading: image.getAttribute('data-shading') === 'true',
        threshold: parseInt(image.getAttribute('data-threshold'), 10),
        isFullColor: image.getAttribute('data-fullcolor') === '1',
    }), [image]);
    const [state, setState] = useState({
        blobUrl: src,
        displayBase64: '',
        width: 0,
        height: 0,
        aspectRatio: NaN,
        imgAspectRatio: NaN,
    });
    const preprocess = () => __awaiter(void 0, void 0, void 0, function* () {
        progressCaller.openNonstopProgress({ id: 'photo-edit-processing', message: t.processing });
        const { blobUrl, dimension, originalWidth, originalHeight } = yield cropPreprocess(src);
        const { width, height } = dimension;
        originalSizeRef.current = { width: originalWidth, height: originalHeight };
        historyRef.current.push({ dimension, blobUrl });
        const displayBase64 = yield calculateBase64(blobUrl, isShading, threshold, isFullColor);
        setState({
            blobUrl,
            displayBase64,
            width,
            height,
            aspectRatio: NaN,
            imgAspectRatio: width / height,
        });
        progressCaller.popById('photo-edit-processing');
    });
    const cleanUpHistory = () => {
        for (let i = 0; i < historyRef.current.length; i += 1) {
            const { blobUrl } = historyRef.current[i];
            if (blobUrl !== src)
                URL.revokeObjectURL(blobUrl);
        }
    };
    useEffect(() => {
        preprocess();
        return () => {
            cleanUpHistory();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const startCropper = (aspectRatio = NaN) => {
        if (cropperRef.current)
            cropperRef.current.destroy();
        cropperRef.current = new Cropper(previewImageRef.current, {
            aspectRatio,
            autoCropArea: 1,
            zoomable: false,
            viewMode: 2,
            minCropBoxWidth: 1,
            minCropBoxHeight: 1,
        });
    };
    const getDimensionFromCropper = () => {
        const cropData = cropperRef.current.getData();
        const previewImage = previewImageRef.current;
        const x = Math.round(cropData.x);
        const y = Math.round(cropData.y);
        const width = Math.min(previewImage.naturalWidth - x, Math.round(cropData.width));
        const height = Math.min(previewImage.naturalHeight - y, Math.round(cropData.height));
        return { x, y, width, height };
    };
    const handleComplete = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!cropperRef.current)
            return;
        let { x, y, width, height } = getDimensionFromCropper();
        const { width: resizedW, height: resizedH } = historyRef.current[0].dimension;
        const { x: currentX, y: currentY } = historyRef.current[historyRef.current.length - 1].dimension;
        const { width: originalWidth, height: originalHeight } = originalSizeRef.current;
        const ratio = originalWidth > originalHeight ? originalWidth / resizedW : originalHeight / resizedH;
        x += currentX;
        y += currentY;
        x = Math.floor(x * ratio);
        y = Math.floor(y * ratio);
        width = Math.floor(width * ratio);
        height = Math.floor(height * ratio);
        if (width === originalWidth && height === originalHeight) {
            onClose();
            return;
        }
        progressCaller.openNonstopProgress({ id: 'photo-edit-processing', message: t.processing });
        const result = yield jimpHelper.cropImage(src, x, y, width, height);
        const base64 = yield calculateBase64(result, isShading, threshold, isFullColor);
        const newWidth = parseFloat(image.getAttribute('width')) * (width / originalWidth);
        const newHeight = parseFloat(image.getAttribute('height')) * (height / originalHeight);
        handleFinish(image, result, base64, newWidth, newHeight);
        progressCaller.popById('photo-edit-processing');
        onClose();
    });
    const { blobUrl } = state;
    const handleApply = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!cropperRef.current)
            return;
        const { x, y, width, height } = getDimensionFromCropper();
        const previewImage = previewImageRef.current;
        if (x === 0 && y === 0 && width === previewImage.naturalWidth && height === previewImage.naturalHeight)
            return;
        progressCaller.openNonstopProgress({ id: 'photo-edit-processing', message: t.processing });
        const result = yield jimpHelper.cropImage(blobUrl, x, y, width, height);
        if (result) {
            const displayBase64 = yield calculateBase64(result, isShading, threshold, isFullColor);
            const { dimension } = historyRef.current[historyRef.current.length - 1];
            historyRef.current.push({
                blobUrl: result,
                dimension: {
                    x: dimension.x + x,
                    y: dimension.y + y,
                    width,
                    height,
                },
            });
            setState(Object.assign(Object.assign({}, state), { blobUrl: result, displayBase64, width, height }));
        }
        progressCaller.popById('photo-edit-processing');
    });
    const handleUndo = () => __awaiter(void 0, void 0, void 0, function* () {
        if (historyRef.current.length === 1)
            return;
        progressCaller.openNonstopProgress({ id: 'photo-edit-processing', message: t.processing });
        const { blobUrl: currentUrl } = historyRef.current.pop();
        URL.revokeObjectURL(currentUrl);
        const { blobUrl: newUrl, dimension } = historyRef.current[historyRef.current.length - 1];
        const { width, height } = dimension;
        const displayBase64 = yield calculateBase64(newUrl, isShading, threshold, isFullColor);
        setState(Object.assign(Object.assign({}, state), { blobUrl: newUrl, displayBase64, width, height }));
        progressCaller.popById('photo-edit-processing');
    });
    const [cancelBtn, backBtn, applyBtn, okBtn] = [
        React.createElement(Button, { key: "cancel", onClick: onClose }, t.cancel),
        React.createElement(Button, { key: "back", onClick: handleUndo, disabled: historyRef.current.length <= 1 }, t.back),
        React.createElement(Button, { key: "apply", onClick: handleApply }, t.apply),
        React.createElement(Button, { key: "ok", type: "primary", onClick: handleComplete }, t.okay),
    ];
    const { width, height, displayBase64 } = state;
    const maxWidth = window.innerWidth - MODAL_PADDING_X;
    const maxHieght = window.innerHeight - MODAL_PADDING_Y;
    const isWideImage = (width / maxWidth > height / maxHieght);
    const isMobile = useIsMobile();
    return (React.createElement(ConfigProvider, { theme: isMobile
            ? { components: { Button: { borderRadius: 100 }, Select: { borderRadius: 100 } } }
            : undefined },
        React.createElement(Modal, { open: true, centered: true, maskClosable: false, title: t.crop, width: isWideImage ? maxWidth : undefined, onCancel: onClose, footer: isMobile ? [cancelBtn, okBtn] : [cancelBtn, backBtn, applyBtn, okBtn] },
            isMobile && (React.createElement("div", { className: styles['top-buttons'] },
                backBtn,
                applyBtn)),
            React.createElement("img", { ref: previewImageRef, src: displayBase64, style: isWideImage ? { width: `${maxWidth}px` } : { height: `${maxHieght}px` }, onLoad: () => startCropper() }),
            isMobile && (React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, t.aspect_ratio),
                React.createElement(Select, { className: styles.select, value: state.aspectRatio, onChange: (val) => {
                        startCropper(val === 0 ? state.imgAspectRatio : val);
                        setState(Object.assign(Object.assign({}, state), { aspectRatio: val }));
                    }, options: [
                        { label: '1:1', value: 1 },
                        { label: t.original, value: 0 },
                        { label: t.free, value: NaN },
                        { label: '16:9', value: 16 / 9 },
                        { label: '4:3', value: 4 / 3 },
                    ] }))))));
};
export default CropPanel;
