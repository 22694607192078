import isDev from 'helpers/is-dev';
import isWeb from 'helpers/is-web';
export const modelsSupportUsb = new Set(['fhexa1', 'ado1']);
export default {
    dpmm: 10,
    camera: {
        movementSpeed: {
            // limited by firmware
            x: 300 * 60,
            y: 100 * 60, // mm per minutes
        },
        imgWidth: 640,
        imgHeight: 280,
        offsetX_ideal: 20,
        offsetY_ideal: 30,
        scaleRatio_ideal: (585 / 720) * 2,
        calibrationPicture: {
            centerX: 90,
            centerY: 90,
            size: 25, // mm
        },
    },
    borderless: {
        safeDistance: {
            X: 40, // mm
        },
    },
    diode: {
        calibrationPicture: {
            offsetX: 69,
            offsetY: 6,
            centerX: 159,
            centerY: 96, // mm
        },
        limitX: 50,
        limitY: 10,
        defaultOffsetX: 70,
        defaultOffsetY: 7,
        safeDistance: {
            X: 50,
            Y: 15, // mm
        },
    },
    allowedWorkarea: {
        'laser-b2': ['fhexa1', 'fbb1p', 'fbb1b', 'fbm1'],
        'laser-b1': ['fhexa1', 'fbb1p', 'fbb1b', 'fbm1'],
        fhexa1: ['fhexa1', 'fbb1p', 'fbb1b', 'fbm1'],
        fbb1p: ['fbb1p', 'fbb1b', 'fbm1'],
        fbb1b: ['fbb1b', 'fbm1'],
        fbm1: ['fbm1'],
        ado1: ['ado1', 'fad1'],
        fad1: ['ado1', 'fad1'],
    },
    adorModels: ['ado1', 'fad1'],
    addonsSupportList: {
        rotary: isDev()
            ? ['fbm1', 'fbb1b', 'fbb1p', 'fhexa1', 'fad1', 'ado1']
            : ['fbm1', 'fbb1b', 'fbb1p', 'fhexa1'],
        openBottom: ['fbm1'],
        autoFocus: ['fbm1'],
        hybridLaser: ['fbm1'],
    },
    leftPanelWidth: 50,
    rightPanelWidth: window.os !== 'MacOS' ? 258 : 242,
    rightPanelScrollBarWidth: window.os !== 'MacOS' ? 16 : 0,
    sidePanelsWidth: window.os !== 'MacOS' ? 308 : 292,
    topBarHeight: window.os === 'Windows' && !isWeb() ? 70 : 40,
    topBarHeightWithoutTitleBar: 40,
    titlebarHeight: window.os === 'Windows' && !isWeb() ? 30 : 0,
    menuberHeight: window.os === 'Windows' && !isWeb() ? 30 : 40,
    layerListHeight: 400,
    rulerWidth: 15,
    rotaryYRatio: {
        1: 1,
        2: 1,
    },
    dpiValueMap: {
        low: 100,
        medium: 250,
        high: 500,
        ultra: 1000,
    },
};
