// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-views-beambox-Right-Panels-LaserManage-ArrowButtons-module__space--jmaVu{width:100%;height:100%}.src-web-app-views-beambox-Right-Panels-LaserManage-ArrowButtons-module__space--jmaVu>div{width:100%;text-align:center}.src-web-app-views-beambox-Right-Panels-LaserManage-ArrowButtons-module__space--jmaVu .src-web-app-views-beambox-Right-Panels-LaserManage-ArrowButtons-module__btn--rbetx:not(:first-of-type){margin-top:10px}", "",{"version":3,"sources":["webpack://./src/web/app/views/beambox/Right-Panels/LaserManage/ArrowButtons.module.scss"],"names":[],"mappings":"AAAA,sFACE,UAAA,CACA,WAAA,CAEA,0FACE,UAAA,CACA,iBAAA,CAIA,8LACE,eAAA","sourcesContent":[".space {\n  width: 100%;\n  height: 100%;\n\n  > div {\n    width: 100%;\n    text-align: center;\n  }\n\n  .btn {\n    &:not(:first-of-type) {\n      margin-top: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "src-web-app-views-beambox-Right-Panels-LaserManage-ArrowButtons-module__space--jmaVu",
	"btn": "src-web-app-views-beambox-Right-Panels-LaserManage-ArrowButtons-module__btn--rbetx"
};
export default ___CSS_LOADER_EXPORT___;
