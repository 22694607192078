var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import deviceMaster from 'helpers/device-master';
import progressCaller from 'app/actions/progress-caller';
import i18n from 'helpers/i18n';
import FisheyePreviewManagerBase from './FisheyePreviewManagerBase';
import getAutoFocusPosition from './getAutoFocusPosition';
import getLevelingData from './getLevelingData';
import getHeight from './getHeight';
import rawAndHome from './rawAndHome';
class FisheyePreviewManagerV2 extends FisheyePreviewManagerBase {
    constructor(device, params) {
        super();
        this.version = 2;
        this.updateLevelingData = () => __awaiter(this, void 0, void 0, function* () {
            const data = Object.assign({}, this.params.levelingData);
            const keys = Object.keys(data);
            keys.forEach((key) => {
                var _a;
                data[key] = Math.round(((_a = data[key] + this.levelingOffset[key]) !== null && _a !== void 0 ? _a : 0) * 1000) / 1000;
            });
            yield deviceMaster.setFisheyeLevelingData(this.levelingOffset);
        });
        this.onObjectHeightChanged = () => __awaiter(this, void 0, void 0, function* () {
            const { params, autoFocusRefKey, objectHeight, levelingOffset } = this;
            const { levelingData = {}, source } = params;
            const refKey = source === 'device' ? 'A' : 'E';
            const heightCompensation = (levelingData[refKey] || 0) -
                (levelingData[autoFocusRefKey] || 0) -
                levelingOffset[refKey] +
                levelingOffset[autoFocusRefKey];
            const finalHeight = objectHeight + heightCompensation;
            yield deviceMaster.setFisheyeObjectHeight(finalHeight);
        });
        this.device = device;
        this.params = params;
    }
    setupFisheyePreview(args = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const { lang } = i18n;
            const { progressId, focusPosition, defaultHeight } = args;
            if (!progressId)
                progressCaller.openNonstopProgress({ id: this.progressId });
            const { device, params } = this;
            progressCaller.update(progressId || this.progressId, { message: 'Fetching leveling data...' });
            this.levelingOffset = yield getLevelingData('offset');
            yield rawAndHome(progressId || this.progressId);
            const height = yield getHeight(device, progressId || this.progressId, defaultHeight);
            if (typeof height !== 'number')
                return false;
            this.objectHeight = height;
            progressCaller.openNonstopProgress({
                id: progressId || this.progressId,
                message: lang.message.getProbePosition,
            });
            this.autoFocusRefKey = focusPosition !== null && focusPosition !== void 0 ? focusPosition : (yield getAutoFocusPosition(device));
            progressCaller.update(progressId || this.progressId, { message: lang.message.endingRawMode });
            yield deviceMaster.endRawMode();
            // V2 calibration use point E as reference
            console.log(params);
            yield deviceMaster.setFisheyeParam(params);
            yield this.updateLevelingData();
            this.onObjectHeightChanged();
            if (!progressId)
                progressCaller.popById(this.progressId);
            return true;
        });
    }
    reloadLevelingOffset() {
        return __awaiter(this, void 0, void 0, function* () {
            this.levelingOffset = yield getLevelingData('offset');
            yield this.updateLevelingData();
            this.onObjectHeightChanged();
        });
    }
}
export default FisheyePreviewManagerV2;
