var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import beamboxPreference from 'app/actions/beambox/beambox-preference';
import LayerModule, { modelsWithModules } from 'app/constants/layer-module/layer-modules';
import { DataType, getData } from 'helpers/layer/layer-config-helper';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import readBitmapFile from './readBitmapFile';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
// TODO: add unit test
const importBitmap = (file) => __awaiter(void 0, void 0, void 0, function* () {
    const workarea = beamboxPreference.read('workarea');
    if (modelsWithModules.has(workarea)) {
        const drawing = svgCanvas.getCurrentDrawing();
        const currentLayer = drawing.getCurrentLayer();
        if (getData(currentLayer, DataType.module) === LayerModule.PRINTER &&
            currentLayer.getAttribute('data-fullcolor') === '1') {
            yield readBitmapFile(file, { gray: false });
            return;
        }
    }
    yield readBitmapFile(file);
});
export default importBitmap;
