const lang = {
    general: {
        wait: 'Procesando, por favor espera',
        choose_folder: 'Elegir Carpeta',
    },
    buttons: {
        next: 'Siguiente',
        back: 'Volver',
        done: 'Aplicar',
        back_to_beam_studio: 'Volver a Beam Studio',
    },
    topbar: {
        untitled: 'Sin título',
        titles: {
            settings: 'Preferencias',
        },
        export: 'GO',
        preview: 'VISTA PREVIA',
        preview_title: 'Vista previa',
        curve_engrave: 'Curva 3D',
        task_preview: 'Previsualizar Trayectoria',
        frame_task: 'Vista previa del cuadro',
        borderless: '(SIN BORDES)',
        tag_names: {
            rect: 'Rectángulo',
            ellipse: 'Óvalo',
            path: 'Trayectoria',
            polygon: 'Polígono',
            image: 'Imagen',
            text: 'Texto',
            text_path: 'Texto en Trayectoria',
            line: 'Línea',
            g: 'Grupo',
            multi_select: 'Múltiples objetos',
            use: 'Objeto importado',
            svg: 'Objeto SVG',
            dxf: 'Objeto DXF',
            no_selection: 'Sin selección',
        },
        alerts: {
            start_preview_timeout: '#803 El tiempo de espera se ha agotado al iniciar el modo de vista previa. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">esta guía</a>.',
            fail_to_start_preview: '#803 No se puede iniciar el modo de vista previa. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">esta guía</a>.',
            fail_to_connect_with_camera: '#803 No se pudo establecer conexión con la cámara de la máquina. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">esta guía</a>.',
            add_content_first: 'Por favor agregue objetos primero',
            headtype_mismatch: 'Módulo incorrecto detectado. ',
            headtype_none: 'Módulo no detectado. ',
            headtype_unknown: 'Módulo desconocido detectado. ',
            install_correct_headtype: 'Instale los módulos láser de diodo de 10W/20W correctamente para habilitar el láser para ejecutar el marco.',
            door_opened: 'Cierre la cubierta de la puerta para habilitar el láser para ejecutar el marco.',
            fail_to_get_door_status: 'Asegúrese de que la cubierta de la puerta esté cerrada para habilitar el láser para ejecutar el marco.',
            QcleanScene: '¿Quieres borrar el dibujo?<br/>¡Esto también borrará tu historial de deshacer!',
            power_too_high: 'POTENCIA DEMASIADO ALTA',
            power_too_high_msg: 'Usar una potencia de láser menor (menos del 70%) alargará la vida del tubo láser.\nPulsa "ANOTADO" para proceder.',
            power_too_high_confirm: 'ANOTADO',
        },
        hint: {
            polygon: 'Presiona la tecla + / - para aumentar / disminuir los lados.',
        },
        menu: {
            preferences: 'Preferencias',
            hide: 'Ocultar Beam Studio',
            hideothers: 'Ocultar a los demás',
            service: 'Servicios',
            quit: 'Salir',
            window: 'Ventana',
            minimize: 'Minimizar',
            close: 'Cerrar la ventana',
            file: 'Archivo',
            edit: 'Editar',
            help: 'Ayuda',
            open: 'Abrir',
            samples: 'Ejemplos',
            import_hello_beamo: 'Ejemplo de beamo',
            import_hello_beambox: 'Ejemplo de beambox',
            import_material_testing_old: 'Prueba de grabado de material - Clásico',
            import_material_testing_simple_cut: 'Prueba de corte de material - Simple',
            import_material_testing_cut: 'Prueba de corte de material',
            import_material_testing_engrave: 'Prueba de grabado de material',
            import_material_testing_line: 'Prueba de la línea de material',
            import_material_printing_test: 'Prueba de Impresión de Material',
            import_ador_laser_example: 'Ejemplo de Ador Laser',
            import_ador_printing_example_single: 'Ejemplo de Ador Impresión - Color Único',
            import_ador_printing_example_full: 'Ejemplo de Ador Impresión - Color Completo',
            import_acrylic_focus_probe: 'Cristal de Enfoque de Acrílico - 3mm',
            export_to: 'Exportar a...',
            export_flux_task: 'Tarea de FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Guardar',
            save_as: 'Guardar como...',
            save_to_cloud: 'Guardar en la nube',
            about_beam_studio: 'Acerca de Beam Studio',
            undo: 'Deshacer',
            redo: 'Rehacer',
            cut: 'Cortar',
            copy: 'Copiar',
            paste: 'Pegar',
            paste_in_place: 'Pegar en su lugar',
            group: 'Agrupar',
            ungroup: 'Desagrupar',
            delete: 'Eliminar',
            duplicate: 'Duplicar',
            offset: 'Compensar',
            scale: 'Escala',
            rotate: 'Girar',
            reset: 'Reiniciar',
            align_center: 'Alinear el centro',
            photo_edit: 'Imagen',
            svg_edit: 'SVG',
            path: 'Trayectoria',
            decompose_path: 'Descomponer',
            object: 'Objeto',
            layer_setting: 'Capa',
            layer_color_config: 'Ajustes de color',
            image_sharpen: 'Afilar',
            image_crop: 'Recortar',
            image_invert: 'Invertir',
            image_stamp: 'Biselar',
            image_vectorize: 'Trazar',
            image_curve: 'Curva',
            align_to_edges: 'Ajustar a los vértices',
            document_setting: 'Configuración del documento',
            document_setting_short: 'Documento',
            clear_scene: 'Nuevos archivos',
            machines: 'Máquinas',
            add_new_machine: 'Configuración de la máquina',
            help_center: 'Centro de ayuda',
            show_gesture_tutorial: 'Instrucciones de gestos táctiles',
            show_start_tutorial: 'Tutorial de inicio ',
            show_ui_intro: 'Mostrar introducción a la interfaz',
            questionnaire: 'Cuestionario de comentarios',
            change_logs: 'Cambiar registros',
            contact: 'Contáctanos',
            tutorial: 'Iniciar el Tutorial de Impresión de la Familia Delta',
            design_market: 'Design Market',
            forum: 'Foro de la Comunidad',
            software_update: 'Actualización del software',
            bug_report: 'Informe de errores',
            dashboard: 'Tablero de mandos',
            machine_info: 'Información de la máquina',
            network_testing: 'Prueba la configuración de la red',
            commands: 'Comandos',
            update_firmware: 'Actualizar el firmware',
            using_beam_studio_api: 'Uso de Beam Studio API',
            set_as_default: 'Establecido como predeterminado',
            calibrate_beambox_camera: 'Calibrar la cámara',
            calibrate_printer_module: 'Calibrar módulo de impresión',
            calibrate_ir_module: 'Calibrar módulo infrarrojo',
            calibrate_beambox_camera_borderless: 'Calibrar la cámara (Fondo abierto)',
            calibrate_diode_module: 'Calibrar el módulo láser de diodo',
            manage_account: 'Administrar mi cuenta',
            sign_in: 'Registrarse',
            sign_out: 'Cerrar sesión',
            account: 'Cuenta',
            my_account: 'Mi cuenta',
            download_log: 'Registros de descargas',
            download_log_canceled: 'Descarga del registro cancelada',
            download_log_error: 'Error desconocido,  inténtalo más tarde.',
            keyboard_shortcuts: 'Atajos de teclado',
            log: {
                network: 'Red',
                hardware: 'Hardware',
                discover: 'Descubre',
                usb: 'USB',
                usblist: 'Lista USB',
                camera: 'Cámara',
                cloud: 'Nube',
                player: 'Reproductor',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Vista',
            zoom_in: 'Acercar',
            zoom_out: 'Alejar',
            fit_to_window: 'Ajustar al tamaño de la ventana',
            zoom_with_window: 'Ajuste automático del tamaño de la ventana',
            borderless_mode: 'Modo sin bordes',
            show_grids: 'Mostrar las cuadrículas',
            show_rulers: 'Mostrar las reglas',
            show_layer_color: 'Usar el color de la capa',
            anti_aliasing: 'Anti-Aliasing',
            disassemble_use: 'Desmontar',
        },
        select_machine: 'Seleccione una máquina',
    },
    support: {
        no_webgl: 'WebGL no está disponible. Por favor, utiliza otros dispositivos.',
        no_vcredist: 'Por favor, instala Vitual C++ Redistribuible 2015<br/> que puede ser descargado en flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Por favor, reinicia Beam Studio.',
        OPERATION_ERROR: '[OE] Ocurrió un conflicto de estatus, por favor, reintenta la acción.',
        SUBSYSTEM_ERROR: '[SE]  Error cuando el firmware de la máquina ejecuta la tarea. Por favor, reinicia la máquina',
        UNKNOWN_COMMAND: '[UC] Por favor, actualiza el firmware del dispositivo',
    },
    device_selection: {
        no_beambox: '#801 No podemos encontrar tu máquina en la red.\nPor favor, sigue esta <a target="_blank" href="https://tupport.flux3dp.com/hc/en-us/articles/360001683556">guía</a> para solucionar el problema de conexión.',
        no_device_web: "#801 Verifique el estado de su máquina o haga clic en 'Configuración de la máquina' a continuación para configurar la máquina.",
    },
    update: {
        update: 'Actualizar',
        release_note: 'Nota de versión:',
        firmware: {
            caption: 'Una actualización del firmware de la máquina está disponible',
            message_pattern_1: '"%s" está listo para la actualización del firmware.',
            message_pattern_2: '%s Firmware v%s está ahora disponible - Tienes v%s.',
            latest_firmware: {
                caption: 'Actualización del firmware de la máquina',
                message: 'Tienes el último firmware de la máquina',
                still_update: 'ACTUALIZAR',
                cant_get_latest: 'No se puede obtener información del firmware más reciente.',
            },
            confirm: 'CARGAR',
            upload_file: 'subir el firmware (*.bin / *.fxfw)',
            update_success: 'La actualización del firmware se ha cargado con éxito',
            update_fail: '#822 Fallo de actualización',
            too_old_for_web: 'La versión actual del firmware de su máquina es v%s.\nSi quiere utilizar la versión en línea de Beam Studio,por favor actualice el firmware de la máquina a la última versión .',
            force_update_message: '#814 Por favor, actualice su máquina a la última versión del firmware.',
            firmware_too_old_update_by_sdcard: 'La versión del firmware es demasiado antigua. Actualice el firmware utilizando una tarjeta SD.',
        },
        software: {
            checking: 'Comprobando la actualización',
            switch_version: 'Cambiar la versión',
            check_update: 'Comprueba si hay alguna actualización',
            caption: 'Una actualización del software de Beam Studio está disponible',
            downloading: 'Descargando las actualizaciones en segundo plano, puedes hacer clic en "OK" para continuar tu trabajo.',
            install_or_not: 'estás listo para la actualización. ¿Te gustaría reiniciar y actualizar ahora?',
            switch_or_not: 'estás listo para el cambio. ¿Te gustaría reiniciar y cambiar ahora?',
            available_update: 'Beam Studio v%s está disponible ahora. Tienes v%s. ¿Deseas descargar la actualización?',
            available_switch: 'Beam Studio v%s ya está disponible. Tienes los v%s. ¿Quieres cambiar a esta versión?',
            not_found: 'Estás usando la última versión de Beam Studio.',
            no_response: 'No te has conectado al servidor, por favor, comprueba tu configuración de red.',
            switch_version_not_found: 'No se encontró la versión conmutable.',
            yes: 'Sí',
            no: 'No',
            update_for_ador: 'La versión actual del software %s es antigua, por favor descargue la última versión de Beam Studio para Ador.',
        },
        updating: 'Actualizando...',
        skip: 'Saltar esta versión',
        preparing: 'Preparando...',
        later: 'MÁS TARDE',
        download: 'ACTUALIZACIÓN EN LÍNEA',
        cannot_reach_internet: '#823 El servidor es inalcanzable <br/>Por favor, comprueba la conexión a Internet',
        install: 'INSTALAR',
        upload: 'CARGAR',
    },
    topmenu: {
        version: 'Versión',
        credit: 'El desarrollo de Beam Studio se basa en la asistencia del proyecto de código abierto <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> y otro <a target="_blank" href="https://flux3dp.com/credits/">software de código abierto</a>.',
        ok: 'OK',
        file: {
            label: 'Archivo',
            import: 'Importar',
            save_fcode: 'Exportar tarea de FLUX',
            save_scene: 'Guardar vista',
            save_svg: 'Exportar SVG',
            save_png: 'Exportar PNG',
            save_jpg: 'Exportar JPG',
            converting: 'Convirtiendo en imagen...',
            all_files: 'Todos los archivos',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Escena de Beam Studio',
            fcode_files: 'Código FLUX',
            clear_recent: 'Eliminar Recientemente Abiertos',
            path_not_exit: 'Esta ubicación parece no existir en el disco.',
        },
        device: {
            download_log_canceled: 'Descarga del registro cancelada',
            download_log_error: 'Ha ocurrido un error desconocido, por favor inténtalo más tarde.',
            log: {
                usblist: 'Lista USB',
            },
            network_test: 'Probar red',
        },
    },
    initialize: {
        // generic strings
        next: 'Siguiente',
        start: 'Comenzar',
        skip: 'Saltar',
        cancel: 'Cancelar',
        confirm: 'Confirmar',
        connect: 'Conectar',
        back: 'Volver',
        retry: 'Reintentar',
        no_machine: 'No tengo una máquina ahora.',
        // specific caption/content
        select_language: 'Selecciona el idioma',
        select_machine_type: 'Selecciona tu modelo',
        select_connection_type: '¿Cómo deseas conectarte?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Red cableada',
            ether2ether: 'Conexión directa',
            usb: 'Conexión USB',
        },
        connect_wifi: {
            title: 'Conectando a Wi-Fi',
            tutorial1: '1. Ve al panel táctil > Haz clic en "Red" > "Conectarse a WiFi".',
            tutorial1_ador: '1. Ve a Panel táctil > Haz clic en "MÁQUINA" > Haz clic en "Red" > "Conectar a Wi-Fi".',
            tutorial2: '2. Selecciona y conecta tu WiFi preferido.',
            what_if_1: '¿Qué pasa si no veo mi Wi-Fi?',
            what_if_1_content: '1. El modo de cifrado de la Wi-Fi debe ser WPA2 o sin contraseña.\n2. El modo de cifrado se puede establecer en la interfaz de administración del router Wi-Fi. Si el router no es compatible con WPA2 enrutador y necesitas ayuda para elegir el router adecuado, ponte en contacto con el servicio de asistencia técnica de FLUX.',
            what_if_2: '¿Qué pasa si no veo ninguna conexión Wi-Fi?',
            what_if_2_content: '1. Asegúrate de que el dongle Wi-Fi esté completamente conectado.\n2. Si no hay una dirección MAC de la red inalámbrica en la pantalla táctil, por favor contacta con el soporte de FLUX.\n3. El canal Wi-Fi debe ser de 2.4Ghz (5Ghz no es compatible).',
        },
        connect_wired: {
            title: 'Conexión a la red cableada',
            tutorial1: '1. Conecta la máquina con tu router.',
            tutorial2: '2. Presiona "Red" para obtener la IP de la red.',
            tutorial2_ador: '2. Presiona "MÁQUINA" > "Red" para obtener la IP de la red.',
            what_if_1: '¿Qué pasa si la IP está vacía?',
            what_if_1_content: '1. Asegúrate de que el cable Ethernet esté completamente enchufado.\n2. Si no hay una dirección MAC de la red alámbrica en la pantalla táctil, por favor contacta con el soporte de FLUX.',
            what_if_2: '¿Qué pasa si la IP comienza con 169?',
            what_if_2_content: '1. Si la dirección IP comienza con 169.254, debería ser un problema de configuración de DHCP, por favor contacta a tu ISP (proveedor de servicios de Internet) para mejor asistencia.',
        },
        connect_ethernet: {
            title: 'Conexión directa',
            tutorial1: '1. Conecta la máquina con tu computadora con el cable ethernet.',
            tutorial2_1: '2. Sigue ',
            tutorial2_a_text: 'esta guía',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' para hacer tu switch como un router.',
            tutorial3: '3. Haz clic en Siguiente.',
        },
        connect_usb: {
            title: 'Conexión USB',
            title_sub: ' (Solo para HEXA y Ador)',
            tutorial1: 'Conecta la máquina a tu computadora con el cable USB.',
            tutorial2: "Haz clic en 'Siguiente'.",
            turn_off_machine: 'Apague la máquina.',
            turn_on_machine: 'Encienda la máquina.',
            wait_for_turning_on: "Haz clic en 'Siguiente' después de que el proceso de inicio esté completo y hayas ingresado en la pantalla principal.",
        },
        connect_machine_ip: {
            check_usb: 'Verificar la conexión USB',
            enter_ip: 'Introduce la IP de tu máquina',
            check_ip: 'Comprobando la disponibilidad de la IP',
            invalid_ip: 'IP Inválido: ',
            invalid_format: 'Formato inválido',
            starts_with_169254: 'Comienza con 169.254',
            unreachable: 'IP inalcanzable',
            check_connection: 'Comprobación de la conexión de la máquina',
            check_firmware: 'Comprobando la versión del firmware',
            check_camera: 'Comprobando la disponibilidad de la cámara',
            retry: 'Reintentar',
            succeeded_message: 'Conectado con éxito 🎉',
            finish_setting: 'Finalizar el ajuste',
        },
        connecting: 'Conectando...',
        setting_completed: {
            start: 'Comenzar ',
            great: 'Bienvenido a Beam Studio',
            setup_later: 'Siempre puedes configurar tu máquina desde la barra de título > "Máquinas" > "Configuración de la máquina"',
            back: 'Volver',
            ok: 'COMIENZA A CREAR',
        },
    },
    error_pages: {
        screen_size: 'Ten en cuenta que Beam Studio podría no funcionar de manera óptima en este equipo. Para una mejor experiencia se recomienda una pantalla de al menos 1024 pixeles de ancho.',
    },
    menu: {
        mm: 'mm',
        inches: 'Pulgadas',
    },
    settings: {
        on: 'Encendido',
        off: 'Apagado',
        low: 'Bajo',
        high: 'Normal',
        caption: 'Ajustes',
        tabs: {
            general: 'General',
            device: 'Máquina',
        },
        ip: 'Dirección IP de la máquina',
        guess_poke: 'Buscar la IP de la máquina',
        auto_connect: 'Selecciona automáticamente la única máquina',
        wrong_ip_format: 'Formatos IP incorrectos',
        default_machine: 'Máquina por defecto',
        default_machine_button: 'Vacío',
        remove_default_machine_button: 'Eliminar',
        confirm_remove_default: 'La máquina predeterminada va a ser eliminada.',
        reset: 'Reiniciar Beam Studio',
        reset_now: 'Reiniciar Beam Studio',
        confirm_reset: '¿Confirma el restablecimiento de Beam Studio?',
        language: 'Idioma',
        notifications: 'Notificaciones de escritorio',
        check_updates: 'Comprobación automática',
        autosave_enabled: 'Guardado Automático',
        autosave_path: 'Guardado Automático Ubicación',
        autosave_interval: 'Guardar Cada',
        autosave_number: 'Número de Guardado Automático',
        autosave_path_not_correct: 'No se encontró la ruta de acceso especificada.',
        preview_movement_speed: 'Previtualización de la velocidad del movimiento',
        preview_movement_speed_hl: 'Previtualización de la velocidad de movimiento (Láser diodo activado)',
        default_units: 'Unidades por defecto',
        default_font_family: 'Fuente por defecto',
        default_font_style: 'Estilo Fuente por defecto',
        fast_gradient: 'Optimización de la velocidad',
        engraving_direction: 'Dirección de grabado',
        top_down: 'De arriba para abajo',
        bottom_up: 'De abajo hacia arriba',
        vector_speed_constraint: 'Límite de velocidad (20mm/s)',
        loop_compensation: 'Compensación del bucle',
        blade_radius: 'Radio de la cuchilla',
        blade_precut_switch: 'Precorte de la cuchilla',
        blade_precut_position: 'Posición de precorte',
        default_beambox_model: 'Configuración de documentos por defecto',
        guides_origin: 'Guías de origen',
        guides: 'Guías',
        image_downsampling: 'Calidad de la previtualización de los mapas de bits',
        anti_aliasing: 'Suavizado',
        continuous_drawing: 'Dibujo continuo',
        trace_output: 'Salida de Trazado / Imagen de rastro',
        single_object: 'Objeto único',
        grouped_objects: 'Objetos agrupados',
        simplify_clipper_path: 'Optimizar la trayectoria calculada',
        enable_low_speed: 'Activar movimiento lento',
        enable_custom_backlash: 'Activar la compensación de retroceso',
        custom_preview_height: 'Altura de la previsualización',
        mask: 'Recorte del área de trabajo',
        text_path_calc_optimization: 'Optimización del cálculo de la trayectoria',
        font_substitute: 'sustituir los caracteres no compatibles',
        font_convert: 'Convertidor de texto a trayectoria',
        default_borderless_mode: 'Abrir fondo por defecto',
        default_enable_autofocus_module: 'Autoenfoque predeterminado',
        default_enable_diode_module: 'Láser diodo por defecto',
        diode_offset: 'Compensación de láser diodo',
        autofocus_offset: 'Desplazamiento de Enfoque Automático',
        diode_one_way_engraving: 'Grabado unidireccional con láser de diodo',
        diode_two_way_warning: 'La máquina puede emitir luz en ambas direcciones y la velocidad es más rápida, por lo que puede causar errores de posición durante el grabado. Se recomienda utilizar materiales de prueba primero.',
        share_with_flux: 'Compartir Beam Studio Analytics',
        none: 'Ninguno',
        close: 'Cerrar',
        enabled: 'Habilitado',
        disabled: 'Deshabilitado',
        cancel: 'Cancelar',
        done: 'Aplicar',
        module_offset_10w: 'Desplazamiento del láser de diodo de 10W',
        module_offset_20w: 'Desplazamiento del láser de diodo de 20W',
        module_offset_printer: 'Desplazamiento de impresora',
        module_offset_2w_ir: 'Desplazamiento del láser infrarrojo de 2W',
        printer_advanced_mode: 'Modo Avanzado de Impresión',
        default_laser_module: 'Módulo láser predeterminado',
        low_laser_for_preview: 'Láser para Vista previa del cuadro',
        groups: {
            general: 'General',
            update: 'Actualizaciones de software',
            connection: 'Conexión',
            autosave: 'Guardado Automático',
            camera: 'Cámara',
            editor: 'Editor',
            engraving: 'Rasterización (Escaneo)',
            path: 'Vector (Esquemas)',
            mask: 'Recorte del área de trabajo',
            text_to_path: 'Texto',
            modules: 'Añadir',
            ador_modules: 'Módulo de Ador',
            privacy: 'Privacidad',
        },
        notification_on: 'Encendido',
        notification_off: 'Apagado',
        update_latest: 'Último',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Grupo',
            use: 'Importación SVG',
            image: 'Imagen',
            text: 'Texto',
        },
        context_menu: {
            cut: 'Cortar',
            copy: 'Copiar',
            paste: 'Pegar',
            paste_in_place: 'Pegar en su lugar',
            duplicate: 'Duplicar',
            delete: 'Eliminar',
            group: 'Agrupar',
            ungroup: 'Desagrupar',
            move_front: 'Traer al frente',
            move_up: 'Adelantar',
            move_down: 'Enviar atrás',
            move_back: 'Enviar al fondo',
        },
        popup: {
            select_import_method: 'Selecciona el estilo de capas:',
            select_import_module: 'Seleccionar módulo:',
            touchpad: 'TouchPad',
            mouse: 'Ratón',
            layer_by_layer: 'Capa',
            layer_by_color: 'Color',
            nolayer: 'Una sola capa',
            loading_image: 'Cargando imagen, por favor espera...',
            no_support_text: 'Beam Studio no soporta actualmente la etiqueta de texto. Por favor, transfiere el texto a la ruta antes de importarlo.',
            speed_too_high_lower_the_quality: 'El uso de una velocidad demasiado alta en esta resolución puede resultar en una menor calidad de grabado de sombreado.',
            both_power_and_speed_too_high: 'El uso de una menor potencia de láser extenderá la vida útil del tubo láser.\nAdemás, una velocidad demasiado alta a esta resolución puede reducir la calidad del grabado de sombreado.',
            too_fast_for_path: 'El uso de una velocidad demasiado alta en capas que contienen objetos de trayectoria puede resultar en una menor precisión al cortar.\nNo recomendamos utilizar una velocidad superior a 20 mm/s al cortar.',
            too_fast_for_path_and_constrain: 'Capas siguientes: %s\nLa velocidad de corte de los objetos de trayectoria vectorial se limitará a 20 mm/s.\nLa velocidad de corte de los objetos de trayectoria vectorial se limitará a 20 mm/s.\nPuedes eliminar este límite en la Configuración de las Preferencias.',
            should_update_firmware_to_continue: '#814 tu firmware no es compatible con esta versión de Beam Studio. Por favor, actualiza el firmware para continuar. (Menú > Máquina > [tu máquina] > Actualizar el firmware)',
            recommend_downgrade_software: 'Detectamos una versión más antigua del firmware. Estamos abordando activamente problemas de compatibilidad, pero por ahora, recomendamos volver a <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Hemos detectado una versión de firmware más antigua. Estamos abordando activamente problemas de compatibilidad, pero por ahora, recomendamos actualizar al firmware más reciente.',
            still_continue: 'Continuar',
            more_than_two_object: 'Demasiados objetos. Sólo soporte para 2 objetos',
            not_support_object_type: 'No apoyar el tipo de objeto',
            select_first: 'Selecciona un objeto primero.',
            select_at_least_two: 'Selecciona dos objetos para proceder',
            import_file_contain_invalid_path: '#808 El archivo SVG importado contiene una ruta de imagen inválida. Por favor, asegúrese de que todos los archivos de imagen existen o incrustar la imagen en el archivo',
            import_file_error_ask_for_upload: 'No se importó el archivo SVG. ¿Estás dispuesto a proporcionar el archivo para desarrollar el equipo para el informe de errores?',
            upload_file_too_large: '#819 El archivo es demasiado grande para subirlo.',
            successfully_uploaded: 'La carga del archivo se realizó con éxito.',
            upload_failed: '#819 La carga del archivo falló.',
            or_turn_off_borderless_mode: ' O desactivar el modo Abrir fondo.',
            svg_1_1_waring: 'La versión de este archivo SVG es la v 1.1, podría haber potenciales problemas de incompatibilidad.',
            svg_image_path_waring: 'Este archivo SVG contiene <image> cargando desde la ruta del archivo. Esto puede causar fallos al cargar.\nPara evitar este riesgo, por favor, utiliza la imagen incrustada al exportar SVG.',
            dxf_version_waring: 'La versión de este archivo DXF no es la 2013, podría haber potenciales problemas de incompatibilidad.',
            dont_show_again: 'No lo muestres la próxima vez.',
            convert_to_path_fail: 'No se ha podido convertir a la ruta.',
            save_unsave_changed: '¿Quieres guardar los cambios no guardados?',
            dxf_bounding_box_size_over: 'El tamaño del dibujo está fuera del área de trabajo. Por favor, acerca tu dibujo al origen en tu software de CAD, o asegúrate de que la unidad esté ajustada correctamente.',
            progress: {
                uploading: 'Cargando',
                calculating: 'Calculando',
            },
            backend_connect_failed_ask_to_upload: '#802 Los errores siguen ocurriendo cuando se intenta conectar con el backend. ¿Quieres tubir tu registro de informe de errores?',
            backend_error_hint: 'Es posible que las funciones no estén funcionando correctamente debido a un error en el backend.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Error al convertir PDF a SVG:',
                error_pdf2svg_not_found: '#825 Error: Comando pdf2svg no encontrado. Por favor, instala pdf2svg con tu gestor de paquetes (por ejemplo, "yum install pdf2svg" o "apt-get install pdf2svg").',
            },
            ungroup_use: 'Esto desagrupará los DXF o SVG importados. Debido a que el archivo puede contener una gran cantidad de elementos, puede llevar tiempo desagruparlo. ¿Estás seguro de proceder?',
            vectorize_shading_image: 'Las imágenes con gradientes tardan más tiempo en ser trazadas y son propensas al ruido. Por favor, apaga el gradiente de la imagen antes de ejecutarla.',
            change_workarea_before_preview: 'El área de trabajo de %s no coincide con el área de trabajo configurada actualmente. ¿Quiere cambiar el área de trabajo actual?',
            bug_report: 'Informe de error',
            sentry: {
                title: 'Hagamos juntos Beam Studio mejor',
                message: '¿Acepta cargar automáticamente información relevante al equipo de desarrollo cuando se produzcan errores?',
            },
            questionnaire: {
                caption: 'Ayúdanos a completar el cuestionario',
                message: 'Ayúdanos a completar el cuestionario para mejorar Beam Studio.',
                unable_to_get_url: 'No se pudo obtener el enlace al cuestionario a través de Internet. Confirme su conexión a Internet.',
                no_questionnaire_available: 'No hay ningún cuestionario disponible en este momento.',
            },
            facebook_group_invitation: {
                title: 'Únete al grupo oficial de usuarios de FLUX',
                message: 'Únete a nuestro grupo oficial de Facebook para conectar con otros usuarios de láser FLUX, hablar de los láseres FLUX, compartir ilustraciones de láser y estar al día de las últimas actualizaciones de nuestros productos. ¡Estamos deseando verte por allí!',
                join_now: 'Únete ahora',
                later: 'Tal vez más tarde',
                already_joined: 'Ya estoy inscrito',
                dont_show_again: 'No volver a mostrar esto',
            },
            ai_credit: {
                relogin_to_use: 'Por favor, vuelva a iniciar sesión para usar esta función.',
                insufficient_credit: 'Te quedaste sin creditos',
                insufficient_credit_msg: 'No puede usar %s Vaya al centro de miembros y compre AI Credit.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Ir',
            },
            text_to_path: {
                caption: 'Convertidor de texto a trayectoria 2.0',
                message: '¡Beam Studio ahora presenta un nuevo convertidor de texto a trayectoria (Convertidor 2.0), que produce resultados más confiables! ¿Le gustaría cambiar a él ahora? \nTambién puede ajustar esta configuración más tarde en la sección "Convertidor de texto a trayectoria" dentro de las preferencias.',
            },
        },
        zoom_block: {
            fit_to_window: 'Ajustar a la ventana',
        },
        time_est_button: {
            calculate: 'Calcular el tiempo',
            estimate_time: 'Tiempo estimado necesario:',
        },
        left_panel: {
            unpreviewable_area: 'Área ciega',
            diode_blind_area: 'Área ciega de adición de láser híbrido',
            borderless_blind_area: 'Área no grabada',
            borderless_preview: 'Vista previa de la cámara en modo sin bordes',
            rectangle: 'Rectángulo',
            ellipse: 'Elipse',
            line: 'Línea',
            image: 'Imagen',
            text: 'Texto',
            label: {
                cursor: 'Seleccionar',
                photo: 'Imagen',
                text: 'Texto',
                line: 'Línea',
                rect: 'Rectángulo',
                oval: 'Óvalo',
                polygon: 'Polígono',
                pen: 'Pluma',
                shapes: 'Elementos',
                array: 'Gama',
                preview: 'Vista previa de la cámara',
                trace: 'Imagen de rastro',
                end_preview: 'Finalizar vista previa',
                clear_preview: 'Vista previa clara',
                choose_camera: 'Cámara',
                live_feed: 'Transmisión en vivo',
                adjust_height: 'Ajustar altura',
                qr_code: 'Código QR',
                boxgen: 'Boxgen',
                my_cloud: 'Mi nube',
                curve_engraving: {
                    title: 'Curva 3D',
                    exit: 'Salir',
                    select_area: 'Seleccionar área',
                    preview_3d_curve: 'Vista previa de curva 3D',
                    clear_area: 'Borrar área seleccionada',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Layers',
                objects: 'Objetos',
                path_edit: 'Editar Trayectoria',
            },
            layer_panel: {
                layer1: 'Capa 1',
                layer_bitmap: 'Mapa de bits',
                layer_engraving: 'Grabado',
                layer_cutting: 'Corte',
                current_layer: 'Capa actual',
                move_elems_to: 'Mover los elementos a:',
                notification: {
                    dupeLayerName: '¡Ya hay una capa llamada así!',
                    newName: 'NUEVO NOMBRE',
                    enterUniqueLayerName: 'Por favor, introduce un nombre de capa único',
                    enterNewLayerName: 'Por favor, introduce el nuevo nombre de la capa',
                    layerHasThatName: 'La capa ya tiene ese nombre',
                    QmoveElemsToLayer: "¿Mover los elementos seleccionados a la capa '%s'?",
                    moveElemFromPrintingLayerTitle: '¿Mover el elemento seleccionado a %s y convertirlo en un elemento láser?',
                    moveElemFromPrintingLayerMsg: 'Tenga en cuenta que si completa esta operación, la configuración de color del elemento seleccionado se eliminará y se establecerá según %s.',
                    moveElemToPrintingLayerTitle: '¿Mover el elemento seleccionado a %s y convertirlo en un elemento de impresión?',
                    moveElemToPrintingLayerMsg: 'Tenga en cuenta que si completa esta operación, la configuración del elemento seleccionado se eliminará y se establecerá según %s.',
                    splitColorTitle: '¿Expandir la capa seleccionada en capas CMYK?',
                    splitColorMsg: 'Tenga en cuenta que si continúa con esta operación, no podrá volver a las capas de color originales.',
                    mergeLaserLayerToPrintingLayerTitle: '¿Deseas fusionar las capas en una capa de impresión?',
                    mergeLaserLayerToPrintingLayerMsg: 'Ten en cuenta que si completas este procedimiento, se eliminarán y ajustarán las configuraciones de la capa láser según la capa actual.',
                    mergePrintingLayerToLaserLayerTitle: '¿Desea fusionar estas capas en una capa de láser?',
                    mergePrintingLayerToLaserLayerMsg: 'Ten en cuenta que si completas este procedimiento, se eliminarán y ajustarán las configuraciones de color de la capa de impresión según la capa actual.',
                },
                layers: {
                    layer: 'Capa',
                    layers: 'Capas',
                    del: 'Eliminar la capa',
                    move_down: 'Mover la capa hacia abajo',
                    new: 'Nueva capa',
                    rename: 'Renombrar la capa',
                    move_up: 'Mover la capa hacia arriba',
                    dupe: 'Duplicar la capa',
                    lock: 'Bloquear capa',
                    unlock: 'Desbloquear',
                    merge_down: 'Fusionarse',
                    merge_all: 'Fusionar todo',
                    merge_selected: 'Fusionar capas seleccionadas',
                    move_elems_to: 'Mover los elementos a:',
                    move_selected: 'Mover los elementos seleccionados a una capa diferente',
                    switchToFullColor: 'Cambiar a capa a color completo',
                    switchToSingleColor: 'Cambiar a capa a color único',
                    splitFullColor: 'Expandir capa',
                    fullColor: 'Color completo',
                },
            },
            laser_panel: {
                preset_setting: 'Ajuste de parámetros (%s)',
                multi_layer: 'Capas múltiples',
                parameters: 'Parámetros',
                strength: 'Poder',
                low_power_warning: 'Es posible que la potencia del láser más baja (menos del 10 %) no emita la luz del láser.',
                speed: 'Velocidad',
                speed_contrain_warning: 'La velocidad de corte de los objetos de trayectoria vectorial se limitará a 20mm/s (0.79in/s). Puede eliminar este límite en Configuración de preferencias.',
                low_speed_warning: 'La velocidad baja puede causar quemaduras en el material.',
                repeat: 'Ejecutar',
                add_on: 'Accesorio',
                focus_adjustment: 'Ajuste del enfoque',
                height: 'La altura del objeto',
                z_step: 'Paso Z',
                diode: 'Láser de diodo',
                backlash: 'Holgura',
                ink_saturation: 'Saturación',
                print_multipass: 'Multipaso',
                white_ink: 'Tinta Blanca',
                white_ink_settings: 'Configuración de Tinta Blanca',
                color_adjustment: 'Ajuste de canal',
                color_adjustment_short: 'Canal',
                halftone: 'Semitono',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Intensidad',
                times: 'veces',
                cut: 'Cortar',
                engrave: 'Grabar',
                more: 'Gestionar',
                delete: 'Eliminar',
                reset: 'Reiniciar',
                sure_to_reset: 'Esto reiniciará todos los preajustes y mantendrá tus parámetros personalizados, ¿estás seguro de proceder?',
                apply: 'Aplicar',
                cancel: 'Cancelar',
                save: 'Guardar',
                save_and_exit: 'Guardar y Salir',
                name: 'Nombre',
                default: 'Por defecto',
                customized: 'Lista personalizada',
                inuse: 'Usando',
                export_config: 'Exportar preajustes',
                new_config_name: 'Nuevo nombre preestablecido',
                sure_to_load_config: 'Esto cargará la disposición de los preajustes y reemplazará los parámetros personalizados establecidos en el archivo, ¿estás seguro de proceder?',
                custom_preset: 'Personalizado',
                various_preset: 'Varios preajustes',
                module: 'Módulo',
                slider: {
                    regular: 'Regular',
                    low: 'Bajo',
                    very_low: 'Mínimo',
                    high: 'Alto',
                    very_high: 'Máximo',
                    slow: 'Lento',
                    very_slow: 'Más Lento',
                    fast: 'Rápido',
                    very_fast: 'Más Rápido',
                },
                dropdown: {
                    parameters: 'Preajustes',
                    save: 'Añade los parámetros actuales',
                    export: 'Exportar',
                    import: 'Importar',
                    more: 'Gestionar',
                    mm: {
                        wood_3mm_cutting: 'Madera - Corte de 3mm',
                        wood_5mm_cutting: 'Madera -  Corte de 5mm',
                        wood_7mm_cutting: 'Madera -  Corte de 7mm',
                        wood_8mm_cutting: 'Madera -  Corte de 8mm',
                        wood_10mm_cutting: 'Madera -  Corte de 10mm',
                        wood_engraving: 'Madera - Grabado',
                        acrylic_3mm_cutting: 'Acrílico - Corte de 3mm',
                        acrylic_5mm_cutting: 'Acrílico -  Corte de 5mm',
                        acrylic_8mm_cutting: 'Acrílico - Corte de 8mm',
                        acrylic_10mm_cutting: 'Acrílico -  Corte de 10mm',
                        acrylic_engraving: 'Acrílico - Grabado',
                        mdf_3mm_cutting: 'MDF de Nueva Zelanda - Corte de 3mm',
                        mdf_5mm_cutting: 'MDF de Nueva Zelanda - Corte de 5mm',
                        mdf_engraving: 'MDF de Nueva Zelanda - Grabado',
                        leather_3mm_cutting: 'Cuero - Corte de 3mm',
                        leather_5mm_cutting: 'Cuero -  Corte de 5mm',
                        leather_engraving: 'Cuero - Grabado',
                        denim_1mm_cutting: 'Mezclilla - Corte de 1mm',
                        fabric_3mm_cutting: 'Tejido -  Corte de 3mm',
                        fabric_5mm_cutting: 'Tejido -  Corte de 5mm',
                        fabric_engraving: 'Tejido - Grabado',
                        rubber_bw_engraving: 'Goma - Grabado',
                        glass_bw_engraving: 'Vidrio - Grabado',
                        metal_bw_engraving: 'Metal - Grabado',
                        steel_engraving_spray_engraving: 'Metal - Grabado',
                        stainless_steel_bw_engraving_diode: 'Metal - Grabado (Láser Diodo)',
                        gold_engraving: 'Oro - Grabado',
                        brass_engraving: 'Latón - Grabado',
                        ti_engraving: 'Titanio - Grabado',
                        stainless_steel_engraving: 'Acero inoxidable - Grabado',
                        aluminum_engraving: 'Aluminio - Grabado',
                        black_acrylic_3mm_cutting: 'Acrílico negro - Corte de 3mm',
                        black_acrylic_5mm_cutting: 'Acrílico negro -  Corte de 5mm',
                        black_acrylic_engraving: 'Acrílico negro - Grabado',
                        abs_engraving: 'ABS - Grabado',
                        silver_engraving: 'Plata - Grabado',
                        iron_engraving: 'Hierro - Grabado',
                        fabric_printing: 'Tejido - Impresión',
                        canvas_printing: 'Lienzo - Impresión',
                        cardstock_printing: 'Cartulina - Impresión',
                        wood_printing: 'Madera - Impresión',
                        bamboo_printing: 'Bambú - Impresión',
                        cork_printing: 'Corcho - Impresión',
                        flat_stone_printing: 'Piedra plana - Impresión',
                        acrylic_printing: 'Acrílico - Impresión',
                        pc_printing: 'PC - Impresión',
                        stainless_steel_printing: 'Acero inoxidable - Impresión',
                        gloss_leather_printing: 'Piel brillante - Impresión',
                        glass_printing: 'Vidrio - Impresión',
                    },
                    inches: {
                        wood_3mm_cutting: "Madera - Corte de 0.1''",
                        wood_5mm_cutting: "Madera - Corte de 0.2''",
                        wood_7mm_cutting: "Madera - Corte de 0.3''",
                        wood_8mm_cutting: "Madera - Corte de 0.3''",
                        wood_10mm_cutting: "Madera - Corte de 0.4''",
                        wood_engraving: 'Madera - Grabado',
                        acrylic_3mm_cutting: "Acrílico - Corte de 0.1''",
                        acrylic_5mm_cutting: "Acrílico - Corte de 0.2''",
                        acrylic_8mm_cutting: "Acrílico - Corte de 0.3''",
                        acrylic_10mm_cutting: "Acrílico - Corte de 0.4''",
                        acrylic_engraving: 'Acrílico - Grabado',
                        mdf_3mm_cutting: "MDF de Nueva Zelanda - Corte de 0.1''",
                        mdf_5mm_cutting: "MDF de Nueva Zelanda - Corte de 0.2''",
                        mdf_engraving: 'MDF de Nueva Zelanda - Grabado',
                        leather_3mm_cutting: "Cuero - Corte de 0.1''",
                        leather_5mm_cutting: "Cuero - Corte de 0.2''",
                        leather_engraving: 'Cuero - Grabado',
                        denim_1mm_cutting: "Mezclilla - Corte de 0.04''",
                        fabric_3mm_cutting: "Tejido - Corte de 0.1''",
                        fabric_5mm_cutting: "Tejido - Corte de  0.2''",
                        fabric_engraving: 'Tejido - Grabado',
                        rubber_bw_engraving: 'Goma - Grabado',
                        glass_bw_engraving: 'Vidrio - Grabado',
                        metal_bw_engraving: 'Metal - Grabado',
                        steel_engraving_spray_engraving: 'Metal - Grabado',
                        stainless_steel_bw_engraving_diode: 'Metal - Grabado (Láser Diodo)',
                        gold_engraving: 'Oro - Grabado',
                        brass_engraving: 'Latón - Grabado',
                        ti_engraving: 'Titanio - Grabado',
                        stainless_steel_engraving: 'Acero inoxidable - Grabado',
                        aluminum_engraving: 'Aluminio - Grabado',
                        black_acrylic_3mm_cutting: "Acrílico negro - Corte de 0.1''",
                        black_acrylic_5mm_cutting: "Acrílico negro - Corte de 0.2''",
                        black_acrylic_engraving: 'Acrílico negro - Grabado',
                        abs_engraving: 'ABS - Grabado',
                        silver_engraving: 'Plata - Grabado',
                        iron_engraving: 'Hierro - Grabado',
                        fabric_printing: 'Tejido - Impresión',
                        canvas_printing: 'Lienzo - Impresión',
                        cardstock_printing: 'Cartulina - Impresión',
                        wood_printing: 'Madera - Impresión',
                        bamboo_printing: 'Bambú - Impresión',
                        cork_printing: 'Corcho - Impresión',
                        flat_stone_printing: 'Piedra plana - Impresión',
                        acrylic_printing: 'Acrílico - Impresión',
                        pc_printing: 'PC - Impresión',
                        stainless_steel_printing: 'Acero inoxidable - Impresión',
                        gloss_leather_printing: 'Piel brillante - Impresión',
                        glass_printing: 'Vidrio - Impresión',
                    },
                },
                laser_speed: {
                    text: 'Velocidad',
                    unit: 'mm/s',
                    fast: 'Rápido',
                    slow: 'Lento',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Power',
                    high: 'Alto',
                    low: 'Bajo',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Tipo de tinta',
                    normal: 'Tinta ordinaria',
                    UV: 'Tinta UV',
                },
                para_in_use: 'Este parámetro está siendo utilizado.',
                do_not_adjust_default_para: 'Los preajustes no pueden ser ajustados.',
                existing_name: 'Se ha utilizado este nombre.',
                presets: 'Preestablecido',
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Agrupar',
                ungroup: 'Desagrupar',
                distribute: 'Distribuir',
                hdist: 'Distribuir horizontalmente',
                vdist: 'Distribuir verticalmente',
                align: 'Alinear',
                left_align: 'Alinear a la izquierda',
                center_align: 'Alinear el centro',
                right_align: 'Alinear a la derecha',
                top_align: 'Alinear la parte superior',
                middle_align: 'Alinear el medio',
                bottom_align: 'Alinear la parte inferior',
                boolean: 'Booleano',
                union: 'Unir',
                subtract: 'Sustraer',
                intersect: 'Cortar',
                difference: 'Diferencia',
                flip: 'Voltear',
                hflip: 'Volteo horizontal',
                vflip: 'Volteo vertical',
                lock_aspect: 'Bloquear relación de aspecto',
                unlock_aspect: 'Desbloquear relación de aspecto',
                option_panel: {
                    fill: 'Relleno',
                    rounded_corner: 'Esquina redondeada',
                    sides: 'Lados',
                    font_family: 'Fuente',
                    font_style: 'Estilo',
                    font_size: 'Tamaño',
                    letter_spacing: 'El espaciado de las letras',
                    line_spacing: 'El espaciado de las líneas',
                    vertical_text: 'Texto vertical',
                    start_offset: 'Offset de Texto',
                    vertical_align: 'Alineación',
                    text_infill: 'Relleno de texto',
                    path_infill: 'Relleno de trayectoria',
                    shading: 'Gradiente',
                    threshold: 'Brillo de umbral',
                    threshold_short: 'Umbral',
                    stroke: 'Borde',
                    stroke_color: 'Color del borde',
                    stroke_width: 'Ancho del borde',
                    color: 'Color',
                },
                actions_panel: {
                    replace_with: 'Reemplazar con...',
                    replace_with_short: 'Reemplazar',
                    trace: 'Trazado',
                    grading: 'Calificación',
                    brightness: 'Brillo',
                    sharpen: 'Afilar',
                    crop: 'Recortar',
                    bevel: 'Biselar',
                    invert: 'Invertir',
                    convert_to_path: 'Convertir en trayectoria',
                    weld_text: 'Soldar texto',
                    fetching_web_font: 'Obteniendo la fuente en línea...',
                    uploading_font_to_machine: 'Cargando la fuente a la máquina ...',
                    wait_for_parsing_font: 'Fuente de análisis... Por favor, espera un segundo.',
                    offset: 'Offset',
                    array: 'Gama',
                    auto_arrange: 'Organización Automática',
                    decompose_path: 'Descomponer',
                    disassemble_use: 'Desmontar',
                    create_textpath: 'Crear Tecto de Trayectoria',
                    create_textpath_short: 'Texto camino',
                    detach_path: 'Disolver el Texto de Trayectoria',
                    detach_path_short: 'Descomponer',
                    edit_path: 'Editar trayectoria',
                    disassembling: 'Desmontar...',
                    ungrouping: 'Desagrupando...',
                    simplify: 'Simplificar',
                    ai_bg_removal: 'Background Removal',
                    ai_bg_removal_short: 'Elim. Fondo',
                    ai_bg_removal_reminder: 'Presionar el botón usará inmediatamente 0.2 Credit, ¿quieres continuar?',
                    outline: 'Contornear',
                },
                path_edit_panel: {
                    node_type: 'TIPO DE NODO',
                    sharp: 'Afilado',
                    round: 'Suave',
                    connect: 'Conectar',
                    disconnect: 'Desconectar',
                    delete: 'Eliminar',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Convierte el texto en un camino...',
            retreive_image_data: 'Recuperar datos de imagen...',
            export_file_error_ask_for_upload: 'No se ha podido exportar la tarea. ¿Estás dispuesto a proporcionar una escena de trabajo para desarrollar el informe de errores?',
        },
        image_trace_panel: {
            apply: 'Aplicar',
            back: 'Volver',
            cancel: 'Cancelar',
            next: 'Siguiente',
            brightness: 'Brillo',
            contrast: 'Contraste',
            threshold: 'Umbral',
            okay: 'Bien',
            tuning: 'Parámetros',
        },
        photo_edit_panel: {
            apply: 'Aplicar',
            back: 'Volver',
            cancel: 'Cancelar',
            next: 'Siguiente',
            sharpen: 'Afila',
            sharpness: 'Nitidez',
            radius: 'Radio',
            crop: 'Crop',
            aspect_ratio: 'Relación',
            original: 'Tamaño Org.',
            free: 'Libre',
            curve: 'Curva',
            start: 'Comienza',
            processing: 'Procesando',
            invert: 'Invertir el color',
            okay: 'Bien',
            compare: 'Comparar',
            phote_edit: 'Edición de fotos',
            brightness_and_contrast: 'Brillo / Contraste',
            brightness: 'Brillo',
            contrast: 'Contraste',
        },
        document_panel: {
            document_settings: 'Configuración del documento',
            engrave_parameters: 'Parámetros de grabado',
            workarea: 'Área de trabajo',
            rotary_mode: 'Rotatorio',
            borderless_mode: 'Fondo abierto',
            engrave_dpi: 'Resolución',
            enable_diode: 'Láser diodo',
            enable_autofocus: 'Autoenfoque',
            extend_workarea: 'Ampliar área de trabajo',
            add_on: 'Complementos',
            low: 'Bajo',
            medium: 'Medio',
            high: 'Alto',
            ultra: 'Ultra Alto',
            enable: 'Habilitar',
            disable: 'Deshabilitar',
            cancel: 'Cancelar',
            save: 'Guardar',
            notification: {
                changeFromPrintingWorkareaTitle: '¿Desea convertir las capas de impresión en capas láser?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Fuente de análisis... Por favor, espera un segundo.',
            text_to_path: {
                font_substitute_pop: 'Su texto contiene caracteres que no son compatibles con la fuente actual. <br/>¿Le gustaría usar <strong>%s</strong> como sustituto?',
                check_thumbnail_warning: 'Algunos textos fueron cambiados a otras fuentes cuando se analizan los textos en las rutas y algunos caracteres no se convierten normalmente.\nPor favor, comprueba otra vez la imagen de vista previa antes de enviar la tarea.',
                error_when_parsing_text: 'Error al convertir texto a ruta',
                use_current_font: 'Usar fuente actual',
                retry: 'Por favor, inténtelo de nuevo más tarde o elija otra fuente',
            },
            lock_desc: 'Preservar la relación entre anchura y altura (SHIFT)',
        },
        tool_panels: {
            cancel: 'Cancelar',
            confirm: 'Confirmar',
            grid_array: 'Crear una matriz de cuadrícula',
            array_dimension: 'Dimensión de la matriz',
            rows: 'Filas',
            columns: 'Cols.',
            array_interval: 'Intervalo de la matriz',
            dx: 'X',
            dy: 'Y',
            offset: 'Offset',
            nest: 'Optimización de la disposición',
            _offset: {
                direction: 'Dirección de compensación',
                inward: 'Hacia adentro',
                outward: 'Hacia Afuera',
                dist: 'Distancia de compensación',
                corner_type: 'Esquina',
                sharp: 'Agudo',
                round: 'Curvo',
                fail_message: 'No se ha logrado compensar los objetos.',
                not_support_message: 'Elementos seleccionados que contienen SVG no soportados tag:\nImagen, Grupo, Texto, Objeto importado',
            },
            _nest: {
                start_nest: 'Arreglar',
                stop_nest: 'Detente',
                end: 'Detente',
                spacing: 'Espaciamiento',
                rotations: 'Posible rotación',
                no_element: 'No hay ningún elemento que arreglar.',
            },
        },
        network_testing_panel: {
            network_testing: 'Prueba de la red',
            local_ip: 'Dirección IP local:',
            insert_ip: 'Dirección IP del dispositivo de destino:',
            empty_ip: '#818 Por favor, introduce primero la IP del dispositivo objetivo.',
            start: 'Iniciar',
            end: 'Fin',
            testing: 'Probando red...',
            invalid_ip: '#818 Dirección IP inválida',
            ip_startswith_169: '#843 La dirección IP de la máquina comienza con 169.254',
            connection_quality: 'Calidad de la conexión',
            average_response: 'Tiempo de respuesta promedio',
            test_completed: 'Prueba completada',
            test_fail: 'La prueba falló',
            cannot_connect_1: '#840 Fallo en la conexión con el IP del objetivo.',
            cannot_connect_2: '#840 Fallo en la conexión a la IP de destino. Por favor, asegúrate de que el objetivo está en la misma red.',
            network_unhealthy: '#841 Calidad de la conexión <70 o tiempo de respuesta promedio >100ms',
            device_not_on_list: '#842 La máquina no está en la lista, pero la calidad de la conexión es >70 y el tiempo de respuesta promedio es <100ms',
            hint_device_often_on_list: 'La máquina a menudo no se encuentra en la lista...',
            link_device_often_on_list: 'https://tupport.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: '¿Falló la conexión al enviar un trabajo?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: '¿El tiempo de espera se produce al iniciar la vista previa de la cámara?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'El acceso a la dirección IP local falló.',
            fail_to_start_network_test: '#817 Fallo al iniciar las pruebas de red.',
            linux_permission_hint: 'Este error suele ocurrir debido a permisos insuficientes.\nPor favor ejecute "sudo beam-studio --no-sandbox" en la terminal para obtener permisos y realizar pruebas de red.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Configuraciones de color de las capas',
            color: 'Color',
            power: 'Power',
            speed: 'Velocidad',
            repeat: 'Ejecutar',
            add: 'Añadir',
            save: 'Guardar',
            cancel: 'Cancelar',
            default: 'Restablecer el valor predeterminado',
            add_config: 'Agregar el color.',
            in_use: 'Este color está en uso.',
            no_input: 'Por favor, introduce un código de color hexadecimal válido.',
            sure_to_reset: 'Perderás todos los parámetros personalizados, ¿estás seguro de volver a los valores por defecto?',
            sure_to_delete: '¿Está seguro de eliminar este ajuste de color?Are you sure to delete this color setting ?',
        },
        rating_panel: {
            title: '¿Te gusta Beam Studio?',
            description: 'Si te gusta Beam Studio, agradeceríamos que compartieras tu experiencia.',
            dont_show_again: 'No lo muestres la próxima vez.',
            thank_you: 'Gracias',
        },
        svg_editor: {
            unnsupported_file_type: 'El tipo de archivo no está admitido directamente. Por favor, convierte el archivo en SVG o mapa de bits',
            unable_to_fetch_clipboard_img: 'No se ha podido recuperar la imagen de tu portapapeles',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Reproducir',
            pause: 'Pausa',
            stop: 'Detener',
            play_speed: 'Velocidad Reproducción',
            travel_path: 'Ruta de Movimientos Rápidos',
            invert: 'Invertir',
            preview_info: 'Vista Previa',
            size: 'Tamaño',
            estimated_time: 'Tiempo Total Estimado',
            cut_time: 'Tiempo de Corte',
            rapid_time: 'Tiempo de Movimiento Rápido',
            cut_distance: 'Distancia de Corte',
            rapid_distance: 'Distancia de Movimientos Rápidos',
            current_position: 'Posición actual',
            remark: '* Toda la información es el valor estimado de referencia.',
            start_here: 'Empieza aqui',
            end_preview: 'Finalizar vista previa',
        },
        shapes_panel: {
            title: 'Elementos',
            basic: 'Básico',
            shape: 'Forma',
            graphics: 'Gráficos',
            arrow: 'Flecha',
            label: 'Etiqueta',
            decor: 'Decoración',
            circular: 'Circular',
            corner: 'Esquina',
            line: 'Línea',
            photo: 'Foto',
            ribbon: 'Cinta',
            speech: 'Discurso',
            text: 'Marco de texto',
            animals: 'Animal',
            birds: 'Ave',
            land: 'Tierra',
            sea: 'Mar',
            holidays: 'Fiesta',
            celebration: 'Celebración',
            CNY: 'Año Nuevo Chino',
            easter: 'Pascua',
            halloween: 'Halloween',
            valentines: 'San Valentín',
            Xmas: 'Navidad',
            nature: 'Naturaleza',
            elements: 'Elementos',
            environment: 'Medio ambiente',
            plants: 'Plantas',
            weather: 'Clima',
        },
        announcement_panel: {
            title: 'Anuncio',
            dont_show_again: 'No volver a mostrar',
        },
    },
    editor: {
        prespray_area: 'Área de Imprimación',
        opacity: 'Opacidad de vista previa',
        exposure: 'Vista previa de exposición',
    },
    flux_id_login: {
        connection_fail: '#847 La conexión con el servicio al cliente de Flux ha fallado.',
        login_success: 'Ingreso exitoso.',
        login: 'Iniciar sesión',
        unlock_shape_library: 'Ingresa para desbloquear la base de datos de figuras.',
        email: 'Correo electrónico',
        password: 'Contraseña',
        remember_me: 'Recordarme',
        forget_password: '¿Olvidaste la contraseña?',
        register: 'Crea tu cuenta FLUX',
        offline: 'Trabajar sin conexión',
        work_offline: 'Trabajar sin conexión',
        incorrect: 'El correo y/o la contraseña son incorrectos.',
        not_verified: 'Este correo electrónico no ha sido verificado.',
        new_to_flux: '¿Nuevo en FLUX? Crea una cuenta ahora.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Explore los planes FLUX+',
            thank_you: '¡Gracias por ser un valioso miembro!',
            ai_credit_tooltip: 'Para eliminación de fondo por IA',
            flux_credit_tooltip: 'Para archivos del Mercado de diseño y eliminación de fondo por IA',
            goto_member_center: 'Ir al Centro de miembros',
            access_plus_feature_1: 'Está accediendo a una',
            access_plus_feature_2: 'característica.',
            access_plus_feature_note: 'Debe tener la membresía FLUX+ para acceder a esta característica.',
            access_monotype_feature: 'No tiene el complemento Fuentes Monotype.',
            access_monotype_feature_note: 'Debe tener la membresía FLUX+ Pro o el complemento Fuentes Monotype para acceder a esta característica.',
            learn_more: 'Más información',
            get_addon: 'Obtener complemento',
            subscribe_now: 'Suscríbase ahora',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Eliminación de fondo por IA',
                my_cloud: 'Almacenamiento ilimitado en la nube',
                boxgen: 'Generador de cajas 3D',
                dmkt: '1000+ archivos de diseño',
                monotype: '250+ fuentes premium',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Ingresa para desbloquear la base de datos de figuras.',
        enjoy_shape_library: 'Base de datos Disfruta las Figuras.',
        shapes: 'Formas',
        elements: 'Elementos',
        recent: 'Recientes',
        search: 'Buscar',
        clear: 'Borrar',
        export_svg_title: 'No se puede exportar SVG',
        export_svg_warning: 'Este proyecto contiene objetos que están protegidos por la ley de propiedad intelectual. Por lo tanto, Beam Studio excluirá automáticamente estos objetos durante la exportación. Aún puede guardar su proyecto dentro Escena de Beam Studio (formato .beam) para mantener todos sus datos. ¿Todavía quieres exportar?',
        learn_more: 'Aprende más',
    },
    change_logs: {
        change_log: 'Cambiar registros:',
        added: 'Agregado:',
        fixed: 'Fija:',
        changed: 'Cambiado:',
        see_older_version: 'Ver versiones anteriores',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Seleccionar Dispositivo',
        auth_failure: '#811 Fallo de autenticación',
        unable_to_connect: '#810 Incapaz de construir una conexión estable con la máquina',
    },
    device: {
        pause: 'Pausa',
        paused: 'Pausado',
        pausing: 'Pausa',
        select_printer: 'Selecciona la impresora',
        retry: 'Reintentar',
        status: 'Estado',
        busy: 'Ocupado',
        ready: 'Listo',
        reset: 'Reinicio (Kick)',
        abort: 'Abortar',
        start: 'Comenzar',
        please_wait: 'Por favor, espera...',
        quit: 'Salir',
        completing: 'Completando',
        aborted: 'Abortado',
        completed: 'Completado',
        aborting: 'Abortar',
        starting: 'Comenzando',
        preparing: 'Preparando',
        resuming: 'Reanudando',
        scanning: 'Escaneando',
        occupied: 'Manteniendo',
        running: 'Trabajando',
        uploading: 'Cargando',
        processing: 'Procesamiento',
        disconnectedError: {
            caption: 'Máquina desconectada',
            message: 'Por favor, confirma si el acceso a la red de %s está disponible',
        },
        unknown: 'Desconocido',
        pausedFromError: 'Pausado por error',
        model_name: 'Nombre del modelo',
        IP: 'IP',
        serial_number: 'Número de serie',
        firmware_version: 'Versión del firmware',
        UUID: 'UUID',
        select: 'Selecciona',
        deviceList: 'Lista de máquinas',
        disable: 'Deshabilitar',
        enable: 'Habilitar',
        submodule_type: 'Módulo',
        cartridge_serial_number: 'Número de serie del cartucho de tinta',
        ink_color: 'Color de tinta',
        ink_type: 'Tipo de tinta',
        ink_level: 'Volumen de tinta restante',
        close_door_to_read_cartridge_info: 'Para acceder a la información de la tinta, por favor cierre la cubierta de la puerta de la máquina.',
        cartridge_info_read_failed: 'Por favor, verifique que su tinta es auténtica de FLUX.',
        cartridge_info_verification_failed: 'Por favor, asegúrese de que la tinta esté completamente insertada. Intente quitar la tinta e instalarla nuevamente.',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Comenzar',
        resume: 'Reanudar',
        start: 'Comenzar',
        pause: 'Pausar',
        stop: 'Detener',
        record: 'Grabar',
        camera: 'Cámara',
        taskTab: 'Tarea',
        connecting: 'Conectando, por favor espere...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Error Crítico: Placa principal desconectada. Por favor, contacta con el soporte de FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Error crítico: sistema sin respuesta. Por favor contacta al Soporte de FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Por favor, comprueba tu tanque de agua.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Cierra la puerta para continuar.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Sobrecalentado. Por favor, espera unos minutos.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Tapa Abierta. Cierra la puerta para continuar.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Restablecimiento del eje Z fallido',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Cajón abierto',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensor de llama anómalo',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensor de flujo de aire anómalo',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 No se detectó módulo giratorio',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Módulo incorrecto detectado. Por favor, instale el módulo correcto para continuar.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Módulo no detectado. Por favor, asegúrese de que el módulo esté instalado correctamente para continuar.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Módulo desconocido detectado. Por favor, instale el módulo correcto para continuar.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Módulo de impresora sin respuesta.',
        USER_OPERATION_ROTARY_PAUSE: 'Por favor, cambia al motor rotativo',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Por favor, retire el cartucho para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Módulo incorrecto detectado. Por favor, instale el módulo correcto para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Módulo no detectado. Por favor, asegúrese de que el módulo esté instalado correctamente para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Módulo desconocido detectado. Por favor, instale el módulo correcto para continuar.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Por favor, instale el módulo correcto para continuar.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Por favor, inserte el cartucho para continuar.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Por favor, inserte el cartucho correcto para continuar.',
        USER_OPERATION: 'Por favor, siga las instrucciones en el panel del dispositivo para continuar.',
        RESOURCE_BUSY: 'La máquina está ocupada\nSi no está funcionando, por favor reinicia la máquina.',
        DEVICE_ERROR: 'Algo salió mal.\nPor favor, reinicia la máquina',
        NO_RESPONSE: '#905 Error al conectarse a la placa principal.\nPor favor, reinicia la máquina.',
        SUBSYSTEM_ERROR: '#402 Error Crítico: sistema sin respuesta. Por favor, contacta con el soporte de FLUX.',
        HARDWARE_FAILURE: 'Algo salió mal.\nPor favor, reinicia la máquina',
        MAINBOARD_OFFLINE: '#905 Error al conectarse a la placa principal.\nPor favor, reinicia la máquina.',
        bug_report: 'Informe de errores',
        processing: 'Procesando',
        savingPreview: 'Generando miniaturas',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'izquierda',
        temperature: 'Temperatura',
        forceStop: '¿Deseas abortar la tarea actual?',
        upload: 'Subir',
        download: 'Descargar',
        relocate: 'Reubicar',
        cancel: 'Cancelar',
        prepareRelocate: 'Preparándose para la reubicación',
        extensionNotSupported: 'Este formato de archivo no es compatible',
        fileExistContinue: 'El archivo ya existe, ¿quieres reemplazarlo?',
        confirmFileDelete: '¿Estás seguro de que quieres borrar este archivo?',
        ask_reconnect: 'Se ha interrumpido la conexión con el equipo. ¿Deseas reconectar?',
        task: {
            BEAMBOX: 'Grabado Láser',
            'N/A': 'Modo libre',
        },
    },
    alert: {
        caption: 'Error',
        duplicated_preset_name: 'Duplicado del nombre preestablecido',
        info: 'INFO',
        warning: 'ADVERTENCIA',
        error: 'UH-OH',
        instruction: 'Instrucción',
        oops: 'Oops...',
        retry: 'Reintentar',
        abort: 'Abortar',
        confirm: 'Confirmar',
        cancel: 'Cancelar',
        close: 'Cerrar',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Sí',
        no: 'No',
        stop: 'Detener',
        save: 'Guardar',
        dont_save: 'No guardar...',
        learn_more: 'Aprende más',
    },
    caption: {
        connectionTimeout: 'Tiempo de espera de la conexión',
    },
    message: {
        cancelling: 'Cancelando...',
        connecting: 'Conectando...',
        connectingMachine: 'Conectando %s...',
        tryingToConenctMachine: 'Intentando conectar a la máquina...',
        connected: 'Conectado',
        authenticating: 'Autentificando...',
        enteringRawMode: 'Entrando en modo raw...',
        endingRawMode: 'Saliendo del modo raw...',
        enteringLineCheckMode: 'Ingresando al modo de verificación de línea ...',
        endingLineCheckMode: 'Saliendo del modo de verificación de línea ...',
        exitingRotaryMode: 'Saliendo del modo rotativo ...',
        turningOffFan: 'Apagando el ventilador ...',
        turningOffAirPump: 'Apagado de la bomba de aire ...',
        gettingLaserSpeed: 'Lectura de la velocidad del cabezal láser ...',
        settingLaserSpeed: 'Ajuste de la velocidad del cabezal láser ...',
        retrievingCameraOffset: 'Leyendo el desplazamiento de la cámara ...',
        connectingCamera: 'Conectando cámara ...',
        homing: 'Volviendo al inicio...',
        connectionTimeout: '#805 Por favor, comprueba el estado de tu red y el indicador de Wi-Fi de tu máquina.',
        getProbePosition: 'Obteniendo posición de la sonda...',
        device_not_found: {
            caption: 'Máquina por defecto no encontrada',
            message: '#812 Por favor, comprueba el indicador de Wi-Fi de tu máquina',
        },
        device_busy: {
            caption: 'Máquina ocupada',
            message: 'La máquina está ejecutando otra tarea, inténtalo de nuevo más tarde. Si deja de funcionar, por favor reinicia la máquina.',
        },
        device_is_used: 'La máquina está siendo usada, ¿quieres abortar la tarea actual?',
        monitor_too_old: {
            caption: 'El firmware está desactualizado',
            content: '#814 Por favor, instala el último firmware con <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">esta guía</a>.',
        },
        unknown_error: '#821 La aplicación ha encontrado un error desconocido, por favor usa Ayuda > Menú > Informe de errores.',
        unknown_device: '#826 No se puede conectar a la máquina, por favor asegúrate de que el USB está conectado a la máquina',
        unsupport_osx_version: 'La versión actual de macOS %s puede que no admita algunas funciones. Por favor, actualice amablemente a macOS 11+.',
        unsupport_win_version: 'La versión actual del sistema operativo %s puede que no soporte algunas funciones. Por favor, actualiza a la última versión.',
        need_password: 'Necesita una contraseña para conectarse a la máquina',
        unavailableWorkarea: '#804 El área de trabajo actual excede el área de trabajo de esta máquina. Por favor, comprueba el área de trabajo de la máquina seleccionada o establece el área de trabajo desde Edición > Configuración de documentos.',
        please_enter_dpi: 'Por favor, introduce la unidad de tu archivo (in mm)',
        auth_error: '#820 Error de Autenticación: Por favor, actualiza Beam Studio y el firmware de la máquina a la última versión.',
        usb_unplugged: 'La conexión USB se ha perdido. Por favor, comprueba tu conexión USB',
        uploading_fcode: 'subir el FCode',
        cant_connect_to_device: '#827 No se puede conectar la máquina, por favor, comprueba tu conexión',
        unable_to_find_machine: 'Incapaz de encontrar la máquina',
        disconnected: 'La conexión es inestable. Por favor, comprueba la conexión del dispositivo e inténtalo de nuevo más tarde.',
        unable_to_start: '#830 Incapaz de comenzar la tarea. Por favor, inténtalo otra vez. Si esto sucede de nuevo, por favor contáctanos con el informe de errores:\n',
        camera: {
            camera_cable_unstable: 'Se detecta que la cámara está transfiriendo fotos de manera inestable. La vista previa aún se puede realizar normalmente, pero puede existir el problema de que la vista previa sea lenta o se agote el tiempo de espera.',
            fail_to_transmit_image: '#845 Algo salió mal en la transmisión de imágenes. Por favor, reinicia tu máquina o Beam Studio. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">esta guía</a>.',
            ws_closed_unexpectly: '#844 La conexión a la cámara de la máquina se ha cerrado inesperadamente. Si este error persiste, por favor sigue <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402755805071">esta guía</a>.',
            continue_preview: 'Continuar',
            abort_preview: 'Abortar',
        },
        preview: {
            camera_preview: 'Vista de la cámara',
            auto_focus: 'Enfoque automático',
            auto_focus_instruction: 'Por favor, mueva la cabeza del módulo láser sobre el objeto y siga las instrucciones de la animación para presionar AF y enfocar.',
            already_performed_auto_focus: 'Ya ha realizado el enfoque automático, ¿utilizar los valores existentes?',
            please_enter_height: 'Por favor, ingrese la altura del objeto para capturar una toma precisa de la cámara.',
            apply: 'Aplicar',
            enter_manually: 'Introducir manualmente',
            adjust: 'Ajustar',
            adjust_height_tooltip: 'Haga clic en la casilla para habilitar la edición.',
        },
        unsupported_example_file: 'El archivo de ejemplo seleccionado no es compatible con el área de trabajo actual.',
        time_remaining: 'Tiempo restante:',
    },
    machine_status: {
        '-17': 'Cartridge IO Mode',
        '-10': 'Mantener el modo',
        '-2': 'Escaneando',
        '-1': 'Manteniendo',
        0: 'Inactivo',
        1: 'Iniciando',
        2: 'ST_TRANSFORMA',
        4: 'Comenzando',
        6: 'Reanudando',
        16: 'Trabajando',
        18: 'Reanudando',
        32: 'Pausado',
        36: 'Pausado',
        38: 'Pausa',
        48: 'Pausado',
        50: 'Pausa',
        64: 'Completado',
        66: 'Completando',
        68: 'Preparando',
        128: 'Abortado',
        256: 'Alarm',
        512: 'Fatal',
        UNKNOWN: 'Desconocido',
    },
    calibration: {
        update_firmware_msg1: 'tu firmware no es compatible con esta función. Por favor, actualiza el firmware a v',
        update_firmware_msg2: 'o superior para continuar (Menú > Máquina > [tu máquina] > Actualizar el firmware)',
        camera_calibration: 'Calibración de la cámara',
        diode_calibration: 'Calibración del módulo láser diodo',
        module_calibration_printer: 'Calibración del Módulo de Impresora',
        module_calibration_2w_ir: 'Calibración del Módulo Infrarrojo',
        back: 'VUELVE',
        next: 'SIGUIENTE',
        skip: 'OMITIR',
        cancel: 'CANCELAR',
        finish: 'HECHO',
        do_engraving: 'HACER GRABADO',
        start_engrave: 'EMPEZAR A GRABAR',
        start_printing: 'INICIAR IMPRESIÓN',
        ask_for_readjust: '¿Quiere omitir el paso de grabado y luego tomar una foto y calibrar directamente?',
        please_goto_beambox_first: 'Por favor, cambia al Modo de Grabación ( Beambox ) para poder usar esta función.',
        please_place_paper: 'Por favor, coloca un papel blanco tamaño A4 o Carta en la esquina tuperior izquierda del área de trabajo',
        please_place_paper_center: 'Coloque una hoja de papel blanco de tamaño A4 o carta en el centro del área de trabajo.',
        please_place_dark_colored_paper: 'Coloque un papel de tamaño A4 o carta de color oscuro en el centro del área de trabajo.',
        please_refocus: {
            beambox: 'Por favor, ajusta la plataforma al punto focal (la altura del acrílico rechazado)',
            beamo: 'Por favor, ajusta el cabezal del láser para enfocar el objeto grabado (la altura del acrílico rechazado)',
            beamo_af: 'Toca dos veces el botón de enfoque automático y deja que el de enfoque toque el material con cuidado.',
            hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
        },
        without_af: 'Sin ajuste de enfoque',
        with_af: 'Con ajuste de enfoque',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotación',
        x_ratio: 'Relación X',
        y_ratio: 'Relación Y',
        show_last_config: 'Mostrar el último resultado',
        use_last_config: 'Utilizar los datos de  la última calibración',
        taking_picture: 'Tomar una foto...',
        analyze_result_fail: 'Falla en el análisis de la imagen capturada.<br/>Por favor asegúrese:<br/>1. Imagen capturada totalmente cubierta con papel blanco.<br/>2. La plataforma está bien enfocada.',
        drawing_calibration_image: 'Dibujando la imagen de calibración...',
        calibrate_done: 'Calibración realizada. La precisión de la cámara es mejor cuando se enfoca con precisión.',
        calibrate_done_diode: 'Calibración realizada. Se ha guardado el desplazamiento módulo láser de diodo.',
        hint_red_square: 'Favor de alinear el contorno del cuadro rojo con el cuadro punteado.',
        hint_adjust_parameters: 'Usa estos parámetros para ajustar el cuadro rojo',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Al calibrar el módulo láser diodo, se necesita una cámara.\nPor favor, asegúrate de que la cámara de esta máquina ha sido calibrada.\nAjusta la plataforma al punto focal (la altura del acrílico rechazado)',
            beamo: 'Al calibrar el módulo láser diodo, se necesita una cámara.\nPor favor, asegúrate de que la cámara de esta máquina ha sido calibrada.\nAjusta la cabeza del láser para enfocar el objeto grabado (la altura del acrílico rechazado)',
        },
        downloading_pictures: 'Descargando imágenes...',
        failed_to_download_pictures: '#848 Error al descargar imágenes, por favor contacte al Soporte de FLUX.',
        uploading_images: 'Subiendo imágenes...',
        calculating_camera_matrix: 'Calculando la matriz de la cámara...',
        calculating_regression_parameters: 'Calculando parámetros de regresión...',
        failed_to_calibrate_camera: '#848 Error al calibrar la cámara, por favor contacte al Soporte de FLUX.',
        failed_to_save_calibration_results: '#849 Error al guardar los resultados de la calibración, por favor inténtelo de nuevo. Si esto continúa ocurriendo, por favor contacte al Soporte de FLUX.',
        ador_autofocus_material: 'Presione el icono "AF" en la página principal de la máquina durante 3 segundos y permita que la sonda toque suavemente el material.',
        ador_autofocus_focusing_block: 'Presione el ícono "AF" en la página principal de la máquina durante 3 segundos y deje que la sonda toque el bloque de enfoque.',
        align_red_cross_cut: 'Por favor, alinee el centro de la cruz roja con la cruz de corte.',
        align_red_cross_print: 'Por favor, alinee el centro de la cruz roja con la cruz impresa.',
        retake: 'Volver a tomar foto',
        calibrate_camera_before_calibrate_modules: 'Por favor, realice la calibración de la cámara antes de calibrar los módulos.',
        check_checkpoint_data: 'Datos del punto de control',
        checking_checkpoint: 'Comprobando datos del punto de control...',
        found_checkpoint: 'Se encontraron datos del punto de control en su dispositivo. ¿Desea recuperarlos del punto de control?',
        use_old_camera_parameter: '¿Desea utilizar los parámetros actuales del objetivo de la cámara?',
        downloading_checkpoint: 'Descargando datos del punto de control...',
        failed_to_parse_checkpoint: 'No se pudo analizar los datos del punto de control.',
        check_device_pictures: 'Ver imágenes del dispositivo',
        checking_pictures: 'Comprobando imágenes del dispositivo...',
        no_picutre_found: '#846 Tu dispositivo no tiene fotos en bruto disponibles para calibración. Por favor, ponte en contacto con el soporte técnico de FLUX.',
        calibrating_with_device_pictures: 'Calibrando con imágenes del dispositivo...',
        failed_to_calibrate_with_pictures: '#847 Error al calibrar con imágenes del dispositivo.',
        getting_plane_height: 'Obteniendo Altura del Plano...',
        preparing_to_take_picture: 'Preparando para tomar foto...',
        put_paper: 'Colocar papel',
        put_paper_step1: 'Por favor, coloque un papel blanco de tamaño A4 o Carta en el centro del área de trabajo.',
        put_paper_step2: 'Asegure las cuatro esquinas del papel para garantizar que quede plano.',
        put_paper_step3: 'Haga clic en "EMPEZAR A GRABAR".',
        solve_pnp_title: 'Alinear puntos de referencia',
        solve_pnp_step1: 'Alinee los puntos de grabado según el número y la posición esperada de cada punto de marcador rojo.',
        solve_pnp_step2: 'Puede presionar "Volver a tomar foto" para realinear o ajustar manualmente las posiciones de los marcadores.',
        align_olt: 'Posición: Alinee con el punto grabado en la parte superior izquierda exterior.',
        align_ort: 'Posición: Alinee con el punto grabado en la parte superior derecha exterior.',
        align_olb: 'Posición: Alinee con el punto grabado en la parte inferior izquierda exterior.',
        align_orb: 'Posición: Alinee con el punto grabado en la parte inferior derecha exterior.',
        align_ilt: 'Posición: Alinee con el punto grabado en la parte superior izquierda interior.',
        align_irt: 'Posición: Alinee con el punto grabado en la parte superior derecha interior.',
        align_ilb: 'Posición: Alinee con el punto grabado en la parte inferior izquierda interior.',
        align_irb: 'Posición: Alinee con el punto grabado en la parte inferior derecha interior.',
        elevate_and_cut: 'Elevar y Cortar',
        elevate_and_cut_step_1: 'Coloque un trozo de madera de color claro del tamaño de A4 en el centro del área de trabajo y eleve a 20 mm.',
        elevate_and_cut_step_1_prism_lift: 'Utilice Ador Prism Lift con una longitud máxima de 14 mm con un trozo de madera de al menos 6 mm de grosor.',
        with_prism_lift: 'Con Ador Prism Lift',
        without_prism_lift: 'Sin Ador Prism Lift',
        camera_parameter_saved_successfully: 'Parámetros de la cámara guardados con éxito.',
        failed_to_save_camera_parameter: 'No se pudo guardar el parámetro de la cámara.',
        failed_to_solve_pnp: 'No se pudo resolver la posición de la cámara.',
    },
    input_machine_password: {
        require_password: '"%s" requiere una contraseña',
        connect: 'CONECTAR',
        password: 'Contraseña',
    },
    tutorial: {
        skip: 'Saltar',
        welcome: 'BIENVENIDO',
        suggest_calibrate_camera_first: 'Recomendamos a los usuarios calibrar la cámara inicialmente y volver a enfocar antes de cada vista previa para obtener resultados óptimos.<br/>¿Desea confirmar para realizar la calibración ahora?<br/>(Puede omitirlo ahora y hacerlo más tarde haciendo clic en "Menú" > "Máquina" > [Su Máquina] > "Calibrar Cámara".)',
        camera_calibration_failed: 'Falló la calibración de la cámara',
        ask_retry_calibration: 'Would you like to calibrate the camera again?',
        needNewUserTutorial: '¿Le gustaría iniciar un tutorial?<br/>(Puede omitirlo ahora y comenzarlo más tarde haciendo clic en "Ayuda" > "Mostrar Tutorial de Inicio".)',
        needNewInterfaceTutorial: '¿Le gustaría iniciar un tutorial para la nueva interfaz de Beam Studio?<br/>(Puede omitirlo ahora y comenzarlo más tarde haciendo clic en "Ayuda" > "Mostrar Introducción a la Interfaz".)',
        next: 'SIGUIENTE',
        look_for_machine: 'Buscador de tutoriales...',
        unable_to_find_machine: 'No se puede encontrar la máquina para el Tutorial. ¿Quieres ir a la página de configuración de la conexión, reintentar o saltar el tutorial?',
        skip_tutorial: 'Te has saltado el tutorial. Siempre puedes iniciar el tutorial haciendo clic en "Ayuda" > "Mostrar tutorial de inicio".',
        set_connection: 'Establecer la conexión',
        retry: 'Reintenta',
        newUser: {
            draw_a_circle: 'Dibuja un círculo',
            drag_to_draw: 'Arrastrar para dibujar',
            infill: 'Enciende el relleno',
            switch_to_object_panel: 'Cambiar al panel de objetos',
            switch_to_layer_panel: 'Cambiar al panel de capas',
            set_preset_wood_engraving: 'Establecer preselección: Madera - Grabado',
            set_preset_wood_cut: 'Establecer preselección: Madera - Corte',
            add_new_layer: 'Añadir una nueva capa',
            draw_a_rect: 'Dibuja un rectángulo',
            switch_to_preview_mode: 'Cambiar al modo de vista previa',
            preview_the_platform: 'Vista previa de la plataforma',
            end_preview_mode: 'Finalizar el modo de vista previa',
            put_wood: '1. Pon la madera de muestra',
            adjust_focus: '2. Ajusta el foco...',
            close_cover: '3. Cierra la tapa.',
            send_the_file: 'Envía el archivo',
            end_alert: '¿Estás seguro de terminar el tutorial?',
            please_select_wood_engraving: 'Por favor, selecciona "Madera - Grabado" Preseleccionado.',
            please_select_wood_cutting: 'Por favor, selecciona "Madera - Corte".',
        },
        newInterface: {
            camera_preview: 'Vista previa de la cámara',
            select_image_text: 'Seleccionar / Imagen / Texto',
            basic_shapes: 'Formas básicas',
            pen_tool: 'Herramienta de la pluma',
            add_new_layer: 'Añadir una nueva capa',
            rename_by_double_click: 'Cambiar el nombre con un doble clic',
            drag_to_sort: 'Arrastrar para clasificar',
            layer_controls: 'Haz clic con el botón derecho del ratón para seleccionar Controles de capa:\nDuplicar / Fusionar / Bloquear / Eliminar Capas',
            switch_between_layer_panel_and_object_panel: 'Cambiar entre el Panel de Capas y el Panel de Objetos',
            align_controls: 'Alinear los controles',
            group_controls: 'Controles de grupo',
            shape_operation: 'Operación de forma',
            flip: 'Voltear',
            object_actions: 'Acciones de Objeto',
            end_alert: '¿Estás seguro de terminar la nueva introducción de la UI?',
            select_machine: 'Seleccione una máquina',
            start_work: 'Empezar a trabajar',
        },
        gesture: {
            pan: 'Desplácese por el lienzo con dos dedos.',
            zoom: 'Mueve dos dedos juntos para alejar o separar dos dedos para agrandar el lienzo.',
            click: 'Toque para seleccionar el objeto.',
            drag: 'Arrastre para seleccionar los múltiples objetos.',
            hold: 'Mantenga presionado por un momento para abrir el menú contextual.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Eso es todo por el tutorial. ¡Ahora es el momento de crear!',
    },
    layer_module: {
        none: 'Ninguno',
        general_laser: 'Láser',
        laser_10w_diode: 'Láser de Diodo de 10W',
        laser_20w_diode: 'Láser de Diodo de 20W',
        laser_2w_infrared: 'Láser Infrarrojo de 2W',
        printing: 'Impresión',
        unknown: 'Módulo desconocido',
        notification: {
            convertFromPrintingModuleTitle: '¿Desea convertir el módulo de impresión en un módulo láser?',
            convertFromPrintingModuleMsg: 'Tenga en cuenta que si completa esta operación, la configuración de color de la capa de impresión se eliminará y se establecerá de acuerdo con la capa actual.',
            convertFromLaserModuleTitle: '¿Desea convertir el módulo láser en un módulo de impresión?',
            convertFromLaserModuleMsg: 'Tenga en cuenta que si completa esta operación, la configuración de la capa láser se eliminará y se establecerá de acuerdo con la capa actual.',
            importedDocumentContainsPrinting: 'El documento contiene capas de impresión. ¿Desea cambiar al área de trabajo a Ador?',
            printingLayersCoverted: 'Las capas de impresión se han convertido en capas láser.',
            performPrintingCaliTitle: 'Realice la calibración del módulo de impresión',
            performPrintingCaliMsg: 'Haz clic en "Confirmar" para ejecutar la calibración o accede a la calibración a través del menú superior.<br />(Máquina > [Nombre de tu máquina] > Calibrar Módulo de Impresión)',
            performIRCaliTitle: 'Realizar la calibración del módulo infrarrojo.',
            performIRCaliMsg: 'Haz clic en "Confirmar" para ejecutar la calibración o accede a la calibración a través del menú superior.<br />(Máquina > [Nombre de tu máquina] > Calibrar módulo infrarrojo)',
        },
        non_working_area: 'Área no laboral',
    },
    qr_code_generator: {
        title: 'Generador de códigos QR',
        placeholder: 'Ingrese un enlace o texto',
        preview: 'Vista previa',
        error_tolerance: 'Tolerancia de error',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Invertir color de fondo',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Caja Básica',
        coming_soon: 'Próximamente',
        workarea: 'Área de trabajo',
        max_dimension_tooltip: 'El ancho/alto/profundidad máximo es %s.',
        volume: 'Volumen',
        outer: 'Exterior',
        inner: 'Interior',
        width: 'Ancho',
        height: 'Alto',
        depth: 'Profundidad',
        cover: 'Tapa',
        thickness: 'Espesor',
        add_option: 'Añadir Opción',
        joints: 'Unión',
        finger: 'Dedo',
        finger_warning: 'La longitud interna del lado de la caja debe ser de al menos 6 mm (0.24 pulgadas) para ser compatible con la unión de dedos.',
        tSlot: 'Ranura en T',
        tSlot_warning: 'La longitud del lado de la caja debe ser de al menos 30 mm (1.18 pulgadas) para ser compatible con la unión de ranuras en T.',
        edge: 'Borde',
        tCount: 'Cantidad de T',
        tCount_tooltip: 'El recuento de ranuras en T se aplica al lado corto; la cantidad en el lado largo se calcula en función de su longitud.',
        tDiameter: 'Diámetro de T',
        tLength: 'Longitud de T',
        continue_import: 'Continuar importando',
        customize: 'Personalizar',
        merge: 'Fusionar',
        text_label: 'Etiqueta',
        beam_radius: 'Compensación del radio del láser',
        beam_radius_warning: 'Elimine la compensación de corte cuando los bordes o uniones de la caja sean cortos para asegurar el montaje de la caja',
        import: 'Importar',
        cancel: 'Cancelar',
        reset: 'Restablecer',
        zoom: 'Zoom',
        control_tooltip: 'Botón izquierdo para rotar\nDesplazarse para hacer zoom\nBotón derecho para mover',
        control_tooltip_touch: 'Arrastrar para rotar\nPellizcar para hacer zoom\nDos dedos para mover',
    },
    my_cloud: {
        title: 'Mi nube',
        loading_file: 'Cargando...',
        no_file_title: 'Guarde archivos en Mi nube para comenzar.',
        no_file_subtitle: 'Vaya al Menú > "Archivo" > "Guardar en la nube"',
        file_limit: 'Archivo gratuito',
        upgrade: 'Actualizar',
        sort: {
            most_recent: 'Más recientes',
            oldest: 'Más antiguos',
            a_to_z: 'Nombre: A - Z',
            z_to_a: 'Nombre: Z - A',
        },
        action: {
            open: 'Abrir',
            rename: 'Renombrar',
            duplicate: 'Duplicar',
            download: 'Descargar',
            delete: 'Eliminar',
            confirmFileDelete: '¿Está seguro de que desea eliminar este archivo? Esta acción no se puede deshacer.',
        },
        save_file: {
            choose_action: 'Guardar archivo:',
            save: 'Guardar',
            save_new: 'Guardar como nuevo archivo',
            input_file_name: 'Introducir nombre de archivo:',
            invalid_char: 'Caracteres no válidos:',
            storage_limit_exceeded: 'Su almacenamiento en la nube ha alcanzado el límite superior. Elimine cualquier archivo innecesario antes de guardar nuevos.',
        },
    },
    camera_data_backup: {
        title: 'Copia de seguridad de datos de la cámara',
        no_picture_found: 'No se encontró ninguna imagen en la máquina.',
        folder_not_exists: 'La carpeta seleccionada no existe.',
        incorrect_folder: 'Error al cargar los datos de calibración. Por favor, compruebe si la carpeta seleccionada es correcta.',
        downloading_data: 'Downloading data...',
        estimated_time_left: 'Tiempo estimado restante:',
        uploading_data: 'Cargando datos...',
        download_success: 'Datos de la cámara descargados con éxito.',
        upload_success: 'Datos de la cámara cargados con éxito.',
    },
    insecure_websocket: {
        extension_detected: 'Extensión Beam Studio Connect detectada',
        extension_detected_description: "Hemos detectado que has instalado la extensión Beam Studio Connect. Haz clic en 'Confirmar' para redirigir a HTTPS, o haz clic en 'Cancelar' para seguir utilizando HTTP.",
        extension_not_deteced: 'No se pudo detectar la extensión Beam Studio Connect',
        extension_not_deteced_description: "Para usar HTTPS, haga clic en 'Confirmar' para instalar la extensión Beam Studio Connect. Después de instalar la extensión, actualice la página para activarla.<br/>De lo contrario, haga clic en el enlace de abajo para ver cómo usar HTTP en Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Enlace</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Medir área de autoenfoque',
        amount: 'Cantidad',
        gap: 'Espacio',
        rows: 'Filas',
        coloumns: 'Columnas',
        row_gap: 'Espacio entre filas',
        column_gap: 'Espacio entre columnas',
        set_object_height: 'Establecer altura del objeto',
        set_object_height_desc: 'Medir el grosor máximo del objeto.',
        measure_area_help: '¿Cómo seleccionar el área de autofoco para la curva 3D?',
        reselect_area: 'Volver a seleccionar área',
        start_autofocus: 'Iniciar autofoco',
        starting_measurement: 'Iniciando medición...',
        preview_3d_curve: 'Vista previa de la curva 3D',
        apply_arkwork: 'Aplicar arte en la curva 3D',
        apply_camera: 'Aplicar imagen de cámara en la curva 3D',
        click_to_select_point: 'Haga clic para seleccionar o deseleccionar puntos para medir nuevamente.',
        remeasure: 'Volver a medir',
        remeasuring_points: 'Volviendo a medir puntos...',
    },
    pass_through: {
        title: 'Modo De Pasar A Través',
    },
};
export default lang;
