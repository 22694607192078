const lang = {
    general: {
        wait: 'Przetwarzanie... Proszę czekać',
        choose_folder: 'Wybierz folder',
    },
    buttons: {
        next: 'DALEJ',
        back: 'COFNIJ',
        done: 'GOTOWE',
        back_to_beam_studio: 'Wróć do Beam Studio',
    },
    topbar: {
        untitled: 'Bez nazwy',
        titles: {
            settings: 'Preferencje',
        },
        export: 'START',
        preview: 'PODGLĄD',
        preview_title: 'PODGLĄD',
        curve_engrave: 'Krzywa 3D',
        task_preview: 'Podgląd ścieżki',
        frame_task: 'Rama do biegania',
        borderless: '(OTWARTE DNO)',
        tag_names: {
            rect: 'Prostokąt',
            ellipse: 'Owal',
            path: 'Ścieżka',
            polygon: 'Wielokąt',
            image: 'Obraz',
            text: 'Tekst',
            text_path: 'Tekst na ścieżce',
            line: 'Linia',
            g: 'Grupa',
            multi_select: 'Wiele obiektów',
            use: 'Zaimportowany obiekt',
            svg: 'Obiekt SVG',
            dxf: 'Obiekt DXF',
            no_selection: 'Brak wyboru',
        },
        alerts: {
            start_preview_timeout: '#803 Upłynął limit czasu podczas uruchamiania trybu podglądu. Uruchom ponownie komputer i Beam Studio. Jeśli błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tym przewodnikiem</a>.',
            fail_to_start_preview: '#803 Nie udało się uruchomić trybu podglądu. Uruchom ponownie komputer i Beam Studio. Jeśli błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tym przewodnikiem</a>.',
            fail_to_connect_with_camera: '#803 Nie udało się nawiązać połączenia z kamerą maszyny. Uruchom ponownie komputer i Beam Studio. Jeśli błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">tym przewodnikiem</a>.',
            add_content_first: 'Please add objects first',
            headtype_mismatch: 'Nieprawidłowy moduł wykryty. ',
            headtype_none: 'Nie wykryto modułu. ',
            headtype_unknown: 'Wykryto nieznany moduł. ',
            install_correct_headtype: 'Zainstaluj prawidłowe moduły lasera diodowego 10 W/20 W, aby włączyć niską moc lasera do uruchomienia ramki.',
            door_opened: 'Zamknij pokrywę drzwi, aby włączyć niską moc lasera do uruchomienia ramki.',
            fail_to_get_door_status: 'Upewnij się, że pokrywa drzwi jest zamknięta, aby włączyć niską moc lasera do uruchomienia ramki.',
            QcleanScene: 'Czy chcesz wyczyścić rysunek?<br/>Spowoduje to także usunięcie historii cofania!',
            power_too_high: 'ZBYT WYSOKA MOC',
            power_too_high_msg: 'Użycie mniejszej mocy lasera (poniżej 70%) wydłuży żywotność tuby laserowej. Wpisz „ROZUMIEM”, aby kontynuować.',
            power_too_high_confirm: 'ROZUMIEM',
        },
        hint: {
            polygon: 'Naciśnij klawisz + / -, aby zwiększyć / zmniejszyć ilość boków.',
        },
        menu: {
            preferences: 'Preferencje',
            hide: 'Ukryj Beam Studio',
            hideothers: 'Ukryj inne',
            service: 'Usługi',
            quit: 'Wyjdź',
            window: 'Okno',
            minimize: 'Zminimalizuj',
            close: 'Zamknij okno',
            file: 'Plik',
            edit: 'Edycja',
            help: 'Pomoc',
            open: 'Otwarty',
            samples: 'Przykłady',
            import_hello_beamo: 'Przykłady Bemo',
            import_hello_beambox: 'Przykłady Beambox',
            import_material_testing_old: 'Test grawerowania — klasyczny',
            import_material_testing_simple_cut: 'Test cięcia - prosty',
            import_material_testing_cut: 'Test cięcia',
            import_material_testing_engrave: 'Test grawerowania',
            import_material_testing_line: 'Test nacinania',
            import_material_printing_test: 'Test drukowania materiału',
            import_ador_laser_example: 'Przykład lasera Ador',
            import_ador_printing_example_single: 'Przykład druku Ador — pojedynczy kolor',
            import_ador_printing_example_full: 'Przykład druku Ador - cały kolor',
            import_acrylic_focus_probe: 'Akrylowa sonda ostrości - 3mm',
            export_to: 'Eksportuj do...',
            export_flux_task: 'Plik FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Zapisz',
            save_as: 'Zapisz jako...',
            save_to_cloud: 'Zapisz w chmurze',
            about_beam_studio: 'O Beam Studio',
            undo: 'Cofnij',
            redo: 'Ponów',
            cut: 'Wytnij',
            copy: 'Kopiuj',
            paste: 'Wklej',
            paste_in_place: 'Wklej w miejscu',
            group: 'Grupuj',
            ungroup: 'Rozgrupuj',
            delete: 'Usuń',
            duplicate: 'Duplikuj',
            offset: 'Obiekt',
            scale: 'Skaluj',
            rotate: 'Obróć',
            reset: 'Resetuj',
            align_center: 'Wyrównaj do środka',
            photo_edit: 'Obraz',
            svg_edit: 'SVG',
            path: 'Ścieżka',
            decompose_path: 'Rozłóż',
            object: 'Obiekt',
            layer_setting: 'Warstwa',
            layer_color_config: 'Ustawienia kolorów',
            image_sharpen: 'Wyostrzyj',
            image_crop: 'Przytnij',
            image_invert: 'Odwróć',
            image_stamp: 'Faza',
            image_vectorize: 'Obrysuj',
            image_curve: 'Krzywa',
            align_to_edges: 'Przyciągaj do wierzchołków',
            document_setting: 'Ustawienia dokumentu',
            document_setting_short: 'Dokumentu',
            clear_scene: 'Nowe pliki',
            machines: 'Maszyny',
            add_new_machine: 'Konfiguracja maszyny',
            help_center: 'Centrum pomocy',
            show_gesture_tutorial: 'Wprowadzenie do gestów dłoni',
            show_start_tutorial: 'Pokaż samouczek startowy',
            show_ui_intro: 'Pokaż wprowadzenie do interfejsu',
            questionnaire: 'Kwestionariusz opinii',
            change_logs: 'Dzienniki zmian',
            contact: 'Skontaktuj się z nami',
            tutorial: 'Uruchom samouczek dotyczący drukowania z rodziny Delta',
            design_market: 'Design Market',
            forum: 'Forum społecznościowe',
            software_update: 'Aktualizacja oprogramowania',
            bug_report: 'Zgłoszenie błędu',
            dashboard: 'Panel',
            machine_info: 'Informacje o maszynie',
            network_testing: 'Przetestuj ustawienia sieciowe',
            commands: 'Polecenia',
            update_firmware: 'Aktualizacja oprogramowania',
            using_beam_studio_api: 'Korzystanie z API Beam Studio',
            set_as_default: 'Ustaw jako domyślne',
            calibrate_beambox_camera: 'Skalibruj kamerę',
            calibrate_printer_module: 'Kalibracja modułu drukowania',
            calibrate_ir_module: 'Kalibracja modułu podczerwieni',
            calibrate_beambox_camera_borderless: 'Skalibruj kamerę (Otwarte dno)',
            calibrate_diode_module: 'Skalibruj moduł lasera diodowego',
            manage_account: 'Zarządzaj kontem',
            sign_in: 'Zaloguj się',
            sign_out: 'Wyloguj się',
            account: 'Konto',
            my_account: 'Moje konto',
            download_log: 'Pobierz dzienniki logów',
            download_log_canceled: 'Pobieranie dziennika logów zostało anulowane',
            download_log_error: 'Wystąpił nieznany błąd, spróbuj ponownie później',
            keyboard_shortcuts: 'Skróty klawiaturowe',
            log: {
                network: 'Sieć',
                hardware: 'Sprzęt komputerowy',
                discover: 'Odkryj',
                usb: 'USB',
                usblist: 'Lista USB',
                camera: 'Kamera',
                cloud: 'Chmura',
                player: 'Gracz',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Widok',
            zoom_in: 'Powiększ',
            zoom_out: 'Zmniejsz',
            fit_to_window: 'Dopasuj obszar roboczy do okna',
            zoom_with_window: 'Automatyczne dopasowywuj obszar roboczy do okna',
            borderless_mode: 'Tryb bez obramowania',
            show_grids: 'Pokaż siatkę',
            show_rulers: 'Pokaż miarki',
            show_layer_color: 'Użyj koloru warstwy',
            anti_aliasing: 'Wygładzanie krawędzi',
            disassemble_use: 'Rozłóż',
        },
        select_machine: 'Wybierz maszynę',
    },
    support: {
        no_webgl: 'WebGL nie jest obsługiwany. Użyj innych urządzeń.',
        no_vcredist: 'Zainstaluj pakiet redystrybucyjny Visual C++ 2015<br/>, który można pobrać ze strony flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Wystąpił nieznany błąd. Uruchom ponownie Beam Studio i maszynę.',
        OPERATION_ERROR: '[OE] Wystąpił konflikt statusu, spróbuj ponownie.',
        SUBSYSTEM_ERROR: '[SE] Błąd podczas wykonywania zadania przez oprogramowanie ukałdowe urządzenia. Proszę ponownie uruchomić maszynę.',
        UNKNOWN_COMMAND: '[UC] Zaktualizuj oprogramowanie sprzętowe urządzenia',
    },
    device_selection: {
        no_beambox: '#801 Nie możemy znaleźć Twojej maszyny w sieci.',
        no_device_web: '#801 Sprawdź status swojej maszyny lub kliknij poniżej „Konfiguracja maszyny”, aby skonfigurować maszynę.',
    },
    update: {
        update: 'AKTUALIZACJA',
        release_note: 'Informacje o wersji:',
        firmware: {
            caption: 'Dostępna jest aktualizacja oprogramowania układowego urządzenia',
            message_pattern_1: '%s jest teraz gotowy do aktualizacji oprogramowania układowego.',
            message_pattern_2: '%s Firmware v%s jest już dostępny - Teraz masz v%s.',
            latest_firmware: {
                caption: 'Aktualizacja oprogramowania układowego urządzenia',
                message: 'Masz najnowsze oprogramowanie układowe urządzenia',
                still_update: 'AKTUALIZACJA',
                cant_get_latest: 'Nie można uzyskać informacji o najnowszym oprogramowaniu urządzenia.',
            },
            confirm: 'PRZEŚLIJ',
            upload_file: 'Przesyłanie oprogramowania układowego (*.bin / *.fxfw)',
            update_success: 'Pomyślnie przesłano aktualizację oprogramowania układowego',
            update_fail: 'Aktualizacja nieudana #822',
            too_old_for_web: 'Bieżąca wersja oprogramowania układowego urządzenia to v%s.\nIf you want to use the online version of the Beam Studio, please update the machine firmware to the latest version.',
            force_update_message: '#814 Zaktualizuj urządzenie do najnowszej wersji oprogramowania sprzętowego.',
            firmware_too_old_update_by_sdcard: 'Wersja oprogramowania sprzętowego jest zbyt stara. Zaktualizuj oprogramowanie sprzętowe za pomocą karty SD.',
        },
        software: {
            checking: 'Sprawdzanie aktualizacji',
            switch_version: 'Zmień wersję',
            check_update: 'Sprawdź dostępne aktualizacje',
            caption: 'Dostępna jest aktualizacja oprogramowania Beam Studio',
            downloading: 'Pobieram aktualizacje w tle, możesz kliknąć „OK”, aby kontynuować pracę.',
            install_or_not: 'jest gotowy do aktualizacji. Czy chcesz teraz ponownie uruchomić i zaktualizować?',
            switch_or_not: 'jest gotowy do zmiany. Czy chcesz teraz ponownie uruchomić i zamienić?',
            available_update: 'Beam Studio v%s jest już dostępna. Teraz masz v%s. Czy chcesz pobrać aktualizację?',
            available_switch: 'Beam Studio v%s jest już dostępna. Teraz masz v%s. Czy chcesz przełączyć się na tę wersję?',
            not_found: 'Używasz najnowszej wersji Beam Studio.',
            no_response: 'Nie udało się połączyć z serwerem, sprawdź ustawienia sieciowe.',
            switch_version_not_found: 'Nie znaleziono wersji do zamiany.',
            yes: 'Tak',
            no: 'Nie',
            update_for_ador: 'Obecna wersja oprogramowania %s jest starsza. Pobierz najnowszą wersję Beam Studio dla Ador.',
        },
        updating: 'Aktualizowanie...',
        skip: 'Pomiń tę wersję',
        preparing: 'Przygotowywanie...',
        later: 'PÓŹNIEJ',
        download: 'AKTUALIZACJA ONLINE',
        cannot_reach_internet: 'Serwer #823 jest nieosiągalny<br/>Sprawdź połączenie internetowe',
        install: 'ZAINSTALUJ',
        upload: 'PRZEŚLIJ',
    },
    topmenu: {
        version: 'Wersja',
        credit: 'Beam Studio jest możliwe dzięki projektowi open source <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> i innym <a target="_blank" href="https://flux3dp.com/credits/">oprogramowanie open source</a>.',
        ok: 'OK',
        file: {
            label: 'Plik',
            import: 'Importuj',
            save_fcode: 'Eksportuj plik FLUX',
            save_scene: 'Zapisz projekt',
            save_svg: 'Eksportuj SVG',
            save_png: 'Eksportuj PNG',
            save_jpg: 'Eksportuj JPG',
            converting: 'Konwertowanie na obraz...',
            all_files: 'Wszystkie pliki',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Plik Beam Studio',
            fcode_files: 'Kod FLUX',
            clear_recent: 'Wyczyść ostatnio otwarte',
            path_not_exit: 'Wygląda na to, że ta ścieżka już nie istnieje na dysku.',
        },
        device: {
            download_log_canceled: 'Pobieranie dziennika logów zostało anulowane',
            download_log_error: 'Wystąpił nieznany błąd, spróbuj ponownie później',
            log: {
                usblist: 'Lista USB',
            },
            network_test: 'Sprawdź połączenie sieciowe',
        },
    },
    initialize: {
        // generic strings
        next: 'Następny',
        start: 'Start',
        skip: 'Pomiń',
        cancel: 'Anuluj',
        confirm: 'Potwierdzać',
        connect: 'Połącz',
        back: 'Cofnij',
        retry: 'Spróbuj ponownie',
        no_machine: 'Nie mam teraz maszyny.',
        // specific caption/content
        select_language: 'Wybierz język',
        select_machine_type: 'Wybierz typ swojej maszyny',
        select_connection_type: 'Jak chcesz się połączyć?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Sieć przewoda',
            ether2ether: 'Połączenie bezpośrednie',
            usb: 'Połączenie USB',
        },
        connect_wifi: {
            title: 'Łączenie z Wi-Fi',
            tutorial1: '1. Przejdź do Panelu dotykowego > Kliknij „Sieć” > „Połącz z Wi-Fi”.',
            tutorial1_ador: '1. Przejdź do Panelu dotykowego > Kliknij „MASZYNA” > Kliknij „Sieć” > „Połącz się z Wi-Fi”.',
            tutorial2: '2. Wybierz i połącz z preferowaną siecią Wi-Fi.',
            what_if_1: 'Co zrobić, jeśli nie widzę mojego Wi-Fi?',
            what_if_1_content: '1. Tryb szyfrowania Wi-Fi powinien być WPA2 lub bez hasła.Tryb szyfrowania można ustawić w panelu administracyjnym routera Wi-Fi.Jeśli router nie obsługuje standardu WPA2 i potrzebujesz pomocy w doborze odpowiedniego routera, skontaktuj się z pomocą techniczną FLUX.',
            what_if_2: 'Co jeśli nie widzę żadnego Wi-Fi?',
            what_if_2_content: '1. Upewnij się że adapter Wi-Fi jest dobrze podłączony. Jeśli na ekranie dotykowym nie ma adresu MAC sieci bezprzewodowej, skontaktuj się z pomocą techniczną FLUX. Kanał Wi-Fi powinien mieć częstotliwość 2,4 GHz (5 GHz nie jest obsługiwane).',
        },
        connect_wired: {
            title: 'Podłączenie do sieci przewodowej',
            tutorial1: '1. Połącz urządzenie z routerem.',
            tutorial2: '2. Naciśnij „Sieć”, aby uzyskać adres IP sieci przewodowej.',
            tutorial2_ador: '2. Prasa „MASZYNA” > „Sieć”, aby uzyskać adres IP sieci przewodowej.',
            what_if_1: 'Co jeśli adres IP jest pusty?',
            what_if_1_content: '1. Upewnij się, że kabel Ethernet jest dobrze podłączony.\n2. Jeśli na ekranie dotykowym nie ma adresu MAC sieci przewodowej, skontaktuj się z pomocą techniczną FLUX.',
            what_if_2: 'Co jeśli adres IP zaczyna się od 169?',
            what_if_2_content: '1. Jeśli adres IP zaczyna się od 169.254, powinien to być problem z ustawieniem DHCP, skontaktuj się ze swoim dostawcą usług internetowych w celu uzyskania dalszej pomocy. Jeśli Twój komputer łączy się z Internetem bezpośrednio za pomocą protokołu PPPoE, zmień sposób korzystania z routera na łączenie za pomocą protokołu PPPoE i włącz funkcję DHCP w routerze.',
        },
        connect_ethernet: {
            title: 'Połączenie bezpośrednie',
            tutorial1: '1. Podłącz urządzenie do komputera kablem ethernetowym.',
            tutorial2_1: '2. Śledź ',
            tutorial2_a_text: 'ten przewodnik',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' aby ustawić Twój komputer jako router',
            tutorial3: '3. Kliknij Dalej.',
        },
        connect_usb: {
            title: 'Połączenie USB',
            title_sub: '(tylko HEXA)',
            tutorial1: 'Podłącz urządzenie do komputera kablem USB.',
            tutorial2: "Kliknij 'Dalej'.",
            turn_off_machine: 'Twyłącz maszynę.',
            turn_on_machine: 'Włącz maszynę.',
            wait_for_turning_on: "Kliknij 'Dalej' after the startup process is complete and you have entered the main screen.",
        },
        connect_machine_ip: {
            check_usb: 'Sprawdź połączenie USB',
            enter_ip: 'Wprowadź adres IP Swojej maszyny',
            check_ip: 'Sprawdzanie dostępności adresu IP',
            invalid_ip: 'Nieprawidłowy adres IP:: ',
            invalid_format: 'Niepoprawny format',
            starts_with_169254: 'Zaczyna się od 169.254',
            unreachable: 'IP nieosiągalne',
            check_connection: 'Sprawdzanie połączenia maszyny',
            check_firmware: 'Sprawdzanie wersji oprogramowania układowegon',
            check_camera: 'Sprawdzanie dostępności kamery',
            retry: 'Spróbuj ponownie',
            succeeded_message: 'Zakończ ustawianie 🎉',
            finish_setting: 'Zacznij tworzyć!',
        },
        connecting: 'Łączę......',
        setting_completed: {
            start: 'Start',
            great: 'Witamy w Beam Studio',
            setup_later: 'Zawsze możesz skonfigurować Swoją maszynę, wybierając z górnego paska menu > „Maszyny” > „Ustawienia maszyny”',
            back: 'Wróć',
            ok: 'ZACZNIJ TWORZYĆ',
        },
    },
    error_pages: {
        screen_size: 'Należy pamiętać, że Beam Studio może nie działać optymalnie na Twoim urządzeniu. Aby uzyskać optymalne wrażenia, Twoje urządzenie powinno mieć ekran o minimalnej szerokości 1024 pikseli.',
    },
    menu: {
        mm: 'mm',
        inches: 'Cale',
    },
    settings: {
        on: 'Włączony',
        off: 'Wyłączony',
        low: 'Niski',
        high: 'Normalny',
        caption: 'Ustawienia',
        tabs: {
            general: 'Ogólne',
            device: 'Maszyna',
        },
        ip: 'Adres IP maszyny',
        guess_poke: 'Wyszukaj adres IP maszyny',
        auto_connect: 'Automatycznie wybierz jedyną maszynę',
        wrong_ip_format: 'Niewłaściwe formaty adresów IP',
        default_machine: 'Maszyna domyślna',
        default_machine_button: 'Wyczyść',
        remove_default_machine_button: 'Usuń',
        confirm_remove_default: 'Maszyna domyślna zostanie usunięta.',
        reset: 'Zresetuj Beam Studio',
        reset_now: 'Zresetuj Beam Studio',
        confirm_reset: 'Potwierdzić zresetowanie Beam Studio?',
        language: 'Język',
        notifications: 'Powiadomienia na komputerze',
        check_updates: 'Sprawdzaj automatycznie',
        autosave_enabled: 'Automatyczne zapisywanie',
        autosave_path: 'Lokalizacja automatycznego zapisu',
        autosave_interval: 'Zapisz co',
        autosave_number: 'Liczba automatycznych zapisów',
        autosave_path_not_correct: 'Nie znaleziono określonej ścieżki.',
        preview_movement_speed: 'Prędkość ruchu - podgląd',
        preview_movement_speed_hl: 'Prędkość ruchu - podgląd (włączony laser diodowy)',
        default_units: 'Jednostki domyślne',
        default_font_family: 'Domyślna czcionka',
        default_font_style: 'Domyślny styl czcionki',
        fast_gradient: 'Optymalizacja prędkości',
        engraving_direction: 'Kierunek',
        top_down: 'Z góry na dół',
        bottom_up: 'Od dołu do góry',
        vector_speed_constraint: 'Ograniczenie prędkości (20 mm/s)',
        loop_compensation: 'Kompensacja pętli',
        blade_radius: 'Promień ostrza',
        blade_precut_switch: 'Wstępnie nacięcie',
        blade_precut_position: 'Pozycja cięcia wstępnego',
        default_beambox_model: 'Domyślne ustawienie dokumentu',
        guides_origin: 'Początek lini pomocniczych',
        guides: 'Linie pomocnicze',
        image_downsampling: 'Jakość podglądu mapy bitowej',
        anti_aliasing: 'Wygładzanie krawędzi',
        continuous_drawing: 'Ciągłe rysowanie',
        trace_output: 'Efekt obrysowania obrazu',
        single_object: 'Pojedynczy obiekt',
        grouped_objects: 'Zgrupowane obiekty',
        simplify_clipper_path: 'Optymalizuj obliczoną ścieżkę',
        enable_low_speed: 'Włącz ruch z małą prędkością',
        enable_custom_backlash: 'Włącz niestandardową kompensację luzu',
        custom_preview_height: 'Niestandardowa wysokość podglądu',
        mask: 'Przycinanie obszaru roboczego',
        text_path_calc_optimization: 'Optymalizacja obliczania ścieżki',
        font_substitute: 'Zastąp nieobsługiwane znaki',
        font_convert: 'Konwerter tekstu na ścieżkę',
        default_borderless_mode: 'Domyślnie otwarte dno',
        default_enable_autofocus_module: 'Domyślny autofokus',
        default_enable_diode_module: 'Domyślny laser diodowy',
        diode_offset: 'Offset lasera diodowego',
        autofocus_offset: 'Przesunięcie autofokusa',
        diode_one_way_engraving: 'Grawerowanie laserem diodowym w jedną stronę',
        diode_two_way_warning: 'Dwukierunkowe grawerowanie jest szybsze, lecz może powodować obniżenie szczegółowości. Zalecamy najpierw przetestować.',
        share_with_flux: 'Udostępnij analizy Beam Studio',
        none: 'Żaden',
        close: 'Zamknij',
        enabled: 'Włączony',
        disabled: 'Wyłączony',
        cancel: 'Anuluj',
        done: 'Zastosuj',
        module_offset_10w: 'Offset lasera diodowego o mocy 10 W',
        module_offset_20w: 'Offset lasera diodowego o mocy 20 W',
        module_offset_printer: 'Przesunięcie drukarki',
        module_offset_2w_ir: 'Laser offsetowy na podczerwień o mocy 2 W',
        printer_advanced_mode: 'Tryb zaawansowany drukarki',
        default_laser_module: 'Domyślny moduł laserowy',
        low_laser_for_preview: 'Laser dla Rama do biegania',
        groups: {
            general: 'Ogólne',
            update: 'Aktualizacje oprogramowania',
            connection: 'Połączenie',
            autosave: 'Automatyczne zapisywanie',
            camera: 'Kamera',
            editor: 'Ustawienia edycji',
            engraving: 'Grawerowanie',
            path: 'Cięcie',
            mask: 'Przycinanie obszaru roboczego',
            text_to_path: 'Tekst',
            modules: 'Dodatki',
            ador_modules: 'Moduły Adoracji',
            privacy: 'Prywatność',
        },
        notification_on: 'Tak',
        notification_off: 'Nie',
        update_latest: 'Najnowsza (stabilna)',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Grupuj',
            use: 'Importuj pliki SVG',
            image: 'Obraz',
            text: 'Tekst',
        },
        context_menu: {
            cut: 'Wytnij',
            copy: 'Kopiuj',
            paste: 'Wklej',
            paste_in_place: 'Wklej w miejscu',
            duplicate: 'Duplikuj',
            delete: 'Usuń',
            group: 'Grupuj',
            ungroup: 'Rozgrupuj',
            move_front: 'Przesuń na wierzch',
            move_up: 'Przesuń do przodu',
            move_down: 'Przesuń do tyłu',
            move_back: 'Przesuń na spód',
        },
        popup: {
            select_import_method: 'Wybierz sposób rozdzielenia warstw:',
            select_import_module: 'Wybierz moduł:',
            touchpad: 'Panel dotykowy',
            mouse: 'Mysz',
            layer_by_layer: 'Warstwami',
            layer_by_color: 'Kolorami',
            nolayer: 'Pojedyncza warstwa',
            loading_image: 'Ładowanie obrazu, proszę czekać...',
            no_support_text: 'Beam Studio nie obsługuje obecnie tagów tekstowych. Przed zaimportowaniem zamień tekst na ścieżki.',
            speed_too_high_lower_the_quality: 'Użycie zbyt dużej prędkości przy tej rozdzielczości może skutkować niższą jakością graweru cieniowania.',
            both_power_and_speed_too_high: '"Użycie niższej mocy lasera wydłuży żywotność tuby laserowej. \nPonadto wysoka prędkość przy tej rozdzielczości może skutkować niższą jakością cieniowania graweru."',
            too_fast_for_path: 'Użycie zbyt dużej prędkości w warstwach zawierających obiekty ścieżki może spowodować niższą precyzję cięcia.\nNie zalecamy używania prędkości większej niż 20 mm/s podczas cięcia.',
            too_fast_for_path_and_constrain: 'Następujące warstwy: %s \nzawierają obiekty wektorowe a ustawiona do nich prędkość przekracza 20 mm/s. \nSzybkość cięcia obiektów wektorowych zostanie ograniczona do 20 mm/s. \nMożesz usunąć to ograniczenie w Ustawieniach programu.',
            should_update_firmware_to_continue: '#814 Twój firmware nie obsługuje tej wersji Beam Studio. Zaktualizuj oprogramowanie układowe, aby kontynuować. (Menu > Maszyna > [Twoja maszyna] > Aktualizuj oprogramowanie sprzętowe)',
            recommend_downgrade_software: 'We detected an older firmware version. We are actively addressing compatibility issues, but for now, we recommend reverting to <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Wykryliśmy starszą wersję oprogramowania sprzętowego. Aktywnie rozwiązujemy problemy ze zgodnością, ale na razie zalecamy aktualizację oprogramowania do najnowszej wersji.',
            still_continue: 'Kontynuuj',
            more_than_two_object: 'Zbyt wiele obiektów. Obsługa tylko 2 obiektów',
            not_support_object_type: 'Nie obsługuje typu obiektu',
            select_first: 'Najpierw wybierz obiekt.',
            select_at_least_two: 'Wybierz dwa obiekty, aby kontynuować',
            import_file_contain_invalid_path: '#808 Zaimportowany plik SVG zawiera nieprawidłową ścieżkę do obrazu. Upewnij się, że wszystkie pliki obrazów istnieją lub umieść obraz w pliku',
            import_file_error_ask_for_upload: 'Nie udało się zaimportować pliku SVG. Czy chcesz udostępnić plik zespołowi programistycznemu w celu zgłoszenia błędu?',
            upload_file_too_large: '#819 Plik jest za duży do przesłania.',
            successfully_uploaded: 'Przesłanie pliku powiodło się.',
            upload_failed: '#819 Przesyłanie pliku nie powiodło się.',
            or_turn_off_borderless_mode: ' Lub wyłącz tryb Otwarte dno.',
            svg_1_1_waring: 'Wersja tego pliku SVG to v 1.1, mogą wystąpić potencjalne problemy z kompatybilnością.',
            svg_image_path_waring: 'Ten plik SVG zawiera <image> ładowane ze ścieżki pliku. Może to powodować błędy podczas ładowania. Aby uniknąć tego ryzyka, uprzejmie prosimy o użycie obrazu osadzonego podczas eksportowania SVG.',
            dxf_version_waring: 'Wersja tego pliku DXF jest inna niż 2013, mogą występować potencjalne problemy z kompatybilnością.',
            dont_show_again: 'Nie pokazuj następnym razem.',
            convert_to_path_fail: 'Nie udało się przekonwertować na ścieżkę.',
            save_unsave_changed: 'Czy chcesz zapisać niezapisane zmiany?',
            dxf_bounding_box_size_over: 'Rozmiar rysunku jest poza obszarem roboczym. Przesuń rysunek bliżej punktu początkowego w oprogramowaniu CAD lub upewnij się, że jednostka jest ustawiona prawidłowo.',
            progress: {
                uploading: 'Przesyłanieg',
                calculating: 'Obliczanie',
            },
            backend_connect_failed_ask_to_upload: '#802 Podczas próby połączenia z backendem występują błędy. Czy chcesz przesłać dziennik raportów o błędach?',
            backend_error_hint: 'Funkcje mogą nie działać poprawnie z powodu błędu zaplecza.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Błąd podczas konwersji pliku PDF na SVG:',
                error_pdf2svg_not_found: '#825 Błąd: nie znaleziono polecenia pdf2svg. Zainstaluj pdf2svg za pomocą menedżera pakietów (np. „yum install pdf2svg” lub „apt-get install pdf2svg”).',
            },
            ungroup_use: 'Spowoduje to rozgrupowanie zaimportowanych plików DXF lub SVG. Ponieważ plik może zawierać dużą liczbę elementów, rozgrupowanie może zająć trochę czasu. Czy na pewno chcesz kontynuować?',
            vectorize_shading_image: 'Obrysowanie obrazów gradientowych zajmuje więcej czasu i jest podatne na szumy. Uprzejmie prosimy o wyłączenie gradientu obrazu przed kontynuowaniem.',
            change_workarea_before_preview: 'Obszar roboczy %s nie pasuje do aktualnie ustawionego obszaru roboczego. Czy chcesz zmienić bieżący obszar roboczy?',
            bug_report: 'Zgłoszenie błędut',
            sentry: {
                title: 'Ulepszajmy Beam Studio razem',
                message: 'Czy zgadzasz się na automatyczne przesyłanie odpowiednich informacji do zespołu programistów w przypadku wystąpienia błędów?',
            },
            questionnaire: {
                caption: 'Pomóż nam wypełnić kwestionariusz',
                message: 'Pomóż nam wypełnić ankietę, aby ulepszać Beam Studio.',
                unable_to_get_url: 'Nie udało się uzyskać linku do kwestionariusza przez Internet. Potwierdź połączenie internetowe.',
                no_questionnaire_available: 'W tej chwili nie ma dostępnej ankiety.',
            },
            facebook_group_invitation: {
                title: 'Dołączyć FLUX Official User Group',
                message: 'Dołączyćnasza oficjalna grupa na Facebooku, w której możesz kontaktować się z innymi użytkownikami laserów FLUX, omawiać lasery FLUX, dzielić się grafikami dotyczącymi laserów i być na bieżąco z najnowszymi aktualizacjami naszych produktów. Nie możemy się doczekać, aż Cię tam zobaczymy!',
                join_now: 'Dołączyć',
                later: 'Może później',
                already_joined: 'Już dołączyłeś',
                dont_show_again: 'Nie pokazuj tego więcej',
            },
            ai_credit: {
                relogin_to_use: 'Aby skorzystać z tej funkcji, zaloguj się ponownie.',
                insufficient_credit: 'Skończył Ci się kredyt',
                insufficient_credit_msg: 'Nie możesz użyć %s. Przejdź do centrum członkowskiego i kup kredyt AI.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Iść',
            },
            text_to_path: {
                caption: 'Konwerter tekstu na ścieżkę 2.0',
                message: 'Beam Studio wprowadza nowy konwerter tekstu na ścieżkę (Konwerter 2.0), który daje bardziej niezawodne wyniki! Czy chcesz teraz przełączyć się na niego? \nMożesz również dostosować to ustawienie później w sekcji "Konwerter tekstu na ścieżkę" w preferencjach.',
            },
        },
        zoom_block: {
            fit_to_window: 'Dopasuj do oknaw',
        },
        time_est_button: {
            calculate: 'Oszacuj czas',
            estimate_time: 'Szacowany czas:',
        },
        left_panel: {
            unpreviewable_area: 'Obszar bez podglądu',
            diode_blind_area: 'Dodatkowy obszar bez podglądu dla lasera diodowego',
            borderless_blind_area: 'Obszar bez możliwości grawerowania',
            borderless_preview: 'Podgląd kamery w trybie Otwartego dna',
            rectangle: 'Prostokąt',
            ellipse: 'Elipsa',
            line: 'Linia',
            image: 'Obraz',
            text: 'Tekst',
            label: {
                cursor: 'Wybierz',
                photo: 'Obraz',
                text: 'Tekst',
                line: 'Linia',
                rect: 'Prostokąt',
                oval: 'Elipsa',
                polygon: 'Wielokąt',
                pen: 'Pióro',
                shapes: 'Elementy',
                array: 'Ułóż',
                preview: 'Podgląd kamery',
                trace: 'Obrysuj obraz',
                end_preview: 'Koniec podglądu',
                clear_preview: 'Wyczyść podgląd',
                choose_camera: 'Kamera',
                live_feed: 'Na żywo',
                adjust_height: 'Dostosuj wysokość',
                qr_code: 'Kod QR',
                boxgen: 'Boxgen',
                my_cloud: 'Moja chmura',
                curve_engraving: {
                    title: 'Krzywa 3D',
                    exit: 'Wyjście',
                    select_area: 'Wybierz obszar',
                    preview_3d_curve: 'Podgląd krzywej 3D',
                    clear_area: 'Wyczyść wybrany obszar',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Warstwy',
                objects: 'Obiekty',
                path_edit: 'Edycja ścieżki',
            },
            layer_panel: {
                layer1: 'Warstwa 1',
                layer_bitmap: 'Mapa bitowa',
                layer_engraving: 'Grawerowanie',
                layer_cutting: 'Cięcie',
                current_layer: 'Bieżąca warstwa',
                move_elems_to: 'Przenieś elementy do:',
                notification: {
                    dupeLayerName: 'Istnieje już warstwa o takiej nazwie!',
                    newName: 'NOWA NAZWA',
                    enterUniqueLayerName: 'Wprowadź unikalną nazwę warstwy',
                    enterNewLayerName: 'Wprowadź nową nazwę warstwy',
                    layerHasThatName: 'Warstwa ma już tę nazwę',
                    QmoveElemsToLayer: 'Przenieś wybrane elementy do warstwy „%s”?',
                    moveElemFromPrintingLayerTitle: 'Przenieść wybrany element do %s i przekonwertować go na element laserowy?',
                    moveElemFromPrintingLayerMsg: 'Pamiętaj, że jeśli wykonasz tę operację, ustawienia kolorów wybranego elementu zostaną usunięte i ustawione zgodnie z %s.',
                    moveElemToPrintingLayerTitle: 'Przenieść wybrany element do %s i przekonwertować go na element drukujący?',
                    moveElemToPrintingLayerMsg: 'Pamiętaj, że jeśli wykonasz tę operację, ustawienia wybranego elementu zostaną usunięte i ustawione zgodnie z %s.',
                    splitColorTitle: 'Rozwinąć wybraną warstwę na warstwy CMYK?',
                    splitColorMsg: 'Należy pamiętać, że wykonanie tej procedury nie umożliwi przywrócenia pełnej warstwy koloru.',
                    mergeLaserLayerToPrintingLayerTitle: 'Czy chcesz połączyć warstwy w moduł Drukowanie?',
                    mergeLaserLayerToPrintingLayerMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia warstwy lasera zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
                    mergePrintingLayerToLaserLayerTitle: 'Czy chcesz połączyć warstwy w moduł Laser?',
                    mergePrintingLayerToLaserLayerMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia kolorów warstwy druku zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
                },
                layers: {
                    layer: 'Warstwa',
                    layers: 'Warstwy',
                    del: 'Usuń warstwę',
                    move_down: 'Przesuń warstwę w dół',
                    new: 'Nowa warstwa',
                    rename: 'Zmień nazwę warstwy',
                    move_up: 'Przenieś warstwę w górę',
                    dupe: 'Zduplikuj warstwę',
                    lock: 'Zablokuj warstwę',
                    unlock: 'Odblokować',
                    merge_down: 'Połącz w dół',
                    merge_all: 'Połącz wszystko',
                    merge_selected: 'Połącz wybrane warstwy',
                    move_elems_to: 'Przenieś elementy do:',
                    move_selected: 'Przenieś wybrane elementy na inną warstwę',
                    switchToFullColor: 'Przełącz na warstwę pełnego koloru',
                    switchToSingleColor: 'Przełącz na pojedynczą warstwę koloru',
                    splitFullColor: 'Rozwiń warstwę',
                    fullColor: 'Pełny kolor',
                },
            },
            laser_panel: {
                preset_setting: 'Ustawienia parametrów (%s)',
                multi_layer: 'Wiele warstw',
                parameters: 'Parametry',
                strength: 'Moc',
                low_power_warning: 'Niższa moc lasera (poniżej 10%) może nie emitować światła lasera.',
                speed: 'Prędkość',
                speed_contrain_warning: 'Szybkość cięcia obiektów wektorowych zostanie ograniczona do 20 mm/s. Możesz usunąć to ograniczenie w Ustawieniach preferencji.',
                low_speed_warning: 'Niska prędkość może spowodować spalenie materiału.',
                repeat: 'Wykonaj',
                add_on: 'Dodatek',
                focus_adjustment: 'Regulacja focusa',
                height: 'Wysokość obiektu',
                z_step: 'Krok Z',
                diode: 'Laser diodowy',
                backlash: 'Reakcja',
                ink_saturation: 'Nasycenie',
                print_multipass: 'Wieloprzebiegowe',
                white_ink: 'Biały atrament',
                white_ink_settings: 'Ustawienia białego atramentu',
                color_adjustment: 'Regulacja kanału',
                color_adjustment_short: 'Kanał',
                halftone: 'Połton',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Siła',
                times: 'czasy',
                cut: 'Cięcie',
                engrave: 'Graweruj',
                more: 'Zarządzaj',
                delete: 'Usuwaj',
                reset: 'Resetowanie',
                sure_to_reset: 'Spowoduje to zresetowanie wszystkich ustawień wstępnych i zachowa dostosowane parametry. Czy na pewno chcesz kontynuować?',
                apply: 'Zastosuj',
                cancel: 'Anuluj',
                save: 'Zapisz',
                save_and_exit: 'Zapisz i wyjdź',
                name: 'Nazwa',
                default: 'Domyślny',
                customized: 'Dostosowana lista',
                inuse: 'Za pomocą',
                export_config: 'Eksportuj ustawienia wstępne',
                new_config_name: 'Nowa nazwa ustawienia wstępnego',
                sure_to_load_config: 'Spowoduje to załadowanie ustawień wstępnych i zastąpienie niestandardowych parametrów ustawionych w pliku, czy na pewno kontynuować?',
                custom_preset: 'Niestandardowe',
                various_preset: 'Różne ustawienia wstępne',
                module: 'Moduł',
                slider: {
                    regular: 'Regularny',
                    low: 'Niski',
                    very_low: 'Min',
                    high: 'Wysoki',
                    very_high: 'Maks',
                    slow: 'Powolny',
                    very_slow: 'Najwolniej',
                    fast: 'Szybko',
                    very_fast: 'Najszybszy',
                },
                dropdown: {
                    parameters: 'Presety',
                    save: 'Dodaj bieżące parametry',
                    export: 'Eksportuj',
                    import: 'Importuj',
                    more: 'Zarządzaj',
                    mm: {
                        wood_3mm_cutting: 'Drewno 3mm - cięcie',
                        wood_5mm_cutting: 'Drewno 5mm - cięcie',
                        wood_7mm_cutting: 'Drewno 7mm - cięcie',
                        wood_8mm_cutting: 'Drewno 8mm - cięcie',
                        wood_10mm_cutting: 'Drewno 10mm - cięcie',
                        wood_engraving: 'Drewno - grawerowanie',
                        acrylic_3mm_cutting: 'Akryl 3mm - cięcie',
                        acrylic_5mm_cutting: 'Akryl 5mm - cięcie',
                        acrylic_8mm_cutting: 'Akryl 8mm - cięcie',
                        acrylic_10mm_cutting: 'Akryl 10mm - cięcie',
                        acrylic_engraving: 'Akryl - grawerowanie',
                        mdf_3mm_cutting: 'MDF 3mm - cięcie',
                        mdf_5mm_cutting: 'MDF 5mm - cięcie',
                        mdf_engraving: 'MDF - grawerowanie',
                        leather_3mm_cutting: 'Skóra 3 mm - cięcie',
                        leather_5mm_cutting: 'Skóra 5 mm - cięcie',
                        leather_engraving: 'Skóra - grawerowanie',
                        denim_1mm_cutting: 'Dżinsowa 1mm - cięcie',
                        fabric_3mm_cutting: 'Tkanina 3mm - cięcie',
                        fabric_5mm_cutting: 'Tkanina 5mm - cięcie',
                        fabric_engraving: 'Tkanina - grawerowanie',
                        rubber_bw_engraving: 'Guma - grawerowanie',
                        glass_bw_engraving: 'Szkło - grawerowanie',
                        metal_bw_engraving: 'Metal - grawerowanie',
                        steel_engraving_spray_engraving: 'Metal - grawerowanie',
                        stainless_steel_bw_engraving_diode: 'Metal - grawerowanie (laser diodowy)',
                        gold_engraving: 'Złoto - grawerowanie',
                        brass_engraving: 'Mosiądz - grawerowanie',
                        ti_engraving: 'Tytan - grawerowanie',
                        stainless_steel_engraving: 'Stal nierdzewna - grawerowanie',
                        aluminum_engraving: 'Aluminum - grawerowanie',
                        black_acrylic_3mm_cutting: 'Czarny akryl 3mm - cięcie',
                        black_acrylic_5mm_cutting: 'Czarny akryl 5mm - cięcie',
                        black_acrylic_engraving: 'Czarny akryl - grawerowanie',
                        abs_engraving: 'ABS - grawerowanie',
                        silver_engraving: 'Srebro - grawerowanie',
                        iron_engraving: 'Żelazo - grawerowanie',
                        fabric_printing: 'Tkanina - druk',
                        canvas_printing: 'Płótno - druk',
                        cardstock_printing: 'Karton - druk',
                        wood_printing: 'Drewno - druk',
                        bamboo_printing: 'Bambus - druk',
                        cork_printing: 'Korek - druk',
                        flat_stone_printing: 'Kamień - druk',
                        acrylic_printing: 'Akryl - druk',
                        pc_printing: 'PC - druk',
                        stainless_steel_printing: 'Stal nierdzewna - druk',
                        gloss_leather_printing: 'Gloss Skóra - druk',
                        glass_printing: 'Szkło - druk',
                    },
                    inches: {
                        wood_3mm_cutting: "Drewno — cięcie 0,1''",
                        wood_5mm_cutting: "Drewno — cięcie 0,2''",
                        wood_7mm_cutting: "Drewno — cięcie 0,3''",
                        wood_8mm_cutting: "Drewno — cięcie 0,3''",
                        wood_10mm_cutting: "Drewno — cięcie 0,4''",
                        wood_engraving: 'Drewno - grawerowanie',
                        acrylic_3mm_cutting: "Akryl - cięcie 0,1''",
                        acrylic_5mm_cutting: "Akryl - cięcie 0,2''",
                        acrylic_8mm_cutting: "Akryl - cięcie 0,3''",
                        acrylic_10mm_cutting: "Akryl - cięcie 0,4''",
                        acrylic_engraving: 'Akryl - grawerowanie',
                        mdf_3mm_cutting: "MDF - cięcie 0.1''",
                        mdf_5mm_cutting: "MDF - cięcie 0.2''",
                        mdf_engraving: 'MDF - grawerowanie',
                        leather_3mm_cutting: "Skóra — cięcie 0,1''",
                        leather_5mm_cutting: "Skóra — cięcie 0,2''",
                        leather_engraving: 'Skóra - grawerowanie',
                        denim_1mm_cutting: "Dżinsowa - cięcie 0.04''",
                        fabric_3mm_cutting: "Tkanina — cięcie 0,1''",
                        fabric_5mm_cutting: "Tkanina - cięcie 0,2''",
                        fabric_engraving: 'Tkanina - grawerowanie',
                        rubber_bw_engraving: 'Guma - grawerowanie',
                        glass_bw_engraving: 'Szkło - grawerowanie',
                        metal_bw_engraving: 'Metal - grawerowanie',
                        steel_engraving_spray_engraving: 'Metal - grawerowanie',
                        stainless_steel_bw_engraving_diode: 'Metal - grawerowanie (laser diodowy)',
                        gold_engraving: 'Złoto - grawerowanie',
                        brass_engraving: 'Mosiądz - grawerowanie',
                        ti_engraving: 'Tytan - grawerowanie',
                        stainless_steel_engraving: 'Stal nierdzewna - grawerowanie',
                        aluminum_engraving: 'Aluminum - grawerowanie',
                        black_acrylic_3mm_cutting: "Czarny akryl - cięcie 0.1''",
                        black_acrylic_5mm_cutting: "Czarny akryl - cięcie 0.2''",
                        black_acrylic_engraving: 'Czarny akryl - grawerowanie',
                        abs_engraving: 'ABS - grawerowanie',
                        silver_engraving: 'Srebro - grawerowanie',
                        iron_engraving: 'Żelazo - grawerowanie',
                        fabric_printing: 'Tkanina - druk',
                        canvas_printing: 'Płótno - druk',
                        cardstock_printing: 'Karton - druk',
                        wood_printing: 'Drewno - druk',
                        bamboo_printing: 'Bambus - druk',
                        cork_printing: 'Korek - druk',
                        flat_stone_printing: 'Kamień - druk',
                        acrylic_printing: 'Akryl - druk',
                        pc_printing: 'PC - druk',
                        stainless_steel_printing: 'Stal nierdzewna - druk',
                        gloss_leather_printing: 'Gloss Skóra - druk',
                        glass_printing: 'Szkło - druk',
                    },
                },
                laser_speed: {
                    text: 'Prędkość',
                    unit: 'mm/s',
                    fast: 'Szybka',
                    slow: 'Wolna',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Moc',
                    high: 'Wysoka',
                    low: 'Niska',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Rodzaj atramentu',
                    normal: 'Atrament',
                    UV: 'Atrament UV',
                },
                para_in_use: 'Ten parametr jest używany.',
                do_not_adjust_default_para: 'Nie można dostosować ustawień wstępnych.',
                existing_name: 'Ta nazwa została już użyta.',
                presets: 'Wstępnie ustawione',
            },
            object_panel: {
                zoom: 'Powiększenie',
                group: 'Grupuj',
                ungroup: 'Rozgrupuj',
                distribute: 'Dystrybucja',
                hdist: 'Dystrybucja pozioma',
                vdist: 'Dystrybucja pionowa',
                align: 'Wyrównaj',
                left_align: 'Wyrównaj do lewej',
                center_align: 'Wyrównaj do środka',
                right_align: 'Wyrównaj do prawej',
                top_align: 'Wyrównaj do góry',
                middle_align: 'Wyrównaj do środka',
                bottom_align: 'Wyrównaj do dołu',
                boolean: 'Wartość logiczna',
                union: 'Połącz',
                subtract: 'Odejmij wierzch',
                intersect: 'Przetnij',
                difference: 'Wyklucz',
                flip: 'Odbij',
                hflip: 'Odbij w poziomie',
                vflip: 'Odbij w pionie',
                lock_aspect: 'Zachowaj proporcję szerokości i wysokości',
                unlock_aspect: 'Odblokuj proporcję szerokości i wysokości',
                option_panel: {
                    fill: 'Wypełnienie',
                    rounded_corner: 'Zaokrąglenie narożników',
                    sides: 'Boki',
                    font_family: 'Czcionka',
                    font_style: 'Styl',
                    font_size: 'Rozmiar',
                    letter_spacing: 'Odstępy między literami',
                    line_spacing: 'Odstępy między wierszami',
                    vertical_text: 'Tekst pionowy',
                    start_offset: 'Przesunięcie tekstu',
                    vertical_align: 'Wyrównanie',
                    text_infill: 'Wypełnienie tekstu',
                    path_infill: 'Wypełnienie ścieżki',
                    shading: 'Gradient',
                    threshold: 'Próg jasności',
                    threshold_short: 'Próg',
                    stroke: 'Skoku',
                    stroke_color: 'Kolor skoku',
                    stroke_width: 'Szerokość skoku',
                    color: 'Kolor',
                },
                actions_panel: {
                    replace_with: 'Zamień z...',
                    replace_with_short: 'Zastępować',
                    trace: 'Obrysuj',
                    grading: 'Cieniuj',
                    brightness: 'Jasność',
                    sharpen: 'Wyostrzyć',
                    crop: 'Przytnij',
                    bevel: 'Fazuj',
                    invert: 'Odwróć',
                    weld_text: 'Tekst spoiny',
                    convert_to_path: 'Konwertuj na ścieżkę',
                    fetching_web_font: 'Pobieranie czcionki online...',
                    uploading_font_to_machine: 'Przesyłanie czcionki do urządzenia...',
                    wait_for_parsing_font: 'Parsowanie czcionki... Proszę chwilę poczekać',
                    offset: 'Przesunięcie ścieżki',
                    array: 'Szyk',
                    auto_arrange: 'Automatyczne Układanie',
                    decompose_path: 'Rozwiń',
                    disassemble_use: 'Rozłóż',
                    create_textpath: 'Utwórz tekst na ścieżce',
                    create_textpath_short: 'Tekst ścieżki',
                    detach_path: 'Rozłóż tekst na ścieżce',
                    detach_path_short: 'Rozwiń',
                    edit_path: 'Edytuj ścieżkę',
                    disassembling: 'Demontuję...',
                    ungrouping: 'Rozgrupowywuje...',
                    simplify: 'Uproszczać',
                    ai_bg_removal: 'Usunąć tła',
                    ai_bg_removal_short: 'BG Usunąć',
                    ai_bg_removal_reminder: 'Naciśnięcie przycisku spowoduje natychmiastowe wykorzystanie 0,2 kredytu. Czy chcesz kontynuować?',
                    outline: 'Zarys',
                },
                path_edit_panel: {
                    node_type: 'TYP WĘZŁA',
                    sharp: 'Ostre',
                    round: 'Okrągłe',
                    connect: 'Łączyć',
                    disconnect: 'Odłączyć',
                    delete: 'Usuwać',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Konwertuj tekst na ścieżkę...',
            retreive_image_data: 'Pobierz dane obrazu...',
            export_file_error_ask_for_upload: 'Nie udało się wyeksportować zadania. Czy chcesz udostępnić scenę roboczą do opracowania zespołu do zgłaszania błędów?',
        },
        image_trace_panel: {
            apply: 'Zastosuj',
            back: 'Wróć',
            cancel: 'Anuluj',
            next: 'Następny',
            brightness: 'Jasność',
            contrast: 'Kontrast',
            threshold: 'Próg',
            okay: 'Dobrze',
            tuning: 'Parametry',
        },
        photo_edit_panel: {
            apply: 'Zastosuj',
            back: 'Wróć',
            cancel: 'Anuluj',
            next: 'Następny',
            sharpen: 'Wyostrzyć',
            sharpness: 'Ostrość',
            radius: 'Promień',
            crop: 'Przytnij',
            aspect_ratio: 'Awspółczynnik proporcji',
            original: 'pierwowzór',
            free: 'Bezpłatny',
            curve: 'Krzywa',
            start: 'Rozpocznij',
            processing: 'Przetwarzanie',
            invert: 'Odwróć kolor',
            okay: 'Dobrze',
            compare: 'Porównywnaj',
            phote_edit: 'Edycja zdjęć',
            brightness_and_contrast: 'Jasność / Kontrast',
            brightness: 'Jasność',
            contrast: 'Kontrast',
        },
        document_panel: {
            document_settings: 'Ustawienia dokumentu',
            engrave_parameters: 'Parametry grawerowania',
            workarea: 'Obszar roboczy',
            rotary_mode: 'Moduł obrotowy',
            borderless_mode: 'Otwarte dno',
            engrave_dpi: 'Jakość',
            enable_diode: 'Laser diodowy',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Rozszerz obszar roboczy',
            add_on: 'Dodatki',
            low: 'Niska',
            medium: 'Średna',
            high: 'Wysoka',
            ultra: 'Super wysoka',
            enable: 'Włącz',
            disable: 'Wyłącz',
            cancel: 'Anuluj',
            save: 'Zapisz',
            notification: {
                changeFromPrintingWorkareaTitle: 'Czy chcesz przekonwertować warstwy druku na warstwy lasera?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Parsowanie czcionki... Proszę chwilę poczekać',
            text_to_path: {
                font_substitute_pop: 'Twój tekst zawiera znaki, które nie są obsługiwane przez bieżącą czcionkę. <br/>Czy chcesz użyć <strong>%s</strong> jako zamiennika?',
                check_thumbnail_warning: 'Niektóre teksty zostały zmienione na inne czcionki podczas zmieniania tekstów na ścieżki, a niektóre znaki mogą nie zostać normalnie przekonwertowane.Sprawdź ponownie obraz podglądu przed wysłaniem zadania.',
                error_when_parsing_text: 'Błąd podczas konwersji tekstu na ścieżkę',
                use_current_font: 'Użyj bieżącej czcionki',
                retry: 'Spróbuj ponownie później lub wybierz inną czcionkę',
            },
            lock_desc: 'Zachowaj proporcje szerokości i wysokości (SHIFT)',
        },
        tool_panels: {
            cancel: 'Anuluj',
            confirm: 'Potwierdź',
            grid_array: 'Utwórz powtórzenie - siatka',
            array_dimension: 'Wymiar siatki',
            rows: 'Rzędy',
            columns: 'Kolumny.',
            array_interval: 'Interwał powtórzenia',
            dx: 'X',
            dy: 'Y',
            offset: 'Przesunięcie',
            nest: 'Optymalizacja ułożenia',
            _offset: {
                direction: 'Kierunek przesunięcia',
                inward: 'Na zewnątrz',
                outward: 'Do wewnątrz',
                dist: 'Odległość przesunięcia',
                corner_type: 'Narożniki',
                sharp: 'Ostre',
                round: 'Okrągłe',
                fail_message: 'Nie udało się przesunąć ścieżki obiektów.',
                not_support_message: 'Wybrane elementy zawierają nieobsługiwany tag SVG: Obraz, Grupę, Tekst i Zaimportowany obiekt. ',
            },
            _nest: {
                start_nest: 'Ułóż',
                stop_nest: 'Zatrzymaj',
                end: 'Zamknij',
                spacing: 'Odległość',
                rotations: 'Możliwy obrót',
                no_element: 'Nie ma elementu do ułożenia.',
            },
        },
        network_testing_panel: {
            network_testing: 'Testowanie sieci',
            local_ip: 'Lokalny adres IP:',
            insert_ip: 'Adres IP urządzenia docelowego:',
            empty_ip: '#818 Najpierw wprowadź adres IP urządzenia docelowego.',
            start: 'Rozpocznij',
            end: 'Koniec',
            testing: 'Testowanie sieci...',
            invalid_ip: '#818 Nieprawidłowy adres IP',
            ip_startswith_169: '#843 Adres IP maszyny zaczyna się od 169.254',
            connection_quality: 'Jakość połączenia',
            average_response: 'Średni czas odpowiedzi',
            test_completed: 'Test zakończony',
            test_fail: 'Test nieudany',
            cannot_connect_1: '#840 Połączenie z docelowym adresem IP nie powiodło się.',
            cannot_connect_2: '#840 Połączenie z docelowym adresem IP nie powiodło się. Upewnij się że urządzenie docelowe znajduje się w tej samej sieci.',
            network_unhealthy: '#841 Jakość połączenia <70 lub średni czas odpowiedzi >100 ms',
            device_not_on_list: '#842 Maszyny nie ma na liście, ale jakość połączenia jest >70, a średni czas odpowiedzi <100ms',
            hint_device_often_on_list: 'Maszyny często nie ma na liście?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Nie udało się połączyć podczas wysyłania zadania?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Przekroczono limit czasu podczas uruchamiania podglądu kamery?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Dostęp do lokalnego adresu IP nie powiódł się.',
            fail_to_start_network_test: '#817 Rozpoczęcie testowania sieci nie powiodło się.',
            linux_permission_hint: 'Ten błąd zwykle występuje z powodu niewystarczających uprawnień.\nUprzejmie uruchom „sudo beam-studio --no-sandbox” w terminalu, aby uzyskać uprawnienia i przeprowadzić testy sieci.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Konfiguracja kolorów warstwy',
            color: 'Kolor',
            power: 'Moc',
            speed: 'Prędkość',
            repeat: 'Wykonaj',
            add: 'Dodaj',
            save: 'Zapisz',
            cancel: 'Anuluj',
            default: 'Przywróć ustawienia domyślne',
            add_config: 'Dodaj kolor',
            in_use: 'Ten kolor jest używany.',
            no_input: 'Wprowadź prawidłowy kod koloru HEX.',
            sure_to_reset: 'Utracisz wszystkie dostosowane parametry. Czy na pewno chcesz przywrócić ustawienia domyślne?',
            sure_to_delete: 'Czy na pewno chcesz usunąć to ustawienie kolorów?',
        },
        rating_panel: {
            title: 'Lubisz Beam Studio?',
            description: 'Jeśli podoba Ci się Beam Studio, bylibyśmy bardzo wdzięczni za ocenę.',
            dont_show_again: 'Nie pokazuj tego następnym razem.',
            thank_you: 'Dziękuję!',
        },
        svg_editor: {
            unnsupported_file_type: 'Typ pliku nie jest bezpośrednio obsługiwany. Przekonwertuj plik na format SVG lub bitmapę',
            unable_to_fetch_clipboard_img: 'Nie udało się pobrać obrazu ze schowka',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Play',
            pause: 'Wstrzymaj',
            stop: 'Zatrzymaj',
            play_speed: 'Szybkość odtwarzania',
            travel_path: 'Ścieżka podróży',
            invert: 'Odwróć',
            preview_info: 'Podgląd informacji',
            size: 'Rozmiar',
            estimated_time: 'Szacowany całkowity czas',
            cut_time: 'Skróć czas',
            rapid_time: 'Czas podróży',
            cut_distance: 'Odległość cięcia',
            rapid_distance: 'Odległość podróży',
            current_position: 'Aktualna pozycja',
            remark: '* Wszystkie informacje są wartościami szacunkowymi w celach informacyjnych.',
            start_here: 'Zacznij tutaj',
            end_preview: 'Zakończ podgląd',
        },
        shapes_panel: {
            title: 'Elementy',
            basic: 'Podstawowe',
            shape: 'Kształt',
            graphics: 'Grafika',
            arrow: 'Strzałka',
            label: 'Etykieta',
            decor: 'Dekoracja',
            circular: 'Okrągły',
            corner: 'Róg',
            line: 'Linia',
            photo: 'Zdjęcie',
            ribbon: 'Wstążka',
            speech: 'Mowa',
            text: 'Tekst',
            animals: 'Zwierzę',
            birds: 'Ptak',
            land: 'Ląd',
            sea: 'Morze',
            holidays: 'Święto',
            celebration: 'Celebracja',
            CNY: 'Chiński Nowy Rok',
            easter: 'Wielkanoc',
            halloween: 'Halloween',
            valentines: 'Walentynki',
            Xmas: 'Święta Bożego Narodzenia',
            nature: 'Natura',
            elements: 'Elementy',
            environment: 'Środowisko',
            plants: 'Rośliny',
            weather: 'Pogoda',
        },
        announcement_panel: {
            title: 'Ogłoszenie',
            dont_show_again: 'Nie pokazuj więcej',
        },
    },
    editor: {
        prespray_area: 'Obszar gruntowania',
        opacity: 'Podgląd przezroczystości',
        exposure: 'Podgląd naświetlenia',
    },
    flux_id_login: {
        connection_fail: '#847 Połączenie z usługą członkowską FLUX nie powiodło się.',
        login_success: 'Zalogowano się pomyślnie.',
        login: 'Zaloguj sie',
        unlock_shape_library: 'Zaloguj się, aby odblokować bazę kształtów.',
        email: 'E-mail',
        password: 'Hasło',
        remember_me: 'Zapamiętaj mnie',
        forget_password: 'Zapomniałeś hasła?',
        register: 'Utwórz swoje konto FLUX',
        offline: 'Pracuj offline',
        work_offline: 'Pracuj offline',
        incorrect: 'Adres e-mail lub hasło są nieprawidłowe.',
        not_verified: 'Adres e-mail nie został jeszcze zweryfikowany.',
        new_to_flux: 'Nowy we FLUXie? Utwórz konto.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Zapoznaj się z planami FLUX+',
            thank_you: 'Dziękujemy za bycie cennym członkiem!',
            ai_credit_tooltip: 'Dla usuwania tła AI',
            flux_credit_tooltip: 'Dla plików Design Market i usuwania tła AI',
            goto_member_center: 'Przejdź do Centrum członkowskiego',
            access_plus_feature_1: 'Uzyskujesz dostęp do funkcji',
            access_plus_feature_2: 'funkcja.',
            access_plus_feature_note: 'Musisz mieć członkostwo FLUX+, aby uzyskać dostęp do tej funkcji.',
            access_monotype_feature: 'Nie masz dodatku Czcionki Monotype.',
            access_monotype_feature_note: 'Musisz mieć członkostwo FLUX+ Pro lub dodatek Czcionki Monotype, aby uzyskać dostęp do tej funkcji.',
            learn_more: 'Dowiedz się więcej',
            get_addon: 'Pobierz dodatek',
            subscribe_now: 'Zapisz się teraz',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Usuwanie tła AI',
                my_cloud: 'Nielimitowana przestrzeń w chmurze',
                boxgen: 'Generator pudełek 3D',
                dmkt: '1000+ plików projektowych',
                monotype: '250+ czcionek premium',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Zaloguj się, aby odblokować bazę kształtów.',
        enjoy_shape_library: 'Korzystaj z bazy kształtów.',
        shapes: 'Kształty',
        elements: 'Elementy',
        recent: 'Ostatnie',
        search: 'Szukaj',
        clear: 'Wyczyść',
        export_svg_title: 'Nie można wyeksportować pliku SVG',
        export_svg_warning: 'Ten projekt zawiera obiekty chronione prawem własności intelektualnej. Dlatego Beam Studio automatycznie wykluczy te obiekty podczas eksportu. Nadal możesz zapisać swój projekt w Beam Studio Scene (format .beam), aby zachować wszystkie dane. Czy nadal chcesz eksportować?',
        learn_more: 'Dowiedz się więcej',
    },
    change_logs: {
        change_log: 'Dzienniki zmian:',
        added: 'Dodane:',
        fixed: 'Naprawione:',
        changed: 'Zmienione:',
        see_older_version: 'Zobacz starsze wersje',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Wybierz urządzenie',
        auth_failure: '##811 Uwierzytelnianie nie powiodło się',
        unable_to_connect: '#810 Nie można zbudować stabilnego połączenia z maszyną',
    },
    device: {
        pause: 'Wstrzymaj',
        paused: 'Wstrzymany',
        pausing: 'Wstrzymywanie',
        select_printer: 'Wybierz drukarkę',
        retry: 'Spróbuj ponownie',
        status: 'Status',
        busy: 'Zajęty',
        ready: 'Gotowy',
        reset: 'Resetuj',
        abort: 'Przerwij',
        start: 'Rozpocznij',
        please_wait: 'Proszę czekać...',
        quit: 'Wyjdź',
        completing: 'Zakańczanie',
        aborted: 'Wstrzymany',
        completed: 'Zakończony',
        aborting: 'Przerywanie',
        starting: 'Rozpoczynanie',
        preparing: 'Przygotowywanie',
        resuming: 'Wznawianie',
        scanning: 'Skanowanie',
        occupied: 'Utrzymywanie',
        running: 'Pracuje',
        uploading: 'Przesyłanie',
        processing: 'Przetwarzanie',
        disconnectedError: {
            caption: 'Maszyna rozłączona',
            message: 'Potwierdź, czy dostęp do sieci %s jest dostępny',
        },
        unknown: 'Nieznany',
        pausedFromError: 'Wstrzymany z powodu błędu',
        model_name: 'Nazwa modelu',
        IP: 'IP',
        serial_number: 'Numer seryjny',
        firmware_version: 'Wersja oprogramowania',
        UUID: 'UUID',
        select: 'Wybierz',
        deviceList: 'Lista maszyn',
        disable: 'Wyłącz',
        enable: 'Włącz',
        submodule_type: 'Moduł',
        cartridge_serial_number: 'Numer seryjny wkładu atramentowego',
        ink_color: 'Kolor tuszu',
        ink_type: 'Typ tuszu',
        ink_level: 'Poziom atramentu',
        close_door_to_read_cartridge_info: 'Aby uzyskać dostęp do informacji o tuszu, proszę zamknąć pokrywę drzwi maszyny.',
        cartridge_info_read_failed: 'Upewnij się, że tusz jest w pełni włożony. Spróbuj wyjąć tusz i zainstaluj go ponownie.',
        cartridge_info_verification_failed: 'Proszę sprawdzić, czy tusz jest autentyczny FLUX.',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Rozpocznij',
        resume: 'Wznawiać',
        start: 'Rozpocznij',
        pause: 'Wstrzymaj',
        stop: 'Zatrzymaj',
        record: 'Nagraj',
        camera: 'Kamera',
        taskTab: 'Zadanie',
        connecting: 'Trwa łączenie, proszę czekać...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Błąd krytyczny: płyta główna odłączona. Skontaktuj się z pomocą techniczną FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Błąd krytyczny: Brak odpowiedzi podsystemu. Skontaktuj się z pomocą techniczną FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Sprawdź w swoim zbiorniku na wodę.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Zamknij drzwi, aby kontynuować.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Przegrzanie. Poczekaj kilka minut.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Dno otwarte. Zamknij dno, aby kontynuować.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Resetowanie osi Z nie powiodło się',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Szuflada otwarta',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Nieprawidłowość czujnika płomienia',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Nieprawidłowość czujnika przepływu powietrza',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nie wykryto modułu obrotowego',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Błąd odrywania od pozycji bazowej',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Wykryto nieprawidłowy moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Nie wykryto modułu. Aby kontynuować, upewnij się, że moduł jest poprawnie zainstalowany.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Wykryto nieznany moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Moduł drukarki brak reakcji.',
        USER_OPERATION_ROTARY_PAUSE: 'Proszę przełączyć się na silnik obrotowy.',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Aby kontynuować, wyjmij wkład.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Wykryto nieprawidłowy moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Nie wykryto modułu. Aby kontynuować, upewnij się, że moduł jest poprawnie zainstalowany.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Wykryto nieznany moduł. Aby kontynuować, zainstaluj odpowiedni moduł.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Aby kontynuować, zainstaluj odpowiedni moduł.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Aby kontynuować, włóż kartridż.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Aby kontynuować, włóż właściwy kartridż.',
        USER_OPERATION: 'Aby kontynuować, postępuj zgodnie z instrukcjami na panelu urządzenia.',
        RESOURCE_BUSY: 'Maszyna jest zajęta\nJeśli nie działa, uruchom ponownie maszynę',
        DEVICE_ERROR: 'Coś poszło nie tak\nProszę ponownie uruchomić komputer',
        NO_RESPONSE: '#905 Błąd podczas łączenia z płytą główną. Proszę ponownie uruchomić maszynę.',
        SUBSYSTEM_ERROR: '#402 Błąd krytyczny: Brak odpowiedzi podsystemu. Skontaktuj się z pomocą techniczną FLUX.',
        HARDWARE_FAILURE: 'Coś poszło nie tak\nProszę ponownie uruchomić komputer',
        MAINBOARD_OFFLINE: '#905 Błąd podczas łączenia z płytą główną.\nPlease restart the machine. ',
        bug_report: 'Zgłoszenie błędu',
        processing: 'Przetwarzanie',
        savingPreview: 'Generowanie miniatur',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'Pozostało',
        temperature: 'Temperatura',
        forceStop: 'Czy chcesz przerwać bieżące zadanie?',
        upload: 'Wgraj',
        download: 'Pobierz',
        relocate: 'Przenieś',
        cancel: 'Anuluj',
        prepareRelocate: 'Przygotowanie do przeniesienia',
        extensionNotSupported: 'Ten format pliku nie jest obsługiwany',
        fileExistContinue: 'Plik już istnieje, czy chcesz go zastąpić?',
        confirmFileDelete: 'Czy na pewno chcesz usunąć ten plik?',
        ask_reconnect: 'Połączenie z maszyną zostało zerwane. Czy chcesz ponownie się połączyć?',
        task: {
            BEAMBOX: 'Grawerowanie laserowe',
            'N/A': 'Tryb wolny',
        },
    },
    alert: {
        caption: 'Błąd',
        duplicated_preset_name: 'Zduplikowana nazwa ustawienia wstępnego',
        info: 'INFORMACJA',
        warning: 'OSTRZEŻENIE',
        error: 'UH-OH',
        instruction: 'Instrukcja',
        oops: 'Ups...',
        retry: 'Spróbuj ponownie',
        abort: 'Przerwij',
        confirm: 'Potwierdź',
        cancel: 'Anuluj',
        close: 'Zamknij',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Tak',
        no: 'Nie',
        stop: 'Zatrzymaj',
        save: 'Zapisz',
        dont_save: 'Nie zapisuj',
        learn_more: 'Dowiedz się więcej',
    },
    caption: {
        connectionTimeout: 'Czas połączenia minął',
    },
    message: {
        cancelling: 'Anulowanie...',
        connecting: 'Łączenie...',
        connectingMachine: 'Łączenie %s...',
        tryingToConenctMachine: 'Próba połączenia z maszyną...',
        connected: 'Połączony',
        authenticating: 'Uwierzytelnianie...',
        enteringRawMode: 'Wejście w tryb startowy...',
        endingRawMode: 'Wychodzenie z trybu startowego...',
        enteringLineCheckMode: 'Wejście w tryb sprawdzania linii...',
        endingLineCheckMode: 'Wychodzenie z trybu sprawdzania linii...',
        exitingRotaryMode: 'Wychodzenie z trybu obrotowego...',
        turningOffFan: 'Wyłączanie wentylatora...',
        turningOffAirPump: 'Wyłączanie pompy powietrza...',
        gettingLaserSpeed: 'Odczytywanie prędkości głowicy lasera...',
        settingLaserSpeed: 'Ustawianie prędkości głowicy laserowej...',
        retrievingCameraOffset: 'Odczyt przesunięcia kamery...',
        connectingCamera: 'Łączenie z kamerą...',
        homing: 'Pozycjonowanie...',
        connectionTimeout: '#805 Upłynął limit czasu połączenia urządzenia. Sprawdź stan sieci i wskaźnik Wi-Fi urządzenia.',
        getProbePosition: 'Pobieranie pozycji sondy...',
        device_not_found: {
            caption: 'Nie znaleziono domyślnej maszyny',
            message: '#812 Sprawdź wskaźnik Wi-Fi urządzenia',
        },
        device_busy: {
            caption: 'Maszyna zajęta',
            message: 'Urządzenie wykonuje inne zadanie, spróbuj ponownie później. Jeśli przestanie działać, uruchom ponownie urządzenie.',
        },
        device_is_used: 'Maszyna jest używana, czy chcesz przerwać bieżące zadanie?',
        monitor_too_old: {
            caption: 'Nieaktualne oprogramowanie układowe',
            content: '#814 Zainstaluj najnowsze oprogramowanie sprzętowe, korzystając z <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">tego przewodnika</a>.',
        },
        unknown_error: '#821 Aplikacja napotkała nieznany błąd, użyj opcji Pomoc > Menu > Raport o błędzie.',
        unknown_device: '#826 Nie można połączyć się z urządzeniem. Upewnij się, że urządzenie jest podłączone do portu USB',
        unsupport_osx_version: 'Bieżąca wersja systemu MacOS X %s może nie obsługiwać niektórych funkcji. Uprzejmie prosimy o aktualizację do najnowszej wersji.',
        unsupport_win_version: 'Bieżąca wersja systemu operacyjnego %s może nie obsługiwać niektórych funkcji. Uprzejmie prosimy o aktualizację do najnowszej wersji.',
        need_password: 'Potrzebujesz hasła, aby połączyć się z urządzeniem',
        unavailableWorkarea: '#804 Bieżący obszar roboczy przekracza obszar roboczy tego urządzenia. Sprawdź obszar roboczy wybranej maszyny lub ustaw obszar roboczy w Edycja > Ustawienia dokumentu.',
        please_enter_dpi: 'Wprowadź jednostkę swojego pliku (in mm)',
        auth_error: '#820 Błąd autoryzacji: Zaktualizuj oprogramowanie Beam Studio i urządzenia do najnowszej wersji.',
        usb_unplugged: 'Utracono połączenie USB. Sprawdź połączenie USB',
        uploading_fcode: 'Przesyłanie FCode',
        cant_connect_to_device: '#827 Nie można podłączyć urządzenia, sprawdź połączenie',
        unable_to_find_machine: 'Nie można znaleźć maszyny',
        disconnected: 'Połączenie niestabilne. Sprawdź połączenie urządzenia i spróbuj ponownie później',
        unable_to_start: '#830 Nie można rozpocząć zadania. Proszę spróbuj ponownie. Jeśli sytuacja się powtórzy, skontaktuj się z nami, wysyłając raport o błędzie:\n',
        camera: {
            camera_cable_unstable: 'Wykryto, że kamera niestabilnie przesyła zdjęcia. Podgląd można nadal wykonać, ale może wystąpić problem z powolnym podglądem lub przekroczeniem limitu czasu.',
            fail_to_transmit_image: '#845 Coś poszło nie tak z transmisją obrazu. Uruchom ponownie komputer i Beam Studio. Jeśli ten błąd będzie się powtarzał, postępuj zgodnie z <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">tym przewodnikiem</a>.',
            ws_closed_unexpectly: '#844 Połączenie z kamerą maszyny zostało niespodziewanie zamknięte. If this error persists, please follow <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">this guide</a>.',
            continue_preview: 'Kontynuuj',
            abort_preview: 'Przerwij',
        },
        preview: {
            camera_preview: 'Kamera Zapowiedź',
            auto_focus: 'Fokus Auto',
            auto_focus_instruction: 'Przesuń głowicę modułu laserowego nad obiekt i postępuj zgodnie z instrukcjami animacji, aby nacisnąć przycisk AF w celu ustawienia ostrości.',
            already_performed_auto_focus: 'Wykonałeś już autofokus, używasz istniejących wartości?',
            please_enter_height: 'Proszę podać wysokość obiektu. Aby uchwycić precyzyjne ujęcie kamery.',
            apply: 'Stosować',
            enter_manually: 'Wprowadzić ręcznie',
            adjust: 'Regulować',
            adjust_height_tooltip: 'Kliknij pole wyboru, aby włączyć edycję.',
        },
        unsupported_example_file: 'Wybrany przykładowy plik nie jest obsługiwany przez bieżący obszar roboczy.',
        time_remaining: 'Pozostały czas:',
    },
    machine_status: {
        '-17': 'Tryb I/O kasety',
        '-10': 'Tryb utrzymania',
        '-2': 'Skanowanie',
        '-1': 'Utrzymywanie',
        0: 'Wolny',
        1: 'Inicjowanie',
        2: 'ST_TRANSFORMUJ',
        4: 'Rozpoczynanie',
        6: 'Wznawianie',
        16: 'Pracuje',
        18: 'Wznawianie',
        32: 'Wstrzymany',
        36: 'Wstrzymany',
        38: 'Wstrzymywanie',
        48: 'Wstrzymany',
        50: 'Wstrzymywanie',
        64: 'Zakończony',
        66: 'Zakańczanie',
        68: 'Przygotowywanie',
        128: 'Wstrzymany',
        256: 'Alarm',
        512: 'Znaczący',
        UNKNOWN: 'Nieznany',
    },
    calibration: {
        update_firmware_msg1: 'Twoje oprogramowanie układowe nie obsługuje tej funkcji. Zaktualizuj oprogramowanie układowe do wersji v',
        update_firmware_msg2: 'lub nowszy, aby kontynuować。 (Menu > Maszyna > [Twoja maszyna] > Aktualizuj oprogramowanie sprzętowe)',
        camera_calibration: 'Kalibracja kamery',
        diode_calibration: 'Kalibracja modułu lasera diodowego',
        module_calibration_printer: 'Kalibracja modułu drukarki',
        module_calibration_2w_ir: 'Kalibracja modułu podczerwieni',
        back: 'WRÓĆ',
        next: 'DALEJ',
        skip: 'POMIŃ',
        cancel: 'ANULUJ',
        finish: 'GOTOWE',
        do_engraving: 'WYKONAJ GRAWEROWANIE',
        start_engrave: 'ROZPOCZNIJ GRAWEROWAĆ',
        start_printing: 'ROZPOCZNIJ DRUKOWANIE',
        ask_for_readjust: 'Czy chcesz pominąć krok grawerowania, a następnie zrobić zdjęcie i skalibrować bezpośrednio?',
        please_goto_beambox_first: 'Aby skorzystać z tej funkcji, przełącz się na tryb grawerowania (Beambox).',
        please_place_paper: 'Proszę umieścić białą kartkę A4 w lewym górnym rogu obszaru roboczego.',
        please_place_paper_center: 'Proszę umieścić białą kartkę A4 w lewym górnym rogu obszaru roboczego',
        please_place_dark_colored_paper: 'Proszę umieścić ciemny papier formatu A4 lub Letter na środku obszaru roboczego.',
        please_refocus: {
            beambox: 'Dostosuj wysokość soczewki do punktu ogniskowej na powierzchni kartki',
            beamo: 'Dostosuj głowicę lasera, aby skupić się na grawerowanym obiekcie',
            beamo_af: 'Dotknij dwukrotnie bocznego przycisku dodatku autofokusa, aby sonda delikatnie dotknęła materiału.',
            hexa: 'Kliknij dwukrotnie przycisk regulacji wysokości, aby podnieść stół o strukturze plastra miodu i sprawić, by sonda dotknęła materiału do grawerowania.',
        },
        without_af: 'Bez autofokusa',
        with_af: 'Z autofokusem',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Obrotu',
        x_ratio: 'Współczynnik X',
        y_ratio: 'Współczynnik Y',
        show_last_config: 'Pokaż ostatni wynik',
        use_last_config: 'Użyj wartości ostatniej kalibracji',
        taking_picture: 'Robienie zdjęcia...',
        analyze_result_fail: 'Nie udało się przeanalizować przechwyconego obrazu.<br/>Upewnij się:<br/>1. Zrobione zdjęcie w całości pokryte białym papierem.<br/>2. Platforma jest odpowiednio skupiona.',
        drawing_calibration_image: 'Tworzenie obrazu kalibracji...',
        calibrate_done: 'Kalibracja wykonana. Lepsza dokładność aparatu jest zapewniona przy precyzyjnym ustawianiu ostrości.',
        calibrate_done_diode: 'Kalibracja wykonana. Przesunięcie modułu lasera diodowego zostało zapisane.',
        hint_red_square: 'Wyrównaj zewnętrzną stronę czerwonego kwadratu z wyciętym kwadratem.',
        hint_adjust_parameters: 'Użyj tych parametrów, aby dostosować czerwony kwadrat',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Podczas kalibracji modułu lasera diodowego potrzebna jest kamera.\nUpewnij się, że kamera tego urządzenia została skalibrowana.\nI uprzejmie dostosuj platformę do punktu ogniskowego',
            beamo: 'Do kalibracji modułu lasera diodowego potrzebna jest kamera.\nUpewnij się, że kamera tego urządzenia została skalibrowana.\nI uprzejmie dostosuj głowicę lasera, do punktu ogniskowego',
        },
        downloading_pictures: 'Pobieranie zdjęć...',
        failed_to_download_pictures: '#848 Nie udało się pobrać zdjęć. Skontaktuj się z pomocą techniczną FLUX.',
        uploading_images: 'Przesyłanie obrazów...',
        calculating_camera_matrix: 'Obliczanie macierzy kamery...',
        calculating_regression_parameters: 'Obliczanie parametrów regresji...',
        failed_to_calibrate_camera: '#848 Nie udało się skalibrować kamery. Skontaktuj się z pomocą techniczną FLUX.',
        failed_to_save_calibration_results: '#849 Nie udało się zapisać wyników kalibracji. Spróbuj ponownie. Jeśli to się będzie powtarzać, skontaktuj się z pomocą techniczną FLUX.',
        ador_autofocus_material: 'Naciśnij ikonę „AF” na stronie głównej maszyny na 3 sekundy i pozwól, aby sonda delikatnie dotykała materiału.',
        ador_autofocus_focusing_block: 'Naciśnij ikonę „AF” na stronie głównej maszyny na 3 sekundy i pozwól sondzie dotknąć bloku ogniskującego.',
        align_red_cross_cut: 'Proszę wyrównać środek czerwonego krzyża z wyciętym krzyżem.',
        align_red_cross_print: 'Proszę wyrównać środek czerwonego krzyża z nadrukowanym krzyżykiem.',
        retake: 'Powtórz zdjęcie',
        calibrate_camera_before_calibrate_modules: 'Przed kalibracją modułów należy przeprowadzić kalibrację kamery.',
        check_checkpoint_data: 'Dane punktu kontrolnego',
        checking_checkpoint: 'Sprawdzanie danych punktu kontrolnego...',
        found_checkpoint: 'Dane punktu kontrolnego znalezione na twoim urządzeniu. Czy chcesz przywrócić z punktu kontrolnego?',
        use_old_camera_parameter: 'Czy chcesz użyć bieżących parametrów obiektywu aparatu?',
        downloading_checkpoint: 'Pobieranie danych punktu kontrolnego...',
        failed_to_parse_checkpoint: 'Nie udało się przeanalizować danych punktu kontrolnego.',
        check_device_pictures: 'Sprawdź zdjęcia urządzenia',
        checking_pictures: 'Sprawdzanie zdjęć urządzenia...',
        no_picutre_found: '#846 Twoje urządzenie nie ma dostępnych zdjęć w formacie raw do kalibracji. Skontaktuj się z obsługą FLUX.',
        calibrating_with_device_pictures: 'Kalibracja za pomocą zdjęć urządzenia...',
        failed_to_calibrate_with_pictures: '#847 Nie udało się skalibrować za pomocą zdjęć urządzenia.',
        getting_plane_height: 'Pobieranie Wysokości Płaszczyzny...',
        preparing_to_take_picture: 'Przygotowanie do zrobienia zdjęcia...',
        put_paper: 'Połóż Papier',
        put_paper_step1: 'Proszę umieścić biały papier w formacie A4 lub Letter w centrum obszaru roboczego.',
        put_paper_step2: 'Zamocuj cztery rogi papieru, aby upewnić się, że leży płasko.',
        put_paper_step3: 'Kliknij "ROZPOCZNIJ GRAWEROWAĆ".',
        solve_pnp_title: 'Wyrównaj punkty znacznikowe',
        solve_pnp_step1: 'Proszę wyrównać punkty grawerowania zgodnie z numerem i oczekiwaną pozycją każdego czerwonego punktu oznaczenia.',
        solve_pnp_step2: 'Możesz nacisnąć przycisk "Powtórz zdjęcie" w celu ponownego wyrównania lub ręcznej regulacji pozycji znaczników.',
        align_olt: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po lewej górnej stronie.',
        align_ort: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po prawej górnej stronie.',
        align_olb: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po lewej dolnej stronie.',
        align_orb: 'Pozycja: Wyrównaj do punktu wyrytego na zewnątrz po prawej dolnej stronie.',
        align_ilt: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej lewej górnej stronie.',
        align_irt: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej prawej górnej stronie.',
        align_ilb: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej lewej dolnej stronie.',
        align_irb: 'Pozycja: Wyrównaj do punktu wyrytego na wewnętrznej prawej dolnej stronie.',
        elevate_and_cut: 'Podnieś i Wytnij',
        elevate_and_cut_step_1: 'Umieść jasno kolorowany kawałek drewna o rozmiarze A4 na środku powierzchni roboczej, unieś go na wysokość 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Użyj Ador Prism Lift z jego maksymalną długością 14 mm wraz z drewnem o grubości co najmniej 6 mm, aby podnieść drewno do wysokości 20 mm.',
        with_prism_lift: 'Z Ador Prism Lift',
        without_prism_lift: 'Bez Ador Prism Lift',
        camera_parameter_saved_successfully: 'Parametry kamery zapisane pomyślnie.',
        failed_to_save_camera_parameter: 'Nie udało się zapisać parametrów kamery.',
        failed_to_solve_pnp: 'Nie udało się rozwiązać pozycji kamery.',
    },
    input_machine_password: {
        require_password: '"%s" wymaga hasła',
        connect: 'POŁĄCZ',
        password: 'Hasło',
    },
    tutorial: {
        skip: 'Pomiń',
        welcome: 'WITAM',
        suggest_calibrate_camera_first: 'Zalecamy użytkownikom skalibrowanie kamery początkowo i ponowne skupienie przed każdym podglądem dla optymalnych wyników.<br/>Czy chcesz potwierdzić przeprowadzenie kalibracji teraz?<br/>(Możesz to teraz pominąć i wykonać później, klikając "Menu" > "Maszyna" > [Twoja Maszyna] > "Skalibruj Kamerę".)',
        camera_calibration_failed: 'Kalibracja aparatu nie powiodła się',
        ask_retry_calibration: 'Czy chcesz ponownie skalibrować aparat?',
        needNewUserTutorial: 'Czy chcesz rozpocząć samouczek?<br/>(Możesz to teraz pominąć i rozpocząć później, klikając "Pomoc" > "Pokaż Samouczek Startowy".)',
        needNewInterfaceTutorial: 'Czy chcesz rozpocząć samouczek dla nowego interfejsu Beam Studio?<br/>(Możesz to teraz pominąć i rozpocząć później, klikając "Pomoc" > "Pokaż Wprowadzenie do Interfejsu".)',
        next: 'DALEJ',
        look_for_machine: 'Wyszukiwanie samouczków...',
        unable_to_find_machine: 'Nie można znaleźć maszyny do samouczka. Czy chcesz przejść do strony ustawień połączenia, spróbować ponownie czy pominąć samouczek?',
        skip_tutorial: 'Pominąłeś samouczek. Zawsze możesz uruchomić samouczek, klikając „Pomoc” > „Pokaż samouczek startowy”',
        set_connection: 'Ustaw połączenie',
        retry: 'Spróbuj ponownie',
        newUser: {
            draw_a_circle: 'Narysuj okrąg',
            drag_to_draw: 'Przeciągnij, aby narysować',
            infill: 'Włącz wypełnienie',
            switch_to_object_panel: 'Przełącz do panelu obiektów',
            switch_to_layer_panel: 'Przejdź do panelu warstw',
            set_preset_wood_engraving: 'Ustaw ustawienie wstępne: Drewno — grawerowanie',
            set_preset_wood_cut: 'Ustaw ustawienie wstępne: Drewno — cięcie',
            add_new_layer: 'Dodaj nową warstwę',
            draw_a_rect: 'Narysuj prostokąt',
            switch_to_preview_mode: 'Przełącz do trybu podglądu',
            preview_the_platform: 'Podgląd platformy',
            end_preview_mode: 'Zakończ tryb podglądu',
            put_wood: '1. Umieść próbkę drewna',
            adjust_focus: '2. Ustaw focus',
            close_cover: '3. Zamknij pokrywę',
            send_the_file: 'Wyślij plik',
            end_alert: 'Czy na pewno chcesz zakończyć samouczek?',
            please_select_wood_engraving: 'Prosimy wybrać ustawienie wstępne „Drewno — grawerowanie”.',
            please_select_wood_cutting: 'Wybierz ustawienie wstępne „Drewno — cięcie”.',
        },
        newInterface: {
            camera_preview: 'Podgląd kamery',
            select_image_text: 'Wybierz / Obraz / Tekst',
            basic_shapes: 'Podstawowe kształty',
            pen_tool: 'Narzędzie Pióro',
            add_new_layer: 'Dodaj nową warstwę',
            rename_by_double_click: 'Zmień nazwę, klikając dwukrotnie',
            drag_to_sort: 'Przeciągnij, aby posortować',
            layer_controls: 'Kliknij prawym przyciskiem myszy, aby wybrać opcje warstw:nDuplicate / Merge / Lock / Delete Layers',
            switch_between_layer_panel_and_object_panel: 'Przełączanie między panelem warstw a panelem obiektów',
            align_controls: 'Opcje wyrównania obiektów',
            group_controls: 'Sterowanie grupowe',
            shape_operation: 'Operacja kształtu',
            flip: 'Odbicie',
            object_actions: 'Akcje obiektów',
            end_alert: 'Czy na pewno zakończyć wprowadzanie do nowego interfejsu użytkownika?',
            select_machine: 'Wybierz maszynę',
            start_work: 'Zacząć pracę',
        },
        gesture: {
            pan: 'Przewiń płótno dwoma palcami.',
            zoom: 'Ściśnij dwoma palcami, aby powiększyć/pomniejszyć płótno.',
            click: 'Stuknij, aby wybrać obiekt.',
            drag: 'Przeciągnij, aby wybrać wiele obiektów.',
            hold: 'Naciśnij i przytrzymaj, aby otworzyć menu kontekstowe.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'To wszystko w samouczku. Teraz czas na tworzenie!',
    },
    layer_module: {
        none: 'brak',
        general_laser: 'Laser',
        laser_10w_diode: '10W Dioda Laser',
        laser_20w_diode: '20W Dioda Laser',
        laser_2w_infrared: '2W Podczerwień Laser',
        printing: 'Druk',
        unknown: 'Moduł nieznany',
        notification: {
            convertFromPrintingModuleTitle: 'Czy chcesz przekonwertować moduł drukowania na moduł lasera?',
            convertFromPrintingModuleMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia kolorów warstwy druku zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
            convertFromLaserModuleTitle: 'Czy chcesz przekonwertować moduł lasera na moduł drukowania?',
            convertFromLaserModuleMsg: 'Należy pamiętać, że po wykonaniu tej operacji ustawienia warstwy lasera zostaną usunięte i ustawione zgodnie z bieżącą warstwą.',
            importedDocumentContainsPrinting: 'Dokument zawiera warstwę do druku, czy chcesz zmienić obszar roboczy na Ador?',
            printingLayersCoverted: 'Warstwy drukarskie zostały zamienione na warstwy laserowe.',
            performPrintingCaliTitle: 'Wykonaj kalibrację modułu drukującego',
            performPrintingCaliMsg: 'Kliknij „Potwierdź”, aby wykonać kalibrację lub przejdź do kalibracji z górnego menu.( Urządzenie > [Nazwa Twojego urządzenia] > Kalibruj moduł drukujący)',
            performIRCaliTitle: 'Wykonaj kalibrację modułu podczerwieni',
            performIRCaliMsg: 'Kliknij „Potwierdź”, aby wykonać kalibrację lub przejdź do kalibracji z górnego menu. ( Urządzenie > [Nazwa Twojego urządzenia] > Kalibruj moduł podczerwieni)',
        },
        non_working_area: 'Obszar Niepracujący',
    },
    qr_code_generator: {
        title: 'Generator kodów QR',
        placeholder: 'Wpisz łącze lub tekst',
        preview: 'Podgląd',
        error_tolerance: 'Tolerancja błędów',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Odwróć kolory tła i pierwszego planu',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Podstawowe pudełko',
        coming_soon: 'Wkrótce',
        workarea: 'Obszar roboczy',
        max_dimension_tooltip: 'Maksymalna szerokość/wysokość/głębokość ustawiona na %s.',
        volume: 'Objętość',
        outer: 'Zewnętrzny',
        inner: 'Wewnętrzny',
        width: 'Szerokość',
        height: 'Wysokość',
        depth: 'Głębokość',
        cover: 'Pokrywa',
        thickness: 'Grubość',
        add_option: 'Dodaj opcję',
        joints: 'Połączenie',
        finger: 'Palec',
        finger_warning: 'Długość wewnętrzna boku pudełka musi wynosić co najmniej 6 mm (0,24 cala), aby była kompatybilna z łączeniem palców.',
        tSlot: 'T-Slot',
        tSlot_warning: 'Długość boku pudełka musi wynosić co najmniej 30 mm (1,18 cala), aby była kompatybilna z łączeniem gniazd T.',
        edge: 'Krawędź',
        tCount: 'Liczba T',
        tCount_tooltip: 'Liczba gniazd T dotyczy krótszego boku; ilość na dłuższym boku jest obliczana na podstawie jego długości.',
        tDiameter: 'Średnica T',
        tLength: 'Długość T',
        continue_import: 'Kontynuuj import',
        customize: 'Dostosuj',
        merge: 'Scal',
        text_label: 'Etykieta',
        beam_radius: 'Kompensacja promienia wiązki',
        beam_radius_warning: 'Fjern kompensasjon for kuttebredde når kantene eller sammenføyningene på boksen er korte for å sikre riktig sammensetting',
        import: 'Importuj',
        cancel: 'Anuluj',
        reset: 'Resetuj',
        zoom: 'Powiększenie',
        control_tooltip: 'Lewy przycisk myszy aby obrócić\nPrzewiń aby przybliżyć\nPrawy przycisk myszy aby przesunąć',
        control_tooltip_touch: 'Przeciągnij aby obrócić\nZsuń palcami aby przybliżyć\nDwa palce aby przesunąć',
    },
    my_cloud: {
        title: 'Moja chmura',
        loading_file: 'Ładowanie...',
        no_file_title: 'Zapisz pliki w Mojej chmurze, aby rozpocząć.',
        no_file_subtitle: 'Przejdź do Menu > "Plik" > "Zapisz w chmurze"',
        file_limit: 'Bezpłatny plik',
        upgrade: 'Uaktualnij',
        sort: {
            most_recent: 'Najnowsze',
            oldest: 'Najstarsze',
            a_to_z: 'Nazwa: A - Z',
            z_to_a: 'Nazwa: Z - A',
        },
        action: {
            open: 'Otwórz',
            rename: 'Zmień nazwę',
            duplicate: 'Duplikuj',
            download: 'Pobierz',
            delete: 'Usuń',
            confirmFileDelete: 'Czy na pewno chcesz usunąć ten plik? Tej akcji nie można cofnąć.',
        },
        save_file: {
            choose_action: 'Zapisz plik:',
            save: 'Zapisz',
            save_new: 'Zapisz jako nowy plik',
            input_file_name: 'Wprowadź nazwę pliku:',
            invalid_char: 'Nieprawidłowe znaki:',
            storage_limit_exceeded: 'Twoja pamięć w chmurze osiągnęła górny limit. Usuń niepotrzebne pliki przed zapisaniem nowych.',
        },
    },
    camera_data_backup: {
        title: 'Kopiuj dane kamery',
        no_picture_found: 'Nie znaleziono obrazu w urządzeniu.',
        folder_not_exists: 'Wybrany folder nie istnieje.',
        incorrect_folder: 'Nie udało się przesłać danych kalibracji. Sprawdź, czy wybrany folder jest poprawny.',
        downloading_data: 'Pobieranie danych...',
        estimated_time_left: 'Szacowany pozostały czas:',
        uploading_data: 'Przesyłanie danych...',
        download_success: 'Pomyślnie pobrano dane z kamery.',
        upload_success: 'Pomyślnie przesłano dane z kamery.',
    },
    insecure_websocket: {
        extension_detected: 'Wykryto rozszerzenie Beam Studio Connect',
        extension_detected_description: 'Wykryliśmy, że zainstalowałeś rozszerzenie Beam Studio Connect. Kliknij "Potwierdź", aby przekierować do protokołu HTTPS, lub kliknij "Anuluj", aby kontynuować korzystanie z protokołu HTTP.',
        extension_not_deteced: 'Nie można wykryć rozszerzenia Beam Studio Connect',
        extension_not_deteced_description: "Aby używać HTTPS, kliknij 'Potwierdź', aby zainstalować rozszerzenie Beam Studio Connect. Po zainstalowaniu rozszerzenia odśwież stronę, aby je aktywować.<br/>W przeciwnym razie kliknij poniższy link, aby zobaczyć, jak używać HTTP w Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Zmierz obszar autofokusu',
        amount: 'Ilość',
        gap: 'Przerwa',
        rows: 'Rzędy',
        coloumns: 'Kolumny',
        row_gap: 'Odstęp między rzędami',
        column_gap: 'Odstęp między kolumnami',
        set_object_height: 'Ustaw wysokość obiektu',
        set_object_height_desc: 'Zmierz maksymalną grubość obiektu.',
        measure_area_help: 'Jak wybrać obszar autofokusa dla krzywej 3D?',
        reselect_area: 'Ponownie wybierz obszar',
        start_autofocus: 'Rozpocznij autofokus',
        starting_measurement: 'Rozpoczynanie pomiaru...',
        preview_3d_curve: 'Podgląd krzywej 3D',
        apply_arkwork: 'Zastosuj dzieło sztuki na krzywej 3D',
        apply_camera: 'Zastosuj obraz z kamery na krzywej 3D',
        click_to_select_point: 'Kliknij, aby wybrać lub odznaczyć punkty do ponownego pomiaru.',
        remeasure: 'Ponownie zmierz',
        remeasuring_points: 'Ponowne mierzenie punktów...',
    },
    pass_through: {
        title: 'Tryb Przejścia',
    },
};
export default lang;
