var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Modal, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import deviceMaster from 'helpers/device-master';
import getDevice from 'helpers/device/get-device';
import storage from 'implementations/storage';
import UnitInput from 'app/widgets/UnitInput';
import useI18n from 'helpers/useI18n';
import { getWorkarea } from 'app/constants/workarea-constants';
import { underlineInputTheme } from 'app/constants/antd-config';
import styles from './PreviewHeight.module.scss';
var Step;
(function (Step) {
    Step[Step["ASK_FOCUS"] = 0] = "ASK_FOCUS";
    Step[Step["ADJUST"] = 1] = "ADJUST";
})(Step || (Step = {}));
const getProbeHeight = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (!deviceMaster.currentDevice)
            yield getDevice();
        const device = deviceMaster.currentDevice;
        if (device.control.getMode() !== 'raw')
            deviceMaster.enterRawMode();
        const { didAf, z } = yield deviceMaster.rawGetProbePos();
        if (!didAf)
            return null;
        const { deep } = getWorkarea(device.info.model, 'ado1');
        return Math.round((deep - z) * 100) / 100;
    }
    catch (err) {
        console.log(err);
        return null;
    }
});
// TODO: add test
const PreviewHeight = ({ initValue, onOk, onClose }) => {
    const lang = useI18n();
    const hasInitValue = useMemo(() => typeof initValue === 'number', [initValue]);
    const [adjustChecked, setAdjustChecked] = useState(!hasInitValue);
    const [step, setStep] = useState(hasInitValue ? Step.ADJUST : Step.ASK_FOCUS);
    const [value, setValue] = useState(initValue);
    const unit = useMemo(() => (storage.get('default-units') === 'inches' ? 'in' : 'mm'), []);
    const isInch = useMemo(() => unit === 'in', [unit]);
    useEffect(() => {
        let effectEnded = false;
        let timeout;
        if (step === Step.ASK_FOCUS && (value === undefined || value === null)) {
            const checkHeight = () => __awaiter(void 0, void 0, void 0, function* () {
                const probeHeight = yield getProbeHeight();
                if (probeHeight !== null) {
                    setValue(probeHeight);
                }
                else if (!effectEnded) {
                    timeout = setTimeout(checkHeight, 1000);
                }
            });
            checkHeight();
        }
        return () => {
            effectEnded = true;
            clearTimeout(timeout);
        };
    }, [step, value]);
    if (step === Step.ASK_FOCUS) {
        return (React.createElement(Modal, { open: true, centered: true, closable: false, maskClosable: false, title: lang.message.preview.auto_focus, footer: [
                React.createElement(Button, { key: "cancel", onClick: () => {
                        onOk(null);
                        onClose();
                    } }, lang.alert.cancel),
                React.createElement(Button, { key: "enter_manually", onClick: () => {
                        setValue(0);
                        setStep(Step.ADJUST);
                    } }, lang.message.preview.enter_manually),
                React.createElement(Button, { type: "primary", key: "ok", disabled: value === undefined || value === null, onClick: () => {
                        onOk(value);
                        onClose();
                    } }, lang.message.preview.apply),
            ] },
            React.createElement("div", { className: styles.text }, lang.message.preview.auto_focus_instruction),
            React.createElement("video", { className: styles.video, autoPlay: true, loop: true, muted: true },
                React.createElement("source", { src: "video/ador-preview-af.webm", type: "video/webm" }),
                React.createElement("source", { src: "video/ador-preview-af.mp4", type: "video/mp4" }))));
    }
    return (React.createElement(Modal, { open: true, centered: true, closable: false, maskClosable: false, title: lang.message.preview.camera_preview, footer: [
            React.createElement(Button, { key: "cancel", onClick: () => {
                    onOk(null);
                    onClose();
                } }, lang.alert.cancel),
            React.createElement(Button, { type: "primary", key: "ok", onClick: () => {
                    onOk(value);
                    onClose();
                } }, lang.message.preview.apply),
        ] },
        React.createElement("div", { className: styles.text },
            hasInitValue
                ? lang.message.preview.already_performed_auto_focus
                : lang.message.preview.please_enter_height,
            hasInitValue && (React.createElement(Tooltip, { className: styles.tooltip, trigger: "hover", title: lang.message.preview.adjust_height_tooltip },
                React.createElement(QuestionCircleOutlined, null)))),
        React.createElement("div", { className: styles.inputs },
            React.createElement(UnitInput, { unit: unit, isInch: isInch, value: value, precision: isInch ? 3 : 2, step: isInch ? 0.254 : 0.1, disabled: !adjustChecked, onChange: (val) => setValue(val), theme: underlineInputTheme }),
            hasInitValue && (React.createElement(Checkbox, { checked: adjustChecked, onChange: (e) => setAdjustChecked(e.target.checked) }, lang.message.preview.adjust)))));
};
export default PreviewHeight;
