var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as React from 'react';
import Icon from '@ant-design/icons';
import { Button, Col, ConfigProvider, InputNumber, Modal, Row, Slider } from 'antd';
import ActionPanelIcons from 'app/icons/action-panel/ActionPanelIcons';
import calculateBase64 from 'helpers/image-edit-panel/calculate-base64';
import Constants from 'app/actions/beambox/constant';
import CurveControl from 'app/widgets/Curve-Control';
import history from 'app/svgedit/history/history';
import i18n from 'helpers/i18n';
import imageProcessor from 'implementations/imageProcessor';
import jimpHelper from 'helpers/jimp-helper';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import OpenCVWebSocket from 'helpers/api/open-cv';
import Progress from 'app/actions/progress-caller';
import SliderControl from 'app/widgets/Slider-Control';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { isMobile } from 'helpers/system-helper';
import styles from './Photo-Edit-Panel.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const opencvWS = new OpenCVWebSocket();
let LANG = i18n.lang.beambox.photo_edit_panel;
const updateLang = () => {
    LANG = i18n.lang.beambox.photo_edit_panel;
};
// TODO: refactor this component, seperate different function into different component
class PhotoEditPanel extends React.Component {
    constructor(props) {
        super(props);
        this.handleCancel = () => {
            const { displaySrc } = this.state;
            const { unmount } = this.props;
            URL.revokeObjectURL(displaySrc);
            ObjectPanelController.updateActiveKey(null);
            unmount();
        };
        this.generateImageData = () => __awaiter(this, void 0, void 0, function* () {
            const { displaySrc, displayBase64 } = this.state;
            Progress.openNonstopProgress({
                id: 'photo-edit-processing',
                message: LANG.processing,
            });
            const result = yield this.calculateImageData(displaySrc);
            Progress.popById('photo-edit-processing');
            if (displayBase64) {
                URL.revokeObjectURL(displayBase64);
            }
            this.setState({
                displayBase64: result,
                isImageDataGenerated: true,
            });
        });
        updateLang();
        const { element, src } = this.props;
        this.state = {
            origSrc: src,
            previewSrc: src,
            displaySrc: src,
            sharpness: 0,
            sharpRadius: 1,
            threshold: parseInt(element.getAttribute('data-threshold'), 10),
            shading: (element.getAttribute('data-shading') === 'true'),
            isFullColor: element.getAttribute('data-fullcolor') === '1',
            brightness: 0,
            contrast: 0,
            displayBase64: null,
            isImageDataGenerated: false,
            isShowingOriginal: false,
        };
    }
    componentDidMount() {
        const { mode, unmount } = this.props;
        if (!['sharpen', 'curve'].includes(mode)) {
            unmount();
            return;
        }
        this.handlePreprocess();
    }
    componentDidUpdate() {
        const { isImageDataGenerated } = this.state;
        if (!isImageDataGenerated) {
            this.generateImageData();
        }
    }
    handlePreprocess() {
        return __awaiter(this, void 0, void 0, function* () {
            const setCompareBase64 = (imgUrl) => __awaiter(this, void 0, void 0, function* () {
                const result = yield this.calculateImageData(imgUrl);
                this.compareBase64 = result;
            });
            Progress.openNonstopProgress({
                id: 'photo-edit-processing',
                message: LANG.processing,
            });
            const { origSrc } = this.state;
            let imgBlobUrl = origSrc;
            try {
                const image = yield jimpHelper.urlToImage(imgBlobUrl);
                const { width: origWidth, height: origHeight } = image.bitmap;
                if (Math.max(origWidth, origHeight) > 600) {
                    // eslint-disable-next-line no-console
                    console.log('Down Sampling');
                    if (origWidth >= origHeight) {
                        image.resize(600, imageProcessor.AUTO);
                    }
                    else {
                        image.resize(imageProcessor.AUTO, 600);
                    }
                    imgBlobUrl = yield jimpHelper.imageToUrl(image);
                }
                setCompareBase64(imgBlobUrl);
                this.setState({
                    origWidth,
                    origHeight,
                    imageWidth: image.bitmap.width,
                    imageHeight: image.bitmap.height,
                    previewSrc: imgBlobUrl,
                    displaySrc: imgBlobUrl,
                });
            }
            catch (err) {
                // eslint-disable-next-line no-console
                console.log(err);
            }
            finally {
                Progress.popById('photo-edit-processing');
            }
        });
    }
    handleComplete() {
        return __awaiter(this, void 0, void 0, function* () {
            const clearHistory = () => {
                const { previewSrc, origSrc } = this.state;
                if (previewSrc !== origSrc) {
                    URL.revokeObjectURL(previewSrc);
                }
            };
            Progress.openNonstopProgress({
                id: 'photo-edit-processing',
                message: LANG.processing,
            });
            const { displaySrc } = this.state;
            const { element, unmount } = this.props;
            const batchCmd = new history.BatchCommand('Photo edit');
            const handleSetAttribute = (attr, value) => {
                svgCanvas.undoMgr.beginUndoableChange(attr, [element]);
                element.setAttribute(attr, value);
                const cmd = svgCanvas.undoMgr.finishUndoableChange();
                if (!cmd.isEmpty()) {
                    batchCmd.addSubCommand(cmd);
                }
            };
            handleSetAttribute('origImage', displaySrc);
            clearHistory();
            const result = yield this.calculateImageData(displaySrc);
            handleSetAttribute('xlink:href', result);
            svgCanvas.undoMgr.addCommandToHistory(batchCmd);
            svgCanvas.selectOnly([element], true);
            unmount();
            Progress.popById('photo-edit-processing');
        });
    }
    handleSharp(isPreview) {
        return __awaiter(this, void 0, void 0, function* () {
            Progress.openNonstopProgress({
                id: 'photo-edit-processing',
                message: LANG.processing,
            });
            const { sharpness, displaySrc, previewSrc, origSrc, origWidth, imageWidth, } = this.state;
            let { sharpRadius } = this.state;
            sharpRadius = isPreview ? Math.ceil(sharpRadius * (imageWidth / origWidth)) : sharpRadius;
            const imgBlobUrl = isPreview ? previewSrc : origSrc;
            try {
                let newImgUrl = imgBlobUrl;
                if (sharpRadius * sharpness > 0) {
                    const blob = yield opencvWS.sharpen(imgBlobUrl, sharpness, sharpRadius);
                    newImgUrl = URL.createObjectURL(blob);
                }
                if (displaySrc !== previewSrc) {
                    URL.revokeObjectURL(displaySrc);
                }
                Progress.popById('photo-edit-processing');
                if (isPreview) {
                    this.setState({
                        displaySrc: newImgUrl,
                        isImageDataGenerated: false,
                    });
                }
                else {
                    this.setState({ displaySrc: newImgUrl }, () => this.handleComplete());
                }
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.log('Error when sharpening image', error);
                Progress.popById('photo-edit-processing');
            }
        });
    }
    handleCurve(isPreview) {
        return __awaiter(this, void 0, void 0, function* () {
            const { displaySrc, previewSrc, origSrc } = this.state;
            const curveMap = [...Array(256).keys()].map((e) => Math.round(this.curvefunction(e)));
            const imgBlobUrl = isPreview ? previewSrc : origSrc;
            Progress.openNonstopProgress({
                id: 'photo-edit-processing',
                message: LANG.processing,
            });
            const newImgUrl = yield jimpHelper.curveOperate(imgBlobUrl, curveMap);
            if (newImgUrl) {
                if (displaySrc !== previewSrc) {
                    URL.revokeObjectURL(displaySrc);
                }
                Progress.popById('photo-edit-processing');
                if (isPreview) {
                    this.setState({
                        displaySrc: newImgUrl,
                        isImageDataGenerated: false,
                    });
                }
                else {
                    this.setState({ displaySrc: newImgUrl }, () => this.handleComplete());
                }
            }
            else {
                Progress.popById('photo-edit-processing');
            }
        });
    }
    updateCurveFunction(curvefunction) {
        this.curvefunction = curvefunction;
    }
    calculateImageData(src) {
        return __awaiter(this, void 0, void 0, function* () {
            const { shading, threshold, isFullColor } = this.state;
            const resultBase64 = calculateBase64(src, shading, threshold, isFullColor);
            return resultBase64;
        });
    }
    renderPhotoEditeModal() {
        const { mode } = this.props;
        const { imageWidth, imageHeight, isShowingOriginal, displayBase64, } = this.state;
        let panelContent = null;
        let rightWidth = 60;
        let title = '';
        switch (mode) {
            case 'sharpen':
                panelContent = this.renderSharpenPanel();
                title = LANG.sharpen;
                rightWidth = 390;
                break;
            case 'curve':
                panelContent = this.renderCurvePanel();
                title = isMobile() ? LANG.brightness_and_contrast : LANG.curve;
                rightWidth = 390;
                break;
            default:
                break;
        }
        const maxAllowableWidth = window.innerWidth - rightWidth;
        const maxAllowableHeight = window.innerHeight - 2 * Constants.topBarHeightWithoutTitleBar - 180;
        const imgSizeStyle = (imageWidth / maxAllowableWidth > imageHeight / maxAllowableHeight)
            ? { width: maxAllowableWidth } : { height: maxAllowableHeight };
        const imgWidth = imgSizeStyle.width
            ? maxAllowableWidth
            : imgSizeStyle.height * (imageWidth / imageHeight);
        if (isMobile()) {
            const [previewButton, ...footerButtons] = this.renderPhotoEditFooter();
            return (React.createElement(ConfigProvider, { theme: {
                    components: {
                        Button: { borderRadius: 100 },
                        InputNumber: { borderRadius: 100 },
                    },
                } },
                React.createElement(Modal, { className: styles.modal, closeIcon: React.createElement(Icon, { className: styles['close-icon'], component: ActionPanelIcons.Delete }), footer: footerButtons, onCancel: () => this.handleCancel(), centered: true, open: true },
                    React.createElement("div", { className: styles.title }, title),
                    React.createElement("div", { className: styles['preview-btn'] }, previewButton),
                    React.createElement("div", { className: styles.preview },
                        React.createElement("img", { id: "original-image", src: isShowingOriginal ? this.compareBase64 : displayBase64 })),
                    panelContent)));
        }
        return (React.createElement(Modal, { open: true, centered: true, width: imgWidth + rightWidth, title: title, footer: this.renderPhotoEditFooter(), onCancel: this.handleCancel },
            React.createElement(Row, { gutter: 10 },
                React.createElement(Col, { flex: `1 1 ${imgSizeStyle.width}` },
                    React.createElement("img", { id: "original-image", style: imgSizeStyle, src: isShowingOriginal ? this.compareBase64 : displayBase64 })),
                React.createElement(Col, { flex: "1 1 260px" }, panelContent))));
    }
    renderSharpenPanel() {
        const { state } = this;
        const { sharpness, sharpRadius } = state;
        const setStateAndPreview = (key, value) => {
            if (state[key] === value) {
                return;
            }
            state[key] = value;
            this.setState(state, () => {
                this.handleSharp(true);
            });
        };
        return isMobile() ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.sharpness),
                React.createElement(InputNumber, { className: styles.input, type: "number", min: 0, max: 20, value: sharpness, onChange: (val) => this.setState(Object.assign(Object.assign({}, state), { sharpness: val })), onBlur: () => this.handleSharp(true), controls: false }),
                React.createElement(Slider, { className: styles.slider, min: 0, max: 20, value: sharpness, onChange: (val) => this.setState(Object.assign(Object.assign({}, state), { sharpness: val })), onAfterChange: () => this.handleSharp(true) })),
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.radius),
                React.createElement(InputNumber, { className: styles.input, type: "number", min: 0, max: 100, value: sharpRadius, onChange: (val) => this.setState(Object.assign(Object.assign({}, state), { sharpRadius: val })), onBlur: () => this.handleSharp(true), controls: false }),
                React.createElement(Slider, { className: styles.slider, min: 0, max: 100, value: sharpRadius, onChange: (val) => this.setState(Object.assign(Object.assign({}, state), { sharpRadius: val })), onAfterChange: () => this.handleSharp(true) })))) : (React.createElement("div", { className: "right-part" },
            React.createElement("div", { className: "scroll-bar-container sharpen" },
                React.createElement("div", { className: "sub-functions with-slider" },
                    React.createElement(SliderControl, { id: "sharpen-intensity", label: LANG.sharpness, min: 0, max: 20, step: 1, default: 0, onChange: (id, val) => setStateAndPreview('sharpness', parseFloat(val)), doOnlyOnMouseUp: true, doOnlyOnBlur: true }),
                    React.createElement(SliderControl, { id: "sharpen-radius", label: LANG.radius, min: 0, max: 100, step: 1, default: 1, onChange: (id, val) => setStateAndPreview('sharpRadius', parseInt(val, 10)), doOnlyOnMouseUp: true, doOnlyOnBlur: true })))));
    }
    renderCurvePanel() {
        const updateCurveFunction = (curvefunction) => this.updateCurveFunction(curvefunction);
        const handleCurve = () => this.handleCurve(true);
        const { brightness, contrast } = this.state;
        const onChange = (type, val) => {
            this.setState((state) => (Object.assign(Object.assign({}, state), { [type]: val })));
            const currentBrightness = type === 'brightness' ? val : brightness;
            const currentContrast = type === 'contrast' ? val : contrast;
            const a = currentContrast < 0 ? currentContrast / 200 + 1 : currentContrast / 50 + 1;
            updateCurveFunction((n) => Math.max(Math.min(a * (n - 127.5) + currentBrightness + 127.5, 255), 0));
        };
        return isMobile() ? (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.brightness),
                React.createElement(InputNumber, { className: styles.input, type: "number", min: -100, max: 100, value: brightness, precision: 0, onChange: (val) => onChange('brightness', val), onBlur: handleCurve, controls: false }),
                React.createElement(Slider, { className: styles.slider, min: -100, max: 100, step: 1, marks: { 0: '0' }, value: brightness, onChange: (val) => onChange('brightness', val), onAfterChange: handleCurve })),
            React.createElement("div", { className: styles.field },
                React.createElement("span", { className: styles.label }, LANG.contrast),
                React.createElement(InputNumber, { className: styles.input, type: "number", min: -100, max: 100, value: contrast, precision: 0, onChange: (val) => onChange('contrast', val), onBlur: handleCurve, controls: false }),
                React.createElement(Slider, { className: styles.slider, min: -100, max: 100, step: 1, marks: { 0: '0' }, value: contrast, onChange: (val) => onChange('contrast', val), onAfterChange: handleCurve })))) : (React.createElement("div", { style: { width: 260, height: 260 } },
            React.createElement(CurveControl, { updateCurveFunction: updateCurveFunction, updateImage: handleCurve })));
    }
    renderPhotoEditFooter() {
        const { mode } = this.props;
        const previewButton = (React.createElement(Button, { key: "preview", onTouchStart: () => this.setState({ isShowingOriginal: true }), onTouchEnd: () => this.setState({ isShowingOriginal: false }), onMouseDown: () => this.setState({ isShowingOriginal: true }), onMouseUp: () => this.setState({ isShowingOriginal: false }), onMouseLeave: () => this.setState({ isShowingOriginal: false }), type: "dashed" }, LANG.compare));
        const handleOk = () => __awaiter(this, void 0, void 0, function* () {
            if (mode === 'sharpen') {
                yield this.handleSharp(false);
            }
            else if (mode === 'curve') {
                yield this.handleCurve(false);
            }
            ObjectPanelController.updateActiveKey(null);
        });
        const cancelButton = (React.createElement(Button, { key: "cancel", onClick: this.handleCancel }, LANG.cancel));
        const okButton = (React.createElement(Button, { key: "ok", onClick: () => handleOk(), type: "primary" }, LANG.okay));
        return [previewButton, cancelButton, okButton];
    }
    render() {
        const { mode } = this.props;
        if (['sharpen', 'curve'].includes(mode)) {
            return this.renderPhotoEditeModal();
        }
        return null;
    }
}
export default PhotoEditPanel;
