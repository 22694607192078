// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-web-app-components-beambox-top-bar-TopBar-module__top-bar--Clrde{color:#fff;font-size:30px}.src-web-app-components-beambox-top-bar-TopBar-module__top-bar--Clrde .src-web-app-components-beambox-top-bar-TopBar-module__right--tTeXN{float:right;display:flex;align-items:center;padding:0 8px;gap:12px;height:40px;position:relative;z-index:1}.src-web-app-components-beambox-top-bar-TopBar-module__top-bar--Clrde .src-web-app-components-beambox-top-bar-TopBar-module__menu--Oh39C{font-size:10pt}.src-web-app-components-beambox-top-bar-TopBar-module__top-bar--Clrde.white{color:#000}", "",{"version":3,"sources":["webpack://./src/web/app/components/beambox/top-bar/TopBar.module.scss","webpack://./src/web/styles/_variables.scss"],"names":[],"mappings":"AAEA,sEACE,UAAA,CACA,cAAA,CAEA,0IACE,WAAA,CACA,YAAA,CACA,kBAAA,CACA,aAAA,CACA,QAAA,CACA,WCHW,CDIX,iBAAA,CACA,SAAA,CAGF,yIACE,cAAA,CAGF,4EACE,UAAA","sourcesContent":["@use 'styles/variables' as variables;\n\n.top-bar {\n  color: #ffffff;\n  font-size: 30px;\n\n  .right {\n    float: right;\n    display: flex;\n    align-items: center;\n    padding: 0 8px;\n    gap: 12px;\n    height: variables.$topBarHeight;\n    position: relative;\n    z-index: 1;\n  }\n\n  .menu{\n    font-size: 10pt;\n  }\n\n  &:global(.white) {\n    color: #000000;\n  }\n}\n","$print-default-font-color: #4A4A4A;\n$default-button-border-radius: 4px;\n$size: 200px;\n$big-z-index: 9999;\n$primaryColor: #888;\n$backgroundColor: #F0F0F0;\n$sidePanelWidth: 242px;\n$winSidePanelWidth: 258px;\n$panelBorderColor: #E0E0E0;\n$topBarHeight: 40px;\n\n$primary-blue: #1890ff;\n$secondary-gray: #7c7c7c;\n$s3: 'https://beam-studio-web.s3.ap-northeast-1.amazonaws.com';\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"top-bar": "src-web-app-components-beambox-top-bar-TopBar-module__top-bar--Clrde",
	"right": "src-web-app-components-beambox-top-bar-TopBar-module__right--tTeXN",
	"menu": "src-web-app-components-beambox-top-bar-TopBar-module__menu--Oh39C"
};
export default ___CSS_LOADER_EXPORT___;
