var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React from 'react';
import Alert from 'app/actions/alert-caller';
import AlertConstants from 'app/constants/alert-constants';
import checkSoftwareForAdor from 'helpers/check-software';
import CommonTools from 'app/components/beambox/top-bar/CommonTools';
import constant from 'app/actions/beambox/constant';
import Discover from 'helpers/api/discover';
import ElementTitle from 'app/components/beambox/top-bar/ElementTitle';
import FileName from 'app/components/beambox/top-bar/FileName';
import FnWrapper from 'app/actions/beambox/svgeditor-function-wrapper';
import FrameButton from 'app/components/beambox/top-bar/FrameButton';
import getDevice from 'helpers/device/get-device';
import GoButton from 'app/components/beambox/top-bar/GoButton';
import i18n from 'helpers/i18n';
import isWeb from 'helpers/is-web';
import Menu from 'app/components/beambox/top-bar/Menu';
import ObjectPanelController from 'app/views/beambox/Right-Panels/contexts/ObjectPanelController';
import PathPreviewButton from 'app/components/beambox/top-bar/PathPreviewButton';
import PreviewButton from 'app/components/beambox/top-bar/PreviewButton';
import PreviewModeController from 'app/actions/beambox/preview-mode-controller';
import SelectMachineButton from 'app/components/beambox/top-bar/SelectMachineButton';
import showResizeAlert from 'helpers/device/fit-device-workarea-alert';
import storage from 'implementations/storage';
import TopBarHints from 'app/components/beambox/top-bar/TopBarHints';
import tutorialConstants from 'app/constants/tutorial-constants';
import tutorialController from 'app/views/tutorials/tutorialController';
import UserAvatar from 'app/components/beambox/top-bar/UserAvatar';
import { CanvasContext, CanvasMode } from 'app/contexts/CanvasContext';
import { getSVGAsync } from 'helpers/svg-editor-helper';
import { SelectedElementContext } from 'app/contexts/SelectedElementContext';
import { TopBarHintsContextProvider } from 'app/contexts/TopBarHintsContext';
// TODO: move all styles from web to modules.scss
import styles from './TopBar.module.scss';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const { $ } = window;
const LANG = i18n.lang.topbar;
const isWhiteTopBar = window.os !== 'MacOS' && !isWeb();
export default class TopBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.isSettingUpPreviewMode = false;
        this.getDefaultDevice = (deviceList) => {
            const { selectedDevice, setSelectedDevice } = this.context;
            if (!selectedDevice && this.defaultDeviceSerial) {
                const defauldDevice = deviceList.find((device) => device.serial === this.defaultDeviceSerial);
                if (defauldDevice && !checkSoftwareForAdor(defauldDevice, false)) {
                    this.defaultDeviceSerial = null;
                }
                else {
                    setSelectedDevice(defauldDevice);
                }
            }
        };
        this.setupPreviewMode = (showModal = false) => __awaiter(this, void 0, void 0, function* () {
            if (this.isSettingUpPreviewMode)
                return;
            this.isSettingUpPreviewMode = true;
            const { device, isWorkareaMatched } = yield getDevice(showModal);
            if (!(yield PreviewModeController.checkDevice(device))) {
                this.isSettingUpPreviewMode = false;
                return;
            }
            if (!isWorkareaMatched && !(yield showResizeAlert(device))) {
                this.isSettingUpPreviewMode = false;
                return;
            }
            const { setMode, startPreviewCallback, setStartPreviewCallback, updateCanvasContext, } = this.context;
            const workarea = document.getElementById('workarea');
            // eslint-disable-next-line react-hooks/rules-of-hooks
            FnWrapper.useSelectTool();
            svgCanvas.clearSelection();
            $(workarea).css('cursor', 'wait');
            const onPreviewError = (errMessage) => {
                if (errMessage === 'Timeout has occurred') {
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_ERROR,
                        message: LANG.alerts.start_preview_timeout,
                    });
                }
                else {
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_ERROR,
                        message: `${LANG.alerts.fail_to_start_preview}<br/>${errMessage}`,
                    });
                }
                setMode(CanvasMode.Draw);
                $(workarea).css('cursor', 'auto');
            };
            try {
                yield PreviewModeController.start(device, onPreviewError);
                if (!PreviewModeController.isPreviewModeOn) {
                    $(workarea).css('cursor', 'auto');
                    this.isSettingUpPreviewMode = false;
                    return;
                }
                $(workarea).css('cursor', 'url(img/camera-cursor.svg), cell');
                if (constant.adorModels.includes(device.model)) {
                    PreviewModeController.previewFullWorkarea(() => {
                        updateCanvasContext();
                        if (tutorialController.getNextStepRequirement() === tutorialConstants.PREVIEW_PLATFORM) {
                            tutorialController.handleNextStep();
                        }
                    });
                }
                setMode(CanvasMode.Preview);
                if (startPreviewCallback) {
                    startPreviewCallback();
                    setStartPreviewCallback(null);
                }
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.error(error);
                if (error.message && error.message.startsWith('Camera WS')) {
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_ERROR,
                        message: `${LANG.alerts.fail_to_connect_with_camera}<br/>${error.message || ''}`,
                    });
                }
                else {
                    Alert.popUp({
                        type: AlertConstants.SHOW_POPUP_ERROR,
                        message: `${LANG.alerts.fail_to_start_preview}<br/>${error.message || ''}`,
                    });
                }
                // eslint-disable-next-line react-hooks/rules-of-hooks
                FnWrapper.useSelectTool();
            }
            finally {
                this.isSettingUpPreviewMode = false;
            }
        });
        this.defaultDeviceSerial = storage.get('selected-device');
        this.isSettingUpPreviewMode = false;
        this.state = {
            hasDiscoverdMachine: false,
        };
    }
    componentDidMount() {
        this.discover = Discover('top-bar', (deviceList) => {
            const { hasDiscoverdMachine } = this.state;
            const hasMachine = deviceList.some((device) => device.serial !== 'XXXXXXXXXX');
            if (hasMachine !== hasDiscoverdMachine) {
                this.setState({ hasDiscoverdMachine: hasMachine });
            }
            this.getDefaultDevice(deviceList);
        });
    }
    componentDidUpdate() {
        const { setShouldStartPreviewController, shouldStartPreviewController, setSetupPreviewMode } = this.context;
        setSetupPreviewMode(() => this.setupPreviewMode);
        if (shouldStartPreviewController) {
            this.setupPreviewMode();
            setShouldStartPreviewController(false);
        }
    }
    componentWillUnmount() {
        this.discover.removeListener('top-bar');
    }
    // eslint-disable-next-line class-methods-use-this
    renderHint() {
        return (React.createElement(TopBarHintsContextProvider, null,
            React.createElement(TopBarHints, null)));
    }
    // eslint-disable-next-line class-methods-use-this
    renderMenu() {
        if (isWeb()) {
            const { currentUser } = this.context;
            return (React.createElement("div", { className: classNames('top-bar-menu-container', styles.menu) },
                React.createElement(Menu, { email: currentUser === null || currentUser === void 0 ? void 0 : currentUser.email })));
        }
        return null;
    }
    render() {
        const { togglePathPreview } = this.context;
        const { hasDiscoverdMachine } = this.state;
        const { mode, hasUnsavedChange, currentUser } = this.context;
        return (React.createElement("div", { className: classNames('top-bar', styles['top-bar'], { white: isWhiteTopBar }), onClick: () => ObjectPanelController.updateActiveKey(null) },
            (window.os === 'Windows' && !!window.titlebar) || (React.createElement(FileName, { hasUnsavedChange: hasUnsavedChange })),
            React.createElement(UserAvatar, { user: currentUser }),
            React.createElement(PreviewButton, null),
            React.createElement("div", { className: styles.right },
                React.createElement(SelectMachineButton, null),
                React.createElement(FrameButton, null),
                React.createElement(PathPreviewButton, { isDeviceConnected: hasDiscoverdMachine, togglePathPreview: togglePathPreview }),
                React.createElement(GoButton, { hasText: isWhiteTopBar, hasDiscoverdMachine: hasDiscoverdMachine })),
            React.createElement(SelectedElementContext.Consumer, null, ({ selectedElement }) => React.createElement(ElementTitle, { selectedElem: selectedElement })),
            this.renderHint(),
            this.renderMenu(),
            React.createElement(CommonTools, { isWeb: isWeb(), hide: mode !== CanvasMode.Draw })));
    }
}
TopBar.contextType = CanvasContext;
