import React, { useContext, useMemo, useState } from 'react';
import { Col, ConfigProvider, Modal, Row } from 'antd';
import useI18n from 'helpers/useI18n';
import { DataType, writeDataLayer } from 'helpers/layer/layer-config-helper';
import { getLayerByName } from 'helpers/layer/layer-helper';
import { PrintingColors } from 'app/constants/color-constants';
import ConfigPanelContext from './ConfigPanelContext';
import ColorRatioBlock from './ColorRatioBlock';
// TODO: add test
const ColorRationModal = ({ fullColor, onClose }) => {
    const t = useI18n().beambox.right_panel.laser_panel;
    const { dispatch, selectedLayers, state } = useContext(ConfigPanelContext);
    const [draftValue, setDraftValue] = useState({
        cRatio: state.cRatio,
        mRatio: state.mRatio,
        yRatio: state.yRatio,
        kRatio: state.kRatio,
        printingStrength: state.printingStrength,
    });
    const handleSave = () => {
        const newState = Object.assign({}, state);
        const keys = fullColor ? ['cRatio', 'mRatio', 'yRatio', 'kRatio'] : ['printingStrength'];
        selectedLayers.forEach((layerName) => {
            const layer = getLayerByName(layerName);
            keys.forEach((key) => {
                if (state[key].value !== draftValue[key].value ||
                    state[key].hasMultiValue !== draftValue[key].hasMultiValue) {
                    writeDataLayer(layer, DataType[key], draftValue[key].value);
                    newState[key] = draftValue[key];
                }
            });
        });
        dispatch({ type: 'update', payload: newState });
        onClose();
    };
    const handleValueChange = (key, value) => {
        setDraftValue((cur) => (Object.assign(Object.assign({}, cur), { [key]: { value, hasMultiValue: false } })));
    };
    const colorLayer = useMemo(() => state.color.hasMultiValue
        ? undefined
        : {
            [PrintingColors.CYAN]: 'c',
            [PrintingColors.MAGENTA]: 'm',
            [PrintingColors.YELLOW]: 'y',
            [PrintingColors.BLACK]: 'k',
        }[state.color.value] || undefined, [state.color.hasMultiValue, state.color.value]);
    return (React.createElement(Modal, { centered: true, open: true, maskClosable: false, width: fullColor ? 600 : 300, onOk: handleSave, onCancel: onClose, cancelText: t.cancel, okText: t.save, title: t.color_adjustment },
        React.createElement(ConfigProvider, { theme: {
                token: {
                    colorPrimaryBorder: '#cecece',
                    colorPrimaryBorderHover: '#494949',
                    colorPrimary: '#494949',
                },
                components: {
                    InputNumber: {
                        activeShadow: 'none',
                        activeBorderColor: '#cecece',
                        hoverBorderColor: '#cecece',
                        controlWidth: 40,
                    },
                    Slider: {
                        handleColor: '#cecece',
                        handleActiveColor: '#494949',
                        dotActiveBorderColor: '#494949',
                        trackBg: 'transparent',
                        trackBgDisabled: 'transparent',
                        trackHoverBg: 'transparent',
                        railSize: 6,
                    },
                },
            } }, fullColor ? (React.createElement(React.Fragment, null,
            React.createElement(Row, { gutter: [10, 0] },
                React.createElement(Col, { span: 12 },
                    React.createElement(ColorRatioBlock, { color: "c", ratio: draftValue.cRatio.value, setRatio: (val) => handleValueChange('cRatio', val) })),
                React.createElement(Col, { span: 12 },
                    React.createElement(ColorRatioBlock, { color: "m", ratio: draftValue.mRatio.value, setRatio: (val) => handleValueChange('mRatio', val) })),
                React.createElement(Col, { span: 12 },
                    React.createElement(ColorRatioBlock, { color: "y", ratio: draftValue.yRatio.value, setRatio: (val) => handleValueChange('yRatio', val) })),
                React.createElement(Col, { span: 12 },
                    React.createElement(ColorRatioBlock, { color: "k", ratio: draftValue.kRatio.value, setRatio: (val) => handleValueChange('kRatio', val) }))))) : (React.createElement(ColorRatioBlock, { ratio: draftValue.printingStrength.value, setRatio: (val) => handleValueChange('printingStrength', val), color: colorLayer })))));
};
export default ColorRationModal;
