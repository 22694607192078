import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ColorPicker as AntdColorPicker, Button } from 'antd';
import colorConstants, { CMYK, objectsColorPresets } from 'app/constants/color-constants';
import isDev from 'helpers/is-dev';
import useI18n from 'helpers/useI18n';
import styles from './ColorPicker.module.scss';
const ColorPicker = ({ allowClear, initColor, triggerType = 'fill', triggerSize = 'middle', onChange, disabled = false, printerColor = false, }) => {
    const [color, setColor] = useState(initColor);
    const [open, setOpen] = useState(false);
    const lang = useI18n().alert;
    useEffect(() => {
        setColor(initColor);
    }, [initColor]);
    const panelRender = (panel) => {
        let colorPresets = objectsColorPresets;
        if (printerColor) {
            colorPresets = isDev() ? colorConstants.printingLayerColor : CMYK;
        }
        return (React.createElement("div", { onClick: (e) => e.stopPropagation() },
            React.createElement("div", { className: styles.preset },
                allowClear && (React.createElement("div", null,
                    React.createElement("div", { className: classNames(styles['preset-block'], styles.clear, {
                            [styles.checked]: color === 'none',
                        }), onClick: () => setColor('none') }))),
                colorPresets.map((preset) => (React.createElement("div", { key: preset, className: classNames(styles['preset-block'], styles.color, {
                        [styles.checked]: preset === color,
                        [styles.printing]: printerColor,
                    }), onClick: () => setColor(preset) },
                    React.createElement("div", { className: styles.inner, style: { backgroundColor: preset } }))))),
            !printerColor && (React.createElement("div", { className: classNames(styles.panel, { [styles.clear]: color === 'none' }) }, panel)),
            React.createElement("div", { className: styles.footer },
                React.createElement(Button, { type: "primary", className: styles.btn, onClick: () => {
                        setOpen(false);
                        onChange(color);
                    } }, lang.ok),
                React.createElement(Button, { type: "default", className: styles.btn, onClick: () => {
                        setOpen(false);
                        setColor(initColor);
                    } }, lang.cancel))));
    };
    return (React.createElement("div", null,
        React.createElement(AntdColorPicker, { rootClassName: classNames({ [styles['no-panel']]: printerColor }), placement: "bottomLeft", disabledAlpha: true, disabled: disabled, open: open, onOpenChange: (o) => setOpen(o), value: color === 'none' ? '#000000' : color, onChangeComplete: (c) => setColor(c.toHexString()), panelRender: panelRender },
            React.createElement("div", { className: classNames(styles.trigger, {
                    [styles.open]: open,
                    [styles.small]: triggerSize === 'small',
                }), onClick: (e) => e.stopPropagation() },
                React.createElement("div", { className: classNames(styles.color, { [styles.clear]: initColor === 'none' }), style: { background: initColor } }, triggerType === 'stroke' && (React.createElement("div", { className: classNames(styles['stroke-inner'], {
                        [styles.clear]: initColor === 'none',
                    }) })))))));
};
export default ColorPicker;
