/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import { Button, Modal } from 'antd';
import useDidUpdateEffect from 'helpers/hooks/useDidUpdateEffect';
import styles from './Instruction.module.scss';
const Instruction = ({ animationSrcs, title, text, steps, buttons, onClose, }) => {
    const videoRef = useRef(null);
    useDidUpdateEffect(() => {
        var _a;
        (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.load();
    }, [animationSrcs]);
    return (React.createElement(Modal, { width: 400, open: true, centered: true, maskClosable: false, title: title, className: styles.container, closable: !!onClose, onCancel: () => onClose === null || onClose === void 0 ? void 0 : onClose(false), footer: buttons.map(({ label, type, onClick }) => (React.createElement(Button, { key: label, type: type, onClick: onClick }, label))) },
        text,
        steps && (React.createElement("ol", { className: styles.steps }, steps.map((step, i) => React.createElement("li", { key: i }, step)))),
        React.createElement("video", { className: styles.video, ref: videoRef, autoPlay: true, loop: true, muted: true }, animationSrcs.map(({ src, type }) => (React.createElement("source", { key: src, src: src, type: type }))))));
};
export default Instruction;
