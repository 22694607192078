var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Form, Input, Space } from 'antd';
import alert from 'app/actions/alert-caller';
import browser from 'implementations/browser';
import dialogCaller from 'app/actions/dialog-caller';
import isFluxPlusActive from 'helpers/is-flux-plus-active';
import storage from 'implementations/storage';
import useI18n from 'helpers/useI18n';
import { externalLinkFBSignIn, externalLinkGoogleSignIn, fluxIDEvents, signIn, signOut, } from 'helpers/api/flux-id';
import { useIsMobile } from 'helpers/system-helper';
import FluxPlusModal from './FluxPlusModal';
import styles from './FluxIdLogin.module.scss';
const FluxIdLogin = ({ silent, onClose }) => {
    const lang = useI18n().flux_id_login;
    const emailInput = useRef(null);
    const passwordInput = useRef(null);
    const rememberMeCheckbox = useRef(null);
    const [isRememberMeChecked, setIsRememberMeChecked] = useState(!!storage.get('keep-flux-id-login'));
    const isMobile = useIsMobile();
    useEffect(() => {
        const checkbox = rememberMeCheckbox.current;
        fluxIDEvents.on('oauth-logged-in', onClose);
        return () => {
            fluxIDEvents.removeListener('oauth-logged-in', onClose);
            const isChecked = checkbox.checked;
            storage.set('keep-flux-id-login', isChecked);
        };
    });
    const renderOAuthContent = () => (React.createElement("div", { className: styles.oauth },
        React.createElement("div", { className: classNames(styles.button, styles.facebook), onClick: externalLinkFBSignIn }, "Continue with Facebook"),
        React.createElement("div", { className: classNames(styles.button, styles.google), onClick: externalLinkGoogleSignIn }, "Continue with Google")));
    const handleLogin = () => __awaiter(void 0, void 0, void 0, function* () {
        const email = emailInput.current.input.value;
        const password = passwordInput.current.input.value;
        const rememberMe = rememberMeCheckbox.current.checked;
        yield signOut();
        const res = yield signIn({
            email,
            password,
            expires_session: !rememberMe,
        });
        if (res.error) {
            return;
        }
        if (res.status === 'error') {
            if (res.info === 'USER_NOT_FOUND') {
                alert.popUpError({ message: lang.incorrect });
            }
            else if (res.info === 'NOT_VERIFIED') {
                alert.popUpError({ message: lang.not_verified });
            }
            else {
                alert.popUpError({ message: res.message });
            }
            return;
        }
        if (res.status === 'ok') {
            // eslint-disable-next-line no-console
            console.log('Log in succeeded', res);
            onClose();
            if (!silent) {
                dialogCaller.showFluxCreditDialog();
            }
        }
    });
    return (React.createElement(FluxPlusModal, { className: styles['flux-login'], onClose: onClose, hideMobileBanner: true },
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.title }, lang.login),
            renderOAuthContent(),
            React.createElement(Divider, null, "or"),
            React.createElement(Form, { className: styles['login-inputs'] },
                React.createElement(Form.Item, { name: "email-input" },
                    React.createElement(Input, { ref: emailInput, onKeyDown: (e) => e.stopPropagation(), placeholder: lang.email })),
                React.createElement(Form.Item, { name: "password-input" },
                    React.createElement(Input.Password, { ref: passwordInput, onKeyDown: (e) => e.stopPropagation(), placeholder: lang.password })),
                React.createElement("div", { className: styles.options },
                    React.createElement("div", { className: styles['remember-me'], onClick: () => setIsRememberMeChecked(!isRememberMeChecked) },
                        React.createElement("input", { ref: rememberMeCheckbox, type: "checkbox", checked: isRememberMeChecked, onChange: () => { } }),
                        React.createElement("div", null, lang.remember_me)),
                    React.createElement("div", { className: styles['forget-password'], onClick: () => browser.open(lang.lost_password_url) }, lang.forget_password))),
            React.createElement(Space, { className: styles.footer, direction: "vertical" },
                React.createElement(Button, { block: true, type: "primary", onClick: handleLogin }, lang.login),
                React.createElement(Button, { block: true, type: "default", onClick: () => browser.open(lang.signup_url) }, lang.register),
                React.createElement("div", { className: styles.text },
                    React.createElement("div", { onClick: () => onClose() }, lang.work_offline),
                    isFluxPlusActive && isMobile && (React.createElement("div", { onClick: () => browser.open(lang.flux_plus.website_url) }, lang.flux_plus.explore_plans)))))));
};
export default FluxIdLogin;
