import { createContext } from 'react';
import i18n from 'helpers/i18n';
import isObjectEmpty from 'helpers/is-object-empty';
import storage from 'implementations/storage';
import { getDefaultPresetData } from 'helpers/presets/preset-helper';
export const getInitState = (selectingConfigName) => {
    const configs = storage.get('customizedLaserConfigs') || [];
    const presetsInUse = storage.get('defaultLaserConfigsInUse');
    const selectedConfig = configs.find((c) => c.name === selectingConfigName);
    const { speed, power, repeat, zStep } = selectedConfig || {};
    return {
        configs,
        selectedItem: { name: selectingConfigName, isCustomized: true },
        presetsInUse,
        dataChanges: {},
        displayValues: {
            speed: speed || 1,
            power: power || 0,
            repeat: repeat || 1,
            zStep: zStep || 0,
        },
    };
};
const selectAction = (state, payload) => {
    const { name, isCustomized } = payload;
    if (isCustomized) {
        const { configs, dataChanges } = state;
        const selectedConfig = configs.find((c) => c.name === name);
        const { speed, power, repeat, zStep } = Object.assign(Object.assign({}, selectedConfig), dataChanges[name]);
        return Object.assign(Object.assign({}, state), { selectedItem: { name, isCustomized }, displayValues: { speed, power, repeat, zStep } });
    }
    // selecting presets
    const { speed, power, repeat } = getDefaultPresetData(name);
    const zStep = 0;
    return Object.assign(Object.assign({}, state), { selectedItem: { name, isCustomized }, displayValues: { speed, power, repeat, zStep } });
};
const changeAction = (state, payload) => {
    const { name, key, value } = payload;
    const { configs, dataChanges, displayValues } = state;
    const selectedConfig = configs.find((c) => c.name === name);
    if (selectedConfig[key] !== value) {
        if (!dataChanges[name]) {
            const change = {};
            change[key] = value;
            dataChanges[name] = change;
        }
        else {
            dataChanges[name][key] = value;
        }
    }
    else {
        if (dataChanges[name]) {
            delete dataChanges[name][key];
        }
        if (isObjectEmpty(dataChanges[name])) {
            delete dataChanges[name];
        }
    }
    return Object.assign(Object.assign({}, state), { dataChanges, displayValues: Object.assign(Object.assign({}, displayValues), { [key]: value }) });
};
const addConfigAction = (state, payload) => {
    const { name } = payload;
    const configs = [...state.configs];
    configs.push({ name, speed: 20, power: 15, repeat: 1, zStep: 0 });
    return Object.assign(Object.assign({}, state), { configs, selectedItem: { name, isCustomized: true }, displayValues: { speed: 20, power: 15, repeat: 1, zStep: 0 } });
};
const swapConfigAction = (state, payload) => {
    const { orig, dist } = payload;
    const configs = [...state.configs];
    [configs[orig], configs[dist]] = [configs[dist], configs[orig]];
    return Object.assign(Object.assign({}, state), { configs });
};
const addPresetAction = (state) => {
    const { selectedItem: { name: key, isCustomized }, configs, presetsInUse } = state;
    if (!isCustomized && key !== '') {
        const unit = storage.get('default-units') || 'mm';
        const displayName = i18n.lang.beambox.right_panel.laser_panel.dropdown[unit][key];
        if (presetsInUse[key]) {
            return Object.assign(Object.assign({}, state), { selectedItem: { name: displayName, isCustomized: true } });
        }
        const { speed, power, repeat } = getDefaultPresetData(key);
        presetsInUse[key] = true;
        configs.push({ name: displayName, speed, power, repeat, zStep: 0, isDefault: true, key });
        return Object.assign(Object.assign({}, state), { selectedItem: { name: displayName, isCustomized: true }, configs,
            presetsInUse });
    }
    return state;
};
const removePresetAction = (state) => {
    const { selectedItem: { name, isCustomized }, configs, presetsInUse, dataChanges } = state;
    const unit = storage.get('default-units') || 'mm';
    const configName = isCustomized ? name : i18n.lang.beambox.right_panel.laser_panel.dropdown[unit][name];
    const index = configs.findIndex((c) => c.name === configName);
    if (index < 0 || !configs[index].isDefault)
        return state;
    const { key } = configs[index];
    presetsInUse[key] = false;
    configs.splice(index, 1);
    if (configs.length > 0) {
        // if custom config list is not empty, selecting config above
        const newIndex = Math.min(index, configs.length - 1);
        const newConfig = configs[newIndex];
        const { name: newConfgName, speed, power, repeat, zStep } = Object.assign(Object.assign({}, newConfig), dataChanges[newConfig.name]);
        return Object.assign(Object.assign({}, state), { configs,
            presetsInUse, selectedItem: { name: newConfgName, isCustomized: true }, displayValues: { speed, power, repeat: repeat || 1, zStep: zStep || 0 } });
    }
    // selecting remove preset in preset list
    return Object.assign(Object.assign({}, state), { configs,
        presetsInUse, selectedItem: { name, isCustomized: false } });
};
const deleteAction = (state) => {
    const { selectedItem: { name, isCustomized }, configs, dataChanges } = state;
    if (!isCustomized)
        return state;
    const idx = configs.findIndex((c) => c.name === name);
    if (idx < 0)
        return state;
    if (configs[idx].isDefault)
        return removePresetAction(state);
    configs.splice(idx, 1);
    if (configs.length > 0) {
        // if custom config list is not empty, selecting config above
        const newIndex = Math.min(idx, configs.length - 1);
        const newConfig = configs[newIndex];
        const { name: newConfgName, speed, power, repeat, zStep } = Object.assign(Object.assign({}, newConfig), dataChanges[newConfig.name]);
        return Object.assign(Object.assign({}, state), { configs, selectedItem: { name: newConfgName, isCustomized: true }, displayValues: { speed, power, repeat: repeat || 1, zStep: zStep || 0 } });
    }
    return Object.assign(Object.assign({}, state), { configs, selectedItem: { name: '', isCustomized: false }, displayValues: { speed: 1, power: 0, repeat: 1, zStep: 0 } });
};
const resetAction = (state) => {
    const configs = storage.get('customizedLaserConfigs') || [];
    const presetsInUse = storage.get('defaultLaserConfigsInUse') || {};
    return Object.assign(Object.assign({}, state), { configs, presetsInUse });
};
export const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'select':
            return selectAction(state, payload);
        case 'change':
            return changeAction(state, payload);
        case 'add-config':
            return addConfigAction(state, payload);
        case 'swap-config':
            return swapConfigAction(state, payload);
        case 'add-preset':
            return addPresetAction(state);
        case 'remove-preset':
            return removePresetAction(state);
        case 'delete':
            return deleteAction(state);
        case 'reset':
            return resetAction(state);
        default:
            break;
    }
    return state;
};
export default createContext({});
