/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Component } from 'react';
import cx from 'classnames';
import { showMenu, hideMenu } from './actions';
import { callIfExists, cssClasses } from './helpers';
export default class ContextMenuTrigger extends Component {
    constructor() {
        super(...arguments);
        this.touchHandled = false;
        this.handleMouseDown = (event) => {
            const { attributes, holdToDisplay } = this.props;
            if (holdToDisplay >= 0 && event.button === 0) {
                event.persist();
                event.stopPropagation();
                this.holdStartPosition = {
                    x: event.clientX,
                    y: event.clientY,
                };
                if (this.mouseDownTimeoutId)
                    clearTimeout(this.mouseDownTimeoutId);
                this.mouseDownTimeoutId = setTimeout(() => {
                    this.handleContextClick(event);
                }, holdToDisplay);
            }
            callIfExists(attributes.onMouseDown, event);
        };
        this.handleMouseMove = (event) => {
            if (this.mouseDownTimeoutId && this.holdStartPosition && event.button === 0) {
                const { holdThreshold } = this.props;
                const { x, y } = this.holdStartPosition;
                const { clientX, clientY } = event;
                if (Math.hypot(clientX - x, clientY - y) > holdThreshold) {
                    clearTimeout(this.mouseDownTimeoutId);
                }
            }
        };
        this.handleMouseUp = (event) => {
            const { attributes } = this.props;
            if (event.button === 0) {
                clearTimeout(this.mouseDownTimeoutId);
            }
            callIfExists(attributes.onMouseUp, event);
        };
        this.handleMouseOut = (event) => {
            const { attributes } = this.props;
            if (event.button === 0) {
                clearTimeout(this.mouseDownTimeoutId);
            }
            callIfExists(attributes.onMouseOut, event);
        };
        this.handleTouchstart = (event) => {
            const { attributes, holdToDisplay } = this.props;
            this.touchHandled = false;
            if (holdToDisplay >= 0 && event.touches.length > 0) {
                event.persist();
                this.holdStartPosition = {
                    x: event.touches[0].pageX,
                    y: event.touches[0].pageY,
                };
                if (this.touchstartTimeoutId)
                    clearTimeout(this.touchstartTimeoutId);
                this.touchstartTimeoutId = setTimeout(() => {
                    this.handleContextClick(event);
                    this.touchHandled = true;
                }, holdToDisplay);
            }
            callIfExists(attributes.onTouchStart, event);
        };
        this.handleTouchMove = (event) => {
            const { holdToDisplay, hideOnLeaveHoldPosition } = this.props;
            if (this.touchstartTimeoutId && this.holdStartPosition && event.touches.length > 0) {
                const { holdThreshold } = this.props;
                const { x, y } = this.holdStartPosition;
                const touch = event.touches[0];
                if (Math.hypot(touch.pageX - x, touch.pageY - y) > holdThreshold) {
                    clearTimeout(this.touchstartTimeoutId);
                }
            }
            if (holdToDisplay
                && hideOnLeaveHoldPosition
                && this.holdStartPosition
                && event.touches.length > 0) {
                const { holdThreshold } = this.props;
                const { x, y } = this.holdStartPosition;
                const touch = event.touches[0];
                if (Math.hypot(touch.pageX - x, touch.pageY - y) > holdThreshold) {
                    hideMenu();
                }
            }
        };
        this.handleTouchEnd = (event) => {
            const { attributes } = this.props;
            if (this.touchHandled) {
                event.preventDefault();
            }
            clearTimeout(this.touchstartTimeoutId);
            callIfExists(attributes.onTouchEnd, event);
        };
        this.handleContextMenu = (event) => {
            const { attributes } = this.props;
            this.handleContextClick(event);
            callIfExists(attributes.onContextMenu, event);
        };
        this.handleMouseClick = (event) => {
            const { attributes, mouseButton } = this.props;
            if (event.button === mouseButton) {
                this.handleContextClick(event);
            }
            callIfExists(attributes.onClick, event);
        };
        this.handleContextClick = (event) => {
            const { collect, disable, disableIfShiftIsPressed, posX, posY, id, } = this.props;
            if (disable)
                return;
            if (disableIfShiftIsPressed && event.shiftKey)
                return;
            event.preventDefault();
            event.stopPropagation();
            let x = event.clientX || (event.touches && event.touches[0].pageX);
            let y = event.clientY || (event.touches && event.touches[0].pageY);
            if (posX) {
                x -= posX;
            }
            if (posY) {
                y -= posY;
            }
            hideMenu();
            const data = callIfExists(collect, this.props);
            const showMenuConfig = {
                position: { x, y },
                target: this.elem,
                id,
            };
            if (data && (typeof data.then === 'function')) {
                // it's promise
                data.then((resp) => {
                    showMenuConfig.data = Object.assign(Object.assign({}, resp), { target: event.target });
                    showMenu(showMenuConfig);
                });
            }
            else {
                showMenuConfig.data = Object.assign(Object.assign({}, data), { target: event.target });
                showMenu(showMenuConfig);
            }
        };
        this.elemRef = (c) => {
            this.elem = c;
        };
    }
    render() {
        const { renderTag, attributes, children } = this.props;
        const newAttrs = Object.assign(Object.assign({}, attributes), { className: cx(cssClasses.menuWrapper, attributes.className), onContextMenu: this.handleContextMenu, onClick: this.handleMouseClick, onMouseDown: this.handleMouseDown, onMouseMove: this.handleMouseMove, onMouseUp: this.handleMouseUp, onTouchStart: this.handleTouchstart, onTouchMove: this.handleTouchMove, onTouchEnd: this.handleTouchEnd, onMouseOut: this.handleMouseOut, ref: this.elemRef });
        return React.createElement(renderTag, newAttrs, children);
    }
}
// eslint-disable-next-line react/static-property-placement
ContextMenuTrigger.defaultProps = {
    attributes: {},
    collect() { return null; },
    disable: false,
    holdToDisplay: 1000,
    holdThreshold: 10,
    renderTag: 'div',
    posX: 0,
    posY: 0,
    mouseButton: 2,
    disableIfShiftIsPressed: false,
};
