import { getSVGAsync } from 'helpers/svg-editor-helper';
import updateLayerColor from 'helpers/color/updateLayerColor';
import { getLayerByName } from './layer-helper';
let svgCanvas;
getSVGAsync((globalSVG) => {
    svgCanvas = globalSVG.Canvas;
});
const changeLayersColor = (layerNames, color) => {
    const layers = layerNames.map((layerName) => getLayerByName(layerName)).filter((layer) => layer);
    svgCanvas.undoMgr.beginUndoableChange('data-color', layers);
    layers.forEach((layer) => {
        layer.setAttribute('data-color', color);
        if (svgCanvas.isUsingLayerColor)
            updateLayerColor(layer);
    });
    const batchCmd = svgCanvas.undoMgr.finishUndoableChange();
    batchCmd.onAfter = () => {
        layers.forEach((layer) => {
            if (svgCanvas.isUsingLayerColor)
                updateLayerColor(layer);
        });
    };
    return batchCmd;
};
export default changeLayersColor;
