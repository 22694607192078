const lang = {
    general: {
        wait: 'Verarbeite .. Bitte warten',
        choose_folder: 'Ordner Auswählen',
    },
    buttons: {
        next: 'Nächster',
        back: 'Zurück',
        done: 'Anwenden',
        back_to_beam_studio: 'Zurück zu Beam Studio',
    },
    topbar: {
        untitled: 'Ohne Titel',
        titles: {
            settings: 'Einstellungen',
        },
        export: 'GEHEN',
        preview: 'VORSCHAU',
        preview_title: 'Vorschau',
        curve_engrave: '3D Kurve',
        task_preview: 'Pfadvorschau',
        frame_task: 'Frame-Vorschau',
        borderless: '(RANDLOS)',
        tag_names: {
            rect: 'Rechteck',
            ellipse: 'Oval',
            path: 'Pfad',
            polygon: 'Vieleck',
            image: 'Bild',
            text: 'Text',
            text_path: 'Pfadtext',
            line: 'Linie',
            g: 'Gruppe',
            multi_select: 'Mehrfachauswahl',
            use: 'Importierte Objekt',
            svg: 'SVG-Objekt',
            dxf: 'DXF-Objekt',
            no_selection: 'Keine Auswahl',
        },
        alerts: {
            start_preview_timeout: '#803 Beim Starten des Vorschaumodus ist eine Zeitüberschreitung aufgetreten. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">diese Anleitung</a>.',
            fail_to_start_preview: '#803 Fehler beim Starten des Vorschaumodus. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">diese Anleitung</a>.',
            fail_to_connect_with_camera: '#803 Verbindung zur Maschinenkamera konnte nicht hergestellt werden. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">diese Anleitung</a>.',
            add_content_first: 'Bitte fügen Sie zuerst Objekte hinzu',
            headtype_mismatch: 'Falsches Modul erkannt. ',
            headtype_none: 'Modul nicht erkannt. ',
            headtype_unknown: 'Unbekanntes Modul erkannt. ',
            install_correct_headtype: 'Bitte installieren Sie 10W/20W-Diodenlasermodule ordnungsgemäß, um Niedriglaser zum Ausführen des Rahmens zu aktivieren.',
            door_opened: 'Bitte schließen Sie die Tür abdeckung, um Niedriglaser zum Ausführen des Rahmens zu aktivieren.',
            fail_to_get_door_status: 'Bitte stellen Sie sicher, dass die Tür abdeckung geschlossen ist, um Niedriglaser zum Ausführen des Rahmens zu aktivieren.',
            QcleanScene: 'Möchtest du die Zeichnung löschen?<br/>Dies löscht auch deine Rückgängig-Historie!',
            power_too_high: 'Leistung zu hoch',
            power_too_high_msg: 'Die Verwendung einer niedrigeren Laserleistung (unter 70%) verlängert die Lebensdauer der Laserröhre!\nGeben Sie "OK" ein, um fortzufahren.',
            power_too_high_confirm: 'OK',
        },
        hint: {
            polygon: 'Press + / - key to increse / decrease sides.',
        },
        menu: {
            preferences: 'Einstellungen',
            hide: 'Beam Studio ausblenden',
            hideothers: 'Andere verstecken',
            service: 'Dienstleistungen',
            quit: 'Verlassen',
            window: 'Fenster',
            minimize: 'Minimieren',
            close: 'Fenster schließen',
            file: 'Datei',
            edit: 'Bearbeiten',
            help: 'Hilfe',
            open: 'Öffnen',
            samples: 'Beispiele',
            import_hello_beamo: 'Beispiel für Beamo',
            import_hello_beambox: 'Beispiel einer Beambox',
            import_material_testing_old: 'Material Gravurtest - Klassisch',
            import_material_testing_simple_cut: 'Material Schneidetest - Einfach',
            import_material_testing_cut: 'Material Schneidetest',
            import_material_testing_engrave: 'Material Gravurtest',
            import_material_testing_line: 'Material Linientest',
            import_material_printing_test: 'Material Drucktest',
            import_ador_laser_example: 'Beispiel für Ador Laser',
            import_ador_printing_example_single: 'Beispiel für Ador Druck - Einzelfarbe',
            import_ador_printing_example_full: 'Beispiel für Ador Druck - Vollfarbe',
            import_acrylic_focus_probe: 'Acrylische Fokus-Sonde - 3mm',
            export_to: 'Exportieren nach...',
            export_flux_task: 'FLUX-Datei',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Speichern',
            save_as: 'Speichern als...',
            save_to_cloud: 'In die Cloud speichern',
            about_beam_studio: 'Über Beam Studio',
            undo: 'Rückgängig machen',
            redo: 'Wiederholen',
            cut: 'Ausschneiden',
            copy: 'Kopieren',
            paste: 'Einfügen',
            paste_in_place: 'Bei Originalposition einfügen',
            group: 'Gruppe',
            ungroup: 'Gruppierung aufheben',
            delete: 'Löschen',
            duplicate: 'Duplizieren',
            offset: 'Offset',
            scale: 'Rahmen',
            rotate: 'Drehen',
            reset: 'Zurücksetzen',
            align_center: 'Zentrieren',
            photo_edit: 'Bild',
            svg_edit: 'SVG',
            path: 'Pfad',
            decompose_path: 'Zerlegen',
            object: 'Objekt',
            layer_setting: 'Ebene',
            layer_color_config: 'Farbeinstellungen',
            image_sharpen: 'Schärfen',
            image_crop: 'Zuschneiden',
            image_invert: 'Farbe umkehren',
            image_stamp: 'Fase',
            image_vectorize: 'Verfolgen',
            image_curve: 'Kurve',
            align_to_edges: 'An Scheitelpunkten ausrichten',
            document_setting: 'Dokumenteinstellungen',
            document_setting_short: 'Dokument',
            clear_scene: 'Neue Dateien',
            machines: 'Maschinen',
            add_new_machine: 'Maschineneinrichtung',
            help_center: 'Hilfezentrum',
            show_gesture_tutorial: 'Einleitung zur Gesten',
            show_start_tutorial: 'Erstmaliges Tutorial anzeigen',
            show_ui_intro: 'Einführung in die Benutzeroberfläche anzeigen',
            questionnaire: 'Feedback-Fragebogen',
            change_logs: 'Protokolle ändern',
            contact: 'Kontaktiere uns',
            tutorial: 'Starte das Delta Family Printing Tutorial',
            design_market: 'Design Market',
            forum: 'Gemeinschaftsforum',
            software_update: 'Software-Update',
            bug_report: 'Fehlerbericht',
            dashboard: 'Dashboard',
            machine_info: 'Maschineninfo',
            network_testing: 'Testen Sie die Netzwerkeinstellungen',
            commands: 'Befehle',
            update_firmware: 'Firmware aktualisieren',
            using_beam_studio_api: 'Verwenden Sie die Beam Studio API',
            set_as_default: 'Als Standard einstellen',
            calibrate_beambox_camera: 'Kamera kalibrieren',
            calibrate_printer_module: 'Druckmodul kalibrieren',
            calibrate_ir_module: 'Infrarotmodul kalibrieren',
            calibrate_beambox_camera_borderless: 'Kamera kalibrieren (randlos)',
            calibrate_diode_module: 'Diodenlasermodul kalibrieren',
            manage_account: 'Mein Konto verwalten',
            sign_in: 'Anmelden',
            sign_out: 'Ausloggen',
            account: 'Konto',
            my_account: 'Mein Konto',
            download_log: 'Protokolle herunterladen',
            download_log_canceled: 'Protokoll-Download abgebrochen',
            download_log_error: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut',
            keyboard_shortcuts: 'Tastenkombinationen',
            log: {
                network: 'Netzwerk',
                hardware: 'Hardware',
                discover: 'Entdecken',
                usb: 'USB',
                usblist: 'USB-Liste',
                camera: 'Kamera',
                cloud: 'Cloud',
                player: 'Spieler',
                robot: 'Roboter',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Ansicht',
            zoom_in: 'Zoom In',
            zoom_out: 'Zoom Out',
            fit_to_window: 'An Fenstergröße anpassen',
            zoom_with_window: 'Automatische Anpassung an Fenstergröße',
            borderless_mode: 'Randloser Modus',
            show_grids: 'Gitter anzeigen',
            show_rulers: 'Lineale anzeigen',
            show_layer_color: 'Ebenenfarbe verwenden',
            anti_aliasing: 'Suavizado',
            disassemble_use: 'Zerlegen',
        },
        select_machine: 'Wählen Sie eine Maschine aus',
    },
    support: {
        no_webgl: 'WebGL wird nicht unterstützt. Bitte verwenden Sie andere Geräte.',
        no_vcredist: 'Bitte installieren Sie Visual C ++ Redistributable 2015 <br/> Das kann auf flux3dp.com heruntergeladen werden',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Bitte starte Beam Studio neu',
        OPERATION_ERROR: '[OE] Ein Statuskonflikt ist aufgetreten. Bitte wiederholen Sie die Aktion.',
        SUBSYSTEM_ERROR: '[SE] Fehler beim Ausführen der Aufgabe durch die Maschinenfirmware. Bitte starte die Maschine neu.',
        UNKNOWN_COMMAND: '[UC] Bitte aktualisieren Sie die Geräte-Firmware',
    },
    device_selection: {
        no_beambox: '#801 Wir können Ihren Computer nicht im Netzwerk finden.\nBefolgen Sie bitte <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">die Anleitung</a>, um das Verbindungsproblem zu beheben.',
        no_device_web: "#801 Überprüfen Sie bitte den Status Ihrer Maschine oder klicken Sie unten auf 'Maschineneinrichtung', um die Maschine einzurichten.",
    },
    update: {
        update: 'Aktualisieren',
        release_note: 'Veröffentlichungshinweis:',
        firmware: {
            caption: 'Ein Firmware-Update für das Gerät ist verfügbar',
            message_pattern_1: '%s ist jetzt bereit für das Firmware-Update.',
            message_pattern_2: '%s Firmware v%s ist jetzt verfügbar - Du hast v%s.',
            latest_firmware: {
                caption: 'Maschinenfirmware-Update',
                message: 'Du hast die neueste Maschinenfirmware',
                still_update: 'AKTUALISIEREN',
                cant_get_latest: 'Informationen zur neuesten Firmware können nicht abgerufen werden.',
            },
            confirm: 'HOCHLADEN',
            upload_file: 'Firmware-Upload (* .bin / * .fxfw)',
            update_success: 'Firmware-Update erfolgreich hochgeladen',
            update_fail: '#822 Update fehlgeschlagen',
            too_old_for_web: '"Ihre Firmwarenversion ist zurzeit v%s.\nWenn Sie die Online-version von Beam Studio benutzen möchten, aktualisieren Sie bitte zur neusten Version."',
            force_update_message: '#814 Bitte aktualisieren Sie Ihre Maschine auf die neueste Firmware-Version.',
            firmware_too_old_update_by_sdcard: 'Die Firmware ist zu alt. Bitte aktualisieren Sie die Firmware mit einer SD-Karte.',
        },
        software: {
            checking: 'Suche nach Updates',
            switch_version: 'Version wechseln',
            check_update: 'Überprüfe auf Updates',
            caption: 'Ein Software-Update für Beam Studio ist verfügbar',
            downloading: 'Wenn Sie Updates im Hintergrund herunterladen, können Sie auf "OK" klicken, um Ihre Arbeit fortzusetzen.',
            install_or_not: 'ist bereit für das Update. Möchtest du jetzt neu starten und aktualisieren?',
            switch_or_not: 'ist schaltbereit. Möchtest du jetzt neu starten und wechseln?',
            available_update: 'Beam Studio v%s ist ab sofort verfügbar,Du hast v%s. Möchtest du das Update herunterladen?',
            available_switch: 'Beam Studio v%s ist ab sofort verfügbar,Du hast v%s. Möchtest du zu dieser Version wechseln?',
            not_found: 'Sie verwenden die neueste Version von Beam Studio.',
            no_response: 'Fehler beim Herstellen einer Verbindung zum Server. Überprüfe die Netzwerkeinstellungen.',
            switch_version_not_found: 'Wechselbare Version nicht gefunden.',
            yes: 'Ja',
            no: 'Nein',
            update_for_ador: 'Die aktuelle Softwareversion %s ist veraltet, bitte laden Sie die neueste Version von Beam Studio für Ador herunter.',
        },
        updating: 'Aktualisierung...',
        skip: 'Überspringe diese Version',
        preparing: 'Vorbereiten...',
        later: 'SPÄTER',
        download: 'ONLINE-UPDATE',
        cannot_reach_internet: '#823 Server ist nicht erreichbar <br/> Bitte überprüfe die Internetverbindung',
        install: 'INSTALLIEREN',
        upload: 'HOCHLADEN',
    },
    topmenu: {
        version: 'Ausführung',
        credit: 'Beam Studio ist dank <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a>Open-Sorce-Projekt sowie anderen <a target="_blank" href="https://flux3dp.com/credits/">Open-Source-Softwares</a> ermöglicht.',
        ok: 'OK',
        file: {
            label: 'Datei',
            import: 'Importieren',
            save_fcode: 'FLUX-Aufgabe exportieren',
            save_scene: 'Datei speichern',
            save_svg: 'SVG exportieren',
            save_png: 'PNG exportieren',
            save_jpg: 'JPG exportieren',
            converting: 'In Bild konvertieren ...',
            all_files: 'Alle Dateien',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam-Studio-Datei',
            fcode_files: 'FLUX-Code',
            clear_recent: 'Vor kurzem geöffnet löschen',
            path_not_exit: 'Dieser Pfad scheint auf dem Schreibtisch nicht mehr zu existieren.',
        },
        device: {
            download_log_canceled: 'Protokoll-Download abgebrochen',
            download_log_error: 'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut',
            log: {
                usblist: 'USB-Liste',
            },
            network_test: 'Netzwerk testen',
        },
    },
    initialize: {
        // generic strings
        next: 'Nächster',
        start: 'Starten',
        skip: 'Überspringen',
        cancel: 'Stornieren',
        confirm: 'Bestätigen',
        connect: 'Verbinden',
        back: 'Zurück',
        retry: 'Wiederholen',
        no_machine: 'Ich habe jetzt keine Maschine.',
        // specific caption/content
        select_language: 'Sprache auswählen',
        select_machine_type: 'Wählen Sie Ihr Modell',
        select_connection_type: 'Wie möchten Sie eine Verbindung herstellen?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Kabelgebundenes Netzwerk',
            ether2ether: 'Direkte Verbindung',
            usb: 'USB-Verbindung',
        },
        connect_wifi: {
            title: 'Verbindung zu Wi-Fi',
            tutorial1: '1. Gehen Sie zu Touch Panel > Klicken Sie auf "Netzwerk" > "Stellen Sie eine Verbindung zu WiFi her".',
            tutorial1_ador: '1. Gehe zu Touch Panel > Klicke auf "MASCHINE" > Klicke auf "Netzwerk" > "Mit Wi-Fi verbinden".',
            tutorial2: '2. Wählen Sie Ihr bevorzugtes WLAN aus und verbinden Sie es.',
            what_if_1: 'Was ist, wenn ich mein WLAN nicht sehe?',
            what_if_1_content: '1. Der Wi-Fi-Verschlüsselungsmodus sollte WPA2 oder kein Kennwort sein.\n 2. Der Verschlüsselungsmodus kann in der Administrationsoberfläche des WLAN-Routers eingestellt werden. Wenn der Router WPA2 nicht unterstützt und Sie Hilfe bei der Auswahl des richtigen Routers benötigen, wenden Sie sich an den FLUX-Support.',
            what_if_2: 'Was ist, wenn ich kein WLAN sehe??',
            what_if_2_content: '1. Stellen Sie sicher, dass der Wi-Fi-Dongle vollständig angeschlossen ist.\n 2. Wenn auf dem Touchscreen keine MAC-Adresse des drahtlosen Netzwerks vorhanden ist, wenden Sie sich an den FLUX-Support.\n3. Der Wi-Fi-Kanal sollte 2.4 GHz betragen (5 GHz werden nicht unterstützt).',
        },
        connect_wired: {
            title: 'Verbindung zum kabelgebundenen Netzwerk herstellen',
            tutorial1: '1. Verbinden Sie das Gerät mit Ihrem Router.',
            tutorial2: '2. Drücken Sie "Netzwerk", um die IP des kabelgebundenen Netzwerks abzurufen.',
            tutorial2_ador: '2. Drücken Sie "MASCHINE" > "Netzwerk", um die IP des kabelgebundenen Netzwerks abzurufen.',
            what_if_1: 'Was ist, wenn die IP leer ist?',
            what_if_1_content: '1. Stellen Sie sicher, dass das Ethernet-Kabel vollständig eingesteckt ist.\n2. Wenn auf dem Touchscreen keine MAC-Adresse des kabelgebundenen Netzwerks vorhanden ist, wenden Sie sich an den FLUX-Support.',
            what_if_2: 'Was ist, wenn die IP mit 169 beginnt??',
            what_if_2_content: '1. Wenn die IP-Adresse mit 169.254 beginnt, sollte es sich um ein Problem mit der DHCP-Einstellung handeln. Wenden Sie sich an Ihren ISP (Internetdienstanbieter), um weitere Unterstützung zu erhalten.\n2. Wenn Ihr Computer über PPPoE eine direkte Verbindung zum Internet herstellt, wechseln Sie zur Verwendung des Routers, um eine Verbindung über PPPoE herzustellen, und aktivieren Sie die DHCP-Funktion im Router.',
        },
        connect_ethernet: {
            title: 'Direkte Verbindung',
            tutorial1: '1. Verbinden Sie das Gerät mit einem Ethernet-Kabel mit Ihrem Computer.',
            tutorial2_1: '2. Befolgen ',
            tutorial2_a_text: 'Sie dieser Anleitung',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ', um Ihren Computer als Router zu verwenden',
            tutorial3: '3. Klicken Sie auf "Nächster"',
        },
        connect_usb: {
            title: 'USB-Verbindung',
            title_sub: ' (Nur für HEXA und Ador)',
            tutorial1: 'Verbinden Sie die Maschine mit Ihrem Computer über das USB-Kabel.',
            tutorial2: "Klicken Sie auf 'Nächster'.",
            turn_off_machine: 'Schalten Sie Ihre Maschine aus.',
            turn_on_machine: 'Schalten Sie die Maschine ein.',
            wait_for_turning_on: "Klicken Sie auf 'Nächster', nachdem der Startvorgang abgeschlossen ist und Sie den Hauptbildschirm betreten haben.",
        },
        connect_machine_ip: {
            check_usb: 'Überprüfen Sie die USB-Verbindung',
            enter_ip: 'Geben Sie die IP Ihres Computers ein',
            check_ip: 'Überprüfen der IP-Verfügbarkeit',
            invalid_ip: 'IP ungültig: ',
            invalid_format: 'Ungültiges Format',
            starts_with_169254: 'Beginnt mit 169.254',
            unreachable: 'IP nicht erreichbar',
            check_connection: 'Überprüfen der Maschinenverbindung',
            check_firmware: 'Firmware-Version überprüfen',
            check_camera: 'Überprüfung der Kameraverfügbarkeit',
            retry: 'Wiederholen',
            succeeded_message: 'Erfolgreich verbunden 🎉',
            finish_setting: 'Einstellung beenden',
        },
        connecting: 'Anschließen...',
        setting_completed: {
            start: 'Start',
            great: 'Willkommen bei Beam Studio',
            setup_later: 'Sie können Ihren Computer jederzeit über die Titelleiste> "Maschinen"> "Maschineneinrichtung" einrichten.',
            back: 'Zurück',
            ok: 'STARTE EIN PROJEKT',
        },
    },
    error_pages: {
        screen_size: 'Please note that Beam Studio may not work optimally on your device. For an optimal experience, it should be a device with a screen width of at least 1024 pixels width.',
    },
    menu: {
        mm: 'mm',
        inches: 'Zoll',
    },
    settings: {
        on: 'An',
        off: 'Aus',
        low: 'Niedrig',
        high: 'Normal',
        caption: 'die Einstellungen',
        tabs: {
            general: 'Allgemeines',
            device: 'Maschine',
        },
        ip: 'IP-Adresse des Geräts',
        guess_poke: 'Suchen Sie nach der IP-Adresse des Geräts',
        auto_connect: 'Wählen Sie automatisch die einzige Maschine aus',
        wrong_ip_format: 'Falsche IP-Formate',
        default_machine: 'Standardmaschine',
        default_machine_button: 'Leeren',
        remove_default_machine_button: 'Entfernen',
        confirm_remove_default: 'Die Standardmaschine wird entfernt.',
        reset: 'Beam Studio zurücksetzen',
        reset_now: 'Beam Studio zurücksetzen',
        confirm_reset: 'Bestätige das Zurücksetzen von Beam Studio?',
        language: 'Sprache',
        notifications: 'Desktop-Benachrichtigungen',
        check_updates: 'Auto-Check',
        autosave_enabled: 'Automatisch Speichern',
        autosave_path: 'Automatisch Speichern Speicherort',
        autosave_interval: 'Speichern Alle',
        autosave_number: 'Anzahl von Automatisch Speichern',
        autosave_path_not_correct: 'Der angegebene Pfad wurde nicht gefunden.',
        preview_movement_speed: 'Vorschau der Bewegungsgeschwindigkeit',
        preview_movement_speed_hl: 'Vorschau der Bewegungsgeschwindigkeit (Diodenlaser aktiviert)',
        default_units: 'Standardeinheiten',
        default_font_family: 'Standardschriftart',
        default_font_style: 'Standardschriftstil',
        fast_gradient: 'Geschwindigkeitsoptimierung',
        engraving_direction: 'Gravurrichtung',
        top_down: 'Von oben nach unten',
        bottom_up: 'Von unten nach oben',
        vector_speed_constraint: 'Geschwindigkeitsbegrenzung (20 mm / s)',
        loop_compensation: 'Schleifenkompensation',
        blade_radius: 'Klingenradius',
        blade_precut_switch: 'Klingenvorschnitt',
        blade_precut_position: 'Vorgeschnittene Position',
        default_beambox_model: 'Standarddokumenteinstellung',
        guides_origin: 'Referenzpunkt',
        guides: 'Anleitungen',
        image_downsampling: 'Bitmap-Vorschau der Qualität',
        anti_aliasing: 'Anti-Aliasing',
        continuous_drawing: 'Kontinuierliches Zeichnen',
        trace_output: 'Bildverfolgungsausgabe',
        single_object: 'Einzelobjekt',
        grouped_objects: 'Gruppierte Objekte',
        simplify_clipper_path: 'Optimieren Sie den berechneten Pfad',
        enable_low_speed: 'Langsame Bewegung aktivieren',
        enable_custom_backlash: 'Benutzerdefinierte Backlash-Kompensation aktivieren',
        custom_preview_height: 'Benutzerdefinierte Vorschauhöhe',
        mask: 'Arbeitsbereich-Ausschnitt',
        text_path_calc_optimization: 'Optimierung der Pfadberechnung',
        font_substitute: 'Ersetzen Sie nicht unterstützte Zeichen',
        font_convert: 'Text-zu-Pfad-Konverter',
        default_borderless_mode: 'Boden offen',
        default_enable_autofocus_module: 'Standardeinstellung für Autofokusmodul',
        default_enable_diode_module: 'Diodenlaser Standard',
        diode_offset: 'Diodenlaser Offset',
        autofocus_offset: 'Autofokus-Versatz',
        diode_one_way_engraving: 'Diodenlaser-Einweggravur',
        diode_two_way_warning: 'Die bidirektionale Lichtemission ist schneller und führt wahrscheinlich zu einer gewissen Ungenauigkeit der Lasergravurposition. Empfehlen Sie zuerst zu testen.',
        share_with_flux: 'Teilen Sie Beam Studio Analytics',
        none: 'Keiner',
        close: 'Schließen',
        enabled: 'Aktiviert',
        disabled: 'ausgeschaltet',
        cancel: 'Stornieren',
        done: 'Anwenden',
        module_offset_10w: '10W Diodenlaser-Versatz',
        module_offset_20w: '20W Diodenlaser-Versatz',
        module_offset_printer: 'Druckerversatz',
        module_offset_2w_ir: '2W Infrarotlaser-Versatz',
        printer_advanced_mode: 'Druckerfortgeschrittener Modus',
        default_laser_module: 'Standard-Lasermodul',
        low_laser_for_preview: 'Laser für Frame-Vorschau',
        groups: {
            general: 'Allgemeines',
            update: 'Software-Updates',
            connection: 'Verbindung',
            autosave: 'Automatisch Speichern',
            camera: 'Kamera',
            editor: 'Editor',
            engraving: 'Rasterung (Scannen)',
            path: 'Vektor (Umrisse)',
            mask: 'Arbeitsbereich-Ausschnitt',
            text_to_path: 'Text',
            modules: 'Zusatz-Module',
            ador_modules: 'Ador-Modul',
            privacy: 'Privatsphäre',
        },
        notification_on: 'An',
        notification_off: 'Aus',
        update_latest: 'Neueste',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Gruppe',
            use: 'SVG importieren',
            image: 'Bild',
            text: 'Text',
        },
        context_menu: {
            cut: 'Ausschneiden',
            copy: 'Kopieren',
            paste: 'Einfügen',
            paste_in_place: 'Bei Originalposition einfügen',
            duplicate: 'Duplizieren',
            delete: 'Löschen',
            group: 'Gruppieren',
            ungroup: 'Gruppierung aufheben',
            move_front: 'Nach ganz oben verschieben',
            move_up: 'Hochschieben',
            move_down: 'Herunterschieben',
            move_back: 'Nach ganz unten verschieben',
        },
        popup: {
            select_import_method: 'Wählen Sie den Layering-Stil:',
            select_import_module: 'Modul auswählen:',
            touchpad: 'TouchPad',
            mouse: 'TouchPad',
            layer_by_layer: 'Schicht',
            layer_by_color: 'Farbe',
            nolayer: 'Einzelne Schicht',
            loading_image: 'Bild laden, bitte warten...',
            no_support_text: 'Beam Studio unterstützt derzeit keine Text-Tags. Bitte übertrage Text vor dem Importieren in den Pfad.',
            speed_too_high_lower_the_quality: 'Die Verwendung einer zu hohen Geschwindigkeit bei dieser Auflösung kann zu einer geringeren Qualität der Gravur führen.',
            both_power_and_speed_too_high: 'Die Verwendung einer niedrigeren Laserleistung verlängert die Lebensdauer der Laserröhre!\nAußerdem kann eine zu hohe Geschwindigkeit bei dieser Auflösung zu einer schlechteren Qualität der Gravur führen.',
            too_fast_for_path: 'Die Verwendung einer zu hohen Geschwindigkeit in Ebenen mit Pfadobjekten, kann zu einer geringeren Präzision beim Schneiden führen.\nWir empfehlen, beim Schneiden keine Geschwindigkeit von mehr als 20 mm/s zu verwenden.',
            too_fast_for_path_and_constrain: 'Folgende Ebenen: %s\nenthalten Vektorpfadobjekte und eine Geschwindigkeit von mehr als 20 mm/s \nDie Schnittgeschwindigkeit von Vektorpfadobjekten wird auf 20 mm/s reduziert.\nDu kannst diese Beschränkung unter Präferenzen-Einstellungen entfernen.',
            should_update_firmware_to_continue: '#814 Deine Firmware unterstützt diese Version von Beam Studio nicht. Bitte aktualisiere die Firmware, um fortzufahren. (Menü > Maschine > [Ihre Maschine] > Firmware aktualisieren)',
            recommend_downgrade_software: 'Wir haben eine ältere Firmware-Version erkannt. Wir arbeiten aktiv an der Behebung von Kompatibilitätsproblemen, aber vorerst empfehlen wir, auf <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a> zurückzukehren.',
            recommend_upgrade_firmware: 'Wir haben eine ältere Firmware-Version erkannt. Wir arbeiten aktiv an der Behebung von Kompatibilitätsproblemen, aber im Moment empfehlen wir ein Update auf die neueste Firmware.',
            still_continue: 'Weiter',
            more_than_two_object: 'Zu viele Objekte. Es werden nur 2 Objekte unterstützt.',
            not_support_object_type: 'Objekttyp wird nicht unterstützt.',
            select_first: 'Wähle zunächst ein Objekt aus.',
            select_at_least_two: 'Wähle zwei Objekte zum Fortfahren aus.',
            import_file_contain_invalid_path: '#808 Importierte SVG-Datei enthält ungültigen Bildpfad. Bitte stelle sicher, dass alle Bilddateien existieren oder betten Sie das Bild in die Datei',
            import_file_error_ask_for_upload: 'Importierte SVG-Datei konnte nicht importiert werden. Bist du bereit, dem Entwicklerteam eine Datei für den Fehlerbericht zur Verfügung zu stellen ?',
            upload_file_too_large: '#819 Datei ist zu groß zum Hochladen.',
            successfully_uploaded: 'Datei-Upload erfolgreich.',
            upload_failed: '#819 Datei-Upload fehlgeschlagen.',
            or_turn_off_borderless_mode: ' Oder deaktivieren Sie den Open Bottom Modus.',
            svg_1_1_waring: 'Die Version dieser SVG-Datei ist Version 1.1. Möglicherweise treten Inkompatibilitätsprobleme auf.',
            svg_image_path_waring: 'Diese SVG-Datei enthält das Laden von <image> aus dem Dateipfad. Dies kann beim Laden zu Fehlern führen.\nUm dieses Risiko zu vermeiden, verwenden Sie beim Exportieren von SVG bitte das eingebettete Bild.',
            dxf_version_waring: 'Die Version dieser DXF-Datei ist nicht 2013, möglicherweise treten Inkompatibilitätsprobleme auf.',
            dont_show_again: 'Nicht mehr anzeigen',
            convert_to_path_fail: 'Konvertierung in Pfad fehlgeschlagen.',
            save_unsave_changed: 'Nicht gespeicherte Änderungen speichern?',
            dxf_bounding_box_size_over: 'Die Zeichnungsgröße ist außerhalb des Arbeitsbereichs. Bewege bitte deine Zeichnung näher an den Ursprung in Ihrer CAD-Software oder stelle sicher, dass das Gerät richtig eingestellt ist.',
            progress: {
                uploading: 'Hochladen',
                calculating: 'Berechnen',
            },
            backend_connect_failed_ask_to_upload: '#802 Beim Versuch, eine Verbindung zum Server herzustellen, treten immer wieder Fehler auf. Möchtest du Ihr Fehlerberichtsprotokoll hochladen?',
            backend_error_hint: 'Funktionen funktionieren möglicherweise aufgrund eines Backend-Fehlers nicht ordnungsgemäß.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Fehler beim Konvertieren von PDF in SVG:',
                error_pdf2svg_not_found: '#825 Fehler: Befehl pdf2svg nicht gefunden. Bitte installieren Sie pdf2svg mit Ihrem Paketmanager (z. B. "yum install pdf2svg" oder "apt-get install pdf2svg").',
            },
            ungroup_use: 'Dadurch wird die Gruppierung von importiertem DXF oder SVG aufgehoben. Da die Datei möglicherweise eine große Anzahl von Elementen enthält, kann das Aufheben der Gruppierung einige Zeit dauern. Sind Sie sicher, fortzufahren?',
            vectorize_shading_image: 'Das Vektorisieren von Schattierungsbildern dauert länger und ist anfällig für Rauschen. Bitte schalten Sie die Bildschattierung aus, bevor Sie sie ausführen.',
            change_workarea_before_preview: 'Der Arbeitsbereich von %s stimmt nicht mit dem aktuell festgelegten Arbeitsbereich überein. Möchten Sie den aktuellen Arbeitsbereich wechseln?',
            bug_report: 'Fehlerbericht',
            sentry: {
                title: 'Lassen Sie uns Beam Studio gemeinsam verbessern',
                message: 'Stimmen Sie zu, relevante Informationen automatisch in das Entwicklungsteam hochzuladen, wenn Fehler auftreten?',
            },
            questionnaire: {
                caption: 'Helfen Sie uns, den Fragebogen auszufüllen',
                message: 'Helfen Sie uns, den Fragebogen auszufüllen, um Beam Studio besser zu machen.',
                unable_to_get_url: 'Fehler beim Abrufen des Links zum Fragebogen über das Internet. Bitte bestätigen Sie Ihre Internetverbindung.',
                no_questionnaire_available: 'Derzeit ist kein Fragebogen verfügbar.',
            },
            facebook_group_invitation: {
                title: 'Der offiziellen FLUX Benutzergruppe beitreten',
                message: 'Treten Sie unserer offiziellen Facebook-Gruppe bei, um mit anderen FLUX Laseranwendern in Kontakt zu treten, über FLUX Laser zu diskutieren, Laserkunstwerke auszutauschen und über die neuesten Updates zu unseren Produkten auf dem Laufenden zu bleiben. Wir können es kaum erwarten, Sie dort zu sehen!',
                join_now: 'Jetzt beitreten',
                later: 'Vielleicht später',
                already_joined: 'Bereits angemeldet',
                dont_show_again: 'Nicht mehr anzeigen',
            },
            ai_credit: {
                relogin_to_use: 'Bitte erneut einloggen, um diese Funktion zu verwenden.',
                insufficient_credit: 'Du hast kein Guthaben mehr',
                insufficient_credit_msg: 'Sie können %s nicht verwenden. Gehen Sie zum Mitgliedercenter und kaufen Sie AI Credit.',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Gehen',
            },
            text_to_path: {
                caption: 'Text-zu-Pfad-Konverter 2.0',
                message: 'Beam Studio führt nun einen neuen Text-zu-Pfad-Konverter (Konverter 2.0) ein, der zuverlässigere Ergebnisse liefert! Möchten Sie jetzt umsteigen? \nSie können diese Einstellung auch später im Abschnitt "Text-zu-Pfad-Konverter" in den Einstellungen anpassen.',
            },
        },
        zoom_block: {
            fit_to_window: 'An Fenster anpassen',
        },
        time_est_button: {
            calculate: 'Zeit berechnen',
            estimate_time: 'Geschätzte benötigte Zeit:',
        },
        left_panel: {
            unpreviewable_area: 'Blinder Bereich',
            diode_blind_area: 'Hybrid Laser Add-On Blindbereich',
            borderless_blind_area: 'Nicht gravierender Bereich',
            borderless_preview: 'Kamera-Vorschau im randlosen Modus',
            rectangle: 'Rechteck',
            ellipse: 'Ellipse',
            line: 'Linie',
            image: 'Bild',
            text: 'Text',
            label: {
                cursor: 'Wählen',
                photo: 'Bild',
                text: 'Text',
                line: 'Linie',
                rect: 'Rechteck',
                oval: 'Oval',
                polygon: 'Vieleck',
                pen: 'Stift',
                shapes: 'Formen',
                array: 'Anordnung',
                preview: 'Kameravorschau',
                trace: 'Bild verfolgen',
                end_preview: 'Vorschau beenden',
                clear_preview: 'Vorschau löschen',
                choose_camera: 'Kamera',
                live_feed: 'Live-Übertragung',
                adjust_height: 'Höhe anpassen',
                qr_code: 'QR-Code',
                boxgen: 'Boxgen',
                my_cloud: 'Meine Cloud',
                curve_engraving: {
                    title: '3D Kurve',
                    exit: 'Beenden',
                    select_area: 'Bereich auswählen',
                    preview_3d_curve: '3D-Kurve Vorschau',
                    clear_area: 'Ausgewählten Bereich löschen',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Ebenen',
                objects: 'Objects',
                path_edit: 'Pfadbearbeitung',
            },
            layer_panel: {
                layer1: 'Ebene 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Gravur',
                layer_cutting: 'Schneiden',
                current_layer: 'Aktuelle Ebene',
                move_elems_to: 'Verschiebe ausgewählte Objekte:',
                notification: {
                    dupeLayerName: 'TEine Ebene hat bereits diesen Namen',
                    newName: 'NEUER NAME',
                    enterUniqueLayerName: 'Verwenden Sie einen eindeutigen Namen für die Ebene',
                    enterNewLayerName: 'Geben Sie bitte einen neuen Namen für die Ebene ein',
                    layerHasThatName: 'Eine Ebene hat bereits diesen Namen',
                    QmoveElemsToLayer: "Verschiebe ausgewählte Objekte in die Ebene '%s'?",
                    moveElemFromPrintingLayerTitle: 'Ausgewähltes Element nach %s verschieben und in Laser-Element umwandeln?',
                    moveElemFromPrintingLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Farbeinstellungen des ausgewählten Elements entfernt und entsprechend %s festgelegt werden.',
                    moveElemToPrintingLayerTitle: 'Element auswählen und in Druckelement umwandeln, um es nach %s zu verschieben?',
                    moveElemToPrintingLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Einstellungen des ausgewählten Elements entfernt und entsprechend %s festgelegt werden.',
                    splitColorTitle: 'Die ausgewählte Ebene in CMYK-Ebenen erweitern?',
                    splitColorMsg: 'Bitte beachten Sie, dass Sie nach Fortsetzung dieser Operation nicht mehr zu den ursprünglichen Farbschichten zurückkehren können.',
                    mergeLaserLayerToPrintingLayerTitle: 'Möchten Sie die Ebenen zu einer Druckebene zusammenführen?',
                    mergeLaserLayerToPrintingLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieses Vorgangs die Einstellungen der Laser-Ebene entfernt und entsprechend der aktuellen Ebene eingestellt werden.',
                    mergePrintingLayerToLaserLayerTitle: 'Möchten Sie diese Ebenen zu einer Laser-Ebene zusammenführen?',
                    mergePrintingLayerToLaserLayerMsg: 'Bitte beachten Sie, dass bei Abschluss dieses Vorgangs die Farbeinstellungen der Druckebene entfernt und entsprechend der aktuellen Ebene eingestellt werden.',
                },
                layers: {
                    layer: 'Ebene',
                    layers: 'Ebenen',
                    del: 'Ebene löschen',
                    move_down: 'Ebene nach unten verschieben',
                    new: 'Neue Ebene',
                    rename: 'Ebene umbenennen',
                    move_up: 'Ebene nach oben verschieben',
                    dupe: 'Ebene duplizieren',
                    lock: 'Ebene sperren',
                    unlock: 'Entsperren',
                    merge_down: 'Nach unten zusammenführen',
                    merge_all: 'Alle zusammenführen',
                    merge_selected: 'Ausgewählte zusammenführen',
                    move_elems_to: 'Verschiebe ausgewählte Objekte:',
                    move_selected: 'Verschiebe ausgewählte Objekte auf eine andere Ebene',
                    switchToFullColor: 'Zu Vollfarben-Ebene wechseln',
                    switchToSingleColor: 'Zu Einzelfarben-Ebene wechseln',
                    splitFullColor: 'Ebene erweitern',
                    fullColor: 'Vollfarbe',
                },
            },
            laser_panel: {
                preset_setting: 'Parametereinstellung (%s)',
                multi_layer: 'Mehrere Schichten',
                parameters: 'Parameter',
                strength: 'Leistung',
                low_power_warning: 'Niedrigere Laserleistung (unter 10 %) emittiert das Laserlicht möglicherweise nicht.',
                speed: 'Geschwindigkeit',
                speed_contrain_warning: 'Die Schnittgeschwindigkeit von Vektorpfadobjekten wird auf 20mm/s (0.79in/s) begrenzt. Sie können diese Grenze in den Einstellungen für Einstellungen entfernen.',
                low_speed_warning: 'Niedrigere Geschwindigkeit kann zu Verbrennungen des Materials führen.',
                repeat: 'Ausführen',
                add_on: 'Zusatz-Module',
                focus_adjustment: 'Fokuseinstellung',
                height: 'Objekthöhe',
                z_step: 'Z Schritt',
                diode: 'Diodenlaser',
                backlash: 'Spiel',
                ink_saturation: 'Sättigung',
                print_multipass: 'Mehrere Durchgänge',
                white_ink: 'Weiße Tinte',
                white_ink_settings: 'Einstellungen für Weiße Tinte',
                color_adjustment: 'Kanaleinstellung',
                color_adjustment_short: 'Kanal',
                halftone: 'Halbton',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Stärke',
                times: 'mal',
                cut: 'Ausschneiden',
                engrave: 'Gravieren',
                more: 'Verwalten',
                delete: 'Löschen',
                reset: 'Zurücksetzen',
                sure_to_reset: 'Dadurch werden alle Voreinstellungen zurückgesetzt und Ihre benutzerdefinierten Konfigurationen werden beibehalten. Sind Sie sicher, dass Sie fortfahren?',
                apply: 'Anwenden',
                cancel: 'Stornieren',
                save: 'Speichern',
                save_and_exit: 'Speichern und schließen',
                name: 'Name',
                default: 'Standard',
                customized: 'Kundenspezifische Liste',
                inuse: 'in Benutzung',
                export_config: 'Parameter exportieren',
                new_config_name: 'Neuer Parametername',
                sure_to_load_config: 'Dadurch wird die Anordnung der Voreinstellungen geladen und die in der Datei festgelegten benutzerdefinierten Parameter ersetzt. Sind Sie sicher, dass Sie fortfahren?',
                custom_preset: 'Benutzerdefiniert',
                various_preset: 'Mehrere Voreinstellungen',
                module: 'Modul',
                slider: {
                    regular: 'Normal',
                    low: 'Niedrig',
                    very_low: 'Minimal',
                    high: 'Hoch',
                    very_high: 'Maximal',
                    slow: 'Langsam',
                    very_slow: 'Am Langsamsten',
                    fast: 'Schnell',
                    very_fast: 'Am Schnellsten',
                },
                dropdown: {
                    parameters: 'Voreinstellungen',
                    save: 'Aktuelle Parameter hinzufügen',
                    export: 'Export',
                    import: 'Importieren',
                    more: 'Verwalten',
                    mm: {
                        wood_3mm_cutting: 'Holz - 3mm Schneiden',
                        wood_5mm_cutting: 'Holz - 5mm Schneiden',
                        wood_7mm_cutting: 'Holz - 7mm Schneiden',
                        wood_8mm_cutting: 'Holz - 8mm Schneiden',
                        wood_10mm_cutting: 'Holz - 10mm Schneiden',
                        wood_engraving: 'Holz - Gravur',
                        acrylic_3mm_cutting: 'Acryl - 3mm Schneiden',
                        acrylic_5mm_cutting: 'Acryl - 5mm Schneiden',
                        acrylic_8mm_cutting: 'Acryl - 8mm Schneiden',
                        acrylic_10mm_cutting: 'Acryl - 10mm Schneiden',
                        acrylic_engraving: 'Acryl - Gravur',
                        mdf_3mm_cutting: 'Neuseeland MDF - 3mm Schneiden',
                        mdf_5mm_cutting: 'Neuseeland MDF - 5mm Schneiden',
                        mdf_engraving: 'Neuseeland MDF - Gravur',
                        leather_3mm_cutting: 'Leder - 3mm Schneiden',
                        leather_5mm_cutting: 'Leder - 5mm Schneiden',
                        leather_engraving: 'Leder - Gravur',
                        denim_1mm_cutting: 'Denim - 1mm Schneiden',
                        fabric_3mm_cutting: 'Stoff - 3mm Schneiden',
                        fabric_5mm_cutting: 'Stoff - 5mm Schneiden',
                        fabric_engraving: 'Stoff - Gravur',
                        rubber_bw_engraving: 'Gummi - Gravur',
                        glass_bw_engraving: 'Glas - Gravur',
                        metal_bw_engraving: 'Metall - Gravur',
                        steel_engraving_spray_engraving: 'Metall - Gravur',
                        stainless_steel_bw_engraving_diode: 'Metall - Gravur (Diodenlaser)',
                        gold_engraving: 'Gold - Gravur',
                        brass_engraving: 'Messing - Gravur',
                        ti_engraving: 'Titan - Gravur',
                        stainless_steel_engraving: 'Edelstahl - Gravur',
                        aluminum_engraving: 'Aluminium - Gravur',
                        black_acrylic_3mm_cutting: 'Schwarzes Acryl - 3mm Schneiden',
                        black_acrylic_5mm_cutting: 'Schwarzes Acryl - 5mm Schneiden',
                        black_acrylic_engraving: 'Schwarzes Acryl - Gravur',
                        abs_engraving: 'ABS - Gravur',
                        silver_engraving: 'Silber - Gravur',
                        iron_engraving: 'Eisen - Gravur',
                        fabric_printing: 'Stoff - Drucken',
                        canvas_printing: 'Segeltuch - Drucken',
                        cardstock_printing: 'Kartenmaterial - Drucken',
                        wood_printing: 'Holz - Drucken',
                        bamboo_printing: 'Bambus - Drucken',
                        cork_printing: 'Kork - Drucken',
                        flat_stone_printing: 'Flacher Stein - Drucken',
                        acrylic_printing: 'Acryl - Drucken',
                        pc_printing: 'PC - Drucken',
                        stainless_steel_printing: 'Rostfreier Stahl - Drucken',
                        gloss_leather_printing: 'Glänzendes Leder - Drucken',
                        glass_printing: 'Glas - Drucken',
                    },
                    inches: {
                        wood_3mm_cutting: "Holz - 0.1'' Schneiden",
                        wood_5mm_cutting: "Holz - 0.2'' Schneiden",
                        wood_7mm_cutting: "Holz - 0.3'' Schneiden",
                        wood_8mm_cutting: "Holz - 0.3'' Schneiden",
                        wood_10mm_cutting: "Holz - 0.4'' Schneiden",
                        wood_engraving: 'Holz - Gravur',
                        acrylic_3mm_cutting: "Acryl- 0.1'' Schneiden",
                        acrylic_5mm_cutting: "Acryl- 0.2'' Schneiden",
                        acrylic_8mm_cutting: "Acryl- 0.3'' Schneiden",
                        acrylic_10mm_cutting: "Acryl- 0.4'' Schneiden",
                        acrylic_engraving: 'Acryl - Gravur',
                        mdf_3mm_cutting: "Neuseeland MDF - 0.1'' Schneiden",
                        mdf_5mm_cutting: "Neuseeland MDF - 0.2'' Schneiden",
                        mdf_engraving: 'Neuseeland MDF - Gravur',
                        leather_3mm_cutting: "Leder - 0.1'' Schneiden",
                        leather_5mm_cutting: "Leder - 0.2'' Schneiden",
                        leather_engraving: 'Leder - Gravur',
                        denim_1mm_cutting: "Denim - 0.04'' Schneiden",
                        fabric_3mm_cutting: "Stoff - 0.1'' Schneiden",
                        fabric_5mm_cutting: "Stoff - 0.2'' Schneiden",
                        fabric_engraving: 'Stoff - Gravur',
                        rubber_bw_engraving: 'Gummi - Gravur',
                        glass_bw_engraving: 'Glas - Gravur',
                        metal_bw_engraving: 'Metall - Gravur',
                        steel_engraving_spray_engraving: 'Metall - Gravur',
                        stainless_steel_bw_engraving_diode: 'Metall - Gravur (Diodenlaser)',
                        gold_engraving: 'Gold - Gravur',
                        brass_engraving: 'Messing - Gravur',
                        ti_engraving: 'Titan - Gravur',
                        stainless_steel_engraving: 'Edelstahl - Gravur',
                        aluminum_engraving: 'Aluminium - Gravur',
                        black_acrylic_3mm_cutting: "Schwarzes Acryl - 0.1'' Schneiden",
                        black_acrylic_5mm_cutting: "Schwarzes Acryl - 0.2'' Schneiden",
                        black_acrylic_engraving: 'Schwarzes Acryl - Gravur',
                        abs_engraving: 'ABS - Gravur',
                        silver_engraving: 'Silber - Gravur',
                        iron_engraving: 'Eisen - Gravur',
                        fabric_printing: 'Stoff - Drucken',
                        canvas_printing: 'Segeltuch - Drucken',
                        cardstock_printing: 'Kartenmaterial - Drucken',
                        wood_printing: 'Holz - Drucken',
                        bamboo_printing: 'Bambus - Drucken',
                        cork_printing: 'Kork - Drucken',
                        flat_stone_printing: 'Flacher Stein - Drucken',
                        acrylic_printing: 'Acryl - Drucken',
                        pc_printing: 'PC - Drucken',
                        stainless_steel_printing: 'Rostfreier Stahl - Drucken',
                        gloss_leather_printing: 'Glänzendes Leder - Drucken',
                        glass_printing: 'Glas - Drucken',
                    },
                },
                laser_speed: {
                    text: 'Geschwindigkeit',
                    unit: 'mm/s',
                    fast: 'Schnell',
                    slow: 'Langsam',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Leistung',
                    high: 'Hoch',
                    low: 'Niedrig',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Tintentyp',
                    normal: 'Gewöhnliche Tinte',
                    UV: 'UV-Tinte',
                },
                para_in_use: 'Dieser Parameter wird verwendet.',
                do_not_adjust_default_para: 'Standardparameter kann nicht angepasst werden.',
                existing_name: 'Dieser Parametername wurde verwendet.',
                presets: 'Voreinstellung',
            },
            object_panel: {
                zoom: 'Zoomen',
                group: 'Gruppieren',
                ungroup: 'Gruppierung aufheben',
                distribute: 'Verteilen',
                hdist: 'Horizontal Abstand',
                vdist: 'Vertikal Abstand',
                align: 'Ausrichten',
                left_align: 'Links ausrichten',
                center_align: 'Zentrum ausrichten',
                right_align: 'Rechts ausrichten',
                top_align: 'Oben ausrichten',
                middle_align: 'Ausrichten Mitte',
                bottom_align: 'Unten ausrichten',
                boolean: 'Boolesch',
                union: 'Vereinigung',
                subtract: 'Differenz',
                intersect: 'Überschneidung',
                difference: 'Ausschluss',
                flip: 'Flip',
                hflip: 'Horizontal Spiegeln',
                vflip: 'Vertical Spiegeln',
                lock_aspect: 'Seitenverhältnis sperren',
                unlock_aspect: 'Seitenverhältni entsperren',
                option_panel: {
                    fill: 'Füllen',
                    rounded_corner: 'Gerundete Ecke',
                    sides: 'Seiten',
                    font_family: 'Schriftart',
                    font_style: 'Style',
                    font_size: 'Stil',
                    letter_spacing: 'Buchstaben-Abstand',
                    line_spacing: 'Zeilenabstand',
                    vertical_text: 'Vertikaler Text',
                    start_offset: 'Text verschieben',
                    vertical_align: 'Ausrichtung',
                    text_infill: 'Textfüllung',
                    path_infill: 'Pfadfüllung',
                    shading: 'Schattierung',
                    threshold: 'Schwellenhelligkeit',
                    threshold_short: 'Schwellenwert',
                    stroke: 'Rahmen',
                    stroke_color: 'Randfarbe',
                    stroke_width: 'Rahmenbreite',
                    color: 'Farbe',
                },
                actions_panel: {
                    replace_with: 'Ersetzen mit...',
                    replace_with_short: 'Ersetzen',
                    trace: 'Verfolgen',
                    grading: 'Benotung',
                    brightness: 'Helligkeit',
                    sharpen: 'Schärfen',
                    crop: 'Ernte',
                    bevel: 'Fase',
                    invert: 'Farbe umkehren',
                    weld_text: 'Text schweißen',
                    convert_to_path: 'In Pfad konvertieren',
                    fetching_web_font: 'Online Schriftstilen herunterladen...',
                    uploading_font_to_machine: 'Schriftenstilen auf das Maschine aufladen...',
                    wait_for_parsing_font: 'Schriftart analysieren ... Bitte warten.',
                    offset: 'Offset',
                    array: 'Anordnung',
                    auto_arrange: 'Automatische Anordnung',
                    decompose_path: 'Zerlegen',
                    disassemble_use: 'Zerlegen',
                    create_textpath: 'Pfadtext erstellen',
                    create_textpath_short: 'Pfadtext',
                    detach_path: 'Pfadtext aufteilen',
                    detach_path_short: 'Zerlegen',
                    edit_path: 'Pfad bearbeiten',
                    disassembling: 'Zerlegen ...',
                    ungrouping: 'Gruppierung aufheben...',
                    simplify: 'Vereinfachen',
                    ai_bg_removal: 'AI-Hintergrundentfernung',
                    ai_bg_removal_short: 'BG Entfernung',
                    ai_bg_removal_reminder: 'Durch Drücken der Taste werden sofort 0,2 Credits verbraucht. Möchten Sie fortfahren?',
                    outline: 'Umriss',
                },
                path_edit_panel: {
                    node_type: 'Knotentyp',
                    sharp: 'Scharf',
                    round: 'Glatt',
                    connect: 'Verbinden',
                    disconnect: 'Trennen',
                    delete: 'Löschen',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Text in Pfad konvertieren ...',
            retreive_image_data: 'Bilddaten abrufen ...',
            export_file_error_ask_for_upload: 'Aufgabe konnte nicht exportiert werden. Bist du bereit, eine Arbeitsszene für die Entwicklung eines Teams für Fehlerberichte bereitzustellen?',
        },
        image_trace_panel: {
            apply: 'Anwenden',
            back: 'Zurück',
            cancel: 'Stornieren',
            next: 'Nächster',
            brightness: 'Helligkeit',
            contrast: 'Kontrast',
            threshold: 'Schwellenwert',
            okay: 'okay',
            tuning: 'Parameter',
        },
        photo_edit_panel: {
            apply: 'Anwenden',
            back: 'Zurück',
            cancel: 'Stornieren',
            next: 'Nächster',
            sharpen: 'Schärfen',
            sharpness: 'Schärfe',
            radius: 'Radius',
            crop: 'Ernte',
            aspect_ratio: 'Seitenverhältnis',
            original: 'Originalgröße',
            free: 'Beliebig',
            curve: 'Kurve',
            start: 'Start',
            processing: 'wird bearbeitet',
            invert: 'Farbe umkehren',
            okay: 'okay',
            compare: 'Vergleichen Sie',
            phote_edit: 'Fotobearbeitung',
            brightness_and_contrast: 'Helligkeit / Kontrast',
            brightness: 'Helligkeit',
            contrast: 'Kontrast',
        },
        document_panel: {
            document_settings: 'Dokumenteinstellungen',
            engrave_parameters: 'Gravurparameter',
            workarea: 'Arbeitsbereich',
            rotary_mode: 'Drehtisch',
            borderless_mode: 'Öffnen Sie den Boden',
            engrave_dpi: 'Auflösung',
            enable_diode: 'Diodenlaser',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Arbeitsbereich erweitern',
            add_on: 'Zusatz-Module',
            low: 'Niedrig',
            medium: 'Mittel',
            high: 'Hoch',
            ultra: 'Ultra Hoch',
            enable: 'Aktivieren',
            disable: 'Deaktivieren',
            cancel: 'Stornieren',
            save: 'Speichern',
            notification: {
                changeFromPrintingWorkareaTitle: 'Möchten Sie die Druckschichten in Laserschichten umwandeln?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Schriftart analysieren ... Bitte warten.',
            text_to_path: {
                font_substitute_pop: 'Ihr Text enthält Zeichen, die von der aktuellen Schriftart nicht unterstützt werden. <br/>Möchten Sie <strong>%s</strong> als Ersatz verwenden?',
                check_thumbnail_warning: 'Einige Texte wurden beim Verwandeln von Texten in Pfade in andere Schriftarten geändert, und einige Zeichen werden möglicherweise nicht normal konvertiert.\nBitte überprüfe das Vorschaubild erneut, bevor du die Aufgabe sendest.',
                error_when_parsing_text: 'Fehler beim Konvertieren von Text in Pfad',
                use_current_font: 'Aktuelle Schriftart verwenden',
                retry: 'Bitte versuchen Sie es später noch einmal oder wählen Sie eine andere Schriftart',
            },
            lock_desc: 'Behalte das Verhältnis von Breite und Höhe bei (UMSCHALTTASTE)',
        },
        tool_panels: {
            cancel: 'Stornieren',
            confirm: 'Bestätigen',
            grid_array: 'Grid Array erstellen',
            array_dimension: 'Array-Dimension',
            rows: 'Zeilen',
            columns: 'Spalten',
            array_interval: 'Array-Intervall',
            dx: 'X',
            dy: 'Y',
            offset: 'Offset',
            nest: 'Arrangement-Optimierung',
            _offset: {
                direction: 'Versatzrichtung',
                inward: 'Innere',
                outward: 'Nach außen',
                dist: 'Versetzte Entfernung',
                corner_type: 'Ecke',
                sharp: 'Scharf',
                round: 'Runden',
                fail_message: 'Fehler beim Versetzen von Objekten.',
                not_support_message: 'Ausgewählte Elemente mit nicht unterstütztem SVG-Tag:\nBild, Gruppe, Text, Importierte Objekt.',
            },
            _nest: {
                start_nest: 'Ordnen',
                stop_nest: 'Halt',
                end: 'Schließen',
                spacing: 'Abstand',
                rotations: 'Mögliche Drehung',
                no_element: 'Es ist kein Element zu arrangieren.',
            },
        },
        network_testing_panel: {
            network_testing: 'Netzwerktests',
            local_ip: 'Lokale IP-Adresse:',
            insert_ip: 'IP-Adresse des Zielgeräts:',
            empty_ip: '#818 Bitte gebe zuerst die IP des Zielgeräts ein.',
            start: 'Start',
            end: 'Ende',
            testing: 'Netzwerk testen ...',
            invalid_ip: '#818 ungültige IP-Adresse',
            ip_startswith_169: '#843 Die IP-Adresse des Geräts beginnt mit 169.254',
            connection_quality: 'Verbindungsqualität',
            average_response: 'Durchschnittliche Reaktionszeit',
            test_completed: 'Test abgeschlossen',
            test_fail: 'Im Test durchgefallen',
            cannot_connect_1: '#840 Verbindung zur Ziel-IP fehlgeschlagen.',
            cannot_connect_2: '#840 Verbindung zur Ziel-IP fehlgeschlagen. Stelle sicher, dass sich das Ziel im selben Netzwerk befindet.',
            network_unhealthy: '#841 Verbindungsqualität < 70 oder durchschnittliche Antwortzeit > 100ms',
            device_not_on_list: '#842 Das Gerät ist nicht in der Liste aufgeführt, aber der Verbindungsqualität beträgt > 70 und die durchschnittliche Antwortzeit < 100ms',
            hint_device_often_on_list: 'Die Maschine ist oft nicht auf der Liste gefunden?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Verbindung beim Senden eines Auftrags fehlgeschlagen?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Zeitüberschreitung beim Starten der Kameravorschau?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Der Zugriff auf die lokale IP-Adresse ist fehlgeschlagen.',
            fail_to_start_network_test: '#817 Starte den Netzwerktest nicht.',
            linux_permission_hint: 'Dieser Fehler tritt normalerweise aufgrund unzureichender Berechtigungen auf.\nFühren Sie bitte "sudo beam-studio --no-sandbox" im Terminal aus, um Berechtigungen zu erhalten und Netzwerktests durchzuführen.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Ebenenfarbkonfigurationen',
            color: 'Farbe',
            power: 'Leistung',
            speed: 'Geschwindigkeit',
            repeat: 'Ausführen',
            add: 'Hinzufügen',
            save: 'Speichern',
            cancel: 'Stornieren',
            default: 'Zurücksetzen',
            add_config: 'Farbe hinzufügen',
            in_use: 'Diese Farbe wird verwendet.',
            no_input: 'Bitte gebe einen gültigen Hex-Farbcode ein.',
            sure_to_reset: 'Du verlierst alle benutzerdefinierten Parameter. Bist du sicher, dass du die Standardeinstellungen wiederherzustellen?',
            sure_to_delete: 'Bist du sicher, diese Farbeinstellung zu löschen?',
        },
        rating_panel: {
            title: 'Gefallen Sie Beam Studio?',
            description: 'Wenn Sie Beam Studio mögen, würden wir uns sehr freuen, wenn Sie uns bewerten könnten.',
            dont_show_again: 'Nicht mehr anzeigen',
            thank_you: 'Danke schön',
        },
        svg_editor: {
            unnsupported_file_type: 'Der Dateityp wird nicht direkt unterstützt. Bitte konvertiere die Datei in SVG oder Bitmap.',
            unable_to_fetch_clipboard_img: 'Bild konnte nicht aus der Zwischenablage abgerufen werden',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Abspielen',
            pause: 'Pause',
            stop: 'Stoppen',
            play_speed: 'Wiedergabegeschwindigkeit',
            travel_path: 'Bewegungspfad',
            invert: 'Farben umkehren',
            preview_info: 'Information Vorschau',
            size: 'Größe',
            estimated_time: 'Voraussichtliche Laufzeitlänge',
            cut_time: 'Schneiddauer',
            rapid_time: 'Bewegungsdauer',
            cut_distance: 'Schnittlänge',
            rapid_distance: 'Bewegungsstrecke',
            current_position: 'Aktuelle Position',
            remark: '* Alle angezeigten Werten dienen nur Referenzzwecken.',
            start_here: 'Hier anfangen',
            end_preview: 'Vorschau beenden',
        },
        shapes_panel: {
            title: 'Elemente',
            basic: 'Grundlegend',
            shape: 'Form',
            graphics: 'Grafiken',
            arrow: 'Pfeil',
            label: 'Symbol',
            decor: 'Dekor',
            circular: 'Kreisförmig',
            corner: 'Ecke',
            line: 'Linie',
            photo: 'Foto',
            ribbon: 'Band',
            speech: 'Rede',
            text: 'Textfeld',
            animals: 'Tier',
            birds: 'Vogel',
            land: 'Land',
            sea: 'Meer',
            holidays: 'Feiertag',
            celebration: 'Feier',
            CNY: 'Chinesisches Neujahr',
            easter: 'Ostern',
            halloween: 'Halloween',
            valentines: 'Valentinstag',
            Xmas: 'Weihnachten',
            nature: 'Natur',
            elements: 'Elemente',
            environment: 'Umwelt',
            plants: 'Pflanzen',
            weather: 'Wetter',
        },
        announcement_panel: {
            title: 'Ankündigung',
            dont_show_again: 'Nicht erneut anzeigen',
        },
    },
    editor: {
        prespray_area: 'Grundierbereich',
        opacity: 'Vorschau Transparenz',
        exposure: 'Vorschau Belichtung',
    },
    flux_id_login: {
        connection_fail: '#847 Verbindung zum FLUX Mitgliederservice fehlgeschlagen.',
        login_success: 'Anmeldung erfolgreich',
        login: 'Einloggen',
        unlock_shape_library: 'Melden Sie sich an, um die shapes Datenbank freizuschalten.',
        email: 'Email',
        password: 'Passwort',
        remember_me: 'Passwort merken',
        forget_password: 'Passwort vergessen?',
        register: 'FLUX-Benutzerkonto erstellen',
        offline: 'Offline arbeiten',
        work_offline: 'Offline arbeiten',
        incorrect: 'Email Adresse oder Passwort ist falsch.',
        not_verified: 'Die Email Adresse ist noch nicht bestätigt worden.',
        new_to_flux: 'Neu bei FLUX? Erstellen Sie einen Account.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Erkunden Sie FLUX+ Pläne',
            thank_you: 'Vielen Dank für Ihre Mitgliedschaft!',
            ai_credit_tooltip: 'Für KI-Hintergrundentfernung',
            flux_credit_tooltip: 'Für Designmarktdateien und KI-Hintergrundentfernung',
            goto_member_center: 'Zum Mitgliederzentrum gehen',
            access_plus_feature_1: 'Sie greifen auf eine',
            access_plus_feature_2: 'Funktion zu.',
            access_plus_feature_note: 'Sie müssen FLUX+ Mitgliedschaft haben, um auf diese Funktion zuzugreifen.',
            access_monotype_feature: 'Sie haben kein Monotype-Schriften-Add-on.',
            access_monotype_feature_note: 'Sie müssen FLUX+ Pro-Mitgliedschaft oder Monotype-Schriften-Add-on haben, um auf diese Funktion zuzugreifen.',
            learn_more: 'Mehr erfahren',
            get_addon: 'Add-on holen',
            subscribe_now: 'Jetzt abonnieren',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI-Hintergrundentfernung',
                my_cloud: 'Unbegrenzter Cloudspeicher',
                boxgen: '3D-Box-Generator',
                dmkt: '1000+ Design-Dateien',
                monotype: '250+ Premium-Schriftarten',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Melden Sie sich an, um die shapes Datenbank freizuschalten.',
        enjoy_shape_library: 'Genießen Sie die shapes Datenbank.',
        shapes: 'Elemente',
        elements: 'Elemente',
        recent: 'Kürzlich',
        search: 'Suche',
        clear: 'Löschen',
        export_svg_title: 'SVG kann nicht exportiert werden',
        export_svg_warning: 'Dieses Projekt enthält Objekte, die durch das Gesetz zum Schutz des geistigen Eigentums geschützt sind. Daher schließt Beam Studio diese Objekte beim Export automatisch aus. Sie können Ihr Projekt weiterhin in Beam-Studio-Datei (.beam-Format) speichern, um alle Daten zu behalten. Möchten Sie noch exportieren?',
        learn_more: 'Erfahren Sie mehr',
    },
    change_logs: {
        change_log: 'Protokolle ändern:',
        added: 'Hinzugefügt:',
        fixed: 'Fest:',
        changed: 'Geändert:',
        see_older_version: 'Siehe Ältere Versionen',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Gerät wählen',
        auth_failure: '#811 Authentifizierung fehlgeschlagen',
        unable_to_connect: '#810 Es kann keine stabile Verbindung zur Maschine hergestellt werden',
    },
    device: {
        pause: 'Pause',
        paused: 'Pause',
        pausing: 'Pause',
        select_printer: 'Wähle Drucker',
        retry: 'Wiederholen',
        status: 'Status',
        busy: 'Beschäftigt',
        ready: 'Bereit',
        reset: 'Zurücksetzen (Kick)',
        abort: 'Abbrechen',
        start: 'Start',
        please_wait: 'Bitte warten...',
        quit: 'Verlassen',
        completing: 'Abschliessen',
        aborted: 'Abgebrochen',
        completed: 'Abgeschlossen',
        aborting: 'Abbruch',
        starting: 'Beginnend',
        preparing: 'Vorbereiten',
        resuming: 'Wiederaufnahme',
        scanning: 'am Scannen',
        occupied: 'in Wartung',
        running: 'am Arbeiten',
        uploading: 'am Hochladen',
        processing: 'wird bearbeitet',
        disconnectedError: {
            caption: 'Maschine nicht angeschlossen',
            message: 'Bitte bestätige, ob ein Netzwerkzugriff von %s verfügbar ist',
        },
        unknown: 'Unbekannt',
        pausedFromError: 'Wegen Fehler angehalten',
        model_name: 'Modellname',
        IP: 'IP',
        serial_number: 'Seriennummer',
        firmware_version: 'Firmware Version',
        UUID: 'UUID',
        select: 'Wählen',
        deviceList: 'Maschinenliste',
        disable: 'Deaktivieren',
        enable: 'Aktivieren',
        submodule_type: 'Modul',
        cartridge_serial_number: 'Tintenpatronen-Seriennummer',
        ink_color: 'Tintenfarbe',
        ink_type: 'Tintenart',
        ink_level: 'Verbleibende Tintenmenge',
        close_door_to_read_cartridge_info: 'Um auf Tinteninformationen zuzugreifen, schließen Sie bitte die Türabdeckung der Maschine.',
        cartridge_info_read_failed: 'Bitte stellen Sie sicher, dass die Tintenpatrone vollständig eingesteckt ist. Versuchen Sie, die Tinte zu entfernen und erneut einzusetzen.',
        cartridge_info_verification_failed: 'Bitte überprüfen Sie, ob Ihre Tinte FLUX authentisch ist.',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Start',
        resume: 'Weitermachen',
        start: 'Start',
        pause: 'Pause',
        stop: 'Halt',
        record: 'Aufzeichnung',
        camera: 'Kamera',
        taskTab: 'Datei',
        connecting: 'Verbindung wird hergestellt. Bitte warten...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritischer Fehler: Mainboard offline. Bitte wenden Sie sich an den FLUX Support.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritischer Fehler: Subsystem keine Antwort. Bitte wenden Sie sich an den FLUX Support',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Bitte erkundigen Sie sich bei Ihrem Wassertank.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 schliesse die Tür, um fortzufahren.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Überhitzt. Bitte warten Sie einige Minuten.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Boden offen. Close the door to continue.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Zurücksetzen der Z-Achse',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Schublade geöffnet',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Flammensensor anomal',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftstromsensor anomal',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Kein Drehmodul erkannt',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off Error',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Falsches Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul nicht erkannt. Bitte stellen Sie sicher, dass das Modul ordnungsgemäß installiert ist, um fortzufahren.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Unbekanntes Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Druckermodul ohne Antwort.',
        USER_OPERATION_ROTARY_PAUSE: 'Bitte wechseln Sie zum Rotationsmotor',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Bitte entfernen Sie die Patrone, um fortzufahren.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Falsches Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul nicht erkannt. Bitte stellen Sie sicher, dass das Modul ordnungsgemäß installiert ist, um fortzufahren.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Unbekanntes Modul erkannt. Bitte installieren Sie das richtige Modul, um fortzufahren.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Bitte installieren Sie das richtige Modul, um fortzufahren.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Bitte setzen Sie die Patrone ein, um fortzufahren.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Bitte setzen Sie die richtige Patrone ein, um fortzufahren.',
        USER_OPERATION: 'Befolgen Sie bitte die Anweisungen auf dem Gerätepanel, um fortzufahren.',
        RESOURCE_BUSY: 'Die Maschine ist beschäftigt\nWenn es nicht läuft, starte den Computer neu',
        DEVICE_ERROR: 'Etwas ist schief gelaufen\nBitte starte die Maschine neu',
        NO_RESPONSE: '#905 Fehler beim Verbinden mit dem Mainboard.\nBitte starte die Maschine neu.',
        SUBSYSTEM_ERROR: '#402 Kritischer Fehler: Subsystem keine Antwort. Bitte wenden Sie sich an den FLUX Support.',
        HARDWARE_FAILURE: 'Etwas ist schief gelaufen\nBitte starte die Maschine neu',
        MAINBOARD_OFFLINE: '#905 Fehler beim Verbinden mit dem Mainboard.\nBitte starte die Maschine neu.',
        bug_report: 'Fehlerbericht',
        processing: 'wird bearbeitet',
        savingPreview: 'Beispiele generieren',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'übrig',
        temperature: 'Temperatur',
        forceStop: 'Möchtest du die laufende Aufgabe abbrechen?',
        upload: 'Hochladen',
        download: 'herunterladen',
        relocate: 'Umziehen',
        cancel: 'Abbrechen',
        prepareRelocate: 'Vorbereitung für den Umzug',
        extensionNotSupported: 'Dieses Dateiformat wird nicht unterstützt.',
        fileExistContinue: 'Datei existiert bereits, möchten Sie sie ersetzen?',
        confirmFileDelete: 'Sind Sie sicher, dass Sie diese Datei löschen möchten?',
        ask_reconnect: 'Die Verbindung zur Maschine ist unterbrochen. Möchten Sie die Verbindung wiederherstellen?',
        task: {
            BEAMBOX: 'Laser-Gravur',
            'N/A': 'Freier Modus',
        },
    },
    alert: {
        caption: 'Fehler',
        duplicated_preset_name: 'Duplizierter Voreinstellungsname',
        info: 'INFO',
        warning: 'WARNUNG',
        error: 'UH-OH',
        instruction: 'Anleitung',
        oops: 'Hoppla...',
        retry: 'Wiederhole',
        abort: 'Abbrechen',
        confirm: 'Bestätige',
        cancel: 'Abbrechen',
        close: 'schließen',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ja',
        no: 'Nein',
        stop: 'Halt',
        save: 'Speichern',
        dont_save: 'Nicht speichern',
        learn_more: 'Erfahren Sie mehr',
    },
    caption: {
        connectionTimeout: 'Verbindungs-Timeout',
    },
    message: {
        cancelling: 'Abbrechen...',
        connecting: 'Verbinden...',
        connectingMachine: 'Verbinden von %s...',
        tryingToConenctMachine: 'Versuch, eine Verbindung zur Maschine herzustellen...',
        connected: 'Verbunden',
        authenticating: 'Authentifizierung...',
        enteringRawMode: 'Rohmodus aufrufen...',
        endingRawMode: 'Rohmodus verlassen...',
        enteringLineCheckMode: 'Aufrufen des Leitungsprüfmodus...',
        endingLineCheckMode: 'Verlassen des Leitungsprüfmodus...',
        exitingRotaryMode: 'Rotationsmodus verlassen...',
        turningOffFan: 'Lüfter ausschalten...',
        turningOffAirPump: 'Luftpumpe ausschalten...',
        gettingLaserSpeed: 'Laserkopfgeschwindigkeit ablesen...',
        settingLaserSpeed: 'Einstellen der Laserkopfgeschwindigkeit...',
        retrievingCameraOffset: 'Kamera-Offset lesen...',
        connectingCamera: 'Kamera anschließen...',
        homing: 'Homing...',
        connectionTimeout: '#805 Zeitlimit für Geräteverbindung. Bitte überprüfe den Zustand Ihres Netzwerks und die Wi-Fi-Anzeige Ihres Geräts.',
        getProbePosition: 'Sondenposition wird abgerufen...',
        device_not_found: {
            caption: 'Standard-Maschine nicht gefunden',
            message: '#812 Bitte überprüfe die Wi-Fi-Anzeige Ihres Geräts',
        },
        device_busy: {
            caption: 'Maschine beschäftigt',
            message: 'Die Maschine führt eine andere Aufgabe aus, versuchen Sie es später noch einmal. Wenn sie nicht mehr funktioniert, starte die Maschine bitte neu.',
        },
        device_is_used: 'Die Maschine wird benutzt, möchten Sie die aktuelle Aufgabe abbrechen?',
        monitor_too_old: {
            caption: 'Veraltete Firmware',
            content: '#814 Bitte installieren Sie die neueste Firmware mit <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">dieser Anleitung</a>.',
        },
        unknown_error: '#821 Die Anwendung ist auf einen unbekannten Fehler gestoßen, bitte benutzen Sie Hilfe > Menü > Fehlerbericht.',
        unknown_device: '#826 Die Verbindung zum Gerät kann nicht hergestellt werden, Bitte stelle sicher, dass USB an das Gerät angeschlossen ist.',
        unsupport_osx_version: 'Die aktuelle macOS-Version %s unterstützt möglicherweise einige Funktionen nicht. Bitte aktualisieren Sie auf die macOS 11+.',
        unsupport_win_version: 'Die aktuelle Betriebssystemversion %s unterstützt möglicherweise einige Funktionen nicht. Bitte aktualisieren Sie auf die neueste Version.',
        need_password: 'Benötigen Passwort für die Verbindung mit der Maschine',
        unavailableWorkarea: '#804 Der aktuelle Arbeitsbereich übersteigt den Arbeitsbereich dieser Maschine. Bitte überprüfe den Arbeitsbereich des ausgewählten Geräts oder stelle den Arbeitsbereich über Bearbeiten > Dokumenteinstellung ein.',
        please_enter_dpi: 'Bitte geben Sie die Einheit Ihrer Datei ein (in mm)',
        auth_error: '#820 Auth-Fehler: Bitte aktualisieren Sie Beam Studio und die Firmware des Computers auf die neueste Version.',
        usb_unplugged: 'Die USB-Verbindung ist verloren gegangen. Bitte überprüfe Ihre USB-Verbindung',
        uploading_fcode: 'FCode hochladen',
        cant_connect_to_device: '#827 Das Gerät konnte nicht angeschlossen werden, bitte überprüfe Ihre Verbindung',
        unable_to_find_machine: 'Maschine konnte nicht gefunden werden',
        disconnected: 'Verbindung instabil, Bitte überprüfe die Geräteverbindung und versuche es später erneut',
        unable_to_start: '#830 Die Aufgabe konnte nicht gestartet werden. Bitte versuchen Sie es erneut. Wenn dies erneut geschieht, kontaktieren Sie uns bitte mit einem Fehlerbericht:\n',
        camera: {
            camera_cable_unstable: 'Es wird festgestellt, dass die Kamera Fotos instabil überträgt. Die Vorschau kann weiterhin normal ausgeführt werden, es kann jedoch das Problem einer langsamen Vorschau oder eines Zeitlimits auftreten.',
            fail_to_transmit_image: '#845 Bei der Bildübertragung ist ein Fehler aufgetreten. Bitte starten Sie Ihren Maschinen oder Beam Studio neu. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">diese Anleitung</a>.',
            ws_closed_unexpectly: '#844 Die Verbindung zur Maschinenkamera wurde unerwartet geschlossen. Wenn dieser Fehler weiterhin besteht, befolgen Sie <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402755805071">diese Anleitung</a>.',
            continue_preview: 'Fortsetzen',
            abort_preview: 'Abbrechen',
        },
        preview: {
            camera_preview: 'Kameravorschau',
            auto_focus: 'Autofokus',
            auto_focus_instruction: 'Bewegen Sie bitte den Lasermodulkopf über das Objekt und befolgen Sie die Animationsanweisungen, um auf AF zu drücken, um den Fokus einzustellen.',
            already_performed_auto_focus: 'Sie haben bereits den Autofokus durchgeführt. Möchten Sie die vorhandenen Werte verwenden?',
            please_enter_height: 'Bitte geben Sie die Höhe des Objekts ein, um eine präzise Kameraaufnahme zu erfassen.',
            apply: 'Anwenden',
            enter_manually: 'Manuell eingeben',
            adjust: 'Anpassen',
            adjust_height_tooltip: 'Die ausgewählte Beispieldatei wird von der aktuellen Arbeitsfläche nicht unterstützt.',
        },
        unsupported_example_file: 'The selected example file is not supported by current workarea.',
        time_remaining: 'Verbleibende Zeit:',
    },
    machine_status: {
        '-17': 'Cartridge IO Mode',
        '-10': 'Modus beibehalten',
        '-2': 'Scannen',
        '-1': 'Aufrechterhaltung',
        0: 'Bereit',
        1: 'Initiieren',
        2: 'ST_TRANSFORM',
        4: 'Beginnend',
        6: 'Wiederaufnahme',
        16: 'Arbeiten',
        18: 'Wiederaufnahme',
        32: 'Pause',
        36: 'Pause',
        38: 'Pause',
        48: 'Pause',
        50: 'Pause',
        64: 'Abgeschlossen',
        66: 'Abschluss',
        68: 'Vorbereiten',
        128: 'Abgebrochen',
        256: 'Alarm',
        512: 'Fatal',
        UNKNOWN: 'Unbekannt',
    },
    calibration: {
        update_firmware_msg1: 'Ihre Firmware unterstützt diese Funktion nicht. Bitte aktualisieren Sie die Firmware auf v',
        update_firmware_msg2: 'oder höher, um fortzufahren。 (Menü> Maschine> [Ihre Maschine]> Firmware aktualisieren)',
        camera_calibration: 'Kamerakalibrierung',
        diode_calibration: 'Kalibrierung des Diodenlasermodul',
        module_calibration_printer: 'Druckermodul-Kalibrierung',
        module_calibration_2w_ir: 'Infrarot-Modul-Kalibrierung',
        back: 'ZURÜCK',
        next: 'NÄCHSTER',
        skip: 'ÜBERSPRINGEN',
        cancel: 'STORNIEREN',
        finish: 'ERLEDIGT',
        do_engraving: 'GRAVIEREN',
        start_engrave: 'STARTE ENGRAVE',
        start_printing: 'DRUCKEN STARTEN',
        ask_for_readjust: 'Möchten Sie den Schneideschritt überspringen und Bilder und Kalibrierungen aufnehmen?',
        please_goto_beambox_first: 'Bitte wechsle in den Gravurmodus (Beambox), um diese Funktion nutzen zu können.',
        please_place_paper: 'Bitte lege ein weißes Papier im A4- oder Letter-Format in die linke obere Ecke des Arbeitsbereichs.',
        please_place_paper_center: 'Legen Sie ein A4- oder Letter-Format weißes Papier in die Mitte des Arbeitsbereichs.',
        please_place_dark_colored_paper: 'Bitte legen Sie ein A4- oder Letter-Format dunkles Papier in die Mitte des Arbeitsbereichs.',
        please_refocus: {
            beambox: 'Stelle die Plattform bitte auf den Brennpunkt ein (die Höhe des umgedrehten Acryls).',
            beamo: 'Stelle den Laserkopf bitte so ein, dass er auf das Gravurobjekt fokussiert (Höhe des umgedrehten Acryls).',
            beamo_af: 'Tippen Sie bitte zweimal auf die Seitentaste des Autofokus-Add-Ons und lassen Sie die Sonde das Material sanft berühren.',
            hexa: 'Double click the height adjustment button to rise the honeycomb table up and make the probe touch the engraving material.',
        },
        without_af: 'Ohne Autofukus',
        with_af: 'Mit Autofokus',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Drehung',
        x_ratio: 'X-Verhältnis',
        y_ratio: 'Y-Verhältnis',
        show_last_config: 'Letztes Ergebnis anzeigen',
        use_last_config: 'Verwenden Sie den letzten Kalibrierungswert',
        taking_picture: 'Foto machen...',
        analyze_result_fail: 'Konnte das aufgenommene Bild nichtanalysieren. <br/> Bitte stelle Folgendes sicher: <br/>1. Das aufgenommene Bild ist vollständig mit weißem Papier bedeckt.<br/>2. Die Plattform ist richtig fokussiert.',
        drawing_calibration_image: 'Kalibrierungsbild zeichnen ...',
        calibrate_done: 'Kalibrierung abgeschlossen. Eine bessere Kameragenauigkeit ist gegeben, wenn genau fokussiert wird.',
        calibrate_done_diode: 'Kalibrierung durchgeführt. Offset des Diodenlasermodul wurde gespeichert.',
        hint_red_square: 'Bitte richten Sie das Äußere des roten Quadrats am ausgeschnittenen Quadrat aus.',
        hint_adjust_parameters: 'Verwende diese Parameter, um das rote Quadrat anzupassen',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Beim Kalibrieren von Diodenlasermodul wird eine Kamera benötigt\nBitte stelle sicher, dass die Kamera dieser Maschine kalibriert wurde.\nUnd stelle bitte die Plattform auf den Brennpunkt ein (die Höhe des umgedrehten Acryls).',
            beamo: 'Beim Kalibrieren von Diodenlasermodul wird eine Kamera benötigt.\nBitte stelle sicher, dass die Kamera dieser Maschine kalibriert wurde.\nUnd stelle bitte den Laserkopf so ein, dass er auf das Gravurobjekt fokussiert ist (die Höhe des umgedrehten Acryls).',
        },
        downloading_pictures: 'Bilder herunterladen...',
        failed_to_download_pictures: '#848 Fehler beim Herunterladen der Bilder, bitte kontaktieren Sie den FLUX Support.',
        uploading_images: 'Bilder hochladen...',
        calculating_camera_matrix: 'Berechnung der Kameramatrix...',
        calculating_regression_parameters: 'Berechnung der Regressionsparameter...',
        failed_to_calibrate_camera: '#848 Fehler bei der Kamerakalibrierung, bitte kontaktieren Sie den FLUX Support.',
        failed_to_save_calibration_results: '#849 Fehler beim Speichern der Kalibrierungsergebnisse, bitte versuchen Sie es erneut. Wenn dies weiterhin passiert, kontaktieren Sie bitte den FLUX Support.',
        ador_autofocus_material: 'Drücken Sie das "AF"-Symbol auf der Hauptseite des Geräts 3 Sekunden lang und lassen Sie die Sonde sanft das Material berühren.',
        ador_autofocus_focusing_block: 'Drücken Sie 3 Sekunden lang auf das "AF"-Symbol auf der Hauptseite der Maschine und lassen Sie die Sonde den Fokussierblock berühren.',
        align_red_cross_cut: 'Bitte alignieren Sie die Mitte des roten Kreuzes mit dem geschnittenen Kreuz.',
        align_red_cross_print: 'Bitte alignieren Sie die Mitte des roten Kreuzes mit dem gedruckten Kreuz.',
        retake: 'Foto erneut aufnehmen',
        calibrate_camera_before_calibrate_modules: 'Bitte führen Sie zuerst eine Kamerakalibrierung durch, bevor Sie die Module kalibrieren.',
        check_checkpoint_data: 'Checkpoint-Daten',
        checking_checkpoint: 'Überprüfe Checkpoint-Daten...',
        found_checkpoint: 'Checkpoint-Daten auf Ihrem Gerät gefunden. Möchten Sie von dem Checkpoint wiederherstellen?',
        use_old_camera_parameter: 'Möchten Sie die aktuellen Kameraparameter verwenden?',
        downloading_checkpoint: 'Herunterladen von Checkpoint-Daten...',
        failed_to_parse_checkpoint: 'Fehler beim Analysieren der Checkpoint-Daten.',
        check_device_pictures: 'Gerätebilder überprüfen',
        checking_pictures: 'Überprüfe Gerätebilder...',
        no_picutre_found: '#846 Dein Gerät hat keine verfügbaren Rohfotos für die Kalibrierung. Bitte kontaktiere den FLUX-Support.',
        calibrating_with_device_pictures: 'Kalibrieren mit Gerätebildern...',
        failed_to_calibrate_with_pictures: '#847 Kalibrierung mit Gerätebildern fehlgeschlagen.',
        getting_plane_height: 'Ermittlung der Höhe der Ebene...',
        preparing_to_take_picture: 'Vorbereitung zum Fotografieren...',
        put_paper: 'Papier einlegen',
        put_paper_step1: 'Bitte legen Sie ein A4- oder Letter-Format weißes Papier in die Mitte des Arbeitsbereichs.',
        put_paper_step2: 'Sichern Sie die vier Ecken des Papiers, um sicherzustellen, dass es flach liegt.',
        put_paper_step3: 'Klicken Sie auf "STARTE ENGRAVE".',
        solve_pnp_title: 'Ausrichten von Markierungspunkten',
        solve_pnp_step1: 'Bitte richten Sie die Gravurpunkte entsprechend der Nummer und der erwarteten Position jedes roten Markierungspunktes aus.',
        solve_pnp_step2: 'Sie können auf "Foto erneut aufnehmen" drücken, um die Ausrichtung zu wiederholen oder die Markierungspositionen manuell anzupassen.',
        align_olt: 'Position: Richten Sie sich am gravierten Punkt oben links außen aus.',
        align_ort: 'Position: Richten Sie sich am gravierten Punkt oben rechts außen aus.',
        align_olb: 'Position: Richten Sie sich am gravierten Punkt unten links außen aus.',
        align_orb: 'Position: Richten Sie sich am gravierten Punkt unten rechts außen aus.',
        align_ilt: 'Position: Richten Sie sich am gravierten Punkt oben links innen.',
        align_irt: 'Position: Richten Sie sich am gravierten Punkt oben rechts innen.',
        align_ilb: 'Position: Richten Sie sich am gravierten Punkt unten links innen.',
        align_irb: 'Position: Richten Sie sich am gravierten Punkt unten rechts innen.',
        elevate_and_cut: 'Anheben und Schneiden',
        elevate_and_cut_step_1: 'Platzieren Sie ein A4-großes hellfarbenes Holz in der Mitte des Arbeitsbereichs und heben Sie es auf 20mm an.',
        elevate_and_cut_step_1_prism_lift: 'Verwenden Sie Ador Prism Lift mit einer maximalen Länge von 14mm zusammen mit einem Holz von mindestens 6mm Dicke.',
        with_prism_lift: 'Mit Ador Prism Lift',
        without_prism_lift: 'Ohne Ador Prism Lift',
        camera_parameter_saved_successfully: 'Kameraparameter erfolgreich gespeichert.',
        failed_to_save_camera_parameter: 'Speichern der Kameraparameter fehlgeschlagen.',
        failed_to_solve_pnp: 'Die Kameraposition konnte nicht gelöst werden.',
    },
    input_machine_password: {
        require_password: '%s erfordert ein Passwort',
        connect: 'VERBINDEN',
        password: 'Passwort',
    },
    tutorial: {
        skip: 'Überspringen',
        welcome: 'WILLKOMMEN',
        suggest_calibrate_camera_first: 'Wir empfehlen Benutzern, die Kamera zuerst zu kalibrieren und vor jeder Vorschau neu zu fokussieren, um optimale Ergebnisse zu erzielen.<br/>Möchten Sie die Kalibrierung jetzt durchführen?<br/>(Sie können es jetzt überspringen und später durch Klicken auf "Menü" > "Maschine" > [Ihre Maschine] > "Kamera kalibrieren".)',
        camera_calibration_failed: 'Kamera Kalibrierung fehlgeschlagen',
        ask_retry_calibration: 'Möchten Sie die Kamera noch einmal kalibrieren?',
        needNewUserTutorial: 'Möchten Sie ein Tutorial starten?<br/>(Sie können es jetzt überspringen und später durch Klicken auf "Hilfe" > "Start-Tutorial anzeigen" starten.)',
        needNewInterfaceTutorial: 'Möchten Sie ein Tutorial für das neue Interface von Beam Studio starten?<br/>(Sie können es jetzt überspringen und später durch Klicken auf "Hilfe" > "Interface-Einführung anzeigen" starten.)',
        next: 'NÄCHSTER',
        look_for_machine: 'Suchmaschine für Tutorial ...',
        unable_to_find_machine: 'Maschine für Tutorial konnte nicht gefunden werden. Möchten Sie zur Verbindungseinstellungsseite gehen, das Tutorial wiederholen oder überspringen?',
        skip_tutorial: 'Sie haben das Tutorial übersprungen. Sie können das Tutorial jederzeit starten, indem Sie auf "Hilfe"> "Erstmaliges Tutorial anzeigen" klicken.',
        set_connection: 'Verbindung einstellen',
        retry: 'Wiederholen',
        newUser: {
            draw_a_circle: 'Zeichne einen Kreis',
            drag_to_draw: 'Zum Zeichnen ziehen',
            infill: 'Füllen einschalten',
            switch_to_object_panel: 'Wechsel zum Objektpanel',
            switch_to_layer_panel: 'Wechseln Sie zum Ebenenbedienfeld',
            set_preset_wood_engraving: 'Set Preset: Holz - Gravur',
            set_preset_wood_cut: 'Set Preset: Holz - Schneiden',
            add_new_layer: 'Fügen Sie eine neue Ebene hinzu',
            draw_a_rect: 'Zeichne ein Rechteck',
            switch_to_preview_mode: 'Wechseln Sie in den Vorschaumodus',
            preview_the_platform: 'Vorschau der Plattform',
            end_preview_mode: 'Beenden Sie den Vorschaumodus',
            put_wood: '1. Legen Sie das Musterholz',
            adjust_focus: '2. Stellen Sie den Fokus ein',
            close_cover: '3. Schließen Sie die Abdeckung',
            send_the_file: 'Senden Sie die Datei',
            end_alert: 'Sind Sie sicher, das Tutorial zu beenden?',
            please_select_wood_engraving: 'Bitte wählen Sie die Voreinstellung "Holz - Gravur".',
            please_select_wood_cutting: 'Bitte wählen Sie die Voreinstellung "Holz - Schneiden".',
        },
        newInterface: {
            camera_preview: 'Kameravorschau',
            select_image_text: 'Wählen Sie / Bild / Text',
            basic_shapes: 'Grundformen',
            pen_tool: 'Stiftwerkzeug',
            add_new_layer: 'Neue Ebene hinzufügen',
            rename_by_double_click: 'Umbenennen durch Doppelklick',
            drag_to_sort: 'Zum Sortieren ziehen',
            layer_controls: 'Klicken Sie mit der rechten Maustaste, um Ebenensteuerelemente auszuwählen:\nEbenen duplizieren / zusammenführen / sperren / löschen',
            switch_between_layer_panel_and_object_panel: 'Wechseln Sie zwischen Ebenenbedienfeld und Objektbedienfeld',
            align_controls: 'Ausrichten Steuerelemente',
            group_controls: 'Gruppensteuerelemente',
            shape_operation: 'Formbetrieb',
            flip: 'Flip',
            object_actions: 'Objektaktionen',
            end_alert: 'Sind Sie sicher, die neue Einführung in die Benutzeroberfläche zu beenden?',
            select_machine: 'Wählen Sie eine Maschine aus',
            start_work: 'Arbeit beginnen',
        },
        gesture: {
            pan: 'Scrollen mit 2 Fingern auf dem Bildschirm.',
            zoom: 'Ziehen Sie mit 2 Fingern zum Auszoomen auseinander, und ziehen Sie zum Einzoomen aufeinander zu.',
            click: 'Tippen Sie leicht das Objekt um zu wählen.',
            drag: 'Legen und ziehen mit einem Finger um mehrere Objekten zu wählen. ',
            hold: 'Finger lang auf einem Objekt legen um das Kontextmenü zu öffnen.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Das ist alles für das Tutorial. Jetzt ist es Zeit zu erstellen!',
    },
    layer_module: {
        none: 'Kein',
        general_laser: 'Laser',
        laser_10w_diode: '10W Diodenlaser',
        laser_20w_diode: '20W Diodenlaser',
        laser_2w_infrared: '2W Infrarotlaser',
        printing: 'Drucken',
        unknown: 'Unbekanntes Modul',
        notification: {
            convertFromPrintingModuleTitle: 'Möchten Sie das Druckmodul in ein Lasermodul umwandeln?',
            convertFromPrintingModuleMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Farbeinstellungen der Druckebene entfernt und entsprechend der aktuellen Ebene festgelegt werden.',
            convertFromLaserModuleTitle: 'Möchten Sie das Lasermodul in ein Druckmodul umwandeln?',
            convertFromLaserModuleMsg: 'Bitte beachten Sie, dass bei Abschluss dieser Operation die Einstellungen der Laser-Ebene entfernt und entsprechend der aktuellen Ebene festgelegt werden.',
            importedDocumentContainsPrinting: 'Das Dokument enthält Druckebenen. Möchten Sie den Arbeitsbereich auf Ador ändern?',
            printingLayersCoverted: 'Die Druckschichten wurden in Laserschichten umgewandelt.',
            performPrintingCaliTitle: 'Führen Sie die Kalibrierung des Druckmoduls durch',
            performPrintingCaliMsg: "Klicken Sie auf 'Bestätigen', um die Kalibrierung durchzuführen, oder greifen Sie über das Hauptmenü darauf zu.<br />(Maschine > [Ihr Maschinenname] > Druckmodul kalibrieren)",
            performIRCaliTitle: 'Führen Sie die Kalibrierung des Infrarotmoduls durch.',
            performIRCaliMsg: "Klicken Sie auf 'Bestätigen', um die Kalibrierung durchzuführen, oder greifen Sie über das Hauptmenü darauf zu.<br />(Maschine > [Ihr Maschinenname] > Infrarotmodul kalibrieren)",
        },
        non_working_area: 'Nicht-Arbeitsbereich',
    },
    qr_code_generator: {
        title: 'QR-Code-Generator',
        placeholder: 'Eingabe eines Links oder Textes',
        preview: 'Vorschau',
        error_tolerance: 'Fehlertoleranz',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Hintergrundfarbe umkehren',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Basic Box',
        coming_soon: 'Bald verfügbar',
        workarea: 'Arbeitsbereich',
        max_dimension_tooltip: 'Max Breite/Höhe/Tiefe Einstellung ist %s.',
        volume: 'Volumen',
        outer: 'Äußere',
        inner: 'Innere',
        width: 'Breite',
        height: 'Höhe',
        depth: 'Tiefe',
        cover: 'Abdeckung',
        thickness: 'Dicke',
        add_option: 'Option hinzufügen',
        joints: 'Verbindung',
        finger: 'Finger',
        finger_warning: 'Die innere Seitenlänge des Kastens muss mindestens 6 mm (0,24 Zoll) betragen, um mit dem Fingerfügen kompatibel zu sein.',
        tSlot: 'T-Nut',
        tSlot_warning: 'Die Kastenseitenlänge muss mindestens 30 mm (1,18 Zoll) betragen, um mit dem T-Schlitz-Fügen kompatibel zu sein.',
        edge: 'Kante',
        tCount: 'T Anzahl',
        tCount_tooltip: 'Die T Anzahl bezieht sich auf die kurze Seite; die Menge auf der langen Seite wird anhand ihrer Länge berechnet.',
        tDiameter: 'T Durchmesser',
        tLength: 'T Länge',
        continue_import: 'Weiter importieren',
        customize: 'Anpassen',
        merge: 'Zusammenführen',
        text_label: 'Textbeschriftung',
        beam_radius: 'Strahlradius',
        beam_radius_warning: 'Entfernen Sie die Kerfkompensation, wenn die Kanten oder Verbindungen der Box kurz sind, um die Montage der Box sicherzustellen',
        import: 'Importieren',
        cancel: 'Abbrechen',
        reset: 'Zurücksetzen',
        zoom: 'Vergrößern',
        control_tooltip: 'Linke Maustaste zum Drehen\nScrollen zum Zoomen\nRechte Maustaste zum Schwenken',
        control_tooltip_touch: 'Ziehen zum Drehen\nZusammenziehen zum Zoomen\nZwei-Finger zum Schwenken',
    },
    my_cloud: {
        title: 'Meine Cloud',
        loading_file: 'Lädt...',
        no_file_title: 'Speichern Sie Dateien in Meine Cloud, um loszulegen.',
        no_file_subtitle: 'Gehen Sie zum Menü > "Datei" > "In die Cloud speichern"',
        file_limit: 'Kostenlose Datei',
        upgrade: 'Aktualisieren',
        sort: {
            most_recent: 'Neueste',
            oldest: 'Älteste',
            a_to_z: 'Name: A - Z',
            z_to_a: 'Name: Z - A',
        },
        action: {
            open: 'Öffnen',
            rename: 'Umbenennen',
            duplicate: 'Duplizieren',
            download: 'Herunterladen',
            delete: 'Löschen',
            confirmFileDelete: 'Möchten Sie diese Datei wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
        },
        save_file: {
            choose_action: 'Datei speichern:',
            save: 'Speichern',
            save_new: 'Als neue Datei speichern',
            input_file_name: 'Dateinamen eingeben:',
            invalid_char: 'Ungültige Zeichen:',
            storage_limit_exceeded: 'Ihr Cloudspeicher hat das obere Limit erreicht. Bitte löschen Sie vor dem Speichern neuer Dateien alle nicht benötigten Dateien.',
        },
    },
    camera_data_backup: {
        title: 'Kameradaten sichern',
        no_picture_found: 'Kein Bild in der Maschine gefunden.',
        folder_not_exists: 'Ausgewählter Ordner existiert nicht.',
        incorrect_folder: 'Fehler beim Hochladen von Kalibrierungsdaten. Bitte überprüfen Sie, ob der ausgewählte Ordner korrekt ist.',
        downloading_data: 'Daten werden heruntergeladen...',
        estimated_time_left: 'Geschätzte verbleibende Zeit:',
        uploading_data: 'Daten werden hochgeladen...',
        download_success: 'Kameradaten erfolgreich heruntergeladen.',
        upload_success: 'Kameradaten erfolgreich hochgeladen.',
    },
    insecure_websocket: {
        extension_detected: 'Beam Studio Connect-Erweiterung erkannt',
        extension_detected_description: "Wir haben festgestellt, dass Sie die Beam Studio Connect-Erweiterung installiert haben. Bitte klicken Sie auf 'Bestätige', um zu HTTPS umzuleiten, oder klicken Sie auf 'Abbrechen', um weiterhin HTTP zu verwenden.",
        extension_not_deteced: 'Beam Studio Connect-Erweiterung konnte nicht erkannt werden',
        extension_not_deteced_description: "Um HTTPS zu verwenden, klicken Sie bitte auf 'Bestätigen', um die Beam Studio Connect-Erweiterung zu installieren. Nach der Installation der Erweiterung aktualisieren Sie bitte die Seite, um sie zu aktivieren.<br/>Andernfalls klicken Sie auf den unten stehenden Link, um zu sehen, wie Sie HTTP in Chrome verwenden können.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Link</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Autofokusbereich Messen',
        amount: 'Menge',
        gap: 'Lücke',
        rows: 'Reihen',
        coloumns: 'Spalten',
        row_gap: 'Reihenabstand',
        column_gap: 'Spaltenabstand',
        set_object_height: 'Objekt-Höhe einstellen',
        set_object_height_desc: 'Messen Sie die maximale Dicke des Objekts.',
        measure_area_help: 'Wie wählt man den Autofokusbereich für 3D-Kurve?',
        reselect_area: 'Bereich erneut auswählen',
        start_autofocus: 'Autofokus starten',
        starting_measurement: 'Messung starten...',
        preview_3d_curve: '3D-Kurve Vorschau',
        apply_arkwork: 'Kunstwerk auf 3D-Kurve anwenden',
        apply_camera: 'Kamerabild auf 3D-Kurve anwenden',
        click_to_select_point: 'Klicken, um Punkte zum erneuten Messen auszuwählen oder abzuwählen.',
        remeasure: 'Erneut messen',
        remeasuring_points: 'Punkte erneut messen...',
    },
    pass_through: {
        title: 'Durchgangsmodus',
    },
};
export default lang;
