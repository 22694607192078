const lang = {
    general: {
        wait: '處理中，請稍待',
        choose_folder: '選擇檔案夾',
    },
    buttons: {
        next: '下一步',
        back: '上一步',
        done: '完成',
        back_to_beam_studio: '回到 Beam Studio',
    },
    topbar: {
        untitled: '無標題',
        titles: {
            settings: '偏好設定',
        },
        export: 'GO',
        preview: '相機預覽',
        preview_title: '相機預覽',
        curve_engrave: '3D 曲面',
        task_preview: '工作預覽',
        frame_task: '外框預覽',
        borderless: '(開蓋模式)',
        tag_names: {
            rect: '矩形',
            ellipse: '橢圓',
            path: '路徑',
            polygon: '多邊形',
            image: '影像',
            text: '文字',
            text_path: '路徑文字',
            line: '線段',
            g: '群組',
            multi_select: '多個物件',
            use: '匯入物件',
            svg: 'SVG 物件',
            dxf: 'DXF 物件',
            no_selection: '無選取物件',
        },
        alerts: {
            start_preview_timeout: '#803 啟動相機預覽時超時，請重新開啟您的機器或是 Beam Studio ，如果此錯誤持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此則引導</a>。',
            fail_to_start_preview: '#803 啟動相機預覽失敗，請重新開啟您的機器或是 Beam Studio ，如果此錯誤持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此則引導</a>。',
            fail_to_connect_with_camera: '#803 與機器相機建立連線時失敗，請重新開啟您的機器或是 Beam Studio ，如果此錯誤持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/360001111355">此則引導</a>。',
            add_content_first: '請先新增物件',
            headtype_mismatch: '偵測到不相符的模組，',
            headtype_none: '未偵測到模組，',
            headtype_unknown: '偵測到未知模組，',
            install_correct_headtype: '請正確安裝10W或20W雷射模組以進行弱出光。',
            door_opened: '請將門蓋關閉以進行弱出光。',
            fail_to_get_door_status: '請確保門蓋關閉以進行弱出光。',
            QcleanScene: '您確定清除場景嗎？<br/>這同時也會刪除您的操作歷史記錄。',
            power_too_high: '功率過高',
            power_too_high_msg: '雷射管在高功率（70％ 以上）下耗損較快，使用低功率可以延長雷試管使用壽命。\n輸入「知道了」以繼續。',
            power_too_high_confirm: '知道了',
        },
        hint: {
            polygon: '按下 + / - 鍵以增加 / 減少邊數。',
        },
        menu: {
            preferences: '偏好設定',
            hide: '隱藏 Beam Studio',
            hideothers: '隱藏其他',
            service: '服務',
            quit: '離開',
            window: '視窗',
            minimize: '最小化',
            close: '關閉視窗',
            file: '檔案',
            edit: '編輯',
            help: '說明',
            open: '打開',
            samples: '範例',
            import_hello_beamo: 'beamo 範例',
            import_hello_beambox: 'Beambox 範例',
            import_material_testing_old: '材質雕刻測試 - 經典',
            import_material_testing_simple_cut: '材質切割測試 - 簡易',
            import_material_testing_cut: '材質切割測試',
            import_material_testing_engrave: '材質雕刻測試',
            import_material_testing_line: '材質線段測試',
            import_material_printing_test: '材質列印測試',
            import_ador_laser_example: 'Ador 雷射範例',
            import_ador_printing_example_single: 'Ador 列印範例 - 單色',
            import_ador_printing_example_full: 'Ador 印刷範例 - 彩色',
            import_acrylic_focus_probe: '壓克力對焦尺- 3mm',
            export_to: '匯出成...',
            export_flux_task: 'FLUX 工作',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: '儲存',
            save_as: '另存新檔',
            save_to_cloud: '儲存到雲端',
            about_beam_studio: '關於 Beam Studio',
            undo: '復原',
            redo: '重做',
            cut: '剪下',
            copy: '複製',
            paste: '貼上',
            paste_in_place: '原地貼上',
            group: '群組化',
            ungroup: '解散群組',
            delete: '刪除',
            duplicate: '重製',
            offset: '位移複製',
            scale: '縮放',
            rotate: '旋轉',
            reset: '重設',
            align_center: '置中',
            photo_edit: '影像',
            svg_edit: 'SVG',
            path: '路徑',
            decompose_path: '解散非連續路徑',
            object: '物件',
            layer_setting: '圖層',
            layer_color_config: '顏色設定',
            image_sharpen: '鋭化',
            image_crop: '裁剪',
            image_invert: '色彩反轉',
            image_stamp: '生成印章斜角',
            image_vectorize: '向量化',
            image_curve: '曲線',
            align_to_edges: '貼齊端點',
            document_setting: '文件設定',
            document_setting_short: '文件設定',
            clear_scene: '新增檔案',
            machines: '機器',
            add_new_machine: '新增或設定機器',
            help_center: '說明中心',
            show_gesture_tutorial: '觸控手勢說明',
            show_start_tutorial: '顯示新手教學',
            show_ui_intro: '顯示介面介紹',
            questionnaire: '問卷回饋',
            change_logs: '更新日誌',
            contact: '聯絡我們',
            tutorial: 'Delta Family 列印教學',
            design_market: 'Design Market',
            forum: '社群論壇',
            software_update: '軟體更新',
            bug_report: '錯誤回報',
            dashboard: '儀表版',
            machine_info: '機器資訊',
            network_testing: '檢測網路設定',
            commands: '指令',
            update_firmware: '韌體更新',
            using_beam_studio_api: '使用 Beam Studio API',
            set_as_default: '設為預設',
            calibrate_beambox_camera: '校正相機',
            calibrate_printer_module: '校正列印模組',
            calibrate_ir_module: '校正紅外線模組',
            calibrate_beambox_camera_borderless: '校正相機 (開蓋模組）',
            calibrate_diode_module: '校正二極體雷射模組',
            manage_account: '管理我的帳號',
            sign_in: '登入',
            sign_out: '登出',
            account: '帳號',
            my_account: '我的帳號',
            download_log: '匯出機器日誌',
            download_log_canceled: '取消日誌下載',
            download_log_error: '不明錯誤發生，請稍候再試一次',
            keyboard_shortcuts: '快捷鍵',
            log: {
                network: 'Network',
                hardware: 'Hardware',
                discover: 'Discover',
                usb: 'USB',
                usblist: 'USB List',
                camera: 'Camera',
                cloud: 'Cloud',
                player: 'Player',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/zh-tw/articles/10003978157455',
            },
            view: '檢視',
            zoom_in: '放大',
            zoom_out: '縮小',
            fit_to_window: '配合視窗尺寸',
            zoom_with_window: '自動配合視窗尺寸',
            borderless_mode: '開蓋模式',
            show_grids: '顯示格線',
            show_rulers: '顯示尺規',
            show_layer_color: '顯示圖層顏色',
            anti_aliasing: '反鋸齒',
            disassemble_use: '解散圖檔',
        },
        select_machine: '選擇機器',
    },
    support: {
        no_webgl: '您的系統不支援 WebGL，建議您使用其他電腦開啟 Beam Studio',
        no_vcredist: '請安裝 Visual C++ Redistributable 2015<br/>可以在flux3dp.com找到',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] 發生未知的錯誤，請重新啟動 Beam Studio 以及機器',
        OPERATION_ERROR: '[OE] 機器發生狀態衝突，請再試一次',
        SUBSYSTEM_ERROR: '[SE] 韌體執行雕刻程序錯誤，請嘗試重新啟動機器',
        UNKNOWN_COMMAND: '[UC] 請更新機器韌體',
    },
    device_selection: {
        no_beambox: '#801 我們在網路上找不到您的機器，<br/>此功能需要在與機器連線的狀態下才會運作，請先新增機器設定連線，<br/>請參考<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/360001683556">此指南</a>排除連線問題！',
        no_device_web: '#801 請檢查機器狀態，或者點選以下『新增或設定機器』，進行機器設定。',
    },
    update: {
        update: '更新',
        release_note: '版本訊息:',
        firmware: {
            caption: '有新的機器韌體更新',
            message_pattern_1: '"%s" 有新的韌體更新。',
            message_pattern_2: '%s 韌體 v%s 可使用 - 你的版本為 v%s.',
            latest_firmware: {
                caption: '韌體更新',
                message: '韌體已經是最新版本',
                still_update: '檔案更新',
                cant_get_latest: '無法取得最新版本韌體資訊。',
            },
            confirm: '上傳',
            upload_file: '韌體上傳',
            update_success: '韌體更新上傳成功',
            update_fail: '#822 更新失敗',
            too_old_for_web: '你韌體的版本為 v%s。<br/>欲使用線上版的 Beam Studio 請更新至最新韌體。',
            force_update_message: '#814 請將您的機器更新至最新的韌體版本。',
            firmware_too_old_update_by_sdcard: '韌體太舊，請使用 SD 卡更新韌體。',
        },
        software: {
            checking: '檢查更新中',
            switch_version: '版本切換',
            check_update: '檢查更新',
            caption: 'Beam Studio 有新的軟體更新',
            downloading: '正在背景下載中，您可以按確定以繼續您的工作。',
            install_or_not: '已準備好更新，是否重新啟動以套用更新？',
            switch_or_not: '已準備完成，是否重新啟動以切換？',
            available_update: 'Beam Studio v%s 現可提供下載，您的版本為 v%s，是否要下載更新？',
            available_switch: 'Beam Studio v%s 現可提供切換，您的版本為 v%s，是否要切換至此此版本？',
            not_found: 'Beam Studio 已是最新版本。',
            no_response: '無法連接到伺服器，請確認您目前的網路狀態。',
            switch_version_not_found: '無法找到可切換的版本',
            yes: '是',
            no: '否',
            update_for_ador: '目前軟體版本 %s 較舊，請下載最新版 Beam Studio for Ador 。',
        },
        updating: '更新中...',
        skip: '跳過此版本',
        preparing: '準備中...',
        later: '稍後',
        download: '線上更新',
        cannot_reach_internet: '#823 伺服器無法連接<br/>請確認網路連線',
        install: '下載',
        upload: '上傳',
    },
    topmenu: {
        version: '版本',
        credit: 'Beam Studio 的開發仰賴 <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> 開放原始碼計畫與其他<a target="_blank" href="https://flux3dp.com/credits/">開放原始碼的軟體</a>的協助才得以完成。',
        ok: '確定',
        file: {
            label: '檔案',
            import: '匯入',
            save_fcode: '匯出工作',
            save_scene: '匯出場景',
            save_svg: '匯出 SVG',
            save_png: '匯出 PNG',
            save_jpg: '匯出 JPG',
            converting: '轉換成圖片中...',
            all_files: '所有檔案',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio 場景',
            fcode_files: 'FLUX Code',
            clear_recent: '清除歷史紀錄',
            path_not_exit: '此路徑似乎已不存在於電腦中，請確認是否有更改檔案位置。',
        },
        device: {
            download_log_canceled: '取消日誌下載',
            download_log_error: '不明錯誤發生，請稍候再試一次',
            log: {
                usblist: 'USB 清單',
            },
            network_test: '網路檢測',
        },
    },
    initialize: {
        // generic strings
        next: '下一步',
        start: '開始使用',
        skip: '跳過',
        cancel: '取消',
        confirm: '確認',
        connect: '連接',
        back: '返回',
        retry: '重試',
        no_machine: '目前沒有機器或已設定過連線，跳過此步驟',
        // specific caption/content
        select_language: '請選擇你想使用的語言',
        select_machine_type: '請選擇您的機種',
        select_connection_type: '請選擇您的連接方式',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: '有線網路',
            ether2ether: '網路線直連',
            usb: 'USB 連線',
        },
        connect_wifi: {
            title: '連接 Wi-Fi',
            tutorial1: '1. 到機器控制面板 > 點擊「網路」 > 「設定 Wi-Fi」。',
            tutorial1_ador: '1. 到機器控制面板 > 點擊「機器」 > 點擊「網路」 > 「設定 Wi-Fi」。',
            tutorial2: '2. 選擇並連接您想使用的 Wi-Fi 。',
            what_if_1: '機器找不到我想使用的 Wi-Fi',
            what_if_1_content: '1. Wi-Fi 加密方式需為 WPA2 或無密碼。\n2. 加密方式可由 Wi-Fi 路由器設定，如果路由器不支援 WPA2，可聯繫客服購買，如果不確定路由器是否支援，可以將型號傳給客服詢問。',
            what_if_2: '機器找不到任何 Wi-Fi',
            what_if_2_content: '1. 確認您的 Wi-Fi 接收器是否有著實插上。\n2. 如果面板上沒有出現無線網路硬體位置，請聯繫客服。\n3. Wi-Fi 頻道為 2.4Ghz (不支援 5Ghz)。',
        },
        connect_wired: {
            title: '連接有線網路',
            tutorial1: '1. 請將您的機器以乙太網路線與路由器連接。',
            tutorial2: '2. 在觸控面板點擊「網路」以獲得有線網路 IP 。',
            tutorial2_ador: '',
            what_if_1: '機器顯示的有線網路 IP 是空的',
            what_if_1_content: '1. 確認您的乙太網路線是否有著實插上。\n2. 如果面板上沒有出現有線網路硬體位置，請聯繫客服。',
            what_if_2: '機器顯示的 IP 開頭為 169',
            what_if_2_content: '1. IP 地址為 169.254 開頭通常為 DHCP 設定問題，需要聯繫網路服務提供商或是網路設定者來協助。\n2. 如果工作環境的網路是由電腦直接 PPPoE 連網，請改由路由器直接 PPPoE 聯網，並在路由器中開啟DHCP 功能。',
        },
        connect_ethernet: {
            title: '網路線直連',
            tutorial1: '1. 將您的機器與您的電腦以乙太網路線連接。',
            tutorial2_1: '2. 依照',
            tutorial2_a_text: '這篇文章',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/zh-tw/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/zh-tw/articles/360001507715',
            tutorial2_2: '使您的電腦同時扮演路由器的角色。',
            tutorial3: '3. 點選 下一步。',
        },
        connect_usb: {
            title: 'USB 連線',
            title_sub: '（僅支援 HEXA & Ador）',
            tutorial1: '將您的機器與您的電腦以 USB 線連接。',
            tutorial2: '點選 下一步。',
            turn_off_machine: '將您的機器關機',
            turn_on_machine: '將您的機器開機',
            wait_for_turning_on: '待開機程序完成進入主畫面後，點選 下一步',
        },
        connect_machine_ip: {
            check_usb: '確認 USB 連線',
            enter_ip: '請輸入您的機器 IP',
            check_ip: '確認 IP',
            invalid_ip: 'IP 錯誤：',
            invalid_format: '格式不符合',
            starts_with_169254: '由 169.254 開頭',
            unreachable: '無法連接至指定 IP',
            check_connection: '確認機器連線',
            check_firmware: '確認韌體版本',
            check_camera: '確認相機',
            retry: '重試',
            succeeded_message: '連線成功 🎉',
            finish_setting: '開始創作',
        },
        connecting: '連接中',
        setting_completed: {
            start: '開始使用',
            great: '歡迎使用 Beam Studio',
            setup_later: '您可以隨時從選單 >「機器」>「新增或設定機器」來設定連線。',
            back: '回到 Wi-Fi 設定',
            ok: '開始使用',
        },
    },
    error_pages: {
        screen_size: '請注意，Beam Studio 無法在您的裝置上順利執行。若要獲得最佳使用體驗，裝置螢幕寬度至少應有 1024 像素。',
    },
    menu: {
        mm: '毫米',
        inches: '英吋',
    },
    settings: {
        on: '開',
        off: '關',
        low: '低',
        high: '正常',
        caption: '設定',
        tabs: {
            general: '一般',
            device: '機器',
        },
        ip: '機器 IP 位址',
        guess_poke: '自動搜尋機器 IP',
        auto_connect: '自動選擇唯一機器',
        wrong_ip_format: 'IP格式錯誤',
        reset: '重置所有設定',
        default_machine: '預設機器',
        default_machine_button: '無',
        remove_default_machine_button: '刪除',
        confirm_remove_default: '將會刪除預設機器',
        reset_now: '重置所有設定',
        confirm_reset: '確認要重置 Beam Studio?',
        language: '語言',
        notifications: '通知',
        check_updates: '自動檢查',
        autosave_enabled: '自動儲存',
        autosave_path: '檔案夾',
        autosave_interval: '儲存間隔',
        autosave_number: '自動儲存數',
        autosave_path_not_correct: '找不到指定的路徑',
        preview_movement_speed: '相機預覽移動速度',
        preview_movement_speed_hl: '相機預覽移動速度（二極體雷射啟用時）',
        default_units: '預設單位',
        default_font_family: '預設字體',
        default_font_style: '預設字型',
        fast_gradient: '速度優化',
        engraving_direction: '雕刻方向',
        top_down: '由上往下',
        bottom_up: '由下往上',
        vector_speed_constraint: '限制上限速度 (20 mm/s)',
        loop_compensation: '封閉路徑補償',
        blade_radius: '旋轉刀半徑',
        blade_precut_switch: '旋轉刀預切',
        blade_precut_position: '預切位置',
        default_beambox_model: '預設文件設定',
        guides_origin: '參考線座標',
        guides: '參考線',
        image_downsampling: '點陣圖預覽品質',
        anti_aliasing: '反鋸齒',
        continuous_drawing: '連續繪製',
        trace_output: '向量化及影像描圖輸出',
        single_object: '單一物件',
        grouped_objects: '多個物件群組',
        simplify_clipper_path: '路徑計算優化',
        enable_low_speed: '啟用慢速移動',
        enable_custom_backlash: '啟用自訂背隙補償',
        custom_preview_height: '自訂預覽高度',
        mask: '工作範圍剪裁',
        text_path_calc_optimization: '路徑計算優化',
        font_substitute: '自動替換字體',
        font_convert: '文字轉路徑',
        default_borderless_mode: '開蓋模式預設',
        default_enable_autofocus_module: '自動對焦預設',
        default_enable_diode_module: '二極體雷射預設',
        diode_offset: '二極體雷射偏移值',
        autofocus_offset: '自動對焦高度補償',
        diode_one_way_engraving: '二極體雷射單向雕刻',
        diode_two_way_warning: '雙向出光，速度較快時可能導致雕刻時位置誤差，建議預先使用材料進行測試。',
        share_with_flux: '分享 Beam Studio 分析',
        none: '無',
        close: '關閉',
        enabled: '啟用',
        disabled: '不啟用',
        cancel: '取消',
        done: '套用',
        module_offset_10w: '10W 二極體雷射偏移',
        module_offset_20w: '20W 二極體雷射偏移',
        module_offset_printer: '列印模組偏移',
        module_offset_2w_ir: '2W 紅外線雷射偏移',
        printer_advanced_mode: '列印進階模式',
        default_laser_module: '預設雷射模組',
        low_laser_for_preview: '外框預覽弱出光',
        groups: {
            general: '一般',
            update: '軟體更新',
            connection: '連線',
            autosave: '自動儲存',
            camera: '相機',
            editor: '編輯器',
            path: '路徑 (線段)',
            engraving: '雕刻 (掃描)',
            mask: '工作範圍剪裁',
            text_to_path: '文字',
            modules: '擴充模組',
            ador_modules: 'Ador 模组',
            privacy: '隱私權',
        },
        notification_on: '開啟',
        notification_off: '關閉',
        update_latest: '穩定版',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/zh-tw/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/zh-tw/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/zh-tw/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/zh-tw/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/zh-tw/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/zh-tw/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/zh-tw/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/zh-tw/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/zh-tw/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/zh-tw/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/zh-tw/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/zh-tw/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/zh-tw/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/zh-tw/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: '群組',
            use: '匯入圖檔',
            image: '圖片',
            text: '文字',
        },
        context_menu: {
            cut: '剪下',
            copy: '複製',
            paste: '貼上',
            paste_in_place: '原地貼上',
            duplicate: '重製',
            delete: '刪除',
            group: '群組',
            ungroup: '解散群組',
            move_front: '移至最前',
            move_up: '往前移',
            move_down: '往後移',
            move_back: '移至最後',
        },
        popup: {
            select_import_method: '選擇分層方式:',
            select_import_module: '選擇模組:',
            touchpad: '觸控板',
            mouse: '滑鼠',
            layer_by_layer: '依圖層分層',
            layer_by_color: '依顏色分層',
            nolayer: '不分層',
            loading_image: '載入圖片中，請稍候...',
            no_support_text: 'Beam Studio 目前不支援由外部匯入文字標籤，請由向量繪圖軟體將文字轉成路徑後再匯入。',
            speed_too_high_lower_the_quality: '在此雕刻解析度使用過快的速度，可能導致漸層雕刻的品質較差。',
            too_fast_for_path: '含有路徑物件的圖層速度過快，可能導致切割時位置誤差。<br/>不建議超過在切割路徑時超過 20mm/s 。',
            too_fast_for_path_and_constrain: '以下圖層： <strong>%s</strong><br/>含有向量路徑物件且速度超過 20mm/s，為維持雕刻的精度，向量路徑速度將被限制在 20mm/s ，您可以在偏好設定解除此限制。',
            both_power_and_speed_too_high: '雷射管在高功率下耗損較快，使用低功率可以延長雷試管使用壽命。<br/>並且在此雕刻解析度使用過快的速度可能導致漸層雕刻的品質較差。',
            should_update_firmware_to_continue: '#814 您的韌體版本不支援最新的軟體改善。為了更良好的使用經驗與雕刻品質，請先更新 Beambox 的韌體以繼續。 (主選單 > 機器 > [ Your Beambox ] > 韌體更新)',
            recommend_downgrade_software: '偵測到較舊的韌體版本，我們仍然在積極解決相容問題，但現在建議您先退回 <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>',
            recommend_upgrade_firmware: '我們偵測到較舊的韌體版本。我們正在積極解決相容問題，但現在建議您先更新至最新的韌體。',
            still_continue: '繼續',
            more_than_two_object: '太多物件，只支援兩物件操作',
            not_support_object_type: '不支援的物件類型',
            select_first: '請先選取物件以繼續',
            select_at_least_two: '請選取兩個物件以繼續',
            import_file_contain_invalid_path: '#808 匯入的SVG檔案中含有不存在的圖片路徑，請確認檔案中所有連結之圖片皆存在，或改將圖片嵌入檔案中。',
            import_file_error_ask_for_upload: '讀取 SVG 檔時發生錯誤，是否願意上傳檔案回報錯誤給開發團隊？',
            upload_file_too_large: '#819 檔案大小過大，請聯絡客服。',
            successfully_uploaded: '檔案已成功上傳。',
            upload_failed: '#819 檔案上傳失敗。',
            or_turn_off_borderless_mode: '或是關閉開蓋模式',
            svg_1_1_waring: '此檔案標示之 SVG 版本為 1.1 版，可能有潛在的不相容風險。',
            svg_image_path_waring: '此檔案內含有以路徑讀取的圖片，可能會有讀取失敗的風險。請在做圖匯出 SVG 時，當點陣圖相關選項改成嵌入。',
            dxf_version_waring: '此 DXF 檔版本非 2013 版，可能有潛在的不相容風險。',
            dont_show_again: '不再顯示此提醒',
            convert_to_path_fail: '轉換成路徑失敗。',
            save_unsave_changed: '請問是否要儲存未儲存的變更，否則變更將會遺失？',
            dxf_bounding_box_size_over: '圖像超出工作範圍，請在 CAD 軟體中將圖像放置於原點附近，或確定圖檔單位是否正確設定。',
            progress: {
                uploading: '上傳中',
                calculating: '計算中',
            },
            backend_connect_failed_ask_to_upload: '#802 連接後端程式時持續發生錯誤，請問您是否要將錯誤報告上傳到雲端?',
            backend_error_hint: '後端程式無法連接，部分功能可能無法使用。',
            pdf2svg: {
                error_when_converting_pdf: '#824 將 PDF 轉換成 SVG 時發生錯誤：',
                error_pdf2svg_not_found: '#825 無法找到 pdf2svg 指令，請使用您的套件管理裝置安裝 pdf2svg（e.g., "yum install pdf2svg" or "apt-get install pdf2svg"）。',
            },
            ungroup_use: '正要解散外部匯入的 DXF 或是 SVG ，在含有物件較多的情況，可能會需要等一陣子，是否確定解散？',
            vectorize_shading_image: '漸層影像在向量化時將花費較多時間，且容易有雜點，請將影像漸層關閉後再執行。',
            change_workarea_before_preview: '%s 的工作範圍與目前設定的工作範圍不相符，是否要切換目前的工作範圍？',
            bug_report: '錯誤回報',
            sentry: {
                title: '我們一起讓 Beam Studio 變得更好',
                message: '請問您是否同意在遇到錯誤時將相關資訊自動上傳給開發團隊？',
            },
            questionnaire: {
                caption: '協助我們填寫問券',
                message: '協助我們填寫問券，讓產品變得更好。',
                unable_to_get_url: '無法透過網路取得目前最新問卷的連結，請確認您的網路連線狀況。',
                no_questionnaire_available: '目前沒有可供填寫的問卷。',
            },
            facebook_group_invitation: {
                title: '加入 FLUX 官方用戶社群',
                message: '即刻加入 FLUX 官方用戶社群，與廣大 FLUX 用戶分享使用心得、展示雷雕作品以及獲取最新的產品資訊。',
                join_now: '馬上加入',
                later: '稍後加入',
                already_joined: '已加入用戶社群',
                dont_show_again: '不要再提醒我',
            },
            ai_credit: {
                relogin_to_use: '請重新登入以使用此功能。',
                insufficient_credit: '您的 Credit 餘額不足',
                insufficient_credit_msg: '您無法使用%s，請前往會員中心購買 AI Credit。',
                buy_link: 'https://member.flux3dp.com/zh-TW/credit',
                go: '前往',
            },
            text_to_path: {
                caption: '文字轉路徑 2.0',
                message: 'Beam Studio 現在推出全新的文字轉路徑 2.0，為您帶來更穩定的使用體驗！您是否要立即切換？\n您也可以稍後於偏好設定中的「文字轉路徑」修改此設定。',
            },
        },
        zoom_block: {
            fit_to_window: '配合視窗尺寸',
        },
        time_est_button: {
            calculate: '計算時間',
            estimate_time: '預計所需時間：',
        },
        left_panel: {
            unpreviewable_area: '非相機預覽範圍',
            diode_blind_area: '非雕刻範圍',
            borderless_blind_area: '非雕刻範圍',
            borderless_preview: '開蓋模式相機預覽',
            rectangle: '長方形',
            ellipse: '橢圓形',
            line: '線段',
            image: '圖片',
            text: '文字',
            label: {
                cursor: '選取',
                photo: '圖片',
                text: '文字',
                line: '線段',
                rect: '方塊',
                oval: '橢圓',
                polygon: '多邊形',
                pen: '鋼筆',
                shapes: '元素',
                array: '陣列',
                preview: '相機預覽',
                trace: '影像描圖',
                end_preview: '結束預覽',
                clear_preview: '清除預覽',
                choose_camera: '相機預覽',
                live_feed: '即時視訊',
                adjust_height: '調整高度',
                qr_code: 'QR 碼',
                boxgen: 'Boxgen',
                my_cloud: '我的雲端',
                curve_engraving: {
                    title: '3D 曲面',
                    exit: '退出',
                    select_area: '選擇區域',
                    preview_3d_curve: '預覽 3D 曲面',
                    clear_area: '清除選取區域',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: '圖層',
                objects: '物件',
                path_edit: '路徑編輯',
            },
            layer_panel: {
                layer1: '預設圖層',
                layer_bitmap: '點陣圖層',
                layer_engraving: '雕刻圖層',
                layer_cutting: '切割圖層',
                current_layer: '目前圖層',
                move_elems_to: '移動到：',
                notification: {
                    dupeLayerName: '圖層名稱與現有圖層重複，請使用別的名稱。',
                    newName: '新圖層名稱',
                    enterUniqueLayerName: '請輸入一個不重複的名稱',
                    enterNewLayerName: '請輸入新圖層的名稱',
                    layerHasThatName: '圖層本來就是這個名稱',
                    QmoveElemsToLayer: "要搬移所選取的物件到'%s'嗎?",
                    moveElemFromPrintingLayerTitle: '您是否要移動選取物件至 %s 並轉換為雷射物件？',
                    moveElemFromPrintingLayerMsg: '請注意，如果您繼續此操作，選取物件的顏色設定將會被刪除，並根據 %s 參數而定。',
                    moveElemToPrintingLayerTitle: '您是否要移動選取物件至 %s 並轉換為列印物件？',
                    moveElemToPrintingLayerMsg: '請注意，如果您繼續此操作，選取物件的設定將會被刪除，並根據 %s 參數而定。',
                    splitColorTitle: '您是否要將所選圖層展開為 CMYK 圖層？',
                    splitColorMsg: '請注意，如果您完成此操作，展開的圖層將無法重新合併。',
                    mergeLaserLayerToPrintingLayerTitle: '您是否要將多個圖層合併為一個列印圖層？',
                    mergeLaserLayerToPrintingLayerMsg: '請注意，如果您繼續此操作，雷射圖層的設定將會被刪除，並根據當前圖層參數而定。',
                    mergePrintingLayerToLaserLayerTitle: '您是否要將多個圖層合併為一個雷射圖層？',
                    mergePrintingLayerToLaserLayerMsg: '請注意，如果您繼續此操作，列印圖層的顏色設定將會被刪除，並根據當前圖層參數而定。',
                },
                layers: {
                    layer: '圖層',
                    layers: 'Layers',
                    del: '刪除圖層',
                    move_down: '向下移動圖層',
                    new: '新增圖層',
                    rename: '重新命名圖層',
                    move_up: '向上移動圖層',
                    dupe: '複製圖層',
                    lock: '鎖定圖層',
                    unlock: '解鎖',
                    merge_down: '向下合併圖層',
                    merge_all: '合併全部圖層',
                    merge_selected: '合併選取圖層',
                    move_elems_to: '移動物件',
                    move_selected: '移動被點選的物件其他圖層',
                    switchToFullColor: '切換成彩色圖層',
                    switchToSingleColor: '切換成單色圖層',
                    splitFullColor: '展開圖層',
                    fullColor: '全彩',
                },
            },
            laser_panel: {
                preset_setting: '參數調整（%s）',
                multi_layer: '多個圖層',
                parameters: '選擇參數',
                strength: '功率',
                low_power_warning: '功率較低時（低於 10 %），有可能不出光。',
                speed: '速度',
                speed_contrain_warning: '向量路徑速度將被限制在 20mm/s (0.79in/s)，您可以在偏好設定解除此限制。',
                low_speed_warning: '低速下可能會導致材料燃燒。',
                repeat: '執行次數',
                add_on: '擴充模組',
                focus_adjustment: '對焦調整',
                height: '物件高度',
                z_step: '每次遞降',
                diode: '二極體雷射',
                backlash: '背隙調整',
                ink_saturation: '飽和度',
                print_multipass: '多重列印',
                white_ink: '白墨',
                white_ink_settings: '白墨設定',
                color_adjustment: '色版調整',
                color_adjustment_short: '色版',
                halftone: '半色調',
                halftone_link: 'https://support.flux3dp.com/hc/zh-tw/articles/9402670389647',
                color_strength: '強度',
                times: '次',
                cut: '切割',
                engrave: '雕刻',
                more: '管理',
                delete: '刪除',
                reset: '恢復預設',
                sure_to_reset: '這將會重置所有的預設參數，並保留您的自訂參數，確定要繼續進行嗎？',
                apply: '套用',
                cancel: '取消',
                save: '儲存參數',
                save_and_exit: '儲存並離開',
                name: '名稱',
                default: '預設',
                customized: '自訂參數清單',
                inuse: '使用中',
                export_config: '匯出參數',
                new_config_name: '新參數名稱',
                sure_to_load_config: '將要讀取預設參數的排序與使用狀況，並覆蓋所有同名的自訂參數，確定要繼續進行嗎？',
                custom_preset: '自訂',
                various_preset: '多個參數',
                module: '模組',
                slider: {
                    regular: '普通',
                    low: '低',
                    very_low: '最低',
                    high: '高',
                    very_high: '最高',
                    slow: '慢',
                    very_slow: '最慢',
                    fast: '快',
                    very_fast: '最快',
                },
                dropdown: {
                    parameters: '選擇參數',
                    save: '新增目前參數',
                    export: '匯出參數',
                    import: '匯入參數',
                    more: '管理',
                    mm: {
                        wood_3mm_cutting: '木板 - 3mm 切割',
                        wood_5mm_cutting: '木板 - 5mm 切割',
                        wood_7mm_cutting: '木板 - 7mm 切割',
                        wood_8mm_cutting: '木板 - 8mm 切割',
                        wood_10mm_cutting: '木板 - 10mm 切割',
                        wood_engraving: '木板 - 刻印',
                        acrylic_3mm_cutting: '壓克力 - 3mm 切割',
                        acrylic_5mm_cutting: '壓克力 - 5mm 切割',
                        acrylic_8mm_cutting: '壓克力 - 8mm 切割',
                        acrylic_10mm_cutting: '壓克力 - 10mm 切割',
                        acrylic_engraving: '壓克力 - 刻印',
                        mdf_3mm_cutting: '紐西蘭密迪板 - 3mm 切割',
                        mdf_5mm_cutting: '紐西蘭密迪板 - 5mm 切割',
                        mdf_engraving: '紐西蘭密迪板 - 刻印',
                        leather_3mm_cutting: '皮革 - 3mm 切割',
                        leather_5mm_cutting: '皮革 - 5mm 切割',
                        leather_engraving: '皮革 - 刻印',
                        denim_1mm_cutting: '牛仔布 - 1mm 切割',
                        fabric_3mm_cutting: '布料 - 3mm 切割',
                        fabric_5mm_cutting: '布料 - 5mm 切割',
                        fabric_engraving: '布料 - 刻印',
                        rubber_bw_engraving: '印章墊 - 刻印',
                        glass_bw_engraving: '玻璃 - 刻印',
                        metal_bw_engraving: '不鏽鋼 - 刻印',
                        steel_engraving_spray_engraving: '不鏽鋼噴劑 - 刻印',
                        stainless_steel_bw_engraving_diode: '不鏽鋼 - 刻印（二極體雷射）',
                        gold_engraving: '金 - 刻印',
                        brass_engraving: '黃銅 - 刻印',
                        ti_engraving: '鈦 - 刻印',
                        stainless_steel_engraving: '不鏽鋼 - 刻印',
                        aluminum_engraving: '鋁 - 刻印',
                        black_acrylic_3mm_cutting: '黑色壓克力 - 3mm 切割',
                        black_acrylic_5mm_cutting: '黑色壓克力 - 5mm 切割',
                        black_acrylic_engraving: '黑色壓克力 - 刻印',
                        abs_engraving: 'ABS - 刻印',
                        silver_engraving: '銀 - 刻印',
                        iron_engraving: '鐵 - 刻印',
                        fabric_printing: '布料 - 列印',
                        canvas_printing: '帆布 - 列印',
                        cardstock_printing: '卡紙 - 列印',
                        wood_printing: '木材 - 列印',
                        bamboo_printing: '竹子 - 列印',
                        cork_printing: '軟木 - 列印',
                        flat_stone_printing: '石板 - 列印',
                        acrylic_printing: '壓克力 - 列印',
                        pc_printing: 'PC - 列印',
                        stainless_steel_printing: '不鏽鋼 - 列印',
                        gloss_leather_printing: '亮面皮革 - 列印',
                        glass_printing: '玻璃 - 列印',
                    },
                    inches: {
                        wood_3mm_cutting: "木板 - 0.1'' 切割",
                        wood_5mm_cutting: "木板 - 0.2'' 切割",
                        wood_7mm_cutting: "木板 - 0.3'' 切割",
                        wood_8mm_cutting: "木板 - 0.3'' 切割",
                        wood_10mm_cutting: "木板 - 0.4'' 切割",
                        wood_engraving: '木板 - 刻印',
                        acrylic_3mm_cutting: "壓克力 - 0.1'' 切割",
                        acrylic_5mm_cutting: "壓克力 - 0.2'' 切割",
                        acrylic_8mm_cutting: "壓克力 - 0.3'' 切割",
                        acrylic_10mm_cutting: "壓克力 - 0.4'' 切割",
                        acrylic_engraving: '壓克力 - 刻印',
                        mdf_3mm_cutting: "紐西蘭密迪板 - 0.1'' 切割",
                        mdf_5mm_cutting: "紐西蘭密迪板 - 0.2'' 切割",
                        mdf_engraving: '紐西蘭密迪板 - 刻印',
                        leather_3mm_cutting: "皮革 - 0.1'' 切割",
                        leather_5mm_cutting: "皮革 - 0.2'' 切割",
                        leather_engraving: '皮革 - 刻印',
                        denim_1mm_cutting: "牛仔布 - 0.4'' 切割",
                        fabric_3mm_cutting: "布料 - 0.1'' 切割",
                        fabric_5mm_cutting: "布料 - 0.2'' 切割",
                        fabric_engraving: '布料 - 刻印',
                        rubber_bw_engraving: '印章墊 - 刻印',
                        glass_bw_engraving: '玻璃 - 刻印',
                        metal_bw_engraving: '不鏽鋼 - 刻印',
                        steel_engraving_spray_engraving: '不鏽鋼噴劑 - 刻印',
                        stainless_steel_bw_engraving_diode: '不鏽鋼 - 刻印（二極體雷射）',
                        gold_engraving: '金 - 刻印',
                        brass_engraving: '黃銅 - 刻印',
                        ti_engraving: '鈦 - 刻印',
                        stainless_steel_engraving: '不鏽鋼 - 刻印',
                        aluminum_engraving: '鋁 - 刻印',
                        black_acrylic_3mm_cutting: "黑色壓克力 - 0.1'' 切割",
                        black_acrylic_5mm_cutting: "黑色壓克力 - 0.2'' 切割",
                        black_acrylic_engraving: '黑色壓克力 - 刻印',
                        abs_engraving: 'ABS - 刻印',
                        silver_engraving: '銀 - 刻印',
                        iron_engraving: '鐵 - 刻印',
                        fabric_printing: '布料 - 列印',
                        canvas_printing: '帆布 - 列印',
                        cardstock_printing: '卡紙 - 列印',
                        wood_printing: '木材 - 列印',
                        bamboo_printing: '竹子 - 列印',
                        cork_printing: '軟木 - 列印',
                        flat_stone_printing: '石板 - 列印',
                        acrylic_printing: '壓克力 - 列印',
                        pc_printing: 'PC - 列印',
                        stainless_steel_printing: '不鏽鋼 - 列印',
                        gloss_leather_printing: '亮面皮革 - 列印',
                        glass_printing: '玻璃 - 列印',
                    },
                },
                laser_speed: {
                    text: '速度',
                    unit: 'mm/s',
                    fast: '快',
                    slow: '慢',
                    min: 1,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: '功率',
                    high: '強',
                    low: '弱',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: '墨水類型',
                    normal: '一般墨',
                    UV: 'UV 墨',
                },
                para_in_use: '此參數已在使用中。',
                do_not_adjust_default_para: '無法調整預設參數。',
                existing_name: '已存在此名稱的自訂參數。',
                presets: '預設',
            },
            object_panel: {
                zoom: '縮放',
                group: '群組',
                ungroup: '解散群組',
                distribute: '均分',
                hdist: '水平均分',
                vdist: '垂直均分',
                align: '對齊',
                left_align: '靠左對齊',
                center_align: '置中對齊',
                right_align: '靠右對齊',
                top_align: '頂端對齊',
                middle_align: '中線對齊',
                bottom_align: '底部對齊',
                boolean: '布林',
                union: '組合形狀',
                subtract: '去除前面形狀',
                intersect: '形狀區域相交',
                difference: '排除重疊形狀',
                flip: '翻轉',
                hflip: '水平翻轉',
                vflip: '垂直翻轉',
                lock_aspect: '鎖定長寬比',
                unlock_aspect: '解鎖長寬比',
                option_panel: {
                    fill: '填充',
                    rounded_corner: '圓角',
                    sides: '邊數',
                    font_family: '字體',
                    font_style: '字型',
                    font_size: '字級',
                    letter_spacing: '字距',
                    line_spacing: '行距',
                    vertical_text: '直書',
                    start_offset: '文字偏移',
                    vertical_align: '對齊',
                    text_infill: '文字填充',
                    path_infill: '路徑填充',
                    shading: '漸層',
                    threshold: '臨界值',
                    threshold_short: '臨界值',
                    stroke: '邊框',
                    stroke_color: '邊框顏色',
                    stroke_width: '邊框寬度',
                    color: '顏色',
                },
                actions_panel: {
                    replace_with: '替換影像',
                    replace_with_short: '替換影像',
                    trace: '向量化',
                    grading: '曲線',
                    brightness: '亮度對比',
                    sharpen: '銳化',
                    crop: '裁剪',
                    bevel: '生成斜角',
                    invert: '色彩反轉',
                    weld_text: '合併文字',
                    convert_to_path: '轉換為路徑',
                    fetching_web_font: '取得線上字體中...',
                    uploading_font_to_machine: '上傳字體到機器中...',
                    wait_for_parsing_font: '解析字體中...',
                    offset: '位移複製',
                    array: '陣列',
                    auto_arrange: '自動排列',
                    decompose_path: '解散非連續路徑',
                    disassemble_use: '解散圖檔',
                    create_textpath: '建立路徑文字',
                    create_textpath_short: '路徑文字',
                    detach_path: '解散路徑文字',
                    detach_path_short: '解散',
                    edit_path: '編輯路徑',
                    disassembling: '解散中...',
                    ungrouping: '解散群組中...',
                    simplify: '路徑優化',
                    ai_bg_removal: '一鍵去背',
                    ai_bg_removal_short: 'AI去背',
                    ai_bg_removal_reminder: '按下按鈕將立即使用 0.2 Credit，是否繼續？',
                    outline: '勾勒輪廓',
                },
                path_edit_panel: {
                    node_type: '節點類型',
                    sharp: '銳利',
                    round: '平滑',
                    connect: '連接',
                    disconnect: '斷開',
                    delete: '刪除',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: '部分字型在不同系統間有差異，輸出前請將字體轉換成路徑，以確保文字正確顯示。轉換文字至路徑中...',
            retreive_image_data: '擷取影像資料中...',
            export_file_error_ask_for_upload: '匯出工作時發生錯誤，是否願意上傳工作場景回報錯誤給開發團隊？',
        },
        image_trace_panel: {
            apply: '套用',
            back: '上一步',
            cancel: '取消',
            next: '下一步',
            brightness: '曝光',
            contrast: '對比',
            threshold: '臨界值',
            okay: '完成',
            tuning: '描圖參數',
        },
        photo_edit_panel: {
            apply: '套用',
            back: '上一步',
            cancel: '取消',
            next: '下一步',
            sharpen: '銳化',
            sharpness: '鋭化強度',
            radius: '鋭化半徑',
            crop: '裁剪',
            aspect_ratio: '長寬比',
            original: '原圖',
            free: '任意',
            curve: '曲線',
            start: '開始',
            processing: '處理中',
            invert: '色彩反轉',
            okay: '完成',
            compare: '原圖比較',
            phote_edit: '影像編輯',
            brightness_and_contrast: '亮度對比',
            brightness: '亮度',
            contrast: '對比',
        },
        document_panel: {
            document_settings: '文件設定',
            engrave_parameters: '雕刻參數',
            workarea: '工作範圍',
            rotary_mode: '旋轉軸',
            borderless_mode: '開蓋模式',
            engrave_dpi: '雕刻解析度',
            enable_diode: '二極體雷射',
            enable_autofocus: '自動對焦',
            extend_workarea: '擴展工作區域',
            add_on: '擴充模組',
            low: '低',
            medium: '中',
            high: '高',
            ultra: '極高',
            enable: '啟用',
            disable: '關閉',
            cancel: '取消',
            save: '儲存',
            notification: {
                changeFromPrintingWorkareaTitle: '您要將列印圖層轉換至雷射圖層嗎？',
            },
        },
        object_panels: {
            wait_for_parsing_font: '解析字體中...',
            text_to_path: {
                font_substitute_pop: '您的文字包含當前字型不支援的字元。<br/>您想使用<strong>%s</strong>作為替換嗎?',
                check_thumbnail_warning: '轉換文字時出現字體不支援的情形，請在送出工作前，再次檢查預覽圖確認文字是否如預期轉換。',
                error_when_parsing_text: '文字轉換路徑時發生錯誤',
                use_current_font: '使用當前字體',
                retry: '請稍後重試或選擇其他字型',
            },
            lock_desc: '縮放時固定比例 (SHIFT)',
        },
        tool_panels: {
            cancel: '取消',
            confirm: '確認',
            grid_array: '生成陣列',
            array_dimension: '陣列維度',
            rows: '列',
            columns: '行',
            array_interval: '陣列間隔',
            dx: '寬',
            dy: '高',
            offset: '偏移物件',
            nest: '排列最佳化',
            _offset: {
                direction: '偏移方向',
                inward: '向內',
                outward: '向外',
                dist: '偏移距離',
                corner_type: '邊角',
                sharp: '尖角',
                round: '圓角',
                fail_message: '生成偏移物件失敗',
                not_support_message: '選取物件中含有不支援的類型：<br/>圖片、群組、文字、匯入圖檔。',
            },
            _nest: {
                start_nest: '開始排列',
                stop_nest: '停止排列',
                end: '結束',
                spacing: '間距',
                rotations: '旋轉方向數',
                no_element: '沒有物件可以進行排列。',
            },
        },
        network_testing_panel: {
            network_testing: '網路檢測',
            local_ip: '本機 IP 位置：',
            insert_ip: '目標 IP 位置：',
            empty_ip: '#818 請先輸入目標 IP 位置',
            start: '檢測',
            end: '結束',
            testing: '網路檢測中...',
            invalid_ip: '#818 錯誤的 IP 位置',
            ip_startswith_169: '#843 目標 IP 開頭為 169.254',
            connection_quality: '連線品質',
            average_response: '平均回覆時間',
            test_completed: '檢測完成',
            test_fail: '檢測失敗',
            cannot_connect_1: '#840 無法與目標 IP 建立連線',
            cannot_connect_2: '#840 無法與目標 IP 建立連線，請確認是否與目標 IP 在同一網路',
            network_unhealthy: '#841 連線品質 < 70 或平均回覆時間 > 100ms',
            device_not_on_list: '#842 列表上看不到機器，連線品質 > 70 且平均回覆時間 < 100ms',
            hint_device_often_on_list: '列表上經常找不到機器？',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/zh-tw/articles/360001841636',
            hint_connect_failed_when_sending_job: '送出工作時出現無法連接？',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/zh-tw/articles/360001841656',
            hint_connect_camera_timeout: '啟動相機預覽時超時？',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/zh-tw/articles/360001791895',
            cannot_get_local: '無法取得本地 IP 位置',
            fail_to_start_network_test: '#817 無法開始網路檢測。',
            linux_permission_hint: '此問題通常是因為權限不足而發生，請在終端機以 "sudo beam-studio --no-sandbox" 以獲得權限進行網路檢測。',
        },
        layer_color_config_panel: {
            layer_color_config: '圖層顏色參數設定',
            color: '顏色',
            power: '功率',
            speed: '速度',
            repeat: '次數',
            add: '新增',
            save: '儲存',
            cancel: '取消',
            default: '回復預設',
            add_config: '新增顏色',
            in_use: '此顏色已在使用中。',
            no_input: '請輸入顏色色碼。',
            sure_to_reset: '您將會失去所有自訂顏色參數，確定要回復到預設值？',
            sure_to_delete: '確定要刪除這項顏色參數。',
        },
        rating_panel: {
            title: '喜歡 Beam Studio 嗎？',
            description: '如果您喜歡 Beam Studio，請給我們評分，我們將不勝感激。',
            dont_show_again: '不再顯示此提醒',
            thank_you: '謝謝您的回饋',
        },
        svg_editor: {
            unnsupported_file_type: 'Beam Studio 不直接支援此檔案格式。請先輸出成圖片檔或 SVG 格式',
            unable_to_fetch_clipboard_img: '無法讀取複製連結中的檔案',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: '播放',
            pause: '暫停',
            stop: '停止',
            play_speed: '播放速度',
            travel_path: '運行軌跡',
            invert: '色彩反轉',
            preview_info: '預覽資訊',
            size: '尺寸',
            estimated_time: '預估總時間',
            cut_time: '切割時間',
            rapid_time: '快速移動時間',
            cut_distance: '切割距離',
            rapid_distance: '快速移動距離',
            current_position: '當下位置',
            remark: '*所有資訊皆為預估數值，僅供參考。',
            start_here: '從這裡開始',
            end_preview: '結束預覽',
        },
        shapes_panel: {
            title: '元素',
            basic: '基本',
            shape: '形狀',
            graphics: '圖形',
            arrow: '箭頭',
            label: '標籤',
            decor: '裝飾',
            circular: '圓形',
            corner: '角落',
            line: '線條',
            photo: '相框',
            ribbon: '緞帶',
            speech: '對話',
            text: '文字方塊',
            animals: '動物',
            birds: '鳥類',
            land: '陸地',
            sea: '海洋',
            holidays: '節日',
            celebration: '慶祝',
            CNY: '農曆新年',
            easter: '復活節',
            halloween: '萬聖節',
            valentines: '情人節',
            Xmas: '聖誕節',
            nature: '自然',
            elements: '元素',
            environment: '環境',
            plants: '植物',
            weather: '天氣',
        },
        announcement_panel: {
            title: '公告',
            dont_show_again: '不再顯示',
        },
    },
    editor: {
        prespray_area: '預噴區',
        opacity: '相機預覽不透明度',
        exposure: '相機預覽曝光值',
    },
    flux_id_login: {
        connection_fail: '#847 無法連接至 FLUX 會員中心。',
        login_success: '登入成功',
        login: '登入',
        unlock_shape_library: '登入使用百萬圖形資源',
        email: '電子信箱',
        password: '密碼',
        remember_me: '記住我',
        forget_password: '忘記密碼',
        register: '註冊 FLUX 帳號',
        offline: '離線使用',
        work_offline: '我要離線使用',
        incorrect: '信箱或密碼輸入錯誤。',
        not_verified: '信箱尚未認證。',
        new_to_flux: '還不是會員？免費註冊新帳號',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: '探索 FLUX+ 方案',
            thank_you: '感謝您成為我們的會員!',
            ai_credit_tooltip: '用於 AI 去背',
            flux_credit_tooltip: '用於 Design Market 檔案和 AI 去背',
            goto_member_center: '前往會員中心',
            access_plus_feature_1: '您正在使用',
            access_plus_feature_2: '功能。',
            access_plus_feature_note: '您必須擁有 FLUX+ 會員資格才能使用此功能。',
            access_monotype_feature: '您無法使用 Monotype 字型。',
            access_monotype_feature_note: '您必須擁有 FLUX+ Pro 會員資格或加購 Monotype 字型才能使用此功能。',
            learn_more: '了解更多',
            get_addon: '加購',
            subscribe_now: '立即訂閱',
            website_url: 'https://tw.flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/zh-TW/subscription',
            features: {
                ai_bg_removal: 'AI 去背',
                my_cloud: '雲端儲存空間',
                boxgen: '3D 盒子產生器',
                dmkt: '1000+ 設計檔案',
                monotype: '250+ 商用字型',
            },
        },
    },
    noun_project_panel: {
        login_first: '請先登入會員，完成後即可啟用。',
        enjoy_shape_library: '新增百萬圖形資源，請盡情使用。',
        shapes: '形狀',
        elements: '元素',
        recent: '最近使用',
        search: '搜尋',
        clear: '清除',
        export_svg_title: '無法匯出 SVG',
        export_svg_warning: '專案中含有受知識產權法律的保護的 Noun Project 物件，因此匯出時 Beam Studio 會自動幫您排除這類型之物件，您仍可以透過儲存場景 (.beam 檔) 的方式保留您的專案，請問是否要繼續匯出？',
        learn_more: '更多資訊',
    },
    change_logs: {
        change_log: '更新日誌',
        added: '新增：',
        fixed: '修正：',
        changed: '更改：',
        see_older_version: '查看之前版本',
        help_center_url: 'https://support.flux3dp.com/hc/zh-tw/sections/360000421876',
    },
    select_device: {
        select_device: '選擇機器',
        auth_failure: '#811 認證失敗',
        unable_to_connect: '#810 無法與機器建立穩定連線',
    },
    device: {
        pause: '暫停',
        paused: '已暫停',
        pausing: '正在暫停',
        select_printer: '選擇成型機',
        retry: '重試',
        status: '狀態',
        busy: '忙碌中',
        ready: '待命中',
        reset: '重設(kick)',
        abort: '取消工作',
        start: '開始',
        please_wait: '請稍待...',
        quit: '中斷連結',
        completing: '完成中',
        aborted: '已終止',
        completed: '已完成',
        aborting: '取消工作中',
        starting: '啟動中',
        preparing: '準備中',
        resuming: '恢復中',
        scanning: '掃描',
        occupied: '機器被佔用',
        running: '工作中',
        uploading: '上傳中',
        processing: '處理中',
        disconnectedError: {
            caption: '機器連線中斷',
            message: '請確認 %s 的網路連線是否正常',
        },
        unknown: '未知狀態',
        pausedFromError: '發生錯誤暫停',
        model_name: '型號',
        IP: 'IP',
        serial_number: '序號',
        firmware_version: '韌體版本',
        UUID: 'UUID',
        select: '選擇',
        deviceList: '機器列表',
        disable: '關閉',
        enable: '開啟',
        submodule_type: '模組',
        cartridge_serial_number: '墨匣序號',
        ink_color: '墨水顏色',
        ink_type: '墨水類型',
        ink_level: '剩餘墨水量',
        close_door_to_read_cartridge_info: '請關閉機器的門蓋以取得墨匣資訊。',
        cartridge_info_read_failed: '請確保墨匣完全插入。嘗試將墨水取出並重新安裝。',
        cartridge_info_verification_failed: '請確認您是否使用 FLUX 原廠墨匣。',
    },
    monitor: {
        monitor: 'MONITOR',
        go: '開始',
        resume: '繼續',
        start: '開始',
        pause: '暫停',
        stop: '停止',
        record: 'RECORD',
        camera: '相機',
        taskTab: '工作',
        connecting: '連線中，請稍候',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 主板沒有回應。請聯繫 FLUX 客服。',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 子系統沒有回應。請聯繫 FLUX 客服。',
        HARDWARE_ERROR_PUMP_ERROR: '#900 水冷未開，請聯繫客服。',
        HARDWARE_ERROR_DOOR_OPENED: '#901 門蓋開啟，將門蓋關上以繼續',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 水溫過高，請稍後再繼續',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 底蓋開啟，將底蓋關上以繼續',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z 軸歸零失敗',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 抽屜開啟',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 火焰偵測異常',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 氣流偵測異常',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 未偵測到旋轉軸',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 歸零 PULL-OFF 失敗',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 偵測到不相符的模組，請安裝正確的模組頭以繼續操作。',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 未偵測到模組，請確認模組頭已正確安裝以繼續操作。',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 偵測到未知模組。請安裝正確的模組以繼續。',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 列印頭無回應。',
        USER_OPERATION_ROTARY_PAUSE: '請切換旋轉軸馬達開關',
        USER_OPERATION_REMOVE_CARTRIDGE: '請移除墨水匣以繼續操作。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 偵測到不相符的模組，請安裝正確的模組頭以繼續操作。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 未偵測到模組，請確認模組頭已正確安裝以繼續操作。',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 偵測到未知模組。請安裝正確的模組以繼續。',
        USER_OPERATION_CHANGE_TOOLHEAD: '請安裝正確的模組以繼續。',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: '請安裝墨匣以繼續。',
        USER_OPERATION_CHANGE_CARTRIDGE: '請安裝正確的墨匣以繼續。',
        USER_OPERATION: '請查看機器面板上的指示以繼續。',
        RESOURCE_BUSY: '機器忙碌中<br/>如果機器沒有在進行動作， 請重新啟動機器',
        DEVICE_ERROR: '韌體發生錯誤<br/>請重新啟動機器',
        NO_RESPONSE: '#905 連接主板時發生錯誤<br/>請重新啟動機器',
        SUBSYSTEM_ERROR: '#402 子系統沒有回應。請聯繫 FLUX 客服。',
        HARDWARE_FAILURE: '韌體發生錯誤<br/>請重新啟動機器',
        MAINBOARD_OFFLINE: '#905 連接主板時發生錯誤<br/>請重新啟動機器',
        bug_report: '下載錯誤回報檔案',
        processing: '處理中',
        savingPreview: '正在產生預覽圖',
        hour: '小時',
        minute: '分',
        second: '秒',
        left: '完成',
        temperature: '溫度',
        forceStop: '是否強制停止現在工作?',
        upload: '上傳',
        download: '下載',
        relocate: '重新定位',
        cancel: '取消',
        prepareRelocate: '準備重新定位中',
        extensionNotSupported: '上傳檔案不支援此檔案格式',
        fileExistContinue: '檔案已存在，是否要覆蓋？',
        confirmFileDelete: '是否確定要刪除這個檔案？',
        ask_reconnect: '與機器的連線中斷，是否嘗試重新連接？',
        task: {
            BEAMBOX: '雷射工作',
            'N/A': '自由模式',
        },
    },
    alert: {
        caption: '錯誤',
        duplicated_preset_name: '重複的預設名稱',
        info: '訊息',
        warning: '警告',
        error: '錯誤',
        instruction: '提示',
        oops: '哎呀',
        retry: '重試',
        abort: '放棄',
        confirm: '確認',
        cancel: '取消',
        close: '關閉',
        ok: '確定',
        ok2: '好',
        yes: ' 是',
        no: '否',
        stop: '停止',
        save: '儲存',
        dont_save: '不要儲存',
        learn_more: '了解更多',
    },
    caption: {
        connectionTimeout: '連線逾時',
    },
    message: {
        cancelling: '取消中...',
        connecting: '連線中...',
        connectingMachine: '連接 %s 中...',
        tryingToConenctMachine: '嘗試連接機器中...',
        connected: '已連線',
        authenticating: '密碼驗證中...',
        enteringRawMode: '正在進入基本動作模式...',
        endingRawMode: '正在結束基本動作模式...',
        enteringLineCheckMode: '正在進入可靠傳輸模式...',
        endingLineCheckMode: '正在結束可靠傳輸模式...',
        exitingRotaryMode: '正在結束旋轉軸模式...',
        turningOffFan: '正在關閉抽氣風扇...',
        turningOffAirPump: '正在關閉空氣幫浦...',
        gettingLaserSpeed: '正在取得雷射頭移動速度...',
        settingLaserSpeed: '正在設定雷射頭移動速度...',
        retrievingCameraOffset: '正在取得相機參數...',
        connectingCamera: '正在連接相機...',
        homing: '歸零中...',
        connectionTimeout: '#805 連接機器逾時，請確認你的網路狀態和機器的 Wi-Fi 訊號符號。',
        getProbePosition: '取得探針位置中...',
        device_not_found: {
            caption: '找不到預設機器',
            message: '#812 請確認預設機器的 Wi-Fi 指示燈，或取消設定預設機器',
        },
        device_busy: {
            caption: '機器忙碌中',
            message: '機器正在進行另外一項工作，請稍候再試。如果機器持續沒有回應，請將機器重新啟動。',
        },
        device_is_used: '機器正被使用中，是否要終止現在任務？',
        monitor_too_old: {
            caption: '韌體需要更新',
            content: '#814 請按照<a target="_blank" href="http://helpcenter.flux3dp.com/hc/zh-tw/articles/216251077">此說明</a>安裝最新韌體版本',
        },
        unknown_error: '#821 無法與機器建立連線，請使用「功能表 > 說明 > 錯誤回報」',
        unknown_device: '#826 無法與機器建立連線，請確認 USB 有連接於機器',
        unsupport_osx_version: '目前系統版本 macOS %s 較舊，部分功能可能無法使用，請更新到 macOS 11+。',
        unsupport_win_version: '目前系統版本 %s 較舊，部分功能可能無法使用，請更新到最新版。',
        need_password: '需要密碼與機器建立連線',
        unavailableWorkarea: '#804 目前設定的工作範圍超過目標機器的工作範圍。請確認選擇的機器型號，或從 編輯 > 文件設定 更改工作範圍。',
        please_enter_dpi: '請輸入該檔案的單位長度 (公釐)',
        auth_error: '#820 認證失敗：請將 Beam Studio 以及機器韌體更新至最新版。',
        usb_unplugged: 'USB 連線逾時，請確認與機器的連接',
        uploading_fcode: '正在上傳 fcode',
        cant_connect_to_device: '#827 無法連結機器，請確認機器是否開啟，以及與機器的連結方式',
        unable_to_find_machine: '無法連接到機器 ',
        disconnected: '連線不穩，請確認機器連線狀況並稍後再試一次',
        unable_to_start: '#830 無法開始工作，如果持續發生，請附上錯誤回報，與我們聯絡:<br/>',
        camera: {
            camera_cable_unstable: '偵測到相機傳輸照片時不穩定，仍能正常進行相機預覽，但可能會有預覽速度較慢或超時的問題。<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/360001791895">了解更多</a>',
            fail_to_transmit_image: '#845 相機傳輸照片異常，請將機器重新開機。如果問題持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/4402756056079">此則引導</a>。',
            ws_closed_unexpectly: '#844 與機器相機的連線無預期的中斷。如果問題持續發生，請參考<a target="_blank" href="https://flux3dp.zendesk.com/hc/zh-tw/articles/4402755805071">此則引導</a>。',
            continue_preview: '繼續預覽',
            abort_preview: '中斷預覽',
        },
        preview: {
            camera_preview: '相機預覽',
            auto_focus: '自動對焦',
            auto_focus_instruction: '請將雷射模組頭移至物體上方，並依照動畫指示按下AF進行對焦。',
            already_performed_auto_focus: '您已經執行了自動對焦，要使用現有數值嗎？',
            please_enter_height: '請輸入物體的高度，以拍攝精確的相機照片。',
            apply: '套用',
            enter_manually: '手動輸入',
            adjust: '調整',
            adjust_height_tooltip: '點選方塊以啟用編輯。',
        },
        unsupported_example_file: '選取範例文件不支援的目前工作範圍。',
        time_remaining: '剩餘時間：',
    },
    machine_status: {
        '-17': '墨匣模式',
        '-10': '動作模式',
        '-2': '掃描中',
        '-1': '維護中',
        0: '待命中',
        1: '初始化',
        2: 'ST_TRANSFORM',
        4: '啟動中',
        6: '回復中',
        16: '工作中',
        18: '回復中',
        32: '已暫停',
        36: '已暫停',
        38: '暫停中',
        48: '已暫停',
        50: '暫停中',
        64: '已完成',
        66: '完成中',
        68: '準備中',
        128: '已中斷',
        256: 'Alarm',
        512: 'Fatal',
        UNKNOWN: '-',
    },
    calibration: {
        update_firmware_msg1: '您的韌體版本不支援此功能。請先更新 Beambox 的韌體至 v',
        update_firmware_msg2: '以上以繼續。 (主選單 > 機器 > [ Your Beambox ] > 韌體更新',
        camera_calibration: '相機校正',
        diode_calibration: '二極體雷射模組校正',
        module_calibration_printer: '列印模組校正',
        module_calibration_2w_ir: '紅外線模組校正',
        back: '上一步',
        next: '下一步',
        skip: '跳過',
        cancel: '取消',
        finish: '完成',
        do_engraving: '執行切割',
        start_engrave: '開始繪製校正圖形',
        start_printing: '開始列印校正圖形',
        ask_for_readjust: '是否需要跳過切割步驟，進行拍照及校正？',
        please_goto_beambox_first: '請先選擇 Beambox 功能，再進行校正',
        please_place_paper: '請將乾淨 A4 白紙放在工作區域的左上角',
        please_place_paper_center: '請將乾淨 A4 白紙放在工作區的中央。',
        please_place_dark_colored_paper: '請將乾淨 A4 深色紙放在工作區的中央。',
        please_refocus: {
            beambox: '請旋轉升降平台旋鈕，直到輕觸焦距螺絲或焦距尺，完成對焦後，轉回對焦尺。',
            beamo: '請轉開焦距固定環，調整雷射頭至平台輕觸焦距尺，完成對焦後，旋緊固定環、轉回對焦尺。',
            beamo_af: '請雙擊自動對焦套件側邊按鈕，使探針輕觸雕刻材料。',
            hexa: '請連續按壓升降平台左側按鈕 2 次，使平台上移、自動對焦探針輕觸雕刻材料。',
        },
        without_af: '無自動對焦',
        with_af: '有自動對焦',
        dx: '水平位移',
        dy: '垂直位移',
        rotation_angle: '旋轉角度',
        x_ratio: '水平比例',
        y_ratio: '垂直比例',
        show_last_config: '顯示前次校正結果',
        use_last_config: '匯入前次校正數值',
        taking_picture: '擷取圖片中...',
        analyze_result_fail: '校正失敗<br/>請確認:<br/>1. 校正圖片完整畫在 A4 紙上<br/>2. 已旋轉升降平台旋鈕，直到輕觸焦距螺絲，完成對焦',
        drawing_calibration_image: '繪製校正圖片中...',
        calibrate_done: '校正相機完成！使用時請正確對焦以取得良好的預覽效果。',
        calibrate_done_diode: '校正完成！二極體雷射模組偏移值已自動儲存。',
        hint_red_square: '請將紅框外側對齊切割出來的方塊',
        hint_adjust_parameters: '由這些參數來調整紅框的位置，旋轉與大小',
        zendesk_link: 'https://support.flux3dp.com/hc/zh-tw/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: '校正二極體雷射需要使用相機校正參數，請確認您的機器已進行過相機校正。並請旋轉升降平台旋鈕，直到輕觸焦距螺絲或焦距尺，完成對焦',
            beamo: '校正二極體雷射需要使用相機校正參數，請確認您的機器已進行過相機校正。並請轉開焦距固定環，調整雷射頭至平台輕觸焦距尺，完成對焦',
        },
        downloading_pictures: '下載圖片中...',
        failed_to_download_pictures: '#848 下載圖片失敗，請聯絡 FLUX 客服。',
        uploading_images: '上傳圖片中...',
        calculating_camera_matrix: '計算相機矩陣中...',
        calculating_regression_parameters: '計算回歸參數中...',
        failed_to_calibrate_camera: '#848 校正相機失敗，請聯絡 FLUX 客服。',
        failed_to_save_calibration_results: '#849 儲存校正結果失敗，請再試一次。若此錯誤持續發生，請聯絡 FLUX 客服。',
        ador_autofocus_material: '在機器的主頁上長按「AF」圖示 3 秒鐘，並讓探針輕輕觸碰材料。',
        ador_autofocus_focusing_block: '在機器面板上長壓「AF」圖示 3 秒鐘，讓探針觸碰對焦塊。',
        align_red_cross_cut: '請將紅色十字的中心對齊切割出來的十字中心。',
        align_red_cross_print: '請將紅色十字的中心對齊列印出來的十字中心。',
        retake: '重拍照片',
        calibrate_camera_before_calibrate_modules: '在校正模組前，請先進行相機校正。',
        check_checkpoint_data: '檢查點資料',
        checking_checkpoint: '正在檢查檢查點資料...',
        found_checkpoint: '在您的機器上找到檢查點資料。您想從檢查點還原嗎？',
        use_old_camera_parameter: '您要使用目前的相機鏡頭參數嗎？',
        downloading_checkpoint: '下載檢查點資料...',
        failed_to_parse_checkpoint: '無法解析檢查點資料.',
        check_device_pictures: '檢查機器照片',
        checking_pictures: '正在檢查機器照片...',
        no_picutre_found: '#846 您的機器中沒有可使用的照片以用於校正，請聯繫 FLUX 客服。',
        calibrating_with_device_pictures: '使用機器照片進行校正...',
        failed_to_calibrate_with_pictures: '#847 使用機器照片進行校正失敗。',
        getting_plane_height: '取得平面高度...',
        preparing_to_take_picture: '準備拍攝...',
        put_paper: '放置紙張',
        put_paper_step1: '請將乾淨 A4 白紙放在工作區的中央。',
        put_paper_step2: '將紙張四個角落固定，確保紙張沒有浮起。',
        put_paper_step3: '點選「開始繪製校正圖形」以繪製圖形。',
        solve_pnp_title: '對齊標記點',
        solve_pnp_step1: '請根據每個紅色標記點的編號和預期位置對齊雕刻點。',
        solve_pnp_step2: '您可以按下「重拍照片」以重試對齊或手動調整標記點位置。',
        align_olt: '位置：對齊外側左上角的雕刻點。',
        align_ort: '位置：對齊外側右上角的雕刻點。',
        align_olb: '位置：對齊外側左下角的雕刻點。',
        align_orb: '位置：對齊外側右下角的雕刻點。',
        align_ilt: '位置：對齊內側左上角的雕刻點。',
        align_irt: '位置：對齊內側右上角的雕刻點。',
        align_ilb: '位置：對齊內側左下角的雕刻點。',
        align_irb: '位置：對齊內側右下角的雕刻點。',
        elevate_and_cut: '抬升和切割',
        elevate_and_cut_step_1: '請將 A4 尺寸的淺色木板放在工作區的中央，並將其墊高至 20mm 高。',
        elevate_and_cut_step_1_prism_lift: '使用 Ador 刀條的最長邊約 14mm 搭配 6mm 以上木板，將其墊高至 20mm 高。',
        with_prism_lift: '使用 Ador 刀條',
        without_prism_lift: '不使用 Ador 刀條',
        camera_parameter_saved_successfully: '相機參數保存成功。',
        failed_to_save_camera_parameter: '保存相機參數失敗。',
        failed_to_solve_pnp: '無法解出相機位置。',
    },
    input_machine_password: {
        require_password: '"%s" 需要密碼',
        connect: '連接',
        password: '密碼',
    },
    tutorial: {
        skip: '跳過教學',
        welcome: '歡迎使用',
        suggest_calibrate_camera_first: '我們建議使用者在第一次使用機器時校正相機，並在每次使用時將平台對焦，以取得最好的效果。<br/>請問您要現在進行相機校正嗎？<br/>（您也可以先跳過，並在之後從上方選單「機器」>「您的機器名稱」>「校正相機」進行校正。）',
        camera_calibration_failed: '相機校正失敗',
        ask_retry_calibration: '請問是否重新執行相機校正？',
        needNewUserTutorial: '請問您是否需要 Beam Studio 的教學？<br/>（您也可以先跳過，並在之後從「說明」>「顯示新手教學」進行教學。）',
        needNewInterfaceTutorial: '請問是否需要為您介紹 Beam Studio 的新介面？<br/>（您也可以先跳過，並在之後從「說明」>「顯示介面介紹」開始介紹。）',
        next: '下一步',
        look_for_machine: '尋找機器中',
        unable_to_find_machine: '無法找到可用於新手教學的機器，是否進行要進行連線設定、重試或是跳過教學？',
        skip_tutorial: '已跳過新手教學，您可以在「說明」>「顯示新手教學」再次啟動新手教學。',
        set_connection: '連線設定',
        retry: '重試',
        newUser: {
            draw_a_circle: '畫一個圓',
            drag_to_draw: '拖曳以生成圖形',
            infill: '設定填充',
            switch_to_object_panel: '切換至物件面板',
            switch_to_layer_panel: '切換到圖層面板',
            set_preset_wood_engraving: '設定參數：木板 - 刻印',
            set_preset_wood_cut: '設定參數：木板 - 切割',
            add_new_layer: '新增圖層',
            draw_a_rect: '畫一個方形',
            switch_to_preview_mode: '切換到相機預覽模式',
            preview_the_platform: '預覽工作平台',
            end_preview_mode: '結束相機預覽模式',
            put_wood: '1. 放進木板',
            adjust_focus: '2. 調整焦距',
            close_cover: '3. 將門蓋關上',
            send_the_file: '送出工作',
            end_alert: '請問您是否確定要結束教學？',
            please_select_wood_engraving: '請選擇「木板 - 刻印」參數。',
            please_select_wood_cutting: '請選擇「木板 - 切割」參數。',
        },
        newInterface: {
            camera_preview: '相機預覽',
            select_image_text: '選取、圖片、文字工具',
            basic_shapes: '基本幾何形狀',
            pen_tool: '鋼筆工具',
            add_new_layer: '新增圖層',
            rename_by_double_click: '雙擊以重新命名',
            drag_to_sort: '拖曳以排序',
            layer_controls: '右鍵點擊以呼叫圖層控制功能：\n複製、合併、鎖定、刪除圖層',
            switch_between_layer_panel_and_object_panel: '在圖層面板與物件面板間切換',
            align_controls: '排列控制',
            group_controls: '群組、解散群組',
            shape_operation: '幾何圖形操作',
            flip: '翻轉',
            object_actions: '物件操作',
            end_alert: '請問您是否確定要結束新介面介紹？',
            select_machine: '選擇機器',
            start_work: '開始工作',
        },
        gesture: {
            pan: '雙指平移讓畫布隨著您的手指方向移動。',
            zoom: '雙指靠攏以縮小，或雙指分開來放大畫布。',
            click: '點按以選擇物件。',
            drag: '拖曳以多選物件。',
            hold: '按住一段時間打開選單。',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: '介紹完畢，開始創作吧！',
    },
    layer_module: {
        none: '無',
        general_laser: '雷射',
        laser_10w_diode: '10W 二極體雷射',
        laser_20w_diode: '20W 二極體雷射',
        laser_2w_infrared: '2W 紅外線雷射',
        printing: '列印',
        unknown: '未知模組',
        notification: {
            convertFromPrintingModuleTitle: '您是否要將列印圖層轉換為雷射圖層？',
            convertFromPrintingModuleMsg: '請注意，如果您繼續此操作，列印圖層的顏色設定將會被刪除，並根據當前圖層參數而定。',
            convertFromLaserModuleTitle: '您是否要將雷射圖層轉換為列印圖層？',
            convertFromLaserModuleMsg: '請注意，如果您繼續此操作，雷射圖層的設定將會被刪除，並根據當前圖層參數而定。',
            importedDocumentContainsPrinting: '這份文件包含列印圖層，您是否要將工作範圍切換成 Ador ？',
            printingLayersCoverted: '列印圖層已轉換為雷射圖層。',
            performPrintingCaliTitle: '執行列印模組校正',
            performPrintingCaliMsg: '點選 「確認」 以執行校正，或者透過上方選單中的「機器」>「您的機器名稱」>「校正列印模組」進行校正。',
            performIRCaliTitle: '執行紅外線模組校正',
            performIRCaliMsg: '點選 「確認」 以執行校正，或者透過上方選單中的「機器」>「您的機器名稱」>「校正紅外線模組」進行校正。',
        },
        non_working_area: '非工作區域',
    },
    qr_code_generator: {
        title: 'QR 碼產生器',
        placeholder: '輸入連結或文字',
        preview: '預覽',
        error_tolerance: '容錯率',
        error_tolerance_link: 'https://support.flux3dp.com/hc/zh-tw/articles/9113705072143',
        invert: '反轉背景顏色',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: '基本款',
        coming_soon: '即將推出',
        workarea: '工作區',
        max_dimension_tooltip: '最大寬度/高度/深度設定為 %s。',
        volume: '尺寸',
        outer: '外部',
        inner: '內部',
        width: '寬度',
        height: '高度',
        depth: '深度',
        cover: '蓋子',
        thickness: '厚度',
        add_option: '添加選項',
        joints: '接合',
        finger: '指接榫',
        finger_warning: '盒子內邊長需要至少 6mm (0.24英吋) 以適用於指接榫接合方式。',
        tSlot: 'T 型槽',
        tSlot_warning: '盒子邊長需要至少 30mm (1.18英吋) 以適用於 T 型槽接合方式。',
        edge: '邊緣',
        tCount: 'T 槽數量',
        tCount_tooltip: 'T 槽數量適用於短邊；長邊的數量則根據其長度計算。',
        tDiameter: 'T 槽直徑',
        tLength: 'T 槽長度',
        continue_import: '繼續匯入',
        customize: '自定義',
        merge: '合併',
        text_label: '標籤',
        beam_radius: '切口補償',
        beam_radius_warning: '建議在盒子或榫接邊較短時取消補償，以確保盒子的接合。',
        import: '匯入',
        cancel: '取消',
        reset: '重置',
        zoom: '縮放',
        control_tooltip: '左鍵旋轉\n滾輪縮放\n右鍵移動',
        control_tooltip_touch: '拖曳旋轉\n捏合縮放\n雙指移動',
    },
    my_cloud: {
        title: '我的雲端',
        loading_file: '載入中...',
        no_file_title: '儲存檔案到我的雲端開始使用。',
        no_file_subtitle: '轉到選單 > “檔案” > “儲存到雲端”',
        file_limit: '免費檔案',
        upgrade: '升級',
        sort: {
            most_recent: '最近',
            oldest: '最早',
            a_to_z: '名稱: A - Z',
            z_to_a: '名稱: Z - A',
        },
        action: {
            open: '開啟',
            rename: '重新命名',
            duplicate: '複製',
            download: '下載',
            delete: '刪除',
            confirmFileDelete: '您確定要刪除此檔案嗎?此操作無法復原。',
        },
        save_file: {
            choose_action: '保存檔案：',
            save: '覆蓋舊檔案',
            save_new: '另存新檔',
            input_file_name: '另存新檔為：',
            invalid_char: '無效字元:',
            storage_limit_exceeded: '您的雲端儲存空間已滿。在儲存新檔案前，請先刪除您不需要的檔案。',
        },
    },
    camera_data_backup: {
        title: '相機資料備份',
        no_picture_found: '機器中未找到任何圖片。',
        folder_not_exists: '所選的資料夾不存在。',
        incorrect_folder: '相機資料上傳失敗。請檢查您選擇的資料夾是否正確。',
        downloading_data: '正在下載資料...',
        estimated_time_left: '預計剩餘時間：',
        uploading_data: '正在上傳資料...',
        download_success: '相機數據下載成功。',
        upload_success: '相機數據上傳成功。',
    },
    insecure_websocket: {
        extension_detected: '偵測到 Beam Studio Connect 擴充功能',
        extension_detected_description: '我們偵測到您已安裝了 Beam Studio Connect 擴充功能。請按下「確認」重新導向至 HTTPS，或者選擇「取消」以繼續使用目前的 HTTP 網址。',
        extension_not_deteced: '無法偵測到 Beam Studio Connect 擴充功能',
        extension_not_deteced_description: '若要使用 HTTPS，請按下「確認」以前往安裝 Beam Studio Connect 擴充功能，安裝擴充功能後，請重新頁面以啟用。<br/>否則，請點擊下面的連結查看如何在 Chrome 中使用 HTTP。',
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/zh-tw/articles/9935859456271">連結</a>',
    },
    curve_engraving: {
        measure_audofocus_area: '測量自動對焦區域',
        amount: '數量',
        gap: '間距',
        rows: '行',
        coloumns: '列',
        row_gap: '行間距',
        column_gap: '列間距',
        set_object_height: '設定物件高度',
        set_object_height_desc: '測量物件的最大厚度。',
        measure_area_help: '如何選擇3D曲面的自動對焦區域？',
        reselect_area: '重新選擇區域',
        start_autofocus: '開始自動對焦',
        starting_measurement: '正在開始測量...',
        preview_3d_curve: '預覽 3D 曲面',
        apply_arkwork: '將藝術作品套用於 3D 曲面',
        apply_camera: '將相機圖像套用於 3D 曲面',
        click_to_select_point: '點擊選擇或取消選擇要重新測量的點。',
        remeasure: '重新測量',
        remeasuring_points: '正在重新測量點...',
    },
    pass_through: {
        title: '直通模式',
    },
};
export default lang;
