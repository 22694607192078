const lang = {
    general: {
        wait: 'Behandler, vennligst vent',
        choose_folder: 'Velg mappe',
    },
    buttons: {
        next: 'NESTE',
        back: 'TILBAKE',
        done: 'FERDIG',
        back_to_beam_studio: 'Tilbake til Beam Studio',
    },
    topbar: {
        untitled: 'Uten tittel',
        titles: {
            settings: 'Innstillinger',
        },
        export: 'EKSPORTER',
        preview: 'FORHÅNDSVISNING',
        preview_title: 'Forhåndsvisning',
        curve_engrave: '3D Kurve',
        task_preview: 'Oppgaveforhåndsvisning',
        frame_task: 'Kjører ramme',
        borderless: '(ÅPEN BUNN)',
        tag_names: {
            rect: 'Rektangel',
            ellipse: 'Oval',
            path: 'Bane',
            polygon: 'Polygon',
            image: 'Bilde',
            text: 'Tekst',
            text_path: 'Tekst på bane',
            line: 'Linje',
            g: 'Gruppe',
            multi_select: 'Flere objekter',
            use: 'Importert objekt',
            svg: 'SVG-objekt',
            dxf: 'DXF-objekt',
            no_selection: 'Ingen valg',
        },
        alerts: {
            start_preview_timeout: '#803 Tidsavbrudd ved oppstart av forhåndsvisning. Start maskinen eller Beam Studio på nytt. Hvis feilen vedvarer, følg denne veiledningen',
            fail_to_start_preview: '#803 Kunne ikke starte forhåndsvisning. Start maskinen eller Beam Studio på nytt. Hvis feilen vedvarer, følg denne veiledningen',
            fail_to_connect_with_camera: '#803 Kunne ikke opprette tilkobling til maskinkamera. Start maskinen eller Beam Studio på nytt. Hvis feilen vedvarer, følg denne veiledningen',
            add_content_first: 'Legg til objekter først',
            headtype_mismatch: 'Feil modul oppdaget. ',
            headtype_none: 'Modul ikke oppdaget. ',
            headtype_unknown: 'Ukjent modul oppdaget. ',
            install_correct_headtype: 'Vennligst installer 10W/20W diode laser moduler ordentlig for å aktivere lav laser for å kjøre ramme.',
            door_opened: 'Vennligst lukk dørdekselet for å aktivere lav laser for å kjøre ramme.',
            fail_to_get_door_status: 'Vennligst sørg for at dørdekselet er lukket for å aktivere lav laser for å kjøre ramme.',
            QcleanScene: 'Vil du slette tegningen?<br/>Dette vil også slette angrehistorikken din!',
            power_too_high: 'FOR HØY EFFEKT',
            power_too_high_msg: 'Bruk av lavere lasereffekt (under 70%) forlenger laserens levetid.\nSkriv inn "FORSTÅTT" for å fortsette.',
            power_too_high_confirm: 'FORSTÅTT',
        },
        hint: {
            polygon: 'Trykk + / -tasten for å øke / redusere sider.',
        },
        menu: {
            preferences: 'Innstillinger',
            hide: 'Skjul Beam Studio',
            hideothers: 'Skjul andre',
            service: 'Tjenester',
            quit: 'Avslutt',
            window: 'Vindu',
            minimize: 'Minimer',
            close: 'Lukk vindu',
            file: 'Fil',
            edit: 'Rediger',
            help: 'Hjelp',
            open: 'Åpne',
            samples: 'Eksempler',
            import_hello_beamo: 'Eksempel på beamo',
            import_hello_beambox: 'Eksempel på beambox',
            import_material_testing_old: 'Materialgraveringstest - Klassisk',
            import_material_testing_simple_cut: 'Materialskjæringstest - Enkel',
            import_material_testing_cut: 'Materialskjæringstest',
            import_material_testing_engrave: 'Materialgraveringstest',
            import_material_testing_line: 'Materiallinjetest',
            import_material_printing_test: 'Materialutskriftstest',
            import_ador_laser_example: 'Eksempel på Ador Laser',
            import_ador_printing_example_single: 'Eksempel på Ador-utskrift - Enkelt farge',
            import_ador_printing_example_full: 'Eksempel på Ador-utskrift - Fullfarge',
            import_acrylic_focus_probe: 'Akrylfokusprobe - 3 mm',
            export_to: 'Eksporter til...',
            export_flux_task: 'FLUX-oppgave',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Lagre',
            save_as: 'Lagre som...',
            save_to_cloud: 'Lagre til skyen',
            about_beam_studio: 'Om Beam Studio',
            undo: 'Angre',
            redo: 'Gjør om',
            cut: 'Klipp ut',
            copy: 'Kopier',
            paste: 'Lim inn',
            paste_in_place: 'Lim inn på plass',
            group: 'Grupper',
            ungroup: 'Del opp',
            delete: 'Slett',
            duplicate: 'Dupliser',
            offset: 'Forskyv',
            scale: 'Skaler',
            rotate: 'Roter',
            reset: 'Tilbakestill',
            align_center: 'Sentrer',
            photo_edit: 'Bilde',
            svg_edit: 'SVG',
            path: 'Bane',
            decompose_path: 'Dekomponer',
            object: 'Objekt',
            layer_setting: 'Lag',
            layer_color_config: 'Fargeinnstillinger',
            image_sharpen: 'Skarphet',
            image_crop: 'Beskjær',
            image_invert: 'Inverter',
            image_stamp: 'Fas',
            image_vectorize: 'Spor',
            image_curve: 'Kurve',
            align_to_edges: 'Fest til hjørner',
            document_setting: 'Dokumentinnstillinger',
            document_setting_short: 'Dokument',
            clear_scene: 'Nye filer',
            machines: 'Maskiner',
            add_new_machine: 'Maskinoppsett',
            help_center: 'Hjelpesenter',
            show_gesture_tutorial: 'Håndbevegelseintroduksjon',
            show_start_tutorial: 'Vis startopplæring',
            show_ui_intro: 'Vis grensesnittintroduksjon',
            questionnaire: 'Tilbakemeldingsskjema',
            change_logs: 'Endringslogg',
            contact: 'Kontakt oss',
            tutorial: 'Start opplæring for Delta-serien',
            design_market: 'Designmarked',
            forum: 'Fellesskapsforum',
            software_update: 'Programvareoppdatering',
            bug_report: 'Feilrapport',
            dashboard: 'Kontrollpanel',
            machine_info: 'Maskininfo',
            network_testing: 'Test nettverksinnstillinger',
            commands: 'Kommandoer',
            update_firmware: 'Oppdater fastvare',
            using_beam_studio_api: 'Bruke Beam Studio API',
            set_as_default: 'Angi som standard',
            calibrate_beambox_camera: 'Kalibrer kamera',
            calibrate_printer_module: 'Kalibrer skrivermodul',
            calibrate_ir_module: 'Kalibrer infrarød modul',
            calibrate_beambox_camera_borderless: 'Kalibrer kamera (åpen bunn)',
            calibrate_diode_module: 'Kalibrer diode lasermodul',
            manage_account: 'Administrer konto',
            sign_in: 'Logg inn',
            sign_out: 'Logg ut',
            account: 'Konto',
            my_account: 'Min konto',
            download_log: 'Last ned logger',
            download_log_canceled: 'Loggnedlasting avbrutt',
            download_log_error: 'Ukjent feil oppstod, vennligst prøv igjen senere',
            keyboard_shortcuts: 'Tastatursnarveier',
            log: {
                network: 'Nettverk',
                hardware: 'Maskinvare',
                discover: 'Oppdag',
                usb: 'USB',
                usblist: 'USB-liste',
                camera: 'Kamera',
                cloud: 'Sky',
                player: 'Spiller',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Vis',
            zoom_in: 'Zoom inn',
            zoom_out: 'Zoom ut',
            fit_to_window: 'Tilpass til vindu',
            zoom_with_window: 'Auto tilpass til vindu',
            borderless_mode: 'Rammeløs modus',
            show_grids: 'Vis rutenett',
            show_rulers: 'Vis linjal',
            show_layer_color: 'Bruk lagfarge',
            anti_aliasing: 'Anti-Aliasing',
            disassemble_use: 'Demontere',
        },
        select_machine: 'Velg en maskin',
    },
    support: {
        no_webgl: 'WebGL støttes ikke. Bruk en annen enhet.',
        no_vcredist: 'Installer Visual C++ Redistributable 2015<br/>Den kan lastes ned på flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Ukjent feil oppstod. Start Beam Studio og maskinen på nytt.',
        OPERATION_ERROR: '[OE] En statuskonflikt oppsto, prøv handlingen på nytt.',
        SUBSYSTEM_ERROR: '[SE] Feil under utføring av oppgave i maskinvare. Start maskinen på nytt.',
        UNKNOWN_COMMAND: '[UC] Oppdater enhetsfastvare',
    },
    device_selection: {
        no_beambox: '#801 Vi finner ikke maskinen din på nettverket.\nFølg <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">veiledningen</a> for å løse tilkoblingsproblemet.',
        no_device_web: "#801 Sjekk statusen til maskinen din, eller klikk på 'Maskinoppsett' nedenfor for å sette opp maskinen.",
    },
    update: {
        update: 'Oppdater',
        release_note: 'Utgivelsesnotat:',
        firmware: {
            caption: 'En fastvareoppdatering til maskinen er tilgjengelig',
            message_pattern_1: '"%s" er klar for fastvareoppdatering.',
            message_pattern_2: '%s fastvare v%s er nå tilgjengelig - Du har v%s.',
            latest_firmware: {
                caption: 'Maskinvare oppdatering',
                message: 'Du har den nyeste maskinvarefastvaren',
                still_update: 'OPPDATER',
                cant_get_latest: 'Kunne ikke hente informasjon om nyeste fastvare.',
            },
            confirm: 'LAST OPP',
            upload_file: 'Fastvareopplasting (*.bin / *.fxfw)',
            update_success: 'Fastvareoppdatering lastet opp vellykket',
            update_fail: '#822 Oppdatering mislyktes',
            too_old_for_web: 'Din nåværende maskinvarefastvare er v%s.\nHvis du vil bruke nettversjonen av Beam Studio, oppdater maskinvarefastvaren til siste versjon.',
            force_update_message: '#814 Oppdater maskinen til siste fastvareversjon.',
            firmware_too_old_update_by_sdcard: 'Fastvareversjonen er for gammel. Oppdater fastvaren ved hjelp av et SD-kort.',
        },
        software: {
            checking: 'Ser etter oppdateringer',
            switch_version: 'Bytt versjon',
            check_update: 'Se etter oppdateringer',
            caption: 'En programvareoppdatering for Beam Studio er tilgjengelig',
            downloading: 'Laster ned oppdateringer i bakgrunnen, du kan klikke "OK" for å fortsette.',
            install_or_not: 'er klar for oppdatering. Vil du starte på nytt og oppdatere nå?',
            switch_or_not: 'er klar for bytte. Vil du starte på nytt og bytte nå?',
            available_update: 'Beam Studio v%s er tilgjengelig nå. Du har v%s. Vil du laste ned oppdateringen?',
            available_switch: 'Beam Studio v%s er tilgjengelig nå. Du har v%s. Vil du bytte til denne versjonen?',
            not_found: 'Du bruker den nyeste versjonen av Beam Studio.',
            no_response: 'Kunne ikke koble til serveren, vennligst sjekk nettverksinnstillingene dine.',
            switch_version_not_found: 'Byttbar versjon ikke funnet.',
            yes: 'Ja',
            no: 'Nei',
            update_for_ador: 'Den nåværende programvareversjonen %s er ikke kompatibel, vennligst last ned den nyeste versjonen av Beam Studio for Ador.',
        },
        updating: 'Oppdaterer...',
        skip: 'Hopp over denne versjonen',
        preparing: 'Forbereder...',
        later: 'SENERE',
        download: 'NETTOPPDATERING',
        cannot_reach_internet: '#823 Serveren er utilgjengelig<br/>Sjekk internettforbindelsen',
        install: 'INSTALLER',
        upload: 'LAST OPP',
    },
    topmenu: {
        version: 'Versjon',
        credit: 'Beam Studio er muliggjort av <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> åpen kildekode-prosjektet og annen <a target="_blank" href="https://flux3dp.com/credits/">åpen kildekode-programvare</a>.',
        ok: 'OK',
        file: {
            label: 'Fil',
            import: 'Importer',
            save_fcode: 'Eksporter FLUX-oppgave',
            save_scene: 'Lagre scene',
            save_svg: 'Eksporter SVG',
            save_png: 'Eksporter PNG',
            save_jpg: 'Eksporter JPG',
            converting: 'Konverterer til bilde...',
            all_files: 'Alle filer',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio Scene',
            fcode_files: 'FLUX Code',
            clear_recent: 'Tøm nylig åpnet',
            path_not_exit: 'Denne stien ser ikke ut til å eksistere lenger på disken.',
        },
        device: {
            download_log_canceled: 'Logg nedlasting avbrutt',
            download_log_error: 'Ukjent feil oppstod, vennligst prøv igjen senere',
            log: {
                usblist: 'USB-liste',
            },
            network_test: 'Test nettverk',
        },
    },
    initialize: {
        next: 'Neste',
        start: 'Start',
        skip: 'Hopp over',
        cancel: 'Avbryt',
        confirm: 'Bekreft',
        connect: 'Koble til',
        back: 'Tilbake',
        retry: 'Prøv igjen',
        no_machine: 'Jeg har ikke en maskin nå.',
        select_language: 'Velg språk',
        select_machine_type: 'Velg din modell',
        select_connection_type: 'Hvordan vil du koble til?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Kablet nettverk',
            ether2ether: 'Direkte tilkobling',
            usb: 'USB-tilkobling',
        },
        connect_wifi: {
            title: 'Kobler til Wi-Fi',
            tutorial1: '1. Gå til berøringsskjerm > Klikk "Nettverk" > "Koble til WiFi".',
            tutorial1_ador: '1. Gå til berøringspanelet > klikk på "MASKIN" > klikk på "Nettverk" > "Koble til WiFi".',
            tutorial2: '2. Velg og koble til foretrukket Wi-Fi.',
            what_if_1: 'Hva hvis jeg ikke ser mitt Wi-Fi?',
            what_if_1_content: '1. Wi-Fi-krypteringen bør være WPA2 eller ingen passord.\n2. Krypteringen kan angis i Wi-Fi-ruteradministrasjon. Hvis ruteren ikke støtter WPA2 og du trenger hjelp med å velge riktig ruter, kontakt FLUX-støtte.',
            what_if_2: 'Hva hvis jeg ikke ser noe Wi-Fi?',
            what_if_2_content: '1. Sjekk at Wi-Fi-dongelen er ordentlig tilkoblet.\n2. Hvis det ikke er noen MAC-adresse for trådløst nettverk på berøringsskjermen, kontakt FLUX-støtte.\n3. Wi-Fi-kanalen bør være 2.4Ghz (5Ghz støttes ikke).',
        },
        connect_wired: {
            title: 'Koble til kablet nettverk',
            tutorial1: '1. Koble maskinen til ruteren.',
            tutorial2: '2. Trykk på "Nettverk" for å få kablet nettverks-IP.',
            tutorial2_ador: '2. Trykk på "MASKIN" > "Nettverk" for å få den kablede nettverks-IP-en.',
            what_if_1: 'Hva hvis IP-en er tom?',
            what_if_1_content: '1. Sjekk at Ethernet-kabelen er ordentlig tilkoblet.\n2. Hvis det ikke er noen MAC-adresse for kablet nettverk på berøringsskjermen, kontakt FLUX-støtte.',
            what_if_2: 'Hva hvis IP-en starter med 169?',
            what_if_2_content: '1. Hvis IP-adressen starter med 169.254, bør det være et DHCP-innstillingsproblem, kontakt internettleverandøren for hjelp.\n2. Hvis datamaskinen kobler direkte til internett med PPPoE, bytt til å bruke ruter med PPPoE, og aktiver DHCP-funksjon i ruteren.',
        },
        connect_ethernet: {
            title: 'Direkte tilkobling',
            tutorial1: '1. Koble maskinen til datamaskinen din med ethernetkabel.',
            tutorial2_1: '2. Følg ',
            tutorial2_a_text: 'denne guiden',
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' for å gjøre datamaskinen din til en ruter.',
            tutorial3: '3. Klikk Neste.',
        },
        connect_usb: {
            title: 'USB-tilkobling',
            title_sub: ' (HEXA Only)',
            tutorial1: '1. Koble maskinen til datamaskinen din med USB-kabel.',
            tutorial2: '2. Klikk Neste.',
            turn_off_machine: 'Slå av maskinen.',
            turn_on_machine: 'Slå på maskinen.',
            wait_for_turning_on: 'Klikk "Neste" etter at oppstartsprosessen er fullført og du har kommet til hovedskjermen.',
        },
        connect_machine_ip: {
            check_usb: 'Sjekk USB-tilkobling',
            enter_ip: 'Skriv inn maskin-IP-en din',
            check_ip: 'Sjekker IP-tilgjengelighet',
            invalid_ip: 'Ugyldig IP: ',
            invalid_format: 'Ugyldig format',
            starts_with_169254: 'Starter med 169.254',
            unreachable: 'IP utilgjengelig',
            check_connection: 'Sjekker maskintilkobling',
            check_firmware: 'Kontrollerer fastvareversjon',
            check_camera: 'Kontrollerer kameratilgjengelighet',
            retry: 'Prøv igjen',
            succeeded_message: 'Tilkoblet 🎉',
            finish_setting: 'Start å lage!',
        },
        connecting: 'Kobler til...',
        setting_completed: {
            start: 'Start',
            great: 'Velkommen til Beam Studio',
            setup_later: 'Du kan alltid konfigurere maskinen fra toppmenyen > "Maskiner" > "Maskinkonfigurasjon"',
            back: 'Tilbake',
            ok: 'START Å LAGE',
        },
    },
    error_pages: {
        screen_size: 'Vær oppmerksom på at Beam Studio kanskje ikke fungerer optimalt på enheten din. For best mulig opplevelse bør skjermbredden være minst 1024 piksler.',
    },
    menu: {
        mm: 'mm',
        inches: 'tommer',
    },
    settings: {
        on: 'På',
        off: 'Av',
        low: 'Lav',
        high: 'Normal',
        caption: 'Innstillinger',
        tabs: {
            general: 'Generelt',
            device: 'Enhet',
        },
        ip: 'Maskin-IP-adresse',
        guess_poke: 'Søk etter maskin-IP-adresse',
        auto_connect: 'Velg automatisk den eneste maskinen',
        wrong_ip_format: 'Feil IP-format',
        default_machine: 'Standardmaskin',
        default_machine_button: 'Tom',
        remove_default_machine_button: 'Fjern',
        confirm_remove_default: 'Standardmaskinen vil bli fjernet.',
        reset: 'Tilbakestill Beam Studio',
        reset_now: 'Tilbakestill Beam Studio',
        confirm_reset: 'Bekreft tilbakestilling av Beam Studio?',
        language: 'Språk',
        notifications: 'Skrivebordsvarsler',
        check_updates: 'Autosjekk',
        autosave_enabled: 'Autoslagre',
        autosave_path: 'Autoslagre plassering',
        autosave_interval: 'Lagre hver',
        autosave_number: 'Antall autoslagringer',
        autosave_path_not_correct: 'Angitt sti ble ikke funnet.',
        preview_movement_speed: 'Forhåndsvisning bevegelsesfart',
        preview_movement_speed_hl: 'Forhåndsvisning bevegelsesfart (diodelaser aktivert)',
        default_units: 'Standardenheter',
        default_font_family: 'Standardskrift',
        default_font_style: 'Standard skriftstil',
        fast_gradient: 'Hastighetsoptimalisering',
        engraving_direction: 'Retning',
        top_down: 'Oppned',
        bottom_up: 'Nedopp',
        vector_speed_constraint: 'Fartsgrense (20 mm/s)',
        loop_compensation: 'Sløyfekompensasjon',
        blade_radius: 'Bladradius',
        blade_precut_switch: 'Blad forhåndskutt',
        blade_precut_position: 'Forhåndskuttposisjon',
        default_beambox_model: 'Standard dokumentinnstilling',
        guides_origin: 'Veiledere opprinnelse',
        guides: 'Veiledere',
        image_downsampling: 'Bildekvalitet',
        anti_aliasing: 'Kantutjevning',
        continuous_drawing: 'Kontinuerlig tegning',
        trace_output: 'Bildesporingsutdata',
        single_object: 'Enkelt objekt',
        grouped_objects: 'Grupperte objekter',
        simplify_clipper_path: 'Forenklet bane',
        enable_low_speed: 'Aktiver lav hastighetsbevegelse',
        enable_custom_backlash: 'Aktiver egen slakkkompensasjon',
        custom_preview_height: 'Egen forhåndsvisningshøyde',
        mask: 'Arbeidsområdeklipping',
        text_path_calc_optimization: 'Baneberegningoptimalisering',
        font_substitute: 'Erstatt ustøttede tegn',
        font_convert: 'Tekst til bane konverterer',
        default_borderless_mode: 'Åpne bunn som standard',
        default_enable_autofocus_module: 'Autofokus som standard',
        default_enable_diode_module: 'Diodlaser som standard',
        diode_offset: 'Diodlaserforskyvning',
        autofocus_offset: 'Autofokusforskyvning',
        diode_one_way_engraving: 'Diodlaser enveisgravering',
        diode_two_way_warning: 'Toveis lysutsendelse er raskere og kan gi unøyaktig laserposisjon. Anbefales å teste først.',
        share_with_flux: 'Del Beam Studio-analyse',
        none: 'Ingen',
        close: 'Lukk',
        enabled: 'Aktivert',
        disabled: 'Deaktivert',
        cancel: 'Avbryt',
        done: 'Bruk',
        module_offset_10w: '10W diodlaserforskyvning',
        module_offset_20w: '20W diodlaserforskyvning',
        module_offset_printer: 'Skriverforskyvning',
        module_offset_2w_ir: '2W infrarød laserforskyvning',
        printer_advanced_mode: 'Avansert skrivermodus',
        default_laser_module: 'Standard lasermodul',
        low_laser_for_preview: 'Laser for Kjører ramme',
        groups: {
            general: 'Generelt',
            update: 'Programvareoppdateringer',
            connection: 'Tilkobling',
            autosave: 'Autospar',
            camera: 'Kamera',
            editor: 'Rediger',
            engraving: 'Rastering (Skanning)',
            path: 'Vektor (Konturer)',
            mask: 'Arbeidsområdeklipping',
            text_to_path: 'Tekst',
            modules: 'Tilleggsmodul',
            ador_modules: 'Ador-moduler',
            privacy: 'Personvern',
        },
        notification_on: 'På',
        notification_off: 'Av',
        update_latest: 'Siste',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Gruppe',
            use: 'Importer SVG',
            image: 'Bilde',
            text: 'Tekst',
        },
        context_menu: {
            cut: 'Klipp ut',
            copy: 'Kopier',
            paste: 'Lim inn',
            paste_in_place: 'Lim inn på plass',
            duplicate: 'Dupliser',
            delete: 'Slett',
            group: 'Grupper',
            ungroup: 'Del opp gruppe',
            move_front: 'Flytt fremst',
            move_up: 'Flytt fremover',
            move_down: 'Flytt bakover',
            move_back: 'Flytt bakerst',
        },
        popup: {
            select_import_method: 'Velg importmetode:',
            select_import_module: 'Velg modul:',
            touchpad: 'Touchpad',
            mouse: 'Mus',
            layer_by_layer: 'Lag',
            layer_by_color: 'Farge',
            nolayer: 'Enkelt lag',
            loading_image: 'Laster inn bilde, vennligst vent...',
            no_support_text: 'Beam Studio støtter ikke tekst-tag for øyeblikket. Konverter tekst til bane før import.',
            speed_too_high_lower_the_quality: 'For høy hastighet med denne oppløsningen kan resultere i lavere kvalitet på skyggegravering.',
            both_power_and_speed_too_high: 'Bruk av lavere laserstyrke vil forlenge levetiden til laser-røret.\nOgså, for høy hastighet med denne oppløsningen kan resultere i lavere kvalitet på skyggegravering.',
            too_fast_for_path: 'For høy hastighet i lag som inneholder baner kan resultere i lavere presisjon ved kutting.\nVi anbefaler ikke å bruke hastigheter over 20 mm/s ved kutting.',
            too_fast_for_path_and_constrain: 'Følgende lag: %s\ninneholder vektorbaner, og har hastighet over 20 mm/s.\nKuttehastigheten for vektorbaner vil bli begrenset til 20 mm/s.\nDu kan fjerne denne begrensningen under Innstillinger.',
            should_update_firmware_to_continue: '#814 Fastvaren din støtter ikke denne versjonen av Beam Studio. Oppdater fastvaren for å fortsette. (Meny > Maskin > [Din maskin] > Oppdater fastvare)',
            recommend_downgrade_software: 'Vi oppdaget en eldre fastvareversjon. Vi jobber med kompatibilitetsproblemer, men foreløpig anbefaler vi å gå tilbake til <a target="_blank" href="https://beamstudio.s3.ap-northeast-1.amazonaws.com/mac/Beam+Studio+1.9.5.dmg">Beam Studio 1.9.5</a>.',
            recommend_upgrade_firmware: 'Vi oppdaget en eldre fastvareversjon. Vi jobber aktivt med kompatibilitetsproblemer, men foreløpig anbefaler vi å oppdatere til nyeste fastvare.',
            still_continue: 'Fortsett',
            more_than_two_object: 'For mange objekter. Støtter kun 2 objekter',
            not_support_object_type: 'Støtter ikke objekttypen',
            select_first: 'Velg et objekt først.',
            select_at_least_two: 'Velg to objekter for å fortsette',
            import_file_contain_invalid_path: '#808 Importert SVG-fil inneholder ugyldig bildebane. Sjekk at alle bildefiler eksisterer eller integrer bildet i filen',
            import_file_error_ask_for_upload: 'Kunne ikke importere SVG-filen. Ønsker du å sende filen til utviklingsteamet for feilrapportering?',
            upload_file_too_large: '#819 Filen er for stor til opplasting.',
            successfully_uploaded: 'Filopplasting vellykket.',
            upload_failed: '#819 Filopplasting mislyktes.',
            or_turn_off_borderless_mode: 'Eller slå av åpen bunn-modus.',
            svg_1_1_waring: 'SVG-filen er i versjon 1.1, det kan være potensielle kompatibilitetsproblemer.',
            svg_image_path_waring: 'Denne SVG-filen inneholder <image> som laster fra filbane. Dette kan føre til feil ved lasting.\nFor å unngå denne risikoen, vennligst bruk innebygd bilde ved eksport av SVG.',
            dxf_version_waring: 'Versjonen av denne DXF-filen er ikke 2013, det kan være potensielle kompatibilitetsproblemer.',
            dont_show_again: 'Ikke vis dette neste gang.',
            convert_to_path_fail: 'Kunne ikke konvertere til bane.',
            save_unsave_changed: 'Vil du lagre ulagrede endringer?',
            dxf_bounding_box_size_over: 'Tegnestørrelsen er utenfor arbeidsområdet. Flytt tegningen nærmere opprinnelsen i CAD-programvaren, eller kontroller at enheten er riktig satt.',
            progress: {
                uploading: 'Laster opp',
                calculating: 'Beregner',
            },
            backend_connect_failed_ask_to_upload: '#802 Feil oppstår kontinuerlig ved tilkobling til serveren. Vil du laste opp feilrapportloggen?',
            backend_error_hint: 'Funksjoner fungerer kanskje ikke ordentlig på grunn av serverfeil.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Feil ved konvertering av PDF til SVG:',
                error_pdf2svg_not_found: '#825 Feil: pdf2svg-kommandoen ble ikke funnet. Installer pdf2svg med pakkehåndtereren din (f.eks. "yum install pdf2svg" eller "apt-get install pdf2svg").',
            },
            ungroup_use: 'Dette vil oppheve grupperingen av importerte DXF eller SVG. Fordi filen kan inneholde et stort antall elementer, kan det ta tid å oppheve grupperingen. Er du sikker på at du vil fortsette?',
            vectorize_shading_image: 'Gradientbilder tar lengre tid å spore og er utsatt for støy. Slå av bildegradienten før du utfører.',
            change_workarea_before_preview: 'Arbeidsområdet for %s samsvarer ikke med det gjeldende arbeidsområdet. Vil du bytte gjeldende arbeidsområde?',
            bug_report: 'Feilrapport',
            sentry: {
                title: 'La oss gjøre Beam Studio bedre sammen',
                message: 'Godtar du å automatisk laste opp relevant informasjon til utviklingsteamet når feil oppstår?',
            },
            questionnaire: {
                caption: 'Hjelp oss med å fylle ut spørreskjemaet',
                message: 'Hjelp oss med å fylle ut spørreskjemaet for å gjøre Beam Studio bedre.',
                unable_to_get_url: 'Kunne ikke hente lenken til spørreskjemaet via internett. Bekreft internettilkoblingen.',
                no_questionnaire_available: 'Det er ingen spørreskjemaer tilgjengelig for øyeblikket.',
            },
            facebook_group_invitation: {
                title: 'Bli med i FLUX brukergruppe',
                message: 'Bli med i vår offisielle Facebook-gruppe for å koble deg sammen med andre FLUX laser-brukere, diskutere FLUX lasere, dele laserkunst og holde deg oppdatert på de siste oppdateringene på produktene våre. Vi gleder oss til å se deg der!',
                join_now: 'Telle meg inn',
                later: 'Kanskje senere',
                already_joined: 'Allerede deltatt',
                dont_show_again: 'Ikke vis dette igjen',
            },
            ai_credit: {
                relogin_to_use: 'Logg inn på nytt for å bruke denne funksjonen',
                insufficient_credit: 'Du har gått tom for kreditt',
                insufficient_credit_msg: 'Du kan ikke bruke %s. Gå til medlemssenteret og kjøp AI-kreditt',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Gå',
            },
            text_to_path: {
                caption: 'Tekst til bane konverterer 2.0',
                message: "Beam Studio introduserer nå en ny tekst til bane konverterer (Konverterer 2.0), som produserer mer pålitelige resultater! Vil du bytte til den nå? \nDu kan også justere denne innstillingen senere i 'Tekst til bane konverterer' delen i innstillingene.",
            },
        },
        zoom_block: {
            fit_to_window: 'Tilpass til vindu',
        },
        time_est_button: {
            calculate: 'Estimér tid',
            estimate_time: 'Estimert tid:',
        },
        left_panel: {
            unpreviewable_area: 'Blind sone',
            diode_blind_area: 'Hybrid laser blind sone',
            borderless_blind_area: 'Ikke-graveringsområde',
            borderless_preview: 'Åpen bunnmodus kameravisning',
            rectangle: 'Rektangel',
            ellipse: 'Ellipse',
            line: 'Linje',
            image: 'Bilde',
            text: 'Tekst',
            label: {
                cursor: 'Velg',
                photo: 'Bilde',
                text: 'Tekst',
                line: 'Linje',
                rect: 'Rektangel',
                oval: 'Oval',
                polygon: 'Polygon',
                pen: 'Penn',
                shapes: 'Elementer',
                array: 'Matrise',
                preview: 'Kamera forhåndsvisning',
                trace: 'Spor bilde',
                end_preview: 'Avslutt forhåndsvisning',
                clear_preview: 'Fjern forhåndsvisning',
                choose_camera: 'Kamera',
                live_feed: 'Direktestrøm',
                adjust_height: 'Juster høyde',
                qr_code: 'QR-kode',
                boxgen: 'Boxgen',
                my_cloud: 'Min sky',
                curve_engraving: {
                    title: '3D Kurve',
                    exit: 'Avslutt',
                    select_area: 'Velg område',
                    preview_3d_curve: 'Forhåndsvis 3D-kurve',
                    clear_area: 'Fjern valgt område',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Lag',
                objects: 'Objekter',
                path_edit: 'Stibane redigering',
            },
            layer_panel: {
                layer1: 'Lag 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Gravering',
                layer_cutting: 'Kutting',
                current_layer: 'Gjeldende lag',
                move_elems_to: 'Flytt element til:',
                notification: {
                    dupeLayerName: 'Det finnes allerede et lag med det navnet!',
                    newName: 'NYTT NAVN',
                    enterUniqueLayerName: 'Vennligst skriv inn et unikt lagnavn',
                    enterNewLayerName: 'Vennligst skriv inn det nye lagnavnet',
                    layerHasThatName: 'Laget har allerede det navnet',
                    QmoveElemsToLayer: "Flytte valgte elementer til lag '%s'?",
                    moveElemFromPrintingLayerTitle: 'Flytt valgt element til %s og konverter det til laserelement?',
                    moveElemFromPrintingLayerMsg: 'Vennligst merk at hvis du fullfører denne operasjonen, vil fargeinnstillingene for det valgte elementet bli fjernet og satt i henhold til %s.',
                    moveElemToPrintingLayerTitle: 'Flytt valgt element til %s og konverter det til et utskriftselement?',
                    moveElemToPrintingLayerMsg: 'Vennligst merk at hvis du fullfører denne operasjonen, vil innstillingene for det valgte elementet bli fjernet og satt i henhold til %s.',
                    splitColorTitle: 'Vil du utvide det valgte laget til CMYK-lag?',
                    splitColorMsg: 'Vær oppmerksom på at hvis du fortsetter med denne operasjonen, vil du ikke kunne gå tilbake til de opprinnelige fargelagene.',
                    mergeLaserLayerToPrintingLayerTitle: 'Vil du slå sammen disse lagene til ett utskriftslag?',
                    mergeLaserLayerToPrintingLayerMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil innstillingene for laserlaget bli fjernet og satt i henhold til gjeldende lag.',
                    mergePrintingLayerToLaserLayerTitle: 'Vil du slå sammen disse lagene til ett laserlag?',
                    mergePrintingLayerToLaserLayerMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil fargeinnstillingene for utskriftslaget bli fjernet og satt i henhold til gjeldende lag.',
                },
                layers: {
                    layer: 'Lag',
                    layers: 'Lag',
                    del: 'Slett lag',
                    move_down: 'Flytt lag ned',
                    new: 'Nytt lag',
                    rename: 'Gi lag nytt navn',
                    move_up: 'Flytt lag opp',
                    dupe: 'Dupliser lag',
                    lock: 'Lås lag',
                    unlock: 'Lås opp',
                    merge_down: 'Slå sammen nedover',
                    merge_all: 'Slå sammen alle',
                    merge_selected: 'Slå sammen valgte lag',
                    move_elems_to: 'Flytt elementer til:',
                    move_selected: 'Flytt valgte elementer til et annet lag',
                    switchToFullColor: 'Bytt til fullfargelag',
                    switchToSingleColor: 'Bytt til enkelt fargelag',
                    splitFullColor: 'Utvid lag',
                    fullColor: 'Fullfarge',
                },
            },
            laser_panel: {
                preset_setting: 'Parameterinnstillinger (%s)',
                multi_layer: 'Flere lag',
                parameters: 'Parametere',
                strength: 'Styrke',
                low_power_warning: 'Lavere laserkraft (under 10%) kan hende ikke sender ut laserlys.',
                speed: 'Hastighet',
                speed_contrain_warning: 'Kuttehastigheten for vektorbaner vil være begrenset til 20 mm/s. Du kan fjerne denne grensen under Innstillinger.',
                low_speed_warning: 'Lav hastighet kan føre til brenning av materialet.',
                repeat: 'Utfør',
                add_on: 'Tillegg',
                focus_adjustment: 'Fokuseringsjustering',
                height: 'Objekthøyde',
                z_step: 'Z-trinn',
                diode: 'Diodlaser',
                backlash: 'Tilbakeslag',
                ink_saturation: 'Metning',
                print_multipass: 'Flerpass',
                white_ink: 'Hvit blekk',
                white_ink_settings: 'Innstillinger for hvitt blekk',
                color_adjustment: 'Kanaljustering',
                color_adjustment_short: 'Kanal',
                halftone: 'Halvtoner',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Styrke',
                times: 'ganger',
                cut: 'Kutt',
                engrave: 'Graver',
                more: 'Administrer',
                delete: 'Slett',
                reset: 'Tilbakestill',
                sure_to_reset: 'Dette vil tilbakestille alle forhåndsinnstillinger og beholde dine tilpassede parametere, er du sikker på at du vil fortsette?',
                apply: 'Bruk',
                cancel: 'Avbryt',
                save: 'Lagre',
                save_and_exit: 'Lagre og lukk',
                name: 'Navn',
                default: 'Standard',
                customized: 'Tilpasset liste',
                inuse: 'I bruk',
                export_config: 'Eksporter forhåndsinnstillinger',
                new_config_name: 'Nytt forhåndsinnstillingsnavn',
                sure_to_load_config: 'Dette vil laste inn forhåndsinnstillinger og erstatte tilpassede parametere angitt i filen, er du sikker på at du vil fortsette?',
                custom_preset: 'Tilpasset',
                various_preset: 'Diverse forhåndsinnstillinger',
                module: 'Modul',
                slider: {
                    regular: 'Vanlig',
                    low: 'Lav',
                    very_low: 'Minimum',
                    high: 'Høy',
                    very_high: 'Maksimum',
                    slow: 'Sakte',
                    very_slow: 'Saktest',
                    fast: 'Rask',
                    very_fast: 'Raskest',
                },
                dropdown: {
                    parameters: 'Forhåndsinnstillinger',
                    save: 'Legg til gjeldende parametere',
                    export: 'Eksporter',
                    import: 'Importer',
                    more: 'Administrer',
                    mm: {
                        wood_3mm_cutting: 'Tre - 3 mm kutting',
                        wood_5mm_cutting: 'Tre - 5 mm kutting',
                        wood_7mm_cutting: 'Tre - 7 mm kutting',
                        wood_8mm_cutting: 'Tre - 8 mm kutting',
                        wood_10mm_cutting: 'Tre - 10 mm kutting',
                        wood_engraving: 'Tre - Gravering',
                        acrylic_3mm_cutting: 'Akryl - 3 mm kutting',
                        acrylic_5mm_cutting: 'Akryl - 5 mm kutting',
                        acrylic_8mm_cutting: 'Akryl - 8 mm skjæring',
                        acrylic_10mm_cutting: 'Akryl - 10 mm skjæring',
                        acrylic_engraving: 'Akryl - gravering',
                        mdf_3mm_cutting: 'New Zealand MDF - 3mm Skjæring',
                        mdf_5mm_cutting: 'New Zealand MDF - 5mm Skjæring',
                        mdf_engraving: 'New Zealand MDF - Gravering',
                        leather_3mm_cutting: 'Lær - 3 mm skjæring',
                        leather_5mm_cutting: 'Lær - 5 mm skjæring',
                        leather_engraving: 'Lær - gravering',
                        denim_1mm_cutting: 'Denim - 1mm Skjæring',
                        fabric_3mm_cutting: 'Stoff - 3 mm skjæring',
                        fabric_5mm_cutting: 'Stoff - 5 mm skjæring',
                        fabric_engraving: 'Stoff - gravering',
                        rubber_bw_engraving: 'Gummi - gravering',
                        glass_bw_engraving: 'Glass - gravering',
                        metal_bw_engraving: 'Metall - gravering',
                        steel_engraving_spray_engraving: 'Metall - gravering',
                        stainless_steel_bw_engraving_diode: 'Metall - gravering (diode laser)',
                        gold_engraving: 'Gull - Gravering',
                        brass_engraving: 'Messing - Gravering',
                        ti_engraving: 'Titan - Gravering',
                        stainless_steel_engraving: 'Rustfritt stål - Gravering',
                        aluminum_engraving: 'Aluminium - Gravering',
                        black_acrylic_3mm_cutting: 'Svart akryl - 3mm Skjæring',
                        black_acrylic_5mm_cutting: 'Svart akryl - 5mm Skjæring',
                        black_acrylic_engraving: 'Svart akryl - Gravering',
                        abs_engraving: 'ABS - Gravering',
                        silver_engraving: 'Sølv - Gravering',
                        iron_engraving: 'Jern - Gravering',
                        fabric_printing: 'Stoff - Utskrift',
                        canvas_printing: 'Lerret - Utskrift',
                        cardstock_printing: 'Kartong - Utskrift',
                        wood_printing: 'Tre - Utskrift',
                        bamboo_printing: 'Bambus - Utskrift',
                        cork_printing: 'Kork - Utskrift',
                        flat_stone_printing: 'Flat stein - Utskrift',
                        acrylic_printing: 'Akryl - Utskrift',
                        pc_printing: 'PC - Utskrift',
                        stainless_steel_printing: 'Rustfritt stål - Utskrift',
                        gloss_leather_printing: 'Blankt lær - Utskrift',
                        glass_printing: 'Glass - Utskrift',
                    },
                    inches: {
                        wood_3mm_cutting: 'Tre - 0,1" skjæring',
                        wood_5mm_cutting: 'Tre - 0,2" skjæring',
                        wood_7mm_cutting: 'Tre - 0,3" Skjæring',
                        wood_8mm_cutting: 'Tre - 0,3" skjæring',
                        wood_10mm_cutting: 'Tre - 0,4" skjæring',
                        wood_engraving: 'Tre - gravering',
                        acrylic_3mm_cutting: 'Akryl - 0,1" skjæring',
                        acrylic_5mm_cutting: 'Akryl - 0,2" skjæring',
                        acrylic_8mm_cutting: 'Akryl - 0,3" skjæring',
                        acrylic_10mm_cutting: 'Akryl - 0,4" skjæring',
                        acrylic_engraving: 'Akryl - gravering',
                        mdf_3mm_cutting: 'MDF - 0,1" Skjæring',
                        mdf_5mm_cutting: 'MDF - 0,2" Skjæring',
                        mdf_engraving: 'MDF - Gravering',
                        leather_3mm_cutting: 'Lær - 0,1" skjæring',
                        leather_5mm_cutting: 'Lær - 0,2" skjæring',
                        leather_engraving: 'Lær - gravering',
                        denim_1mm_cutting: 'Denim - 0,04" Skjæring',
                        fabric_3mm_cutting: 'Stoff - 0,1" skjæring',
                        fabric_5mm_cutting: 'Stoff - 0,2" skjæring',
                        fabric_engraving: 'Stoff - gravering',
                        rubber_bw_engraving: 'Gummi - gravering',
                        glass_bw_engraving: 'Glass - gravering',
                        metal_bw_engraving: 'Metall - gravering',
                        steel_engraving_spray_engraving: 'Metall - gravering',
                        stainless_steel_bw_engraving_diode: 'Metall - gravering (diode laser)',
                        gold_engraving: 'Gull - Gravering',
                        brass_engraving: 'Messing - Gravering',
                        ti_engraving: 'Titan - Gravering',
                        stainless_steel_engraving: 'Rustfritt stål - Gravering',
                        aluminum_engraving: 'Aluminium - Gravering',
                        black_acrylic_3mm_cutting: 'Svart akryl - 0,1" Skjæring',
                        black_acrylic_5mm_cutting: 'Svart akryl - 0,2" Skjæring',
                        black_acrylic_engraving: 'Svart akryl - Gravering',
                        abs_engraving: 'ABS - Gravering',
                        silver_engraving: 'Sølv - Gravering',
                        iron_engraving: 'Jern - Gravering',
                        fabric_printing: 'Stoff - Utskrift',
                        canvas_printing: 'Lerret - Utskrift',
                        cardstock_printing: 'Kartong - Utskrift',
                        wood_printing: 'Tre - Utskrift',
                        bamboo_printing: 'Bambus - Utskrift',
                        cork_printing: 'Kork - Utskrift',
                        flat_stone_printing: 'Flat stein - Utskrift',
                        acrylic_printing: 'Akryl - Utskrift',
                        pc_printing: 'PC - Utskrift',
                        stainless_steel_printing: 'Rustfritt stål - Utskrift',
                        gloss_leather_printing: 'Blankt lær - Utskrift',
                        glass_printing: 'Glass - Utskrift',
                    },
                },
                laser_speed: {
                    text: 'Hastighet',
                    unit: 'mm/s',
                    fast: 'Rask',
                    slow: 'Sakte',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Kraft',
                    high: 'Høy',
                    low: 'Lav',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Blekktype',
                    normal: 'Blekk',
                    UV: 'UV-blekk',
                },
                para_in_use: 'Denne parameteren er i bruk.',
                do_not_adjust_default_para: 'Standardinnstillinger kan ikke justeres.',
                existing_name: 'Dette navnet er allerede i bruk.',
                presets: 'Forhåndsinnstilling',
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Gruppere',
                ungroup: 'Del opp',
                distribute: 'Distribuer',
                hdist: 'Horisontal distribusjon',
                vdist: 'Vertikal distribusjon',
                align: 'Juster',
                left_align: 'Venstrejuster',
                center_align: 'Midtstill',
                right_align: 'Høyrejuster',
                top_align: 'Toppjuster',
                middle_align: 'Midtstill vertikalt',
                bottom_align: 'Bunnjustér',
                boolean: 'Boolsk',
                union: 'Union',
                subtract: 'Subtraksjon',
                intersect: 'Skjæringspunkt',
                difference: 'Forskjell',
                flip: 'Snur',
                hflip: 'Horisontal snur',
                vflip: 'Vertikal snur',
                lock_aspect: 'Lås størrelsesforhold',
                unlock_aspect: 'Lås opp størrelsesforhold',
                option_panel: {
                    fill: 'Fyll',
                    rounded_corner: 'Avrundet hjørne',
                    sides: 'Sider',
                    font_family: 'Skrifttype',
                    font_style: 'Stil',
                    font_size: 'Størrelse',
                    letter_spacing: 'Bokstavavstand',
                    line_spacing: 'Linjeavstand',
                    vertical_text: 'Vertikal tekst',
                    start_offset: 'Tekstforskyvning',
                    vertical_align: 'Justering',
                    text_infill: 'Tekstutfylling',
                    path_infill: 'Baneutfylling',
                    shading: 'Gradient',
                    threshold: 'Terskel lysstyrke',
                    threshold_short: 'Terskel',
                    stroke: 'Strek',
                    stroke_color: 'Strekfarge',
                    stroke_width: 'Stregbredde',
                    color: 'Farge',
                },
                actions_panel: {
                    replace_with: 'Erstatt med...',
                    replace_with_short: 'Erstatt',
                    trace: 'Spor',
                    grading: 'Justering',
                    brightness: 'Lysstyrke',
                    sharpen: 'Skarphet',
                    crop: 'Beskjær',
                    bevel: 'Fas',
                    invert: 'Inverter',
                    weld_text: 'Slå sammen tekst',
                    convert_to_path: 'Konverter til bane',
                    fetching_web_font: 'Henter nettfont...',
                    uploading_font_to_machine: 'Laster opp font til maskinen...',
                    wait_for_parsing_font: 'Tolker font... Vennligst vent et øyeblikk',
                    offset: 'Forskyvning',
                    array: 'Matrise',
                    auto_arrange: 'Automatisk Ordne',
                    decompose_path: 'Dekomponer',
                    disassemble_use: 'Demonter',
                    create_textpath: 'Lag tekstbane',
                    create_textpath_short: 'Tekstbane',
                    detach_path: 'Dekomponer tekstbane',
                    detach_path_short: 'Dekomponer',
                    edit_path: 'Rediger bane',
                    disassembling: 'Demonterer...',
                    ungrouping: 'Grupperer opp...',
                    simplify: 'Forenklet',
                    ai_bg_removal: 'Bakgrunnsfjerning',
                    ai_bg_removal_short: 'BG-fjerning',
                    ai_bg_removal_reminder: 'Ved å trykke på knappen bruker du umiddelbart 0,2 kreditt. Vil du fortsette?',
                    outline: 'Omriss',
                },
                path_edit_panel: {
                    node_type: 'NODETYPE',
                    sharp: 'Skarp',
                    round: 'Rund',
                    connect: 'Koble til',
                    disconnect: 'Koble fra',
                    delete: 'Slett',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Konverter tekst til bane...',
            retreive_image_data: 'Hente bildedata...',
            export_file_error_ask_for_upload: 'Kunne ikke eksportere oppgave. Er du villig til å sende arbeidsscene til utviklingsteamet for feilrapportering?',
        },
        image_trace_panel: {
            apply: 'Bruk',
            back: 'Tilbake',
            cancel: 'Avbryt',
            next: 'Neste',
            brightness: 'Lysstyrke',
            contrast: 'Kontrast',
            threshold: 'Terskel',
            okay: 'OK',
            tuning: 'Innstillinger',
        },
        photo_edit_panel: {
            apply: 'Bruk',
            back: 'Tilbake',
            cancel: 'Avbryt',
            next: 'Neste',
            sharpen: 'Skarphet',
            sharpness: 'Skarphet',
            radius: 'Radius',
            crop: 'Beskjær',
            aspect_ratio: 'Størrelsesforhold',
            original: 'Opprinnelig',
            free: 'Fritt',
            curve: 'Kurve',
            start: 'Start',
            processing: 'Behandler',
            invert: 'Inverter farge',
            okay: 'OK',
            compare: 'Sammenligne',
            phote_edit: 'Bildebehandling',
            brightness_and_contrast: 'Lysstyrke / Kontrast',
            brightness: 'Lysstyrke',
            contrast: 'Kontrast',
        },
        document_panel: {
            document_settings: 'Dokumentinnstillinger',
            engrave_parameters: 'Graveringsparametere',
            workarea: 'Arbeidsområde',
            rotary_mode: 'Roterende',
            borderless_mode: 'Åpen bunn',
            engrave_dpi: 'Oppløsning',
            enable_diode: 'Diode-laser',
            enable_autofocus: 'Autofokus',
            extend_workarea: 'Utvid arbeidsområdet',
            add_on: 'Tillegg',
            low: 'Lav',
            medium: 'Medium',
            high: 'Høy',
            ultra: 'Ultra høy',
            enable: 'Aktiver',
            disable: 'Deaktiver',
            cancel: 'Avbryt',
            save: 'Lagre',
            notification: {
                changeFromPrintingWorkareaTitle: 'Vil du konvertere Utskriftslag til Laserlag?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Analyserer skrifttype ... Vennligst vent et øyeblikk',
            text_to_path: {
                font_substitute_pop: 'Teksten din inneholder tegn som ikke støttes av gjeldende skrift. <br/>Vil du bruke <strong>%s</strong> som erstatning?',
                check_thumbnail_warning: 'Noen tekster ble endret til andre skrifttyper når tekst ble konvertert til baner, og noen tegn ble kanskje ikke konvertert normalt. \nSjekk forhåndsvisningsbildet igjen før du sender oppgaven.',
                error_when_parsing_text: 'Feil ved konvertering av tekst til bane',
                use_current_font: 'Bruk gjeldende skrifttype',
                retry: 'Prøv igjen senere eller velg en annen skrift',
            },
            lock_desc: 'Bevar forholdet mellom bredde og høyde (SHIFT)',
        },
        tool_panels: {
            cancel: 'Avbryt',
            confirm: 'Bekreft',
            grid_array: 'Lag rutenett',
            array_dimension: 'Rutenettdimensjon',
            rows: 'Rader',
            columns: 'Kolonner',
            array_interval: 'Rutenettintervall',
            dx: 'X',
            dy: 'Y',
            offset: 'Forskyvning',
            nest: 'Plassering',
            _offset: {
                direction: 'Forskyvningsretning',
                inward: 'Innover',
                outward: 'Utad',
                dist: 'Forskyvningsavstand',
                corner_type: 'Hjørne',
                sharp: 'Skarp',
                round: 'Rund',
                fail_message: 'Kunne ikke forskyve objekter',
                not_support_message: 'Valgte elementer som inneholder ikke-støttede SVG-tagger:\nBilde, Gruppe, Tekst og Importert objekt',
            },
            _nest: {
                start_nest: 'Plassere',
                stop_nest: 'Stopp',
                end: 'Lukk',
                spacing: 'Mellomrom',
                rotations: 'Mulig rotasjon',
                no_element: 'Det er ingen elementer å plassere',
            },
        },
        network_testing_panel: {
            network_testing: 'Nettverkstest',
            local_ip: 'Lokal IP-adresse:',
            insert_ip: 'Målenhetens IP-adresse:',
            empty_ip: '#818 Vennligst skriv inn målenhetens IP-adresse først',
            start: 'Start',
            end: 'Slutt',
            testing: 'Tester nettverk...',
            invalid_ip: '#818 Ugyldig IP-adresse',
            ip_startswith_169: '#843 Maskin-IP-adressen starter med 169.254',
            connection_quality: 'Tilkoblingskvalitet',
            average_response: 'Gjennomsnittlig responstid',
            test_completed: 'Test fullført',
            test_fail: 'Test mislyktes',
            cannot_connect_1: '#840 Kunne ikke koble til mål-IP.',
            cannot_connect_2: '#840 Kunne ikke koble til mål-IP. Vennligst kontroller at målet er i samme nettverk.',
            network_unhealthy: '#841 Dårlig tilkoblingskvalitet eller høy responstid',
            device_not_on_list: '#842 Maskinen er ikke på listen, men god tilkoblingskvalitet',
            hint_device_often_on_list: 'Maskinen blir ofte ikke funnet på listen?',
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: 'Mislyktes å koble til ved jobbsending?',
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: 'Tidsavbrudd ved kameraoppkobling?',
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: 'Kunne ikke hente lokal IP-adresse.',
            fail_to_start_network_test: '#817 Kunne ikke starte nettverkstest.',
            linux_permission_hint: 'Denne feilen oppstår vanligvis på grunn av utilstrekkelige rettigheter.\nKjør "sudo beam-studio --no-sandbox" i terminalen for å få rettigheter og utføre nettverkstest.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Lagfargekonfigurasjoner',
            color: 'Farge',
            power: 'Effekt',
            speed: 'Hastighet',
            repeat: 'Gjenta',
            add: 'Legg til',
            save: 'Lagre',
            cancel: 'Avbryt',
            default: 'Tilbakestill til standard',
            add_config: 'Legg til farge',
            in_use: 'Denne fargen er i bruk.',
            no_input: 'Vennligst skriv inn gyldig hex fargekode.',
            sure_to_reset: 'Du vil miste alle tilpassede innstillinger, er du sikker på at du vil tilbakestille til standard?',
            sure_to_delete: 'Er du sikker på at du vil slette denne fargeinnstillingen?',
        },
        rating_panel: {
            title: 'Liker du Beam Studio?',
            description: 'Hvis du liker Beam Studio, setter vi stor pris på om du kunne vurdere oss.',
            dont_show_again: 'Ikke vis dette neste gang.',
            thank_you: 'Takk!',
        },
        svg_editor: {
            unnsupported_file_type: 'Filtypen støttes ikke direkte. Konverter filen til SVG eller bitmap',
            unable_to_fetch_clipboard_img: 'Kunne ikke hente bilde fra utklippstavlen',
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Spill av',
            pause: 'Pause',
            stop: 'Stopp',
            play_speed: 'Avspillingshastighet',
            travel_path: 'Reisevei',
            invert: 'Inverter',
            preview_info: 'Forhåndsvisningsinformasjon',
            size: 'Størrelse',
            estimated_time: 'Estimert totaltid',
            cut_time: 'Skjæretid',
            rapid_time: 'Reisetid',
            cut_distance: 'Skjæreavstand',
            rapid_distance: 'Reiseavstand',
            current_position: 'Gjeldende posisjon',
            remark: '* All informasjon er estimert verdi for referanse.',
            start_here: 'Start her',
            end_preview: 'Avslutt forhåndsvisning',
        },
        shapes_panel: {
            title: 'Elementer',
            basic: 'Grunnleggende',
            shape: 'Form',
            graphics: 'Grafikk',
            arrow: 'Pil',
            label: 'Etikett',
            decor: 'Dekor',
            circular: 'Sirkulær',
            corner: 'Hjørne',
            line: 'Linje',
            photo: 'Foto',
            ribbon: 'Bånd',
            speech: 'Tale',
            text: 'Tekstboks',
            animals: 'Dyr',
            birds: 'Fugler',
            land: 'Land',
            sea: 'Hav',
            holidays: 'Høytider',
            celebration: 'Feiring',
            CNY: 'Kinesisk nyttår',
            easter: 'Påske',
            halloween: 'Halloween',
            valentines: 'Valentinsdag',
            Xmas: 'Jul',
            nature: 'Natur',
            elements: 'Elementer',
            environment: 'Miljø',
            plants: 'Planter',
            weather: 'Vær',
        },
        announcement_panel: {
            title: 'Kunngjøring',
            dont_show_again: 'Ikke vis igjen',
        },
    },
    editor: {
        prespray_area: 'Primeområde',
        opacity: 'Forhåndsvisning gjennomsiktighet',
        exposure: 'Forhåndsvisningseksponering',
    },
    flux_id_login: {
        connection_fail: '#847 Kunne ikke koble til FLUX medlemstjeneste.',
        login_success: 'Logget inn.',
        login: 'Logg inn',
        unlock_shape_library: 'Logg inn for å låse opp formdatabasen.',
        email: 'E-post',
        password: 'Passord',
        remember_me: 'Husk meg',
        forget_password: 'Glemt passord?',
        register: 'Opprett FLUX-kontoen din',
        offline: 'Jobb offline',
        work_offline: 'Jobb offline',
        incorrect: 'E-postadresse eller passord er ikke riktig.',
        not_verified: 'E-postadressen er ikke verifisert ennå.',
        new_to_flux: 'Ny til FLUX? Opprett en konto.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Utforsk FLUX+ planene',
            thank_you: 'Takk for å være et verdsatt medlem!',
            ai_credit_tooltip: 'For AI bakgrunnsfjerning',
            flux_credit_tooltip: 'For Design Market filer og AI bakgrunnsfjerning',
            goto_member_center: 'Gå til medlemssenteret',
            access_plus_feature_1: 'Du aksesserer en',
            access_plus_feature_2: 'funksjon.',
            access_plus_feature_note: 'Du må ha FLUX+ medlemskap for å aksessere denne funksjonen.',
            access_monotype_feature: 'Du har ikke Monotype Fonts-tillegget.',
            access_monotype_feature_note: 'Du må ha FLUX+ Pro medlemskap eller Monotype Fonts-tillegg for å aksessere denne funksjonen.',
            learn_more: 'Lær mer',
            get_addon: 'Få tillegget',
            subscribe_now: 'Abonner nå',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'AI bakgrunnsfjerning',
                my_cloud: 'Ubegrenset skylagring',
                boxgen: '3D boks generator',
                dmkt: '1000+ designfiler',
                monotype: '250+ premium skrifttyper',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Logg inn for å låse opp formdatabasen.',
        enjoy_shape_library: 'Nyt formdatabasen.',
        shapes: 'Former',
        elements: 'Elementer',
        recent: 'Nylig',
        search: 'Søk',
        clear: 'Tøm',
        export_svg_title: 'Kan ikke eksportere SVG',
        export_svg_warning: 'Dette prosjektet inneholder objekter som er beskyttet av opphavsretten. Derfor vil Beam Studio automatisk utelate disse objektene under eksporten. Du kan fremdeles lagre prosjektet ditt i Beam Studio Scene (.beam-format) for å beholde alle dataene dine. Vil du fremdeles eksportere?',
        learn_more: 'Lær mer',
    },
    change_logs: {
        change_log: 'Endringslogger:',
        added: 'Lagt til:',
        fixed: 'Fikset:',
        changed: 'Endret:',
        see_older_version: 'Se eldre versjoner',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Velg enhet',
        auth_failure: '#811 Autentisering mislyktes',
        unable_to_connect: '#810 Kunne ikke opprette en stabil tilkobling til maskinen',
    },
    device: {
        pause: 'Pause',
        paused: 'Pauset',
        pausing: 'Pauser',
        select_printer: 'Velg skriver',
        retry: 'Prøv igjen',
        status: 'Status',
        busy: 'Opptatt',
        ready: 'Klar',
        reset: 'Tilbakestill',
        abort: 'Avbryt',
        start: 'Start',
        please_wait: 'Vennligst vent...',
        quit: 'Avslutt',
        completing: 'Fullfører',
        aborted: 'Avbrutt',
        completed: 'Fullført',
        aborting: 'Avbryter',
        starting: 'Starter',
        preparing: 'Forbereder',
        resuming: 'Gjenopptar',
        scanning: 'Skanner',
        occupied: 'Opptatt',
        running: 'Kjører',
        uploading: 'Laster opp',
        processing: 'Behandler',
        disconnectedError: {
            caption: 'Maskinen frakoblet',
            message: 'Bekreft om nettverkstilgangen til %s er tilgjengelig',
        },
        unknown: 'Ukjent',
        pausedFromError: 'Pauset grunnet feil',
        model_name: 'Modellnavn',
        IP: 'IP',
        serial_number: 'Serienummer',
        firmware_version: 'Firmwareversjon',
        UUID: 'UUID',
        select: 'Velg',
        deviceList: 'Enhetsliste',
        disable: 'Deaktiver',
        enable: 'Aktiver',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    },
    monitor: {
        monitor: 'OVERVÅK',
        go: 'Start',
        resume: 'Gjenoppta',
        start: 'Start',
        pause: 'Pause',
        stop: 'Stopp',
        record: 'Ta opp',
        camera: 'Kamera',
        taskTab: 'Oppgave',
        connecting: 'Kobler til, vennligst vent...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Kritisk feil: Hovedkort frakoblet. Kontakt FLUX-støtte.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Kritisk feil: Undersystem svarer ikke. Kontakt FLUX-støtte.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Sjekk vannbeholderen.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Lukk døren for å fortsette.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Overopphetet. Vent noen minutter.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Bunn åpnet. Lukk bunnen for å fortsette.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Z-akse nullstilling mislyktes',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Skuffen åpnet',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Flammesensor unormal',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Luftstrømsensor unormal',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Ingen roterende modul oppdaget',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Homing Pull-Off-feil',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Feil modul oppdaget. Installer riktig modul for å fortsette.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modul ikke oppdaget. Kontroller at modulen er riktig installert for å fortsette.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Ukjent modul oppdaget. Installer riktig modul for å fortsette.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Ingen respons fra skrivermodul.',
        USER_OPERATION_ROTARY_PAUSE: 'Vennligst bytt til roterende motor',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Ta ut patronen for å fortsette.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Feil modul oppdaget. Installer riktig modul for å fortsette.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modul ikke oppdaget. Kontroller at modulen er riktig installert for å fortsette.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Ukjent modul oppdaget. Installer riktig modul for å fortsette.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Installer riktig modul for å fortsette.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Vennligst sett inn patronen for å fortsette.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Vennligst sett inn riktig patron for å fortsette.',
        USER_OPERATION: 'Følg instruksjonene på enhetspanelet for å fortsette.',
        RESOURCE_BUSY: 'Maskinen er opptatt. Hvis den ikke kjører, vennligst start maskinen på nytt',
        DEVICE_ERROR: 'Noe gikk galt. Vennligst start maskinen på nytt',
        NO_RESPONSE: '#905 Feil ved tilkobling til hovedkort. Vennligst start maskinen på nytt.',
        SUBSYSTEM_ERROR: '#402 Kritisk feil: Delsystem svarer ikke. Vennligst kontakt FLUX-support.',
        HARDWARE_FAILURE: 'Noe gikk galt. Vennligst start maskinen på nytt',
        MAINBOARD_OFFLINE: '#905 Feil ved tilkobling til hovedkort. Vennligst start maskinen på nytt.',
        bug_report: 'Feilrapport',
        processing: 'Behandler',
        savingPreview: 'Genererer miniatyrbilder',
        hour: 't',
        minute: 'm',
        second: 's',
        left: 'venstre',
        temperature: 'Temperatur',
        forceStop: 'Ønsker du å avbryte gjeldende oppgave?',
        upload: 'Last opp',
        download: 'Last ned',
        relocate: 'Flytt',
        cancel: 'Avbryt',
        prepareRelocate: 'Forbereder for flytting',
        extensionNotSupported: 'Dette filformatet støttes ikke',
        fileExistContinue: 'Filen finnes allerede, vil du erstatte den?',
        confirmFileDelete: 'Er du sikker på at du vil slette denne filen?',
        ask_reconnect: 'Tilkoblingen til maskinen ble brutt. Vil du koble til på nytt?',
        task: {
            BEAMBOX: 'Lasergravering',
            'N/A': 'Fritt modus',
        },
    },
    alert: {
        caption: 'Feil',
        duplicated_preset_name: 'Duplisert forhåndsinnstilt navn',
        info: 'INFO',
        warning: 'ADVARSEL',
        error: 'FEIL',
        instruction: 'Instruksjon',
        oops: 'Oops...',
        retry: 'Prøv igjen',
        abort: 'Avbryt',
        confirm: 'Bekreft',
        cancel: 'Avbryt',
        close: 'Lukk',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Ja',
        no: 'Nei',
        stop: 'Stopp',
        save: 'Lagre',
        dont_save: 'Ikke lagre',
        learn_more: 'Lær mer',
    },
    caption: {
        connectionTimeout: 'Tilkoblingstidsavbrudd',
    },
    message: {
        cancelling: 'Avbryter...',
        connecting: 'Kobler til...',
        connectingMachine: 'Kobler til %s...',
        tryingToConenctMachine: 'Prøver å koble til maskin...',
        connected: 'Tilkoblet',
        authenticating: 'Autentiserer...',
        enteringRawMode: 'Går inn i råmodus...',
        endingRawMode: 'Forlater råmodus...',
        enteringLineCheckMode: 'Går inn i linjesjekkmodus...',
        endingLineCheckMode: 'Avslutter linjekontrollmodus...',
        exitingRotaryMode: 'Avslutter roteringsmodus...',
        turningOffFan: 'Slår av vifte...',
        turningOffAirPump: 'Slår av luftpumpe...',
        gettingLaserSpeed: 'Leser lasershodehastighet...',
        settingLaserSpeed: 'Angir lasershodehastighet...',
        retrievingCameraOffset: 'Leser kameraforskyvning...',
        connectingCamera: 'Kobler til kamera...',
        homing: 'Hjemkjører...',
        connectionTimeout: '#805 Tilkobling til enhet tidsavbrudd. Sjekk nettverkstilstanden og maskinens Wi-Fi-indikator.',
        getProbePosition: 'Henter probeposisjon...',
        device_not_found: {
            caption: 'Standardmaskin ikke funnet',
            message: '#812 Sjekk maskinens Wi-Fi-indikator',
        },
        device_busy: {
            caption: 'Maskinen er opptatt',
            message: 'Maskinen utfører en annen oppgave, prøv igjen senere. Hvis den slutter å fungere, start maskinen på nytt.',
        },
        device_is_used: 'Maskinen er i bruk, vil du avbryte gjeldende oppgave?',
        monitor_too_old: {
            caption: 'Fastvare utdatert',
            content: '#814 Installer nyeste fastvare med <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">denne veiledningen</a>.',
        },
        unknown_error: '#821 Programmet har støtt på en ukjent feil, bruk Hjelp > Meny > Rapporter feil.',
        unknown_device: '#826 Kan ikke koble til maskinen, kontroller at USB er tilkoblet maskinen',
        unsupport_osx_version: 'Gjeldende macOS-versjon %s støtter kanskje ikke alle funksjoner. Oppdater til macOS 11+.',
        unsupport_win_version: 'Gjeldende OS-versjon %s støtter kanskje ikke alle funksjoner. Oppdater til nyeste versjon.',
        need_password: 'Trenger passord for å koble til maskinen',
        unavailableWorkarea: '#804 Gjeldende arbeidsområde overskrider maskinens arbeidsområde. Sjekk arbeidsområdet for valgt maskin eller angi arbeidsområde fra Rediger > Dokumentinnstillinger.',
        please_enter_dpi: 'Angi enheten for filen (i mm)',
        auth_error: '#820 Autentiseringsfeil: Oppdater Beam Studio og maskinens fastvare til nyeste versjon.',
        usb_unplugged: 'USB-tilkobling tapt. Sjekk USB-tilkoblingen',
        uploading_fcode: 'Laster opp FCode',
        cant_connect_to_device: '#827 Kan ikke koble til maskinen, sjekk tilkoblingen',
        unable_to_find_machine: 'Kan ikke finne maskin ',
        disconnected: 'Ustabil tilkobling, sjekk enhetstilkobling og prøv igjen senere',
        unable_to_start: '#830 Kan ikke starte oppgaven. Prøv igjen. Hvis dette skjer igjen, kontakt oss med en feilrapport:',
        camera: {
            camera_cable_unstable: 'Det er oppdaget at kameraet overfører bilder ustabilt. Forhåndsvisning kan fortsatt utføres normalt, men det kan være problemer med treg forhåndsvisning eller tidsavbrudd.',
            fail_to_transmit_image: '#845 Noe gikk galt med bildeoverføringen. Start maskinen eller Beam Studio på nytt. Hvis denne feilen vedvarer, følg denne veiledningen.',
            ws_closed_unexpectly: '#844 Tilkoblingen til maskinkameraet ble uventet lukket. Hvis denne feilen vedvarer, følg denne veiledningen.',
            continue_preview: 'Fortsett',
            abort_preview: 'Avbryt',
        },
        preview: {
            camera_preview: 'Kameravisning',
            auto_focus: 'Autofokus',
            auto_focus_instruction: 'Flytt laserhodet over objektet og følg animasjonsinstruksjonene for å trykke AF for å fokusere.',
            already_performed_auto_focus: 'Du har allerede utført autofokus, bruke eksisterende verdier?',
            please_enter_height: 'Oppgi høyden på objektet for å ta presis kamerabilde.',
            apply: 'Bruk',
            enter_manually: 'Oppgi manuelt',
            adjust: 'Juster',
            adjust_height_tooltip: 'Klikk avkrysningsboksen for å aktivere redigering.',
        },
        unsupported_example_file: 'Det valgte eksempelfilen støttes ikke av gjeldende arbeidsområde.',
        time_remaining: 'Gjenstående tid:',
    },
    machine_status: {
        '0': 'Ledig',
        '1': 'Initialiserer',
        '2': 'ST_TRANSFORM',
        '4': 'Starter',
        '6': 'Gjenopptar',
        '16': 'Arbeider',
        '18': 'Gjenopptar',
        '32': 'Pauset',
        '36': 'Pauset',
        '38': 'Setter på pause',
        '48': 'Pauset',
        '50': 'Setter på pause',
        '64': 'Fullført',
        '66': 'Fullfører',
        '68': 'Forbereder',
        '128': 'Avbrutt',
        '256': 'Alarm',
        '512': 'Fatal',
        '-17': 'Patron IO-modus',
        '-10': 'Vedlikeholdsmodus',
        '-2': 'Skanner',
        '-1': 'Vedlikeholder',
        UNKNOWN: 'Ukjent',
    },
    calibration: {
        update_firmware_msg1: 'Fastvaren støtter ikke denne funksjonen. Oppdater fastvare til v',
        update_firmware_msg2: 'eller nyere for å fortsette。 (Meny > Maskin > [Din maskin] > Oppdater fastvare)',
        camera_calibration: 'Kamerajustering',
        diode_calibration: 'Diode lasermoduljustering',
        module_calibration_printer: 'Skrivermoduljustering',
        module_calibration_2w_ir: 'Infrarød moduljustering',
        back: 'TILBAKE',
        next: 'NESTE',
        skip: 'HOPP OVER',
        cancel: 'AVBRYT',
        finish: 'FERDIG',
        do_engraving: 'UTFØR GRAVERING',
        start_engrave: 'START GRAVERING',
        start_printing: 'START UTSKRIFT',
        ask_for_readjust: 'Vil du hoppe over graveringstrinnet og deretter ta bilde og kalibrere direkte?',
        please_goto_beambox_first: 'Vennligst bytt til graveringsmodus (Beambox) for å bruke denne funksjonen.',
        please_place_paper: 'Vennligst plasser et A4- eller brevstørrelse hvitt papir i venstre øverste hjørne av arbeidsområdet.',
        please_place_paper_center: 'Vennligst plasser et A4- eller brevstørrelse hvitt papir i midten av arbeidsområdet.',
        please_place_dark_colored_paper: 'Vennligst plasser et A4- eller brevstørrelse mørkt papir i midten av arbeidsområdet.',
        please_refocus: {
            beambox: 'Vennligst juster plattformen til fokuspunktet (høyden på nedsnudd akryl)',
            beamo: 'Vennligst juster laserhodet for å fokusere på graveringsobjektet (høyden på nedsnudd akryl)',
            beamo_af: 'Vennligst dobbelttrykk sideknappen på autofokustillegget og la sonden mykt berøre materialet.',
            hexa: 'Dobbeltklikk på høydejusteringsknappen for å heve honningkakebordet opp og la sonden berøre graveringsmaterialet.',
        },
        without_af: 'Uten autofokus',
        with_af: 'Med autofokus',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotasjon',
        x_ratio: 'X-forhold',
        y_ratio: 'Y-forhold',
        show_last_config: 'Vis siste resultat',
        use_last_config: 'Bruk siste kalibreringsverdi',
        taking_picture: 'Tar bilde...',
        analyze_result_fail: 'Kunne ikke analysere bildet som ble tatt.<br/>Sjekk:<br/>1. Bildet dekker hele det hvite papiret.<br/>2. Plattformen er fokusert ordentlig.',
        drawing_calibration_image: 'Tegner kalibreringsbilde...',
        calibrate_done: 'Kalibrering fullført. Bedre kamera-nøyaktighet oppnås ved presis fokusering.',
        calibrate_done_diode: 'Kalibrering fullført. Forskyvningen til diodlasermodulen er lagret.',
        hint_red_square: 'Vennligst juster den røde firkanten til den stemmer overens med den skårne firkanten.',
        hint_adjust_parameters: 'Vennligst bruk disse parameterne for å justere den røde firkanten',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: 'Når du kalibrerer diode lasermodulen, trengs kamera.\nVennligst sørg for at kameraet på denne maskinen er kalibrert.\nOg vennligst juster plattformen til brennpunktet (høyden på nedvendt akryl)',
            beamo: 'Når du kalibrerer diode lasermodulen, trengs kamera.\nVennligst sørg for at kameraet på denne maskinen er kalibrert.\nOg vennligst juster laserhodet for å fokusere på graveringsobjektet (høyden på nedvendt akryl)',
        },
        downloading_pictures: 'Laster ned bilder...',
        failed_to_download_pictures: '#848 Kunne ikke laste ned bilder, vennligst kontakt FLUX-støtte.',
        uploading_images: 'Laster opp bilder...',
        calculating_camera_matrix: 'Beregner kameramatrise...',
        calculating_regression_parameters: 'Beregner regresjonsparametere...',
        failed_to_calibrate_camera: '#848 Kunne ikke kalibrere kamera, vennligst kontakt FLUX-støtte.',
        failed_to_save_calibration_results: '#849 Kunne ikke lagre kalibreringsresultater, vennligst prøv igjen. Hvis dette fortsetter, vennligst kontakt FLUX-støtte.',
        ador_autofocus_material: 'Trykk på "AF"-ikonet på hovedsiden til maskinen i 3 sekunder og la sonden berøre materialet forsiktig.',
        ador_autofocus_focusing_block: 'Trykk på "AF"-ikonet på hovedsiden til maskinen i 3 sekunder og la sonden berøre fokuseringsblokken.',
        align_red_cross_cut: 'Vennligst juster midten av det røde korset med kors-snittet.',
        align_red_cross_print: 'Vennligst juster midten av det røde korset med det utskrevne korset.',
        retake: 'Ta bilde på nytt',
        calibrate_camera_before_calibrate_modules: 'Vennligst utfør kamera-kalibrering før du kalibrerer moduler.',
        check_checkpoint_data: 'Kontrollpunktdata',
        checking_checkpoint: 'Sjekker kontrollpunktdata...',
        found_checkpoint: 'Kontrollpunktdata funnet på enheten din. Vil du gjenopprette fra kontrollpunktet?',
        use_old_camera_parameter: 'Vil du bruke gjeldende kamerobjektivparametere?',
        downloading_checkpoint: 'Laster ned kontrollpunktdata...',
        failed_to_parse_checkpoint: 'Kunne ikke analysere kontrollpunktdata.',
        check_device_pictures: 'Sjekk enhetsbilder',
        checking_pictures: 'Sjekker enhetsbilder...',
        no_picutre_found: '#846 Enheten din har ingen tilgjengelige rå bilder for kalibrering. Vennligst kontakt FLUX-støtte.',
        calibrating_with_device_pictures: 'Kalibrerer med enhetsbilder...',
        failed_to_calibrate_with_pictures: '#847 Klarte ikke å kalibrere med enhetsbilder.',
        getting_plane_height: 'Få Høyde på Plan...',
        preparing_to_take_picture: 'Forbereder for å ta bilde...',
        put_paper: 'Legg papir',
        put_paper_step1: 'Vennligst plasser et A4- eller brevstørrelse hvitt papir i midten av arbeidsområdet.',
        put_paper_step2: 'Fest de fire hjørnene på papiret for å sikre at det ligger flatt.',
        put_paper_step3: 'Klikk på "START GRAVERING".',
        solve_pnp_title: 'Juster markørpunkter',
        solve_pnp_step1: 'Vennligst juster graveringpunktene i henhold til nummeret og den forventede posisjonen til hvert røde markørpunkt.',
        solve_pnp_step2: 'Du kan trykke på "Ta bilde på nytt" for å justere på nytt eller manuelt justere markørposisjonene.',
        align_olt: 'Posisjon: Juster med det graverte punktet øverst til venstre på utsiden.',
        align_ort: 'Posisjon: Juster med det graverte punktet øverst til høyre på utsiden.',
        align_olb: 'Posisjon: Juster med det graverte punktet nederst til venstre på utsiden.',
        align_orb: 'Posisjon: Juster med det graverte punktet nederst til høyre på utsiden.',
        align_ilt: 'Posisjon: Juster med det graverte punktet øverst til venstre på innsiden.',
        align_irt: 'Posisjon: Juster med det graverte punktet øverst til høyre på innsiden.',
        align_ilb: 'Posisjon: Juster med det graverte punktet nederst til venstre på innsiden.',
        align_irb: 'Posisjon: Juster med det graverte punktet nederst til høyre på innsiden.',
        elevate_and_cut: 'Heve og Kutte',
        elevate_and_cut_step_1: 'Plasser en A4-størrelse lysfarget tre midt i arbeidsområdet, hevet til 20mm.',
        elevate_and_cut_step_1_prism_lift: 'Bruk Ador Prism Lift med maksimal lengde på 14mm sammen med et tre på minst 6mm tykkelse for å løfte treet til en høyde på 20mm.',
        with_prism_lift: 'Med Ador Prism Lift',
        without_prism_lift: 'Uten Ador Prism Lift',
        camera_parameter_saved_successfully: 'Kameraparametrene er lagret.',
        failed_to_save_camera_parameter: 'Feilet i å lagre kameraparametre.',
        failed_to_solve_pnp: 'Klarte ikke å løse kameraposisjonen.',
    },
    input_machine_password: {
        require_password: '"%s" krever passord',
        connect: 'KOBLE TIL',
        password: 'Passord',
    },
    tutorial: {
        skip: 'Hopp over',
        welcome: 'VELKOMMEN',
        suggest_calibrate_camera_first: 'Vi anbefaler brukere å kalibrere kameraet først og fokusere på nytt før hver forhåndsvisning for optimale resultater.<br/>Vil du bekrefte å utføre kalibreringen nå?<br/>(Du kan hoppe over det nå og gjøre det senere ved å klikke på "Meny" > "Maskin" > [Din Maskin] > "Kalibrer Kamera".)',
        camera_calibration_failed: 'Kamerakalibrering mislyktes',
        ask_retry_calibration: 'Vil du kalibrere kameraet på nytt?',
        needNewUserTutorial: 'Vil du starte en veiledning?<br/>(Du kan hoppe over den nå og starte senere ved å klikke på "Hjelp" > "Vis Startveiledning".)',
        needNewInterfaceTutorial: 'Vil du starte en veiledning for Beam Studios nye grensesnitt?<br/>(Du kan hoppe over den nå og starte senere ved å klikke på "Hjelp" > "Vis Grensesnittintroduksjon".)',
        next: 'NESTE',
        look_for_machine: 'Søker etter maskin for opplæring...',
        unable_to_find_machine: 'Kunne ikke finne maskin for opplæring. Vil du gå til tilkoblingsside, prøve på nytt eller hoppe over opplæring?',
        skip_tutorial: 'Du har hoppet over opplæringen. Du kan alltid starte opplæringen ved å klikke "Hjelp" > "Vis startopplæring"',
        set_connection: 'Angi tilkobling',
        retry: 'Prøv igjen',
        newUser: {
            draw_a_circle: 'Tegn en sirkel',
            drag_to_draw: 'Dra for å tegne',
            infill: 'Slå på utfylling',
            switch_to_object_panel: 'Bytt til objektpanel',
            switch_to_layer_panel: 'Bytt til lagpanel',
            set_preset_wood_engraving: 'Angi forhåndsinnstilling: Tre - Gravering',
            set_preset_wood_cut: 'Angi forhåndsinnstilling: Tre - Skjæring',
            add_new_layer: 'Legg til et nytt lag',
            draw_a_rect: 'Tegn et rektangel',
            switch_to_preview_mode: 'Bytt til forhåndsvisningsmodus',
            preview_the_platform: 'Forhåndsvis plattformen',
            end_preview_mode: 'Avslutt forhåndsvisningsmodus',
            put_wood: '1. Legg i prøvetreet',
            adjust_focus: '2. Juster fokus',
            close_cover: '3. Lukk dekselet',
            send_the_file: 'Send filen',
            end_alert: 'Er du sikker på at du vil avslutte opplæringen?',
            please_select_wood_engraving: 'Vennligst velg "Tre - Gravering" forhåndsinnstilling.',
            please_select_wood_cutting: 'Vennligst velg "Tre - Skjæring" forhåndsinnstilling.',
        },
        newInterface: {
            camera_preview: 'Kameraforhåndsvisning',
            select_image_text: 'Velg / Bilde / Tekst',
            basic_shapes: 'Grunnleggende former',
            pen_tool: 'Pennverktøy',
            add_new_layer: 'Legg til nytt lag',
            rename_by_double_click: 'Gi nytt navn ved å dobbeltklikke',
            drag_to_sort: 'Dra for å sortere',
            layer_controls: 'Høyreklikk for å velge lagkontroller:\nDupliser / Slå sammen / Lås / Slett lag',
            switch_between_layer_panel_and_object_panel: 'Bytt mellom lags-panel og objekt-panel',
            align_controls: 'Justeringskontroller',
            group_controls: 'Gruppekontroller',
            shape_operation: 'Formoperasjon',
            flip: 'Vend',
            object_actions: 'Objekt-handlinger',
            end_alert: 'Er du sikker på at du vil avslutte introduksjonen til nytt grensesnitt?',
            select_machine: 'Velg en maskin',
            start_work: 'Start arbeid',
        },
        gesture: {
            pan: 'Rull arbeidsområdet med to fingre.',
            zoom: 'Klyp med to fingre for å zoome inn/ut arbeidsområdet.',
            click: 'Trykk for å velge objektet.',
            drag: 'Dra for å velge flere objekter.',
            hold: 'Trykk og hold for å åpne hurtigmenyen.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Det var alt for opplæringen. Nå er det på tide å begynne å lage!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: '10W Diode Laser',
        laser_20w_diode: '20W Diode Laser',
        laser_2w_infrared: '2W Infrarød Laser',
        printing: 'Utskrift',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Vil du konvertere Utskriftsmodulen til Lasermodul?',
            convertFromPrintingModuleMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil fargeinnstillingene for utskriftslaget fjernes og settes i henhold til gjeldende lag.',
            convertFromLaserModuleTitle: 'Vil du konvertere Lasermodulen til Utskriftsmodul?',
            convertFromLaserModuleMsg: 'Vær oppmerksom på at hvis du fullfører denne operasjonen, vil innstillingene for laserlaget fjernes og settes i henhold til gjeldende lag.',
            importedDocumentContainsPrinting: 'Dokumentet inneholder utskriftslag, vil du endre arbeidsområde til Ador?',
            printingLayersCoverted: 'Skriverlagene har blitt konvertert til laserskriverlag.',
            performPrintingCaliTitle: 'Utfør utskriftsmodulkalibrering',
            performPrintingCaliMsg: 'Klikk på "Bekreft" for å utføre kalibreringen, eller gå til kalibrering via toppmenyen.<br />(Maskin > [Maskinnavn] > Kalibrer utskriftsmodul)',
            performIRCaliTitle: 'Utfør infrarød modulkalibrering',
            performIRCaliMsg: 'Klikk på "Bekreft" for å utføre kalibreringen, eller gå til kalibrering via toppmenyen.<br />(Maskin > [Maskinnavn] > Kalibrer infrarød modul)',
        },
        non_working_area: 'Ikke-Arbeidsområde',
    },
    qr_code_generator: {
        title: 'QR-kodegenerator',
        placeholder: 'Skriv inn en lenke eller tekst',
        preview: 'Forhåndsvisning',
        error_tolerance: 'Feiltoleranse',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Inverter bakgrunnsfarge',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Grunnleggende boks',
        coming_soon: 'Kommer snart',
        workarea: 'Arbeidsområde',
        max_dimension_tooltip: 'Maks bredde/høyde/dybde er %s.',
        volume: 'Volum',
        outer: 'Ytre',
        inner: 'Indre',
        width: 'Bredde',
        height: 'Høyde',
        depth: 'Dybde',
        cover: 'Deksel',
        thickness: 'Tykkelse',
        add_option: 'Legg til valg',
        joints: 'Sammenføyning',
        finger: 'Finger',
        finger_warning: 'Den indre sidelengden på boksen må være minst 6 mm (0,24 tommer) for å være kompatibel med fingerføyning.',
        tSlot: 'T-spor',
        tSlot_warning: 'Bokssidelengden må være minst 30 mm (1,18 tommer) for å være kompatibel med T-sporføyning.',
        edge: 'Kant',
        tCount: 'T-antall',
        tCount_tooltip: 'Antall T-spor gjelder for den korte siden; mengden på den lange siden beregnes basert på lengden.',
        tDiameter: 'T-diameter',
        tLength: 'T-lengde',
        continue_import: 'Fortsett å importere',
        customize: 'Tilpass',
        merge: 'Slå sammen',
        text_label: 'Tekstetikett',
        beam_radius: 'Kompensasjon for kuttebredde',
        beam_radius_warning: 'Fjern kompensasjon for kuttebredde når kantene eller sammenføyningene på boksen er korte for å sikre riktig sammensetting',
        import: 'Importer',
        cancel: 'Avbryt',
        reset: 'Tilbakestill',
        zoom: 'Zoom',
        control_tooltip: 'Venstre mus for å rotere\nRull for å zoome\nHøyre mus for å panorere',
        control_tooltip_touch: 'Dra for å rotere\nKlyp for å zoome\nTo fingre for å panorere',
    },
    my_cloud: {
        title: 'Min sky',
        loading_file: 'Laster inn...',
        no_file_title: 'Lagre filer til Min sky for å komme i gang.',
        no_file_subtitle: 'Gå til Meny > "Fil" > "Lagre til skyen"',
        file_limit: 'Gratis fil',
        upgrade: 'Oppgrader',
        sort: {
            most_recent: 'Nylig',
            oldest: 'Eldst',
            a_to_z: 'Navn: A - Å',
            z_to_a: 'Navn: Å - A',
        },
        action: {
            open: 'Åpne',
            rename: 'Gi nytt navn',
            duplicate: 'Dupliser',
            download: 'Last ned',
            delete: 'Slett',
            confirmFileDelete: 'Er du sikker på at du vil slette denne filen? Denne handlingen kan ikke angres.',
        },
        save_file: {
            choose_action: 'Lagre fil:',
            save: 'Lagre',
            save_new: 'Lagre som ny fil',
            input_file_name: 'Skriv inn filnavn:',
            invalid_char: 'Ugyldige tegn:',
            storage_limit_exceeded: 'Skyen din har nådd øvre grense. Vennligst slett unødvendige filer før du lagrer nye.',
        },
    },
    camera_data_backup: {
        title: 'Sikkerhetskopiering av kameradata',
        no_picture_found: 'Ingen bilde funnet i maskinen.',
        folder_not_exists: 'Valgt mappe eksisterer ikke.',
        incorrect_folder: 'Kunne ikke laste opp kalibreringsdataene. Sjekk om mappen du har valgt er riktig.',
        downloading_data: 'Laster ned data...',
        estimated_time_left: 'Estimert gjenværende tid:',
        uploading_data: 'Laster opp data...',
        download_success: 'Kameradata er lastet ned.',
        upload_success: 'Kameradata er lastet opp.',
    },
    insecure_websocket: {
        extension_detected: 'Beam Studio Connect-utvidelsen er oppdaget',
        extension_detected_description: "Vi har oppdaget at du har installert Beam Studio Connect-utvidelsen. Klik på 'Bekreft' for å omdirigere til HTTPS, eller klikk på 'Avbryt' for å fortsette å bruke HTTP.",
        extension_not_deteced: 'Kunne ikke oppdage Beam Studio Connect-utvidelsen',
        extension_not_deteced_description: "For å bruke HTTPS, klikk på 'Bekreft' for å installere Beam Studio Connect-utvidelsen. Etter å ha installert utvidelsen, vennligst oppdater siden for å aktivere den.<br/>Hvis ikke, klikk på lenken nedenfor for å se hvordan du bruker HTTP i Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Lenke</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Mål autofokusområde',
        amount: 'Mengde',
        gap: 'Avstand',
        rows: 'Rader',
        coloumns: 'Kolonner',
        row_gap: 'Rader Avstand',
        column_gap: 'Kolonner Avstand',
        set_object_height: 'Sett objektets høyde',
        set_object_height_desc: 'Mål den maksimale tykkelsen på objektet.',
        measure_area_help: 'Hvordan velge autofokusområdet for 3D-kurve?',
        reselect_area: 'Velg område på nytt',
        start_autofocus: 'Start autofokus',
        starting_measurement: 'Starter måling...',
        preview_3d_curve: 'Forhåndsvis 3D-kurve',
        apply_arkwork: 'Bruk kunstverk på 3D-kurve',
        apply_camera: 'Bruk kamerabilde på 3D-kurve',
        click_to_select_point: 'Klikk for å velge eller velge bort punkter for å måle på nytt.',
        remeasure: 'Mål på nytt',
        remeasuring_points: 'Måler punkter på nytt...',
    },
    pass_through: {
        title: 'Gå Gjennom-Modus',
    },
};
export default lang;
