import * as React from 'react';
import alert from 'app/actions/alert-caller';
import Controls from 'app/components/settings/Control';
import i18n from 'helpers/i18n';
import SelectControl from 'app/components/settings/SelectControl';
import UnitInput from 'app/widgets/Unit-Input-v2';
import { OptionValues } from 'app/constants/enums';
import { getWorkarea } from 'app/constants/workarea-constants';
const Module = ({ defaultUnit, selectedModel, diodeOffsetX, diodeOffsetY, borderlessModeOptions, autofocusModuleOptions, autofocusOffset, diodeModuleOptions, diodeOneWayEngravingOpts, updateBeamboxPreferenceChange, }) => {
    const { lang } = i18n;
    const workarea = getWorkarea(selectedModel);
    const onDiodeOneWayEngravingChanged = (e) => {
        if (e.target.value === OptionValues.FALSE) {
            alert.popUp({ message: lang.settings.diode_two_way_warning });
        }
        updateBeamboxPreferenceChange('diode-one-way-engraving', e.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.modules),
        React.createElement(SelectControl, { label: lang.settings.default_borderless_mode, url: lang.settings.help_center_urls.default_borderless_mode, id: "default-open-bottom", options: borderlessModeOptions, onChange: (e) => updateBeamboxPreferenceChange('default-borderless', e.target.value) }),
        React.createElement(SelectControl, { label: lang.settings.default_enable_autofocus_module, url: lang.settings.help_center_urls.default_enable_autofocus_module, id: "default-autofocus", options: autofocusModuleOptions, onChange: (e) => updateBeamboxPreferenceChange('default-autofocus', e.target.value) }),
        React.createElement(SelectControl, { label: lang.settings.default_enable_diode_module, url: lang.settings.help_center_urls.default_enable_diode_module, id: "default-diode", options: diodeModuleOptions, onChange: (e) => updateBeamboxPreferenceChange('default-diode', e.target.value) }),
        React.createElement(Controls, { label: lang.settings.diode_offset },
            React.createElement("span", { className: "font2", style: { marginRight: '10px', lineHeight: '32px' } }, "X"),
            React.createElement(UnitInput, { id: "diode-offset-x-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: workarea.width, defaultValue: diodeOffsetX || 0, getValue: (val) => updateBeamboxPreferenceChange('diode_offset_x', val), forceUsePropsUnit: true, className: { half: true } }),
            React.createElement("span", { className: "font2", style: { marginRight: '10px', lineHeight: '32px' } }, "Y"),
            React.createElement(UnitInput, { id: "diode-offset-y-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: 0, max: workarea.height, defaultValue: diodeOffsetY || 0, getValue: (val) => updateBeamboxPreferenceChange('diode_offset_y', val), forceUsePropsUnit: true, className: { half: true } })),
        React.createElement(SelectControl, { label: lang.settings.diode_one_way_engraving, id: "default-diode", options: diodeOneWayEngravingOpts, onChange: onDiodeOneWayEngravingChanged }),
        React.createElement(Controls, { label: lang.settings.autofocus_offset },
            React.createElement(UnitInput, { id: "autofocus-offset-input", unit: defaultUnit === 'inches' ? 'in' : 'mm', min: -10, max: 10, defaultValue: autofocusOffset || 0, step: defaultUnit === 'inches' ? 0.1 : 1, getValue: (val) => updateBeamboxPreferenceChange('af-offset', val), forceUsePropsUnit: true, className: { half: true } }))));
};
export default Module;
