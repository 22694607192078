import * as React from 'react';
import Controls from 'app/components/settings/Control';
import i18n from 'helpers/i18n';
import isDev from 'helpers/is-dev';
import SelectControl from 'app/components/settings/SelectControl';
import UnitInput from 'app/widgets/Unit-Input-v2';
function Engraving({ fastGradientOptions, reverseEngravingOptions, updateBeamboxPreferenceChange, paddingAccel, paddingAccelDiode, }) {
    const { lang } = i18n;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "subtitle" }, lang.settings.groups.engraving),
        React.createElement(SelectControl, { id: "set-fast-gradient", label: lang.settings.fast_gradient, url: lang.settings.help_center_urls.fast_gradient, options: fastGradientOptions, onChange: (e) => updateBeamboxPreferenceChange('fast_gradient', e.target.value) }),
        React.createElement(SelectControl, { id: "set-reverse-engraving", label: lang.settings.engraving_direction, options: reverseEngravingOptions, onChange: (e) => updateBeamboxPreferenceChange('reverse-engraving', e.target.value) }),
        isDev() && (React.createElement(React.Fragment, null,
            React.createElement(Controls, { label: "Padding Accel" },
                React.createElement(UnitInput, { id: "hardware-acceleration", unit: "mm/s^2", min: 1, max: 12000, decimal: 0, defaultValue: paddingAccel.defaultValue, getValue: paddingAccel.getValue, className: { half: true } })),
            React.createElement(Controls, { label: "Padding Accel HL" },
                React.createElement(UnitInput, { id: "hardware-acceleration", unit: "mm/s^2", min: 1, max: 12000, decimal: 0, defaultValue: paddingAccelDiode.defaultValue, getValue: paddingAccelDiode.getValue, className: { half: true } }))))));
}
export default Engraving;
