import classNames from 'classnames';
import React from 'react';
import Draggable from 'react-draggable';
class DraggableWindow extends React.PureComponent {
    renderTrafficLight() {
        const { onClose } = this.props;
        return (React.createElement("div", { className: classNames('traffic-lights') },
            React.createElement("div", { className: classNames('traffic-light', 'traffic-light-close'), onClick: onClose || (() => { }) })));
    }
    render() {
        const { title, children, defaultPosition, containerClass, handleClass, } = this.props;
        return (
        // @ts-expect-error 'Draggable' cannot be used as a JSX component.
        React.createElement(Draggable, { handle: ".handle", defaultClassName: classNames('react-draggable', containerClass), defaultPosition: defaultPosition, bounds: "body" },
            React.createElement("div", null,
                React.createElement("div", { className: classNames('handle', handleClass) }, title),
                this.renderTrafficLight(),
                children)));
    }
}
export default DraggableWindow;
