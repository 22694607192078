import DeviceConstants from 'app/constants/device-constants';
import i18n from 'helpers/i18n';
export var ButtonTypes;
(function (ButtonTypes) {
    ButtonTypes[ButtonTypes["NONE"] = 0] = "NONE";
    ButtonTypes[ButtonTypes["PLAY"] = 1] = "PLAY";
    ButtonTypes[ButtonTypes["DISABLED_PLAY"] = 2] = "DISABLED_PLAY";
    ButtonTypes[ButtonTypes["PAUSE"] = 3] = "PAUSE";
    ButtonTypes[ButtonTypes["DISABLED_PAUSE"] = 4] = "DISABLED_PAUSE";
    ButtonTypes[ButtonTypes["STOP"] = 5] = "STOP";
    ButtonTypes[ButtonTypes["DISABLED_STOP"] = 6] = "DISABLED_STOP";
})(ButtonTypes || (ButtonTypes = {}));
const statusButtonTypeMap = {};
statusButtonTypeMap[DeviceConstants.status.INIT] = [
    ButtonTypes.DISABLED_PLAY,
];
statusButtonTypeMap[DeviceConstants.status.STARTING] = [
    ButtonTypes.DISABLED_PLAY,
];
statusButtonTypeMap[DeviceConstants.status.RESUME_TO_STARTING] = [
    ButtonTypes.DISABLED_STOP,
    ButtonTypes.DISABLED_PAUSE,
];
statusButtonTypeMap[DeviceConstants.status.RUNNING] = [
    ButtonTypes.STOP,
    ButtonTypes.PAUSE,
];
statusButtonTypeMap[DeviceConstants.status.RESUME_TO_RUNNING] = [
    ButtonTypes.DISABLED_STOP,
    ButtonTypes.DISABLED_PAUSE,
];
statusButtonTypeMap[DeviceConstants.status.PAUSED] = [
    ButtonTypes.STOP,
    ButtonTypes.PLAY,
];
statusButtonTypeMap[DeviceConstants.status.PAUSED_FROM_STARTING] = [
    ButtonTypes.STOP,
    ButtonTypes.PLAY,
];
statusButtonTypeMap[DeviceConstants.status.PAUSING_FROM_STARTING] = [
    ButtonTypes.STOP,
    ButtonTypes.DISABLED_PLAY,
];
statusButtonTypeMap[DeviceConstants.status.PAUSED_FROM_RUNNING] = [
    ButtonTypes.STOP,
    ButtonTypes.PLAY,
];
statusButtonTypeMap[DeviceConstants.status.PAUSING_FROM_RUNNING] = [
    ButtonTypes.STOP,
    ButtonTypes.DISABLED_PLAY,
];
statusButtonTypeMap[DeviceConstants.status.COMPLETED] = [
    ButtonTypes.PLAY,
];
statusButtonTypeMap[DeviceConstants.status.COMPLETING] = [
    ButtonTypes.DISABLED_STOP,
    ButtonTypes.DISABLED_PAUSE,
];
statusButtonTypeMap[DeviceConstants.status.PREPARING] = [
    ButtonTypes.DISABLED_STOP,
    ButtonTypes.DISABLED_PLAY,
];
statusButtonTypeMap[DeviceConstants.status.ABORTED] = [
    ButtonTypes.DISABLED_STOP,
    ButtonTypes.PLAY,
];
statusButtonTypeMap[DeviceConstants.status.ABORTING] = [
    ButtonTypes.DISABLED_STOP,
    ButtonTypes.DISABLED_PAUSE,
];
statusButtonTypeMap[DeviceConstants.status.ALARM] = [
    ButtonTypes.STOP,
    ButtonTypes.DISABLED_PLAY,
];
statusButtonTypeMap[DeviceConstants.status.FATAL] = [
    ButtonTypes.STOP,
    ButtonTypes.DISABLED_PLAY,
];
export default {
    getDisplayStatus: (stLabel) => {
        if (!stLabel)
            return '';
        const key = stLabel.replace(/^"+|"+$/g, '');
        const statusMap = {
            IDLE: i18n.lang.device.ready,
            INIT: i18n.lang.device.starting,
            STARTING: i18n.lang.device.starting,
            RUNNING: i18n.lang.device.running,
            PAUSED: i18n.lang.device.paused,
            PAUSING: i18n.lang.device.pausing,
            COMPLETING: i18n.lang.device.completing,
            COMPLETED: i18n.lang.device.completed,
            ABORTING: i18n.lang.device.aborting,
            ABORTED: i18n.lang.device.aborted,
            RESUMING: i18n.lang.device.starting,
            OCCUPIED: i18n.lang.device.occupied,
            SCANNING: i18n.lang.device.scanning,
            PREPARING: i18n.lang.device.completed,
        };
        return statusMap[key] || stLabel || '';
    },
    isAbortedOrCompleted: (report) => {
        if (!report)
            return false;
        return (report.st_id === DeviceConstants.status.ABORTED
            || report.st_id === DeviceConstants.status.COMPLETED);
    },
    getControlButtonType: (report) => {
        if (!report) {
            return [];
        }
        if (statusButtonTypeMap[report.st_id]) {
            return statusButtonTypeMap[report.st_id];
        }
        return [];
    },
    allowedCameraStatus: [
        DeviceConstants.status.IDLE,
        DeviceConstants.status.RUNNING,
        DeviceConstants.status.PAUSED,
        DeviceConstants.status.PAUSED_FROM_STARTING,
        DeviceConstants.status.PAUSED_FROM_RUNNING,
        DeviceConstants.status.COMPLETED,
        DeviceConstants.status.ABORTED,
    ],
};
