const lang = {
    general: {
        wait: 'Elaborazione in corso, attendere prego',
        choose_folder: 'Scegli cartella',
    },
    buttons: {
        next: 'AVANTI',
        back: 'INDIETRO',
        done: 'FATTO',
        back_to_beam_studio: 'Torna a Beam Studio',
    },
    topbar: {
        untitled: 'Senza titolo',
        titles: {
            settings: 'Preferenze',
        },
        export: 'ESPORTA',
        preview: 'ANTEPRIMA',
        preview_title: 'Anteprima',
        curve_engrave: 'Curva 3D',
        task_preview: 'Anteprima percorso',
        frame_task: 'Esecuzione cornice',
        borderless: '(APRI A SCHERMO INTERO)',
        tag_names: {
            rect: 'Rettangolo',
            ellipse: 'Ovale',
            path: 'Percorso',
            polygon: 'Poligono',
            image: 'Immagine',
            text: 'Testo',
            text_path: 'Testo su percorso',
            line: 'Linea',
            g: 'Gruppo',
            multi_select: 'Più oggetti',
            use: 'Oggetto importato',
            svg: 'Oggetto SVG',
            dxf: 'Oggetto DXF',
            no_selection: 'Nessuna selezione',
        },
        alerts: {
            start_preview_timeout: '#803 Timeout durante l\'avvio della modalità anteprima. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">questa guida</a>.',
            fail_to_start_preview: '#803 Impossibile avviare la modalità anteprima. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">questa guida</a>.',
            fail_to_connect_with_camera: '#803 Impossibile stabilire la connessione con la videocamera della macchina. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/360001111355">questa guida</a>.',
            add_content_first: 'Si prega di aggiungere prima gli oggetti',
            headtype_mismatch: 'Modulo non corrispondente rilevato. ',
            headtype_none: 'Modulo non rilevato. ',
            headtype_unknown: 'Modulo sconosciuto rilevato. ',
            install_correct_headtype: "Installare correttamente i moduli laser da 10W/20W per abilitare il laser a bassa potenza per l'esecuzione della cornice.",
            door_opened: "Chiudere lo sportello per abilitare il laser a bassa potenza per l'esecuzione della cornice.",
            fail_to_get_door_status: "Assicurarsi che lo sportello sia chiuso per abilitare il laser a bassa potenza per l'esecuzione della cornice.",
            QcleanScene: 'Vuoi cancellare il disegno?<br/>Questo cancellerà anche la tua cronologia di annullamento!',
            power_too_high: 'POTENZA TROPPO ALTA',
            power_too_high_msg: 'Utilizzare una potenza laser inferiore (sotto il 70%) estende la durata del tubo laser.\nInserire "HO CAPITO" per procedere.',
            power_too_high_confirm: 'HO CAPITO',
        },
        hint: {
            polygon: 'Premere + / - per aumentare / diminuire i lati.',
        },
        menu: {
            preferences: 'Preferenze',
            hide: 'Nascondi Beam Studio',
            hideothers: 'Nascondi altri',
            service: 'Servizi',
            quit: 'Esci',
            window: 'Finestra',
            minimize: 'Riduci a icona',
            close: 'Chiudi finestra',
            file: 'File',
            edit: 'Modifica',
            help: 'Aiuto',
            open: 'Apri',
            samples: 'Esempi',
            import_hello_beamo: 'Esempio di beamo',
            import_hello_beambox: 'Esempio di beambox',
            import_material_testing_old: 'Test incisione materiale - Classico',
            import_material_testing_simple_cut: 'Test taglio materiale - Semplice',
            import_material_testing_cut: 'Test taglio materiale',
            import_material_testing_engrave: 'Test incisione materiale',
            import_material_testing_line: 'Test linea materiale',
            import_material_printing_test: 'Test di stampa materiale',
            import_ador_laser_example: 'Esempio di Ador Laser',
            import_ador_printing_example_single: 'Esempio di stampa Ador - Monocolore',
            import_ador_printing_example_full: 'Esempio di stampa Ador - Colore completo',
            import_acrylic_focus_probe: 'Sonda messa a fuoco acrilico - 3mm',
            export_to: 'Esporta su...',
            export_flux_task: 'Attività FLUX',
            export_BVG: 'BVG',
            export_SVG: 'SVG',
            export_PNG: 'PNG',
            export_JPG: 'JPG',
            save_scene: 'Salva',
            save_as: 'Salva con nome...',
            save_to_cloud: 'Salva sul cloud',
            about_beam_studio: 'Informazioni su Beam Studio',
            undo: 'Annulla',
            redo: 'Ripeti',
            cut: 'Taglia',
            copy: 'Copia',
            paste: 'Incolla',
            paste_in_place: 'Incolla sul posto',
            group: 'Raggruppa',
            ungroup: 'Separa',
            delete: 'Elimina',
            duplicate: 'Duplica',
            offset: 'Sposta',
            scale: 'Scala',
            rotate: 'Ruota',
            reset: 'Ripristina',
            align_center: 'Allinea al centro',
            photo_edit: 'Immagine',
            svg_edit: 'SVG',
            path: 'Tracciato',
            decompose_path: 'Scomponi',
            object: 'Oggetto',
            layer_setting: 'Livello',
            layer_color_config: 'Impostazioni colore',
            image_sharpen: 'Nitidezza',
            image_crop: 'Ritaglia',
            image_invert: 'Inverti',
            image_stamp: 'Smusso',
            image_vectorize: 'Traccia',
            image_curve: 'Curva',
            align_to_edges: 'Allinea ai bordi',
            document_setting: 'Impostazioni documento',
            document_setting_short: 'Documento',
            clear_scene: 'Nuovi file',
            machines: 'Macchine',
            add_new_machine: 'Configura macchina',
            help_center: 'Centro assistenza',
            show_gesture_tutorial: 'Introduzione gesti',
            show_start_tutorial: 'Mostra tutorial introduttivo',
            show_ui_intro: 'Mostra introduzione interfaccia',
            questionnaire: 'Questionario feedback',
            change_logs: 'Registro modifiche',
            contact: 'Contattaci',
            tutorial: 'Tutorial stampa Delta',
            design_market: 'Mercato progetti',
            forum: 'Forum community',
            software_update: 'Aggiornamento software',
            bug_report: 'Segnalazione bug',
            dashboard: 'Dashboard',
            machine_info: 'Informazioni macchina',
            network_testing: 'Test impostazioni rete',
            commands: 'Comandi',
            update_firmware: 'Aggiorna firmware',
            using_beam_studio_api: 'Utilizzo Beam Studio API',
            set_as_default: 'Imposta come predefinito',
            calibrate_beambox_camera: 'Calibra telecamera',
            calibrate_printer_module: 'Calibra modulo di stampa',
            calibrate_ir_module: 'Calibra modulo a infrarossi',
            calibrate_beambox_camera_borderless: 'Calibra telecamera (parte inferiore aperta)',
            calibrate_diode_module: 'Calibra modulo laser',
            manage_account: 'Gestisci account',
            sign_in: 'Accedi',
            sign_out: 'Disconnetti',
            account: 'Account',
            my_account: 'Il mio account',
            download_log: 'Scarica registri',
            download_log_canceled: 'Download registri annullato',
            download_log_error: 'Si è verificato un errore sconosciuto, riprova più tardi',
            keyboard_shortcuts: 'Scorciatoie da tastiera',
            log: {
                network: 'Rete',
                hardware: 'Hardware',
                discover: 'Rileva',
                usb: 'USB',
                usblist: 'Elenco USB',
                camera: 'Fotocamera',
                cloud: 'Cloud',
                player: 'Lettore',
                robot: 'Robot',
            },
            link: {
                help_center: 'https://helpcenter.flux3dp.com/',
                contact_us: 'https://flux3dp.zendesk.com/hc/en-us/requests/new',
                forum: 'https://www.facebook.com/groups/flux.laser/',
                downloads: 'https://flux3dp.com/downloads/',
                beam_studio_api: 'https://github.com/flux3dp/beam-studio/wiki/Beam-Studio-Easy-API',
                design_market: 'https://dmkt.io',
                shortcuts: 'https://support.flux3dp.com/hc/en-us/articles/10003978157455',
            },
            view: 'Visualizza',
            zoom_in: 'Ingrandisci',
            zoom_out: 'Rimpicciolisci',
            fit_to_window: 'Adatta alla finestra',
            zoom_with_window: 'Adatta automaticamente alla finestra',
            borderless_mode: 'Modalità a schermo intero',
            show_grids: 'Mostra griglie',
            show_rulers: 'Mostra righelli',
            show_layer_color: 'Usa colore del livello',
            anti_aliasing: 'Anti-aliasing',
            disassemble_use: 'Disassembla',
        },
        select_machine: 'Seleziona una macchina',
    },
    support: {
        no_webgl: 'WebGL non è supportato. Utilizzare altri dispositivi.',
        no_vcredist: 'Installare Visual C++ Redistributable 2015<br/>Scaricabile da flux3dp.com',
    },
    generic_error: {
        UNKNOWN_ERROR: '[UE] Si è verificato un errore sconosciuto. Riavviare Beam Studio e la macchina.',
        OPERATION_ERROR: "[OE] Conflitto di stato, riprovare l'azione.",
        SUBSYSTEM_ERROR: "[SE] Errore durante l'esecuzione del firmware della macchina. Riavviare la macchina.",
        UNKNOWN_COMMAND: '[UC] Aggiornare il firmware del dispositivo',
    },
    device_selection: {
        no_beambox: '#801 Impossibile trovare la macchina sulla rete.\nSeguire la <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/360001683556">guida</a> per risolvere il problema di connessione.',
        no_device_web: "#801 Controlla lo stato della tua macchina o clicca su 'Configura macchina' qui sotto per configurare la macchina.",
    },
    update: {
        update: 'Aggiorna',
        release_note: 'Note di rilascio:',
        firmware: {
            caption: 'Disponibile aggiornamento firmware per la macchina',
            message_pattern_1: '"%s" è pronto per l\'aggiornamento del firmware.',
            message_pattern_2: 'Firmware %s v%s disponibile - Hai la v%s.',
            latest_firmware: {
                caption: 'Aggiornamento firmware macchina',
                message: "Hai l'ultima versione del firmware della macchina",
                still_update: 'AGGIORNA',
                cant_get_latest: "Impossibile ottenere informazioni sull'ultimo firmware.",
            },
            confirm: 'CARICA',
            upload_file: 'Caricamento firmware (*.bin / *.fxfw)',
            update_success: 'Aggiornamento firmware caricato correttamente',
            update_fail: '#822 Aggiornamento non riuscito',
            too_old_for_web: "La versione corrente del firmware della macchina è la v%s.\nPer utilizzare la versione online di Beam Studio, aggiornare il firmware all'ultima versione.",
            force_update_message: "#814 Aggiornare la macchina all'ultima versione del firmware.",
            firmware_too_old_update_by_sdcard: 'Versione del firmware troppo vecchia. Aggiornare il firmware utilizzando una scheda SD.',
        },
        software: {
            checking: 'Controllo aggiornamenti',
            switch_version: 'Cambia versione',
            check_update: 'Controlla aggiornamenti',
            caption: 'Disponibile aggiornamento software per Beam Studio',
            downloading: 'Download degli aggiornamenti in background, fare clic su "OK" per continuare.',
            install_or_not: "è pronto per l'aggiornamento. Vuoi riavviare e aggiornare ora?",
            switch_or_not: 'è pronto per il passaggio. Vuoi riavviare e passare ora?',
            available_update: "Beam Studio v%s è disponibile ora. Hai la v%s. Vuoi scaricare l'aggiornamento?",
            available_switch: 'Beam Studio v%s è disponibile ora. Hai la v%s. Vuoi passare a questa versione?',
            not_found: "Stai utilizzando l'ultima versione di Beam Studio.",
            no_response: 'Impossibile connettersi al server, controlla le impostazioni di rete.',
            switch_version_not_found: 'Versione passabile non trovata.',
            yes: 'Sì',
            no: 'No',
            update_for_ador: "La versione software corrente %s non è compatibile, scarica l'ultima versione di Beam Studio per Ador.",
        },
        updating: 'Aggiornamento in corso...',
        skip: 'Salta questa versione',
        preparing: 'Preparazione in corso...',
        later: 'PIÙ TARDI',
        download: 'AGGIORNAMENTO ONLINE',
        cannot_reach_internet: '#823 Server irraggiungibile<br/>Controlla la connessione a internet',
        install: 'INSTALLA',
        upload: 'CARICA',
    },
    topmenu: {
        version: 'Versione',
        credit: 'Beam Studio è reso possibile dal progetto open source <a target="_blank" href="https://github.com/flux3dp/beam-studio">Beam Studio</a> e da altri <a target="_blank" href="https://flux3dp.com/credits/">software open source</a>.',
        ok: 'OK',
        file: {
            label: 'File',
            import: 'Importa',
            save_fcode: 'Esporta attività FLUX',
            save_scene: 'Salva scena',
            save_svg: 'Esporta SVG',
            save_png: 'Esporta PNG',
            save_jpg: 'Esporta JPG',
            converting: 'Conversione in immagine in corso...',
            all_files: 'Tutti i file',
            svg_files: 'SVG',
            png_files: 'PNG',
            jpg_files: 'JPG',
            scene_files: 'Beam Studio Scene',
            fcode_files: 'FLUX Code',
            clear_recent: 'Cancella recenti',
            path_not_exit: 'Questo percorso non sembra più esistere sul disco.',
        },
        device: {
            download_log_canceled: 'Download log annullato',
            download_log_error: 'Si è verificato un errore sconosciuto, riprova più tardi',
            log: {
                usblist: 'Elenco USB',
            },
            network_test: 'Testa rete',
        },
    },
    initialize: {
        next: 'Avanti',
        start: 'Inizia',
        skip: 'Salta',
        cancel: 'Annulla',
        confirm: 'Conferma',
        connect: 'Connetti',
        back: 'Indietro',
        retry: 'Riprova',
        no_machine: 'Non ho una macchina ora.',
        select_language: 'Seleziona lingua',
        select_machine_type: 'Seleziona il tuo modello',
        select_connection_type: 'Come desideri connetterti?',
        connection_types: {
            wifi: 'Wi-Fi',
            wired: 'Rete cablata',
            ether2ether: 'Connessione diretta',
            usb: 'Connessione USB',
        },
        connect_wifi: {
            title: 'Connessione a Wi-Fi',
            tutorial1: '1. Vai a Touch Panel > Fai clic su "Rete" > "Connetti a WiFi".',
            tutorial1_ador: '1. Vai a Pannello Touch > Fai clic su "MACCHINA" > Fai clic su "Rete" > "Connetti a Wi-Fi".',
            tutorial2: '2. Seleziona e connetti il Wi-Fi preferito.',
            what_if_1: 'Cosa fare se non vedo il mio Wi-Fi?',
            what_if_1_content: "1. La modalità di crittografia Wi-Fi dovrebbe essere WPA2 o nessuna password.\n2. La modalità di crittografia può essere impostata nell'interfaccia di amministrazione del router Wi-Fi. Se il router non supporta WPA2 e hai bisogno di aiuto nella scelta del router giusto, contatta l'assistenza FLUX.",
            what_if_2: 'Cosa succede se non vedo alcuna rete Wi-Fi?',
            what_if_2_content: "1. Assicurati che il dongle Wi-Fi sia completamente inserito.\n2. Se non c'è alcun indirizzo MAC della rete wireless sul touchscreen, contatta l'assistenza FLUX.\n3. Il canale Wi-Fi dovrebbe essere 2.4Ghz (5Ghz non è supportato).",
        },
        connect_wired: {
            title: 'Connessione alla rete cablata',
            tutorial1: '1. Collega la macchina con il tuo router.',
            tutorial2: '2. Premi "Rete" per ottenere l\'IP di rete cablata.',
            tutorial2_ador: '2. Premi "MACCHINA" > "Rete" per ottenere l\'indirizzo IP cablato.',
            what_if_1: "Cosa succede se l'IP è vuoto?",
            what_if_1_content: "1. Assicurati che il cavo Ethernet sia completamente inserito.\n2. Se non c'è alcun indirizzo MAC della rete cablata sul touchscreen, contatta l'assistenza FLUX.",
            what_if_2: "Cosa succede se l'IP inizia con 169?",
            what_if_2_content: "1. Se l'indirizzo IP inizia con 169.254, dovrebbe trattarsi di un problema di impostazione DHCP, contatta il tuo ISP (Internet service provider) per ulteriore assistenza.\n2. Se il tuo computer si connette a Internet direttamente tramite PPPoE, passa alla connessione tramite router utilizzando PPPoE e abilita la funzione DHCP nel router.",
        },
        connect_ethernet: {
            title: 'Connessione diretta',
            tutorial1: '1. Collega la macchina con il tuo computer con il cavo ethernet.',
            tutorial2_1: '2. Segui ',
            tutorial2_a_text: "'questa guida'",
            tutorial2_a_href_mac: 'https://support.flux3dp.com/hc/en-us/articles/360001517076',
            tutorial2_a_href_win: 'https://support.flux3dp.com/hc/en-us/articles/360001507715',
            tutorial2_2: ' per rendere il tuo computer un router.',
            tutorial3: '3. Fai clic su Avanti.',
        },
        connect_usb: {
            title: 'Connessione USB',
            title_sub: ' (solo HEXA)',
            tutorial1: '1. Collega la macchina con il tuo computer con il cavo USB.',
            tutorial2: '2. Fai clic su Avanti.',
            turn_off_machine: 'Spegni la macchina.',
            turn_on_machine: 'Accendi la macchina.',
            wait_for_turning_on: 'Fai clic su "Avanti" dopo che il processo di avvio è completo e sei entrato nella schermata principale.',
        },
        connect_machine_ip: {
            check_usb: 'Controlla connessione USB',
            enter_ip: "Inserisci l\\'indirizzo IP della tua macchina",
            check_ip: 'Controllo disponibilità IP',
            invalid_ip: 'IP non valido: ',
            invalid_format: 'Formato non valido',
            starts_with_169254: 'Inizia con 169.254',
            unreachable: 'IP irraggiungibile',
            check_connection: 'Controllo connessione macchina',
            check_firmware: 'Controllo della versione del firmware',
            check_camera: 'Controllo della disponibilità della fotocamera',
            retry: 'Riprova',
            succeeded_message: 'Connessione riuscita 🎉',
            finish_setting: 'Inizia a creare!',
        },
        connecting: 'Connessione in corso...',
        setting_completed: {
            start: 'Inizia',
            great: 'Benvenuto in Beam Studio',
            setup_later: 'Puoi sempre configurare la tua macchina dalla Barra del titolo > "Macchine" > "Configurazione macchina"',
            back: 'Indietro',
            ok: 'INIZIA A CREARE',
        },
    },
    error_pages: {
        screen_size: "Si prega di notare che Beam Studio potrebbe non funzionare in modo ottimale sul dispositivo. Per un'esperienza ottimale, dovrebbe essere un dispositivo con una larghezza dello schermo di almeno 1024 pixel.",
    },
    menu: {
        mm: 'mm',
        inches: 'Pollici',
    },
    settings: {
        on: 'Acceso',
        off: 'Spento',
        low: 'Basso',
        high: 'Normale',
        caption: 'Impostazioni',
        tabs: {
            general: 'Generale',
            device: 'Macchina',
        },
        ip: 'Indirizzo IP della macchina',
        guess_poke: 'Cerca indirizzo IP della macchina',
        auto_connect: "Seleziona automaticamente l'unica macchina",
        wrong_ip_format: 'Formati IP errati',
        default_machine: 'Macchina predefinita',
        default_machine_button: 'Vuoto',
        remove_default_machine_button: 'Rimuovi',
        confirm_remove_default: 'La macchina predefinita verrà rimossa.',
        reset: 'Reimposta Beam Studio',
        reset_now: 'Reset Beam Studio',
        confirm_reset: 'Confermare reset Beam Studio?',
        language: 'Lingua',
        notifications: 'Notifiche desktop',
        check_updates: 'Controllo aggiornamenti automatico',
        autosave_enabled: 'Salvataggio automatico',
        autosave_path: 'Percorso salvataggio automatico',
        autosave_interval: 'Salva ogni',
        autosave_number: 'Numero di salvataggi automatici',
        autosave_path_not_correct: 'Percorso specificato non trovato.',
        preview_movement_speed: 'Velocità anteprima movimento',
        preview_movement_speed_hl: 'Velocità anteprima movimento (Laser a diodi abilitato)',
        default_units: 'Unità predefinite',
        default_font_family: 'Carattere predefinito',
        default_font_style: 'Stile carattere predefinito',
        fast_gradient: 'Ottimizzazione velocità',
        engraving_direction: 'Direzione',
        top_down: "Dall'alto verso il basso",
        bottom_up: "Dal basso verso l'alto",
        vector_speed_constraint: 'Limite velocità (20mm/s)',
        loop_compensation: 'Compensazione ciclo',
        blade_radius: 'Raggio lama',
        blade_precut_switch: 'Taglio preliminare lama',
        blade_precut_position: 'Posizione taglio preliminare',
        default_beambox_model: 'Impostazione documento predefinita',
        guides_origin: 'Origine guide',
        guides: 'Guide',
        image_downsampling: 'Qualità anteprima bitmap',
        anti_aliasing: 'Anti-aliasing',
        continuous_drawing: 'Disegno continuo',
        trace_output: 'Uscita traccia immagine',
        single_object: 'Oggetto singolo',
        grouped_objects: 'Oggetti raggruppati',
        simplify_clipper_path: 'Ottimizza il percorso calcolato',
        enable_low_speed: 'Abilita movimento a bassa velocità',
        enable_custom_backlash: 'Abilita compensazione personalizzata del gioco',
        custom_preview_height: 'Altezza anteprima personalizzata',
        mask: 'Maschera area di lavoro',
        text_path_calc_optimization: 'Ottimizzazione calcolo percorso',
        font_substitute: 'Sostituisci caratteri non supportati',
        font_convert: 'Convertitore di testo in percorso',
        default_borderless_mode: 'Apri inferiore predefinito',
        default_enable_autofocus_module: 'Messa a fuoco automatica predefinita',
        default_enable_diode_module: 'Laser a diodi predefinito',
        diode_offset: 'Offset laser a diodi',
        autofocus_offset: 'Offset messa a fuoco automatica',
        diode_one_way_engraving: 'Incisione laser a diodi monodirezionale',
        diode_two_way_warning: "L'emissione bidirezionale è più veloce e probabilmente genera qualche imprecisione sulla posizione di incisione laser. Si consiglia di testare prima.",
        share_with_flux: 'Condividi analisi Beam Studio',
        none: 'Nessuno',
        close: 'Chiudi',
        enabled: 'Abilitato',
        disabled: 'Disabilitato',
        cancel: 'Annulla',
        done: 'Applica',
        module_offset_10w: 'Offset Laser a Diodi 10W',
        module_offset_20w: 'Offset Laser a Diodi 20W',
        module_offset_printer: 'Offset Stampante',
        module_offset_2w_ir: 'Offset Laser a Infrarossi 2W',
        printer_advanced_mode: 'Modalità Avanzata Stampante',
        default_laser_module: 'Modulo Laser Predefinito',
        low_laser_for_preview: 'Laser per Esecuzione cornice',
        groups: {
            general: 'Generale',
            update: 'Aggiornamenti software',
            connection: 'Connessione',
            autosave: 'Salvataggio automatico',
            camera: 'Fotocamera',
            editor: 'Editor',
            engraving: 'Rasterizzazione (Scansione)',
            path: 'Vettore (Contorni)',
            mask: 'Ritaglio area di lavoro',
            text_to_path: 'Testo',
            modules: 'Componente aggiuntivo',
            ador_modules: 'Moduli Ador',
            privacy: 'Privacy',
        },
        notification_on: 'Acceso',
        notification_off: 'Spento',
        update_latest: 'Ultima',
        update_beta: 'Beta',
        help_center_urls: {
            connection: 'https://support.flux3dp.com/hc/en-us/sections/360000302135',
            image_downsampling: 'https://support.flux3dp.com/hc/en-us/articles/360004494995',
            anti_aliasing: 'https://support.flux3dp.com/hc/en-us/articles/360004408956',
            continuous_drawing: 'https://support.flux3dp.com/hc/en-us/articles/360004406496',
            simplify_clipper_path: 'https://support.flux3dp.com/hc/en-us/articles/360004407276',
            fast_gradient: 'https://support.flux3dp.com/hc/en-us/articles/360004496235',
            reverse_engraving: 'https://support.flux3dp.com/hc/en-us/articles/',
            vector_speed_constraint: 'https://support.flux3dp.com/hc/en-us/articles/360004496495',
            loop_compensation: 'https://support.flux3dp.com/hc/en-us/articles/360004408856',
            mask: 'https://support.flux3dp.com/hc/en-us/articles/360004408876',
            font_substitute: 'https://support.flux3dp.com/hc/en-us/articles/360004496575',
            font_convert: 'https://support.flux3dp.com/hc/en-us/articles/9132766761743',
            default_borderless_mode: 'https://support.flux3dp.com/hc/zh-tw/articles/360001104076',
            default_enable_autofocus_module: 'https://support.flux3dp.com/hc/en-us/articles/360001574536',
            default_enable_diode_module: 'https://support.flux3dp.com/hc/en-us/articles/360001568035',
        },
    },
    beambox: {
        tag: {
            g: 'Gruppo',
            use: 'Importa SVG',
            image: 'Immagine',
            text: 'Testo',
        },
        context_menu: {
            cut: 'Taglia',
            copy: 'Copia',
            paste: 'Incolla',
            paste_in_place: 'Incolla nella posizione originale',
            duplicate: 'Duplica',
            delete: 'Elimina',
            group: 'Raggruppa',
            ungroup: 'Separa',
            move_front: 'Porta in primo piano',
            move_up: 'Sposta avanti',
            move_down: 'Sposta indietro',
            move_back: 'Sposta in fondo',
        },
        popup: {
            select_import_method: 'Seleziona lo stile di stratificazione:',
            select_import_module: 'Seleziona modulo:',
            touchpad: 'Touchpad',
            mouse: 'Mouse',
            layer_by_layer: 'Strato',
            layer_by_color: 'Colore',
            nolayer: 'Strato singolo',
            loading_image: 'Caricamento immagine in corso...',
            no_support_text: 'Beam Studio al momento non supporta il testo. Convertire il testo in tracciato prima di importare.',
            speed_too_high_lower_the_quality: 'Una velocità troppo elevata a questa risoluzione può comportare una qualità inferiore della tonalità di incisione.',
            both_power_and_speed_too_high: 'Utilizzare una potenza laser inferiore prolungherà la durata del tubo laser. Inoltre, una velocità troppo elevata a questa risoluzione può comportare una qualità inferiore della tonalità di incisione.',
            too_fast_for_path: 'Una velocità troppo elevata negli strati contenenti oggetti tracciato può comportare una precisione inferiore durante il taglio. Non consigliamo di usare una velocità superiore a 20 mm/s durante il taglio.',
            too_fast_for_path_and_constrain: 'I seguenti strati: %s contengono oggetti tracciato vettoriali e hanno una velocità che supera i 20 mm/s. La velocità di taglio degli oggetti tracciato vettoriali sarà limitata a 20 mm/s. È possibile rimuovere questo limite nelle impostazioni delle preferenze.',
            should_update_firmware_to_continue: '#814 Il firmware non supporta questa versione di Beam Studio. Aggiornare il firmware per continuare. (Menu > Macchina > [La tua macchina] > Aggiorna firmware)',
            recommend_downgrade_software: 'Abbiamo rilevato una versione firmware precedente. Stiamo lavorando attivamente per risolvere i problemi di compatibilità, ma per ora consigliamo di tornare a Beam Studio 1.9.5.',
            recommend_upgrade_firmware: "Abbiamo rilevato una versione firmware obsoleta. Stiamo lavorando attivamente per risolvere eventuali problemi di compatibilità, ma per il momento consigliamo di aggiornare all'ultima versione firmware.",
            still_continue: 'Continua',
            more_than_two_object: 'Troppi oggetti. Supporto solo per 2 oggetti',
            not_support_object_type: 'Tipo di oggetto non supportato',
            select_first: 'Selezionare prima un oggetto.',
            select_at_least_two: 'Selezionare due oggetti per procedere',
            import_file_contain_invalid_path: '#808 Il file SVG importato contiene percorsi immagine non validi. Assicurarsi che tutti i file immagine esistano o incorporare le immagini nel file',
            import_file_error_ask_for_upload: 'Non è stato possibile importare il file SVG. Vuoi fornire il file al team di sviluppo per la segnalazione di bug?',
            upload_file_too_large: '#819 Il file è troppo grande per il caricamento.',
            successfully_uploaded: 'Caricamento file riuscito.',
            upload_failed: '#819 Caricamento file non riuscito.',
            or_turn_off_borderless_mode: ' O disattiva la modalità Open Bottom.',
            svg_1_1_waring: 'La versione di questo file SVG è v 1.1, potrebbero esserci potenziali problemi di incompatibilità.',
            svg_image_path_waring: 'Questo file SVG contiene <image> che caricano dal percorso file. Questo potrebbe causare errori durante il caricamento.\\\\nPer evitare questo rischio, utilizza immagini incorporate quando esporti in SVG.',
            dxf_version_waring: 'La versione di questo file DXF non è 2013, potrebbero esserci potenziali problemi di incompatibilità.',
            dont_show_again: 'Non mostrare la prossima volta.',
            convert_to_path_fail: 'Impossibile convertire in percorso.',
            save_unsave_changed: 'Vuoi salvare le modifiche non salvate?',
            dxf_bounding_box_size_over: "Le dimensioni del disegno superano l'area di lavoro. Sposta il tuo disegno più vicino all'origine nel tuo software CAD, o assicurati che l'unità sia impostata correttamente.",
            progress: {
                uploading: 'Caricamento',
                calculating: 'Calcolo',
            },
            backend_connect_failed_ask_to_upload: '#802 Si sono verificati errori continui durante il tentativo di connessione al backend. Vuoi caricare il rapporto di bug?',
            backend_error_hint: 'Le funzionalità potrebbero non funzionare correttamente a causa di errori backend.',
            pdf2svg: {
                error_when_converting_pdf: '#824 Errore durante la conversione da PDF a SVG:',
                error_pdf2svg_not_found: '#825 Errore: comando pdf2svg non trovato. Installa pdf2svg con il tuo gestore di pacchetti (es. "yum install pdf2svg" o "apt-get install pdf2svg").',
            },
            ungroup_use: 'Questo separerà il gruppo di file DXF o SVG importati. Poiché il file potrebbe contenere un gran numero di elementi, potrebbe volerci del tempo per separare il gruppo. Sei sicuro di voler procedere?',
            vectorize_shading_image: "Le immagini sfumate richiedono più tempo per essere tracciate e sono soggette a rumore. Disattiva il gradiente dell'immagine prima di eseguire.",
            change_workarea_before_preview: "L'area di lavoro di %s non corrisponde all'area di lavoro attualmente impostata. Vuoi passare all'area di lavoro corrente?",
            bug_report: 'Segnalazione di bug',
            sentry: {
                title: 'Rendiamo Beam Studio migliore insieme',
                message: "Sei d'accordo a caricare automaticamente le informazioni rilevanti al team di sviluppo in caso di errori?",
            },
            questionnaire: {
                caption: 'Aiutaci a compilare il questionario',
                message: 'Aiutaci a compilare il questionario per migliorare Beam Studio.',
                unable_to_get_url: 'Impossibile ottenere il link al questionario tramite internet. Verifica la tua connessione internet.',
                no_questionnaire_available: 'Al momento non è disponibile alcun questionario.',
            },
            facebook_group_invitation: {
                title: 'Unisciti al gruppo ufficiale degli utenti FLUX',
                message: "Unisciti al nostro gruppo Facebook ufficiale per connetterti con altri utenti laser FLUX, discutere dei laser FLUX, condividere opere laser e rimanere aggiornato sulle ultime novità sui nostri prodotti. Non vediamo l'ora di vederti lì!",
                join_now: 'Iscriviti ora',
                later: 'Forse più tardi',
                already_joined: 'Già iscritto',
                dont_show_again: 'Non mostrare più',
            },
            ai_credit: {
                relogin_to_use: "Effettua nuovamente l'accesso per utilizzare questa funzione",
                insufficient_credit: 'Sei a corto di credito',
                insufficient_credit_msg: 'Non puoi utilizzare %s. Vai al centro membri e acquista crediti AI',
                buy_link: 'https://member.flux3dp.com/en-US/credit',
                go: 'Vai',
            },
            text_to_path: {
                caption: 'Convertitore di testo in percorso 2.0',
                message: "Beam Studio introduce ora un nuovo convertitore di testo in percorso (Convertitore 2.0), che produce risultati più affidabili! Vuoi passare ad esso ora?\\nPuoi anche regolare questa impostazione più tardi nella sezione 'Convertitore di testo in percorso' all'interno delle preferenze.",
            },
        },
        zoom_block: {
            fit_to_window: 'Adatta a finestra',
        },
        time_est_button: {
            calculate: 'Stima tempo',
            estimate_time: 'Tempo stimato:',
        },
        left_panel: {
            unpreviewable_area: 'Area cieca',
            diode_blind_area: 'Area cieca aggiuntiva laser ibrido',
            borderless_blind_area: 'Area non incisa',
            borderless_preview: 'Anteprima fotocamera modalità fondo aperto',
            rectangle: 'Rettangolo',
            ellipse: 'Ellisse',
            line: 'Linea',
            image: 'Immagine',
            text: 'Testo',
            label: {
                cursor: 'Seleziona',
                photo: 'Immagine',
                text: 'Testo',
                line: 'Linea',
                rect: 'Rettangolo',
                oval: 'Ovale',
                polygon: 'Poligono',
                pen: 'Penna',
                shapes: 'Elementi',
                array: 'Matrice',
                preview: 'Anteprima fotocamera',
                trace: 'Traccia immagine',
                end_preview: 'Termina anteprima',
                clear_preview: 'Cancella anteprima',
                choose_camera: 'Fotocamera',
                live_feed: 'Live Feed',
                adjust_height: 'Regola altezza',
                qr_code: 'Codice QR',
                boxgen: 'Boxgen',
                my_cloud: 'La mia nuvola',
                curve_engraving: {
                    title: 'Curva 3D',
                    exit: 'Esci',
                    select_area: 'Seleziona area',
                    preview_3d_curve: 'Anteprima curva 3D',
                    clear_area: 'Cancella area selezionata',
                },
            },
        },
        right_panel: {
            tabs: {
                layers: 'Livelli',
                objects: 'Oggetti',
                path_edit: 'Modifica percorso',
            },
            layer_panel: {
                layer1: 'Livello 1',
                layer_bitmap: 'Bitmap',
                layer_engraving: 'Incisione',
                layer_cutting: 'Taglio',
                current_layer: 'Livello corrente',
                move_elems_to: 'Sposta elementi a:',
                notification: {
                    dupeLayerName: 'Esiste già un livello con quel nome!',
                    newName: 'NUOVO NOME',
                    enterUniqueLayerName: 'Inserisci un nome di livello univoco',
                    enterNewLayerName: 'Inserisci il nuovo nome del livello',
                    layerHasThatName: 'Il livello ha già quel nome',
                    QmoveElemsToLayer: "Spostare gli elementi selezionati nel livello '%s'?",
                    moveElemFromPrintingLayerTitle: "Spostare l'elemento selezionato in %s e convertirlo in elemento laser?",
                    moveElemFromPrintingLayerMsg: "Si prega di notare che se si completa questa operazione, le impostazioni colore dell'elemento selezionato verranno rimosse e impostate in base a %s.",
                    moveElemToPrintingLayerTitle: "Spostare l'elemento selezionato in %s e convertirlo in elemento di stampa?",
                    moveElemToPrintingLayerMsg: "Si prega di notare che se si completa questa operazione, le impostazioni dell'elemento selezionato verranno rimosse e impostate in base a %s.",
                    splitColorTitle: 'Vuoi espandere il livello selezionato nei livelli CMYK?',
                    splitColorMsg: 'Si prega di notare che se si prosegue con questa operazione, non sarà possibile tornare agli originali livelli colore.',
                    mergeLaserLayerToPrintingLayerTitle: 'Vuoi unire questi livelli in un unico livello di stampa?',
                    mergeLaserLayerToPrintingLayerMsg: 'Si prega di notare che se si completa questa operazione, le impostazioni del livello laser verranno rimosse e impostate in base al livello corrente.',
                    mergePrintingLayerToLaserLayerTitle: 'Vuoi unire questi livelli in un unico livello laser?',
                    mergePrintingLayerToLaserLayerMsg: 'Si prega di notare che se si completa questa operazione, le impostazioni di colore del livello di stampa verranno rimosse e impostate in base al livello corrente.',
                },
                layers: {
                    layer: 'Livello',
                    layers: 'Livelli',
                    del: 'Elimina livello',
                    move_down: 'Sposta giù livello',
                    new: 'Nuovo livello',
                    rename: 'Rinomina livello',
                    move_up: 'Sposta su livello',
                    dupe: 'Duplica livello',
                    lock: 'Blocca livello',
                    unlock: 'Sblocca',
                    merge_down: 'Unisci verso il basso',
                    merge_all: 'Unisci tutti',
                    merge_selected: 'Unisci livelli selezionati',
                    move_elems_to: 'Sposta elementi in:',
                    move_selected: 'Sposta elementi selezionati in un altro livello',
                    switchToFullColor: 'Passa al livello a colori completo',
                    switchToSingleColor: 'Passa al livello a colore singolo',
                    splitFullColor: 'Espandi livello',
                    fullColor: 'Colori completi',
                },
            },
            laser_panel: {
                preset_setting: 'Impostazioni parametri (%s)',
                multi_layer: 'Livelli multipli',
                parameters: 'Parametri',
                strength: 'Potenza',
                low_power_warning: 'Una potenza laser inferiore (sotto il 10%) potrebbe non emettere il raggio laser.',
                speed: 'Velocità',
                speed_contrain_warning: 'La velocità di taglio degli oggetti vettoriali sarà limitata a 20 mm/s. È possibile rimuovere questo limite nelle impostazioni delle preferenze.',
                low_speed_warning: 'Una velocità bassa potrebbe causare la combustione del materiale.',
                repeat: 'Esegui',
                add_on: 'Aggiuntivo',
                focus_adjustment: 'Regolazione messa a fuoco',
                height: 'Altezza oggetto',
                z_step: 'Passo Z',
                diode: 'Laser a diodi',
                backlash: 'Gioco',
                ink_saturation: 'Saturazione',
                print_multipass: 'Multi-passaggio',
                white_ink: 'Inchiostro bianco',
                white_ink_settings: 'Impostazioni inchiostro bianco',
                color_adjustment: 'Regolazione canale',
                color_adjustment_short: 'Canale',
                halftone: 'Semitono',
                halftone_link: 'https://support.flux3dp.com/hc/en-us/articles/9402670389647',
                color_strength: 'Intensità',
                times: 'volte',
                cut: 'Taglia',
                engrave: 'Incide',
                more: 'Gestisci',
                delete: 'Elimina',
                reset: 'Ripristina',
                sure_to_reset: 'Questo ripristinerà tutti i preset e manterrà i parametri personalizzati, sei sicuro di procedere?',
                apply: 'Applica',
                cancel: 'Annulla',
                save: 'Salva',
                save_and_exit: 'Salva ed Esci',
                name: 'Nome',
                default: 'Predefinito',
                customized: 'Personalizzato',
                inuse: 'In uso',
                export_config: 'Esporta Preset',
                new_config_name: 'Nuovo Nome Preset',
                sure_to_load_config: 'Questo caricherà la disposizione dei preset e sostituirà i parametri personalizzati impostati nel file, sei sicuro di procedere?',
                custom_preset: 'Personalizzato',
                various_preset: 'Vari Preset',
                module: 'Modulo',
                slider: {
                    regular: 'Normale',
                    low: 'Basso',
                    very_low: 'Minimo',
                    high: 'Alto',
                    very_high: 'Massimo',
                    slow: 'Lento',
                    very_slow: 'Molto lento',
                    fast: 'Veloce',
                    very_fast: 'Molto veloce',
                },
                dropdown: {
                    parameters: 'Preset',
                    save: 'Aggiungi parametri correnti',
                    export: 'Esporta',
                    import: 'Importa',
                    more: 'Gestisci',
                    mm: {
                        wood_3mm_cutting: 'Legno - Taglio 3mm',
                        wood_5mm_cutting: 'Legno - Taglio 5mm',
                        wood_7mm_cutting: 'Legno - taglio 7mm',
                        wood_8mm_cutting: 'Legno - Taglio 8mm',
                        wood_10mm_cutting: 'Legno - Taglio 10mm',
                        wood_engraving: 'Legno - Incisione',
                        acrylic_3mm_cutting: 'Acrilico - Taglio 3mm',
                        acrylic_5mm_cutting: 'Acrilico - Taglio 5mm',
                        acrylic_8mm_cutting: 'Acrilico - Taglio 8mm',
                        acrylic_10mm_cutting: 'Acrilico - Taglio 10mm',
                        acrylic_engraving: 'Acrilico - Incisione',
                        mdf_3mm_cutting: 'MDF Nuova Zelanda - Taglio 3mm',
                        mdf_5mm_cutting: 'MDF Nuova Zelanda - Taglio 5mm',
                        mdf_engraving: 'MDF Nuova Zelanda - Incisione',
                        leather_3mm_cutting: 'Pelle - Taglio 3mm',
                        leather_5mm_cutting: 'Pelle - Taglio 5mm',
                        leather_engraving: 'Pelle - Incisione',
                        denim_1mm_cutting: 'Denim - Taglio 1mm',
                        fabric_3mm_cutting: 'Tessuto - Taglio 3mm',
                        fabric_5mm_cutting: 'Tessuto - Taglio 5mm',
                        fabric_engraving: 'Tessuto - Incisione',
                        rubber_bw_engraving: 'Gomma - Incisione',
                        glass_bw_engraving: 'Vetro - Incisione',
                        metal_bw_engraving: 'Metallo - Incisione',
                        steel_engraving_spray_engraving: 'Metallo - Incisione',
                        stainless_steel_bw_engraving_diode: 'Metallo - Incisione (Laser a diodi)',
                        gold_engraving: 'Oro - Incisione',
                        brass_engraving: 'Ottone - Incisione',
                        ti_engraving: 'Titanio - Incisione',
                        stainless_steel_engraving: 'Acciaio inossidabile - Incisione',
                        aluminum_engraving: 'Alluminio - Incisione',
                        black_acrylic_3mm_cutting: 'Acrilico nero - Taglio 3mm',
                        black_acrylic_5mm_cutting: 'Acrilico nero - Taglio 5mm',
                        black_acrylic_engraving: 'Acrilico nero - Incisione',
                        abs_engraving: 'ABS - Incisione',
                        silver_engraving: 'Argento - Incisione',
                        iron_engraving: 'Ferro - Incisione',
                        fabric_printing: 'Tessuto - Stampa',
                        canvas_printing: 'Tela - Stampa',
                        cardstock_printing: 'Cartoncino - Stampa',
                        wood_printing: 'Legno - Stampa',
                        bamboo_printing: 'Bambù - Stampa',
                        cork_printing: 'Sughero - Stampa',
                        flat_stone_printing: 'Pietra piatta - Stampa',
                        acrylic_printing: 'Acrilico - Stampa',
                        pc_printing: 'PC - Stampa',
                        stainless_steel_printing: 'Acciaio inossidabile - Stampa',
                        gloss_leather_printing: 'Pelle lucida - Stampa',
                        glass_printing: 'Vetro - Stampa',
                    },
                    inches: {
                        wood_3mm_cutting: "Legno - 0,1'' Taglio",
                        wood_5mm_cutting: "Legno - 0,2'' Taglio",
                        wood_7mm_cutting: "Legno - 0,3'' Taglio",
                        wood_8mm_cutting: "Legno - 0,3'' Taglio",
                        wood_10mm_cutting: "Legno - 0,4'' Taglio",
                        wood_engraving: 'Legno - Incisione',
                        acrylic_3mm_cutting: "Acrilico - 0,1'' Taglio",
                        acrylic_5mm_cutting: "Acrilico - 0,2'' Taglio",
                        acrylic_8mm_cutting: "Acrilico - 0,3'' Taglio",
                        acrylic_10mm_cutting: "Acrilico - 0,4'' Taglio",
                        acrylic_engraving: 'Acrilico - Incisione',
                        mdf_3mm_cutting: "MDF - 0,1'' Taglio",
                        mdf_5mm_cutting: "MDF - 0,2'' Taglio",
                        mdf_engraving: 'MDF - Incisione',
                        leather_3mm_cutting: "Pelle - 0,1'' Taglio",
                        leather_5mm_cutting: "Pelle - 0,2'' Taglio",
                        leather_engraving: 'Pelle - Incisione',
                        denim_1mm_cutting: "Denim - 0,04'' Taglio",
                        fabric_3mm_cutting: "Tessuto - 0,1'' Taglio",
                        fabric_5mm_cutting: "Tessuto - 0,2''Taglio",
                        fabric_engraving: 'Tessuto - Incisione',
                        rubber_bw_engraving: 'Gomma - Incisione',
                        glass_bw_engraving: 'Vetro - Incisione',
                        metal_bw_engraving: 'Metallo - Incisione',
                        steel_engraving_spray_engraving: 'Metallo - Incisione',
                        stainless_steel_bw_engraving_diode: 'Metallo - Incisione (Laser a diodi)',
                        gold_engraving: 'Oro - Incisione',
                        brass_engraving: 'Ottone - Incisione',
                        ti_engraving: 'Titanio - Incisione',
                        stainless_steel_engraving: 'Acciaio inossidabile - Incisione',
                        aluminum_engraving: 'Alluminio - Incisione',
                        black_acrylic_3mm_cutting: 'Acrilico nero - 0,1" Taglio',
                        black_acrylic_5mm_cutting: 'Acrilico nero - 0,2" Taglio',
                        black_acrylic_engraving: 'Acrilico nero - Incisione',
                        abs_engraving: 'ABS - Incisione',
                        silver_engraving: 'Argento - Incisione',
                        iron_engraving: 'Ferro - Incisione',
                        fabric_printing: 'Tessuto - Stampa',
                        canvas_printing: 'Tela - Stampa',
                        cardstock_printing: 'Cartoncino - Stampa',
                        wood_printing: 'Legno - Stampa',
                        bamboo_printing: 'Bamboo - Stampa',
                        cork_printing: 'Sughero - Stampa',
                        flat_stone_printing: 'Pietra piatta - Stampa',
                        acrylic_printing: 'Acrilico - Stampa',
                        pc_printing: 'PC - Stampa',
                        stainless_steel_printing: 'Acciaio inossidabile - Stampa',
                        gloss_leather_printing: 'Pelle lucida - Stampa',
                        glass_printing: 'Vetro - Stampa',
                    },
                },
                laser_speed: {
                    text: 'Velocità',
                    unit: 'mm/s',
                    fast: 'Veloce',
                    slow: 'Lento',
                    min: 3,
                    max: 300,
                    step: 0.1,
                },
                power: {
                    text: 'Potenza',
                    high: 'Alto',
                    low: 'Basso',
                    min: 1,
                    max: 100,
                    step: 0.1,
                },
                ink_type: {
                    text: 'Tipo di inchiostro',
                    normal: 'Inchiostro',
                    UV: 'Inchiostro UV',
                },
                para_in_use: 'Questo parametro è già in uso.',
                do_not_adjust_default_para: 'I parametri predefiniti non possono essere modificati.',
                existing_name: 'Questo nome è già stato utilizzato.',
                presets: 'Predefinito',
            },
            object_panel: {
                zoom: 'Zoom',
                group: 'Gruppo',
                ungroup: 'Separa',
                distribute: 'Distribuisci',
                hdist: 'Distribuzione orizzontale',
                vdist: 'Distribuzione verticale',
                align: 'Allinea',
                left_align: 'Allinea a sinistra',
                center_align: 'Allinea al centro',
                right_align: 'Allinea a destra',
                top_align: 'Allinea in alto',
                middle_align: 'Allinea al centro',
                bottom_align: 'Allinea in basso',
                boolean: 'Operazioni booleane',
                union: 'Unione',
                subtract: 'Sottrazione',
                intersect: 'Intersezione',
                difference: 'Differenza',
                flip: 'Capovolgi',
                hflip: 'Capovolgi orizzontalmente',
                vflip: 'Capovolgi verticalmente',
                lock_aspect: 'Blocca proporzioni',
                unlock_aspect: 'Sblocca proporzioni',
                option_panel: {
                    fill: 'Riempimento',
                    rounded_corner: 'Angoli arrotondati',
                    sides: 'Lati',
                    font_family: 'Carattere',
                    font_style: 'Stile',
                    font_size: 'Dimensione',
                    letter_spacing: 'Spaziatura',
                    line_spacing: 'Interlinea',
                    vertical_text: 'Testo verticale',
                    start_offset: 'Offset testo',
                    vertical_align: 'Allineamento',
                    text_infill: 'Riempimento testo',
                    path_infill: 'Riempimento percorso',
                    shading: 'Sfumatura',
                    threshold: 'Soglia luminosità',
                    threshold_short: 'Soglia',
                    stroke: 'Contorno',
                    stroke_color: 'Colore tracciato',
                    stroke_width: 'Larghezza tracciato',
                    color: 'Colore',
                },
                actions_panel: {
                    replace_with: 'Sostituisci con...',
                    replace_with_short: 'Sostituisci',
                    trace: 'Traccia',
                    grading: 'Classificazione',
                    brightness: 'Luminosità',
                    sharpen: 'Nitidezza',
                    crop: 'Ritaglia',
                    bevel: 'Smusso',
                    invert: 'Inverti',
                    weld_text: 'Salda testo',
                    convert_to_path: 'Converti in percorso',
                    fetching_web_font: 'Recupero carattere online...',
                    uploading_font_to_machine: 'Caricamento carattere sulla macchina...',
                    wait_for_parsing_font: 'Analisi carattere... Attendere un secondo',
                    offset: 'Offset',
                    array: 'Array',
                    auto_arrange: 'Disposizione Automatica',
                    decompose_path: 'Scomponi',
                    disassemble_use: 'Disassembla',
                    create_textpath: 'Crea testo su percorso',
                    create_textpath_short: 'Testo percorso',
                    detach_path: 'Scomponi testo su percorso',
                    detach_path_short: 'Scomponi',
                    edit_path: 'Modifica percorso',
                    disassembling: 'Disassemblaggio in corso...',
                    ungrouping: 'Separa gruppo in corso...',
                    simplify: 'Semplifica',
                    ai_bg_removal: 'Rimozione sfondo',
                    ai_bg_removal_short: 'Rimozione sfondo',
                    ai_bg_removal_reminder: 'Premendo il pulsante verranno immediatamente utilizzati 0,2 crediti, vuoi continuare?',
                    outline: 'Contorno',
                },
                path_edit_panel: {
                    node_type: 'TIPO NODO',
                    sharp: 'Affilato',
                    round: 'Arrotondato',
                    connect: 'Connetti',
                    disconnect: 'Disconnetti',
                    delete: 'Elimina',
                },
            },
        },
        bottom_right_panel: {
            convert_text_to_path_before_export: 'Converti testo in percorso...',
            retreive_image_data: 'Recupera dati immagine...',
            export_file_error_ask_for_upload: 'Esportazione file non riuscita. Sei disposto a fornire la scena di lavoro al team di sviluppo per la segnalazione di bug?',
        },
        image_trace_panel: {
            apply: 'Applica',
            back: 'Indietro',
            cancel: 'Annulla',
            next: 'Avanti',
            brightness: 'Luminosità',
            contrast: 'Contrasto',
            threshold: 'Soglia',
            okay: 'OK',
            tuning: 'Parametri',
        },
        photo_edit_panel: {
            apply: 'Applica',
            back: 'Indietro',
            cancel: 'Annulla',
            next: 'Avanti',
            sharpen: 'Nitidezza',
            sharpness: 'Nitidezza',
            radius: 'Raggio',
            crop: 'Ritaglia',
            aspect_ratio: "Rapporto d'aspetto",
            original: 'Originale',
            free: 'Libero',
            curve: 'Curva',
            start: 'Inizio',
            processing: 'Elaborazione',
            invert: 'Inverti colore',
            okay: 'OK',
            compare: 'Confronta',
            phote_edit: 'Modifica foto',
            brightness_and_contrast: 'Luminosità / Contrasto',
            brightness: 'Luminosità',
            contrast: 'Contrasto',
        },
        document_panel: {
            document_settings: 'Impostazioni documento',
            engrave_parameters: 'Parametri di incisione',
            workarea: 'Area di lavoro',
            rotary_mode: 'Rotativo',
            borderless_mode: 'Aperto in basso',
            engrave_dpi: 'Risoluzione',
            enable_diode: 'Laser a diodi',
            enable_autofocus: 'Messa a fuoco automatica',
            extend_workarea: 'Espandi area di lavoro',
            add_on: 'Componenti aggiuntivi',
            low: 'Basso',
            medium: 'Medio',
            high: 'Alto',
            ultra: 'Altissimo',
            enable: 'Abilita',
            disable: 'Disabilita',
            cancel: 'Annulla',
            save: 'Salva',
            notification: {
                changeFromPrintingWorkareaTitle: 'Vuoi convertire gli strati di stampa nelle aree di taglio laser?',
            },
        },
        object_panels: {
            wait_for_parsing_font: 'Analisi font in corso... Attendere un secondo',
            text_to_path: {
                font_substitute_pop: 'Il tuo testo contiene caratteri non supportati dal carattere corrente. <br/>Vuoi usare <strong>%s</strong> come sostituzione?',
                check_thumbnail_warning: "Alcuni testi sono stati modificati in altri font durante la conversione in percorsi e alcuni caratteri potrebbero non essere convertiti correttamente.\nSi prega di controllare nuovamente l'anteprima prima di inviare il task.",
                error_when_parsing_text: 'Errore durante la conversione del testo in percorso',
                use_current_font: 'Usa font corrente',
                retry: 'Riprova più tardi o scegli un altro carattere',
            },
            lock_desc: 'Mantieni proporzioni (MAIUSC)',
        },
        tool_panels: {
            cancel: 'Annulla',
            confirm: 'Conferma',
            grid_array: 'Crea griglia',
            array_dimension: 'Dimensioni griglia ',
            rows: 'Righe',
            columns: 'Colonne',
            array_interval: 'Spaziatura griglia',
            dx: 'X',
            dy: 'Y',
            offset: 'Offset',
            nest: 'Ottimizza disposizione',
            _offset: {
                direction: 'Direzione offset',
                inward: 'Interno',
                outward: "Verso l'esterno",
                dist: 'Distanza di offset',
                corner_type: 'Angolo',
                sharp: 'Appuntito',
                round: 'Arrotondato',
                fail_message: 'Impossibile offsettare gli oggetti.',
                not_support_message: 'Elementi selezionati contenenti tag SVG non supportati:\nImmagine, Gruppo, Testo e Oggetto importato.',
            },
            _nest: {
                start_nest: 'Disponi',
                stop_nest: 'Interrompi',
                end: 'Chiudi',
                spacing: 'Spaziatura',
                rotations: 'Possibili rotazioni',
                no_element: 'Non ci sono elementi da disporre.',
            },
        },
        network_testing_panel: {
            network_testing: 'Test di rete',
            local_ip: 'Indirizzo IP locale:',
            insert_ip: 'Indirizzo IP dispositivo di destinazione:',
            empty_ip: "#818 Inserire prima l'indirizzo IP di destinazione.",
            start: 'Inizia',
            end: 'Termina',
            testing: 'Test di rete in corso...',
            invalid_ip: '#818 Indirizzo IP non valido',
            ip_startswith_169: "#843 L'indirizzo IP della macchina inizia con 169.254",
            connection_quality: 'Qualità della connessione',
            average_response: 'Tempo medio di risposta',
            test_completed: 'Test completato',
            test_fail: 'Test fallito',
            cannot_connect_1: "#840 Impossibile connettersi all'IP di destinazione.",
            cannot_connect_2: "#840 Impossibile connettersi all'IP di destinazione. Assicurarsi che il dispositivo di destinazione sia sulla stessa rete.",
            network_unhealthy: '#841 Qualità della connessione <70 o tempo medio di risposta >100ms',
            device_not_on_list: "#842 La macchina non è nell'elenco, ma la qualità della connessione è >70 e il tempo medio di risposta è <100ms",
            hint_device_often_on_list: "La macchina spesso non viene trovata nell'elenco?",
            link_device_often_on_list: 'https://support.flux3dp.com/hc/en-us/articles/360001841636',
            hint_connect_failed_when_sending_job: "Connessione non riuscita durante l'invio di un lavoro?",
            link_connect_failed_when_sending_job: 'https://support.flux3dp.com/hc/en-us/articles/360001841656',
            hint_connect_camera_timeout: "Timeout durante l'avvio dell'anteprima della fotocamera?",
            link_connect_camera_timeout: 'https://support.flux3dp.com/hc/en-us/articles/360001791895',
            cannot_get_local: "Accesso all'indirizzo IP locale non riuscito.",
            fail_to_start_network_test: '#817 Impossibile avviare il test di rete.',
            linux_permission_hint: 'Questo errore si verifica di solito a causa di autorizzazioni insufficienti.\nEseguire "sudo beam-studio --no-sandbox" nel terminale per ottenere le autorizzazioni e eseguire il test di rete.',
        },
        layer_color_config_panel: {
            layer_color_config: 'Configurazioni colore strato',
            color: 'Colore',
            power: 'Potenza',
            speed: 'Velocità',
            repeat: 'Esegui',
            add: 'Aggiungi',
            save: 'Salva',
            cancel: 'Annulla',
            default: 'Ripristina predefiniti',
            add_config: 'Aggiungi colore',
            in_use: 'Questo colore è in uso.',
            no_input: 'Inserire un codice colore esadecimale valido.',
            sure_to_reset: 'Perderai tutti i parametri personalizzati, sei sicuro di ripristinare le impostazioni predefinite?',
            sure_to_delete: 'Sei sicuro di voler eliminare questa impostazione colore?',
        },
        rating_panel: {
            title: 'Ti piace Beam Studio?',
            description: 'Se ti piace Beam Studio, ti saremmo molto grati se potessi valutarci.',
            dont_show_again: 'Non mostrare la prossima volta.',
            thank_you: 'Grazie!',
        },
        svg_editor: {
            unnsupported_file_type: 'Il tipo di file non è supportato direttamente. Convertire il file in SVG o bitmap',
            unable_to_fetch_clipboard_img: "Impossibile recuperare l'immagine dagli appunti",
        },
        units: {
            walt: 'W',
            mm: 'mm',
        },
        path_preview: {
            play: 'Riproduci',
            pause: 'Pausa',
            stop: 'Stop',
            play_speed: 'Velocità di riproduzione',
            travel_path: 'Percorso di spostamento',
            invert: 'Inverti',
            preview_info: 'Informazioni di anteprima',
            size: 'Dimensioni',
            estimated_time: 'Tempo totale stimato',
            cut_time: 'Tempo di taglio',
            rapid_time: 'Tempo di spostamento',
            cut_distance: 'Distanza di taglio',
            rapid_distance: 'Distanza di spostamento',
            current_position: 'Posizione corrente',
            remark: '* Tutte le informazioni sono valori stimati come riferimento.',
            start_here: 'Inizia qui',
            end_preview: 'Fine anteprima',
        },
        shapes_panel: {
            title: 'Elementi',
            basic: 'Di base',
            shape: 'Forma',
            graphics: 'Grafica',
            arrow: 'Freccia',
            label: 'Etichetta',
            decor: 'Decorazione',
            circular: 'Circolare',
            corner: 'Angolo',
            line: 'Linea',
            photo: 'Foto',
            ribbon: 'Nastro',
            speech: 'Discorso',
            text: 'Riquadro di testo',
            animals: 'Animale',
            birds: 'Uccello',
            land: 'Terra',
            sea: 'Mare',
            holidays: 'Festività',
            celebration: 'Celebrazione',
            CNY: 'Capodanno cinese',
            easter: 'Pasqua',
            halloween: 'Halloween',
            valentines: 'San Valentino',
            Xmas: 'Natale',
            nature: 'Natura',
            elements: 'Elementi',
            environment: 'Ambiente',
            plants: 'Piante',
            weather: 'Meteo',
        },
        announcement_panel: {
            title: 'Annuncio',
            dont_show_again: 'Non mostrare nuovamente',
        },
    },
    editor: {
        prespray_area: 'Area di preparazione',
        opacity: 'Anteprima opacità',
        exposure: 'Anteprima esposizione',
    },
    flux_id_login: {
        connection_fail: '#847 Impossibile connettersi al servizio membri FLUX.',
        login_success: 'Accesso effettuato con successo.',
        login: 'Accedi',
        unlock_shape_library: 'Accedi per sbloccare il database delle forme.',
        email: 'Email',
        password: 'Password',
        remember_me: 'Ricordami',
        forget_password: 'Password dimenticata?',
        register: 'Crea il tuo account FLUX',
        offline: 'Lavora offline',
        work_offline: 'Lavora offline',
        incorrect: 'Email o password errati.',
        not_verified: "L'indirizzo email non è stato ancora verificato.",
        new_to_flux: 'Nuovo su FLUX? Registrati.',
        signup_url: 'https://id.flux3dp.com/user/login#up',
        lost_password_url: 'https://id.flux3dp.com/user/forgot-password',
        flux_plus: {
            explore_plans: 'Esplora i piani FLUX+',
            thank_you: 'Grazie per essere un membro prezioso!',
            ai_credit_tooltip: 'Per la rimozione dello sfondo AI',
            flux_credit_tooltip: 'Per i file Design Market e la rimozione dello sfondo AI',
            goto_member_center: 'Vai al Centro membri',
            access_plus_feature_1: 'Stai accedendo a una',
            access_plus_feature_2: 'funzionalità.',
            access_plus_feature_note: "Devi avere l'abbonamento FLUX+ per accedere a questa funzionalità.",
            access_monotype_feature: "Non hai l'add-on Caratteri Monotype.",
            access_monotype_feature_note: "Devi avere l'abbonamento FLUX+ Pro o l'add-on Caratteri Monotype per accedere a questa funzionalità.",
            learn_more: 'Per saperne di più',
            get_addon: "Ottieni l'add-on",
            subscribe_now: 'Iscriviti adesso',
            website_url: 'https://flux3dp.com/subscription',
            member_center_url: 'https://member.flux3dp.com/en-US/subscription',
            features: {
                ai_bg_removal: 'Rimozione sfondo AI',
                my_cloud: 'Archiviazione cloud illimitata',
                boxgen: 'Generatore di scatole 3D',
                dmkt: '1000+ file di progettazione',
                monotype: '250+ caratteri premium',
            },
        },
    },
    noun_project_panel: {
        login_first: 'Accedi per sbloccare il database delle forme.',
        enjoy_shape_library: 'Goditi il database delle forme.',
        shapes: 'Forme',
        elements: 'Elementi',
        recent: 'Recenti',
        search: 'Cerca',
        clear: 'Pulisci',
        export_svg_title: 'Esportazione SVG non riuscita',
        export_svg_warning: "Questo progetto contiene oggetti protetti da copyright. Pertanto Beam Studio escluderà automaticamente questi oggetti durante l'esportazione. È comunque possibile salvare l'intero progetto nel formato Beam Studio Scene (.beam) per conservare tutti i dati. Vuoi comunque esportare?",
        learn_more: 'Per saperne di più',
    },
    change_logs: {
        change_log: 'Registro delle modifiche:',
        added: 'Aggiunto:',
        fixed: 'Risolto:',
        changed: 'Modificato:',
        see_older_version: 'Guarda le versioni precedenti',
        help_center_url: 'https://support.flux3dp.com/hc/en-us/sections/360000421876',
    },
    select_device: {
        select_device: 'Seleziona dispositivo',
        auth_failure: '#811 Autenticazione non riuscita',
        unable_to_connect: '#810 Impossibile stabilire una connessione stabile con la macchina',
    },
    device: {
        pause: 'Pausa',
        paused: 'In pausa',
        pausing: 'In pausa',
        select_printer: 'Seleziona stampante',
        retry: 'Riprova',
        status: 'Stato',
        busy: 'Occupato',
        ready: 'Pronto',
        reset: 'Ripristina',
        abort: 'Interrompi',
        start: 'Avvia',
        please_wait: 'Attendere prego...',
        quit: 'Esci',
        completing: 'Completamento',
        aborted: 'Interrotto',
        completed: 'Completato',
        aborting: 'Interrompendo',
        starting: 'Avviando',
        preparing: 'Preparazione',
        resuming: 'Riprendendo',
        scanning: 'Scansione',
        occupied: 'Manutenzione',
        running: 'Lavorazione',
        uploading: 'Caricamento',
        processing: 'Elaborazione',
        disconnectedError: {
            caption: 'Macchina disconnessa',
            message: 'Si prega di confermare se la rete di %s è disponibile',
        },
        unknown: 'Sconosciuto',
        pausedFromError: 'In pausa da errore',
        model_name: 'Nome modello',
        IP: 'IP',
        serial_number: 'Numero di serie',
        firmware_version: 'Versione firmware',
        UUID: 'UUID',
        select: 'Seleziona',
        deviceList: 'Elenco dispositivi',
        disable: 'Disabilita',
        enable: 'Abilita',
        submodule_type: 'Module',
        cartridge_serial_number: 'Ink cartridge serial number',
        ink_color: 'Ink color',
        ink_type: 'Ink type',
        ink_level: 'Ink level',
        close_door_to_read_cartridge_info: "To access ink information, please close the machine's door cover.",
        cartridge_info_read_failed: 'Please ensure that the ink is fully inserted. Try to remove the ink and install it back.',
        cartridge_info_verification_failed: 'Please verify that your ink is FLUX authentic.',
    },
    monitor: {
        monitor: 'MONITOR',
        go: 'Avvia',
        resume: 'Riprendi',
        start: 'Avvia',
        pause: 'Pausa',
        stop: 'Interrompi',
        record: 'Registra',
        camera: 'Telecamera',
        taskTab: 'Attività',
        connecting: 'Connessione in corso, attendere...',
        HARDWARE_ERROR_MAINBOARD_ERROR: '#401 Errore critico: Scheda madre offline. Contattare assistenza FLUX.',
        HARDWARE_ERROR_SUBSYSTEM_ERROR: '#402 Errore critico: Nessuna risposta dal sottosistema. Contattare assistenza FLUX.',
        HARDWARE_ERROR_PUMP_ERROR: '#900 Controllare serbatoio acqua.',
        HARDWARE_ERROR_DOOR_OPENED: '#901 Chiudere porta per continuare.',
        HARDWARE_ERROR_OVER_TEMPERATURE: '#902 Surriscaldamento. Attendere qualche minuto.',
        HARDWARE_ERROR_BOTTOM_OPENED: '#903 Fondo aperto. Chiudere fondo per continuare.',
        HARDWARE_ERROR_PLATFORM_HOMING_FAILED: '#910 Reset asse Z non riuscito',
        HARDWARE_ERROR_DRAWER_OPENED: '#911 Cassetto aperto',
        HARDWARE_ERROR_FIRE_DETECTED: '#912 Sensore fiamma anomalo',
        HARDWARE_ERROR_AIR_ASSIST_ABNORMAL: '#913 Sensore flusso aria anomalo',
        HARDWARE_ERROR_ROTARY_NOT_DETECTED: '#914 Nessun modulo rotante rilevato',
        HARDWARE_ERROR_HOMING_PULLOFF_FAILED: '#920 Errore pull-off homing',
        HARDWARE_ERROR_HEADTYPE_MISMATCH: '#915 Rilevato modulo non corretto. Installare il modulo corretto per continuare.',
        HARDWARE_ERROR_HEADTYPE_NONE: '#917 Modulo non rilevato. Assicurarsi che il modulo sia installato correttamente per continuare.',
        HARDWARE_ERROR_HEADTYPE_UNKNOWN: '#918 Modulo sconosciuto rilevato. Installare il modulo corretto per continuare.',
        HARDWARE_ERROR_PRINTER_NO_RESPONSE: '#919 Nessuna risposta dal modulo stampante.',
        USER_OPERATION_ROTARY_PAUSE: 'Passare a motore rotante',
        USER_OPERATION_REMOVE_CARTRIDGE: 'Rimuovere la cartuccia per continuare.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_MISMATCH: '#915 Rilevato modulo non corretto. Installare il modulo corretto per continuare.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_NONE: '#917 Modulo non rilevato. Assicurarsi che il modulo sia installato correttamente per continuare.',
        USER_OPERATION_CHANGE_TOOLHEAD_HEADTYPE_UNKNOWN: '#918 Modulo sconosciuto rilevato. Installare il modulo corretto per continuare.',
        USER_OPERATION_CHANGE_TOOLHEAD: 'Installare il modulo corretto per continuare.',
        USER_OPERATION_CHANGE_CARTRIDGE_CARTRIDGE_NOT_DETECTED: 'Si prega di inserire la cartuccia per continuare.',
        USER_OPERATION_CHANGE_CARTRIDGE: 'Si prega di inserire la cartuccia corretta per continuare.',
        USER_OPERATION: 'Si prega di seguire le istruzioni sul pannello del dispositivo per continuare.',
        RESOURCE_BUSY: 'La macchina è occupata\nSe non è in esecuzione, riavvia la macchina',
        DEVICE_ERROR: 'Errore imprevisto\nRiavvia la macchina',
        NO_RESPONSE: '#905 Errore di connessione alla scheda madre.\nRiavvia la macchina.',
        SUBSYSTEM_ERROR: "#402 Errore critico: nessuna risposta dal sottosistema. Contatta l'assistenza FLUX.",
        HARDWARE_FAILURE: 'Errore imprevisto\nRiavvia la macchina',
        MAINBOARD_OFFLINE: '#905 Errore di connessione alla scheda madre.\nRiavvia la macchina.',
        bug_report: 'Segnalazione bug',
        processing: 'Elaborazione in corso',
        savingPreview: 'Generazione anteprime',
        hour: 'h',
        minute: 'm',
        second: 's',
        left: 'sinistra',
        temperature: 'Temperatura',
        forceStop: "Interrompere l'attività corrente?",
        upload: 'Carica',
        download: 'Scarica',
        relocate: 'Sposta',
        cancel: 'Annulla',
        prepareRelocate: 'Preparazione spostamento',
        extensionNotSupported: 'Formato file non supportato',
        fileExistContinue: 'Il file esiste già, sovrascrivere?',
        confirmFileDelete: 'Eliminare questo file?',
        ask_reconnect: 'Connessione persa. Riconnettersi?',
        task: {
            BEAMBOX: 'Taglio laser',
            'N/A': 'Modalità libera',
        },
    },
    alert: {
        caption: 'Errore',
        duplicated_preset_name: 'Nome preset duplicato',
        info: 'INFO',
        warning: 'ATTENZIONE',
        error: 'UH-OH',
        instruction: 'Istruzione',
        oops: 'Oops...',
        retry: 'Riprova',
        abort: 'Interrompi',
        confirm: 'Conferma',
        cancel: 'Annulla',
        close: 'Chiudi',
        ok: 'OK',
        ok2: 'OK',
        yes: 'Sì',
        no: 'No',
        stop: 'Interrompi',
        save: 'Salva',
        dont_save: 'Non salvare',
        learn_more: 'Per saperne di più',
    },
    caption: {
        connectionTimeout: 'Timeout connessione',
    },
    message: {
        cancelling: 'Annullamento...',
        connecting: 'Connessione in corso...',
        connectingMachine: 'Connessione a %s in corso...',
        tryingToConenctMachine: 'Tentativo di connessione alla macchina...',
        connected: 'Connesso',
        authenticating: 'Autenticazione in corso...',
        enteringRawMode: 'Accesso alla modalità raw...',
        endingRawMode: 'Uscita dalla modalità raw...',
        enteringLineCheckMode: 'Accesso alla modalità controllo linea...',
        endingLineCheckMode: 'Uscita dalla modalità controllo linea...',
        exitingRotaryMode: 'Uscita dalla modalità rotante...',
        turningOffFan: 'Spegnimento ventola...',
        turningOffAirPump: 'Spegnimento pompa aria...',
        gettingLaserSpeed: 'Lettura velocità testa laser...',
        settingLaserSpeed: 'Impostazione velocità testa laser...',
        retrievingCameraOffset: 'Lettura offset telecamera...',
        connectingCamera: 'Connessione telecamera...',
        homing: 'Riporto a zero...',
        connectionTimeout: "#805 Timeout connessione dispositivo. Controllare lo stato della rete e l'indicatore Wi-Fi della macchina.",
        getProbePosition: 'Ottenimento della posizione della sonda...',
        device_not_found: {
            caption: 'Macchina predefinita non trovata',
            message: "#812 Controllare l'indicatore Wi-Fi della macchina",
        },
        device_busy: {
            caption: 'Macchina occupata',
            message: 'La macchina sta eseguendo un altro compito, riprovare più tardi. Se smette di funzionare, riavviare la macchina.',
        },
        device_is_used: "La macchina è in uso, interrompere l'attività corrente?",
        monitor_too_old: {
            caption: 'Firmware obsoleto',
            content: '#814 Installare l\'ultimo firmware con <a target="_blank" href="http://helpcenter.flux3dp.com/hc/en-us/articles/216251077">questa guida</a>.',
        },
        unknown_error: "#821 L'applicazione ha rilevato un errore sconosciuto, utilizzare Aiuto > Menu > Segnalazione bug.",
        unknown_device: '#826 Impossibile connettersi alla macchina, assicurarsi che la USB sia collegata alla macchina',
        unsupport_osx_version: 'La versione corrente di macOS %s potrebbe non supportare alcune funzioni. Aggiornare a macOS 11+.',
        unsupport_win_version: "La versione corrente del sistema operativo %s potrebbe non supportare alcune funzioni. Aggiornare all'ultima versione.",
        need_password: 'Necessaria password per connettersi alla macchina',
        unavailableWorkarea: "#804 L'area di lavoro corrente supera quella della macchina selezionata. Controllare l'area di lavoro della macchina selezionata o impostare l'area di lavoro da Modifica > Impostazioni documento.",
        please_enter_dpi: 'Inserire la dimensione del file (in mm)',
        auth_error: "#820 Errore di autenticazione: aggiornare Beam Studio e il firmware della macchina all'ultima versione.",
        usb_unplugged: 'Connessione USB persa. Controllare la connessione USB',
        uploading_fcode: 'Caricamento FCode',
        cant_connect_to_device: '#827 Impossibile connettersi alla macchina, controllare la connessione',
        unable_to_find_machine: 'Impossibile trovare la macchina ',
        disconnected: 'Connessione instabile, controllare la connessione al dispositivo e riprovare più tardi',
        unable_to_start: "#830 Impossibile avviare l'attività. Riprovare. Se il problema persiste, contattarci con segnalazione bug:",
        camera: {
            camera_cable_unstable: "È stato rilevato che la fotocamera sta trasferendo le foto in modo instabile. L'anteprima può essere eseguita normalmente, ma potrebbero esserci problemi di lentezza o timeout.",
            fail_to_transmit_image: '#845 Si è verificato un errore durante la trasmissione dell\'immagine. Riavviare la macchina o Beam Studio. Se l\'errore persiste, seguire <a target="_blank" href="https://flux3dp.zendesk.com/hc/en-us/articles/4402756056079">questa guida</a>.',
            ws_closed_unexpectly: '#844 La connessione alla fotocamera della macchina è stata chiusa inaspettatamente. Se l\'errore persiste, seguire <a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/4402755805071">questa guida</a>.',
            continue_preview: 'Continua',
            abort_preview: 'Interrompi',
        },
        preview: {
            camera_preview: 'Anteprima fotocamera',
            auto_focus: 'Messa a fuoco automatica',
            auto_focus_instruction: "Spostare il modulo laser sopra l'oggetto e seguire le istruzioni animate per premere AF per mettere a fuoco.",
            already_performed_auto_focus: 'Hai già eseguito la messa a fuoco automatica, vuoi utilizzare i valori esistenti?',
            please_enter_height: "Inserire l'altezza dell'oggetto. Per catturare uno scatto di fotocamera preciso.",
            apply: 'Applica',
            enter_manually: 'Inserisci manualmente',
            adjust: 'Regola',
            adjust_height_tooltip: 'Selezionare la casella di controllo per abilitare la modifica.',
        },
        unsupported_example_file: "Il file di esempio selezionato non è supportato dall'area di lavoro corrente.",
        time_remaining: 'Tempo rimanente:',
    },
    machine_status: {
        '0': 'Inattivo',
        '1': 'Inizializzazione',
        '2': 'TRASFORMAZIONE',
        '4': 'Avvio',
        '6': 'Ripresa',
        '16': 'Lavorazione',
        '18': 'Ripresa',
        '32': 'In pausa',
        '36': 'In pausa',
        '38': 'Messa in pausa',
        '48': 'In pausa',
        '50': 'Messa in pausa',
        '64': 'Completato',
        '66': 'Completamento',
        '68': 'Preparazione',
        '128': 'Interrotto',
        '256': 'Allarme',
        '512': 'Fatale',
        '-17': '.machine_status.-17',
        '-10': 'Modalità manutenzione',
        '-2': 'Scansione',
        '-1': 'Manutenzione',
        UNKNOWN: 'Sconosciuto',
    },
    calibration: {
        update_firmware_msg1: 'Il firmware non supporta questa funzione. Aggiornare il firmware alla versione v',
        update_firmware_msg2: 'o superiore per continuare。 (Menu > Macchina > [La tua macchina] > Aggiorna firmware)',
        camera_calibration: 'Calibrazione fotocamera',
        diode_calibration: 'Calibrazione modulo laser a diodi',
        module_calibration_printer: 'Calibrazione modulo stampante',
        module_calibration_2w_ir: 'Calibrazione modulo a infrarossi',
        back: 'INDIETRO',
        next: 'AVANTI',
        skip: 'SALTA',
        cancel: 'ANNULLA',
        finish: 'COMPLETATO',
        do_engraving: 'ESEGUI INTAGLIO',
        start_engrave: 'AVVIA INTAGLIO',
        start_printing: 'INIZIA STAMPA',
        ask_for_readjust: 'Vuoi saltare il passaggio di incisione e poi scattare una foto e calibrare direttamente?',
        please_goto_beambox_first: 'Per utilizzare questa funzione, passa prima alla Modalità Incisione (Beambox).',
        please_place_paper: "Posiziona un foglio di carta bianca formato A4 o Letter nell'angolo in alto a sinistra dell'area di lavoro.",
        please_place_paper_center: "Posiziona un foglio di carta bianca formato A4 o Letter al centro dell'area di lavoro.",
        please_place_dark_colored_paper: "Posiziona un foglio di carta colorata scura formato A4 o Letter al centro dell'area di lavoro.",
        please_refocus: {
            beambox: "Regola delicatamente la piattaforma al punto focale (l'altezza dell'acrilico ribaltato)",
            beamo: "Regola delicatamente la testa laser per mettere a fuoco l'oggetto di incisione (l'altezza dell'acrilico ribaltato)",
            beamo_af: "Tocca delicatamente due volte il pulsante laterale dell'accessorio di messa a fuoco automatica e lascia che la sonda tocchi delicatamente il materiale.",
            hexa: "Fai doppio clic sul pulsante di regolazione dell'altezza per sollevare il tavolo a nido d'ape e fare in modo che la sonda tocchi il materiale di incisione.",
        },
        without_af: 'Senza messa a fuoco automatica',
        with_af: 'Con messa a fuoco automatica',
        dx: 'X',
        dy: 'Y',
        rotation_angle: 'Rotazione',
        x_ratio: 'Rapporto X',
        y_ratio: 'Rapporto Y',
        show_last_config: 'Mostra ultima calibrazione',
        use_last_config: 'Usa ultimi valori di calibrazione',
        taking_picture: 'Acquisizione immagine in corso...',
        analyze_result_fail: "Impossibile analizzare l'immagine acquisita.<br/>Assicurarsi che:<br/>1. L'immagine acquisita copra interamente il foglio bianco.<br/>2. La piattaforma sia a fuoco (all'altezza dell'acrilico ribaltato).",
        drawing_calibration_image: 'Generazione immagine di calibrazione in corso...',
        calibrate_done: 'Calibrazione completata. Si ottiene una maggiore precisione della fotocamera quando a fuoco.',
        calibrate_done_diode: "Calibrazione completata. L'offset del modulo laser a diodi è stato salvato.",
        hint_red_square: 'Allineare il bordo esterno del quadrato rosso con il quadrato di taglio.',
        hint_adjust_parameters: 'Usare questi parametri per regolare il quadrato rosso',
        zendesk_link: 'https://support.flux3dp.com/hc/en-us/articles/360001811416',
        please_do_camera_calibration_and_focus: {
            beambox: "Per calibrare il modulo laser a diodi è necessaria la fotocamera.\nAssicurarsi che la fotocamera di questa macchina sia stata calibrata.\nE regolare delicatamente la piattaforma al punto focale (l'altezza dell'acrilico ribaltato).",
            beamo: "Per calibrare il modulo laser a diodi è necessaria la fotocamera.\nAssicurarsi che la fotocamera di questa macchina sia stata calibrata.\nE regolare delicatamente la testa laser a fuoco sull'oggetto di incisione (l'altezza dell'acrilico ribaltato).",
        },
        downloading_pictures: 'Download delle foto in corso...',
        failed_to_download_pictures: "#848 Impossibile scaricare le foto, si prega di contattare l\\'assistenza FLUX.",
        uploading_images: 'Caricamento delle immagini in corso...',
        calculating_camera_matrix: 'Calcolo della matrice della fotocamera in corso...',
        calculating_regression_parameters: 'Calcolo dei parametri di regressione in corso...',
        failed_to_calibrate_camera: "#848 Impossibile calibrare la fotocamera, si prega di contattare l'assistenza FLUX.",
        failed_to_save_calibration_results: "#849 Impossibile salvare i risultati della calibrazione, si prega di riprovare. Se il problema persiste, si prega di contattare l'assistenza FLUX.",
        ador_autofocus_material: 'Premere l\'icona "AF" nella pagina principale della macchina per 3 secondi e lasciare che la sonda tocchi delicatamente il materiale.',
        ador_autofocus_focusing_block: 'Premere l\'icona "AF" nella pagina principale della macchina per 3 secondi e lasciare che la sonda tocchi il blocco di messa a fuoco.',
        align_red_cross_cut: 'Si prega di allineare il centro della croce rossa con la croce di taglio.',
        align_red_cross_print: 'Si prega di allineare il centro della croce rossa con la croce stampata.',
        retake: 'Riprendi immagine',
        calibrate_camera_before_calibrate_modules: 'Si prega di eseguire prima la calibrazione della fotocamera e poi la calibrazione dei moduli.',
        check_checkpoint_data: 'Dati del checkpoint',
        checking_checkpoint: 'Controllo dei dati del checkpoint...',
        found_checkpoint: 'I dati del checkpoint sono stati trovati sul tuo dispositivo. Desideri recuperarli dal checkpoint?',
        use_old_camera_parameter: 'Vuoi utilizzare i parametri attuali della lente della fotocamera?',
        downloading_checkpoint: 'Download dei dati del checkpoint...',
        failed_to_parse_checkpoint: 'Impossibile analizzare i dati del checkpoint.',
        check_device_pictures: 'Controlla le immagini del dispositivo',
        checking_pictures: 'Controllo delle immagini del dispositivo in corso...',
        no_picutre_found: '#846 Il tuo dispositivo non dispone di foto raw disponibili per la calibrazione. Si prega di contattare il supporto FLUX.',
        calibrating_with_device_pictures: 'Calibrazione con le immagini del dispositivo in corso...',
        failed_to_calibrate_with_pictures: '#847 Impossibile calibrare con le immagini del dispositivo.',
        getting_plane_height: 'Ottenere Altezza del Piano...',
        preparing_to_take_picture: 'Preparazione per scattare una foto...',
        put_paper: 'Metti Carta',
        put_paper_step1: "Si prega di posizionare un foglio di carta bianco di formato A4 o Lettera al centro dell'area di lavoro.",
        put_paper_step2: 'Fissare i quattro angoli della carta per garantire che sia piatta.',
        put_paper_step3: 'Fare clic su "AVVIA INTAGLIO".',
        solve_pnp_title: 'Allineare i Punti di Riferimento',
        solve_pnp_step1: 'Allineare i punti di incisione in base al numero e alla posizione prevista di ciascun punto di riferimento rosso.',
        solve_pnp_step2: 'È possibile premere "Riprendi immagine" per riallineare o regolare manualmente le posizioni dei marcatori.',
        align_olt: 'Posizione: Allineare con il punto inciso in alto a sinistra esterno.',
        align_ort: 'Posizione: Allineare con il punto inciso in alto a destra esterno.',
        align_olb: 'Posizione: Allineare con il punto inciso in basso a sinistra esterno.',
        align_orb: 'Posizione: Allineare con il punto inciso in basso a destra esterno.',
        align_ilt: 'Posizione: Allineare con il punto inciso in alto a sinistra interno.',
        align_irt: 'Posizione: Allineare con il punto inciso in alto a destra interno.',
        align_ilb: 'Posizione: Allineare con il punto inciso in basso a sinistra interno.',
        align_irb: 'Posizione: Allineare con il punto inciso in basso a destra interno.',
        elevate_and_cut: 'Solleva e Taglia',
        elevate_and_cut_step_1: "Posiziona un pezzo di legno di dimensioni A4 di colore chiaro al centro dell'area di lavoro, elevato a 20 mm.",
        elevate_and_cut_step_1_prism_lift: 'Utilizza Ador Prism Lift con una lunghezza massima di 14 mm insieme a un pezzo di legno di almeno 6 mm di spessore.',
        with_prism_lift: 'Con Ador Prism Lift',
        without_prism_lift: 'Senza Ador Prism Lift',
        camera_parameter_saved_successfully: 'Parametri della fotocamera salvati con successo.',
        failed_to_save_camera_parameter: 'Impossibile salvare i parametri della fotocamera.',
        failed_to_solve_pnp: 'Impossibile risolvere la posizione della fotocamera.',
    },
    input_machine_password: {
        require_password: '"%s" richiede una password',
        connect: 'CONNETTI',
        password: 'Password',
    },
    tutorial: {
        skip: 'Salta',
        welcome: 'BENVENUTO',
        suggest_calibrate_camera_first: 'Consigliamo agli utenti di calibrare la fotocamera inizialmente e rifocalizzare prima di ogni anteprima per risultati ottimali.<br/>Vuoi confermare per effettuare la calibrazione ora?<br/>(Puoi saltarlo ora e farlo più tardi cliccando su "Menu" > "Macchina" > [La Tua Macchina] > "Calibra Fotocamera".)',
        camera_calibration_failed: 'Calibrazione fotocamera non riuscita',
        ask_retry_calibration: 'Vuoi calibrare nuovamente la fotocamera?',
        needNewUserTutorial: 'Vorresti iniziare un tutorial?<br/>(Puoi saltarlo ora e iniziarlo più tardi cliccando su "Aiuto" > "Mostra Tutorial di Avvio".)',
        needNewInterfaceTutorial: 'Vorresti iniziare un tutorial per la nuova interfaccia di Beam Studio?<br/>(Puoi saltarlo ora e iniziarlo più tardi cliccando su "Aiuto" > "Mostra Introduzione all\'Interfaccia".)',
        next: 'AVANTI',
        look_for_machine: 'Ricerca macchina per tutorial...',
        unable_to_find_machine: 'Impossibile trovare la macchina per il tutorial. Vuoi andare alla pagina delle impostazioni di connessione, riprovare o saltare il tutorial?',
        skip_tutorial: 'Hai saltato il tutorial. Puoi sempre avviare il tutorial facendo clic su "Aiuto" > "Mostra tutorial di avvio"',
        set_connection: 'Imposta connessione',
        retry: 'Riprova',
        newUser: {
            draw_a_circle: 'Disegna un cerchio',
            drag_to_draw: 'Trascina per disegnare',
            infill: 'Attiva riempimento',
            switch_to_object_panel: 'Passa al pannello degli oggetti',
            switch_to_layer_panel: 'Passa al pannello livelli',
            set_preset_wood_engraving: 'Imposta predefinito: Legno - Incisione',
            set_preset_wood_cut: 'Imposta predefinito: Legno - Taglio',
            add_new_layer: 'Aggiungi nuovo livello',
            draw_a_rect: 'Disegna un rettangolo',
            switch_to_preview_mode: 'Passa alla modalità anteprima',
            preview_the_platform: 'Anteprima la piattaforma',
            end_preview_mode: 'Termina modalità anteprima',
            put_wood: '1. Metti il campione di legno',
            adjust_focus: '2. Regola la messa a fuoco',
            close_cover: '3. Chiudi il coperchio',
            send_the_file: 'Invia il file',
            end_alert: 'Sei sicuro di voler terminare il tutorial?',
            please_select_wood_engraving: 'Seleziona gentilmente il preset "Legno - Incisione".',
            please_select_wood_cutting: 'Seleziona gentilmente il preset "Legno - Taglio".',
        },
        newInterface: {
            camera_preview: 'Anteprima fotocamera',
            select_image_text: 'Seleziona / Immagine / Testo',
            basic_shapes: 'Forme di base',
            pen_tool: 'Strumento penna',
            add_new_layer: 'Aggiungi nuovo livello',
            rename_by_double_click: 'Rinomina con doppio clic',
            drag_to_sort: 'Trascina per ordinare',
            layer_controls: 'Clic destro per selezionare i controlli del livello:\nDuplica / Unisci / Blocca / Elimina livelli',
            switch_between_layer_panel_and_object_panel: 'Passa tra pannello livelli e pannello oggetti',
            align_controls: 'Controlli allineamento',
            group_controls: 'Controlli gruppo',
            shape_operation: 'Operazione forma',
            flip: 'Capovolgi',
            object_actions: 'Azioni oggetto',
            end_alert: 'Sei sicuro di terminare la presentazione della nuova interfaccia utente?',
            select_machine: 'Seleziona una macchina',
            start_work: 'Inizia il lavoro',
        },
        gesture: {
            pan: 'Scorri la tela con due dita.',
            zoom: 'Avvicina/allontana con due dita per ingrandire/rimpicciolire la tela.',
            click: 'Tocca per selezionare oggetto.',
            drag: 'Trascina per selezionare più oggetti.',
            hold: 'Premi e tieni premuto per aprire menu contestuale.',
        },
        links: {
            adjust_focus: 'https://flux3dp.zendesk.com/hc/en-us/articles/360001684196',
        },
        tutorial_complete: 'Ecco fatto per il tutorial. Ora è il momento di creare!',
    },
    layer_module: {
        none: 'None',
        general_laser: 'Laser',
        laser_10w_diode: 'Laser a diodi da 10 W',
        laser_20w_diode: 'Laser a diodi da 20 W',
        laser_2w_infrared: 'Laser a infrarossi da 2 W',
        printing: 'Stampa',
        unknown: 'Unknown Module',
        notification: {
            convertFromPrintingModuleTitle: 'Vuoi convertire il modulo di stampa in modulo laser?',
            convertFromPrintingModuleMsg: 'Tieni presente che se completi questa operazione, le impostazioni di colore dello strato di stampa verranno rimosse e impostate in base allo strato corrente.',
            convertFromLaserModuleTitle: 'Vuoi convertire il modulo laser in modulo di stampa?',
            convertFromLaserModuleMsg: 'Tieni presente che se completi questa operazione, le impostazioni dello strato laser verranno rimosse e impostate in base allo strato corrente.',
            importedDocumentContainsPrinting: "Il documento contiene uno strato di stampa, vuoi cambiare l'area di lavoro in Ador?",
            printingLayersCoverted: 'Gli strati di stampa sono stati convertiti in strati laser.',
            performPrintingCaliTitle: 'Esegui la calibrazione del modulo di stampa',
            performPrintingCaliMsg: 'Fare clic su "Conferma" per eseguire la calibrazione o accedere alla calibrazione dal menu in alto.<br />(Macchina> [Nome macchina] > Calibra modulo di stampa)',
            performIRCaliTitle: 'Esegui la calibrazione del modulo a infrarossi',
            performIRCaliMsg: 'Fare clic su "Conferma" per eseguire la calibrazione o accedere alla calibrazione dal menu in alto.<br />(Macchina> [Nome macchina] > Calibra modulo a infrarossi)',
        },
        non_working_area: 'Area Non Lavorativa',
    },
    qr_code_generator: {
        title: 'Generatore codici QR',
        placeholder: 'Inserisci un link o un testo',
        preview: 'Anteprima',
        error_tolerance: 'Tolleranza errore',
        error_tolerance_link: 'https://support.flux3dp.com/hc/en-us/articles/9113705072143',
        invert: 'Inverti colore sfondo',
    },
    boxgen: {
        title: 'BOXGEN',
        basic_box: 'Scatola base',
        coming_soon: 'Prossimamente',
        workarea: 'Area di lavoro',
        max_dimension_tooltip: 'Impostazione max larghezza/altezza/profondità è %s.',
        volume: 'Volume',
        outer: 'Esterno',
        inner: 'Interno',
        width: 'Larghezza',
        height: 'Altezza',
        depth: 'Profondità',
        cover: 'Coperchio',
        thickness: 'Spessore',
        add_option: 'Aggiungi opzione',
        joints: 'Giunzione',
        finger: 'Dito',
        finger_warning: 'La lunghezza interna del lato della scatola deve essere di almeno 6 mm (0,24 pollici) per essere compatibile con il collegamento a dita.',
        tSlot: 'T-Slot',
        tSlot_warning: 'La lunghezza del lato della scatola deve essere di almeno 30 mm (1,18 pollici) per essere compatibile con il collegamento a T.',
        edge: 'Bordo',
        tCount: 'Conteggio T',
        tCount_tooltip: 'Il conteggio degli slot a T si applica al lato corto; la quantità sul lato lungo viene calcolata in base alla sua lunghezza.',
        tDiameter: 'Diametro T',
        tLength: 'Lunghezza T',
        continue_import: 'Continua a importare',
        customize: 'Personalizza',
        merge: 'Unisci',
        text_label: 'Etichetta',
        beam_radius: 'Compensazione della tacca',
        beam_radius_warning: 'Rimuovere il compenso della tacca quando i bordi o le giunzioni della scatola sono corti per garantire il montaggio della scatola',
        import: 'Importa',
        cancel: 'Annulla',
        reset: 'Ripristina',
        zoom: 'Zoom',
        control_tooltip: 'Sinistro per ruotare\nScorri per lo zoom\nDestro per spostare',
        control_tooltip_touch: 'Trascina per ruotare\nStringi per lo zoom\nDue dita per spostare',
    },
    my_cloud: {
        title: 'La mia nuvola',
        loading_file: 'Caricamento...',
        no_file_title: 'Salva i file nel cloud per iniziare.',
        no_file_subtitle: 'Vai al Menu > "File" > "Salva sul cloud"',
        file_limit: 'File gratuito',
        upgrade: 'Aggiorna',
        sort: {
            most_recent: 'Più recenti',
            oldest: 'Meno recenti',
            a_to_z: 'Nome: A-Z',
            z_to_a: 'Nome: Z-A',
        },
        action: {
            open: 'Apri',
            rename: 'Rinomina',
            duplicate: 'Duplica',
            download: 'Scarica',
            delete: 'Elimina',
            confirmFileDelete: "Sei sicuro di voler eliminare questo file? Quest'azione non può essere annullata.",
        },
        save_file: {
            choose_action: 'Salva file:',
            save: 'Salva',
            save_new: 'Salva come nuovo file',
            input_file_name: 'Inserisci nome file:',
            invalid_char: 'Caratteri non validi:',
            storage_limit_exceeded: "L'archiviazione cloud ha raggiunto il limite massimo. Elimina eventuali file non necessari prima di salvarne di nuovi.",
        },
    },
    camera_data_backup: {
        title: 'Backup dati della fotocamera',
        no_picture_found: 'Nessuna immagine trovata nella macchina.',
        folder_not_exists: 'La cartella selezionata non esiste.',
        incorrect_folder: 'Impossibile caricare i dati di calibrazione. Verifica se la cartella selezionata è corretta.',
        downloading_data: 'Download dei dati...',
        estimated_time_left: 'Tempo stimato rimanente:',
        uploading_data: 'Caricamento dei dati...',
        download_success: 'Dati della fotocamera scaricati con successo.',
        upload_success: 'Dati della fotocamera caricati con successo.',
    },
    insecure_websocket: {
        extension_detected: "Rilevata l'estensione Beam Studio Connect",
        extension_detected_description: "Abbiamo rilevato che hai installato l'estensione Beam Studio Connect. Fare clic su 'Conferma' per reindirizzare a HTTPS, o fare clic su 'Annulla' per continuare a utilizzare HTTP.",
        extension_not_deteced: "Impossibile rilevare l'estensione Beam Studio Connect",
        extension_not_deteced_description: "Per utilizzare HTTPS, fare clic su 'Conferma' per installare l'estensione Beam Studio Connect. Dopo aver installato l'estensione, aggiornare la pagina per attivarla.<br/>Altrimenti, fare clic sul link sottostante per vedere come utilizzare HTTP su Chrome.",
        unsecure_url_help_center_link: '<a target="_blank" href="https://support.flux3dp.com/hc/en-us/articles/9935859456271">Collegamento</a>',
    },
    curve_engraving: {
        measure_audofocus_area: 'Misura area autofocus',
        amount: 'Quantità',
        gap: 'Spazio',
        rows: 'Righe',
        coloumns: 'Colonne',
        row_gap: 'Spazio tra le righe',
        column_gap: 'Spazio tra le colonne',
        set_object_height: 'Imposta altezza oggetto',
        set_object_height_desc: "Misurare lo spessore massimo dell'oggetto.",
        measure_area_help: "Come selezionare l'area di autofocus per la curva 3D?",
        reselect_area: 'Reseleziona area',
        start_autofocus: 'Avvia autofocus',
        starting_measurement: 'Inizio della misurazione...',
        preview_3d_curve: 'Anteprima della curva 3D',
        apply_arkwork: "Applica opera d'arte sulla curva 3D",
        apply_camera: 'Applica immagine della fotocamera sulla curva 3D',
        click_to_select_point: 'Clicca per selezionare o deselezionare i punti da misurare nuovamente.',
        remeasure: 'Rimisura',
        remeasuring_points: 'Rimisurazione dei punti...',
    },
    pass_through: {
        title: 'Modalità Di Attraversamento',
    },
};
export default lang;
